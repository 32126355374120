/* eslint-disable eqeqeq*/

import React, { useRef, useState, useEffect, useContext } from 'react';
// import { useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import { StyledMaterialTable } from '../../ui/display';

import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { vocabulary } from '../Strings';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
// import StyledCircularProgress from '../../ui/display/StyledCircularProgress'
import StyledSelect from "../../ui/forms/StyledSelect";
import { CreateIrrigation, GetAreaDetails, GetAreasList, } from "../../globalsTools/network";
import 'moment/locale/ar'
import 'moment/locale/fr'
import 'moment/locale/en-au'
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { Done, HighlightOff } from '@material-ui/icons';
import { CreateMultipleIrrigation, UpdateIrrigation } from "../../globalsTools/network";
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { logger } from '../../globalsTools/utilities';
import AreaFilteringInputs from '../Components/AreaFilteringInputs';

const MultipleIrrigationForm = ({ open, onClose, sitesList, item, isEditing, initialAreaId, areaId = null }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors, control } = useForm()
    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const modalContext = useContext(ModalContext)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [selectedAreaId, setSelectedAreaId] = useState(null)
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(null);

    const [selectedSiteId, setSelectedSiteId] = useState('')
    const [parcelsList, setParcelsList] = useState([])
    const [sectorsList, setSectorsList] = useState([])
    const [parcelId, setParcelId] = useState('')
    const [sectorId, setSectorId] = useState('')
    const [waterVolume, setWaterVolume] = useState(0)
    const [irrigationDaysCount, setIrrigationDaysCount] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const [timeValueTable, setTimeValueTable] = useState(null)
    const [step, setStep] = useState(0)

    const [sitesListDatasource, setSitesListDatasource] = useState([])

    useEffect(() => {
        initialAreaId && setSelectedAreaId(initialAreaId)
    }, [initialAreaId])


    // useEffect(() => {
    //     if (selectedArea != null) {
    //         setSelectedArea(selectedArea)
    //         categorizeAreas(selectedArea)
    //     }
    // }, [selectedArea])
    // useEffect(() => {
    //     if (item) {
    //         setSelectedSiteId(item.area.parent !== null ? item.area.parent.id : item.area.id)
    //         setParcelId(item.area.parent !== null ? item.area_id : null)
    //         setStartDate(item.start_time)
    //         setEndDate(item.end_time)
    //         setWaterVolume(item.water_volume)
    //         GetAreaDetails(item.area.parent !== null ? item.area.parent.id : item.area_id, setSelectedArea)
    //     }


    // }, [item])

    // const handleChange = event => {
    //     GetAreaDetails(event.target.value, setSelectedAreaId)
    // };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    // const categorizeAreas = (areasDetails) => {
    //     switch (areasDetails.type) {
    //         case 'site':
    //             setParcelsList(areasDetails["childs"])
    //             setSelectedSiteId(areasDetails.id)
    //             // setParcelId(null)

    //             break;
    //         case 'parcel':
    //             setParcelId(areasDetails.id)
    //             setSectorsList(areasDetails["childs"])
    //             setSectorId(null)

    //             break;
    //         case 'sector':
    //             setSectorId(areasDetails.id)
    //             break;

    //         default:
    //             return ''

    //     }
    // }

    let dateStart = moment(startDate);


    const getDateBeetwen = (dateStartLocal, endDateLocal, pas) => {

        let timeValues = [];

        if (dateStartLocal != null && endDateLocal != null && pas != null) {
            while (moment(endDateLocal).format("YYYY-MM-DD") >= moment(dateStartLocal).format("YYYY-MM-DD")) {
                timeValues.push({
                    irrigation_date: moment(dateStartLocal).format("YYYY-MM-DD"),
                    water_volume: waterVolume
                });
                dateStartLocal.add(Number(pas), "day");
            }
        }
        return timeValues
    }

    const handleDateChangeFrom = key => date => {
        setStartDate(date)
    };
    const handleDateChangeTo = key => date => {
        setEndDate(date)
    };

    const addRow = () => {
        let obj = {
            irrigation_date: '',
            water_volume: ''
        }
        setTimeValueTable(timeValueTable => [...timeValueTable, obj])

        return true
    }
    const remove = (row) => {
        let index = row.tableData.id
        timeValueTable.splice(index, 1)

        setTimeValueTable(timeValueTable => [...timeValueTable]);


    };
    const saveRowToList = (data) => {

        if (selectedIndex !== null) {
            // return (
            //     //timeValueTable[selectedIndex].irrigation_date = data.date,
            //     //timeValueTable[selectedIndex].water_volume = data.waterQuantity
            // )

        }

    }
    const addWaterQuantity = (data) => {

        if (waterVolume !== null) {
            timeValueTable.map((item, index) => {
                return item.water_volume = data;
            });
        }
    }
    const getRowIndex = (row) => {
        setSelectedIndex(row.tableData.id)
    }

    const changeStep = (step) => {
        setStep(step)
    }

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? vocabulary.waterVolume + ' ' + vocabulary.updateSucc : vocabulary.multipleIrrigationLabel + " " + vocabulary.addedSucc, 'success')
                // refetchFunc()
                //setIsLoading(false)
                onClose()


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                // setIsLoading(false)

            }
        }
    }

    const newMultiIrrigation = () => {
        let multiIrrigation = []
        timeValueTable.map(el => {
            delete el.tableData;
            return multiIrrigation.push(el)
        })
        CreateMultipleIrrigation(
            {
                area_id: areaId ?? selectedAreaId,
                irrigations: JSON.stringify(multiIrrigation)
            }, r => operationCallBack(r)
        )
    }

    const createOneIrrigation = () => {
        CreateIrrigation(
            {
                water_volume: waterVolume,
                start_time: moment(startDate).format('YYYY-MM-DD HH:mm'),
                area_id: areaId ?? selectedAreaId,

            }, r => operationCallBack(r)
        )

    }

    const editIrrigation = () => {

        UpdateIrrigation(
            {
                id: item && item.id,
                water_volume: waterVolume,
                iso_unit: 'mm',
                start_time: moment(startDate).format('YYYY-MM-DD'),
                end_time: moment(endDate).format('YYYY-MM-DD'),
                area_id: areaId ?? selectedAreaId,
                prm_irrigation_type_id: null,
                mode: 'Manual'


            }, r => operationCallBack(r)
        )

        // setOpenEditConfirmation(false)

    }

    const [formMultipleActivated, setFormMultipleActivated] = useState(false)

    const onChange = (e, row) => {
        timeValueTable[row.tableData.index] = { ...row, [e.target.name]: parseFloat(e.target.value) };
        setTimeValueTable(timeValueTable)
    }

    return (
        <StyledModal
            open={open}
            onClose={onClose}
            width={730}
            modalContext={modalContext}
            title={isEditing ? vocabulary.editIrrigation : vocabulary.addMultipleIrrigationLabel}
        >
            <form
                ref={form}

            >
                {step === 0 &&
                    <>
                        {
                            !areaId && <AreaFilteringInputs
                                initialAreaId={selectedAreaId}
                                handleChangeFn={setSelectedAreaId}
                            />
                        }


                        <StyledInput
                            register={register}
                            rules={{ required: true }}
                            name="waterVolume"
                            label={vocabulary.waterQuantity + ' ( ' + vocabulary.metreJour + ' ) '}
                            fullWidth
                            errors={errors.waterVolume}
                            value={waterVolume}
                            onChange={(event) => {
                                setWaterVolume(event.target.value);
                                timeValueTable && timeValueTable.length > 0 && addWaterQuantity(event.target.value)
                            }}
                            InputProps={{ inputProps: { min: 0, max: 999999999 } }}

                        />

                        <FormControlLabel
                            control={<Switch checked={formMultipleActivated} onChange={() => setFormMultipleActivated(!formMultipleActivated)} />}
                            label={vocabulary.addMultipleIrrigationLabel}
                        />

                        {formMultipleActivated &&
                            <StyledInput
                                register={register}
                                name="irrigationDaysCount"
                                label={vocabulary.howManyDaysLabel}
                                type="number"
                                fullWidth
                                errors={errors.irrigationDaysCount}
                                value={irrigationDaysCount}
                                InputProps={{ inputProps: { min: 0, max: 365 } }}

                                onChange={(event) => {
                                    setIrrigationDaysCount(event.target.value)
                                }}
                            />
                        }
                        <div style={{ display: 'flex' }}>
                            <DatePicker
                                value={startDate}
                                onChange={handleDateChangeFrom('dateFrom')}
                                style={{ margin: '8px', width: '100%' }}
                                animateYearScrolling
                                label={formMultipleActivated ? vocabulary.startDate : vocabulary.irrigationDate}
                                format={currentLanguage == 'fr' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                            />

                            {formMultipleActivated &&
                                <DatePicker
                                    value={endDate}
                                    onChange={handleDateChangeTo('dateTo')}
                                    label={vocabulary.endDate}
                                    minDate={startDate}

                                    style={{ margin: '8px', width: '100%' }}
                                    format={currentLanguage == 'fr' ? "DD/MM/YYYY" : "MM/DD/YYYY"}

                                />
                            }
                        </div>
                    </>}
                {step === 1 && formMultipleActivated &&

                    <StyledMaterialTable
                        title={''}
                        data={timeValueTable}
                        columns={
                            [

                                {
                                    title: vocabulary.date, align: 'center', field: 'irrigation_date', render: rowData => <>
                                        {
                                            <div key={rowData.irrigation_date}>
                                                <TextField
                                                    register={register}
                                                    rules={{ required: true }}
                                                    name={'irrigation_date'}
                                                    type='date'
                                                    defaultValue={moment(rowData.irrigation_date).format("YYYY-MM-DD")}
                                                    onKeyDown={() => getRowIndex(rowData)}
                                                />
                                            </div>
                                        }
                                    </>
                                },
                                {
                                    title: vocabulary.quantity + ' (' + vocabulary.metreJour + ' )', align: 'center', field: 'water_volume', render: rowData => <>
                                        {
                                            <div key={rowData.water_volume}>
                                                <TextField
                                                    register={register}
                                                    rules={{ required: true }}
                                                    name={'water_volume'}
                                                    type='number'
                                                    step='0.01'
                                                    defaultValue={rowData.water_volume}
                                                    InputProps={{ inputProps: { min: 0, max: 999999999 } }}
                                                    onKeyDown={() => getRowIndex(rowData)}
                                                    onChange={(e) => onChange(e, rowData)}
                                                />
                                            </div>
                                        }
                                    </>
                                },
                            ]
                        }
                        actions={[
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' className="addCycleBtn" />,
                                tooltip: vocabulary.addLigneLabel,
                                isFreeAction: true,
                                onClick: (event) => addRow()
                            },
                            {
                                icon: () => <Done />,
                                tooltip: vocabulary.save,
                                onClick: (event, row) => handleSubmit(saveRowToList)()

                            },
                            rowData => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => remove(row),
                                disabled: rowData.id < 2000
                            }),

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20, 40, 60, 80],
                        }}
                    />
                }

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button
                        style={{ margin: '5px' }}
                        onClick={() => modalContext.changeModalStatus(false)}
                        color={step === 0 ? "secondary" : 'primary'}
                        variant="contained"
                    >
                        <HighlightOff /> &nbsp;  {vocabulary.cancel}
                    </Button>

                    {step === 1 &&
                        <>
                            <Button
                                style={{ margin: '5px' }}
                                color="secondary"
                                onClick={() => changeStep(0)}
                                variant="contained"
                            >
                                <SkipPreviousIcon /> &nbsp;   {vocabulary.previous}

                            </Button>
                            <Button
                                style={{ margin: '5px' }}
                                color="primary"
                                onClick={() => newMultiIrrigation()}
                                variant="contained"

                            >
                                <Done /> &nbsp;  {vocabulary.save}

                            </Button>
                        </>
                    }

                    {(step === 0 && isEditing == true) &&
                        <Button
                            style={{ margin: '5px' }}
                            color="primary"
                            onClick={() => editIrrigation()}
                            variant="contained"

                        >
                            <SkipNextIcon /> &nbsp; {vocabulary.editLabel}

                        </Button>
                    }




                    {(step === 0 && isEditing == false && formMultipleActivated) &&
                        <Button
                            style={{ margin: '5px' }}
                            color="primary"
                            onClick={() => { setTimeValueTable(getDateBeetwen(dateStart, endDate, irrigationDaysCount)); changeStep(1) }}
                            variant="contained"
                            disabled={!(dateStart != null && endDate != null && irrigationDaysCount != null && waterVolume != null && (selectedAreaId != null || areaId != null))}
                        >
                            <SkipNextIcon /> &nbsp; {vocabulary.next}

                        </Button>
                    }

                    {(step === 0 && isEditing == false && !formMultipleActivated) &&
                        <Button
                            style={{ margin: '5px' }}
                            color="primary"
                            onClick={() => { createOneIrrigation() }}
                            variant="contained"
                            disabled={!(dateStart != null && waterVolume != null && selectedAreaId != null)}
                        >
                            <Done /> &nbsp; {vocabulary.save}

                        </Button>
                    }


                </div>


            </form>

        </StyledModal>

    )

}
export default MultipleIrrigationForm
/* eslint-disable eqeqeq*/
/* eslint-disable-next-line react-hooks/exhaustive-deps */

import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { GetAreaDetails, CreateIrrigation, UpdateIrrigation, GetIrrigationFamilyList, GetIrrigationTypeList } from "../../globalsTools/network";
import StyledSelect from "../../ui/forms/StyledSelect";
import { vocabulary } from '../Strings';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress'
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";

const AddIrrigationForm = ({ isEditing, item, selectedAreaFromOnePage, open, recalculate, onClose, irrigationOnePager = false, AreaId, sitesList, getingSiteList }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [selectedArea, setSelectedArea] = useState(null)
    const [selectedSiteId, setSelectedSiteId] = useState(irrigationOnePager == true ? AreaId : null)
    const [parcelsList, setParcelsList] = useState([])
    const [waterVolume, setWaterVolume] = useState(null)
    const [isoUnit, setIsoUnit] = useState(null)
    const [startDate, setStartDate] = useState(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DDTHH:mm"))
    const [endDate, setEndDate] = useState(null)
    const [parcelId, setParcelId] = useState(null)
    // const [sitesList, setSitesList] = useState(null)
    const [sectorsList, setSectorsList] = useState([])
    const [sectorId, setSectorId] = useState('')
    const [irrigationFamilyList, setIrrigationFamilyList] = useState(null)
    const [irrigationFamilyId, setIrrigationFamilyId] = useState(null)
    const [irrigationTypeList, setIrrigationTypeList] = useState(null)
    const [irrigationTypeId, setIrrigationTypeId] = useState(null)
    const [irrigationMode, setIrrigationMode] = useState('')
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    const modalContext = useContext(ModalContext)

    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea])

    useEffect(() => {
        // GetAreasList(setSitesList)
        GetIrrigationFamilyList(setIrrigationFamilyList)

    }, [])
    useEffect(() => {
        if (item) {

            setSelectedSiteId(item.area.parent !== null ? item.area.parent.id : item.area.id)
            setParcelId(item.area.parent !== null ? item.area_id : null)
            setStartDate(item.start_time)
            setEndDate(item.end_time)
            setIsoUnit(item.iso_unit)
            setWaterVolume(item.water_volume)
            setIrrigationTypeId(item.prm_irrigation_type_id)
            setIrrigationMode(item.mode)
            setIrrigationFamilyId(item.prm_irrigation_type.prm_irrigation_family_id)
            GetIrrigationTypeList(item.prm_irrigation_type.prm_irrigation_family_id, setIrrigationTypeList)
            GetAreaDetails(item.area.parent !== null ? item.area.parent.id : item.area_id, setSelectedArea)
            // getingSiteList()
        }


    }, [item])
    useEffect(() => {
        if (isEditing === false) {
            setIrrigationFamilyId(null)
            setIrrigationMode(null)
            setIrrigationTypeId(null)
        }

    }, [open, isEditing]); // N’exécute l’effet que si open a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const handleDateChangeFrom = key => date => {
        setStartDate(date)
    };
    const handleDateChangeTo = key => date => {
        setEndDate(date)
    };
    useEffect(() => {
        if (isEditing === false) {


            // setSelectedSiteId(null)
            setStartDate(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DDTHH:mm"))
            setEndDate(null)
            setIsoUnit(null)
        }


    }, [open, isEditing])
    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSelectedSiteId(areasDetails.id)
                // setParcelId(null)

                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                setSectorsList(areasDetails["childs"])
                setSectorId(null)

                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;

            default:
                return ''

        }
    }
    const validForm = () => {
        if (!selectedSiteId || !isoUnit || !irrigationTypeId || !irrigationMode || !endDate) {
            return true
        }
        else return false

    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200 & irrigationOnePager == false) {
                snackbar(isEditing ? JSON.parse(item.prm_irrigation_type.name)['name_' + currentLanguage] + ' ' + vocabulary.updateSucc : vocabulary.waterVolume + ' ' + vocabulary.addedSucc, 'success')
            }
            else if (r.status === 200 & irrigationOnePager == true) {
                recalculate(true)
                snackbar(vocabulary.waterVolume + ' ' + vocabulary.addedSucc, 'success')

            }
            else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    let unityType = [{ name: 'mm', id: '1' }, { name: 'L', id: '2' }, { name: 'm3', id: '3' }]
    let mode = [{ name: vocabulary.automatic, value: 'Automatic' }, { name: vocabulary.manuel, value: 'Manual' }]

    const newIrrigation = (data) => {

        if (isEditing === false) {
            CreateIrrigation(
                {
                    water_volume: waterVolume,
                    iso_unit: isoUnit,
                    start_time: moment(startDate).format('YYYY-MM-DD HH:mm'),
                    end_time: moment(endDate).format('YYYY-MM-DD HH:mm'),
                    area_id: irrigationOnePager ? AreaId : selectedArea && selectedArea.id,
                    prm_irrigation_type_id: irrigationTypeId,
                    mode: irrigationMode

                }, r => operationCallBack(r)
            )

            onClose()
        }

    }
    const handleUpdateIrrigation = () => {
        UpdateIrrigation(
            {
                id: item && item.id,
                water_volume: waterVolume,
                iso_unit: isoUnit,
                start_time: moment(startDate).format('YYYY-MM-DD HH:mm'),
                end_time: moment(endDate).format('YYYY-MM-DD HH:mm'),
                area_id: parcelId !== null ? parcelId : selectedSiteId,
                prm_irrigation_type_id: irrigationTypeId,
                mode: irrigationMode


            }, r => operationCallBack(r)
        )

        setOpenEditConfirmation(false)
        onClose()
    }
    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };

    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? newIrrigation() : setOpenEditConfirmation(true), disabled: isEditing ? false : validForm() },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={600}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addIrrigation : vocabulary.editIrrigation}
            >
                {
                    ((isEditing == true && selectedArea != null) || isEditing == false) ?
                        <form
                            onSubmit={handleSubmit(newIrrigation)}
                            ref={form}

                        >
                            {/* {sitesList && */}
                            <StyledSelect
                                value={selectedSiteId}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.Area}
                                onChange={handleChange}
                                name='Area'
                                label={vocabulary.site}
                                fullWidth
                                data={irrigationOnePager ? [{ 'id': AreaId, 'name': selectedAreaFromOnePage && selectedAreaFromOnePage.name }] : sitesList && sitesList.data}
                                valueField='id'
                                labelField='name'
                                styles={{ width: '100%' }}

                            />
                            {/* } */}
                            <br />

                            {(parcelsList.length > 0 && selectedSiteId) &&
                                <StyledSelect
                                    value={parcelId}
                                    register={register}
                                    errors={errors.ParcelId}
                                    onChange={handleChange}
                                    name='ParcelId'
                                    label={vocabulary.parcel}
                                    fullWidth
                                    data={parcelsList}
                                    valueField='id'
                                    labelField='name'
                                    styles={{ width: '100%' }}

                                />
                            }

                            {(sectorsList.length > 0 && parcelId) &&
                                <StyledSelect
                                    value={sectorId}
                                    register={register}
                                    errors={errors.sectorId}
                                    onChange={handleChange}
                                    name='sectorId'
                                    label={vocabulary.sector}
                                    fullWidth
                                    data={sectorsList}
                                    valueField='id'
                                    labelField='name'
                                    styles={{ width: '100%' }}

                                />
                            }
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                name="waterVolume"
                                label={vocabulary.waterQuantity}
                                type="text"
                                fullWidth
                                errors={errors.waterVoluyme}
                                value={waterVolume}
                                onChange={(event) => {
                                    setWaterVolume(event.target.value)
                                }}



                            />
                            <StyledSelect
                                value={isoUnit}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.isoUnit}
                                onChange={(event) => {
                                    setIsoUnit(event.target.value)
                                }}
                                name='isoUnit'
                                label={vocabulary.isoUnit}
                                fullWidth
                                data={unityType}
                                valueField='name'
                                labelField='name'
                                styles={{ width: '100%' }}


                            />
                            <br />
                            <StyledSelect
                                value={irrigationFamilyId}
                                register={register}
                                //rules={{ required: true }}
                                errors={errors.irrigationFamilyId}
                                onChange={(event) => {
                                    setIrrigationFamilyId(event.target.value)
                                    GetIrrigationTypeList(event.target.value, setIrrigationTypeList)
                                }}
                                render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
                                name='irrigationFamilyId'
                                label={vocabulary.irrigationFamily}
                                fullWidth
                                data={irrigationFamilyList && irrigationFamilyList.data}
                                valueField='id'
                                labelField='name'
                                styles={{ width: '100%' }}

                            />
                            <br />

                            {/* {irrigationFamilyId && */}
                            <StyledSelect
                                value={irrigationTypeId}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.irrigationTypeId}
                                onChange={(event) => {
                                    setIrrigationTypeId(event.target.value)
                                }}
                                render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
                                name='irrigationTypeId'
                                label={vocabulary.irrigationType}
                                fullWidth
                                data={irrigationTypeList && irrigationTypeList}
                                valueField='id'
                                labelField='name'
                                styles={{ width: '100%' }}
                                disabled={!irrigationTypeList}


                            />
                            {/* } */}
                            <br />
                            <StyledSelect
                                value={irrigationMode}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.irrigationMode}
                                onChange={(event) => {
                                    setIrrigationMode(event.target.value)
                                }}
                                name='irrigationMode'
                                label={vocabulary.irrigationMode}
                                fullWidth
                                data={mode}
                                valueField='value'
                                labelField='name'
                                styles={{ width: '100%' }}


                            />
                            <DateTimePicker
                                value={startDate}
                                onChange={handleDateChangeFrom('dateFrom')}
                                style={{ margin: '8px', width: '100%' }}
                                animateYearScrolling
                                label={vocabulary.startDate}
                                ampm={false}
                            />

                            <DateTimePicker
                                value={endDate}
                                onChange={handleDateChangeTo('dateTo')}
                                label={vocabulary.endDate}
                                minDate={startDate}
                                ampm={false}
                                style={{ margin: '8px', width: '100%' }}

                            />

                            <StyledModalFooter actions={actions} />

                        </form> : <StyledCircularProgress size={50} label={vocabulary.gettingIrrigationInformation} />
                }
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={currentLanguage == "en" ? JSON.parse(item.prm_irrigation_type.name)['name_' + currentLanguage] + ' ' + vocabulary.ofLabel + ' ' + moment(item.start_time).format('YYYY-MM-DD HH:mm') : JSON.parse(item.prm_irrigation_type.name)['name_' + currentLanguage] + ' ' + vocabulary.ofLabel + ' ' + moment(item.start_time).format('MM-DD-YYYY HH:mm')}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateIrrigation();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default AddIrrigationForm

/* eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { setCurrentPage, loadAllCountries } from '../../../actions';
import { GetAllUsers, DeleteUser, UpdateUser, GetAreaByUserId, GetAreasListing, DeleteArea, DeleteAreaPermanently, MassDeleteAreaPermanently, CleanDeletedAreas } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import FollowIcon from '@material-ui/icons/HowToReg';
import UnfollowIcon from '@material-ui/icons/PersonAddDisabled'
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { BathtubOutlined, Delete, Refresh } from '@material-ui/icons';

import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { useCallback } from 'react';
import { logger } from '../../../globalsTools/utilities';


const AreasManagement = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [allAreaList, setAllAreaList] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [openRestoreModal, setOpenRestoreModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.areaManagement));
        dispatch(loadAllCountries())
        GetAreasListing(setAllAreaList)
        document.title = vocabulary.areaManagement

    }, [dispatch]); // N’exécute l’effet qu'a la première render'


    const openAlertConfirmDelete = (row) => {
        logger('Row On Alert >>', selectedRows, row)
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(item.name + " : " + vocabulary.deleteMsg, 'success')
                GetAreasListing(setAllAreaList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }


    let convertSize = useCallback(
        (size) => {
            return Number(size / 10000).toFixed(2) + " " + vocabulary.hectar
        },
      [],
    )

    const mtStyleFn = useCallback((rowData) => ({ 
        backgroundColor: rowData.deleted_at==null ? "#fff" : "#f5c9b0"
    }), [])

    useEffect(() => {
      logger('Selected Rows ->> ', selectedRows)
    }, [selectedRows])
    
    
    return (
            <StyledCard>
                {allAreaList ?
                    <StyledMaterialTable
                        title={vocabulary.userList}
                        data={allAreaList && allAreaList.filter(item => item.parent_id==null)}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        // parentChildData={parentChild}

                        columns={[
                            { title: vocabulary.userName,   field: 'user_id',
                                                            render: rowData => {
                                                                let user = allAreaList.filter(item => item.user_id==rowData)[0].user; 
                                                                return <>{user && user.lastname + ', ' + user.firstname}</>
                                                            }, 
                                                            defaultGroupOrder:0 },
                            { title: vocabulary.name_label, field: 'name' },
                            { title: vocabulary.description, field: 'description' },

                            { title: vocabulary.size, field: 'size', render: rowData => <>{convertSize(rowData.size)}</> },
                            { title: vocabulary.created_at, field: 'created_at', render: rowData => moment(rowData.created_at).format('YYYY-MM-DD HH:mm') },
                            { title: vocabulary.lastConnectionLabel, field: 'user.last_connection', render: rowData => <>{rowData.last_connection !== null ? moment(rowData.last_connection).format('YYYY-MM-DD HH:mm') : vocabulary.notConnectedYet}</> },


                        ]}
                        actions={[
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.Delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),
                            }),
                            {
                                icon: () => <Refresh fontSize='large' color='primary' />,
                                tooltip: vocabulary.refreshAreaManagement,
                                isFreeAction: true,
                                onClick: () => GetAreasListing(setAllAreaList)
                            },
                            {
                                icon: () => <BathtubOutlined fontSize='large' color='primary' />,
                                tooltip: vocabulary.cleanDeletedAreas,
                                isFreeAction: true,
                                onClick: () => window.confirm(vocabulary.cleanDeletedAreas + " ?") && CleanDeletedAreas()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 100,
                            pageSizeOptions: [100, 400, 1000],
                            exportMenu: [{
                                label: 'Export PDF',
                                exportFunc: (cols, datas) => ExportPdf(cols, datas, "Areas List")
                              }, {
                                label: 'Export CSV',
                                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Areas List')
                              }],
                            grouping:true,
                            rowStyle: mtStyleFn,
                            selection:true

                        }}

                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={Array.isArray(item) ? item.length : item.name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                if (Array.isArray(item)) {
                                    MassDeleteAreaPermanently(item.map(i => i.id), operationCallBack);
                                } else {
                                    DeleteAreaPermanently(item.id, operationCallBack);
                                }
                            }
                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

            </StyledCard>

    )
}
export default AreasManagement
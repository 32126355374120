import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip } from '@material-ui/core/';

import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';
import { setCurrentPage,  unfollowMemberThenLoadData } from '../../actions';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';

import UnfollowIcon from '@material-ui/icons/PersonAdd';
import AppIcon from '@material-ui/icons/Computer';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';

import { useHistory } from 'react-router-dom';
import AddFollowersForm from './AddFollowersForm'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { GetAllFollowers } from '../../globalsTools/network'
import { GetSubscriptionList } from '../../globalsTools/network'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { stylesThemed } from '../globalStyle';

const Followers = (props) => {

    const dispatch = useDispatch()
    const history = useHistory();

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [open, setOpen] = useState(false);
    const [followersList, setFollowersList] = useState(null);
    const [subscriptionList, setSubscriptionList] = useState(null)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.followers));
        
        GetSubscriptionList(setSubscriptionList)
        GetAllFollowers(setFollowersList)
        document.title = vocabulary.followers

    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.followers));
        document.title = vocabulary.followers

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };



    const unfollowMemberSeabex = (id) => {
        dispatch(unfollowMemberThenLoadData(id, null)).then(() => GetAllFollowers(setFollowersList)
        )
    }

    const getRealTimeData = (row) => {
        //if (row.gateway_id) {
        history.push("../app/monitoringAndSensors", { "stations": row });

    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpen(!open)

        } else {
            setOpen(newStatus)
        }
    }

    const classes = stylesThemed();

    const contextValueAdd = { open: open, changeModalStatus: changeModalStatus }
    return (
        <ModalContext.Provider value={contextValueAdd}>

            <StyledCard>
                {followersList ?
                    <StyledMaterialTable
                        title={vocabulary.followers}
                        data={followersList}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[
                            { title: vocabulary.fullName, field: 'user', render: rowData => <>{rowData.user.firstname} {rowData.lastname}</> },
                            { title: vocabulary.mobile, field: 'user', render: rowData => <>{rowData.user.mobile}</> },
                            { title: vocabulary.name_label, field: 'subscription', render: rowData => <>{JSON.parse(rowData.subscription.title).name}</> },
                            {
                                title: vocabulary.SubscriptionType, field: 'subscription', render: rowData => <div style={{ display: 'flex' }}>
                                    {JSON.parse(rowData.subscription.channels).sms ?
                                        <Chip
                                            icon={<SmsIcon className={classes.colorTextChips} />}
                                            label={vocabulary.sms}
                                            className={[classes.chip, classes.colorAction, classes.colorTextChips]}
                                        />
                                        : null
                                    }
                                    {JSON.parse(rowData.subscription.channels).email ?
                                        <Chip
                                            icon={<EmailIcon className={classes.colorTextChips} />}
                                            label={vocabulary.email}
                                            className={[classes.chip, classes.colorGateway, classes.colorTextChips]}
                                        />
                                        : null}
                                    {JSON.parse(rowData.subscription.channels).application ?
                                        <Chip
                                            icon={<AppIcon className={classes.colorTextChips} />}
                                            label={vocabulary.application}
                                            className={[classes.chip, classes.colorMonitoring, classes.colorTextChips]}
                                        />
                                        : null}
                                    {JSON.parse(rowData.subscription.channels).api ?
                                        <Chip
                                            icon={<AppIcon className={classes.colorTextChips} />}
                                            label={vocabulary.apiLabel}
                                            className={[classes.chip, classes.colorMonitoring, classes.colorTextChips]}
                                        />
                                        : null}
                                </div>
                            },

                            { title: vocabulary.memberSince, field: 'start_date' },

                        ]}
                        actions={[

                            row => ({
                                icon: () => <UnfollowIcon />,
                                tooltip: vocabulary.unfollow,
                                onClick: (event, row) => unfollowMemberSeabex(row.user_id),

                                disabled: row.id < 2000
                            }),

                            {
                                icon: () => <SettingsInputAntenna />,
                                tooltip: 'RealTime',
                                onClick: (event, row) => getRealTimeData(row.stations)
                            },
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.add,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }


                {open &&
                    <AddFollowersForm
                        open={open}
                        onClose={() => {
                            handleClose()
                        }}
                        isEditing={false}
                        subscriptionList={subscriptionList && subscriptionList}
                        refetchFunc={() => GetAllFollowers(setFollowersList)
                        }
                    />}

            </StyledCard>
        </ModalContext.Provider>

    );

}

// Followers.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default Followers;
/* eslint-disable no-useless-escape*/

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Divider } from '@material-ui/core/';
import { loadCitiesByIdCountries } from '../../actions';
// import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../ui/layout'
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import StyledSelect from '../../ui/forms/StyledSelect'
import { CONSTS } from './GlobalConst';
import { CreateOrganization, updateOrganization } from '../../globalsTools/network'

const AddOrganismeForm = ({ open, selectedItem, countriesList, citiesList, handleClose, isEditing, refetchFunc }) => {

    const dispatch = useDispatch()
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const [status, setStatus] = useState('')
    const [country, setCountry] = useState('Tunisia')
    // const [toUpdate, setToUpdate] = useState(false)
    // const [id, setId] = useState('')

    const [type, setType] = useState('')
    const [selectedVilleIndex, setSelectedVilleIndex] = useState('')
    const [mobile, setMobile] = useState('')
    // const [isEditing, setIsEditing] = useState(false);


    useEffect(() => {
        if (isEditing === false) {

            setMobile('')
        }

    }, [isEditing]);

    useEffect(() => {

        if (selectedItem && isEditing === true) {

            setMobile(selectedItem.mobile)
            setStatus(selectedItem.status)
            setType(JSON.parse(selectedItem.type).type)
            setSelectedVilleIndex(selectedItem.prm_governate_id)
            setCountry(selectedItem.prm_country_id)

        }

    }, [selectedItem, isEditing]); // N’exécute l’effet que si item a changé

    const NewOrganisme = (data) => {

        CreateOrganization(
            {
                name: JSON.stringify({ 'name': data.name }),
                email: data.email,
                mobile: mobile,
                address: JSON.stringify({ 'address': data.address }),
                prm_governate_id: selectedVilleIndex,
                type: JSON.stringify({ 'type': type }),
                status: status,
                prm_country_id: country
            }, function (r) {
                if (r) {
                    if (r.status === 200) {
                        refetchFunc();
                        handleClose()

                    }
                }

            })
        handleClose()
    }
    const updateSeabexOrganisme = (data) => {
        updateOrganization(
            {
                id: selectedItem.id,
                name: JSON.stringify({ 'name': data.name }),
                email: data.email,
                mobile: mobile,
                address: JSON.stringify({ 'address': data.address }),
                prm_governate_id: selectedVilleIndex,
                type: JSON.stringify({ 'type': type }),
                status: status,
                prm_country_id: country
            }).then(() => refetchFunc());
        handleClose()
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    const actions = [
        { title: vocabulary.submit, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={handleClose}
                width={500}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.newAffiliatedorganization : vocabulary.editAffiliatedorganization}>
                <form
                    ref={form}
                    onSubmit={isEditing === true ? handleSubmit(updateSeabexOrganisme) : handleSubmit(NewOrganisme)}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.name}
                        name="name"
                        label={vocabulary.organizationName}
                        type="text"
                        fullWidth
                        defaultValue={selectedItem && selectedItem.name && JSON.parse(selectedItem.name).name}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true, validate: validateEmail }}
                        name="email"
                        errors={errors.email}
                        label={vocabulary.email}
                        type="text"
                        fullWidth
                        defaultValue={selectedItem && selectedItem.email}

                    />
                    {errors.email && <span>Email Invalide</span>}
                    <PhoneInput
                        country="TN"
                        displayInitialValueAsLocalNumber
                        placeholder="Enter phone number"
                        value={mobile}

                        onChange={(event) => {
                            setMobile(event)
                        }}

                        error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid phone number') : 'Phone number required'} />
                    <Divider />

                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                        <StyledAutoComplete
                            stye={{ width: 80 }}
                            data={countriesList}
                            optionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setCountry(newValue.countyId)
                                dispatch(loadCitiesByIdCountries(newValue.value))

                            }}
                            label={vocabulary.country}

                        />
                        <StyledAutoComplete
                            stye={{ width: 80 }}
                            data={citiesList}
                            optionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setSelectedVilleIndex(newValue.value)

                            }}
                            label={vocabulary.region}

                        />

                    </div>
                    <StyledInput
                        errors={errors.address}
                        register={register}
                        rules={{ required: true }}
                        name="address"
                        label={vocabulary.address}
                        type="text"
                        fullWidth
                        defaultValue={selectedItem && selectedItem.address && JSON.parse(selectedItem.address).address}


                    />
                    <Divider />
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >

                        <StyledSelect
                            register={register}
                            value={type}
                            rules={{ required: true }}
                            errors={errors.type}
                            name='type'
                            label={vocabulary.type}
                            fullWidth
                            onChange={(event) => {
                                setType(event.target.value)
                            }}
                            data={CONSTS.TYPES}
                            valueField='value'
                            labelField='title'
                        />

                        <br />
                        <StyledSelect
                            register={register}
                            value={status}
                            rules={{ required: true }}
                            errors={errors.status}
                            name='status'
                            label={vocabulary.status}
                            fullWidth
                            onChange={(event) => {
                                setStatus(event.target.value)
                            }}
                            data={CONSTS.TYPES}
                            valueField='value'
                            labelField='title'
                        />
                    </div>
                    <br />

                    <Divider />

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
        </>
    )
}

export default AddOrganismeForm


/* eslint-disable eqeqeq*/
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars*/

import React, { useRef, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { CreatePublication, UpdatePublication } from "../../../globalsTools/network";
import { useSnackbar } from 'notistack';
// import StyledSelect from "../../../ui/forms/StyledSelect";
import { DatePicker } from '@material-ui/pickers';
import StyledAutoComplete from "../../../ui/forms/StyledAutoComplete";
// import { useDropzone } from 'react-dropzone';
// import upload from '../../../images/upload.png'
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";

import { Tabs, Tab } from '@material-ui/core'

const PublicationAddForm = ({ isEditing, onClose, item, open, width, refetchFunc, setIsLoading, countriesList }) => {

    const getSteps = () => {
        return ['English', 'Frensh', 'Arabic'];
    }

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const modalContext = useContext(ModalContext)
    const stepsPage = getSteps();
    const [tab, setTab] = useState(0)

    const [title, setTitle] = useState(null);
    const [titleFr, setTitleFr] = useState(null);
    const [titleAr, setTitleAr] = useState(null);
    const [description, setDescription] = useState(null);
    const [descriptionFr, setDescriptionFr] = useState(null);
    const [descriptionAr, setDescriptionAr] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [subTitleFr, setSubTitleFr] = useState(null);
    const [subTitleAr, setSubTitleAr] = useState(null);
    const [author, setAuthor] = useState(null);
    const [authorFr, setAuthorFr] = useState(null);
    const [authorAr, setAuthorAr] = useState(null);
    const [linkTitleEn, setLinkTitleEn] = useState(null);
    const [linkTitleFr, setLinkTitleFr] = useState(null);
    const [linkTitleAr, setLinkTitleAr] = useState(null);

    const [link, setLink] = useState(null);
    const [publicationImage, setPublicationImage] = useState(null);


    const [color, setColor] = useState(null)
    const [statusLabel, setStatusLabel] = useState(null)
    const [startdate, setStartDate] = useState(null)
    const [countryId, setCountryId] = useState(null)
    const [countryAlphaIso, setCountryAlphaIso] = useState(null)
    // const [fileReader, setFileReader] = useState(null)
    // const [file, setFile] = useState(null)
    // const [activeStep, setActiveStep] = useState(0);
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const statusType = [

        {
            name: 'Alert',
            id: 'alert',
            color: '#03465b',
        },
        {
            name: 'Info',
            color: '#349ff3',
            id: 'info'
        },
        {
            name: 'Error',
            color: '#f45347',
            id: 'error'

        },
        {
            name: 'Event',
            color: '#800080',
            id: 'event'

        },
        {
            name: 'Warning',
            id: 'warning',
            color: '#ffa016'

        }
    ];
    useEffect(() => {

        if (isEditing === false) {
            setTitle(null)
            setTitleAr(null)
            setTitleFr(null)
            setDescription(null)
            setDescriptionAr(null)
            setDescriptionFr(null)
            setColor(null)
            setSubTitle(null)
            setSubTitleAr(null)
            setSubTitleFr(null)
            setAuthor(null)
            setAuthorAr(null)
            setAuthorFr(null)
            setStartDate(null)
            setLink(null)
            setLinkTitleAr(null)
            setLinkTitleEn(null)
            setLinkTitleFr(null)
            setCountryId(null)
            setTab(0)
            setStatusLabel(null)
            setPublicationImage(null)
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (item) {

            setTitle(JSON.parse(item.title).title_en)
            setTitleFr(JSON.parse(item.title).title_fr)
            setTitleAr(JSON.parse(item.title).title_ar)
            setDescription(JSON.parse(item.description).description_en)
            setDescriptionAr(JSON.parse(item.description).description_ar)
            setDescriptionFr(JSON.parse(item.description).description_fr)
            setAuthorFr(JSON.parse(item.author).author_fr)
            setAuthorAr(JSON.parse(item.author).author_ar)
            setAuthor(JSON.parse(item.author).author_en)
            setSubTitle(JSON.parse(item.subTitle).subtitle_en)
            setSubTitleFr(JSON.parse(item.subTitle).subtitle_fr)
            setSubTitleAr(JSON.parse(item.subTitle).subtitle_ar)
            setCountryId(item.country_id)
            setCountryAlphaIso(item.country.iso_alpha2.toUpperCase())
            setLinkTitleAr(JSON.parse(item.link).LinkTitle_ar)
            setLinkTitleEn(JSON.parse(item.link).LinkTitle_en)
            setLinkTitleFr(JSON.parse(item.link).LinkTitle_fr)
            setLink(JSON.parse(item.link).link)
            setPublicationImage(JSON.parse(item.image).src)
            setStatusLabel(item.type)
            setStartDate(item.date)


        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé
    useEffect(() => {
        countriesList.unshift({
            name: vocabulary.worldWideLabel,
            id: null,
            iso_alpha2: null

        })
    }, [countriesList]); // N’exécute l’effet que si selectedItem a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const validForm = () => {
        if (!statusLabel || !link || !startdate) {
            return true
        }
        else return false

    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? JSON.parse(item.title)['title_' + currentLanguage] + "  " + vocabulary.updateSucc : vocabulary.publicationLabel + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)
                setTab(0)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleAddPublication = () => {
        if (isEditing === false) {

            CreatePublication(
                {
                    title: JSON.stringify({ "title_fr": titleFr, "title_en": title, "title_ar": titleAr }),
                    subTitle: JSON.stringify({ "subtitle_fr": subTitleFr, "subtitle_en": subTitle, "subtitle_ar": subTitleAr }),
                    description: JSON.stringify({ "description_fr": descriptionFr, "description_en": description, "description_ar": descriptionAr }),
                    author: JSON.stringify({ "author_fr": authorFr, "author_en": author, "author_ar": authorAr }),
                    link: JSON.stringify({ 'LinkTitle_fr': linkTitleFr, 'LinkTitle_en': linkTitleEn, 'LinkTitle_ar': linkTitleAr, 'link': link }),
                    image: JSON.stringify({ 'legend': 'iheb photo', 'src': publicationImage }),
                    type: statusLabel,
                    country_id: countryId,
                    country_iso: countryAlphaIso,
                    date: startdate

                }, r => operationCallBack(r)
            )
            onClose()
        }

    }
    const handleUpdatePublication = () => {

        UpdatePublication(
            {
                id: item.id,
                title: JSON.stringify({ "title_fr": titleFr, "title_en": title, "title_ar": titleAr }),
                subTitle: JSON.stringify({ "subtitle_fr": subTitleFr, "subtitle_en": subTitle, "subtitle_ar": subTitleAr }),
                description: JSON.stringify({ "description_fr": descriptionFr, "description_en": description, "description_ar": descriptionAr }),
                author: JSON.stringify({ "author_fr": authorFr, "author_en": author, "author_ar": authorAr }),
                link: JSON.stringify({ 'LinkTitle_fr': linkTitleFr, 'LinkTitle_en': linkTitleEn, 'LinkTitle_ar': linkTitleAr, 'link': link }),
                image: JSON.stringify({ 'legend': 'iheb photo', 'src': publicationImage }),
                type: statusLabel,
                country_id: countryId,
                country_iso: countryAlphaIso,
                date: startdate


            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        onClose()
    }



    
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleAddPublication() : setOpenEditConfirmation(true), disabled: isEditing ? false : validForm() },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]


    const handleTabChange = (event, newValue) => {
        setTab(newValue)
    }


    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={width}
                title={isEditing === false ? vocabulary.add + ' ' + vocabulary.publicationLabel : vocabulary.editLabel + ' ' + vocabulary.publicationLabel}
            >
                <form
                    ref={form}
                    onSubmit={handleSubmit(handleAddPublication)}
                    onError={errors => console.log(errors)}>
                    <Tabs style={{ marginBottom: 8 }}
                        value={tab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        {stepsPage.map((label, index) => (
                            <Tab label={label} />

                        ))}


                    </Tabs>
                    <div className="form_footer">
                        <div className="unit_list">
                            {' basic information '}
                        </div>
                        {

                            tab == 0 &&
                            <>
                                <StyledInput
                                    value={title}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setTitle(event.target.value) }}
                                    name='title'
                                    type="text"
                                    styles={{ width: '97%' }}
                                    label={vocabulary.titleLabel}
                                />
                                <StyledInput
                                    value={subTitle}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setSubTitle(event.target.value) }}
                                    name='subTitle'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={vocabulary.subTitle}
                                />
                                <StyledInput
                                    register={register}
                                    name="description"
                                    label={vocabulary.description}
                                    value={description}
                                    rules={{ required: true }}
                                    onChange={(event) => { setDescription(event.target.value) }}
                                    errors={errors.description}
                                    maxRows={3}
                                    styles={{ width: '97%' }}

                                    multiline={true}

                                />
                                <StyledInput
                                    value={author}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setAuthor(event.target.value) }}
                                    name='subTitle'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={vocabulary.AuthorLabel}
                                />
                                <StyledInput
                                    value={linkTitleEn}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setLinkTitleEn(event.target.value) }}
                                    name='linkTitle'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={'link Title'}
                                />

                            </>
                        }
                        {
                            tab == 1 &&
                            <>
                                <StyledInput
                                    value={titleFr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setTitleFr(event.target.value) }}
                                    name='title'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={vocabulary.titleLabel}
                                />
                                <StyledInput
                                    value={subTitleFr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setSubTitleFr(event.target.value) }}
                                    name='subTitle'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={vocabulary.subTitle}
                                />
                                <StyledInput
                                    register={register}
                                    name="description"
                                    label={vocabulary.description}
                                    value={descriptionFr}
                                    rules={{ required: true }}
                                    onChange={(event) => { setDescriptionFr(event.target.value) }}
                                    errors={errors.description}
                                    maxRows={3}
                                    styles={{ width: '97%' }}

                                    multiline={true}

                                />
                                <StyledInput
                                    value={authorFr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setAuthorFr(event.target.value) }}
                                    name='subTitle'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={vocabulary.AuthorLabel}
                                />
                                <StyledInput
                                    value={linkTitleFr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setLinkTitleFr(event.target.value) }}
                                    name='linkTitle'
                                    type="text"
                                    styles={{ width: '97%' }}

                                    label={'link Title'}
                                />

                            </>
                        }
                        {
                            tab == 2 &&
                            <>
                                <StyledInput
                                    value={titleAr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setTitleAr(event.target.value) }}
                                    name='title'
                                    type="text"
                                    styles={{ width: '97%' }}
                                    label={vocabulary.titleLabel}
                                />
                                <StyledInput
                                    value={subTitleAr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setSubTitleAr(event.target.value) }}
                                    name='subTitle'
                                    type="text"
                                    styles={{ width: '97%' }}
                                    label={vocabulary.subTitle}
                                />
                                <StyledInput
                                    register={register}
                                    name="description"
                                    label={vocabulary.description}
                                    value={descriptionAr}
                                    rules={{ required: true }}
                                    onChange={(event) => { setDescriptionAr(event.target.value) }}
                                    errors={errors.description}
                                    maxRows={3}
                                    styles={{ width: '97%' }}
                                    multiline={true}

                                />
                                <StyledInput
                                    value={authorAr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setAuthorAr(event.target.value) }}
                                    name='authorAr'
                                    type="text"
                                    styles={{ width: '97%' }}
                                    label={vocabulary.AuthorLabel}
                                />
                                <StyledInput
                                    value={linkTitleAr}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.title}
                                    onChange={(event) => { setLinkTitleAr(event.target.value) }}
                                    name='linkTitle'
                                    type="text"
                                    styles={{ width: '97%' }}
                                    label={'link Title'}
                                />

                            </>
                        }
                    </div>


                    <StyledInput
                        value={link}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.title}
                        onChange={(event) => { setLink(event.target.value) }}
                        name='link'
                        type="text"
                        fullWidth
                        label={'link url'}
                    />
                    <StyledInput
                        value={publicationImage}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.title}
                        onChange={(event) => { setPublicationImage(event.target.value) }}
                        name='publicationImage'
                        type="text"
                        fullWidth
                        label={'Publication Image url'}
                    />
                    <StyledAutoComplete
                        styles={{ width: '100%' }}
                        data={countriesList}
                        optionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setCountryId(newValue !== null ? newValue.id : null)
                            setCountryAlphaIso(newValue !== null ? newValue.iso_alpha2 ? newValue.iso_alpha2.toUpperCase() : null : null)

                        }}
                        label={vocabulary.country}


                    />
                    <DatePicker
                        label={vocabulary.date}
                        value={startdate}
                        onChange={setStartDate}
                        animateYearScrolling
                        disableFuture
                        style={{ margin: '8px', width: '100%' }}
                    />
                    <FormControl style={{ marginLeft: '8px', width: '100%' }}>
                        <InputLabel>{vocabulary.type}</InputLabel>
                        <Select
                            value={statusLabel}
                            onChange={(event) => {
                                setStatusLabel(event.target.value)
                                setColor(statusType.find(el => el.id == event.target.value).color)
                            }}
                            name="color"
                        >
                            {statusType.map((item, index) =>
                                <MenuItem
                                    style={{ color: statusType[index].color, fontSize: 'large' }}
                                    key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            )}
                        </Select>
                        {/* {file &&
                            <ul style={{ margin: '20px', listStyleType: 'none', lineHeight: '4' }}>
                                <li key={file.path}>
                                    {vocabulary.fileName} : {file.path}

                                </li>
                                <li>
                                    {vocabulary.fileSize} : {file.size}   {vocabulary.bytesLabel}
                                </li>
                            </ul>
                            } */}
                    </FormControl>
                    {/* <div {...getRootProps({ className: 'dropzone' })} style={{ margin: '10px', border: '3px dashed #01232d ', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                        <input {...getInputProps()} />
                        <div style={{ display: 'flex', alignItems: 'center', width: '70%', cursor: 'pointer' }}>
                            <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                                <img src={upload} alt='upload' />
                            </div>
                            <div style={{ fontSize: 'large', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {`Drag 'and' drop  a Picture here, or click to select one`}
                            </div>
                        </div>
                    </div> */}

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={JSON.parse(item.title).title_ar ? JSON.parse(item.title)['title_' + currentLanguage] : JSON.parse(item.title).title_en}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdatePublication();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default PublicationAddForm

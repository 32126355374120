/* eslint-disable  dot-location */
/* eslint-disable eqeqeq*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { setCurrentPage } from '../../../actions';
import AlertDialog from "../../Components/alertDialog";
import IrrigationTypeManagement from './IrrigationTypeListe'
import IrrigationFamilyForm from './IrrigationFamilyAdd'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledMaterialTable } from '../../../ui/display';
import { GetIrrigationFamilyList, DeleteIrrigationFamily } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const IrrigationFamilyList = (props) => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [irrigationFamilyList, setIrrigationFamilyList] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userIrrigationManagement));
        GetIrrigationFamilyList(setIrrigationFamilyList)
        document.title = vocabulary.userIrrigationManagement
    }, [dispatch]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.userIrrigationManagement));
        document.title = vocabulary.userIrrigationManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)['name_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetIrrigationFamilyList(setIrrigationFamilyList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteIrrigationFamily = (item) => {

        DeleteIrrigationFamily(item.id
            , r => operationCallBack(r)
        )
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <>
                {(!isLoading && irrigationFamilyList) ?
                    <StyledMaterialTable
                        title={vocabulary.irrigationFamily}
                        data={irrigationFamilyList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: ({ rowData }) => { return (<IrrigationTypeManagement itemRow={rowData} />) }
                            }]}

                        columns={[
                            { title: vocabulary.name_label, field: 'name', render: rowData => <>{JSON.parse(rowData.name)['name_' + currentLanguage]}</> },
                            { title: vocabulary.description, field: 'name', render: rowData => <>{JSON.parse(rowData.description).description_fr}</> },

                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editIrrigationFamily,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                                //disabled: row.id < 2000
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addIrrigationFamily,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            detailPanelType: 'single',
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],

                        }}
                    /> : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item.name)["name_" + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteIrrigationFamily(item);
                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                { (openEdit == true || openAdd == true) &&
                    <IrrigationFamilyForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetIrrigationFamilyList(setIrrigationFamilyList)}
                        setIsLoading={setIsLoading}
                    />
                }

            </>
        </ModalContext.Provider>
    )
}
export default IrrigationFamilyList
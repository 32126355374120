import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip } from '@material-ui/core/';
// import { Add, Done } from '@material-ui/icons/';
import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';

// import { withStyles } from '@material-ui/core/styles';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import { setCurrentPage } from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';

import AlertIcon from '@material-ui/icons/NotificationsActive';
import AdviceIcon from '@material-ui/icons/VpnKey';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import AddAdviceNotif from './AddAdviceNotif'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { GetAllAdvices, GetAgentNotifications } from "../../globalsTools/network";
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { stylesThemed } from '../globalStyle';

const Advices = (props) => {

  const dispatch = useDispatch()

  const userType = useSelector(state => state.app.userrights)
  const currentLanguage = useSelector(state => state._globalState.preferred_language)

  const [open, setOpen] = useState(false);
  const [advicesList, setAdvicesList] = useState(null);
  const [agentNotification, setAgentNotification] = useState(null);

  useEffect(() => {

    dispatch(setCurrentPage(vocabulary.AdvicesService));
    document.title = vocabulary.AdvicesService

    if (userType && userType === 'Agent') {
      GetAllAdvices(setAdvicesList)
    } else {
      GetAgentNotifications(setAgentNotification);
    }
  }, [dispatch, userType]); // N’exécute l’effet qu'a la première render

  useEffect(() => {
    document.title = vocabulary.AdvicesService
    dispatch(setCurrentPage(vocabulary.AdvicesService));

  }, [currentLanguage, dispatch]) // N’exécute l’effet que si currentLanguage a changé

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  const changeModalStatus = (newStatus) => {
    if (newStatus == null) {
      setOpen(!open)

    } else {
      setOpen(newStatus)
    }
  }

  const classes = stylesThemed();

  const contextValueAdd = { open: open, changeModalStatus: changeModalStatus }

  return (

    <ModalContext.Provider value={contextValueAdd}>


      <StyledCard>
        {(advicesList || agentNotification) ?
          <StyledMaterialTable
            title={vocabulary.AdvicesService}
            data={userType === 'Agent' ? advicesList : agentNotification}
            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

            columns={[
              { title: vocabulary.sendingDate, field: 'created_at', },
              {
                title: vocabulary.status, field: 'status', render: rowData => <>
                  {rowData.status === false ?
                    <Chip
                      //icon={<AdviceIcon className={classes.colorTextChips} />}
                      label="Not sent"
                      color="secondary"
                      className={[classes.chip, classes.colorTextChips]}
                    />
                    :
                    <Chip
                      //icon={<AdviceIcon className={classes.colorTextChips} />}
                      label="Sent"
                      color="primary"
                      className={[classes.chip, classes.colorTextChips]}
                    />
                  }
                </>
              },

              { title: vocabulary.title, field: 'name', },
              {
                title: vocabulary.type, field: 'type', render: rowData => <>
                  {rowData.type === "advice" ?
                    <Chip
                      icon={<AdviceIcon className={classes.colorTextChips} />}
                      label={vocabulary.advice}
                      color="primary"
                      className={[classes.chip, classes.colorTextChips]}
                    /> : null
                  }
                  {rowData.type === "alert" ?
                    <Chip
                      icon={<AlertIcon className={classes.colorTextChips} />}
                      label={vocabulary.alert}
                      color="secondary"
                      className={[classes.chip, classes.colorTextChips]}
                    />
                    : null
                  }
                </>
              },
              { title: vocabulary.others, field: 'users_count', },
              { title: vocabulary.total, field: 'owner_advices_count' },


            ]}
            actions={[


              {
                icon: () => <AddIcon fontSize='large' color='primary' />,
                tooltip: vocabulary.newNotification,
                isFreeAction: true,
                onClick: (event) => handleClickOpen()
              }

            ]}
            options={{
              actionsColumnIndex: -1,
              search: true

            }}
          />
          : <StyledCircularProgress size={50} label={vocabulary.loading} />}


        {open &&
          <AddAdviceNotif
            open={open}
            onClose={() => {
              handleClose()
            }}
            isEditing={false}
            adviceList={() => GetAllAdvices(setAdvicesList)}
            agentNotifList={() => GetAgentNotifications(setAgentNotification)}
          />}

      </StyledCard>
    </ModalContext.Provider>

  );

}
// Advices.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
export default Advices;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { vocabulary } from "../Strings";
import { setCurrentPage } from "../../actions";
import { StyledCard } from "../../ui/layout";
import { data } from './FakeData'
import { List, ListItemText, ListItem } from '@material-ui/core';

import './style.css'


const FaqHelp = () => {
  const dispatch = useDispatch()
  const currentLanguage = useSelector(state => state._globalState.preferred_language)
  const [selectedIndex, setSelectedIndex] = useState(data[0])

  useEffect(() => {
    dispatch(setCurrentPage('FAQ'))
  }, [dispatch])

  useEffect(() => {

    dispatch(setCurrentPage('FAQ'));
    document.title = 'FAQ'

  }, [dispatch, currentLanguage])

  const getSelectedIndexDetails = (item) => {
    setSelectedIndex(item)

  }
  return (
    <StyledCard>
      <div className="faqContainer">
        <div className="faqIndexList">
          {
            data.map((item, index) => (
              <List component="nav" style={{ width: '100%' }}>
                <ListItem className='faqSelectedIndex'  button 
                onClick={() => getSelectedIndexDetails(item, index)}
                selected={item.id === selectedIndex.id}
                >
                  <ListItemText primary={<span style={{fontSize:'18px',fontWeight:'bold'}}>{item.indice}</span> } />
                </ListItem>
              </List>
            ))
          }

        </div>

        <div className='faqIndexDescription'>
          <p className='faqIndexDescriptionDetails'>
            {selectedIndex['description_'+ currentLanguage]}
          </p>
        </div>
      </div>


    </StyledCard>

  );
};

export default FaqHelp;

/* eslint-disable  */
import React, { useState, useRef, useEffect, useContext } from "react";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import EquipmentPortListItem from "./ChildComponents/EquipmentPortItem";

// import { FeatureGroup, Map, Marker, Polygon } from "react-leaflet";
import { vocabulary } from "../Strings";
import Select from "@material-ui/core/Select";
import L from "leaflet";
// import { useSelector, useDispatch } from 'react-redux'
// import { updateStationItem } from '../../actions'
import "./style.css";
import { StyledModal } from "../../ui/layout";
import StyledModalFooter from "../../ui/layout/StyledModalFooter";
import StyledSelect from "../../ui/forms/StyledSelect";
import StyledInput from "../../ui/forms/StyledInput";
import { ModalContext } from "../../ui/layout/contextLayout/ModalContext";
import { useForm } from "react-hook-form";
import {
  GetDeployedDeviceGatewayByAreaId,
  GetseabexCategorieByParentId,
  GetSeabexCategoryModel,
  GetEquipmentsType,
  createStation,
  GetAreaDetails,
  updateStationItem,
} from "../../globalsTools/network";
import { FormControl, InputLabel, Typography, Button } from "@material-ui/core";
import SeabexMap from "../Sites/Map/SeabexMap";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import StyledCircularProgress from "../../ui/display/StyledCircularProgress";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import {
  steps,
  GuideContext,
} from "../../ui/layout/contextLayout/GuideContext";
import { IS_ENABLED } from "../globalConsts";
import AddStationStepper from "./AddStationStepper";
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const AddStationForm = ({
  areas,
  setIsLoading,
  item,
  isEditing,
  seabexCategoriePack,
  handleClickClose,
  open,
  deployedDevicesProps,
  refetchFunc,
}) => {
  const { guide, dispatchGuide } = useContext(GuideContext);

  useEffect(() => {
    setTimeout(() => {
      if (
        steps[guide.initialStep].element === ".addDeployedDeviceModal1" &&
        guide.currentStep === "addDeployedDeviceModal"
      )
        dispatchGuide({ type: IS_ENABLED, payload: true });
    }, 1000);
  }, []);
  function getSteps() {
    return [
      vocabulary.deployedDevices,
      isEditing ? vocabulary.updateStation : vocabulary.addMarker,
      vocabulary.equipmentLabel,
    ];
  }

  // const dispatch = useDispatch()

  const modalContext = useContext(ModalContext);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const [stationName, setStationName] = useState(null);
  const [area_id, setArea_id] = useState(null);
  const [deviceCategory, setDeviceCategory] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [center, setCenter] = useState({
    lat: 36.675419,
    lng: 10.286298,
  });
  const [identifier, setIdentifier] = useState(null);

  const [deviceFamily, setDeviceFamily] = useState("");
  const [deviceModel, setDeviceModel] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [deviceModelFamily, setDeviceModelFamily] = useState(null);
  const [seabexCategoryModel, setSeabexCategoryModel] = useState(null);
  const [equipmentTypeList, setEquipmentTypeList] = useState(null);
  const [parcelsList, setParcelsList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [parcelId, setParcelId] = useState(null);
  const [sectorId, setSectorId] = useState("");

  const [stationGateway, setStationGateway] = useState("");
  // const [deployedDevicesList, setDeployedDevicesList] = useState(null)
  const [portslist, setPortslist] = useState([]);
  const [getwayByAreaList, setGetwayByAreaList] = useState([]);

  const [portListToEdit, setPortListToEdit] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

  const [getRealPosition, setGetRealPosition] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const stepsPage = getSteps();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (item && item.area) {

      let NewPortList = [];
      item &&
        item.area_deployed_device_equipment &&
        item.area_deployed_device_equipment !== []
        ? item.area_deployed_device_equipment.map((el, index) => {
          NewPortList.push({
            port_name: el.port,
            port_type: el.description,
            equipment: el.equipment_id,
          });
        })
        : [];
      setDeviceCategory(item.seabex_categorie_id);
      GetseabexCategorieByParentId(
        item.seabex_categorie_id,
        setDeviceModelFamily
      );

      setDeviceFamily(item.seabexmodel && item.seabexmodel.seabex_category_id);
      GetAreaDetails(
        item.area.parent !== null ? item.area.parent.id : item.area_id,
        setSelectedArea
      );
      setArea_id(
        item.area && item.area.type == "site"
          ? item.area.id
          : item.area.parent.id
      );
      setParcelId(
        item.area && item.area.type == "parcel" ? item.area.id : null
      );
      setSectorId(
        item.area && item.area.type == "sector" ? item.area.id : null
      );
      // selectedAreaInEditCase(item.area)
      setStationName(item.name);
      setDeviceModel(item.seabex_model_id);
      setStationGateway(item.gateway_id);
      setPortListToEdit(NewPortList && NewPortList);
      let cenTer = JSON.parse(item.realposition);
      let coordinations =
        cenTer != null
          ? {
            lat: cenTer.latitude,
            lng: cenTer.longitude,
          }
          : {};
      setZoom(16);
      setCenter(coordinations);
      let DeviceType = item && item.seabexmodel && item.seabexmodel.type && JSON.parse(item && item.seabexmodel.type) && JSON.parse(item.seabexmodel.type).type;
      if (DeviceType)
      {
        DeviceType =
        DeviceType.charAt(0) +
        DeviceType.substring(1, DeviceType.length).toLowerCase();
        GetEquipmentsType(DeviceType, setEquipmentTypeList);

      }

      // setEquipmentTypeList({ data: item.area_deployed_device_equipment })
      GetDeployedDeviceGatewayByAreaId(
        item.area.parent !== null ? item.area.parent.id : item.area_id,
        setGetwayByAreaList
      );
      setGetRealPosition(item.realposition);
      setIdentifier(item.identifier);
    }
  }, [item]); // N’exécute l’effet que si item a changé (edit case)


  useEffect(() => {

    var result = "";
    if (seabexCategoryModel !== null) {
      result = seabexCategoryModel.data.filter((sm) => sm.id == deviceModel);
    }

    setSelectedModel(result[0]);

    if (result[0] && result[0].type !== "GATEWAY") {

      let ports = JSON.parse(result[0].inputs);
      setPortslist(ports);
    }
  }, [deviceModel !== null]);

  useEffect(() => {
    if (areas) {
      // setSelectedArea(areas.data.find(item => item.id === area_id))
      if (isEditing) {
        let devicetype = item.seabexmodel
          ? JSON.parse(item.seabexmodel.type).type
          : "";
        devicetype =
          devicetype.charAt(0) +
          devicetype.substring(1, devicetype.length).toLowerCase();
        setDeviceType(devicetype);
      }
    }
  }, [area_id]);

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (area_id != null) {
      GetDeployedDeviceGatewayByAreaId(area_id, setGetwayByAreaList);
    }
  }, [area_id]); // N’exécute l’effet que si area_id a changé et remplir les gateway relative a l'area selectionner

  useEffect(() => {
    if (selectedArea != null) {
      setSelectedArea(selectedArea);
      categorizeAreas(selectedArea);
    }
  }, [selectedArea]); // N’exécute l’effet que si selectedArea a changé

  const handleChange = (event) => {
    GetAreaDetails(event.target.value, setSelectedArea);
  };
  const categorizeAreas = (areasDetails) => {
    switch (areasDetails.type) {
      case "site":
        setParcelsList(areasDetails["childs"]);
        setArea_id(areasDetails.id);

        break;
      case "parcel":
        setParcelId(areasDetails.id);
        setSectorsList(areasDetails["childs"]);
        setSectorId(null);
        // setArea_id(areasDetails.parent_id)

        break;
      case "sector":
        setSectorId(areasDetails.id);
        // setArea_id(areasDetails.parent.parent_id)
        // setParcelId(areasDetails.parent_id)
        break;

      default:
        return "";
    }
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <StyledSelect
              value={area_id}
              register={register}
              rules={{ required: true }}
              errors={errors.Area}
              onChange={handleChange}
              name="Area"
              label={vocabulary.site}
              styles={{ width: "100%" }}
              data={areas}
              valueField="id"
              labelField="name"
            />
            {parcelsList.length > 0 && area_id && (
              <StyledSelect
                value={parcelId}
                register={register}
                errors={errors.ParcelId}
                onChange={handleChange}
                name="ParcelId"
                label={vocabulary.parcel}
                fullWidth
                data={parcelsList}
                valueField="id"
                labelField="name"
                styles={{ width: "100%" }}
              />
            )}

            {sectorsList.length > 0 && parcelId && (
              <StyledSelect
                value={sectorId}
                register={register}
                errors={errors.sectorId}
                onChange={handleChange}
                name="sectorId"
                label={vocabulary.sector}
                fullWidth
                data={sectorsList}
                valueField="id"
                labelField="name"
                styles={{ width: "100%" }}
              />
            )}
            {area_id && (
              <StyledInput
                register={register}
                rules={{ required: true }}
                errors={errors.stationName}
                name="stationName"
                label={vocabulary.stationName}
                type="text"
                fullWidth
                value={stationName}
                onChange={(event) => {
                  setStationName(event.target.value);
                }}
              />
            )}

            {area_id && (
              <StyledSelect
                value={deviceCategory}
                register={register}
                rules={{ required: true }}
                errors={errors.DeviceCategory}
                onChange={(event) => {
                  setDeviceCategory(event.target.value);
                  GetseabexCategorieByParentId(
                    event.target.value,
                    setDeviceModelFamily
                  );
                }}
                styles={{ width: "100%" }}
                name="DeviceCategory"
                label={vocabulary.deviceCategory}
                fullWidth
                data={seabexCategoriePack.data}
                valueField="id"
                labelField="name"
              />
            )}

            {deviceCategory && deviceModelFamily && (
              <StyledSelect
                value={deviceFamily}
                register={register}
                rules={{ required: true }}
                errors={errors.Device_Family}
                onChange={(event) => {
                  setDeviceFamily(event.target.value);

                  GetSeabexCategoryModel(
                    event.target.value,
                    setSeabexCategoryModel
                  );
                }}
                name="Device_Family"
                label={vocabulary.deviceFamily}
                styles={{ width: "100%" }}
                data={deviceModelFamily.data}
                valueField="id"
                labelField="name"
              />
            )}

            {deviceFamily && seabexCategoryModel && (
              <StyledSelect
                value={deviceModel}
                register={register}
                rules={{ required: true }}
                errors={errors.deviceModel}
                onChange={(event) => {
                  const checkId = (item) => item.id === event.target.value;

                  let index =
                    seabexCategoryModel &&
                    seabexCategoryModel.data.findIndex(checkId);
                  let type = JSON.parse(
                    seabexCategoryModel && seabexCategoryModel.data[index].type
                  ).type;
                  type =
                    type.charAt(0) +
                    type.substring(1, type.length).toLowerCase();
                  setDeviceType(type);
                  GetEquipmentsType(type, setEquipmentTypeList);
                  setDeviceModel(event.target.value);
                }}
                name="deviceModel"
                label={vocabulary.deviceModel}
                styles={{ width: "100%" }}
                data={seabexCategoryModel.data}
                valueField="id"
                labelField="name"
                secondLabelField="seriel_number"
              />
            )}
            <br />
            {deviceModel &&
              deviceType.toString() !== "Gateway" &&
              getwayByAreaList.data &&
              getwayByAreaList.data.length > 0 && (
                <>
                  <FormControl style={{ marginLeft: "8px", width: "100%" }}>
                    <InputLabel>{vocabulary.selectGateway}</InputLabel>
                    <Select
                      value={stationGateway}
                      onChange={(event) => {
                        setStationGateway(event.target.value);
                      }}
                      name="getwayID"
                    >
                      {getwayByAreaList.data.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name ? _.startCase(item.name) : item.identifier}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

            {area_id && (
              <div>
                <StyledInput
                  register={register}
                  rules={{ required: true }}
                  errors={errors.identifier}
                  value={identifier}
                  name="identifier"
                  onChange={(event) => {
                    setIdentifier(event.target.value);
                  }}
                  label={vocabulary.identifier}
                  type="text"
                  fullWidth
                />
              </div>
            )}
          </>
        );
      case 1:
        return (
          <SeabexMap
            editingItem={selectedArea && selectedArea}
            goToSite={true}
            staticMap={true}
            markersDraggable={true}
            selectedAreaId={isEditing ? item && item.id : area_id}
            isEditingStation={isEditing}
            getRealPosition={setGetRealPosition}
            previousPosType={
              isEditing
                ? JSON.parse(item.realposition)
                : deviceType.toUpperCase()
            }
            stationEditAdd={true}
          />
        );
      case 2:
        return (
          <List>

            {equipmentTypeList &&
              equipmentTypeList.data.length > 0 &&
              (isEditing == true ? portListToEdit : portslist).map(
                (port, index) => {
                  return (
                    <EquipmentPortListItem
                      ports={isEditing == true ? portListToEdit : portslist}
                      port={port}
                      index={index}
                      Equipments={equipmentTypeList && equipmentTypeList.data}
                      handlePortChange={handlePortChange}
                      isEditing={isEditing}
                    />
                  );
                }
              )}
          </List>
        );
      default:
        return "Unknown step";
    }
  };
  const operationCallBack = (r) => {
    if (r) {
      if (r.status === 200) {
        setIsLoading(false);
        snackbar(
          isEditing ? item.name + " " + vocabulary.updateSucc : vocabulary.deployedDevices + " " + vocabulary.addedSucc, "success");
        refetchFunc();
      } else {
        snackbar(
          vocabulary.errorOccurred +
          " " +
          vocabulary.serverSideLabel +
          " ,  " +
          vocabulary.tryAgainLabel,
          "error"
        );
        setIsLoading(false);
      }
    }
  };
  const handleAdd = (data, e) => {
    if (isEditing == false) {
      let realPos = {
        type: "Feature",
        properties: {
          name: stationName,
          identifier: identifier,
          type: deviceType,
        },
        geometry: {
          type: "Point",
          coordinates: [
            getRealPosition && getRealPosition.lat,
            getRealPosition && getRealPosition.lng,
          ],
        },
      };
      setIsLoading(true);

      createStation(
        {
          name: stationName,
          identifier: identifier,
          area_id: selectedArea && selectedArea.id,
          realposition: JSON.stringify(realPos),
          seabex_model_id: deviceModel,
          seabex_categorie_id: deviceCategory,
          gateway_id: stationGateway,
          area_deployed_device_equipment: deviceType == 'Gateway' ? null : JSON.stringify(portslist),
          configuration: JSON.stringify(portslist),
        },
        (r) => operationCallBack(r)
      );
      handleClickClose();
    }

  };

  const handleUpdateDevice = () => {

    let realPos = null;
    realPos = JSON.parse(item.realposition);
    delete realPos.geometry.coordinates;
    realPos.geometry.coordinates = [
      getRealPosition && getRealPosition.lat,
      getRealPosition && getRealPosition.lng,
    ];
    updateStationItem(
      {
        id: item.id,
        name: stationName,
        identifier: identifier,
        area_id: parcelId == null ? area_id : parcelId,
        realposition: JSON.stringify(realPos),
        seabex_model_id: deviceModel,
        seabex_categorie_id: deviceCategory,
        gateway_id: stationGateway,
        area_deployed_device_equipment: JSON.stringify(portListToEdit),
        configuration: JSON.stringify(portListToEdit),
      },
      (r) => operationCallBack(r)
    );
    setOpenEditConfirmation(false)
    handleClickClose();

  }

  if (deployedDevicesProps.find((e) => { })) {
    new Promise((resolve) => {
      deployedDevicesProps &&
        deployedDevicesProps
          .find((e) => {
            resolve(
              e.seabexmodel !== null
                ? JSON.parse(e.seabexmodel.type).type == "GATEWAY"
                : null
            );
          })
          .catch((error) => {
          });
    });
  }

  const validForm = (activeStep) => {
    if (
      activeStep == 0
        ? !deviceCategory ||
        !deviceFamily ||
        !deviceModel ||
        !identifier ||
        !area_id ||
        !stationName
        : !getRealPosition
    ) {
      return true;
    } else return false;
  };

  const handlePortChange = (index, field, value) => {
    let ports = portslist.slice();
    let portsEdit = portListToEdit.slice();

    // field == 'equipment' && setEquipmentPrmDataType(equipmentTypeList && equipmentTypeList.data.length > 0 ? equipmentTypeList.data.find(item => item.id == value).prmdatatypes : [])

    isEditing === false
      ? (ports[index][field] = value)
      : (portsEdit[index][field] = value);
    setPortslist(ports);
    setPortListToEdit(portsEdit);
  };

  const actions = [
    {
      title: isEditing === false ? vocabulary.save : vocabulary.editLabel,
      color: "primary",
      position: "end",
      // type: "submit",
      disabled: isEditing ? false : validForm(),
      onClick: () => isEditing == false ? handleAdd() : setOpenEditConfirmation(true)
    },
    {
      title: vocabulary.cancel,
      color: "secondary",
      position: "start",
      onClick: () => modalContext.changeModalStatus(false),
    },
  ];

  const selectedItem = isEditing ? item && item : null;
  const editArea = isEditing
    ? areas && {
      ...areas,
      data: [areas.find((area) => area.id === selectedItem.area_id)],
    }
    : null;
  return (
    <>
      {guide.isEnabled && guide.currentStep === "addDeployedDeviceModal" && (
        <AddStationStepper handleClickClose={handleClickClose} />
      )}
      <StyledModal
        open={open}
        modalContext={modalContext}
        width={700}
        title={
          isEditing === false ? vocabulary.addStation : vocabulary.updateStation
        }
      >
        {(isEditing == true && selectedArea != null) || isEditing == false ? (
          <form onSubmit={handleSubmit(handleAdd)}>
            <div className={classes.root}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {stepsPage.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep === stepsPage.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      {vocabulary.allStepsCompleted}
                    </Typography>
                    <Button onClick={handleReset}>{vocabulary.reset}</Button>
                  </div>
                ) : (
                  <div>
                    <div>{getStepContent(activeStep)}</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="contained"
                        color="secondary"
                        style={{ margin: "5px" }}
                        className={classes.backButton}
                      >
                        <SkipPreviousIcon /> &nbsp; {vocabulary.previous}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ margin: "5px" }}
                        disabled={isEditing ? false : validForm(activeStep)}
                      >
                        {activeStep === stepsPage.length - 1 ? (
                          <DoneOutlineIcon />
                        ) : (
                          <SkipNextIcon />
                        )}{" "}
                        &nbsp;{" "}
                        {activeStep === stepsPage.length - 1
                          ? vocabulary.finishLabel
                          : vocabulary.next}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {activeStep === stepsPage.length && (
              <StyledModalFooter actions={actions} />
            )}
          </form>
        ) : (
          <StyledCircularProgress
            size={50}
            label={vocabulary.loadingDeviceInformation}
          />
        )}
      </StyledModal>
      {openEditConfirmation === true &&
        <AlertEditDialogConfirmation
          operation={vocabulary.editLabel}
          open={openEditConfirmation}
          EditItemName={item.name}
          onClose={() => {
            setOpenEditConfirmation(false);
          }}
          confirmUpdate={() => {
            if (item) {
              handleUpdateDevice();
            }

            setOpenEditConfirmation(false);
          }}

          cancel={() => {
            setOpenEditConfirmation(false);
          }}
        />}
    </>
  );
};
export default AddStationForm;

/* eslint-disable eqeqeq*/

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

// import {Replay } from '@material-ui/icons/';
import { updateGetWayPlan } from '../../actions';
// import AlertDialog from '../Components/alertDialog';
import { vocabulary } from '../Strings';
// import CronRuleForm from './CronTaskRuleForm';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledSelect from "../../ui/forms/StyledSelect";
import { GetIrrigationPlanTask, createIrrigationPlan, updateIrrigationPlan } from '../../globalsTools/network';
import { useSnackbar } from 'notistack';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";

const AutomationPlanForm = ({ siteList, setIsLoading, isEditing, item, onClose, open, refetchFunc }) => {

    const dispatch = useDispatch()
    const form = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const { register,  errors } = useForm()
    const modalContext = useContext(ModalContext)

    const [status, setStatus] = useState('');
    const [area_id, setArea_id] = useState(null);
    const [selectedArea, setSelectedArea] = useState([]);
    const [description, setDescription] = useState(null);
    const [title, setTitle] = useState(null);
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);


    const [cronRulesList, setCronRulesList] = useState(null);



    const STATUS_TYPES = [
        { title: vocabulary.enabled, value: 'Enabled' },
        { title: vocabulary.disabled, value: 'Disabled' },
        { title: vocabulary.accepted, value: 'Accepted' },
        { title: vocabulary.proposed, value: 'Proposed' },
    ]
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {
        if (isEditing === false) {
            setStatus('')
            setArea_id(null)
            setDescription(null)
            setTitle(null)
        }
    }, [open, isEditing]); // N’exécute l’effet que si open a changé

    useEffect(() => {
        if (item) {
            GetIrrigationPlanTask(item.id, setCronRulesList)
            setStatus(item.status)
            setArea_id(item.area_id)
            setTitle(item.title)
            setDescription(item.description)
        }

    }, [item, dispatch]); // N’exécute l’effet que si item a changé

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                setIsLoading(false)
                snackbar(isEditing ? item.title + ' ' + vocabulary.updateSucc : vocabulary.automationPlan + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                if (isEditing === true) {
                    dispatch(updateGetWayPlan(item.id))

                }

            }

            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)

            }
        }
    }

    const handleConfirmePopup = (data) => {

        if (isEditing === false) {
            setIsLoading(true)

            createIrrigationPlan(
                {
                    area_id: area_id,
                    title: title,
                    status: status,
                    description: description
                }, r => operationCallBack(r)
            )
            onClose()
        }

    }

    const handleUpdateAutomationPlan = () => {

        updateIrrigationPlan(
            {
                id: item.id,
                area_id: area_id,
                title: title,
                status: status,
                description: description
            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        onClose()
    }

    const validForm = () => {
        if (!area_id || !title || !status) {
            return true
        }
        else return false

    }

    // const handleClickOpen = () => {
    //     setOpenCronRule(true)
    // };


    // const handleCloseAlert = () => {
    //     setOpenConfirmationDialog(false);
    // };

    // const handleClickOpenAlert = () => {
    //     setOpenConfirmationDialog(true);
    // };

    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleConfirmePopup() : setOpenEditConfirmation(true), disabled: isEditing == false ? validForm() : false },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]


    return (

        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={600}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.AddAutomationPlan : vocabulary.editAutomationPlan}
            >

                <form
                    ref={form}
                    onError={errors => console.log(errors)}>

                    <StyledSelect
                        value={area_id}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Area}
                        onChange={(event) => {
                            setArea_id(event.target.value)
                            setSelectedArea(siteList && siteList.find(item => item.id === event.target.value))
                        }}
                        name='Area'
                        label={vocabulary.site}
                        styles={{ width: '100%' }}
                        data={siteList && siteList}
                        valueField='id'
                        labelField='name'

                    />
                    <StyledInput
                        name="title"
                        register={register}
                        rules={{ required: true }}
                        label={vocabulary.title}
                        errors={errors.Title}
                        type="text"
                        fullWidth
                        value={title}
                        onChange={(event) => {
                            setTitle(event.target.value)
                        }}

                    />

                    <StyledInput
                        name="Description"
                        register={register}
                        label={vocabulary.description}
                        errors={errors.Description}
                        type="text"
                        fullWidth
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value)
                        }}

                    />

                    <StyledSelect
                        value={status}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.status}
                        onChange={(event) => {
                            setStatus(event.target.value)
                        }}
                        name='status'
                        label={vocabulary.status}
                        fullWidth
                        data={STATUS_TYPES}
                        valueField='value'
                        labelField='title'
                        styles={{ width: '100%' }}

                    />

                    <StyledModalFooter actions={actions} />

                </form>


            </StyledModal>

            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.title}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateAutomationPlan();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}



        </>
    )
}

export default AutomationPlanForm


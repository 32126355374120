/* eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import UserAddForm from './UserAddForm';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { setCurrentPage } from '../../../actions';
import { organizationDeleteUser, getOrganizationUsersList, organizationUpdateUser } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { Refresh } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Chip } from '@material-ui/core';
import FollowIcon from '@material-ui/icons/HowToReg';
import UnfollowIcon from '@material-ui/icons/PersonAddDisabled'


const UserListTable = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [partnersList, setUsersList] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)
    let history = useHistory();


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userManagement));
        getOrganizationUsersList(setUsersList)
        document.title = vocabulary.userManagement

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userManagement));
        document.title = vocabulary.userManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

 
    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
        setIsEditing(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }


    const renderPermissions = (permissions) => {
        if (!permissions) {
            return null;
        }
    
        return permissions.map((item) => (
            <Chip key={item.name} label={item.name} />
        ));
    };

    const operationCallBack = (r, row) => {
        if (r) {
            if (r.status === 201) {
                snackbar(isActive == true ? row.name + ' ' + vocabulary.activeLabel : row.name + ' ' + vocabulary.inactiveLabel, 'success')
                getOrganizationUsersList(setUsersList)


            } else {
                snackbar(vocabulary.errorOccurred , 'error');
            }
        }
    }

    const HandleDesactiveUser = (row) => {
        setIsActive(false)
        organizationUpdateUser(
            {
                id: row.id,
                active: false
            }
            , r => operationCallBack(r, row)
        )

    }

    const handleActiveUser = (row) => {
        setIsActive(true)

        organizationUpdateUser({
            id: row.id,
            active: true
        }
            , r => operationCallBack(r, row)
        )

    }




    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {partnersList ?
                    <StyledMaterialTable
                        title={vocabulary.userManagement}
                        data={partnersList}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[
                            { title: vocabulary.firstname, field: 'firstname' },
                            { title: vocabulary.lastname, field: 'lastname' },
                            { title: vocabulary.email, field: 'email' },
                            { title: vocabulary.address, field: 'permissions', render: rowData => <>{renderPermissions(rowData?.permissions)}</> },
                            { title: vocabulary.type, field: 'type' },
                            { title: vocabulary.activeLabel, field: 'active', render: rowData => <>{rowData.active == false ? vocabulary.inactiveLabel : vocabulary.activeLabel}</> },
                            { title: vocabulary.created_at, field: 'created_at', render: rowData => moment(rowData.created_at).format('YYYY-MM-DD HH:mm') },
                            { title: vocabulary.lastConnectionLabel, field: 'last_connection', render: rowData => <>{rowData.last_connection !== null ? moment(rowData.last_connection).format('YYYY-MM-DD HH:mm') : vocabulary.notConnectedYet}</> },
                        ]}
                        actions={[
                            row => ({
                                icon: () => row.active === false ? <FollowIcon /> : <UnfollowIcon />,
                                tooltip: row.active === true ? vocabulary.inactiveLabel : vocabulary.activeLabel,
                                disabled: row.type === "Super",
                                onClick: (event, row) => row.active === false ? handleActiveUser(row) : HandleDesactiveUser(row),
                            }),
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.Delete,
                                disabled: row.type === "Super",
                                onClick: (event, row) => openAlertConfirmDelete(row),
                            }),
                            row => ({
                                icon: 'edit_outline',
                                tooltip: vocabulary.EditUser,
                                disabled: row.type === "Super",
                                onClick: (event, row) => onEdit(row)
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addNewUser,
                                isFreeAction: true,
                                onClick: () => handleClickOpen()
                            },
                            {
                                icon: () => <Refresh fontSize='large' color='primary' />,
                                tooltip: vocabulary.refreshData,
                                isFreeAction: true,
                                onClick: () => getOrganizationUsersList(setUsersList)
                            }
                   

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80],
                            exportMenu: [{
                                label: 'Export PDF',
                                exportFunc: (cols, datas) => ExportPdf(cols, datas, "Users List")
                            }, {
                                label: 'Export CSV',
                                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Users List')
                            }]

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.firstname}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                organizationDeleteUser(item.id).then(() => getOrganizationUsersList(setUsersList));

                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}


                {(openAdd == true || openEdit == true) &&
                    <UserAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => getOrganizationUsersList(setUsersList)}
                    />}
            </StyledCard>
        </ModalContext.Provider>

    )
}
export default UserListTable

/* eslint-disable no-unused-vars*/

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormControl, Select, InputLabel } from '@material-ui/core/';
import { vocabulary } from '../Strings';
import {
    ValidatorForm,
    // TextValidator, SelectValidator 
} from 'react-material-ui-form-validator';
import { laodStationDataTypes } from '../../actions';
import { MenuItem } from '@material-ui/core/';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledSelect from "../../ui/forms/StyledSelect";
import { LogicalOperator } from './LogicalOperator';

var isExisty = function isExisty(value) {
    return value !== null && value !== undefined;
};

var _isEmpty = function _isEmpty(value) {
    if (value instanceof Array) {
        return value.length === 0;
    }
    return value === '' || !isExisty(value);
};

function matchRegexp(value, regexp) {
    var validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);
    return _isEmpty(value) || validationRegexp.test(value);
}


function ConditionForm(props) {


    const dispatch = useDispatch()
    const form = useRef(null);

    const { register,  errors } = useForm()
    const modalContext = useContext(ModalContext)

    const stationDataType = useSelector(state => state.dataType.data)
    //  const deployedDevices = useSelector(state => state.station.data.filter(station => (station.seabexstation ? station.seabexstation.type === 'ACQUISITION' : false)))
    const deployedDevices = useSelector(state => state.station.data.data.filter(station => (station.seabexmodel ? JSON.parse(station.seabexmodel.type).type === 'ACQUISITION' : false)))

    const [Sensor, setSensor] = useState('')
    const [sensorType, setSensorType] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [Operator, setOperator] = useState('')
    const [Value, setValue] = useState(0)
    const [modelsArray, setModelsArray] = useState([])
    const [selectedAckNodeIndex, setSelectedAckNodeIndex] = useState('')
    const [identifier, setIdentifier] = useState('')
    const [deviceId, setDeviceId] = useState('')



    useEffect(() => {
        if (props.open === true) {
            setSensor('')
            setSensorType('')
            setOperator('')
            setValue(0)
            setModelsArray([])
            setIdentifier('')

        }

        setIsEditing(props.isEditing)
        ValidatorForm.addValidationRule('idConditionValidator', (value) => {
            return matchRegexp(value, /^[0-9a-zA-Z]*$/);
        });

    }, [props.open, props]); // N’exécute l’effet que si count a changé

    const confirmAddForm = () => {

        props.addCondition({
            // DeviceID: deployedDevices[selectedAckNodeIndex].identifier,
            DeviceID: deviceId,

            
            Sensor: Sensor,
            Variable: sensorType[0],
            // Pin: "A0",     
            Operator: Operator,
            Value: Value,
            identifier: identifier,
        })

        props.onClose()
    }

    const actions = [
        { title: vocabulary.add, color: "primary", position: 'end', type: "submit" ,onClick:()=>confirmAddForm() },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (

        <div>


            <StyledModal
                open={props.open}
                onClose={props.onClose}
                modalContext={modalContext}
                width={props.width}
                title={vocabulary.add + " " + vocabulary.conditions}
            >

                <form
                    ref={form}
                   // onSubmit={handleSubmit(confirmAddForm)}
                    onError={errors => console.log(errors)}>
                    <StyledInput
                        name="Identifier"
                        label={vocabulary.identifier}
                        value={identifier}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Identifier}
                        onChange={(event) => {
                            setIdentifier(event.target.value)
                        }}
                        type="text"
                        fullWidth
                    />

                    <StyledSelect
                        value={deviceId}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.selectedActionType}
                        onChange={(event, index) => {
                            setSelectedAckNodeIndex(index.key)
                            setDeviceId(event.target.value)
                            dispatch(laodStationDataTypes(event.target.value))

                        }}
                        name='selectedAckNodeIndex'
                        label={vocabulary.selectStation}
                        fullWidth
                        data={deployedDevices}
                        valueField='id'
                        styles={{ width: '100%' }}
                        labelField='name'

                    />

                    {(deviceId && stationDataType) ?
                        <FormControl required
                            style={{ width: '100%', margin: '8px' }} >
                            <InputLabel id="demo-simple-select-outlined-label">{vocabulary.sensorType}</InputLabel>

                            <Select
                                value={sensorType}
                                onChange={(event) => {
                                    setSensorType(event.target.value)
                                    setModelsArray(stationDataType.filter(i => JSON.parse(i.type).name_fr === event.target.value))

                                }}
                                name='sensorType'
                                label={vocabulary.sensorType + " *"}

                            >
                                {stationDataType.map((item, index) =>
                                    <MenuItem key={index} value={JSON.parse(item.type).name_fr}>{JSON.parse(item.type).name_fr}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        : null
                    }
                    {(sensorType && stationDataType) ?
                        <FormControl required
                            variant="outlined"
                            style={{ width: '100%', margin: '8px' }}>
                            <InputLabel id="demo-simple-select-outlined-label">{vocabulary.sensor}</InputLabel>
                            <Select
                                value={Sensor}
                                onChange={(event) => {
                                    setSensor(event.target.value)

                                }}
                                name='Sensor'
                                label='Sensor'

                            >
                                {modelsArray.map((item, index) =>
                                    <MenuItem key={index} value={JSON.parse(item.type).name_fr}>{JSON.parse(item.type).name_fr} ({vocabulary.pin} : {item.pin})</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        : null
                    }



                    <StyledInput
                        name="Value"
                        label={vocabulary.value}
                        value={Value}
                        onChange={(event) => {
                            setValue(event.target.value)

                        }}
                        type="text"
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Value}
                        fullWidth
                    />
                    <StyledSelect
                        value={Operator}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Operator}
                        onChange={(event) => {
                            setOperator(event.target.value)

                        }}
                        name='Operator'
                        label={vocabulary.operator}
                        fullWidth
                        data={LogicalOperator.condition}
                        valueField='value'
                        labelField='title'
                        selectValue={Value}
                        styles={{ width: '100%' }}

                    />
                   

                    <StyledModalFooter actions={actions} />

                </form>


            </StyledModal>

        </div>
    )

}

export default ConditionForm;

import { GetMeteoData, getPlatformAlert } from "../globalsTools/network";
import { UPDATE_RECALCULATING_CYCLES, GET_WEATHER_DATA, REMOVE_RECALCULATING_CYCLE, TOGGLE_ALERT } from "./actionTypes";

export const updateRecalculatingCycles = (payload) => {
    return {
        type: UPDATE_RECALCULATING_CYCLES,
        payload
    }
};

export const removeRecalculatingCycle = (payload) => {
    return {
        type: REMOVE_RECALCULATING_CYCLE,
        payload
    }
};

export const fetchMeteoData = (areaId) => {
    return async (dispatch) => {
        return GetMeteoData(
            areaId,
            payload => dispatch({ type: GET_WEATHER_DATA, payload })
        )
    };
};


// export const updateAlert = () => {
//     return async (dispatch) => {
//         return getPlatformAlert(
//             payload => dispatch({
//                 type: TOGGLE_ALERT,
//                 payload: payload.message,
//                 etat: payload.show
//             })
//         )
//     };
// };
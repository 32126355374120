/* eslint-disable eqeqeq*/
/* eslint-disable  no-useless-concat */

import React from 'react';
import { useSelector } from 'react-redux';

import TodayIcon from '@material-ui/icons/Today';

import pressure from '../../images/pressure.png'

import { vocabulary } from '../Strings';
import InputLabel from '@material-ui/core/InputLabel';
import "react-circular-progressbar/dist/styles.css";
import iconDewPoint from '../../images/dewpoint.png'
import { StyledCard } from "../../ui/layout";
import evaporationBlue from '../../images/evaporationBlue.png';
import agriculture from '../../images/agriculture.png';
import agendaBlue from '../../images/agendaBlue.png';
import phaseBlue from '../../images/phaseBlue.png';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';


const WaterNeedOnDemandCard = ({ waterNeedOnDemandData }) => {

    const moment = require('moment');
    const currentLanguage = useSelector(state => state._globalState.preferred_language)




    function windSpeed(speed) {
        if (speed < 2)
            return {
                name_ar: "هادئ",
                name_en: "Calm",
                name_fr: "Calme"
            }
        else if (speed >= 2 && speed < 4)
            return {
                name_ar: "هادئ",
                name_en: "Light Air",
                name_fr: "Air léger"
            }

        else if (speed >= 4 && speed < 8)
            return {
                name_ar: "نسيم عليل",
                name_en: "Light Breeze",
                name_fr: "Air léger"
            }
        else if (speed >= 8 && speed < 13)
            return {
                name_ar: "نسيم لطيف",
                name_en: "Gentle Breeze",
                name_fr: "Petite brise"
            }

        else if (speed >= 13 && speed < 18)
            return {
                name_ar: "مناخ معتدل ",
                name_en: "Moderate climate",
                name_fr: "Climat tempéré "
            }
        else if (speed >= 18 && speed < 25)

            return {
                name_ar: "هواء نقي ",
                name_en: "Fresh Breeze",
                name_fr: " Brise fraîche"
            }

        else if (speed >= 25 && speed < 31)
            return {
                name_ar: "نسيم قوي",
                name_en: "Strong Breeze",
                name_fr: " Brise forte"
            }
        else if (speed >= 31 && speed < 39)
            return {
                name_ar: "بالقرب من العاصفة",
                name_en: "Near Gale",
                name_fr: "Près de Gale"
            }
        else
            return {
                name_ar: "هادئ",
                name_en: "Calm",
                name_fr: "Calme"
            }
    }


    const dailyWeather = waterNeedOnDemandData && waterNeedOnDemandData.meteo

    return (
        <StyledCard
            width={'100%'}
            marginTop={'20px'}>
            {waterNeedOnDemandData ?

                <div className="weatherDay d-flex" style={{ height: '600px', width: 'inherit' }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 left-blockWaterNeed" >
                        <div className="meteoMainDetail">

                            <span className="meteoMainInput"><TodayIcon /> {moment.unix(dailyWeather.daily.time).locale(currentLanguage).format('LL')}</span>
                            <span className="meteoMainInput"> {vocabulary.timeZone + ' : ' + dailyWeather.timezone}</span>
                            <span className="meteoMainInput">{vocabulary.temperature + ' :' + Number(dailyWeather.average.temperature).toFixed() + '°C'}</span>
                            <span className="meteoMainInput">{vocabulary.windSpeed + " :" + Number(dailyWeather && dailyWeather.average.wind_speed).toFixed() + vocabulary.windSpeedUnit + "(" + windSpeed(dailyWeather && dailyWeather.average.wind_speed)["name_" + currentLanguage] + ")"}</span>
                            <span className="meteoMainInput">{vocabulary.totalPrecipitation + " :" + Number(dailyWeather.total_precipitation).toFixed(2)}</span>
                            <span className="meteoMainInput">{vocabulary.humidity + ": " + Number((dailyWeather.average.humidity)*100).toFixed() + ' %'}</span>


                        </div>
                    </div>
                    <div className="col-lg-9  col-md-9 col-sm-9 right-block d-flex flex-column " style={{ height: '600px', justifyContent: 'space-around', background: '#F0F8FF' }}>
                        <div className="meteoChart" style={{ height: '280px', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', alignItems: 'center' }}>
                            <div className="meteoDetailCard">
                                <span className="meteoMainInputLabel"> {vocabulary.Culture_Name} </span>
                                <div className="firstSectionDetails">
                                    <InputLabel>  {vocabulary.cultureFamily + " :" + " " + JSON.parse(waterNeedOnDemandData.culture.prm_culture_family.name)["name_" + currentLanguage]}</InputLabel>

                                    <InputLabel margin={'dense'}>  {vocabulary.culture + " :" + JSON.parse(waterNeedOnDemandData.culture.name)["name_" + currentLanguage]}</InputLabel>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><img src={agriculture} alt="evaporationimg" className="secondSectionImg" /></div>

                                </div>

                            </div>
                            <div className="meteoDetailCard">

                                <span className="meteoMainInputLabel">{vocabulary.phase + "&" + vocabulary.kc}</span>
                                <div className="firstSectionDetails">
                                    {waterNeedOnDemandData.WaterNeed.phase && <InputLabel > {vocabulary.phase + " " + ' :' + ' ' + vocabulary[waterNeedOnDemandData.WaterNeed.phase]}</InputLabel>}
                                    <InputLabel margin={'dense'}>{vocabulary.kc + " " + ": " + " " + waterNeedOnDemandData.WaterNeed.kc}</InputLabel>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><img src={phaseBlue} alt="evaporationimg" className="secondSectionImg" /></div>

                                </div>


                            </div>

                            <div className="meteoDetailCard">
                                <span className="meteoMainInputLabel">{vocabulary.evapotranspiration}</span>
                                <div className="firstSectionDetails">
                                    <InputLabel>  {vocabulary.evapotranspiration + ":" + "  " + Number(waterNeedOnDemandData.eto.eto).toFixed(2)}</InputLabel>

                                    <InputLabel style={{ marginTop: '20px' }}>  {vocabulary.actualEvapotranspiration + ":" + "  " + Number(waterNeedOnDemandData.WaterNeed.needs).toFixed(2)}</InputLabel>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><img src={evaporationBlue} alt="evaporationimg" className="secondSectionImg" /></div>
                                </div>

                            </div>
                        </div>
                        <div className="meteoData" style={{ height: '280px', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', alignItems: 'center' }}>
                            <div className="meteoDetailCard">
                                <span className="meteoMainInputLabel">{vocabulary.cycleDays}</span>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '150px', alignItems: 'center', justifyContent: 'space-around' }} >
                                    <img src={agendaBlue} alt="evaporationimg" className="secondSectionImg" />

                                    <InputLabel> {vocabulary.dayLabel + ":" + "  " + waterNeedOnDemandData.WaterNeed.day}</InputLabel>

                                </div>

                            </div>
                            <div className="meteoDetailCard">
                                <span className="meteoMainInputLabel">{vocabulary.dewPoint}</span>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '150px', alignItems: 'center', justifyContent: 'space-around' }}>
                                    <img alt='errorpic' className="secondSectionImg" src={iconDewPoint} />
                                    <InputLabel> {waterNeedOnDemandData.eto.params.dew_point}  °C</InputLabel>
                                </div>


                            </div>
                            <div className="meteoDetailCard">
                                <span className="meteoMainInputLabel">{vocabulary.pressureLabel}</span>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '150px', alignItems: 'center', justifyContent: 'space-around' }}>
                                    <img alt='errorpic' className="secondSectionImg" src={pressure} />
                                    <InputLabel> {Number(waterNeedOnDemandData.eto.params.atmospheric_pressure).toFixed(2)}  kPa</InputLabel>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
        :<StyledCircularProgress size={50} label={vocabulary.loading} />    
        }
        </StyledCard>
    )
}
export default WaterNeedOnDemandCard
/* eslint-disable no-useless-escape*/
/* eslint-disable no-unused-vars*/

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { loadCitiesByIdCountries, displayWarning } from '../../../actions';


import { Typography, Grid } from "@material-ui/core/";
import { ModalContext } from '../../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledInput from "../../../../ui/forms/StyledInput";
import { addProduct, GetAllSeabexPack, GetUsersList, UpdateProduct } from "../../../../globalsTools/network";
import { StyledModal } from '../../../../ui/layout'
import StyledModalFooter from '../../../../ui/layout/StyledModalFooter';
import { vocabulary } from '../../../Strings';
import StyledAutoComplete from '../../../../ui/forms/StyledAutoComplete';


const ProductAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const { enqueueSnackbar } = useSnackbar();


    const [packList, setPackList] = useState(null)
    const [currentItem, setCurrentItem] = useState({
        source_id : '',
        label : '',
        description : '',
        currency : 'EUR',
        price : '',
        reference : 'piece',
        unit : '',
        vat_rate : 'FR_200'
    })

    useEffect(() => {


        GetAllSeabexPack(setPackList);

    }, [setPackList])

    useEffect(() => {
        if (item) {
            setCurrentItem(item)
        }
    }, [item, isEditing]);

    const onChange = (event) => {

        setCurrentItem({
            ...currentItem,
            [event.target.name]: event.target.value
        });
    }


    const renderPackName = (name) => {
        try {
            return JSON.parse(name)[currentLanguage]
        } catch (e) {
            return "N/A"
        }
    }
    const handleResponse = (response, success, status) => {
        if (response) {
            if (success) {

                //snackbarMsg(vocabulary.emailCheckAccount, 'warning');
                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response.response.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }
    const handleFormSubmit = (values) => {

        if (isEditing) {
            UpdateProduct(currentItem.source_id, {
                source_id: currentItem.source_id,
                ...values
            }, handleResponse)
        } else {
            addProduct({
                source_id: currentItem.source_id,
                ...values
            }, handleResponse)
        }
    }

    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewUser : vocabulary.EditUser}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>

                    {/* <StyledInfoBubble type='info'>{vocabulary.invitationVoucherWarning}</StyledInfoBubble> */}

                    <form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        style={{ ...formStyle }}>


                        <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillTextAddUser}</Typography>
                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            {
                                packList && <Grid item xs style={{ display: 'flex' }}>

                                    <StyledAutoComplete
                                        data={packList?.data ?? []}
                                        defaultValue={isEditing ? packList.data[item?.source_id] : null}
                                        optionLabel={(option) => renderPackName(option.name)}
                                        onChange={(event, newValue) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                source_id: newValue.id
                                            })
                                        }}
                                        label={vocabulary.seabexOffers}
                                        valueField="id"
                                        labelField="name"
                                        styles={{ width: `100%` }}
                                    />
                                </Grid>
                            }

                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="label"
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.label}
                                    label={vocabulary.name}
                                    value={currentItem?.label}
                                    onChange={onChange}
                                    type="text"
                                    styles={{ width: `100%` }}
                                />
                            </Grid>

                        </Grid>

                        <StyledInput
                            register={register}
                            rules={{ required: false }}
                            name="description"
                            label={vocabulary.description}
                            type="text"
                            value={currentItem?.description}
                            onChange={onChange}
                            errors={errors.description}
                            disableAutoComplete={true}
                        />

                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput

                                    errors={errors.price}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="price"
                                    value={currentItem?.price}
                                    onChange={onChange}
                                    label={vocabulary.price}
                                    type="text"
                                    fullWidth

                                />

                            </Grid>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput

                                    errors={errors.unit}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="unit"
                                    value={currentItem?.unit}
                                    onChange={onChange}
                                    label={vocabulary.defaultUnit}
                                    type="text"
                                    fullWidth

                                />

                            </Grid>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    errors={errors.vat_rate}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="vat_rate"
                                    value={currentItem?.vat_rate}
                                    onChange={onChange}
                                    label={vocabulary.tva}

                                    type="text"
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    errors={errors.currency}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="currency"
                                    value={currentItem?.currency}
                                    onChange={onChange}
                                    label={vocabulary.currency}
                                    type="text"
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    errors={errors.reference}
                                    register={register}
                                    rules={{ required: false }}
                                    style={{ marginTop: "-7px" }}
                                    name="reference"
                                    value={currentItem?.reference}
                                    onChange={onChange}
                                    label={vocabulary.reference}
                                    type="text"
                                    fullWidth

                                />
                            </Grid>
                        </Grid>


                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}
export default ProductAddForm
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentPage } from '../../../actions';
import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import EquipmentFamilyAddForm from './EquipmentFamilyAdd'
import EquipmentsTypeList from './EquipmentsTypeList'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledMaterialTable } from '../../../ui/display';
import { DeleteEquipmentFamily, GetEquipmentFamilyList } from '../../../globalsTools/network';
import { StyledCard } from "../../../ui/layout";
import AddIcon from '@material-ui/icons/AddCircleOutline';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';

const EquipmentFamilyManagement = () => {


    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [equipmentFamilyList, setEquipmentFamilyList] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const equipmentType = [{ name: 'Acquisition', id: '1' }, { name: 'Action', id: '2' }]

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userEquipmentManagment));
        document.title = vocabulary.userEquipmentManagment

        GetEquipmentFamilyList(setEquipmentFamilyList)

    }, [dispatch]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.userEquipmentManagment));
        document.title = vocabulary.userEquipmentManagment

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {
                    !isLoading ?
                        <StyledMaterialTable
                            title={vocabulary.equipmentType}
                            data={equipmentType}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                            detailPanel={[
                                {
                                    tooltip: 'Show Details',
                                    render: ({ rowData }) => { return (<EquipmentsTypeList item={rowData} />) }
                                }]}

                            columns={[
                                { title: vocabulary.type, field: 'name' },
                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editEquipmentType,
                                    onClick: (event, row) => onEdit(row)
                                },
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),

                                    //disabled: row.id < 2000
                                }),
                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.addEquipmentType,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpen()
                                }

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                detailPanelType: 'single'

                            }}
                        />
                        : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }



                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                DeleteEquipmentFamily(item.id);
                            }


                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                {(openAdd == true || openEdit == true) &&
                    <EquipmentFamilyAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        setIsLoading={setIsLoading}


                    />
                }
            </StyledCard>
        </ModalContext.Provider>

    )

}
export default EquipmentFamilyManagement
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { startImpersonating } from '../../../actions';
import { impersonateOrganizationTake } from '../../../globalsTools/network';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { vocabulary } from '../../Strings';
import Banner from './Banner';
import moment from 'moment';


function Impersonate({ children }) {

    const impersonate = useSelector(state => state.app.impersonate)

    const dispatch = useDispatch()

    let location = useLocation();

    const data = location.state


    const operationCallBack = (r, success) => {
        if (success) {
            const payload = { 
                token: r.token, 
                user: data.user, 
                expires_at : moment(r.expires_at)
            }
            dispatch(startImpersonating(payload))
        }
    }

    useEffect(() => {
        if (data && data.user) {
            impersonateOrganizationTake(data?.user.id, operationCallBack)
        }
    }, [location,data])

    return (

        impersonate && impersonate.user && impersonate.token ? (
            <>
                <Banner />
                {children}
            </>
        ) :
            (
                <StyledCircularProgress
                    size={50}
                    label={vocabulary.loading}
                />
            )

    )
}

export default Impersonate
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../images/upload.png";
import { StyledModal } from "../../ui/layout";
import { vocabulary } from "../Strings";
import AddArea from "./AddArea";
import { useSnackbar } from 'notistack';

const AddAreaFileModal = ({ open, onClose, refetchFunc }) => {

  const { enqueueSnackbar } = useSnackbar();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [callAddArea, setCallAddArea] = useState(false);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (acceptedFiles != null) {
      acceptedFiles.map((file, index) => {
        if (file.type === '' && file.name.indexOf(".kml") !== -1 || file.type.indexOf("kml") !== -1) {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = () => {
            const binaryStr = reader.result;
            var XMLParser = require("react-xml-parser");
            var xml = new XMLParser().parseFromString(binaryStr);
            let coordinates = null
            try {
              coordinates = xml
                .getElementsByTagName("coordinates")[0]
                .value.replaceAll(",0", "")
                .split(" ")
            } catch (e) {
              snackbar(vocabulary.wrongFileContent, 'error')
            }
            if (coordinates !== null) {
              let formatCoords = [];
              for (const item of coordinates) {
                formatCoords.push(JSON.parse("[" + item + "]").reverse());
              }
              const geojson = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: [formatCoords],
                },
              };
              setMap(geojson);
              setCallAddArea(true);
            };
          }
        }
        else if (file.type === '' && (file.name.indexOf(".geojson") !== -1 || file.name.indexOf(".json") !== -1) || file.type.indexOf("json") !== -1 || file.type.indexOf("geojson") !== -1) {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = () => {
            const binaryStr = reader.result;
            let coordinates = null
            try {
              coordinates = JSON.parse(binaryStr).boundary.geometry.coordinates[0]
            } catch (e) {
              snackbar(vocabulary.wrongFileContent, 'error')
            }
            if (coordinates !== null) {
              let formatCoords = []
              for (const item of coordinates) {
                formatCoords.push(item.reverse());
              }
              const geojson = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: [formatCoords],
                },
              };
              setMap(geojson);
              setCallAddArea(true);
            }
          }
        }
        else {
          snackbar(vocabulary.wrongChoosenFile, 'error')

        }
      });
    }
  }, [acceptedFiles]);

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  return (
    <>
      {callAddArea ? (
        <AddArea
          width={745}
          closeFn={() => {
            return onClose(false);
          }}
          withoutContext={true}
          item={null}
          isEditing={false}
          refetchFunc={refetchFunc}
          parentArea={undefined}
          initialMap={map}
        />
      ) : (
        <StyledModal
          title={vocabulary.addAreaUsingKmlFile}
          withoutContext={true}
          open={open}
          closeFn={onClose}
        >
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              margin: "10px",
              border: "3px dashed ",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "70%",
                cursor: "pointer",
              }}
            >
              <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                <img src={upload} alt="upload" />
              </div>
              <div
                style={{
                  fontSize: "large",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {vocabulary.dragDropKmlFileLabel}
              </div>
            </div>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default AddAreaFileModal;

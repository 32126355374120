/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';

import AlertDialog from "../../Components/alertDialog";
import IrrigationTypeForm from './IrrigationTypeAdd'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { GetIrrigationTypeList, DeleteIrrigationType } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress'; 

const IrrigationTypeManagement = ({ itemRow }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [irrigationTypeList, setIrrigationTypeList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (itemRow) {
            GetIrrigationTypeList(itemRow.id, setIrrigationTypeList)
        }

    }, [itemRow])

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(false)

    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)['name_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetIrrigationTypeList(item.prm_irrigation_family_id, setIrrigationTypeList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteIrrigationType = (item) => {

        DeleteIrrigationType(item.id
            , r => operationCallBack(r)
        )
    }
    const openAddSoilType = () => {
        setOpenType(true)
        setIsEditing(false)
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenType(!openType)
            setOpenEdit(!openEdit)
        } else {
            setOpenType(newStatus)
            setOpenEdit(newStatus)
        }
    }

    const contextValue = { open: openType, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            {(!isLoading && irrigationTypeList) ?
                <StyledMaterialTable
                    title={vocabulary.irrigationFamily + ': ' + JSON.parse(itemRow.name)['name_' + currentLanguage]}
                    data={irrigationTypeList}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}
                    columns={[
                        { title: vocabulary.name_en, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name_en}</> },
                        { title: vocabulary.name_fr, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name_fr}</> },
                        { title: vocabulary.name_ar, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name_ar}</> },
                        { title: vocabulary.efficiency, field: 'efficiency' },
                        { title: vocabulary.description, field: 'description', render: rowData => <>{JSON.parse(rowData.description).description}</> },
                    ]}
                    actions={[
                        {
                            icon: 'edit_outline',
                            tooltip: vocabulary.editIrrigationType,
                            onClick: (event, row) => onEdit(row)
                        },
                        row => ({
                            icon: 'delete_outline',
                            tooltip: vocabulary.delete,
                            onClick: (event, row) => openAlertConfirmDelete(row),

                            disabled: row.id < 2000
                        }),
                        {
                            icon: () => <AddIcon fontSize='large' color='primary' />,
                            tooltip: vocabulary.addIrrigationType,
                            isFreeAction: true,
                            onClick: (event) => openAddSoilType()
                        }

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        pagging: false
                    }}
                />
                : <StyledCircularProgress size={50} label={vocabulary.loading} />
            }

            {
                alertConfirmDelete === true &&
                <AlertDialog
                    operation={vocabulary.delete}
                    open={alertConfirmDelete}
                    deletedItemName={JSON.parse(item.name)["name_" + currentLanguage]}
                    onClose={() => {
                        closeAlertConfirmDelete();
                    }}
                    confirmeDelete={() => {
                        if (item) {
                            handleDeleteIrrigationType(item);
                        }
                        closeAlertConfirmDelete();
                    }}
                    cancel={() => {
                        closeAlertConfirmDelete();
                    }}
                />
            }

            { (openType == true || openEdit == true) &&
                <IrrigationTypeForm
                    open={isEditing === false ? openType : openEdit}
                    onClose={() => {
                        return (
                            isEditing === false ?
                                (setOpenType(false),
                                    setIsEditing(false))
                                :
                                setOpenEdit(false)
                        )

                    }}
                    item={isEditing === true ? item : null}

                    itemId={itemRow.id}
                    isEditing={isEditing}
                    reftchFunc={() => GetIrrigationTypeList(itemRow.id, setIrrigationTypeList)}
                    setIsLoading={setIsLoading}

                />
            }
        </ModalContext.Provider>

    )
}
export default IrrigationTypeManagement;
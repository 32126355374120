import React  from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import DataTable from './DataTable'

const CountriesList = (props) => {

    return (
        <>
            <DataTable
                cities={props.countries}
                setparams={props.setparams}
                areaParams={props.areaParams}


            />
        </>
    )
}

export default  CountriesList 
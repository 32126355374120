/* eslint-disable no-useless-concat*/
/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';

import { StyledMaterialTable } from '../../ui/display';
import { DownloadLogs, GetLogsByAreaAndDataType, GetLogsByDeployedDeviceId } from "../../globalsTools/network";
import LineChartLogs from '../Charts/LineChart/LineChartLogs'
import Excel from '../../images/icons8-xls-32.png'
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';

const LogsTable = ({ sourceTypeId,
    selectedDataTypeIdentifier,
    selectedAreaId,
    selectedDataTypeId,
    dateFrom,
    dateTo,
    deviceId,
    startGateData,
    selectedDataTypeIdByDevice,
    selectedGetewayId,
    // equipmentDataTypeId
}) => {

    const moment = require('moment');
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [logList, setLogList] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [isLoading, setIsLoading] = useState(false)

    const RayonnementSolaireId = '227dda65-3dc4-4465-b55b-53f3bc15852b'

    const tableRef = React.createRef();



    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                setIsLoading(false)
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();

            } else {
                setIsLoading(false)
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
            }
        }
    }


    const headerTable = vocabulary.datatype + "," + vocabulary.port + "," + vocabulary.deployedDevices + "," + vocabulary.originalValue + "," + vocabulary.calculatedValue + "," + vocabulary.isoUnit + "," + vocabulary.captureTime + "," + vocabulary.area

    const DownloadLogsTable = () => {
        setIsLoading(true)

        DownloadLogs(
            {
                header: headerTable,
                area_id: selectedAreaId,
                prm_data_type_id: selectedDataTypeId,
                prm_data_type_identifier: selectedDataTypeIdentifier,
                start_date: dateFrom,
                end_date: dateTo,
                download: true,
                deployed_device_id: deviceId
            }, r => operationCallBack(r)
        )


    };

    useEffect(() => {
        if (startGateData) {
            tableRef.current.onQueryChange();
        }

    }, [startGateData]);

    return (


        <>
            {(logList && logList !== null && logList.length > 0) &&
                <LineChartLogs
                    data={logList}
                    dataTypeName={logList !== null ? JSON.parse(logList[0].data_type.name)['name_' + currentLanguage] : ''}
                    unit={logList !== null ? logList[0].data_type.iso_unit : ''}
                />}


            {!isLoading ?
                <StyledMaterialTable
                    tableRef={tableRef}
                    title={vocabulary.Logs}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage } }}
                    onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                    data={query => new Promise((resolve, reject) => {
                        (deviceId === null ?
                            GetLogsByAreaAndDataType(selectedAreaId, selectedDataTypeId, sourceTypeId, dateFrom, dateTo, query.pageSize, (query.page + 1))
                            : GetLogsByDeployedDeviceId(deviceId ? deviceId : selectedGetewayId, selectedDataTypeIdByDevice, dateFrom, dateTo, query.pageSize, (query.page + 1)))
                            .then(result => {

                                if (result && result.data) {
                                    setLogList(result.data.data)

                                    resolve({
                                        data: result.data.data,
                                        page: result.data.current_page - 1,
                                        totalCount: result.data.total,
                                        pageSize: result.data.per_page,
                                    })
                                    window.scrollTo(0, 0);
                                }

                            })

                    })
                    }

                    columns={[
                        { title: vocabulary.calculatedValue, field: 'calculated_value', render: rowData => <>{rowData.data_type.id == RayonnementSolaireId ? Number(rowData.calculated_value).toFixed(5) : Number(rowData.calculated_value).toFixed(3)}</> },

                        // { title: vocabulary.formula, field: 'calculated_value', render: rowData => <>{JSON.parse(rowData.calculated_value).formula.type === 'formula' ? ((JSON.parse(rowData.calculated_value).formula.type).toUpperCase() + ' : ' + JSON.parse(rowData.calculated_value).formula.formula) : ((JSON.parse(rowData.calculated_value).formula.type.toUpperCase()) + ' ' + JSON.parse(rowData.calculated_value).formula.source.provider + ' : ' + JSON.parse(rowData.calculated_value).formula.source.call)}</> },
                        { title: vocabulary.originalValue, field: 'value', render: rowData => <>{Number(rowData.value).toFixed(3)}</> },
                        { title: vocabulary.isoUnit, field: 'data_type', render: rowData => <>{rowData.data_type.iso_unit}</> },
                        { title: vocabulary.port, field: 'port', render: rowData => <>{rowData.port ? rowData.port : '--'}</> },
                        { title: vocabulary.sourceLabel, field: 'source', render: rowData => <>{rowData.data_source_id}</> },
                        { title: vocabulary.captureTime, field: 'capture_time', render: rowData => <>{rowData.capture_time ? currentLanguage == "en" ? moment(rowData.capture_time).format('YYYY-MM-DD HH:mm:ss') : moment(rowData.capture_time).format('DD-MM-YYYY HH:mm:ss') : '--'}</> },
                        { title: vocabulary.deployedDevices, field: 'deployed_device', render: rowData => <>{rowData.deployed_device ? rowData.deployed_device.name : vocabulary.inputSourceOrigin}</> },

                    ]}
                    actions={[


                        {
                            icon: () => <img src={Excel} alt="excelIcon" />,
                            tooltip: vocabulary.downloadLabel,
                            isFreeAction: true,
                            onClick: (event) => DownloadLogsTable()
                        }

                    ]}

                    options={{
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 40, 60, 80, 100, 200, 500, 1000],
                        actionsColumnIndex: -1,
                        search: false,
                    }}
                />
                : <StyledCircularProgress size={50} label={vocabulary.loadingFilelogs} />
            }


        </>





    )
}

export default LogsTable
/* eslint-disable no-useless-escape*/

export const fr = {
  _SPACE_SENSE_LAUNCH_PACK_TITLE: 'Historique d\'un an d\'imagerie satellite - Pack de lancement',
  _SPACE_SENSE_LAUNCH_PACK_DESCRIPTION: 'Historique d\'un an d\'imagerie satellite relatif à une zone y compris les index NDVI, NDWI, NDRE, LAI and CHI',
  parcelsNumber: 'Nombre de parcels',
  areas: 'Surfaces',
  areasList: 'Liste des zones',
  parcels: 'Parcelles',
  addParcel: 'Ajouter une nouvelle parcelle',
  parcel: 'Parcelle',
  size: 'Superficie',
  area: 'zone',
  areaInformation: 'Informations relatives à la zone',
  addArea: 'Ajouter une nouvelle zone',
  addSector: 'Ajouter un secteur',
  stopEditing: 'Arrêter l\'édition',
  editArea: 'Modifier la zone ',
  removeSite: 'Supprimer le site',
  removeParcel: 'Supprimer la parcelle ',
  selectedArea: 'Zone sélectionnée',
  addSite: 'Ajouter un Site',
  editParcel: 'Modifier la Parcel',
  editSector: 'Modifier le Secteur',
  AgricultureArea: 'Les Zones Agricoles',
  editSite: 'Modifier Site',
  siteMap: 'La carte du Site',
  site: 'Site',
  mapView: 'vue de la carte',
  listView: 'Vue De Liste',
  yourSiteLabel: 'Vos sites',
  goToLabel: 'Trouver une adresse / coordonnées',
  doubleClickToShowAreaDetail: 'Veuillez double-cliquer sur une zone affichée pour charger les détails ...',
  sousArea: 'sous-zones',
  map: 'Carte',
  numberDeployedDevices: 'Nombre d\'appareils déployés',
  partnerIntegrations: 'Intégrations partenaires',
  historicalLabel: 'Historique',
  forecastLabel: 'Prévision ',
  emptyParcelLabel: 'La zone sélectionnée ne contient pas des parcelles , voulez-vous créer une ?',
  emptySectorLabel: 'La zone sélectionnée ne contient pas des secteurs, voulez-vous créer un ?',
  hectar: 'Hectare',
  navigateTo: 'Aller vers',
  sites: 'Sites',
  LatitudeLabel: 'Latitude',
  LongitudeLabel: 'Longitude',
  siteGateway: 'passerelle de site',
  drippers: 'les goutteurs',
  sector: 'Secteur',
  searchPlace: 'Rechercher des lieux...',
  lines: 'Les lignes',
  noParcelCreatedLabel: 'il n\'y a pas des parcelles',
  noSectorCreatedLabel: 'Il n\'y a pas des secteurs ',
  noAreaCreatedLabel: 'Il n\'y a pas des sites ',
  onepage_areaDetail: 'Informations et Préconisations',
  addField: 'Ajouter Un Nouveau Champ',
  providerLabel: 'Fournisseur',
  personalizationdDataTypeSource: 'Personnalisation les sources de types de données',
  addAreaUsingKmlFile: 'Ajouter une zone à l\'aide d\'un fichier KML, GeoJSON ou JSON',
  dragDropKmlFileLabel: 'Faites glisser et déposez un fichier de format KML, GeoJSON ou JSON, ici, ou cliquez pour sélectionner un fichier',
  wrongChoosenFile: 'Format de fichier incorrect, veuillez choisir un fichier de type KML, GeoJSON ou JSON',
  wrongFileContent: 'Ce fichier ne correspond pas au bon format de contenu',
  buyOneYearSateliteLabel: 'Acheter un an d\'historique d\'image satellites',
  indexTypeDescription: 'Vous aurez accés  à 8 types d\'index y compris NDVI, NDWI, NDRE, EVI, CHI, LAI, SAVI, RGB',
  lunchPromotionLabel: 'Promotion de lancement',
  SateliteDescription: 'L\'utilisation de l\'imagerie satellitaire en agriculture n\'est pas quelque chose de nouveau. Il a été utilisé de manière limitée pendant des décennies, \n principalement pour fournir des éléments simples comme des index (NDVI, NDWI…). Les images satellite vous aident à obtenir: \'',
  cropHealthIndecator: 'Indicateur de la santé des cultures: ',
  cropHealthIndecatorDescription: 'Surveillez la santé de vos cultures en détectant les stress biotiques et abiotiques via NDVI, NDWI et NDRE ',
  cropAnomalyDetector: 'Détecteur d\'anomalies des cultures: ',
  cropAnomalyDetectorDescription: 'Identifier les zones du champ où la culture est sur ou sous-performante par rapport à la façon dont elle pousse habituellement ',
  viewDetailsLabel: 'Voir les détails ',
  PayerLabel: 'Payer',
  paymentFormLabel: 'Formulaire de paiement ',
  tva: 'Immatriculation TVA ',
  billingAddress: 'Adresse de facturation ',
  billingDetails: 'Détails de facturation',
  department: 'Département ',
  includingTaxesLabel: 'Taxes incluses ',
  seabexOffers: 'Offres Seabex ',
  seabexOffersDiscount: 'J\'ai un code de réduction',
  applyDiscount: 'Appliquer le bon',
  seabexSatelliteImagesOffers: 'Imagerie satellite pour cette parcelle',
  cardDetailLabel: 'Détails de la carte',
  dialog_newArea: 'Vous n\'avez pas encore de zones enregistrées. Voulez-vous en créer une maintenant?',
  satelliteIndex: 'Indices de végétation',
  satelliteImages: 'Images satellites ',
  noSatteliteImageAvailable: 'Il n\'y a pas d\'images satellites disponibles pour cette zone. Si vous souhaitez avoir accès à ce service, veuillez cliquer sur le bouton dans la carte des détails de la zone. ',
  ndvi: 'Indice de végétation par différence normalisée',
  ndwi: 'indice normalisé d\'humidité',
  ndre: 'Différence normalisée bord rouge',
  savi: 'Indice de végétation ajusté au sol',
  chi: 'Indice de Chlorophylle',
  evi: 'Indice de végétation amélioré',
  lai: 'Indice de surface foliaire',
  nirv: 'Indice du proche-infrarouge de la végétation',
  rgb: 'Image satellite en RGB',
  ndvi_description: 'Etant l\'indice le plus populaire pour la surveillance de la santé des cultures, le NDVI mesure le niveau de verdeur et le stade de croissance des cultures. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus la couverture végétale de cette zone est importante ; plus le pixel est rouge, moins il y a de couverture végétale dans cette zone.',
  ndwi_description: 'Etant l\'indice le plus populaire pour le suivi de l\'irrigation, le NDWI mesure l\'état de stress hydrique des cultures. Pour chaque pixel de cette image de champ, plus le pixel est bleu, plus cette zone contient d\'eau ; plus le pixel est orange, moins cette zone contient d\'eau.',
  ndre_description: 'Etant l\'indice le plus populaire pour la surveillance des engrais, le NDRE mesure l\'état de stress des éléments nutritifs des cultures, en particulier l\'azote. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus cette zone contient d\'azote ; plus le pixel est rouge, moins cette zone contient d\'azote.',
  savi_description: 'Similaire au NDVI, SAVI est plus adapté aux cultures avec un pourcentage élevé de sol nu, comme les vins, les vergers, les cultures précoces, etc. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus la couverture végétale est importante. cette zone a; plus le pixel est rouge, moins il y a de couverture végétale dans cette zone.',
  chi_description: 'En tant qu\'indicateur de productivité des cultures, le ChI mesure le niveau de teneur en chlorophylle dans les feuilles des plantes. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus cette zone contient de chlorophylle ; plus le pixel est rouge, moins cette zone contient de chlorophylle.',
  evi_description: 'Lorsque certaines cultures arrivent à un stade de croissance tardif, le NDVI peut saturer. L\'indice EVI vous permet de poursuivre la surveillance des cultures à un stade tardif. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus cette zone a de biomasse ; plus le pixel est rouge, moins cette zone a de biomasse.',
  lai_description: 'En tant qu\'indice couramment utilisé pour la mesure de la biomasse, le LAI indique la variabilité de la géométrie des plantes. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus cette zone a de biomasse ; plus le pixel est rouge, moins cette zone a de biomasse.',
  nirv_description: 'Par rapport aux autres indices de végétation, le NIRv est le reflet le plus proche de la productivité et du rendement des cultures. Pour chaque pixel de cette image de champ, plus le pixel est vert, plus cette zone est productive ; plus le pixel est rouge, moins cette zone est productive.',
  rgb_description: 'Lorsque les indices de végétation changent brusquement (c\'est-à-dire présence de nuages, récolte), le RVB (ou images \'truecolor\') est l\'outil parfait pour effectuer des vérifications visuelles.',
  selectIndexLabel: 'Vous pouvez sélectionner ici votre index ',
  visibilityLabel: 'Visibilité',
  lastupdatedLabel: 'Dernière mise à jour le  ',
  meanLabel: 'Moyenne',

  // irrigation types

  irrigationAspersion: "Irrigation par aspersion",
  irrigationLocalized: "Irrigation localisée",
  irrigationSubmersion: "Irrigation par submersion",
  irrigationPivot: "Irrigation par pivots",
  irrigationLocalizedDrip: "Irrigation au goutte-à-goutte",
  irrigationLocalizedMicroAspersion: "Irrigation par micro-aspersion",
  invoiceNumber: 'Numéro facture',
  invoicePaid: 'Payé',
  invoiceCurrencyAmount: 'Devise Montant',
  invoiceAmount: 'Montant',
  invoiceCurrencyTax: 'Taxe de change',
  invoiceDiscount: 'Remise',
  invoiceDeadline: 'Date limite',
  invoiceIsDraft: 'Brouillon',
  currency: 'Devise',
  deployedDevices: 'Appareils déployés',
  deviceName: 'nom de l\'appareil',
  deviceFamily: 'Famille d\'appareils',
  identifier: 'Identifiant',
  addStation: 'Ajouter Station',
  stationEquipments: 'Équipement',
  deviceCategory: 'Catégorie d\'appareil',
  deviceModel: 'Modèle d\'appareil',
  station: 'Station',
  selectGateway: 'sélectionnez Gateway',
  updateStation: 'Modifier station',
  port: 'Port',
  selectStation: 'Selectionner la Station',
  addMarker: 'Ajouter un Marqueur',
  position: 'Position',
  gateway: 'Passerelle',
  monitoring: 'Surveillance',
  stations: 'Stations',
  noModelYet: 'Pas encore de modèle',
  stationName: 'Nom de la dispositif',
  action: 'Action',
  noAreaYet: 'Pas encore de zone',
  createDeployedDevice: 'Créer un appareil déployé',
  loadingDeviceInformation: 'Obtenir des informations sur l\'appareil',
  lastPing: 'Dernier ping',
  publicIp: 'IP publique',
  inetrnalIp: 'IP interne',
  sdfreeSpace: 'Espace libre SD',
  scheduledJobsLabel: 'Travaux planifiés',
  confirmDeviceCoordinates: 'Confirmer les coordonnées de l\'appareil ',
  realTimeSensorsAndActuators: 'Capteurs temps réel et Monitoring',
  selectAreaToMonitor: 'Sélectionnez la zone que vous souhaitez surveiller',
  filterAdvice: 'Utilisez des filtres pour plus de précision',
  realTimeData: 'Connexion au Serveur des données Temps Réel',
  controlPanel: 'Panneau de Contrôle',
  realtimeMonitoringPanel: 'Panneau de Suivi Temps Réel',
  calculatedValue: 'Valeur calculée',
  originalValue: 'Valeur original',
  notConnectedLabel: 'Déconnecté',
  connectedLabel: 'Connecté',
  relay_status: 'État du relais',
  noRealTimeDataLabel: 'y\'as pas des données temps réél pour ce site , car il ne contient pas des appareils déployés',
  autoScaleLabel: 'Échelle automatique',
  attachedOnLabel: 'attaché sur le',
  onSurLabel: 'sur ',
  isLabel: 'est ',
  caseLabel: 'Raison ',
  fromDeviceLabel: ' de l\'appareil ',
  dueScenarioLabel: ' en raison d\'un scénario  ',
  COMMAND: ' Envoi de commande ...  ',
  UserAction: ' Action utilisateur  ',
  AutomationPlan: 'Plan d\'automatisation',
  Scenario: 'Scénario ',
  possibleReasonLabel: 'Raison possible: l\'équipement est débranché, mal branché ou endommagé',
  originalUnitLabel: 'Unité de valeur d\'origine',
  calculatedUnitLabel: 'Unité de valeur calculée',
  activatedLabel: 'Activé',
  desactivatedLabel: 'Désactivé',
  youAreConnectedToGatewayLabel: 'Vous êtes connecté à Passerelle',
  noRealTimeDataToDisplayLabel: 'Aucune donnée en temps réel à afficher',
  defaultLabel: 'Par défaut',
  rangeLabel: 'Intervalle',
  relayActivationLabel: 'Activation du relai',
  relayDeactivation: 'Désactivation du relai',
  cycles: 'Cycles',
  cycleLabel: 'cycle',
  day_number: 'Nombre de jours depuis l\'implantation',
  remainingDays: 'Jours restant',
  developmentPhase: 'Phase de developpement',
  'Phase de developpement': 'Phase de developpement',
  'Phase initiale': 'Phase initiale',
  Total: 'Total',
  Autre: 'Autre',
  midSeasonPhase: 'Phase mi-saison',
  suggestedValueLabe: 'Cette valeur est une valeur suggérée basée sur notre calcul! N\'hésitez pas à le changer.',
  'Phase mi-saison': 'Phase mi-saison',
  durationMidSeasonPhase: ' La Durée de la Phase mi-saison',
  phaseArriereSaison: 'Phase arrière-saison',
  'Phase arrière-saison': 'Phase arrière-saison',
  durationPhaseArriereSaison: 'La Durée de la Phase arrière-saison',
  phase: 'Phase',
  currentPhaseIsLabel: 'Stade végétatif : ',
  kc: 'Coefficient Cultural',
  need_irrigation: 'Besoin d\'irrigation',
  endCycle: 'Fin du cycle',
  creationCycle: 'créer un cycle de production',
  RecommendedProductionCycle: 'Utiliser le cycle de production recommandé',
  customizeProductionCycle: 'Personnalisez votre propre cycle de production',
  noCycleInSite: 'Aucun cycle de production est attribué a ce site',
  cycleExist: 'La culture et la zone à inserré deja exist',
  productionCycle: 'Cycle de Production',
  productionCycleCreateSuggestion: 'Souhaitez-vous créer un cycle de production pour cette nouvelle zone maintenant ?',
  addCycle: 'Ajouter Cycle',
  editCycle: 'Modifier Cycle',
  cycleDetails: 'Détails du cycle',
  cycleDays: 'Le nombre de jours depuis le début du cycle',
  end_cycle: 'Fin du cycle de production',
  openCycle: 'Ouvrir Cycle ',
  cycle: 'Cycle',
  cycleStatus: 'En cours',
  finishCycle: 'Terminer',
  recommendedDate: 'Date de fin du cycle recommandée',
  depth: 'profondeur(m)',
  initialStage: 'Phase initiale',
  durationInitialStage: 'La Durée de la Phase initiale',
  rootingDepth: 'Profondeur d\'enracinement (mètre)',
  sensibilite: 'Sensibilité',
  durationDevelopmentPhase: 'La Durée de la Phase de developpement',
  addGrowthDuration: 'Ajouter une durée de croissance',
  editGrowthDuration: 'Modifier la durée de croissance',
  growthDurationLabel: 'Durée de croissance',
  yearsSinceImplimantation: 'Nombre d\'années depuis l\'implantation ',
  monthSinceImplimantation: 'Nombre de mois depuis l\'implantation ',
  recalculateLabel: 'Lancer un recalcul',
  cycleInCalculation: 'Calcul en cours',
  cycle_text: 'Les differentes phases de developpment',
  cycleEndedOn: 'Terminé on ',
  irrigationDaysNumber: 'Nombre de jours d\'irrigation',
  gettingCycleInformation: 'Obtenir les informations du cycle',
  addCycleLoadingMsg: 'Chargement du processus de calcul',
  first_part: '10 premiers jours ',
  middle_part: 'Deuxième 10 jours ',
  end_part: '10 derniers jours ',
  evolutionCoefficientLabel: 'Évolution du coefficient culturel - Source (FAO) ',
  currentPeriod: 'Période actuel',
  ofLabel: 'de',
  ageLabel: 'Age',
  implantationDateLabel: 'Date d\'implantation ',
  nocycleFoundedInSelectedArea: 'La zone sélectionnée ne contient pas un cycle de production, voulez-vous créer un ?',
  cropCoefficientDescription: 'Le coefficient cultural (kc) est le rapport entre l\'évapotranspiration de la culture (ETc) et l\'évapotranspiration potentielle (ET0), il intègre les effets des 4 caractéristiques primaires qui distinguent une culture de la culture de référence qui sont : la hauteur de la culture, la résistance de surface sol - végétation, l\'albédo, l\'évaporation de sol (Allen et al, 1998).',
  numberOfDaysPerIrrigation: 'Nombre de position d’arrosage sur la parcelle culture',
  specifyDateLabel: 'Spécifier La Date',
  currentPhaseLabel: 'Stade phénologique actuel',
  newPhaseLabel: 'Nouvelle phase',
  changePhaseLabel: 'Changer de Phase',
  confirmPassageLabel: 'Veuillez confirmer que le passage de',
  tookplaceLabel: 's\'est déroule le',
  toPhaseLabel: 'à la phase',
  thePhaseLabel: 'la phase',
  endOnLabel: 'Terminé le  ',
  calculatedStandinfLabel: 'Encours de calculé',
  waterRequirementLabel: 'Besoin en eau et bilan hydrique ',
  evapotranspiration: 'ETP évapotranspiration potentielle',
  adjustedIrrigationVolume: 'Irrigation suggérée',
  adjustedSoilWaterVolume: 'Réserve d\'eau ajustée',
  survivalReserve: 'Réserve de survie',
  adjustedSurvivalReserve: 'Réserve de survie ajustée',
  totalPrecipitation: 'Précipitations ',
  totalPrecipitationForecasted: 'Précipitations Prévues',
  waterInSoil: "Quantité d'eau dans le sol",
  availableWaterInSoil: "Quantité d'eau dans le sol",
  actualEvapotranspiration: 'Evapotranspiration Réelle',
  todayWaterNeed: 'Besoin en eau des cultures',
  calculationParam: 'Paramètre de calcul',
  waterNeedHistory: 'L\'évolution des besoins en eau',
  eau_utile: 'Eau utilisable',
  irrigationQantity: 'Quantité à irriguer',
  waterNeedNextDays: 'Besoin en eau pour les jours d\'un tour d\'eau complet',
  fillRate: 'Taux de remplissage du sol',
  useful_Reserve: 'Réserve Utilisable',
  easily_useful_Reserve: 'Réserve Facilement Utilisable',
  metreJour: 'mm',
  precipitationUnit: 'L/m²',
  hectarMcube: 'm³/hectare',
  drainageLabel: 'Drainage',
  waterQuantity: 'Quantité d\'eau',
  waterNeedRecalculatedProcessing: 'Le recalcul des besoins en eau est en cours de traitement',
  waterNeedRecalculated: 'Besoin en eau recalculé avec succès',
  howManyDaysLabel: 'Tous les combien de jours  ',
  simplifiedViewLabel: 'Vue Simplifiée',
  recommendationsViewLabel: 'Vue avec préconisations',
  totalPrecipitationUnitLabel: 'Total des Précipitations (mm)',
  totalIrrigationsPerformedLabel: 'Total des Irrigations Effectuées (mm)',
  totalRecommendedIrrigations: 'Total des Irrigations Préconisées (mm)',
  dataDetailsLabel: 'Détail des données',
  waterNeedOnDemand: 'Besoin en eau à la demande',
  drawZone: 'Dessiner une zone',
  apply_changes_for_other_areas: "Appliquer les changements à d'autres parcelles ?",
  //Automation Plans
  automationPlan: "Plans d'irrigation",
  automationPlans: "Plans d'Automatisation",
  AddAutomationPlan: "Ajouter Plan d'Automatisation",
  editAutomationPlan: "Modifier Plan d'Automatisation",
  tasksLabel: 'les tâches',
  addIrrigationPlan: 'Ajouter Un Plan d\'irrigation',
  selectedMonths: 'Les Mois séléctionné',
  selectedMonthDays: 'Les jours du mois séléctionnés',
  selectedWeekDays: 'Les jours de la semaine séléctionnés ',
  selectActuator: 'Sélectionner le port',
  onLabel: 'Allumé ',
  offLabel: 'Éteint',
  scenarioLabel: 'Scenario',
  chapriority: 'Priorité',
  changePriority: 'Changer La Priorité',
  operatorLabel: 'Operateur',
  conditions: 'Les conditions',
  scEquation: 'Équation',
  sensor: 'Capteur',
  sensorType: 'Type de Capteur',
  warningCondition: 'au moins une condition doit être fournie',
  warningAction: 'au moins une action doit être fournie ',
  actionType: 'Type d\'action',
  priority: 'Priorité',
  conditionsList: 'Liste des conditions',
  deviceID: 'Identifiant de l\'appareil',
  shared_device: 'Périphérique partagé',
  related_areas: 'Parcelles concernées',
  pin: 'localisation',
  operator: 'Facteur:<>=',
  value: 'Valeur',
  equation: 'Equation',
  astationOrAutomation: 'Station/AutomationPlan',
  delay: 'Délai',
  delayInMinute: 'Délai en minutes',
  selectStatus: 'Début  le Statut',
  Logs: 'Journaux',
  multiLogs: 'Multi-journaux',
  dataLogs: 'Historique de données',
  findLogs: 'Trouver Logs',
  choiceBySite: 'Choix par Site ',
  choiceByDeployedDevice: 'Choix Par Appareil Déployé',
  downloadLabel: 'Télécharger',
  inputSourceOrigin: 'L\'origine de cette entrée est un fichier',
  loadingFilelogs: 'Telechargement de fichier en cours ...',
  intervalUnitLabel: 'Unité d\'intervalle',
  intervalNumberLabel: 'Numéro d\'intervalle',
  addNewDataTypeLabel: 'Ajouter un nouveau type de données',
  confirmSelectionLabel: 'Confirmer la sélection',
  saveSelectionLabel: 'Save sélection',
  findEvents: 'Trouver des événements',
  eventsList: 'Liste des événements',
  Events: 'Événements',
  network: 'Mon réseau',
  reason: 'Raison',
  assistedMode: 'Mode assisté',
  automaticMode: 'Mode automatique',
  detailsLabel: 'Détails',
  connect: 'Se Connecter',
  duration: 'Durée',
  AssistedAction: 'Action Assistée',
  modeLabel: 'Mode',
  youHaveActivatedLabel: 'Vous avez activé ',
  ofTheDeviceLabel: 'de l\'appareil',
  youHaveDesactivatedLabel: 'Vous avez desactivé',
  atTimeLabel: ' à',
  errorOccuredOnLabel: ' Un erreur est survenue  sur',
  youAreConnectedLabel: 'Vous êtes connecté',
  sentACommandLabel: 'Vous avez envoyé une commande sur ',
  youOpenedLabel: 'Vous avez ouvert ',
  youClosedLabel: 'Vous avez fermé',
  onTheDeviceLabel: 'sur l\'appareil',
  irrigationMangement: 'Gestion d\'Irrigation',
  irrigationDate: 'Date d\'irrigation',
  addIrrigation: 'Ajouter Irrigation',
  editIrrigation: 'Modifier l\'Irrigatoin',
  findIrrigation: 'Trouver Irrigation',
  irrigationType: 'Type d\'irrigation',
  addIrrigationType: 'Ajouter Irrigation Type',
  editIrrigationType: 'Modifier Irrigation Type',
  irrigationFamily: 'Famille d\'irrigation',
  addIrrigationFamily: 'Ajouter Famille d\'irrigation',
  editIrrigationFamily: 'Modifier Famille d\'irrigation',
  irrigationMode: 'Mode d\'irrigation',
  userIrrigationManagement: 'Gestion du type d\'irrigation',
  waterVolume: 'Irrigation',
  irrigationVolume: 'Volume d\'irrigation',
  waterVolumeLabel: 'Volume d\'eau',
  isoUnit: 'Unité ISO',
  efficiency: 'Efficacité',
  automatic: 'Automatique',
  manuel: 'Manual',
  gettingIrrigationInformation: 'Obtenir les informations sur l\'irrigation',
  addMultipleIrrigationLabel: 'Ajouter des irrigations multiples',
  editMultipleIrrigationLabel: 'Modifier les irrigations multiples',
  multipleIrrigationLabel: 'Irrigation multiple',
  rainfallManagement: 'Gestion pluviométrie ',
  rainfallList: ' Liste des pluviométrie ',
  currentPluvio: 'Pluviométrie  actuelle ',
  correctedRainfall: 'Pluviométrie  corrigé',
  subscriptions: 'Abonnements',
  subscribe: 'S\'Abonner',
  smsPrice: 'Le prix du SMS',
  emailPrice: 'Le prix du l\'Email',
  appPrice: 'Le prix de l\'Application',
  price: 'Prix',
  total: 'Total',
  apiLabel: 'API',
  alert: 'Alerte',
  advice: 'Conseil',
  sms: 'SMS',
  application: 'Application',
  addSubscription: 'Ajouter un Abonnement',
  editSubcription: 'Modifier un Abonnement ',
  SubscriptionType: 'Type d\'abonnement',
  editSubscription: 'Modifier Subscription',
  disableEdit: 'Vous ne pouvez pas modifier ',
  showAllGroup: 'Tous les groupes',
  showMyGroup: 'Mes groupes',
  groupAdded: 'Groupe ajouté avec succès',
  addGroup: 'Ajouter un Groupe',
  groupLabel: 'Groupe',
  leftGroup: 'Vous avez quitter le  groupe',
  groupeManagment: 'Gérer des groupes',
  editGroup: 'Modifier un Groupe',
  leaveGroup: 'Quitter le groupe',
  disableDelete: 'Vous ne pouvez pas supprimer ',
  subscribeSucces: 'Vous êtes inscrit au groupe',
  alreadySubscribe: 'Vous êtes  deja inscrit au groupe ',
  unfollow: 'Désabonner',
  followers: 'Abonnés',
  nbFollowers: 'Nombre d\'abonnés',
  nbFollowersSeabex: 'Membres SEABEX',
  newFollower: 'Nouveau abonnée',
  fullName: 'Nom complet',
  fullNameOnCard: 'Nom complet sur la carte',
  agents: 'Agents',
  newAgent: 'Nouvel agent',
  agent: 'Agent',
  sentByAgent: 'Envoyé par l\'agent',
  SentNotifNumber: 'Nombre de notifications envoyées',
  maximumHeat: 'Temperature maximale ',
  minimumHeat: 'température minimale',
  temperature: 'Température',
  temperatureAverage: 'Température moyenne',
  temperatureDewPointWeekLabel: 'Tendances température / point de rosée et humidité pour aujourd\'hui et les prochaines 48 heures ',
  humidity: 'Taux d\'humidité',
  rainPrecipitation: 'Précipitations de pluie ',
  snowPrecipitation: 'Précipitations de neige ',
  uvIndex: 'Indice Ultraviolet',
  cloudCover: 'Couverture nuageuse ',
  dewPoint: 'Point de rosée ',
  windSpeed: 'Vitesse du vent ',
  windUnit: 'km/h',
  windSpeedUnit: 'm/s',
  windDirection: 'Direction du vent',
  sunset: 'Lever et coucher du soleil',
  veryHighLabel: 'Très haut',
  windStatus: 'État du Vent',
  highLabel: 'Haut',
  extremeLabel: 'Extrême',
  pressureLabel: 'Pression Atmosphérique',
  nextWeekWeather: 'Météo des prochains jours ',
  weatherStations: 'Stations Meteos',
  Evolution24H: 'Prévisions météo actuelles sur 24 heures',
  WeatherToday: 'Météo d\'aujourd\'hui',
  temperatureApparent: 'Température apparente ',
  humidite_au_point_fletrissement: 'Point de flétrissement',
  bas_du_rfu: 'Seuil minimal de la réserve facilement utilisable',
  seuil_declenchement: 'Seuil de déclenchement',
  moderateLabel: 'Modérer',
  lowLabel: 'Faible',
  timeZone: 'Fuseau horaire',
  weatherTrends: 'Tendances Météorologiques',
  SoilElecConductivity: 'Électroconductivité du sol',
  SoilTemperature: 'Température du sol',
  SoilWaterContent: 'Teneur volumique en eau du sol',
  chooseMySoilType: 'Choisissez mon type de sol',
  createMyCustomSoilType: 'Créer ma texture de sol par horizon',
  customSoilStructure: 'Texture de sol personnalisée par horizon',
  addNewSoilHorizon: 'Ajouter un nouveau horizon de sol',
  soilStructure: 'Structure du sol dans la zone sélectionnée',
  addSoilType: 'Ajouter un type de sol',
  editSoilType: 'Modifier le type de sol',
  solTexture: 'Texture du sol',
  addSoilTexture: 'Ajouter la texture du sol',
  editSoilTexture: 'Modifier la texture du sol',
  soilSensibilite: 'Sensibilité du sol',
  recommendedSoilType: 'Utilisez le type de sol recommandé',
  soilType: 'Type de sol',
  soilFamily: 'Famille de sol',
  userSoilManagement: 'Gestion des sols',
  humidite_capacite_au_champ: 'Capacité au champ',
  salinity: 'Salinité',
  densite_apparente: 'Densité apparente',
  deleteCulture: 'Supprimer la Culture',
  cultureFamily: 'Famille du Culture',
  addCultureFamily: 'Ajouter famille de culture',
  editCultureFamily: 'Modifier famille de culture',
  deleteCultureFamily: 'Supprimer Famille de Culture',
  addCulture: 'Ajouter une culture',
  editCulture: 'Modifier une culture',
  Culture_Name: 'Nom de culture',
  culture: 'Culture',
  userCultureManagement: 'Gestion des cultures',
  phaseNameLabel: 'Nom de la phase',
  phaseDurationLabel: 'La durée de la phase ',
  developmentStagesLabel: 'Veuillez inserer les stades de développement de la plante ',
  DegreeDayOfGrowthLabel: 'Degré jour de croissance ',
  cultureDetailLabel: 'La détail de Culture',
  length: 'Durée ',
  name: 'Phase ',
  degree: 'Degré',
  rootDepth: 'Profondeur',
  userPreferencesLabel: 'Préférences utilisateur',
  fileName: 'Nom de fichier',
  fileSize: 'Taille du fichier',
  bytesLabel: 'Bytes',
  dragDropFileLabel: 'Faites glisser et déposez quelques fichiers ici, ou cliquez pour sélectionner des fichiers',
  correspondenceTableLabel: 'Tableau de correspondance',
  sourcesManagement: 'Gestion des sources',
  sourceLabel: 'Sources type',
  measureLabel: 'Les Mésures',
  colmunsLabel: 'Colonnes',
  fileManagment: 'Gestion des fichiers',
  configurationLabel: 'Configuration',
  fileList: 'Liste des fichiers',
  dataSource: 'Source de données',
  fileSended: 'Fichier Envoyé Avec Succès',
  waitWhileFileIsSending: 'Chargement de l\'envoi du fichier ...',
  wrongChoosenXlsxFile: 'Format de fichier incorrect, veuillez choisir un fichier de type XLSX',
  homePageLabel: 'Accueil',
  AuthorLabel: 'Auteur',
  subTitle: 'Sous-titre',
  publicationsLabel: 'Les publications',
  publicationLabel: 'une publication',
  publicationManagment: 'Gestion des publications',
  worldWideLabel: 'À l\'échelle mondiale ',
  equipmentList: 'Liste des types d\'équipement',
  addEquipmentType: 'Ajouter un type d\'équipement',
  editEquipmentType: 'Modifier un type d\'équipement',
  addEquipment: 'Ajouter un équipement',
  editEquipment: 'Modifier un équipement',
  noAttachedEquipment: 'Aucun Équipement attaché',
  noAttachedDataType: 'Aucun type de données attaché ',
  equipmentType: 'Type d\'équipement',
  userEquipmentManagment: 'Gestion des équipements',
  reference: 'Référence',
  attachDataType: 'Joindre le type de données à l\'équipement',
  equipmentLabel: 'Équipement',
  equipmentTypeOfFamily: 'Types d\'équipement de la famille',
  onTheEquipmentLabel: 'sur L\'equipement',
  financialManagment: 'Gestion financière ',
  financialCategorie: 'Catégories financières',
  accountingManagement: 'Gestion Comptabilité',
  invoiceManagement: 'Gestion des factures',
  customerManagement: 'Gestion des clients',
  productManagement: 'Gestion des produits',
  voucherManagement: 'Gestion des bons',
  financialCategoryLabel: 'Catégorie financière',
  userName: 'Nom d\'utilisateur ',
  financialType: 'Type financier ',
  financialCategoryParent: 'Parent de catégorie financière',
  expensesRevenue: 'Dépenses / Revenus ',
  myFinancialCategorieLabel: 'Mes catégories financières ',
  activitiesManagement: 'Gestion des activités',
  activitiesList: 'Liste des activités',
  activityLabel: 'Activité',
  activityType: 'Type d\'activité',
  pickColor: 'Choisis une couleur',
  activityTypeByMe: 'Mes type d\'activités',
  activities: 'Activités',
  addActivity: 'Ajouter Activité',
  addActivityType: 'Ajouter un Type d\'activité',
  culturalActivities: 'Activités culturales',
  agriculturalActivities: 'Activités agricoles',
  January: 'Janvier',
  February: 'Fevrier',
  March: 'Mars',
  April: 'Avril',
  May: 'May',
  June: 'Juin',
  July: 'Juillet',
  August: 'Aout',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Decembre',
  'Every Month': 'Chaque Mois',
  'Every Month Day': 'Chaque Jour du Mois ',
  'Every Day': 'Chaque Jour',
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',
  Tomorrow: 'Demain',
  today: 'Aujourd\'hui',
  Yesterday: 'Hier',
  now: 'Maintenant',
  dayLabel: 'Jours',
  MonthLabel: 'Mois',
  timeLabel: 'Temps',
  startTime: 'Le Temps de début',
  endTime: 'Temps de Fin',
  from: 'De',
  to: 'à',
  created_at: 'Créé le',
  date: 'Date',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  captureTime: 'Temps de capture',
  noRecordToDisplay: 'Aucun enregistrement à afficher',
  rowsLabel: 'Lignes',
  actions: 'Actions',
  nextpage: 'Page suivante',
  lastpage: 'Dernière page',
  previouspage: 'Page précédente',
  firstpage: 'Première page',
  search: 'Rechercher',
  noDataAvailable: 'Pas de données disponibles',
  next: 'SUIVANT',
  previous: 'Précédent',
  refreshData: 'Actualiser les données',
  titleLabel: 'Titre',
  loading: 'Chargement...',
  valueLabel: 'Valeur',

  rainfallEditReminder: "Quand vous terminez de modifier la pluviométrie, n'oubliez pas de sauvegarder vos modifications",
  saveChanges: "Sauvegarder les changements",
  confirmCancelMessage: "Merci de confirmer ou annuler le changement avant de passer !",
  subArea: "Sous-parcelle",
  referenceArea: "Parcelle de référence",
  //snackbar msg 
  deleteMsg: 'Supprimé avec succès',
  accessGranted: 'Accès Autorisé !',
  confirmationLabel: 'Êtes-vous sûr de ',
  confirmationLabelEndCycle: 'Êtes-vous sûr de vouloir clôturer le cycle à la date sélectionnée',
  emailCheckAccount: 'Veuillez vérifier votre e-mail pour vérifier votre compte !',
  emailCheckPassword: 'Veuillez vérifier votre e-mail pour réinitialiser votre mot de passe ',
  emailCheckPasswordChanged: ' Mot de passe a était changé avec succès, Veuillez vérifier  votre compte',
  checkAccessCode: 'Veuilliez verifier vos codes d\'accès ',
  errorOccurred: 'Une erreur est survenue',
  errorChecked: 'l\'Erreur a été vérifiée',
  errorCorrected: 'l\'Erreur a été corrigée',
  checkedLabel: 'vérifié',
  correctedLabel: 'corrigé',
  updateSucc: 'mis à jour avec succés',
  addedSucc: 'ajouté avec succès',
  registerDone: 'Inscription terminée',
  connectionSuccessful: 'La connexion a réussi',
  connectionFailed: 'La connexion a échoué',
  enabled: 'Activé',
  disabled: 'Désactivé',
  activeLabel: 'Activé',
  inactiveLabel: 'Désactivé',
  notConnectedYet: 'Pas encore connecté',
  accepted: 'Accepté',
  proposed: 'Proposé',
  serverSideLabel: 'coté serveur',
  tryAgainLabel: 'Veuillez réessayer ulturiement',
  checkUrData: 'Veuillez vérifier vos données d\'insertion',
  theLabel: 'le',
  successfullyConnectedOn: 'Connexion réussie sur',
  at: 'à',
  successfullyExecutionOn: 'Exécuté avec succès sur',
  automationPlanExecutionOn: 'Exécution du plan d\'automatisation sur',
  automationPlanExecutionFailedOn: 'L\'exécution du plan d\'automatisation a échoué le',
  addNewUser: 'Nouvel utilisateur',
  userList: 'Liste des Utilisateurs',
  invoiceList: 'Liste des factures',
  voucherList: 'Liste des bons',
  addNewVoucher: 'Ajouter un nouveau bon',
  EditVoucher: 'Modifier le bon',
  clientList: 'Liste des clients',
  productList: 'Liste des produits',
  organizationList: 'Liste des Organizations',
  EditUser: 'Modifier un Utilisateur',
  mobile: 'Numéro de télèphone',
  address: 'Addresse',
  diploma: 'Diplôme',
  speciality: 'Spécialité',
  experience: 'Expérience',
  region: 'Région',
  agree: 'Accepter',
  disagree: 'N\'accepte pas',
  skills: 'Les compétences',
  invitationVoucher: 'Bon d\'invitation',
  applyVoucher: 'Appliquer',
  iHaveAVoucherDiscount: 'J\'ai un code de remise',
  invitationVoucherWarning: 'L\'inscription à la plateforme Seabex est ouverte, en avant première, aux clients ayant fait leurs demandes à l\'équipe Seabex via le formulaire d\'invitation. Vous devez disposez d\'un code d\'invitation pour pouvoir vous inscrire. Assurez-vous d\'avoir ce code avant de faire votre inscription.',
  firstname: 'Prénom',
  lastname: 'Nom',
  email: 'Email',
  password: 'Mot de passe',
  c_password: 'Confirmation du mot de passe',
  memberSince: 'Membre depuis',
  ville: 'Ville',
  enterPhoneNumber: 'Votre numéro de téléphone',
  getContactedBySeabex: 'Si vous souhaitez être contacté par Seabex pour faire le suivi de votre compte, merci de saisir votre numéro de téléphone',
  requiredPhoneNumber: 'Numéro de téléphone requis',
  invalidPhoneNumber: 'Numéro de téléphone invalide',
  clientSeabex: 'Membre SEABEX',
  username: 'Nom d\'utilisateur',
  forgetPassword: 'Mot de passe oublié? cliquez-ici.',
  checkYourEmail: 'Vérifier votre boite mail',
  resetPassword: 'Réinitialiser le mot de passe',
  newpassword: 'Nouveau mot de passe',
  placeToSearch: 'Entrez un lieu à rechercher',
  userManagement: 'Gestion d\'utilisateur',
  partnerManagement: 'Gestion des partenaires',
  organizationManagement: 'Gestion d\'organization',
  areaManagement: 'Gestion des sites',
  termsConditions: 'Termes et conditions',
  youCanCreateOne: 'Vous pouvez en créer un ici',
  privacyPolicy: 'Politique de confidentialité',
  restoreLabel: 'Restaurer',
  restoreDescription: 'Cette operation va supprimer  d\'une facon definitive  : les  donnés de l\'area selectionné :',
  restoredItem: 'Données collectées, Météo, Irrigation, Cycle, Dispositifs déployés ',
  noAreaToDisplayLabel: 'L\'utilisateur selectionné n\'a pas des zone pour les afficher',
  securePasswordConditions: 'Minimum 10 caractères alphanumériques avec, de préférence, au moins une lettre en majuscule, un nombre et un symbole',
  userType: 'Vous êtes (merci de choisir l\'option qui s\'applique)',
  errorInSignUp: 'Une erreur s\'est produite lors de votre processus d\'inscription, merci de vérifier les informations saisies.',
  successfulSingup: 'Merci de vous être inscrit à la plateforme Seabex, nous vous avons envoyé un e-mail de validation. Veuillez cliquer sur le lien pour activer votre compte.',
  registrationSuccLabel: 'Inscription réussi',
  registrationErrorLabel: 'Erreur d\'enregistrement ',
  accountExistLabel: 'Le compte existe déjà ! ',
  invalidInvitationVoucher: 'Le code d\'invitation est invalide !',
  _MOBILE_ALREADY_EXISTS_: 'Ce numéro de téléphone est attaché à un autre compte !',
  dontHaveAnAccount: 'Si vous n\'avez pas de compte',
  signin: 'Se connecter',
  alreadyRegistered: 'Déjà inscrit ? connectez-vous...',
  signup: 'S\'inscrire',
  logout: 'Se déconnecter',
  loginInvalidLabel: 'l\'Email ou le mot de passe est incorrect réessayez',
  mailInvalid: 'Email invalide',
  formFillText: 'Merci de remplir ce formulaire pour vous enregistrer sur la plateforme :',
  formFillTextAddUser: 'Merci de remplir ce formulaire pour enregistrer un nouvel utilisateur sur la plateforme :',
  acceptTerms: 'Pour procèder, merci de cliquer ici pour confirmer que vous accepter les <a href=\'https://seabex.com/conditions-generales-dutilisation/\' target=\'_blank\'>termes et conditions d\'utilisation</a> et <a href=\'https://seabex.com/politique-de-confidentialite/\' target=\'_blank\'>la politique de confidentialité</a>',
  Farmer: 'Producteur',
  Super: 'Administrateur',
  Expert: 'Expert',
  Institution: 'Institute',
  searchCountry: 'Recherche pays',
  specifications: 'Caractéristiques',
  searchGovernorate: 'Rechercher gouvernorat',
  governates: 'Gouvernorats',
  delegations: 'Délégations',
  city: 'Ville',
  country: 'Pays',
  government: 'Gouvernement',
  confirmBtn: 'Confirmer',
  cancelBtn: 'Annuler',
  add: 'Ajouter',
  'delete': 'Supprimer',
  editLabel: 'Modifier',
  backLabel: 'Arrière',
  start: 'Début',
  open: 'Ouverture',
  close: 'Fermeture',
  openAction: 'Ouvrir',
  closeAction: 'Fermer',
  stateOpen: 'Ouverture',
  stateClosed: 'Fermeture',
  addNode: 'Ajouter Noeud',
  confirmationTitle: 'Confirmation',
  resetSelection: 'Réinitialiser la sélection',
  addSelection: 'Ajouter une sélection',
  startAction: 'Action début',
  endAction: 'Action de fin',
  save: 'Sauvegarder',
  saved: 'Enregistrement effectué avec succès !',
  cancel: 'Annuler',
  findLabel: 'Trouver',
  send: 'Envoyer',
  operationSucc: 'Votre opération a été effectué avec succés',
  ok: 'OK',
  yes: 'Oui',
  no: 'Non',
  reset: 'Réinitialiser',
  finishLabel: 'Terminer',
  newSearch: 'Nouvelle recherche',
  submit: 'Enregistrer',
  remove: 'Supprimer',
  addLigneLabel: 'Ajouter une ligne',
  validateBtnLabel: 'Valider',
  confirmationbtnLabel: 'Confirmation',
  confirmDelete: 'Confirmation de suppression',
  confirmAction: 'Confirmer l\'envoi de l\'action',
  warningLabel: 'Avertissement',
  title: 'Titre',
  description: 'Description',
  type: 'Type',
  fieldRequired: 'Champs obligatoires ',
  passwordDntMatch: 'Le mot de passe ne correspond pas',
  emailInvalidLabel: 'Email Invalide',
  minLength: 'Doit avoir une longueur minimale de ',
  emailNotValid: 'Email non valide',
  name_label: 'Nom',
  required: 'Ce champ est obligatoire',
  mismatch: 'confirmation ne correspond pas',
  name_ar: 'Nom en Arabe',
  name_fr: 'Nom en Français',
  name_en: 'Nom en Anglais',
  unity: 'Unité',
  status: 'Status',
  'public': 'Publique',
  'private': 'Privée',
  nonProfit: 'Non lucratif',
  CompanyLabel: 'Entreprise',
  companyNameLabel: 'Nom de l\'entreprise',
  modelPackName: 'Nom du Modéle',
  addModelPack: 'Ajouter un Modéle',
  editModelPack: 'Modifier un Modéle',
  editCategory: 'Modifier Catégorie',
  seabexCategoryStation: 'Catégorie du station',
  seabexModelCategory: ' Catégorie du model',
  category: 'Catégorie',
  addCategory: 'Ajouter Catégorie',
  addCategoryModel: 'Ajouter Model',
  editCategoryModel: 'Modifer Model',
  userCatalogManagement: 'Gestion des catalogue',
  serielNumber: 'Numéro de série',
  categoryName: 'Nom de la Catégorie',
  seabexModelType: 'Modèle Seabex',
  categories: 'Catégories',
  portNameLabel: 'Nom du port',
  portTypeLabel: 'Type du port',
  categoryFamily: 'Famille de catégorie',
  errorHandler: 'Gestion des erreur',
  andLabel: 'Et',
  error_text: 'Erreur',
  errorList: 'Liste des erreurs',
  statusCode: 'Code Http',
  itemManagmentLabel: 'Gestion des articles ',
  item: 'Article',
  itemCategory: 'Catégorie d\'article',
  itemsLabel: 'Les articles',
  otherInformations: 'Autres Renseignements',
  profile: 'Profil  ',
  userPersonnelInfo: 'Informations utilisateur',
  seeProfile: 'Voir le Profil',
  activesince: 'Actif depuis',
  addExpense: 'Ajouter des dépenses',
  farmers: 'Fermiers',
  experts: 'Experts',
  viewMore: 'Voir Plus',
  avatarImportationLabel: 'Importez votre avatar ',
  birthDateLabel: 'Date de naissance (facultatif) ',
  joinedLabel: 'Inscrit le  : ',
  lastConnectionLabel: 'Dernière connexion : ',
  addDataType: 'Ajouter un Type de données',
  editDataType: 'Modifier le type de données',
  datatype: 'Types de données',
  dataTypeManagment: 'Gestion des types de données',
  dataTypeNature: 'Nature du type de données',
  abbreviationLabel: 'Abréviation',
  systemLabel: 'Système',
  SingularLabel: 'Singulier',
  pluralLabel: 'Pluriel',
  defaultUnit: 'Unité par défaut',
  unitListsLabel: 'Listes des unités',
  dataTypeListOfLabel: 'Liste des types de données de',
  noDataTypeSelectedYetLabel: 'Aucun type de données sélectionné pour le moment',
  dataTypesDisplayed: 'Types de données à afficher:',
  managingQuotasLabel: 'Gérer mes quotas  ',
  quotaLabel: 'Quota  (m3)',
  quota: 'Quota',
  seasonStartLabel: 'Début de saison ',
  seasonEndLabel: 'Fin de saison ',
  addQuota: 'Ajouter un quota ',
  editQuota: 'Modifier le quota ',
  organization: 'Organisations',
  contactOrganization: 'Contacter l\'Organization',
  organizationName: 'Nom de l\'organisme',
  newAffiliatedorganization: 'Nouvelle organisation affiliée',
  editAffiliatedorganization: 'Modifier organisation affiliée',
  organizationcity: 'Ville de l\'organisme',
  Organization: 'Organisation ',
  formula: 'Formule',
  myformulas: 'Mes formules',
  allFormulas: 'Toutes les formules',
  seeFormula: 'Voir formule',
  FormulaName: 'Nom de la formule',
  newFormula: 'Nouvelle formule',
  formules: 'Formules',
  formulasList: 'Liste des formules',
  formulaDisplay: 'C\'est ta formule',
  owner: 'Propriétaire',
  serviceListLabel: 'Les services',
  useFormulaLabel: 'Utiliser la formule',
  useAlgorithmLabel: 'Utiliser l\'algorithme',
  algorithmLabel: 'Algorithme',
  addVariableToDataType: 'Ajouter une variable au type de données sélectionné',
  nbbeneficiers: 'Vérifier le nombre d\'abonné ',
  AdvicesService: 'Service de notifications',
  newNotification: 'Nouvelle notification',
  notificationType: 'Type notification',
  others: 'Autres',
  content: 'Contenu',
  sendingDate: 'Date d\'envoi',
  lastNotifs: 'Dernières notifications',
  allStepsCompleted: 'Toutes les étapes terminées',
  color: 'Choisir la Couleur',
  plantType: 'Type de Plante',
  harvestDate: 'Date de récolte',
  activityTypes: 'Type d\'activité',
  expenses: 'Dépenses',
  revenues: 'Revenues',
  addRevenue: 'Ajouter Revenue',
  quantity: 'Quantité',
  planification: 'Planification',
  planning: 'Planification',
  Statistics: 'Statistiques',
  model: 'Modèle',
  Settings: 'Paramètres',
  userGeoDataManagement: 'Gestion des données géographiques',
  dashboardLabel: 'Tableau de bord',
  processingtimeLabel: 'Temps de traitement',
  CounterLabel: 'Compteur',
  allRequestStatusLabel: 'Toutes les demandes des statut',
  requestStatusByDateLabel: 'Demander des journaux d\'état par date',
  requestStatusLogsLabel: 'Demander des journaux d\'état',
  refreshAreaManagement: 'Actualiser la liste des sites',
  cleanDeletedAreas: 'Supprimer définitivement les sites effacés',
  introSeabexTitleGuide: 'Bienvenue',
  changeLanguageTitleGuide: 'Change the language of the guide title',
  changeStepsTitleGuide: 'change Steps Title Guide',
  stepperTriggerTitleGuide: 'stepper Trigger Title Guide',
  ariticleTitleGuide: 'Accueil',
  introSiteTitleGuide: 'Introduction Sites',
  addSiteTitleGuide: 'Ajout d’un Site via ‘+’',
  goToButtonTitleGuide: 'L’ajout de sites ‘Aller à’',
  goToModalTitleGuide: 'La latitude en combinaison avec la longitude',
  drawAreaButtonsTitleGuide: 'Sites ‘ajouter une nouvelle zone’',
  drawPolygonRectangleButtonTitleGuide: 'Dessin des Sites sur le map',
  addNewAreaModalTitleGuide: 'Les champs pour ajouter le site',
  areaOnMapTitleGuide: 'Le cadre du site',
  areaSoilStructureTitleGuide: 'La composition du sol',
  nextButtonAddAreaTitleGuide: 'Suivant',
  dataSourceTableTitleGuide: 'Type de data sources de data',
  addNewSourceTitleGuide: 'Ajouter des Type de data & sources de data',
  saveBtnAddAreaTitleGuide: 'Enregistrer',
  areasListTitleGuide: 'Notification d’enregistrement d’un nouveau site',
  addAreaUsingFileTitleGuide: 'Ajout d’un nouveau site par l’épingle',
  addParcelSectorTitleGuide: 'Vue de liste des site',
  introCycleTitleGuide: 'Introduction Cycles',
  addCycleBtnTitleGuide: 'Ajout d’un cycle via ‘+’',
  addCycleModalTitleGuide: 'Les champs pour ajouter le cycle',
  cycleListTitleGuide: 'Liste des cycles',
  mapViewBtnTitleGuide: 'Introduction one pager Rapport',
  onePagerMeteoTitleGuide: 'Rapport one pager Meteo ',
  onePagerWaterNeedTitleGuide: 'تقرير متطلبات المياه',
  onePagerWaterNeedHierarchyTitleGuide: 'تقرير شجرة الحساب',
  onePagerWaterNeedHistoryTitleGuide: ' Rapport historique des besoins en eau',
  introDeployedDevicesTitleGuide: 'Introduction appareil déployés',
  addDeployedDeviceBtnTitleGuide: 'Ajout d’un appareil déployés via ‘+’',
  addDeployedDeviceModalTitleGuide: 'The fields to add the Device',
  addDeployedDeviceModal1TitleGuide: 'Modal Step 1',
  addDeployedDeviceModal2TitleGuide: 'Modal Step 2',
  addDeployedDeviceModal3TitleGuide: 'Modal Step 3',
  realTimeintroTitleGuide: 'real Time intro Title Guide',
  realTimeTitleGuide: 'real Time Title Guide',
  logsIntroTitleGuide: 'logs Intro Title Guide',
  logsTitleGuide: 'logs Title Guide',
  dialog_notNow: 'Pas maintenant',
  dialog_okAcceptance: 'Oui',
  dialogRecalculateTitle: 'Cette opération de recalcul peut prendre plusieurs minutes. Quand le recalcul est terminé, vous en serez notifié. Merci de consulter les résultats dans la page dédiée à la parcelle concernée en cliquant sur le bouton \'informations et préconisations\' dans la ligne de la parcelle concernée.',
  helpAndSupport: 'Aide et soutien',
  watchGuideAgain: 'Regardez à nouveau le guide',
  addAreaNameGuide: 'Nom du site',
  addAreaDescriptionGuide: 'Description du site',
  introSeabexGuide: 'Notre tutoriel vous aide à avoir la meilleure expérience sur notre plateforme:\n- La création d’un site/Sous-sites (parcelle/secteur)\n- La création d’un cycle\n- Les appareils déployés\n- Temps réel',
  changeLanguageGuide: 'Veuillez choisir parmi 3 langues à utiliser:\n- Français\n- Anglais\n- Arabe',
  changeStepsGuide: 'change Steps Guide\nVeuillez passer à l\'étape suivante',
  stepperTriggerGuide: '::stepperTrigger::stepperTriggerGuide\nVeuillez cliquer sur le point d’interrogation, le tutoriel clarifiant une rubrique parmi le menu par exemple Sites sera affiché.',
  articleGuide: 'Veuillez trouver dans l\'accueil, les actualités & les événements qui vous intéressent\n(Exemple: Une formation des agrumes par le Centre Technique des Agrumes (CTA) )',
  introSiteGuide: 'Veuillez commencer par la création des sites/parcelles et secteurs:\nEn effet, un site ( un emplacement dédié aux activités d’agriculture\nUn sous-site/ une parcelle (Portion de terrain de même culture/végétation) et un secteur (Subdivision d\'une parcelle).\n \nVous commencez maintenant par la création d\'un site.',
  addSiteGuide: 'Veuillez commencer par appuyer sur le bouton ‘+’ pour ajouter un nouveau site, vous allez accéder automatiquement à la \'Map View/ vue de la carte\' pour choisir le lieu et dessiner le site sur le World Map.',
  goToButtonGuide: ' Veuillez aller rapidement à n\'importe quel lieu en cliquant sur \'Aller à \' ce bouton vert afin d’avoir une navigation facile et rapide.',
  goToModalGuide: 'Veuillez appuyer sur ‘Aller à’, vous aurez automatiquement un popup d\'un formulaire. Vous avez le choix:\n- Soit de remplir le premier champ avec le nom de pays/ville/ adresse de rue ou lieu connu.\n- Ou bien d\'insérer la latitude en combinaison avec la longitude pour indiquer la position précise d\'un élément sur Terre. Veuillez cliquer sur Aller â coordonnées pour avoir le lieu.',
  drawAreaButtonsGuide: ' Veuillez appuyer sur le bouton ajouter une nouvelle zone afin d\'ajouter un nouveau site.',
  drawPolygonRectangleButtonGuide: '::stopEditing::Si vous voulez arrêter le dessin des limites du site avant le terminer et revenir à l’étape précédente.\n::addAreaPolygon::Pour le polygone , veuillez cliquer une fois sur un point sur les limites du site et finir par cliquer sur le même point du départ.\n::addAreaRectangle::Pour le rectangle, Veuillez cliquer une fois sur la souris, glissez selon la taille du site et finissez par relâcher la souris.\n En appuyant sur le bouton espace du clavier, ces formes seront désactivées et en appuyant une autre fois elles seront réactivées.\n::mapLayers::Vous avez le world Map en plusieurs versions (Satellite, terrain etc...) à travers le bouton des rectangles superposés à droite en haut sur le map.\n::drawAreaButtons::Si vous voulez arrêter le dessin des limites du site avant le terminer et revenir à l’étape précédente.........',
  addNewAreaModalGuide: 'Si vous terminez le dessin des limites du site, un formulaire avec des champs à remplir va apparaître.\n\nVous aurez la possibilité de:\n- Noter le titre du site (champ obligatoire)\n- Noter le champ description (champ non obligatoire)\n\n;;Cas 1:;; Si vous choisissez le type de sol recommandé coché par défaut, vous aurez les champs apparaissant  successivement l’un après le remplissage de l’autre:\n* ‘La famille d\'irrigation’ à remplir avec 3 choix\n* ’Type d’irrigation’ à remplir avec 4 choix\n;;Cas 2:;; Si vous cochez  ‘choisissez mon type de sol’, vous aurez les champs apparaissant  successivement l’un après le remplissage de l’autre:\n* ‘Type de sol’\n* ‘Texture de sol’ à remplir\n* ‘La famille d\'irrigation’ à remplir avec 3 choix\n* ‘Type d’irrigation’ à remplir avec 4 choix',
  areaOnMapGuide: '- Le site créé avec un cadre bleu sur la carte selon les limites dessiné aux étapes précédentes.',
  areaSoilStructureGuide: '- Vous aurez une composition détaillée du sol du site.\n* À gauche vous trouvez les différentes profondeurs du sol\n* Chaque ligne représente le pourcentage de chacun de ces éléments dans votre sol à ce niveau.\n*À droite vous trouvez les composants essentiels des sols : l\'argile, le limon et le sable.\n*En bas vous trouvez le pourcentage de chacun de ces éléments dans votre sol.',
  nextButtonAddAreaGuide: 'Une fois les champs sont remplis, veuillez avancer dans la configuration du site en appuyant sur le bouton SUIVANT',
  dataSourceTableGuide: 'Vous pouvez aller au niveau de choisir parmi les paramètres ‘Type de data’ ou en ajoutant les vôtres, obtenus en téléchargeant auparavant des fichiers ‘sources de data’ ou bien en choisissant celles par défaut comme’ Seabex Default’.',
  addNewSourceGuide: '- Pour ajouter un nouveau data type au niveau des paramètres ‘Type de data’.\n- Vous pouvez ajouter vos propres types de données en cliquant sur le bouton +.\n- Par défaut, ce dernier type de données va être ajouté à la fin de la liste des autres données.\n- De plus ce dernier va avoir par défaut “SEABEX DEFAULT” comme source mais vous pouvez en choisir une autre.',
  saveBtnAddAreaGuide: 'Une fois les champs sont remplis, vous pouvez finir la configuration du site en appuyant sur le bouton ENREGISTRER',
  areasListGuide: 'Une fois les étapes de la création d’un nouveau site sont terminés,\n::snackBar::Une notification va apparaître en bas à gauche de l’écran:\n;;- Cas 1:;; Si le site n’est pas créé à cause d’une erreur, la notification va être rouge affichant ‘erreur’\n;;- Cas 2:;; Si le site est créé, la notification va être verte affichant ‘Ajouté avec succès’\n;;-Cas 3:;; ......\n\nD\'autre, vous pouvez voir une liste de tous les sites créés des actions sur chaque site (comme modifier ou supprimer).\n',
  addAreaUsingFileGuide: 'Vous avez une autre méthode pour ajouter un nouveau site:  Veuillez appuyer sur l’épingle pour télécharger un fichier KML, JSON ou GeoJSon contenant les coordonnées du site.\nEn téléchargeant le fichier, vous serez automatiquement dans l’étape du formulaire de la création d’un site les mêmes étapes.',
  addParcelSectorGuide: 'Ajout d’une sous-partie (parcelle d’un site ou un secteur d’une parcelle):\n::mapButton:: En appuyant sur ce bouton, vous allez accéder automatiquement au mapview et avoir la possibilité d’ajouter une sous-partie (parcelle d’un site ou un secteur d’une parcelle).\nDans le map, en faisant d’un double clic dans un site, vous pouvez créer une sous-partie. (parcelle d’un site ou un secteur d’une parcelle).........\n::arrowSite:: En appuyant sur la flèche à gauche des noms de sites, une liste des sous-sites/ parcelles s’affiche en dessous.',
  introCycleGuide: 'Vous commencez par la création d’un cycle afin d’avoir plusieurs informations utiles à propos de vos cycles comme le besoin en eau.\nEn effet, le type de production peut être classifié suivant différents critères:\n;;la portée de l’activité agricole:;; à des fins de consommation ou industrielles, ces dernières peuvent avoir à leur tour une finalité alimentaire (arachide) ou de transformation industrielle (matière première: coton)\n;;le type de culture:;;Pérenne (arbres fruitiers) ou annuelle (maïs)\n;;l’objectif de la production animale:;;Consommation alimentaire ou valorisation des sous-produits, etc.',
  addCycleBtnGuide: 'Veuillez commencer par appuyer sur le bouton ‘+’ pour ajouter un nouveau cycle et vous aurez un formulaire à remplir contenant plusieurs champs.',
  addCycleModalGuide: 'Les champs à remplir pour déclarer le cycle:\n- Site: choisir le site pour lequel vous avez ce cycle\n- Famille de culture: Il ya 4 familles cultures fourragères, arboricultures, cultures maraîchères,  céréales \nEn choisissant une parmi ces familles, un champs des sous familles apparaît:\n- Culture: dépend de la famille de culture choisisse\n- La date de début du cycle: Depuis combien du temps ces cultures sont plantés\n::cycleDate:: \n- Après l\'agriculteur va choisir:\n- (a) Dans le cas: Utiliser le cycle de production recommandé  les champs suivantes apparaissent:\nCycle de Production*\nProfondeur d\'enracinement (mètre)*\nNombre de jours d\'irrigation (champs non-obligatoire)\nQuantité d\'eau dans le sol  (champs non-obligatoire)\nCapacité au champ*\nPoint de flétrissement*\n::recommandedProductionCycle:: \n \n- (b) Dans le cas: Personnalisez votre propre cycle de production les champs obligatoires suivantes apparaissent:\nPhase initiale*\nPhase de développement*\nPhase mi-saison*\nPhase arrière-saison*\nProfondeur d\'enracinement (mètre)*\nNombre de jours d\'irrigation\nQuantité d\'eau dans le sol\nCapacité au champ*\nPoint de flétrissement*\n::productionCycle:: Le calcul en besoin en eau et la préparation d’un rapport ‘one pager’ sera calculé automatiquement et vous attendez jusqu’à le loading se terminera\n- Cas 1: Si le cycle n’est pas créé à cause d’une erreur, la notification va être rouge affichant ‘erreur’\n \n::cultureCycle:: \n- Cas 2: Si le cycle est créé, la notification va être verte affichant ‘Ajouté avec succès’\n- Cas 3: Si le cycle existe déjà pour ce site (sera double),lLa notification va être bleu. \n::existCycleNotif:: \n',
  cycleListGuide: 'Une fois les champs remplis et les étapes de la création d’un nouveau cycle sont terminés, vous vous trouvez automatiquement la liste de vos cycles.\nLes colonnes contenant:\n- Les noms des sites\n- Noms de cultures\n- Date de début du cycle (Lorsqu’ils sont plantés)\n- leurs descriptions insérées par vous précédemment dans le formulaire de la création du site.\n* Vous obtenez les informations comme valeur ajouté du SEABEX :\n-Nombre de jours depuis l’implantation\n-Fin de cycle: Peut être terminé selon la durée recommandée automatiquement ou bien manuellement en cliquant sur le bouton\n-La phase dans laquelle se trouve votre culture:  phase initiale, phase de développement, phase mi-saison, phase arrière saison.\n- En appuyant sur la flèche à gauche des noms des cycles, la phase dans laquelle se trouve votre culture  + la date de début et de fin recommandée pour votre cycle s’affiche en dessous.',
  mapViewBtnGuide: 'Veuillez appuyer sur le bouton à droite,  vous allez accéder automatiquement au mapview et avoir “un one pager”, un rapport contenant:\n- La structure du sol dans la zone sélectionnée\n- Les données météorologiques:\n- Détails du cycle\n- l\'historique des besoins en eaux avec des courbes\n- les détails de l\'irrigation\n- la quantité d\'eau à irriguer\n- l\'arborescence du calcul du besoin en eau',
  onePagerMeteoGuide: 'Nom de culture : (exemple: Haricots vert) et les paramètres: Quantité à irriguer et ses inputs par exemple Evapotranspiration Réelle',
  onePagerWaterNeedGuide: 'onePagerWaterNeedGuide 24',
  onePagerWaterNeedHierarchyGuide: 'Veuillez  trouver l\'arborescence du calcul du besoin en eau,chaque ligne représente les inputs nécessaires pour le paramètre dans la ligne en dessus jusqu’à avoir la quantité d’eau à irrigué.\nToutes les données sont calculées pour 24',
  onePagerWaterNeedHistoryGuide: 'Veuillez choisir la période pour laquelle vous voulez visualiser la quantité d’eau irriguée et d’autres données.\nVeuillez appuyer sur\nVous allez avoir des courbes qui représentent les données suivantes qui apparaissent en cochant les cases à côté\nLa Réserve d\'eau ajustée\nIrrigation suggérée\nÉvapotranspiration de référence\nEvapotranspiration Réelle\nPrécipitations\nIrrigationQuantité à irriguer\nCapacité au champ\nPoint de flétrissement\nQuantité d\'eau dans le sol\nVeuillez trouver les données enregistrées chaque 24 h dans ce tableau comme historique de référence pour vous.',
  introDeployedDevicesGuide: 'Vous commencez par créer un appareil ',
  addDeployedDeviceBtnGuide: 'Veuillez commencer par appuyer sur le bouton «+» pour ajouter un nouvel appareil et vous aurez un formulaire à remplir avec plusieurs champs.',
  addDeployedDeviceModalGuide: 'The fields to be filled in to declare the Device:\n- Site: choose the site for which you have this Device\n- stationName : the Device name \n device Category: There are 9 Category like Gateway , Action , Acquisition \nBy choosing one among these Categories, a field of the sub-Categories appears:\n-Device Family: depends on the Category  chosen\n- Device Model : depends on the Device family  chosen\n-Identifier : the Device identifier \n',
  addDeployedDeviceModal1Guide: 'step 1 explain fields contens',
  addDeployedDeviceModal2Guide: 'step 2 explain how to draw and change location using gif image including some text',
  addDeployedDeviceModal3Guide: 'step 3 explain in use case if gateway there is no step 3 and the other use cases explain the fields to fill',
  realTimeintroGuide: 'Vous pouvez trouver la liste des appareils déployès ici.',
  realTimeGuide: '::realTimeStatus:: Vous pouvez connaît si vos etes connecter aux appareils ou pas.\n \n \n::realTimeTable:: \n \n \nVous trouvez ici la liste des appareils déployés, avec quelques actions sur les appareils (OUVERTURE, FERMETURE).\n Si vous êtes dans le cas d\'une erreur, ou bien d\'un changement d\'état, Vous serez notifier par la barre de notification.',
  logsIntroGuide: 'Vous pouvez trouver l\'historique de toutes les données capturées ici.',
  logsGuide: 'Vous pouvez chercher et lister les journaux à travers le choix de site ou bien le choix d\'un appareil déployé.\n::logsStatics:: \n Ce diagramme liste les valeurs capturées à travers le temps.\n::logsTable:: \n Vous trouvez toutes les informations capturées dans ce tableau trié avec le temps de capture.\n::downloadXSL:: Vous pouvez même télécharger un fichier de format XSL contenant toutes les données capturent en cliquant sur ce bouton',
  ordersMenu: 'Mes achats et abonnements',
  orderReference: 'Référence de ma commande',
  partner: 'Partenaire',
  orderDate: 'Date',
  orderTotal: 'Total payé',
  orderDetail: 'Détail de ma commande',
  ordersList: 'Liste de mes achats',
  nowConnected: 'Vous êtes maintenant connecté aux notifications instannées !',
  nowDisconnected: 'Vous vous êtes déconnecté des notifications instannées !',
  productionCycleCalculationNotification: "Le calcul pour le cycle de production de la culture < %culture_name% > sur < %area_name% > a été relancé",

  // Payment Manager
  paymentConfirming: "Confirmation du paiement en cours...",
  paymentSuccessful: "Votre paiement a été pris en compte, vous recevrez une confirmation par email.",


  // extra

  impersonatingUser: "Vous êtes en train d'usurper le compte utilisateur de ",
  sessionEndsIn: "La session se termine dans ",
  leaveImpersonation: "Quitter l'usurpation",
  solMaximumDepth: 'Profondeur Maximale de Sol Exploitable',
  unknownDepth: "Je ne connais pas ma profondeur par défaut",
  compactionRate: "Taux de Tassement",
  greenFruits: "Fruits verts",
  changeStartDate: "Pour changer la date de début d'un stade phénologique, cliquer dessus sur la barre de progression.",
  totalCumulativePrecipitation: "Total et cumul des précipitations par mois",
  demandeAssistance: 'Demande d\'assistance',
  videosTutoriaux: 'Vidéos de Tutoriaux',
  rainfallPerMonth: 'Précipitations par mois',
  irrigationPerMonth: "Irrigation par mois",
  dontKnow: 'Je ne la connais pas',
  numberDripperPerFoot: "Nombre de gouttes par pieds",
  flowRatePerDripper: "Debit par gouteur",
  speed: 'Vitesse',
  radius: 'Rayon',
  permissions: 'Droits d\'accès',
  unit: 'Unité',
  yield: 'Rendement',
  quintals: 'Quintaux par hectare (q/ha)',
  tons: 'Tonnes par hectare (t/ha)',
  kilograms: 'Kilogrammes par hectare (kg/ha)',
  // Alert Message
  'Updates are in progress, sorry if you encounter any instability.': "Des mises à jour sont en cours, désolé si vous rencontrez une quelconque instabilité."
}
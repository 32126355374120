import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ScenariosTable from './ScenariosTable';

import { CircularProgress } from '@material-ui/core/';



import { setCurrentPage, loadScenario, loadStations } from '../../actions';
import { vocabulary } from '../Strings';



function Scenario(props) {

    const dispatch = useDispatch()

    const appLoading = useSelector(state => state.app.appLoading)   
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    // const scenario = useSelector(state => state.scenario.data)

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.scenarioLabel))
        dispatch(loadScenario())
        dispatch(loadStations())
        document.title=vocabulary.scenarioLabel

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.scenarioLabel))
        document.title=vocabulary.scenarioLabel

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    return (

        <>
            <div style={{
                visibility: appLoading ? 'visible' : 'hidden',
                position: 'absolute',
                left: '50%',
                top: '45%',
                width: '100%'


            }}>
                <CircularProgress size={50} />
            </div>


            <div style={{ opacity: appLoading ? 0.4 : 1, width: '100%' }} >



                <ScenariosTable />
            </div>
        </>
    )

}


export default Scenario;


import { Grid, Button, Typography } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import React from 'react'
import { useLocation } from 'react-router'

const ActivationStatus = props => {
    const search = useLocation().search;
    const language = new URLSearchParams(search).get('language');
    const status = new URLSearchParams(search).get('status');

    return (
        <div style={{display:'flex', alignItems:'center', height:`100%`, backgroundColor:status==='success' ? `#0ca606` : `#954114`}}>
            <Grid container direction='column' style={{width:'auto', margin:'0 auto'}}>
                <Grid item>
                    { status==='success' ? <CheckCircle style={{color:'white',width:60, height:60}} /> : <Cancel style={{color:'white',width:60, height:60}} /> }
                </Grid>
                <Grid item style={{marginTop:20}}>
                    { status==='success' ? <Typography  style={{color:'white', fontSize:36}}>{language==='fr' ? 'Parfait ! Votre compte est activé !' : 'Perfect! Your account is now active!'}</Typography> 
                        : <div>
                            <Typography  style={{color:'white', fontSize:36}}>{language==='fr' ? "Oups ! L'activation de votre compte n'a pas réussit !" : 'Oups! Your account activation was not successful!'}</Typography> 
                            <Typography  style={{color:'white', fontSize:18}}>{language==='fr' ? "Peut être que vous l'aviez déjà activé ?" : 'Maybe you already did it?'}</Typography> 
                          </div>
                    }
                </Grid>
                <Grid item style={{marginTop:20}}>
                    <Button variant="outlined" color="white" href="/" style={{color:'white', borderColor:'white'}}>{language==='fr' ? 'Essayez de vous connecter...' : 'Try to connect...'}</Button>
                </Grid>
            </Grid>
        </div>
    )
}


export default ActivationStatus

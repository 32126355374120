/* eslint-disable no-unused-vars*/

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardContent, CircularProgress, Card } from '@material-ui/core/';
import { vocabulary } from './Strings';
import { resetUserPassword, displayWarning } from '../actions';
import { StyledSubmitButton } from "../ui/forms";
import StyledInput from "../ui/forms/StyledInput";
import farm from '../images/farm.jpg';
import { useHistory } from "react-router-dom";


// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const ForgetPassword = (props) => {

  const dispatch = useDispatch()
  const form = useRef(null);
  let history = useHistory();

  const appLoading = useSelector(state => state.app.appLoading)

  const [email, setEmail] = useState(null);
  const [snackbar, setSnackbar] = useState({})



  const resetPassword = () => {
    dispatch(resetUserPassword({ email: email },
      function (r) {
        if (r) {
          if (r.status === 200) {
            //  self.props.history.push('/app/sites'); 

            dispatch(displayWarning(vocabulary.emailCheckPassword))
            history.push('')
          }
          else if (r.status === 403) {
            // setState({ snackbar: { show: true, message: "Veuilliez verifier vos codes d'accès" } }); 
            setSnackbar({ show: true, message: vocabulary.checkAccessCode })
          }
          else {
            // setState({ snackbar: { show: true, message: "Une erreur est survenue" + r.status } });
            setSnackbar({ show: true, message: vocabulary.errorOccurred + r.status })

          }
        }
      }
    )
    );
  }
  return (


    <div>
      <div style={{
        visibility: appLoading ? 'visible' : 'hidden',
        position: 'absolute',
        left: '50%',
        top: '45%',

      }}>
        <CircularProgress size={50} />
      </div>

      <div style={{
        flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: "url(" + farm + ")", padding: 10, minHeight: '100vh',
        opacity: appLoading ? 0.4 : 1
      }}>

        <form
          ref={form}
          onSubmit={resetPassword}
        >

          <Card>
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StyledInput
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  name="email"
                  label={vocabulary.email}
                  autoComplete="email"
                />

              </div>
            </CardContent>
            <StyledSubmitButton label={vocabulary.resetPassword} />

          </Card>

        </form>

      </div>

    </div>
  );

}

export default ForgetPassword;
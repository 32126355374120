import React from "react";

import { vocabulary } from '../Strings';

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';


const RecalculDialogConfirmation = ({ openConfirmation, onClose, recalculateWaterNeed, isLoading }) => {


    return (
        <Dialog
            open={openConfirmation}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title">{vocabulary.recalculateLabel} </DialogTitle>
            {
                !isLoading ?
                    <>
                        <DialogContent>

                            <DialogContentText id="alert-dialog-description">
                                    {vocabulary.dialogRecalculateTitle}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                {vocabulary.dialog_notNow}
                            </Button>
                            <Button onClick={recalculateWaterNeed} color="primary" autoFocus>
                                {vocabulary.dialog_okAcceptance}
                            </Button>
                        </DialogActions>

                    </>
                    :
                    <div style={{ width: '500px' }}> <StyledCircularProgress size={50} label={vocabulary.loading} height={'200px'} /> </div>
            }

        </Dialog>

    )
}
export default RecalculDialogConfirmation
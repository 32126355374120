import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from '@material-ui/core/';
import 'bootstrap/dist/css/bootstrap.css';
import { ModalContext } from '../..//ui/layout/contextLayout/ModalContext';

import {  setCurrentPage, loadAllCountries } from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';

import FollowIcon from '@material-ui/icons/HowToReg';

import { ListItemText } from '@material-ui/core/'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UnfollowIcon from '@material-ui/icons/PersonAddDisabled'
import SmsIcon from '@material-ui/icons/Sms';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import PeoleIcon from '@material-ui/icons/People';
import QueueIcon from '@material-ui/icons/Queue';
import Slide from '@material-ui/core/Slide';
import 'react-phone-number-input/style.css'
// import { useHistory } from 'react-router-dom';
import AgentForm from './AgentForm'
import NotificationForm from './NotificationForm'
import AddOrganismeForm from './AddOrganismeForm'
import AlertDialog from "../Components/alertDialog";
import { StyledCard } from "../../ui/layout";

import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import ListOrganizationFollowers from './ListOrganizationFollowers'
import { GetMyOrganizationsandFollowers,DisableOrganisme,EnableOrganization,DeleteOrganization } from '../../globalsTools/network'
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListallOrganizations = (props) => {

    const dispatch = useDispatch()
    // const form = useRef(null);
    // const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const appLoading = useSelector(state => state.app.appLoading)

    //const organizations = useSelector(state => state.organization.data)
    const countries = useSelector(state => state.countries.data)
    const cities = useSelector(state => state.cities.data)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)


    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [agentOpen, setAgentOpen] = useState(false);
    const [openLatestNotifs, setOpenLatestNotifs] = useState(false);
    const [currentRow, setCurrentRow] = useState([])
    const [currentFarmers, setCurrentFarmers] = useState([])
    const [currentNonSeabexFarmers, setCurrentNonSeabexFarmers] = useState([])
    const [mobile, setMobile] = useState('')
    const [selectedItem, setselectedItem] = useState(null)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)


    const [currentOrganismeId, setCurrentOrganismeId] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [organisationList, setOrganisationList] = useState(null)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.organization));
        dispatch(loadAllCountries())
        GetMyOrganizationsandFollowers(setOrganisationList)
        document.title=vocabulary.organization

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.organization));
        document.title=vocabulary.organization

    }, [dispatch,currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const handleClickOpen1 = (row) => {


        setOpen1(true)
        setCurrentRow(row)
        setCurrentFarmers(row.farmers.length > 0 ? row.farmers : [])
        setCurrentNonSeabexFarmers([])


    }
    const handleClickNotificationOpen = (tel) => {
        setNotificationOpen(true)
        setMobile(tel)
    }
    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const handleClickOpen3 = (id) => {
        setAgentOpen(true)
        setCurrentOrganismeId(id)
    }

    const handleClose1 = () => {
        setOpen1(false)
    }
    const handleNotificationClose = () => {
        setNotificationOpen(false)
    }
    const handleCloseAddAgent = () => {
        setAgentOpen(false)
    }



    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };


    const handleCloseLatestNotifs = () => {
        setOpenLatestNotifs(false)
        setCurrentRow([])
    }


    const toDeleteOrganization = (id) => {
        DeleteOrganization(id).then(() => GetMyOrganizationsandFollowers(setOrganisationList)
        )
    }

    const toUpdateOrganization = (row) => {
        setIsEditing(true)
        setOpen(true)
        setselectedItem(row)
    }

    const disableOrganisme = (row) => {
        DisableOrganisme(
            {
                active: false
            }, row.id 
            , function (r) {
                if (r) {
                    if (r.status === 200) {
                        snackbar(vocabulary.unfollow + ' ' + JSON.parse(row.name).name, 'success')
                        GetMyOrganizationsandFollowers(setOrganisationList)
    
                    } else {
                        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                    }
                }
            }
            )
            // .then(() => GetMyOrganizationsandFollowers(setOrganisationList)
            // );
    }

    const EnableOrganisme = (row) => {
        EnableOrganization({
            active: true
        }, row.id
        , function (r) {
            if (r) {
                if (r.status === 200) {
                    snackbar(vocabulary.subscribe + ' ' + JSON.parse(row.name).name, 'success')
                    GetMyOrganizationsandFollowers(setOrganisationList)
                } else {
                    snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                }
            }
        })
         
    }

    const countriesList = countries.map(item => (

        {
            value: item["identifier"],
            label: JSON.parse(item.name)["name_" + currentLanguage],
            countryId: item.id

        }
    ));
    const citiesList = cities.map(item => ({
        value: item["id"],
        label: JSON.parse(item.name)["name_" + currentLanguage]
    }));

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setAgentOpen(!agentOpen)
            setNotificationOpen(!notificationOpen)
            setOpen(!open)
        } else {
            setAgentOpen(newStatus)
            setNotificationOpen(newStatus)
            setOpen(newStatus)
            setAgentOpen(newStatus)
            setIsEditing(newStatus)

        }
    }


    const contextNotificationValue = { open: notificationOpen, changeModalStatus: changeModalStatus }
    const contextAgentValue = { open: agentOpen, changeModalStatus: changeModalStatus }
    const contextAddOrganisme = { open: open, changeModalStatus: changeModalStatus }


    return (
        <ModalContext.Provider value={notificationOpen === true ? contextNotificationValue : (agentOpen === true ? contextAgentValue : contextAddOrganisme)}>
            <StyledCard>

                {organisationList ?
                    <StyledMaterialTable
                        title={vocabulary.organization}
                        data={organisationList.organizationsAndFollowers}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay },header:{actions:vocabulary.actions},pagination:{nextTooltip:vocabulary.nextpage,previousTooltip:vocabulary.previouspage,lastTooltip:vocabulary.lastpage,firstTooltip:vocabulary.firstpage,labelRowsSelect:vocabulary.rowsLabel},toolbar:{searchPlaceholder:vocabulary.search} }}

                        columns={[
                            { title: vocabulary.organizationName, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name}</> },
                            { title: vocabulary.mobile, field: 'mobile' },
                            { title: vocabulary.email, field: 'email' },
                            { title: vocabulary.status, field: 'status' },
                            { title: vocabulary.address, field: 'address', render: rowData => <>{JSON.parse(rowData.address).address}</> },
                            { title: vocabulary.nbFollowers, field: 'farmers_count' },
                            { title: vocabulary.activityTypes, field: 'active', render: rowData => <>{rowData.active === 1 ? "Active" : "Not active"}</> },

                        ]}
                        actions={[
                            {
                                icon: () => <PeoleIcon />,
                                tooltip: vocabulary.followers,
                                onClick: (event, row) => handleClickOpen1(row)
                            },
                            row => ({
                                icon: () => row.active === 1 ? <FollowIcon /> : <UnfollowIcon />,
                                tooltip: row.active === 1 ? 'Disable' : 'Active',
                                onClick: (event, row) => row.active === 1 ? disableOrganisme(row) : EnableOrganisme(row),
                            }),

                            rowData => ({
                                icon: () => <SmsIcon />,
                                tooltip: vocabulary.contactOrganization,
                                onClick: (row) => handleClickNotificationOpen(rowData.mobile)
                            }),
                            rowData => ({
                                icon: 'edit_outline',
                                tooltip: vocabulary.editLabel,
                                onClick: (row) => toUpdateOrganization(rowData)
                            }),
                            rowData => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(rowData),
                            }),
                            {
                                icon: () => <QueueIcon fontSize="small" />,
                                tooltip: vocabulary.newAgent,
                                onClick: (row) => handleClickOpen3(row.id)
                            },
                            {
                                icon: 'add',
                                tooltip: vocabulary.add,
                                isFreeAction: true,
                                onClick: () => handleClickOpen()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                        }}
                    />
                    : <StyledLoadingRow />}
                <div style={{ opacity: appLoading ? 0.4 : 1 }} >

                    <AddOrganismeForm
                        open={open}
                        handleClose={() => {
                            return (
                                handleClose(),
                                setIsEditing(false)
                            )
                        }}
                        countriesList={countriesList}
                        citiesList={citiesList}
                        isEditing={isEditing}
                        selectedItem={isEditing === true ? selectedItem : null}
                        refetchFunc={() => GetMyOrganizationsandFollowers(setOrganisationList)
                        }
                    />

                    <Dialog
                        fullWidth={true}
                        open={openLatestNotifs}
                        onClose={handleCloseLatestNotifs}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogTitle id="max-width-dialog-title">{vocabulary.lastNotifs}</DialogTitle>
                        <DialogContent>
                            <List>
                                {currentRow.length > 0 ? currentRow.map(e => {
                                    return (
                                        <>
                                            <ListItem>
                                                <ListItemText primary={JSON.parse(e.name).name} />
                                                <ListItemText primary={e.content} secondary={e.created_at} />
                                                <Divider variant="fullWidth" component="li" absolute={true} />
                                            </ListItem>
                                        </>
                                    )
                                }
                                )
                                    : null
                                }
                            </List>
                        </DialogContent>
                    </Dialog>
                    {/* List of followers and their stations  */}


                </div>
                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={'Delete'}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item && item.name).name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                toDeleteOrganization(item.id);
                            } 
                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                <ListOrganizationFollowers
                    handleClose1={handleClose1}
                    open1={open1}
                    Transition={Transition}
                    currentRow={currentRow}
                    currentFarmers={currentFarmers}
                    currentNonSeabexFarmers={currentNonSeabexFarmers}
                />
                {agentOpen &&
                    <AgentForm
                        agentOpen={agentOpen}
                        onClose={() => { handleCloseAddAgent() }}
                        countriesList={countriesList}
                        citiesList={citiesList}
                        currentOrganismeId={currentOrganismeId}
                        isAgentEditing={false}
                    />}
                {notificationOpen &&
                    <NotificationForm
                        open={notificationOpen}
                        onClose={() => { handleNotificationClose() }}
                        mobile={mobile}
                        isAgentEditing={false}

                    />
                }

            </StyledCard>

        </ModalContext.Provider>

    );
}

export default ListallOrganizations
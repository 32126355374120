/* eslint-disable  dot-location */

import React, { useState, useEffect } from 'react';

 
import AlertDialog from "../../Components/alertDialog";

import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import { GetAreaDetails, DeleteArea } from "../../../globalsTools/network";
import { StyledStaticMap } from "../../../ui/display";
import { useSnackbar } from 'notistack';
import ParcelDetailsList from './ParcelDetailList'
import {Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { logger } from '../../../globalsTools/utilities';
import { Pageview } from '@material-ui/icons';
import { vocabulary } from '../../Strings';

const AreaDetailsList = ({ itemRow, setMapView, setGoToSite, setTab, editingItem }) => {

    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [areaDetailsList, setAreaDetailsList] = useState(null)

    useEffect(() => {
        
        if (itemRow) {
            logger('AreaDetailsList : ', itemRow)
            GetAreaDetails(itemRow.id, setAreaDetailsList)
        }

    }, [itemRow])  // N’exécute l’effet que si itemRow a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const convertSize = (size) => {
        return Number(size / 10000).toFixed(2) + " " + vocabulary.hectar
    }
    const GoToSelectedSite = (row) => {
        setMapView(true)
        setGoToSite(true)
        editingItem(row)
        setTab(1)
    }
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenType(!openType)
            setOpenEdit(!openEdit)
        } else {
            setOpenType(newStatus)
            setOpenEdit(newStatus)
        }
    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                GetAreaDetails(itemRow.id, setAreaDetailsList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteArea = (item) => {
        DeleteArea(item.id
            , r => operationCallBack(r)
        )
    }
 

    const contextValue = { open: openType, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    return (

        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            <StyledCard elevation={1} variant="outlined" borderRadius={0} additionalStyle={{ border: 'none', borderLeft: '5px solid #c4e9fb' }}>

                {areaDetailsList ?
                    areaDetailsList.childs.length > 0 ?
                        <StyledMaterialTable
                            title={vocabulary.parcel}
                            data={areaDetailsList && areaDetailsList.childs}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}
                            detailPanel={[
                                {
                                    tooltip: 'Show Details',
                                    render: ({ rowData }) => { return (<ParcelDetailsList  // show sector list 
                                        itemRow={rowData}
                                        areasList={areaDetailsList.childs}
                                        setMapView={setMapView}
                                        setGoToSite={setGoToSite}
                                        setTab={setTab}
                                        editingItem={editingItem}
                                    />)}
                                    
                                }]
                            }
                            columns={[
                                { title: vocabulary.name_label, field: 'name' },
                                { title: vocabulary.description, field: 'description' },

                                { title: vocabulary.size, field: 'size', render: rowData => <>{convertSize(rowData.size)}</> },

                                { title: vocabulary.map, field: 'staticmap', render: rowData => <StyledStaticMap image={rowData.staticmap} base64={true} height={80} /> },
                                { title: '', render: rowData =>  <Button startIcon={<Pageview />} onClick={() => GoToSelectedSite(rowData)} style={{ textAlign:'start', lineHeight: `18px`}} >{vocabulary.onepage_areaDetail}</Button>},
                            ]}
                   
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false,

                            }}
                        />

                        : <div className='emptyParcelLabel'>
                            {vocabulary.emptyParcelLabel}
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={()=>GoToSelectedSite(itemRow)}
                            >
                              <AddIcon /> &nbsp;  {vocabulary.add}
                            </Button>
                        </div>
                    :
                    <StyledLoadingRow />
                }
          
                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteArea(item);
                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
                {


                }

            </StyledCard>
        </ModalContext.Provider>
    )
}
export default AreaDetailsList
/* eslint-disable  dot-location */
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';

import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { GetFormulaById } from '../../globalsTools/network';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import MathJax from 'react-mathjax2'
// import { Controlled as CodeMirror } from 'react-codemirror2';

// require('codemirror/lib/codemirror.css');
// require('codemirror/theme/material.css');
// require('codemirror/theme/neat.css');
// require('codemirror/mode/xml/xml.js');
// require('codemirror/mode/javascript/javascript.js');

const FormulaById = ({ itemRow }) => {

    // const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [formulaDetail, setFormulaDetail] = useState(null)

    useEffect(() => {

        if (itemRow) {
            GetFormulaById(itemRow.id, setFormulaDetail)
        }

    }, [itemRow])

    return (


        <StyledCard>

            {formulaDetail ?
                <StyledMaterialTable
                    title={itemRow.type == 'formula' ? vocabulary.formula : vocabulary.algorithmLabel + ': ' + itemRow.name}
                    data={[formulaDetail]}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                    columns={[

                        {
                            title: '', field: 'formula', render: rowData => <>
                            {
                               itemRow.type == 'formula' ?
                               <MathJax.Context input='tex'>
                                    <div style={{fontSize:'xx-large'}} >
                                     <MathJax.Node inline>{rowData.formula}</MathJax.Node>
                                    </div>
                                </MathJax.Context>
                                : 
                                    //     <CodeMirror
                                    //     value={JSON.parse(itemRow.source).algorithm}
                                    //     options={{
                                    //         mode: 'javascript',
                                    //         theme: 'material',
                                    //         lineNumbers: true
                                    //     }}

                                    // />
                                    <></>
                                } 
                                </>
                        }

                    ]}

                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false

                    }}
                />
                :
                <StyledLoadingRow />

            }



        </StyledCard>
    )
}
export default FormulaById
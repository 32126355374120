/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/
/* eslint-disable  no-useless-concat */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { vocabulary } from '../Strings';
import { setCurrentPage } from '../../actions';
import { GeItemList, DeleteItem, GetFinancialCategories } from '../../globalsTools/network'
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AlertDialog from "../Components/alertDialog";
import ItemAddForm from '../Admin/ItemManagment/ItemAddForm'
import { Chip } from '@material-ui/core/';
// import FactCheckIcon from '@material-ui/icons/FactCheck';
import PropTypes from "prop-types";
import CancelIcon from "@material-ui/icons/Cancel";
import ReceiptIcon from '@material-ui/icons/Receipt';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { stylesThemed } from "../globalStyle";

const UserFinancialItemList = () => {

    const classes = stylesThemed();


    // const moment = require('moment');

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const userId = useSelector(state => state.app.userid)

    const [isEditing, setIsEditing] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [itemList, setItemList] = useState(null)
    const [financialCategorieList, setFinancialCategorieList] = useState(null)

    const [showFinancialItemByUser, setShowFinancialItemByUser] = useState(false)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.itemManagmentLabel))
        document.title = vocabulary.itemManagmentLabel
        GeItemList(setItemList)
        GetFinancialCategories(setFinancialCategorieList)

    }, [dispatch,]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.itemManagmentLabel))
        document.title = vocabulary.itemManagmentLabel
    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 204) {
                snackbar(item.title + ' ' + vocabulary.deleteMsg, 'success')
                GeItemList(setItemList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const closeAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(false)

    };
    const handleDeleteItem = (item) => {
         DeleteItem(item.id
            , r => operationCallBack(r)
        )
    }
    const openAddItemForm = () => {
        setOpen(true)
        setIsEditing(false)
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpen(!open)
            setOpenEdit(!openEdit)
        } else {
            setOpen(newStatus)
            setOpenEdit(newStatus)
        }
    }
    const itemType = (type) => {
        switch (type) {
            case "expenses":
                return (
                    <Chip
                        icon={<ReceiptIcon className={classes.textLockOpenColor} />}
                        label={vocabulary.expenses}
                        className={[
                            classes.chip,
                            classes.colorLockOpenType,
                            classes.textLockOpenColor,
                        ]}
                        variant="outlined"
                    />
                );
                break;
            case "revenues":
                return (
                    <Chip
                        icon={<ReceiptIcon className={classes.textErrorColor} />}
                        label={vocabulary.revenues}
                        className={[
                            classes.chip,
                            classes.colorEroorType,
                            classes.textErrorColor,
                        ]}
                        variant="outlined"
                    />
                );
                break;
                default:
                    return type

        }
    };
    const HandleShowItemList = () => {
        setShowFinancialItemByUser(true)
        GeItemList(setItemList)
    }
    const HandleShowActivities = () => {
        setShowFinancialItemByUser(false)
        GeItemList(setItemList)
    }

    const contextValue = { open: open, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    return (
        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>
            <StyledCard>

                {
                    (!isLoading && itemList && itemList.data.length > 0) ?
                        <StyledMaterialTable
                            title={showFinancialItemByUser ? 'My financial Item list' : vocabulary.itemsLabel}
                            data={showFinancialItemByUser ? itemList.data.filter(el => el.user_id !== null) : itemList.data}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}
                            columns={[
                                { title: vocabulary.title, field: 'title', render: rowData => <>{rowData.title ? rowData.title : '-'}</> },
                                {
                                    title: vocabulary.type, field: 'type', render: rowData => (
                                        <>
                                            {rowData.type ? (
                                                itemType(rowData.type)
                                            ) : (
                                                <Chip
                                                    icon={<CancelIcon />}
                                                    label={'No Type'}
                                                    className={classes.chip}
                                                    color="default"
                                                    variant="outlined"
                                                />
                                            )}
                                        </>
                                    )

                                },
                                { title: vocabulary.price, field: 'price', render: rowData => <>{Number(rowData.price).toFixed(2) + ' ' + '( € )'}</> },
                                { title: vocabulary.description, field: 'description', render: rowData => <>{rowData.description}</> },
                                { title: vocabulary.financialCategorie, field: 'financialCategorie', render: rowData => <>{rowData.financial_category ? rowData.financial_category.title : '--'}</> },

                            ]}
                            actions={
                                showFinancialItemByUser == false ? [

                                    {
                                        icon: () => <AddIcon fontSize='large' color='primary' />,
                                        tooltip: vocabulary.add,
                                        isFreeAction: true,
                                        onClick: (event) => openAddItemForm()
                                    },
                                    {
                                        icon: () => showFinancialItemByUser === false ? <ViewComfyIcon /> : <ViewHeadlineIcon />,
                                        tooltip: showFinancialItemByUser === false ? 'my financial Item' : vocabulary.itemsLabel,
                                        onClick: (event, row) => showFinancialItemByUser === false ? HandleShowItemList() : HandleShowActivities(),
                                        isFreeAction: true,

                                    },

                                ] :
                                    [
                                        {
                                            icon: () => <AddIcon fontSize='large' color='primary' />,
                                            tooltip: vocabulary.add,
                                            isFreeAction: true,
                                            onClick: (event) => openAddItemForm()
                                        },
                                        {
                                            icon: () => showFinancialItemByUser === false ? <ViewComfyIcon /> : <ViewHeadlineIcon />,
                                            tooltip: showFinancialItemByUser === false ? 'my financial Item' : vocabulary.itemsLabel,
                                            onClick: (event, row) => showFinancialItemByUser === false ? HandleShowItemList() : HandleShowActivities(),
                                            isFreeAction: true,

                                        },
                                        row => ({
                                            icon: 'edit_outline',
                                            tooltip: userId !== row.user_id ? vocabulary.disableEdit : vocabulary.editLabel,
                                            disabled: userId !== row.user_id,
                                            onClick: (event, row) => onEdit(row)
                                        }),
                                        row => ({
                                            icon: 'delete_outline',
                                            onClick: (event, row) => openAlertConfirmDelete(row),
                                            tooltip: userId !== row.user_id ? vocabulary.disableDelete : vocabulary.delete,
                                            disabled: userId !== row.user_id
                                        }),
                                    ]
                            }
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                pagging: false
                            }}
                        />
                        : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

                {
                    alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.title}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteItem(item);
                            }
                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />
                }

                <ItemAddForm
                    open={isEditing === false ? open : openEdit}
                    onClose={() => {
                        return (
                            isEditing === false ?
                                (setOpen(false),
                                    setIsEditing(false))
                                :
                                setOpenEdit(false)
                        )

                    }}
                    item={isEditing === true ? item : null}
                    width={600}
                    itemId={item && item.id}
                    isEditing={isEditing}
                    refetchFunc={() => GeItemList(setItemList)}
                    setIsLoading={setIsLoading}
                    financialCategorieList={financialCategorieList && financialCategorieList.data}

                />
            </StyledCard>

        </ModalContext.Provider>
    )
}

export default UserFinancialItemList
/* eslint-disable  dot-location */
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import { setCurrentPage } from '../../../actions';

import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { GetActivitiesType, DeleteActivitiesType } from '../../../globalsTools/network';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ActivitiesTypeAddForm from './ActivitiesTypeAddForm'

const ActivitiesTypesList = () => {

    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [activitiesType, setActivitiesType] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.activitiesManagement));
        document.title = vocabulary.activitiesManagement
        GetActivitiesType(setActivitiesType)

    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.activitiesManagement));
        document.title = vocabulary.activitiesManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.title + ' ' + vocabulary.deleteMsg, 'success')
                GetActivitiesType(setActivitiesType)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteActivitiesType = (item) => {

        DeleteActivitiesType(item.id
            , r => operationCallBack(r)
        )
    }

    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenType(!openType)
            setOpenEdit(!openEdit)
        } else {
            setOpenType(newStatus)
            setOpenEdit(newStatus)
        }
    }
    const openActivityType = () => {
        setOpenType(true)
        setIsEditing(false)
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValue = { open: openType, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    return (

        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            <StyledCard>

                {
                    (!isLoading && activitiesType) ?
                        <StyledMaterialTable
                            title={vocabulary.activityType}
                            data={activitiesType ? activitiesType.data : []}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}

                            columns={[
                                { title: vocabulary.titleLabel, field: 'title' },
                                { title: vocabulary.description, field: 'description' },
                                { title: vocabulary.status, field: 'status' },

                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => onEdit(row)
                                },
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),
                                }),
                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => openActivityType()
                                },


                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 40, 60, 80, 100],

                            }}
                        />
                        :
                        <StyledCircularProgress size={50} label={vocabulary.loading} />

                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.title}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteActivitiesType(item)

                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
                {
                    (openEdit == true || openType == true) &&
                    <ActivitiesTypeAddForm
                        open={isEditing === true ? openEdit : openType}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenType(false),
                                        setIsEditing(false))
                                    :
                                    setOpenType(false)
                            )

                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetActivitiesType(setActivitiesType)}
                        setIsLoading={setIsLoading}

                    />
                }

            </StyledCard>
        </ModalContext.Provider>
    )
}
export default ActivitiesTypesList
/* eslint-disable eqeqeq*/
/* eslint-disable  no-useless-concat */

import React, { useRef, useContext } from 'react';

import { vocabulary } from '../../Strings';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import StyledInput from "../../../ui/forms/StyledInput";
import { useForm } from 'react-hook-form';
import { CreateSeabexCategory, UpdateSeabexCategory } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';

const CatagoryAddForm = ({ isEditing, onClose, open, item, parent_id, width, refetchFunc }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? item.name + "  " + vocabulary.updateSucc : vocabulary.categoryFamily + " " + vocabulary.addedSucc, 'success')
                refetchFunc()

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const newCategorie = (data) => {

        if (isEditing === false) {
            CreateSeabexCategory(
                {
                    name: data.categoryName,
                    parent_id: parent_id

                }, r => operationCallBack(r)
            )
            onClose()
        } else (
            UpdateSeabexCategory(
                {
                    id: item.id,
                    name: data.categoryName,
                    parent_id: item.parent_id

                }, r => operationCallBack(r)
            )

        )
        onClose()

    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={width}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addCategory : vocabulary.editCategory}
            >
                <form
                    onSubmit={handleSubmit(newCategorie)}
                    ref={form}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="categoryName"
                        label={vocabulary.categoryName}
                        type="text"
                        fullWidth
                        errors={errors.categoryName}

                        defaultValue={item && item.name}


                    />
                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
        </>
    )

}
export default CatagoryAddForm

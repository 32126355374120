/* eslint-disable eqeqeq*/
/* eslint-disable no-unreachable*/
/* eslint-disable  no-useless-concat */

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import { getEventsById } from "../../globalsTools/network";

import 'bootstrap/dist/css/bootstrap.css';

import { vocabulary } from '../Strings';
import './../../App.css';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
// import { withStyles } from '@material-ui/core/'
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import PropTypes from 'prop-types';

const EventDetails = ({ item }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const moment = require('moment');

    const [eventDetail, setEventDetail] = useState(null)

    useEffect(() => {

        if (item) {
            getEventsById(item.id, setEventDetail)
        }
    }, [item])

    const EventReasonDetail = (eventDetail) => {
        switch (eventDetail.type) {
            case 'EXECUTION':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {eventDetail.reason == 'UserAction' ?
                        eventDetail.state == 'true' ? vocabulary.youHaveActivatedLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                            + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.theLabel + ' ' + (currentLanguage == "en" ? moment(eventDetail.start_time).format('YYYY-MM-DD') : moment(eventDetail.start_time).format('DD-MM-YYYY'))
                            + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                            : vocabulary.youHaveDesactivatedLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                            + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + vocabulary.theLabel + moment(eventDetail.start_time).format('DD-MM-YYYY')
                            + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                        : 'test'
                    }

                </span>
                break;
            case 'ERROR':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {vocabulary.errorOccuredOnLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                        + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.onTheEquipmentLabel + ' ' + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + ' ' + currentLanguage == "en" ? moment(eventDetail.start_time).format('YYYY-MM-DD') : moment(eventDetail.start_time).format('DD-MM-YYYY')
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')

                    }
                </span>
                break;
            case 'CONNECT':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {vocabulary.youAreConnectedLabel + ' ' + vocabulary.onTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.theLabel + ' ' + (currentLanguage == "en" ? moment(eventDetail.start_time).format('YYYY-MM-DD') : moment(eventDetail.start_time).format('DD-MM-YYYY'))
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                    }
                </span>
                break;
            case 'COMMAND':

                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {vocabulary.sentACommandLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                        + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.onTheEquipmentLabel + " " + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + ' ' + currentLanguage == "en" ? moment(eventDetail.start_time).format('YYYY-MM-DD') : moment(eventDetail.start_time).format('DD-MM-YYYY')
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')

                    }
                </span>
                break;
            case 'AutomationPlanExecution':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {eventDetail.state === "true" || eventDetail.state === "1" ?
                        vocabulary.youOpenedLabel + ' ' + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                            + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + '  ' + vocabulary.onTheEquipmentLabel + " " + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + ' ' + currentLanguage == "en" ? moment(eventDetail.start_time).format('YYYY-MM-DD') : moment(eventDetail.start_time).format('DD-MM-YYYY')
                            + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                        : eventDetail.state === "false" || eventDetail.state === "0" ?
                            vocabulary.youClosedLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                                + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.onTheEquipmentLabel + " " + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + " " + currentLanguage == "en" ? moment(eventDetail.start_time).format('YYYY-MM-DD') : moment(eventDetail.start_time).format('DD-MM-YYYY')
                                + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                            : '--'
                    }
                </span>
                break;
            default:
                return eventDetail.type;

        }
    }


    return (
        eventDetail !== null ?
            EventReasonDetail(eventDetail)

            : < StyledLoadingRow />





    );

}

/*

topic: notify
    EXECUTION   //When a control station execute the action this the confirmation that the action is executed successfully
        reason : UserAction, AP, SC
    CONNECT     //When the gateway is connected to broker
    COMMAND     //When we send a command to the gateway to get some informations : { command: user action + ....., getmodeauto: actual modeauto status, shutdown: to turn offf the geteway, reboot: to reboot the gateway, reset: to reset the gateway app,.....}
    ERROR       //When an error occure on the gateway or the SA et SC, generally on the SA when sensor is unpluged or unwell pluged
    AutomationPlanExecution //When a task related to AP is lunched, the comfirmation of the action is served on EXECUTION
*/
// EventDetails.propTypes = {
//     classes: PropTypes.object.isRequired,
// };
export default EventDetails



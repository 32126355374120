import React from "react";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Popup,
} from "react-leaflet";
import { defaultMarker } from "../../../globalsTools/utilities";
import { iconTypeChange } from "../../Sites/Map/Functions/MapFunctions";
import { vocabulary } from "../../Strings";

const deviceTypes = ["weather_station"];

const WeatherStationsMapping = ({ weather_stations }) => {



  return (

    deviceTypes.map((type) => {
      const typeCount = weather_stations.length;
      return (
        <LayersControl.Overlay key={type} name={`${type.replace('_', ' ').toUpperCase()} (${typeCount})`} checked>
          <FeatureGroup>
            {
              weather_stations
                .map((weather_station) => {
                    const position = [weather_station.latitude, weather_station.longitude];
                    return (
                      <Marker
                        key={`marker-${weather_station.id}`}
                        position={position}
                        icon={iconTypeChange('ACQUISITION')  || defaultMarker}
                      >
                        <Popup key={`popup-${weather_station.id}`}>
                          <div>
                            <strong>{vocabulary.deviceName}: </strong> {weather_station.name}
                          </div>

                          {
                         <div>
                              <strong>{vocabulary.site}: </strong> {weather_station.provider}
                            </div>
                          }
                        </Popup>

                      </Marker>
                    );
                }

                )
            }
          </FeatureGroup>
        </LayersControl.Overlay>
      )
    })


  );
};

export default WeatherStationsMapping;


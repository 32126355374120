/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

import { setCurrentPage } from '../../actions';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
import AddShoppingCartIcon from '@material-ui/icons/Cached';
import SearchIcon from '@material-ui/icons/Search';

import { useForm } from 'react-hook-form';
import StyledSelect from "../../ui/forms/StyledSelect";
import EventsListTable from './EventsListTable';
import { StyledCard } from "../../ui/layout";
import { GetDeployedDevicebygateway, GetDeployedDeviceGatewayByAreaId } from "../../globalsTools/network";
import { Grid, Radio, RadioGroup, Button, FormControlLabel, CardContent } from '@material-ui/core/';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';

import {
    GetAreasList, GetAreaDetails, GetGatewaysList,
    // GetEventsByAreaId
    GetEventType
} from "../../globalsTools/network";
import { stylesThemed } from '../globalStyle';

const EventList = () => {

    const classes = stylesThemed();

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const { register, handleSubmit, errors } = useForm()

    const [dateFrom, setDateFrom] = useState(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DD HH:mm"));
    const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD HH:mm"));
    const [deviceId, setDeviceId] = useState(null);
    const [selectedGetewayId, setSelectedGetewayId] = useState('');
    const [sitesList, setSitesList] = useState(null)
    const [getwayArray, setGetwayArray] = useState(null)
    const [parcelId, setParcelId] = useState('')
    const [sectorId, setSectorId] = useState('')
    const [selectedArea, setSelectedArea] = useState(null)
    const [siteId, setSiteId] = useState('')
    const [parcelsList, setParcelsList] = useState([])
    const [sectorsList, setSectorsList] = useState([])
    const [deployedDeviceByGateways, setDeployedDeviceByGateways] = useState([])
    const [changeVue, setChangeVue] = useState('choiceBySite');
    const [eventListByAreaId, setEventListByAreaId] = useState(null);
    const [selectedAreaId, setSelectedAreaId] = useState(null)
    const [eventType, setEventType] = useState(null)
    const [eventTypeList, setEventTypeList] = useState(null)
    const [gatewayListByArea, setGatewayListByArea] = useState(null)
    const [startGateData, setStartGateData] = useState(null)


    useEffect(() => {
        GetAreasList(setSitesList)
        GetGatewaysList(setGetwayArray)
        GetEventType(setEventTypeList)
        dispatch(setCurrentPage(vocabulary.Events));
        document.title = vocabulary.Events


    }, [dispatch]) // N’exécute l’effet que a la 1ère render

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.Events));
        document.title = vocabulary.Events

    }, [dispatch, currentLanguage]) // N’exécute l’effet que si currentLanguage a changé

    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };


    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSiteId(areasDetails.id)
                setParcelId('')

                break;
            case 'parcel':
                setSectorsList(areasDetails["childs"])
                setParcelId(areasDetails.id)
                setSectorId('')
                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;
            default:
                return ''

        }
    }

    useEffect(() => {
        if (selectedArea != null) {
            const areaId = selectedArea.id

            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
            setSelectedAreaId(areaId)
            changeVue === 'choicebyDeployedDevice' && GetDeployedDeviceGatewayByAreaId(areaId, setGatewayListByArea)

        }
    }, [selectedArea, changeVue])  // N’exécute l’effet que si selectedArea a changé
    const resetForm = () => { // réinitialiser le formulaire 

        return (
            setDateFrom(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DD HH:mm")),
            setDateTo(moment(new Date()).format("YYYY-MM-DD HH:mm")),
            setSelectedGetewayId(''),
            setDeviceId(null),
            setSectorId(''),
            setParcelsList([]),
            setSiteId(''),
            setParcelId(''),
            setSelectedArea(null),
            setEventListByAreaId(null),
            setEventType(null),
            setStartGateData(null)
        )

    }


    const findEvents = () => {
        setStartGateData(moment())

    }
    const handleVueChange = event => {
        setChangeVue(event.target.value);
        setSiteId(null)


    };

    const handleDateChange = key => date => {
        key === 'dateFrom' ? setDateFrom(moment(date).format("YYYY-MM-DD HH:mm")) : setDateTo(moment(date).format("YYYY-MM-DD HH:mm"))
    }


    return (
        <>
            {sitesList ?
                <StyledCard styleProp={{
                    width: '100%',
                    padding: 30,
                    paddingRight: 50,
                    marginTop: 15
                }}>


                    <div style={{
                        display: 'flex', justifyContent: 'space-around', marginBottom: 35,
                        alignItems: 'center'
                    }}
                    >

                        <DateTimePicker
                            value={dateFrom}
                            onChange={handleDateChange('dateFrom')}
                            ampm={false}
                            label={vocabulary.startDate}
                            maxDate={moment()}
                            variant="inline"
                            disableFuture={true}
                            autoOk={true}


                        />
                        <DateTimePicker
                            value={dateTo}
                            onChange={handleDateChange('dateTo')}
                            ampm={false}
                            label={vocabulary.endDate}
                            minDate={moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                            maxDate={moment().format('YYYY-MM-DD HH:mm')}
                            variant="inline"
                            autoOk={true}


                        />
                    </div>

                    <form
                        onSubmit={handleSubmit(findEvents)}
                    >
                        <div>
                            <RadioGroup aria-label="gender" name="gender1" value={changeVue} onChange={handleVueChange}>
                                <FormControlLabel value="choiceBySite" control={<Radio />} label={vocabulary.choiceBySite} />

                                <FormControlLabel value="choicebyDeployedDevice" control={<Radio />} label={vocabulary.choiceByDeployedDevice} />

                            </RadioGroup>

                        </div>

                        <CardContent>
                            <Grid container style={{ display: 'flex', flexDirection: 'row', flexWrap: "no-wrap", alignItems: 'center' }} spacing={2}>

                                {changeVue === 'choiceBySite' &&
                                    <>
                                        <Grid item className={classes.flexAndGrow}>

                                            {sitesList ?
                                                <StyledSelect
                                                    value={siteId}
                                                    register={register}
                                                    rules={{ required: true }}
                                                    errors={errors.Area}
                                                    onChange={handleChange}
                                                    name='Area'
                                                    label={vocabulary.site}
                                                    autoWidth
                                                    data={sitesList}
                                                    valueField='id'
                                                    labelField='name'
                                                /> : null}
                                        </Grid>

                                        <Grid item className={classes.flexAndGrow}>

                                            {(parcelsList !== [] && siteId) &&
                                                <StyledSelect
                                                    value={parcelId}
                                                    register={register}
                                                    errors={errors.Area}
                                                    onChange={handleChange}
                                                    name='ParcelId'
                                                    label={vocabulary.parcel}
                                                    autoWidth
                                                    data={parcelsList && parcelsList}
                                                    valueField='id'
                                                    labelField='name'

                                                />
                                            }
                                        </Grid>

                                        <Grid item className={classes.flexAndGrow}>

                                            {(sectorsList !== [] && parcelId) &&
                                                <StyledSelect
                                                    value={sectorId}
                                                    register={register}
                                                    errors={errors.Area}
                                                    onChange={handleChange}

                                                    name='sectorId'
                                                    label={vocabulary.sector}
                                                    autoWidth
                                                    data={sectorsList && sectorsList}
                                                    valueField='id'
                                                    labelField='name'

                                                />
                                            }
                                        </Grid>

                                    </>
                                }

                                {changeVue === 'choicebyDeployedDevice' &&
                                    <>
                                        <Grid item className={classes.flexAndGrow}>

                                            {sitesList ?
                                                <StyledSelect
                                                    value={siteId}
                                                    register={register}
                                                    rules={{ required: true }}
                                                    errors={errors.Area}
                                                    onChange={handleChange}
                                                    name='Area'
                                                    label={vocabulary.site}
                                                    autoWidth
                                                    data={sitesList && sitesList.data}
                                                    valueField='id'
                                                    labelField='name'
                                                />
                                                : null}
                                        </Grid>
                                        <Grid item className={classes.flexAndGrow}>
                                            {(siteId && gatewayListByArea) ?
                                                <StyledSelect
                                                    value={selectedGetewayId}
                                                    register={register}
                                                    rules={{ required: true }}
                                                    errors={errors.selectGateway}
                                                    onChange={(event) => {
                                                        setSelectedGetewayId(event.target.value)
                                                        GetDeployedDevicebygateway(event.target.value, setDeployedDeviceByGateways)
                                                    }}
                                                    name='selectGateway'
                                                    label={vocabulary.selectGateway}
                                                    autoWidth
                                                    data={gatewayListByArea && gatewayListByArea.data}
                                                    valueField='id'
                                                    labelField='name'

                                                />
                                                : null}

                                        </Grid>

                                        <Grid item className={classes.flexAndGrow}>

                                            {(selectedGetewayId && deployedDeviceByGateways !== []) &&
                                                <StyledSelect
                                                    value={deviceId}
                                                    register={register}
                                                    rules={{ required: true }}
                                                    errors={errors.deviceId}
                                                    onChange={(event) => {
                                                        setDeviceId(event.target.value)
                                                    }}
                                                    name='deviceId'
                                                    label={vocabulary.selectStation}
                                                    autoWidth
                                                    data={deployedDeviceByGateways && deployedDeviceByGateways}
                                                    valueField='id'
                                                    labelField='name'

                                                />

                                            }
                                        </Grid>

                                    </>

                                }
                                {
                                    (deviceId || siteId) &&
                                    <StyledSelect
                                        value={eventType}
                                        register={register}
                                        errors={errors.eventType}
                                        onChange={(event) => {
                                            setEventType(event.target.value)
                                        }}
                                        name='eventType'
                                        label={vocabulary.type}
                                        autoWidth
                                        data={eventTypeList && eventTypeList}
                                        valueField='type'
                                        labelField='type'

                                    />
                                }



                            </Grid>

                        </CardContent>
                        <Button variant="contained" color="primary" className={classes.button} type="submit">
                            <SearchIcon style={{ marginRight: '10px' }} />
                            {vocabulary.findEvents}
                        </Button>
                        <Button variant="contained" color="secondary" className={classes.button} onClick={() => resetForm()}>
                            <AddShoppingCartIcon style={{ marginRight: '10px' }} />
                            {vocabulary.newSearch}
                        </Button>
                    </form>
                </StyledCard>
                : <StyledCircularProgress size={50} label={vocabulary.loading} />}



            {startGateData !== null ?
                <EventsListTable
                    eventListByAreaId={eventListByAreaId && eventListByAreaId}
                    selectedAreaId={selectedAreaId}
                    dateFrom={moment(dateFrom).format("YYYY-MM-DD HH:mm")}
                    dateTo={moment(dateTo).format("YYYY-MM-DD HH:mm")}
                    type={eventType}
                    deviceId={deviceId}
                    startGateData={startGateData}

                />
                : null}


        </>
    );

}


// EventList.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default EventList;
import moment from "moment";
import {
  ASPERSION_IRRIGATION,
  DRIP_IRRIGATION,
  MICRO_ASPERSION_IRRIGATION,
  PIVO_IRRIGATION,
  SUBMERSION_IRRIGATION
} from "../App/globalConsts"
import { logger } from "./utilities";

const dripInHours = (irrigationParams, value) => {
  let surface = Number(irrigationParams['TREE_TOTAL_SURFACE_M']);
  let number_drippers = Number(irrigationParams['DRIPPERS_PER_TREE']);
  let flow_per_dripper = Number(irrigationParams['FLOW_PER_DRIPPER_LH']);
  let minutes = Number(value) * 60 * surface / (number_drippers * flow_per_dripper);
  let h = Math.floor(minutes / 60);
  let m = Math.floor(minutes % 60);

  let hDisplay = h > 0 ? h + (h === 1 ? " heure, " : " heures, ") : "";
  let mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
  return h + ":" + m + ' (hh:mm)';
  // return h + ":" + m + ' (hh:mm) / ' + Number(value).toFixed(2) + ' mm';
}

// const dripInHours = (irrigationParams, value) => {
//   let surfaceM2 = Number(irrigationParams.SURFACE) * 0.0001
//   let number_drippers = Number(irrigationParams.DRIPPERS_PER_TREE)
//   let flow_per_dripper = Number(irrigationParams.FLOW_RATE) // l/h
//   let qTotaleLH = number_drippers * flow_per_dripper * surfaceM2
//   let qTotaleMH = qTotaleLH * 0.36 / 100
//   let NeedInHours = value / qTotaleMH
//   return NeedInHours;
// }

const mircroAspersionInHours = (irrigationParams, value) => {
  let surfaceM2 = Number(irrigationParams.SURFACE) * 0.0001
  let number_drippers = Number(irrigationParams.DRIPPERS_PER_TREE)
  let flow_per_dripper = Number(irrigationParams.FLOW_RATE) // m3/h
  let qTotaleLH = number_drippers * flow_per_dripper * surfaceM2
  let qTotaleMH = qTotaleLH * 0.36 / 100
  let NeedInHours = Number(value) / qTotaleMH
  return NeedInHours; // need in hours H
}

const aspersionInHours = (irrigationParams, value) => {
  let surfaceM2 = Number(irrigationParams.SURFACE) * 0.0001
  let number_drippers = Number(irrigationParams.DRIPPERS_PER_TREE)
  let flow_per_dripper = Number(irrigationParams.FLOW_RATE) // m3/h
  let qTotaleLH = number_drippers * flow_per_dripper * surfaceM2
  let qTotaleMH = qTotaleLH * 0.36 / 100
  let NeedInHours = value / qTotaleMH
  return NeedInHours; // need in hours H
}

const submersionInHours = (irrigationParams, value) => {
  let surfaceM2 = Number(irrigationParams.SURFACE) * 0.0001
  let VtotaleL = surfaceM2 / value //mm
  let FLOW_RATE = Number(irrigationParams.FLOW_RATE) // l/M
  return FLOW_RATE * VtotaleL; // need in hours H
}

const pivotInInHours = (irrigationParams, value) => {
  let surfaceM2 = Number(irrigationParams.SURFACE) * 0.0001
  let VtotaleL = surfaceM2 / value //mm
  let FLOW_RATE = Number(irrigationParams.FLOW_RATE) // l/M
  return FLOW_RATE * VtotaleL; // need in hours H
}

export const convertNeedInHours = (type, params, value, currentLanguage = 'fr') => {

  logger("convertNeedInHours", type, params, value, currentLanguage)

  let needInHours = 0;

  if (type === DRIP_IRRIGATION) {
    needInHours = dripInHours(params, value);
  }

  if (type === MICRO_ASPERSION_IRRIGATION) {
    needInHours = mircroAspersionInHours(params, value);
  }

  if (type === ASPERSION_IRRIGATION) {
    needInHours = aspersionInHours(params, value);
  }

  if (type === SUBMERSION_IRRIGATION) {
    needInHours = submersionInHours(params, value);
  }

  if (type === PIVO_IRRIGATION) {
    needInHours = pivotInInHours(params, value);
  }


  // const duration = moment.duration(needInHours, 'hours');
  // return moment.utc(duration.as('milliseconds')).format('HH:mm')
  return needInHours
}

/* eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import PartnerAddForm from './PartnerAddForm';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { setCurrentPage } from '../../../actions';
import { organizationUserDeletePartners , getOrganizationUserPartnersList } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { Refresh } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const PartnerListTable = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [partnersList, setPartnersList] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)
    let history = useHistory();
 

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.partnerManagement));
        getOrganizationUserPartnersList(setPartnersList)
        document.title = vocabulary.partnerManagement

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.partnerManagement));
        document.title = vocabulary.partnerManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const impersonateUser = (user) => {
        history.push(`/app/organization/user/area/impersonate`, { user })
    }

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
        setIsEditing(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r, row) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isActive == true ? row.firstname + ' ' + row.lastname + ' ' + vocabulary.inactiveLabel : row.firstname + ' ' + row.lastname + ' ' + vocabulary.activeLabel, 'success')
                getOrganizationUserPartnersList(setPartnersList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
 


    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {partnersList ?
                    <StyledMaterialTable
                        title={vocabulary.partnerManagement}
                        data={partnersList}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                       columns={[
                            { title: vocabulary.firstname, field: 'firstname' },
                            { title: vocabulary.lastname, field: 'lastname' },
                            { title: vocabulary.email, field: 'email' },

                        ]}
                        actions={[
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.Delete,
                                disabled: row.type === "Super",
                                onClick: (event, row) => openAlertConfirmDelete(row),
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addNewUser,
                                isFreeAction: true,
                                onClick: () => handleClickOpen()
                            },
                            {
                                icon: () => <Refresh fontSize='large' color='primary' />,
                                tooltip: vocabulary.refreshData,
                                isFreeAction: true,
                                onClick: () => getOrganizationUserPartnersList(setPartnersList)
                            },
                            row => ({
                                icon: () => <Refresh fontSize='large' color='secondary' />,
                                tooltip: "Impersonate",
                                onClick: () => impersonateUser(row)
                            })

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80],
                            exportMenu: [{
                                label: 'Export PDF',
                                exportFunc: (cols, datas) => ExportPdf(cols, datas, "Users List")
                            }, {
                                label: 'Export CSV',
                                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Users List')
                            }]

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.firstname}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                organizationUserDeletePartners(item.id).then(() => getOrganizationUserPartnersList(setPartnersList));

                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}


                {(openAdd == true || openEdit == true) &&
                    <PartnerAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => getOrganizationUserPartnersList(setPartnersList)}
                    />}
            </StyledCard>
        </ModalContext.Provider>

    )
}
export default PartnerListTable
/* eslint-disable  dot-location */
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';

import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import { getDeployedDeviceById } from "../../globalsTools/network";

const StationDetail = ({ itemRow }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const moment = require('moment');


    const [deployedDeviceByIdList, setDeployedDeviceByIdList] = useState(null)
    const [deployedDeviceEquipmentList, setDeployedDeviceEquipmentList] = useState(null)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setLoading(true)
        if (itemRow) {
            getDeployedDeviceById(itemRow.id, setDeployedDeviceByIdList)
        }
        setLoading(false)
    }, [itemRow.id]) // N’exécute l’effet que si itemRow a changé (get deployedDevice by id)

    useEffect(() => {
        setLoading(true)
        if (deployedDeviceByIdList && deployedDeviceByIdList[0].name !== 'Gateway') {
            setDeployedDeviceEquipmentList(deployedDeviceByIdList.map(el => el.area_deployed_device_equipment))
        }
        setLoading(false)
    }, [deployedDeviceByIdList]) // N’exécute l’effet que si deployedDeviceByIdList est rompli (get deployedDevice equipment list)

    return (

        <StyledCard>

            {!loading &&  deployedDeviceByIdList && deployedDeviceByIdList[0].seabexmodel ?

                 JSON.parse(deployedDeviceByIdList[0]?.seabexmodel?.type)?.type == 'GATEWAY' ?
                    (
                        <StyledMaterialTable
                            title={vocabulary.equipmentLabel}
                            data={deployedDeviceByIdList && deployedDeviceByIdList[0].additional_informations ? [JSON.parse(deployedDeviceByIdList[0].additional_informations)] : []}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay } }}

                            columns={[
                                { title: vocabulary.lastPing, field: 'Time', render: rowData => <>{moment(rowData.Time).locale(currentLanguage).fromNow()}</> },
                                { title: vocabulary.publicIp, field: 'Details', render: rowData => <>{rowData.Details ? rowData.Details.PublicIp : ''}</> },
                                { title: vocabulary.inetrnalIp, field: 'Details', render: rowData => <>{rowData.Details ? rowData.Details.InternalIp : ''}</> },
                                { title: vocabulary.sdfreeSpace, field: 'Details', render: rowData => <>{rowData.Details ? rowData.Details.SDFreeSpace : ''}</> },
                                { title: vocabulary.modeLabel, field: 'Details', render: rowData => <>{rowData.Details.ModeAuto == true ? vocabulary.automatic : vocabulary.manuel}</> },
                                // { title: vocabulary.timeZone, field: 'Details', render: rowData => <>{rowData.Details ? moment.unix(Number(rowData.Details.LocalTime) / 1000).format() : ''}</> },
                                { title: vocabulary.timeZone, field: 'Details', render: rowData => <>{rowData.Details ? moment(rowData.Details.LocalTime).format('DD-MM-YYYY HH:mm:ss') : ''}</> },

                                { title: vocabulary.scheduledJobsLabel, field: 'Details', render: rowData => <>{rowData.Details ? rowData.Details.ScheduledJobs : ''}</> },

                            ]}

                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false,


                            }}
                        />
                    )

                    : !loading && deployedDeviceEquipmentList && (
                        <StyledMaterialTable
                            title={vocabulary.equipmentLabel}
                            data={deployedDeviceEquipmentList && deployedDeviceEquipmentList[0].length > 0 ? deployedDeviceEquipmentList[0] : []}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay } }}

                            columns={[
                                {
                                    title: vocabulary.port, field: 'port', render: rowData => <>{<div> {rowData.port} </div>}</>
                                },
                                {
                                    title: vocabulary.name_label, field: 'equipment', render: rowData => <>{<div> {rowData.equipment ? JSON.parse(rowData.equipment.name).name_fr : <span style={{ color: 'grey' }}>{vocabulary.noAttachedEquipment}</span>}  </div>}</>
                                },
                                {
                                    title: vocabulary.reference, field: 'equipment', render: rowData => <>{<div>{<span>{rowData.equipment ? rowData.equipment.reference : <span style={{ color: 'grey' }}>{vocabulary.noAttachedEquipment}</span>}</span>}</div>} </>
                                },
                                {
                                    title: vocabulary.description, field: 'equipment', render: rowData => <> {<div> {<span>{rowData.description ? rowData.description : <span style={{ color: 'grey' }}>{vocabulary.noAttachedEquipment}</span>}</span>} </div>}</>
                                },
                                {
                                    title: vocabulary.datatype, field: 'equipment', render: rowData => <>{
                                        rowData.equipment ? rowData.equipment.prmdatatypes.length > 0 ?
                                            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '3' }}>
                                                {
                                                    rowData.equipment.prmdatatypes.map((el, index) => {
                                                        return <span key={index}>{rowData.equipment.prmdatatypes.length > 0 && JSON.parse(el.name).name_ar ? JSON.parse(el.name)['name_' + currentLanguage] : JSON.parse(el.name).name_fr}</span>
                                                    })
                                                }
                                            </div>
                                            : <span style={{ color: 'grey' }}>{vocabulary.noAttachedDataType}</span>
                                            : <span style={{ color: 'grey' }}>{vocabulary.noAttachedEquipment}</span>
                                    }
                                    </>
                                },

                            ]}

                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false,
                            }}
                        />
                    )
 
                : <StyledLoadingRow />
            }

        </StyledCard>
    )
}
export default StationDetail
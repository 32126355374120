/* eslint-disable no-sequences */

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { vocabulary } from '../Strings';
import { setCurrentPage } from '../../actions';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { PluvioByAreaIdByDate, SetMultiplePluvio, SetPluvioByAreaIdByDate } from '../../globalsTools/network'
// import AddGroupeForm from './AddGroupForm'
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";

import { useSnackbar } from 'notistack';
import { DatePicker, useStaticState } from '@material-ui/pickers';
import moment from 'moment';
import AreaFilteringInputs from '../Components/AreaFilteringInputs';
import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core"
import { getDateFormat } from "../../globalsTools/utilities";
import { Cancel, CheckCircle, Save } from "@material-ui/icons";
import { formatDateStr } from "../../utils";
import { unionBy, uniqBy } from "lodash";
import AreaSelect from "../Sites/Components/AreaSelect";
import { MTableToolbar } from "@material-table/core";

const PluvioList = () => {

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar();

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [dateFrom, setDateFrom] = useState(moment().subtract(10, "days"));
    const [dateTo, setDateTo] = useState(moment())
    const [reset, setReset] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [waterNeedByDateList, setWaterNeedByDateList] = useState([]);
    const [selectedParcels, setSelectedParcels] = useState([]);
    const [multiple, setMultiple] = useState(false)
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.rainfallManagement))
        document.title = vocabulary.rainfallManagement

    }, [dispatch]); // N’exécute l’effet que a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.rainfallManagement))
        document.title = vocabulary.rainfallManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const handleDateChange = key => date => {
        key === 'dateFrom' ? setDateFrom(date) : setDateTo(date)
    }



    const changeModalStatus = (newStatus) => {

        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }


    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(vocabulary.saved, 'success')
                setChanged(false)
                findPluvio()
            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleLoading = (data) => {
        setWaterNeedByDateList(data)
        setLoading(false)
    }

    const findPluvio = () => {
        setLoading(true)
        PluvioByAreaIdByDate(
            selectedAreaId,
            moment(dateFrom).format('YYYY-MM-DD'),
            moment(dateTo).format('YYYY-MM-DD'),
            handleLoading
        );

    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    const resetSelection = () => {
        setReset(!reset);
        setWaterNeedByDateList(null)
        setSelectedAreaId(null)
    }

    const handleCorrection = (date, value) => {
        console.log("====", date, value);
        if (waterNeedByDateList) {
            const updatedWaterNeedByDateList = waterNeedByDateList.map(obj => {
                if (obj.date === date) {
                    obj.correctedRainfall = value !== '' ? Number(value) : null;
                }
                return obj;
            });

            setWaterNeedByDateList(updatedWaterNeedByDateList);
            console.log("====", updatedWaterNeedByDateList);
        }
    }


    const handleClickSave = () => {
        let min_date, max_date = waterNeedByDateList[0]?.date
        min_date = max_date = waterNeedByDateList[0]?.date
        waterNeedByDateList.forEach(e => { if (e.date < min_date) min_date = e.date; else if (e.date > max_date) max_date = e.date; })
        let correctedRainfall = waterNeedByDateList.filter(e => e.correctedRainfall != null)
        console.log("correctedRainfall", correctedRainfall);
        if (multiple) {

            selectedParcels.push({ id: selectedAreaId })

            SetMultiplePluvio(
                selectedParcels,
                { start_date: min_date, end_date: max_date, correctedRainfall },
                operationCallBack
            )

        } else {
            SetPluvioByAreaIdByDate(
                selectedAreaId,
                min_date,
                max_date,
                correctedRainfall,
                operationCallBack
            )
        }


    }

    const formatuseCallback = (rowData) => {
        return (
            <InputCorrectedRainfall
                rowData={rowData}
                handleCorrection={handleCorrection}
                confirmChangeFn={() => setChanged(true)}
            />
        )
    }

    const mtStyleFn = useCallback((rowData) => ({
        backgroundColor: rowData.correctedRainfall !== null ? "#C8E6C9" : 'none'
    }), [])




    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {
                <StyledCard

                    styleProp={{
                        padding: 30,
                    }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 35,
                        alignItems: 'center',
                        width: '100%'
                    }} >

                        <DatePicker
                            value={dateFrom}
                            onChange={handleDateChange('dateFrom')}
                            label={vocabulary.startDate}
                            format={getDateFormat(currentLanguage)}
                            autoOk={true}
                            disableFuture={true}

                        />
                        <DatePicker
                            value={dateTo}
                            onChange={handleDateChange('dateTo')}
                            label={vocabulary.endDate}
                            minDate={dateFrom}
                            format={getDateFormat(currentLanguage)}
                            autoOk={true}
                            disableFuture={true}

                        />
                    </div>

                    <Grid container>
                        <Grid item style={{ flexGrow: 1 }}>
                            <AreaSelect onChangeFn={setSelectedAreaId} title={vocabulary.referenceArea} />

                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', marginTop: 20, marginBottom: 20 }} >
                                <Button color="secondary" variant="contained" onClick={() => { resetSelection() }} >{vocabulary.resetSelection}</Button>
                                <Button color="primary" variant="contained" disabled={selectedAreaId == null ? true : false} onClick={() => { findPluvio() }} >{vocabulary.findLabel}</Button>
                            </div>
                        </Grid>

                        {selectedAreaId && (
                            <Grid item style={{ flexGrow: 1 }}>
                                <Paper elevation={2} style={{ backgroundColor: '#f7f4f0', padding: 20, marginBottom: 16 }}>
                                    <Grid item container direction="column" style={{ display: 'flex' }}>
                                        <Grid item style={{ display: 'flex', flexWrap: "nowrap", flexDirection: 'row' }}>
                                            <div style={{ flexGrow: 1 }}>{vocabulary.rainfallEditReminder}</div>
                                            <Button
                                                disabled={waterNeedByDateList && waterNeedByDateList?.filter(e => e.correctedRainfall != null)?.length == 0 && changed}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<Save fontSize='large' />}
                                                onClick={() => handleClickSave()}
                                            >{vocabulary.saveChanges}</Button>
                                        </Grid>

                                        <Grid item style={{ display: 'flex', flexWrap: "nowrap", flexDirection: 'row', alignItems: "flex-end" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={multiple}
                                                        onChange={() => setMultiple(!multiple)}
                                                        name="multiple"
                                                        color="primary"
                                                    />
                                                }
                                                label={vocabulary.apply_changes_for_other_areas}
                                            />
                                            {multiple && <AreaSelect onChangeFn={setSelectedParcels} multipleSelect title="Parcelles concernées" />}
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>

                    {
                        loading
                            ? <StyledCircularProgress label={vocabulary.loading} height={100} />
                            :
                            <StyledMaterialTable
                                title={vocabulary.rainfallList}
                                data={waterNeedByDateList && waterNeedByDateList.length > 0 ? waterNeedByDateList : []}
                                localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}

                                columns={[
                                    {
                                        title: vocabulary.date,
                                        field: 'date',
                                        render: rowData => <>{moment(rowData.date).format('YYYY-MM-DD')}</>,
                                        editable: 'never'
                                    },
                                    {
                                        title: vocabulary.currentPluvio + "(" + vocabulary.metreJour + ')',
                                        field: 'totalPrecipitation',
                                        editable: 'never'
                                    },
                                    {
                                        title: vocabulary.correctedRainfall + "(" + vocabulary.metreJour + ')',
                                        field: 'correctedRainfall',
                                        type: "numeric",
                                        editable: 'onUpdate'
                                    },
                                ]}

                                options={{
                                    actionsColumnIndex: -1,
                                    search: true,
                                    rowStyle: mtStyleFn

                                }}

                                editable={{

                                    onRowUpdate: (newData, oldData) => {
                                        return new Promise((resolve) => {
                                            setTimeout(() => {
                                                if (waterNeedByDateList) {
                                                    const dataUpdate = [...waterNeedByDateList];

                                                    const target = dataUpdate.find((el) => el.date === oldData.date);
                                                    console.log("target", target);
                                                    const index = dataUpdate.indexOf(target);
                                                    dataUpdate[index] = newData;

                                                    setWaterNeedByDateList([...dataUpdate]);
                                                    resolve();
                                                }
                                            }, 1000);


                                        });
                                    },

                                }}
                            />
                    }

                </StyledCard>
            }
        </ModalContext.Provider>


    )
}

const InputCorrectedRainfall = ({ rowData, handleCorrection, confirmChangeFn }) => {
    const [changed, setChanged] = useState(false)
    const [showCaution, setShowCaution] = useState(true)
    const [valueRF, setValueRF] = useState(rowData.correctedRainfall ?? '')
    const inputRef = React.createRef();

    const confirmChange = useCallback(
        () => {
            if (changed) {
                setShowCaution(true)
                inputRef.current.focus()
                confirmChangeFn && confirmChangeFn()
            }
        }
        , [changed, inputRef]
    )

    return (
        <div onBlur={() => confirmChange()}>
            <Grid container direction="column">
                <Grid container item direction="row" style={{ alignItems: 'center' }}>
                    <StyledInput
                        innerRef={inputRef}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 999999999 }, }}
                        onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                                ev.preventDefault();
                                setChanged(false);
                                setShowCaution(false);
                                handleCorrection && handleCorrection(rowData.date, valueRF)
                            }
                        }}

                        onChange={(event) => { setValueRF(event.target.value); setChanged(true) }}
                        value={valueRF}

                    />
                    {changed && <CheckCircle onClick={() => { setChanged(false); setShowCaution(false); handleCorrection && handleCorrection(rowData.date, valueRF) }} />}
                    {changed && <Cancel onClick={() => { setValueRF(rowData.correctedRainfall ?? ''); setChanged(false); setShowCaution(false); inputRef.current.focus() }} />}
                </Grid>
                <Grid item>
                    {changed && showCaution && <div>{vocabulary.confirmCancelMessage}</div>}
                </Grid>
            </Grid>
        </div>
    )
}

export default PluvioList
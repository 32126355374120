import React from "react";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, Input, Checkbox, ListItemText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { vocabulary } from "../../App/Strings";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
    },
};


export default (props) => {

    const { register, indexV, rules, renderValue, name, styles, onChange, secondLabelField, currentLanguage, selectedIndex, errors, triggerValidation, label, data, valueField, labelField, ...properties } = props

    const useStyle = makeStyles((theme) => ({
        root: {
            margin: 8,
            ...styles,
            '& p': {
                fontSize: 12,
                width: '100%',

            }
        },
        '& .MuiListItem': {
            root: {
                '&$selected': {
                    color: 'white',
                    backgroundColor: 'rgba(200, 200, 200, 0.14)',
                    borderLeft: 'solid 4px',
                    paddingLeft: 12
                },
                color: '#333333',
            },

        },
    }))

    const classes = useStyle();

    const checkErrors = (errorType) => {
        switch (errorType) {
            case 'required':
                return vocabulary.required
            case 'minLength':
                return vocabulary.minLength + rules.minLength

            default:
                return true
        }
    }


    return (
        <FormControl className={classes.root}>
            <InputLabel id={name + "-label"}>{label}</InputLabel>

            <Select
                multiple
                inputRef={register(rules)}
                {...properties}
                value={selectedIndex}

                onChange={onChange}
                required={rules && rules.required}
                name={name}
                id={name}
                labelId={name + "-label"}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={renderValue ? renderValue : selected => { return data.filter(item => selected.indexOf(item.id) > -1).map(n => JSON.parse(n.name)['name_' + currentLanguage]).join(', ') }}
                MenuProps={MenuProps}
                fullWidth
                error={errors ? true : false}
            >
                {
                    data.map(item => (
                        <MenuItem key={item.id} value={item[valueField]}>
                            <Checkbox checked={selectedIndex.indexOf(item.id) > -1} />
                            <ListItemText primary={JSON.parse(item[labelField])['name_' + currentLanguage]} />
                        </MenuItem>
                    ))
                }
            </Select>
            <FormHelperText>{errors ? checkErrors(errors.type) : null}</FormHelperText>
        </FormControl>
    )

}
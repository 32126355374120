import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ActivateIntegrations } from '../../../../../globalsTools/network';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { StyledInput } from '../../../../../ui/forms';
import { vocabulary } from '../../../../Strings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';




const SencropActivateForm = ({ isEditing, partner }) => {

    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    const [email, setEmail] = useState(null)
    const [data, setData] = useState({});
    const [showDeviceForm, setShowDeviceForm] = useState(false)
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [deviceId, setDeviceId] = useState(null)
    const [shared, setShared] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [item, setItem] = useState()



    const activate = () => {

        ActivateIntegrations(partner, {
            credentials: {
                email,

            }
        }, (data) => {
            if (data.status == 200) {
                history.push(`/app/integrations/${partner}/configure`, { partner })
            } else {
                snackbar(vocabulary.errorOccurred + data.status, 'error')
            }

        })
    }

 
    return (

        <Grid container spacing={3}>
            <Grid item xs={9}>
                <StyledInput
                    name={vocabulary.email}
                    label={vocabulary.email}
                    type="text"
                    fullWidth
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}

                />
            </Grid>
            <Grid item xs={3} style={{ alignSelf: 'center', marginTop: "15px" }}
            >
                {
                    !isLoading ? <Button
                        variant='contained'
                        color='primary'
                        onClick={activate}
                        disabled={isLoading}
                    >
                        {vocabulary.connect}

                    </Button>
                        :
                        <CircularProgress size={30} />
                }
            </Grid>
        </Grid>
    );
}

export default SencropActivateForm


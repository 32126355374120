/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { vocabulary } from '../Strings';
import { setCurrentPage } from '../../actions';
import { ListPartnerIntegrations } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';

import PartnerCard from './partners/PartnerCard';
import { Grid } from '@material-ui/core';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';


const ListPartners = () => {


    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [openEdit, setOpenEdit] = useState(false);
    const [item, setItem] = useState(null)
    const [openAdd, setOpenAdd] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [partnerIntegrations, setPartnerIntegrations] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.partnerIntegrations));
        setIsLoading(true)
        ListPartnerIntegrations(data => {
            setPartnerIntegrations(data)
            setIsLoading(false)
        })
  

        document.title = vocabulary.partnerIntegrations

    }, [dispatch]); // N’exécute l’effet que a la première render

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.partnerIntegrations));
        document.title = vocabulary.partnerIntegrations

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const refreshFn = () => {
        ListPartnerIntegrations(setPartnerIntegrations)
    }


    return (
        <>
            {
                !isLoading ?
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {partnerIntegrations && partnerIntegrations.map((item, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <PartnerCard partner={item} refreshFn={refreshFn} />
                            </Grid>
                        ))}
                    </Grid>

                    : <StyledLoadingRow />
            }
        </>

    );

}



export default ListPartners;




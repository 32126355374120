/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Card, CardContent, CardHeader, Typography, FormControl, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core/';
// import { Add, Done } from '@material-ui/icons/';
import 'bootstrap/dist/css/bootstrap.css';


import { setCurrentPage } from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';
import MathJax from 'react-mathjax2'
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttacheIcon from '@material-ui/icons/AddToPhotos'
import Slide from '@material-ui/core/Slide';
import { getAllDeployedDevices, GetAreasList, GetFormulas, AttachFormulaToStation, DeleteFormula, LoadUserFormulas, GetEquipmentsType, createFormulaItem } from '../../globalsTools/network'
import { StyledInput } from '../../ui/forms'
import { useForm } from 'react-hook-form';
import StyledSelect from "../../ui/forms/StyledSelect";
import { StyledCard } from "../../ui/layout";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { Done } from '@material-ui/icons';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SelectedFormula from './SelectedFormula'
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import FormulaAddForm from './FormulaAddForm'
import AlertDialog from "../Components/alertDialog";
import { useSnackbar } from 'notistack';
import FormulaById from './FormulaById'
// const ascii = 'U = 1/(R_(si) + sum_(i=1)^n(s_n/lambda_n) + R_(se))'
// const content = `This can be dynamic text (e.g. user-entered) text with ascii math embedded in $$ symbols like $$${ascii}$$`
// const tex = `f(x) = \\int_{-\\infty}^\\i\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi`
const Formules = () => {

    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [selectedEquipmentId, setSelectedEquipmentId] = useState('')
    const [selectedDatatypeId, setSelectedDatatypeId] = useState('')
    const [formula, setFormula] = useState('')
    const [formulasList, setFormulasList] = useState(null)
    const [deployedDevices, setDeployedDevices] = useState(null)
    const [name, setName] = useState('')
    const [open, setOpen] = useState(false)
    const [currentFormula, setCurrentFormula] = useState('')
    const [userFormulas, setUserFormulas] = useState(true)
    const [openAttach, setOpenAttach] = useState(false)
    const [selectedAreaIndex, setSelectedAreaIndex] = useState('')
    const [selectedNodeIndex, setSelectedNodeIndex] = useState('')
    const [sensorType, setSensorType] = useState('')
    const [datatype_id, setDatatype_id] = useState('')
    const [currentformulaSensorName, setCurrentformulaSensorName] = useState('')
    const [currentFormulaDatatype, setCurrentFormulaDatatype] = useState('')
    const [attachedName, setAttachedName] = useState('')
    const [attachedDescription, setAttachedDescription] = useState('')
    const [description, setDescription] = useState('')
    const [siteList, setSiteList] = useState(null)
    const [equipmentFormula, setEquipmentFormula] = useState(null)
    const [equipments, setEquipments] = useState(null)
    const [userFormulasList, setUserFormulasList] = useState(null)
    const [equipmentDataTypeList, setEquipmentDataTypeList] = useState(null)

    const [latex, setLatex] = useState("");
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const resetForm = () => {

        setSelectedEquipmentId('')
        setFormula('')
        setName('')
        setDescription('')
        setSelectedDatatypeId('')

    }



    useEffect(() => {


        dispatch(setCurrentPage(vocabulary.formules))
        GetFormulas(setFormulasList)
        getAllDeployedDevices(setDeployedDevices)
        GetAreasList(setSiteList)
        GetEquipmentsType('Acquisition', setEquipments)
        LoadUserFormulas(setUserFormulasList)




        document.title = vocabulary.formules

    }, [dispatch]); // N’exécute l’effet qu'a la première render '


    // componentDidMount(){
    //     // MathLive.makeMathField('mf1', {
    //     //     virtualKeyboardMode: 'onfocus',      // Show the virtual keyboard on focus
    //     //     smartMode: false, 
    //     //     onContentDidChange: self.updateOutput
    //     // });

    //     MathLive.makeMathField('mf1', {
    //         smartMode: true,                        // Makes text entry easier
    //             virtualKeyboardMode: 'onfocus',      // Always show virtual keyboard glyph(manual,onfocus)
    //             onContentDidChange: self.updateOutput
    //         });
    // }
    // const mf = MathLive.makeMathField('mf', {
    //     smartMode: true,
    //     virtualKeyboardMode: 'manual',
    //     onContentDidChange: (mf) => {
    //         const latex = mf.getValue();
    //         document.getElementById('latex').value = latex;
    //     },
    // });

    const escapeHtml = (string) => {
        return String(string).replace(/[&<>"'`=/\u200b]/g, function (s) {
            return {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#39;',
                '/': '&#x2F;',
                '`': '&#x60;',
                '=': '&#x3D;',
                '\u200b': '&amp;#zws;'
            }[s] || s;
        });
    }

    const handleClickOpen = (item) => {
        setOpen(true)
        setCurrentFormula(item.formula)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                GetFormulas(setFormulasList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteFormula = (item) => {

        DeleteFormula(item.id
            , r => operationCallBack(r)
        )
    }


    const attachFormula = () => {
        AttachFormulaToStation(
            {
                station_id: sensorType,
                name: attachedName,
                description: attachedDescription,
                port: equipmentFormula,
                formula_id: currentFormula
            }, null);
    }

    const saveFormula = () => {
        createFormulaItem({
            formula: formula,
            equipment: selectedEquipmentId,
            datatype: selectedDatatypeId,
            name: name,
            description: description
        }, null)
    }

    const getUserFormulas = () => {
        setUserFormulas(true)

    }
    const getAllFormulas = () => {
        setUserFormulas(false)

    }


    const showAttachedPopup = (item) => {
        setOpenAttach(true)
        setCurrentFormula(item.id)
        setCurrentformulaSensorName(item.equipment.name)
        setCurrentFormulaDatatype(item.datatype.type)

    }

    const hideAttachedPopup = () => {
        setOpenAttach(false)

    }

    const onMathChange = (mathText) => {
        setLatex(mathText)
    }
    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const handleClickOpenAddForm = () => {
        setOpenAdd(true)
    };

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <>
                {(!isLoading && formulasList) ?
                    <StyledCard
                    // direction={'column'}
                    >
                        <StyledMaterialTable
                            title={vocabulary.formula}
                            data={formulasList.data}
                            detailPanel={[
                                {
                                    tooltip: 'Show Details',
                                    render: ({ rowData }) => { return (<FormulaById itemRow={rowData} />) }
                            }]}
                            columns={[
                                { title: vocabulary.name_label, field: 'name' },
                                { title: vocabulary.description, field: 'description', render: rowData => <>{ rowData.description ? typeof  rowData.description =='object' ?  rowData.description:JSON.parse(rowData.description).description: 'Check Data parsing '}</> },
                                { title: vocabulary.equipmentLabel, field: 'formula_prm_data_type_user', render: rowData => <>{ rowData.formula_prm_data_type_user.length > 0 ? JSON.parse(rowData.formula_prm_data_type_user[0].equipment.name).name_fr : ''}</> },
                                {
                                    title: vocabulary.datatype, field: 'formula_prm_data_type_user', render: rowData => <>{
                                        rowData.formula_prm_data_type_user.length > 0 ? 
                                        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '2' }}>
                                            {
                                                rowData.formula_prm_data_type_user.map((el, index) => {
                                                    return <span>{rowData.formula_prm_data_type_user.length > 0 && JSON.parse(el.prm_data_type.name).name_ar ? JSON.parse(el.prm_data_type.name)['name_' + currentLanguage] : JSON.parse(el.prm_data_type.name).name_fr}</span>
                                                })
                                            }
                                        </div>
                                        : ''
                                    }
                                    </>
                                },
                                { title: vocabulary.type, field: 'type' },

                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => onEdit(row)
                                },
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),

                                    disabled: row.id < 2000
                                }),
                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpenAddForm()
                                }

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false

                            }}
                        />

                        <FormulaAddForm
                            open={isEditing === false ? openAdd : openEdit}
                            onClose={() => {
                                return (
                                    isEditing === true ?
                                        (setOpenEdit(false),
                                            setIsEditing(false))
                                        : setOpenAdd(false)

                                )
                            }}
                            width={700}
                            item={isEditing === true ? item : null}
                            isEditing={isEditing}
                            refetchFunc={() => GetFormulas(setFormulasList)}
                            sitesList={siteList}
                            equipments={equipments && equipments.data}
                            setIsLoading={setIsLoading}

                        />
                    </StyledCard >
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />

                }
                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteFormula(item);
                            }
                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
            </>
        </ModalContext.Provider>

    );

}




export default Formules;
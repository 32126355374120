import React, { useState, useEffect } from 'react';


import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import CatalogModelAddForm from './CatalogModelAdd'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import { getSeabexCategoryModelList, DeleteSeabexStationByCategory } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const CatalogueModelList = ({ itemRow }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [catalogeModelList, setCatalogeModelList] = useState(null)

    useEffect(() => {

        if (itemRow) {
            getSeabexCategoryModelList(itemRow.id, setCatalogeModelList)
        }

    }, [itemRow])

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 204) {
                snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                getSeabexCategoryModelList(itemRow.id, setCatalogeModelList)

            } else {
                snackbar(vocabulary.errorOccurred + "  " + r.status, 'error');
            }
        }
    }
    const handleDeleteCatalog = (item) => {

        DeleteSeabexStationByCategory(item.id
            , r => operationCallBack(r)
        )
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {catalogeModelList ?

                <StyledMaterialTable
                    title={vocabulary.seabexModelCategory}
                    data={catalogeModelList.data}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                    columns={[
                        { title: vocabulary.name_label, field: 'name' },
                        { title: vocabulary.serielNumber, field: 'seriel_number' },
                        { title: vocabulary.type, field: 'type', render: rowData => <>{JSON.parse(rowData.type).type}</> },
                        { title: vocabulary.description, field: 'description', render: rowData => <>{JSON.parse(rowData.description).specifications}</> },

                    ]}
                    actions={[
                        {
                            icon: 'edit_outline',
                            tooltip: vocabulary.editLabel,
                            onClick: (event, row) => onEdit(row)
                        },
                        row => ({
                            icon: 'delete_outline',
                            tooltip: vocabulary.delete,
                            onClick: (event, row) => openAlertConfirmDelete(row),

                        }),
                        {
                            icon: () => <AddIcon fontSize='large' color='primary' />,
                            tooltip: vocabulary.add,
                            isFreeAction: true,
                            onClick: (event) => handleClickOpen()
                        }

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging:false
                    }}
                />
                :
                <StyledLoadingRow />
            }

            {alertConfirmDelete === true &&
                <AlertDialog
                    operation={vocabulary.delete}
                    open={alertConfirmDelete}
                    deletedItemName={item.name + ' (' + item.seriel_number + ' )'}
                    onClose={() => {
                        closeAlertConfirmDelete();
                    }}
                    confirmeDelete={() => {
                        if (item) {
                            handleDeleteCatalog(item)
                        }
                        
                        closeAlertConfirmDelete();
                    }}

                    cancel={() => {
                        closeAlertConfirmDelete();
                    }}
                />}


            <CatalogModelAddForm
                open={isEditing === false ? openAdd : openEdit}
                onClose={() => {
                    return (
                        isEditing === true ?
                            (setOpenEdit(false),
                                setIsEditing(false))
                            : setOpenAdd(false)

                    )
                }}
                item={isEditing === true ? item : null}
                id_seabexcategorie={itemRow.id}
                isEditing={isEditing}
                refetchFunc={() => getSeabexCategoryModelList(itemRow.id, setCatalogeModelList)}
            />
            

        </ModalContext.Provider>
    )
}
export default CatalogueModelList
import { logger } from "./utilities";

const eventSubscribe = (eventName, listener) => {
    logger("EVENT SUBSCRIBED", eventName);
    document.addEventListener(eventName, listener);
}
  
const eventUnsubscribe = (eventName, listener) => {
    logger("EVENT UN SUBSCRIBED", eventName);
    document.removeEventListener(eventName, listener);
}
  
const eventPublish = (eventName, data) => {
    logger("EVENT DISPATCHED", eventName);
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}
  
export { eventPublish, eventSubscribe, eventUnsubscribe };
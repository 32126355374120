import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../globalConsts";
import { useHistory } from "react-router-dom";
import {
  GuideContext,
  steps,
} from "../../ui/layout/contextLayout/GuideContext";

const LogsStepper = () => {
  const { guide, dispatchGuide } = useContext(GuideContext);
  const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element === ".realTime") {
            dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
            dispatchGuide({ type: CURRENT_STEP, payload: "realTime" });
            dispatchGuide({ type: IS_ENABLED, payload: false });
            history.push("/app/realtime");
          } 
      }}
      options={{
        tooltipClass: "steps",
      }}
      onExit={() =>
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        })
      }
    />
  );
};

export default LogsStepper;

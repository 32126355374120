/* eslint-disable eqeqeq*/
/* eslint-disable  no-useless-concat */

import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { GetAreaDetails, GetSatelliteTiles } from '../../../globalsTools/network';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
// import { AreaOnePager } from '../AreaOnePager';
// import SeabexMap from '../Map/SeabexMap';
import { FixedSizeList as List } from 'react-window';
import Image from 'material-ui-image';
import './AreaSatelliteUIStyle.css';
import {
  Accordion,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { AutoSizer } from '@enykeev/react-virtualized';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IndexEvolutionChart } from './IndexEvolutionChart';
import { StyledCard } from '../../../ui/layout';
import { vocabulary } from '../../Strings'
import { logger } from '../../../globalsTools/utilities';
import { StyledSelect } from '../../../ui/forms';

export const AreaSatelliteUI = ({ areaId, setOverlayImage, setOpacity }) => {

  const moment = require('moment');
  const currentLanguage = useSelector(state => state._globalState.preferred_language)

  const [area, setArea] = useState(null);
  const [satelliteTiles, setSatelliteTiles] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState('ndvi');
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [localOpacity, setLocalOpacity] = useState(100);

  const [selectedIndexProperties, setSelectedIndexProperties] = useState(null)
  const [minProp, setMinProp] = useState(null)
  const [maxProp, setMaxProp] = useState(null)
  const [gradientProp, setGradientProp] = useState(null)
  const [selectedYears, setSelectedYears] = useState([]);
  const [filteredTiles, setFilteredTiles] = useState(null)


  const gradientRedGreen = { background: `linear-gradient(to right, rgb(165, 0, 38), rgb(211, 50, 43), rgb(241, 109, 67), rgb(252, 171, 99), rgb(254, 220, 140), rgb(249, 247, 174), rgb(215, 238, 142), rgb(164, 216, 111), rgb(100, 188, 97), rgb(35, 150, 79), rgb(35, 150, 79))` }
  const gradientRedBlue = { background: `linear-gradient(to right, rgb(211, 50, 43), rgb(241, 109, 67), rgb(252, 171, 100), rgb(254, 220, 144), rgb(250, 248, 192), rgb(220, 241, 236), rgb(171, 214, 232), rgb(118, 171, 208), rgb(74, 116, 180), rgb(74, 116, 180))` }

  var indexes = [
    { name: 'ndvi', label: vocabulary.ndvi, description: vocabulary.ndvi_description, color: '#9C27B0', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 0, max: 1 },
    { name: 'ndwi', label: vocabulary.ndwi, description: vocabulary.ndwi_description, color: '#3F51B5', gradient: gradientRedBlue, gradientName: "gradientRedBlue", min: -1, max: 1 },
    { name: 'ndre', label: vocabulary.ndre, description: vocabulary.ndre_description, color: '#8BC34A', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 0, max: 1 },
    { name: 'chi', label: vocabulary.chi, description: vocabulary.chi_description, color: '#009688', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 0, max: 3 },
    { name: 'evi', label: vocabulary.evi, description: vocabulary.evi_description, color: '#FF9800', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 0, max: 1 },
    { name: 'savi', label: vocabulary.savi, description: vocabulary.savi_description, color: '#795548', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 0, max: 1 },
    { name: 'lai', label: vocabulary.lai, description: vocabulary.lai_description, color: '#B71C1C', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 1, max: 7 },
    { name: 'nirv', label: vocabulary.nirv, description: vocabulary.nirv_description, color: '#263238', gradient: gradientRedGreen, gradientName: "gradientRedGreen", min: 0, max: 1 },
    { name: 'rgb', label: vocabulary.rgb, description: vocabulary.rgb_description, color: '#263238' },
  ];


  /*
  SAVI / NDRE / NDVI / EVI / NIRV : Min : 0  - Max : 1
  background: linear-gradient(to right, rgb(165, 0, 38), rgb(211, 50, 43), rgb(241, 109, 67), rgb(252, 171, 99), rgb(254, 220, 140), rgb(249, 247, 174), rgb(215, 238, 142), rgb(164, 216, 111), rgb(100, 188, 97), rgb(35, 150, 79), rgb(35, 150, 79));
  
  CHI : Min : 0  - Max : 3
  background: linear-gradient(to right, rgb(165, 0, 38), rgb(211, 50, 43), rgb(241, 109, 67), rgb(252, 171, 99), rgb(254, 220, 140), rgb(249, 247, 174), rgb(215, 238, 142), rgb(164, 216, 111), rgb(100, 188, 97), rgb(35, 150, 79), rgb(35, 150, 79));
  
  
  LAI : Min : 0  - Max : 7
  background: linear-gradient(to right, rgb(165, 0, 38), rgb(211, 50, 43), rgb(241, 109, 67), rgb(252, 171, 99), rgb(254, 220, 140), rgb(249, 247, 174), rgb(215, 238, 142), rgb(164, 216, 111), rgb(100, 188, 97), rgb(35, 150, 79), rgb(35, 150, 79));
  
  NDWI : Min : 0 - Max : 1
  background: linear-gradient(to right, rgb(211, 50, 43), rgb(241, 109, 67), rgb(252, 171, 100), rgb(254, 220, 144), rgb(250, 248, 192), rgb(220, 241, 236), rgb(171, 214, 232), rgb(118, 171, 208), rgb(74, 116, 180), rgb(74, 116, 180));
  */


  useEffect(() => {
    if (areaId != null) {
      GetAreaDetails(areaId, setArea);
      GetSatelliteTiles(areaId, setSatelliteTiles);
    }
  }, [areaId]);

  useEffect(() => {
    let tiles = _.filter(satelliteTiles, (item) => {
      // return unescape(item.index).replaceAll('"', '') === selectedIndex;
      return item.index.replaceAll('"', '') === selectedIndex;
    });
    setSelectedTiles(tiles);
  }, [satelliteTiles, selectedIndex]);

  useEffect(() => {
    if (setOpacity) {
      setOpacity(localOpacity / 100);
    }
  }, [localOpacity, setOpacity]);


  const ImageItem = ({ index, style }) => {

    const GUTTER_SIZE = 10;

    return (
      <Card
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
        }}
        onClick={() =>
          setOverlayImage({
            bounds: JSON.parse(selectedTiles[index]['bounds']),
            path: unescape(selectedTiles[index]['path'])
              .replaceAll('"', '')
              .replaceAll('\\/', '/'),
          })
        }
      >
        <CardContent style={{ padding: 0 }}>
          <Typography style={{ background: "#efdec5" }}>{selectedTiles[index]['capture_date']}</Typography>
          {/* <Typography>{formatNumber(selectedTiles[index]['min'])+'/'+formatNumber(selectedTiles[index]['max'])+' - '+formatNumber(selectedTiles[index]['mean'])}</Typography> */}
        </CardContent>
        <Image
          src={unescape(selectedTiles[index]['path'])
            .replaceAll('"', '')
            .replaceAll('\\/', '/')}
        />
      </Card>
    );
  };

  useEffect(() => {
    if (selectedIndex) {
      let selectedIndexFromArray = indexes.find(x => x.name == selectedIndex)
      setSelectedIndexProperties(selectedIndexFromArray)
      setMinProp(selectedIndexFromArray.min)
      setMaxProp(selectedIndexFromArray.max)
      setGradientProp(selectedIndexFromArray.gradientName)
    }
  }, [selectedIndex])

  useEffect(() => {
    logger("AreaSatelliteUI -> minProp, maxProp, gradientProp : ", minProp, maxProp, gradientProp)
  }, [minProp, maxProp, gradientProp])

  const handleYearChange = (event) => {
    const selectedYearsArray = event.target.value;
    setSelectedYears(selectedYearsArray);


    const filteredData = selectedTiles.filter(item =>
      selectedYearsArray.includes(item.capture_date.split('-')[0])
    );

    setFilteredTiles(filteredData);
  };
  return (
    <>
      {area ? (

        <StyledCard direction='column'>
          {/* <SeabexMap /> */}
          {area.satellite === true ?
            <>
              <CardHeader
                title={vocabulary.satelliteImages}
                subheader={
                  vocabulary.lastupdatedLabel + ' ' +
                  (selectedTiles.length > 0 ? currentLanguage == "en" ? moment(selectedTiles[selectedTiles.length - 1].capture_date).format('YYYY-MM-DD') : moment(selectedTiles[selectedTiles.length - 1].capture_date).format('DD-MM-YYYY') : '--')
                }
              />

              <CardContent>
                <Grid container direction="row" spacing={4} wrap='nowrap'>
                  <Grid item>
                    <FormControl>
                      <InputLabel id='index-selection-helper-label'>
                        {vocabulary.satelliteIndex}
                      </InputLabel>
                      <Select
                        labelId='index-selection-helper-label'
                        id='index-selection-helper'
                        value={selectedIndex}
                        onChange={(event) => setSelectedIndex(event.target.value)}
                      >
                        {indexes.map((item, index) => (
                          <MenuItem key={'index' + index} value={item.name}>
                            {item.label + " (" + item.name.toUpperCase() + ")"}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {vocabulary.selectIndexLabel}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <Typography id='continuous-slider' align="left" gutterBottom>
                        {vocabulary.visibilityLabel}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item>
                          <VisibilityOff />
                        </Grid>
                        <Grid item xs>
                          <Slider
                            value={localOpacity}
                            onChange={(event, newValue) =>
                              setLocalOpacity(newValue)
                            }
                            aria-labelledby='continuous-slider'
                            valueLabelDisplay='auto'
                          />
                        </Grid>
                        <Grid item>
                          <Visibility />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  {selectedIndexProperties && selectedIndexProperties.gradient &&
                    <Grid item container direction='row' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <Typography>{minProp}</Typography>
                      <div style={{ flexGrow: 1, border: `solid 1px`, height: 20, background: selectedIndexProperties.gradient.background }}></div>
                      <Typography>{maxProp}</Typography>
                    </Grid>
                  }
                </Grid>

                {
                  <Accordion expanded>
                    <AccordionDetails>
                      {selectedIndexProperties && selectedIndexProperties.description}
                    </AccordionDetails>
                  </Accordion>
                }

                {selectedTiles && selectedIndex &&
                  <StyledSelect
                    value={selectedYears}
                    onChange={handleYearChange}
                    name='customer_type'
                    label={vocabulary.customer_type}
                    data={[...new Set(selectedTiles.map(item => item.capture_date.split('-')[0]))]}
                    multiple={true}
                  />
                }

                {selectedTiles && selectedIndex && (
                  <AutoSizer disableHeight defaultHeight={210}>
                    {({ height, width }) => (
                      <List
                        width={width}
                        height={210}
                        itemCount={selectedTiles ? selectedTiles.length : 0}
                        itemSize={120}
                        layout='horizontal'
                      >
                        {ImageItem}
                      </List>
                    )}
                  </AutoSizer>
                )}
                {
                  selectedIndexProperties && selectedIndexProperties.gradientName &&
                  <IndexEvolutionChart data={filteredTiles ?? selectedTiles}
                    index={selectedIndex}
                    min={minProp}
                    max={maxProp}
                    backgroundStyle={gradientProp} />
                }


                {/* <AreaOnePager initialSelectedArea={area} />  */}
              </CardContent>
            </>
            : <StyledCard direction='column'>
              <CardContent>
                {area.satellite === "__PROCESSING__"
                  ? <Typography><b>It's coming! please check back in 15 mins.</b></Typography>
                  : <Typography>{vocabulary.noSatteliteImageAvailable}</Typography>
                }
              </CardContent>
            </StyledCard>
          }

        </StyledCard>
      ) : (
        <StyledLoadingRow />
      )}
    </>
  );
};

/* eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceAddForm from './InvoiceAddForm'

import FollowIcon from '@material-ui/icons/HowToReg';
import UnfollowIcon from '@material-ui/icons/PersonAddDisabled'
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RestoreIcon from '@material-ui/icons/Restore';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { CloudDownload, FontDownload, PanoramaFishEye, PanoramaFishEyeOutlined, Refresh, RemoveRedEye } from '@material-ui/icons';
import { setCurrentPage } from '../../../../actions';
import StyledCircularProgress from '../../../../ui/display/StyledCircularProgress';

import { vocabulary } from '../../../Strings';
import { StyledMaterialTable } from '../../../../ui/display';
import { StyledCard } from '../../../../ui/layout';
import { ModalContext } from '../../../../ui/layout/contextLayout/ModalContext';
import AlertDialog from '../../../Components/alertDialog';
import { GetAllInvoices, GetInvoiceById, UpdateInvoice } from '../../../../globalsTools/network';
import { Link, useHistory } from 'react-router-dom';
import { Link as MatLink } from '@material-ui/core';


const InvoiceListTable = () => {

    const dispatch = useDispatch();
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [allInvoiceList, setAllInvoiceList] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [invoiceListById, setInvoiceListById] = useState(null)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.accountingManagement));

        GetAllInvoices(setAllInvoiceList)
        document.title = vocabulary.accountingManagement

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.accountingManagement));
        document.title = vocabulary.accountingManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        history.replace("/app/billingManagement/invoices/add");
    };
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r, row) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isActive == true ? row.name + ' ' + vocabulary.activeLabel : row.name + ' ' + vocabulary.inactiveLabel, 'success')
                GetAllInvoices(setAllInvoiceList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const HandleDesactiveUser = (row) => {
        setIsActive(false)
        UpdateInvoice(
            {
                id: row.id,
                active: false
            }
            , r => operationCallBack(r, row)
        )

    }

    const handleActiveUser = (row) => {
        setIsActive(true)

        UpdateInvoice({
            id: row.id,
            active: true
        }
            , r => operationCallBack(r, row)
        )

    }
    const getAreaByUserList = (id) => {
        GetInvoiceById(id, setInvoiceListById)

    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {allInvoiceList ?
                    <StyledMaterialTable
                        title={vocabulary.invoiceList}
                        data={allInvoiceList.invoices}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[
                            { title: vocabulary.identifier, field: 'id' },
                            { title: vocabulary.invoiceNumber, field: 'invoice_number' },
                            {
                                title: vocabulary.invoicePaid, field: 'paid', render: rowData => <>{rowData.paid ? vocabulary.yes : vocabulary.no}</>
                            },
                            { title: vocabulary.status, field: 'status' },
                            { title: vocabulary.invoiceCurrencyAmount, field: 'currency_amount' },
                            { title: vocabulary.invoiceAmount, field: 'amount', render: rowData => <>{rowData.amount +' ' + rowData.currency}</> },
                            { title: vocabulary.date, field: 'date' },
                            { title: vocabulary.invoiceCurrencyTax, field: 'currency_tax' },
                            { title: vocabulary.invoiceDiscount, field: 'discount' },
                            { title: vocabulary.invoiceDeadline, field: 'deadline' },
                            {
                                title: vocabulary.invoiceIsDraft, field: 'is_draft', render: rowData => <>{rowData.is_draft ? vocabulary.yes : vocabulary.no}</>
                            },
                            {
                                title: vocabulary.downloadLabel, field: 'file_url', render: rowData => <>{
                                    <MatLink href={rowData.file_url} target="_blank"> <CloudDownload /> </MatLink>
                                }</>
                            },
                            {
                                title: vocabulary.name, field: 'customer.name', render: rowData => <>{
                                    <Link to={`/app/billingManagement/customers/${rowData.customer.source_id}`} > {rowData.customer.name} </Link>
                                }</>
                            },
                            { title: vocabulary.type, field: 'customer.customer_type' },
                            { title: vocabulary.mobile, field: 'customer.phone' },

                        ]}
                        actions={[
         

                            row => ({
                                icon: 'edit_outline',
                                tooltip: vocabulary.EditUser,
                                disabled: row.type === "Super",
                                onClick: (event, row) => onEdit(row),
                                hidden: row.status != 'draft'
                            }),
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80],
                            exportMenu: [{
                                label: 'Export PDF',
                                exportFunc: (cols, datas) => ExportPdf(cols, datas, "Users List")
                            }, {
                                label: 'Export CSV',
                                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Users List')
                            }]

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

  


            </StyledCard>
        </ModalContext.Provider>

    )
}
export default InvoiceListTable

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {  CircularProgress, Chip, Card  } from '@material-ui/core/';
// import IconButton from '@material-ui/core/IconButton';
// import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import { vocabulary } from '../Strings';
import { setCurrentPage, loadDataStatistics } from '../../actions';
import AlertIcon from '@material-ui/icons/NotificationsActive';
import AdviceIcon from '@material-ui/icons/VpnKey';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts';
import { styles } from '../globalStyle';

const Statistics = (props) => {

  const dispatch = useDispatch()
  const appLoading = useSelector(state => state.app.appLoading)
  const stats = useSelector(state => state.organization.nbNotifs)
  const currentLanguage = useSelector(state => state._globalState.preferred_language)


  // const [data, setData] = useState([]);
  // const [keys, setKeys] = useState([]);
  // const [values, setValues] = useState([]);

 

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.statistics))
    dispatch(loadDataStatistics());
    document.title=vocabulary.statistics

  }, [dispatch,currentLanguage]); // N’exécute l’effet que si count a changé

  
 
  // const  jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';
  const styles = props.classes;

return (
  <div
    className={styles.container}
    style={{
      padding: 10,
      minHeight: '100vh',
      flexDirection: 'column',
    }}
  >

    <div style={{
      visibility: appLoading ? 'visible' : 'hidden',
      position: 'absolute',
      left: '50%',
      top: '45%',

    }}>
      <CircularProgress size={50} />
    </div>

    <div style={{ opacity: appLoading ? 0.4 : 1 }} >
      {/* <FormControl className={styles.textField} style={{marginLeft:'15px', marginBottom:'15px'}}>
          <InputLabel htmlFor="adornment-password">CTV name</InputLabel>
          <Input
            id="adornment-password"
            type='text'
            value={this.state.searchField}
            onChange={this.handleChange('searchField')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Search"
                  //onClick={this.findOrganisme.bind(this)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl> */}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {stats&&stats.map(o => {
          return (
            <div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'Row' }} >
              <Card>
                <h3 style={{ marginLeft: '3px' }}>CTV: {o.name}</h3>
                <BarChart
                  width={500}
                  height={300}
                  data={o.data}
                  margin={{
                    top: 25, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="fullname" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="advices" fill="#8884d8" />
                  <Bar dataKey="alerts" fill="#82ca9d" />
                </BarChart>
              </Card>
              <Card style={{ display: 'flex', flexDirection: 'Column', marginRight: '15px' }} >
                <Chip
                  size="small"
                  label={"Total notifications: " + o.total}
                  className={[styles.chip, styles.colorTextChips, styles.colorGateway]}
                />
                <Chip
                  icon={<AlertIcon className={classNames(styles.colorTextChips)} />}
                  color="secondary"
                  size="small"
                  label={"Alerts: " + o.alerts}
                  className={[styles.chip, styles.colorTextChips]}
                />
                <Chip
                  icon={<AdviceIcon className={styles.colorTextChips} />}
                  color="primary"
                  size="small"
                  label={"Advices: " + o.advices}
                  className={[styles.chip, styles.colorTextChips]}
                />
              </Card>
            </div>
          )

        })}

      </div>
    </div>
  </div>
);
  
}

export default withStyles(styles)(Statistics)
/* eslint-disable */
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent, CardHeader, Typography, Button, Chip } from '@material-ui/core/';
import wifiGreen from '../../images/wifiGreen.png';
import wifiRed from '../../images/wifiRed.png';
import 'bootstrap/dist/css/bootstrap.css';
// import mqtt from 'mqtt';
import { setCurrentPage } from '../../actions';
import Actuator from './actuator';
import ReactSpeedometer from "react-d3-speedometer"
import TodayWeather from './TodayWeather'
import { vocabulary } from '../Strings';
import './../../App.css';

import "./style.css";
import StyledEmptyDataLabel from '../../ui/display/StyledEmptyDataLabel'
// import LineChartRealTime from '../Charts/LineChart/LineChartRealTime'
import { affectRTValuesToSensors, affectActuators, getPercent } from './Tools/ConversionFunctions';
import FilteringCard from './FilteringCard';
import { useSnackbar } from 'notistack';
import { GetEquipmentById, SendCommand, GetPrmDataTypeByAreaId } from '../../globalsTools/network';
import { StyledMaterialTable } from '../../ui/display';
import { useHistory } from 'react-router-dom';
import MonitoringPageStepper from './MonitoringPageStepper';
import { GuideContext, steps } from '../../ui/layout/contextLayout/GuideContext';
import CurrentPage from '../Components/CurrentPage';
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from '../globalConsts';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { mqttClient } from '../MqttController/MqttController';

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import StopIcon from '@material-ui/icons/Stop';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import { styles } from '../globalStyle';
import ActionsCard from './ActionsCard';
import { logger } from '../../globalsTools/utilities';

var _ = require('lodash');

const MonitoringPage = ({ location, classes }) => {

  const EQUIPMENTS = "Equipments"
  const moment = require('moment');
  let history = useHistory();
  const NEW_REALTIME_ITEM = "New Realtime Item"
  const ADDED_EQUIPMENT = "Added Equipment"
  const userId = useSelector(state => state.app.userid)
  const appLoading = useSelector(state => state.app.appLoading)

  let now = new Date().toISOString()
  // const mqttClientId = "mqttjs_" + userId + '&useragent=' + navigator.userAgent; // TODO Track user using msqtt , check that later
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const currentLanguage = useSelector(state => state._globalState.preferred_language)

  const [realTimeNodeArray, setRealTimeNodeArray] = useState([]);
  const [realTimeActionArray, setRealTimeActionArray] = useState([]);
  const [getwayID, setGetwayID] = useState('');

  const [siteId, setSiteId] = useState('+');
  const [parcelId, setParcelId] = useState('+');
  const [sectorId, setSectorId] = useState("+");
  const [siteName, setSiteName] = useState('');

  const [areaId, setAreaId] = useState('');
  const [automaticMode, setAutomaticMode] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const [equipments, setEquipments] = useState([]);
  const [DeployedDevices, setDeployedDevices] = useState([]);
  // const [realtimeData, setRealtimeData] = useState([])
  const { guide, dispatchGuide } = useContext(GuideContext)
  const [lastRealtimeData, setLastRealtimeData] = useState([]);
  const [newTopic, setNewTopic] = useState('');
  const [dataTypeListByArea, setDataTypeListByArea] = useState(null);
  const [loading, setLoading] = useState(false)

  const [devicesStatus, setDevicesStatus] = useState([])

  useEffect(() => {
    if (lastRealtimeData.length > 0) {
      for (const item of lastRealtimeData) {
        disptachRealtimeData({ type: NEW_REALTIME_ITEM, newItem: item })
      }
    }

  }, [lastRealtimeData])



  const getEquipmentFromState = (idEquipment, port, deviceId, captureTime) => {// Get Equipment Details

    let itemIndex = equipments.findIndex(
      element => element.id === idEquipment && element.port === port && element.deployed_device_id === deviceId
      //&& element.capture_time === captureTime
    );
    if (itemIndex === -1) {
      GetEquipmentById(idEquipment, (data) => { // API CALL GET EQUIPMENT By ID
        data.port = port
        setEquipments(_.concat(equipments, data))
        disptachRealtimeData({ type: ADDED_EQUIPMENT, section: EQUIPMENTS, id: idEquipment, item: data })
      })
      return {}
    } else {
      return equipments[itemIndex]
    }

  }


  const [realtimeData, disptachRealtimeData] = useReducer((realtimeData, action) => {
    switch (action.type) {
      case NEW_REALTIME_ITEM:
        if (action.newItem) {
          let item = action.newItem;
          let itemIndex = realtimeData.findIndex(// Get Index of the existing data
            element =>

              element.equipment_id === item.equipment_id && element.deployed_device_id === item.deployed_device_id
              && element.prm_data_type_id === item.prm_data_type_id && element.port === item.port
          );

          // let itemIndex = _.findIndex(realtimeData, (o) => {
          //   return o.equipment_id == item.equipment_id
          //     & o.deployed_device_id == item.deployed_device_id
          //     & o.datatype_id == item.datatypeidentifier_id
          //     & o.port == item.port
          // } );
          let SORT_ORDER = ['deployed_device_id', 'port', 'datatype']
          if (itemIndex == -1) {// If data exist on the realtimeData
            let realtimeDataCopy = [];// Create new array 
            let itemEquipment = getEquipmentFromState(item.equipment_id, item.port, item.deployed_device_id, item.capture_time, item.calculated_value)// API CALL GET EQUIPMENT
            realtimeDataCopy = _.sortBy(_.concat(realtimeData, {
              ...item,
              itemEquipment: itemEquipment,
              newValue: true,
              lastUpdate: _.now(),
              calculated_value: item.datatypeidentifier == "relay_status" ? item.original_value == 1 ? 1 : 0 : Number(item.calculated_value).toFixed(3),
              original_value: item.datatypeidentifier == "relay_status" ? item.original_value == 1 ? 1 : 0 : Number(item.original_value).toFixed(3),
              capture_time: item.capture_time

            }
            ), SORT_ORDER)

            return realtimeDataCopy
          } else if (itemIndex != -1) {// If data  doesnt exist on the realtimeData
            let realtimeDataCopy = _.map(realtimeData, (o, index) => {
              if (index == itemIndex) {
                return {
                  ...o,
                  newValue: false,
                  calculated_value: item.datatypeidentifier == "relay_status" ? item.original_value == 1 ? 1 : 0 : Number(item.calculated_value).toFixed(3),
                  capture_time: item.capture_time,
                  lastUpdate: _.now(),
                  original_value: item.datatypeidentifier == "relay_status" ? item.original_value == 1 ? 1 : 0 : Number(item.original_value).toFixed(3)
                }
              } else {
                return { ...o, newValue: o.lastUpdate && _.now() - o.lastUpdate < 30000 }
              }
            })

            return realtimeDataCopy
          }
        }
        break;

      case ADDED_EQUIPMENT:
        if (action.section) {

          let field, fieldObject
          let id = action.id
          let item = action.item
          if (action.section == EQUIPMENTS) {
            field = "equipment_id"
            fieldObject = "itemEquipment"
            // item  = getEquipmentFromState(id)
          } else {
            field = "deployed_device_id"
          }
          let realtimeDataCopy = _.cloneWith(realtimeData, true)
          _.forEach(realtimeDataCopy, (o, index, realtimeDataCollection) => {
            if (o[field] == id) {
              realtimeDataCollection[index][fieldObject] = item
            }
          })
          return realtimeDataCopy
        } else {

          return realtimeData;
        }

      default:
        return realtimeData
    }
  }, [])



  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,
      resumeHideDuration: 6,
      style: { whiteSpace: 'pre-line' }
    }
    );
  };

  useEffect(() => {
    if (guide.currentStep === "realTime" && (steps[guide.initialStep].element === ".realTimeintro" || steps[guide.initialStep].element === ".realTime") && guide.isEnabled === false) {
      {
        setTimeout(() => {
          dispatchGuide({ type: IS_ENABLED, payload: true })
        }, 1000);
      }
    }
  }, [])

  useEffect(() => {
    dispatch(setCurrentPage(<CurrentPage name={vocabulary.realTimeSensorsAndActuators} helpFn={helpFn} />))
    document.title = vocabulary.realTimeSensorsAndActuators

  }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  const helpFn = () => {
    dispatchGuide({ type: INITIAL_STEP, payload: steps.findIndex(step => step.element === ".realTimeintro") })
    dispatchGuide({ type: CURRENT_STEP, payload: 'realTime' })
    setTimeout(() => {
      dispatchGuide({ type: IS_ENABLED, payload: true })
    }, 500);
  }
  const handleChange = newAreaId => {
    setAreaId(newAreaId)
  };

  const reconnectAndreSubscribeUnbound = (getwayID) => {


    mqttClient.on('message', (topic, message) => {

      logger("On Message MQTT : ", topic, " - ",message, message.toString())

      try {
        message = JSON.parse(message.toString().replace(/[^\x20-\x7E]/g, '')); // to convert buffer to string
      } catch(error) {
        message = message.toString().replace(/[^\x20-\x7E]/g, '')
      }

      // for disconnecting 
      // TODO
      // mqttClient.end()
      // if (topic.indexOf('/notify/') != -1) {// If We got a notification we must show a snack bar 
      //   self.affectRTValuesToSensors(message)
      // }

      if (topic.indexOf('/device_status') != -1) {
          let [user_id, device_identifier] = topic.split("/")
          let device_port_status = []
          message = message.split(":")
          message.shift()
          message.map((element) => {
            if (element.indexOf("_") > -1) {
              let [key, value] = element.split("_")
              logger("Element key, value ", element, element.split("_"), key, value); 
              device_port_status[key] = value
            }
          })
          let devicesStatusCopy = [...devicesStatus]
          devicesStatusCopy[device_identifier] = device_port_status
          setDevicesStatus(devicesStatusCopy) 
          /*

          {
            '84564351884 (device identifier)' : 'status_chain',
            '84564351884 (device identifier)' : 'status_chain',
            '84564351884 (device identifier)' : 'status_chain',
            '84564351884 (device identifier)' : 'status_chain',
          }
          */
      }
      if (topic.indexOf('/realtime/') != -1) {
        let realData = message;
        // TODO make the realtime changes ( change datatype dynamicaly)
        if (Array.isArray(realData)) {
          if (realData.length > 0) {
            for (const item of realData) {
              // TODO REMOVE REDUCER
              disptachRealtimeData({ type: NEW_REALTIME_ITEM, newItem: item })// Add the new item to the REAL TIME DATA ARRAY
            }
          }
        }
        // else { // in case of 
        //   disptachRealtimeData({ type: NEW_REALTIME_ITEM, newItem: realData })
        // }
      }

      if (topic.indexOf('/notify/') != -1) {// If We got a notification we must show a snack bar 
        let notification = typeof message == "object" ? message : JSON.parse(message)
        let action = notification.Details.Action == "true" ? vocabulary.activatedLabel : vocabulary.desactivatedLabel;

        switch (notification.Type) {



          case "GETMODEAUTO":
            if (notification.Message.ActualData) {
              notification.Message.ActualData.forEach(element => {
                if (element.Type === "ACK") {
                  setRealTimeNodeArray(affectRTValuesToSensors(element, realTimeNodeArray))
                }
                else if (element.Type === "ACT") {
                  setRealTimeActionArray(affectActuators(element, realTimeActionArray))
                }
              });
            }
            setAutomaticMode(eval(notification.Message.ModeAuto))
            break;

          default:
            break;
        }
      }
    });

  }// END reconnectAndreSubscribeUnbound


  // TODO Why USING THIS  ???????
  // const reconnectAndreSubscribe = reconnectAndreSubscribeUnbound.bind(this)


  useEffect(() => {
    if (mqttClient != null && siteId != null) {
       
      siteId !== '+' && GetPrmDataTypeByAreaId(siteId, setDataTypeListByArea)
    }
  }, [siteId, mqttClient])

  useEffect(() => {
    if (mqttClient != null) setIsConnected(true)
    else setIsConnected(false)
  }, [mqttClient])


  useEffect(() => {// DYNAMIC SUBSCRIBES
    if (mqttClient != null) {
      if (isConnected && ((siteId !== "+"))) {
        // let buildTopic = `/realtime/${userId}/${BuildTopic(siteId, parcelId, sectorId)}/#`
        let buildTopic = `${userId}/+/device_status`
        if (newTopic != "") {
          mqttClient.unsubscribe(newTopic, function (err) {
            if (!err) {
              console.log('MQTT UNSUBSCRIBE FROM', newTopic);
            } else {
              console.log('MQTT UNSUBSCRIBE ERROR FROM', newTopic);
            }
          })
        }
        mqttClient.subscribe(buildTopic, function (err, granted) {
          if (err) {
            snackbar('[SUBSCRIBE] ' + vocabulary.errorOccurred + ': ' + err, 'error')
          } else {
            setNewTopic(buildTopic)
          }
        })
      }
    }
  }, [isConnected, userId, siteId, parcelId, sectorId, mqttClient, getwayID])

  const handleSendCommand = (rowData) => {
    let command_value = rowData.original_value == 1 ? "false" : rowData.original_value == 0 ? "true" : ""
    let command = 'A:R' + rowData.port + '/' + command_value
    SendCommand(
      {
        equipment_id: rowData.equipment_id,
        message: JSON.stringify({ 'type': 'COMMAND', 'data': { "Destination": rowData.data ? typeof rowData.data == 'object' ? rowData.data.DeviceID : JSON.parse(rowData.data).DeviceID : rowData.deployeddeviceidentifier, 'Message': command } }),
        identifier: rowData.data ? typeof rowData.data == 'object' ? rowData.data.DeviceID : JSON.parse(rowData.data).DeviceID : rowData.deployeddeviceidentifier,
        port: rowData.port
      }

    )
  }
  const getLimit = (dataTypeIdentifier, specifications, type) => {
    let tab = specifications.filter(el => el.dataTypeName == dataTypeIdentifier)
    return Number(tab[0][type])
  }

  const navigateToDeployedDevices = () => {
    history.push('/app/stations');
  };

  const getPortNameType = (data, port) => {
    let item = data.find(el => el.port_name == port);
    return item.port_type + " (" + item.port_name + ")";
  };

  
  useEffect(() => {
    logger("devicesStatus monitoring page", devicesStatus)
  }, [devicesStatus])

  return (

    <>
      {guide.isEnabled && guide.currentStep === 'realTime' &&
        <MonitoringPageStepper />
      }
      <FilteringCard
        initialAreaId={location && location.state && location.state.areaId ? location.state.areaId : ''}
        initialGetwayID={getwayID}
        handleChangeFn={handleChange}
        siteSetter={setSiteId}
        parcelSetter={setParcelId}
        sectorSetter={setSectorId}
        getwayIdSetter={setGetwayID}
        siteName={setSiteName}
        deployedDevice={setDeployedDevices}
        lastRealtimeData={setLastRealtimeData}
        setAreaId={setAreaId}
        loading={loading}
        setLoading={setLoading}
      />


      {areaId && devicesStatus && 
        <>
          <ActionsCard
            areaId={areaId}
            devicesStatus = {devicesStatus}
          />
          <TodayWeather
            areaId={areaId}
            siteName={siteName}
          />
        </>
      }

      {/* WARNING TO DO NOT REMOVE /  to check why realTime chart can be dynamic only if this block is hidden */}
      {/* <div style={{ display: 'none' }}>
        {(realtimeData.length > 0 && areaId) &&
          <LineChartRealTime
            realtimeData={realtimeData}
            linedataLabel={vocabulary.realTimeData}
            equipments={equipments && equipments}
            dataTypeListByArea={dataTypeListByArea}
          />
        }
      </div> */}


      {(areaId) && !loading &&
        <div style={{ marginTop: '10px' }}>
          {
            DeployedDevices.length > 0 ?
              <div>

                {realtimeData.length > 0 ?

                  <>
                    {realtimeData.filter(value => value.equipment_type == "Action").length > 0 &&
                      <StyledMaterialTable
                        data={realtimeData.length > 0 ? realtimeData.filter(value => value.equipment_type == "Action") : []}
                        title={<div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-start", paddingTop: 10 }}><Typography variant="h3">{vocabulary.controlPanel}</Typography><Typography>{vocabulary.realTimeData + " : " + (isConnected ? vocabulary.connectedLabel : vocabulary.notConnectedLabel)} &nbsp;&nbsp; <img style={{ width: '25px', height: '25px' }} src={isConnected ? wifiGreen : wifiRed} /> </Typography></div>}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[

                          { title: vocabulary.deployedDevices, field: "deployedDeviceName", render: (rowData) => <>{rowData.deployeddevicename ? rowData.deployeddevicename : rowData.deployedDeviceName}</> },
                          { title: vocabulary.port, field: "port", render: (rowData) => <>{rowData.configuration ? getPortNameType(JSON.parse(rowData.configuration), rowData.port) : rowData.port}</> },
                          { title: vocabulary.equipmentLabel, render: (rowData) => { try { let equipment = rowData.itemEquipment ? JSON.parse(rowData.itemEquipment.name).name_fr + ' (' + rowData.equip_device_description + ' )' : vocabulary.loading; return equipment } catch (e) { return vocabulary.loading } } },
                          { title: vocabulary.captureTime, field: 'captureTime', render: (rowData) => <>{rowData.captureTime ? rowData.captureTime && moment(rowData.captureTime).format('YYYY-MM-DD HH:mm:ss') : rowData.capture_time && moment(rowData.capture_time).format('YYYY-MM-DD HH:mm:ss')}</>, cellStyle: rowData => { if (rowData && rowData.newValue) return { backgroundColor: '#FFEEEE' }; else return {}; } },
                          {
                            title: vocabulary.status, render: (rowData) => {
                              return Number(rowData.original_value) === 1
                                ? <Chip variant="outlined" label={vocabulary.stateOpen} className={classes.openStatusChip} icon={<PlayCircleFilledWhiteIcon className={classes.openStatusChip} />} />
                                : <Chip variant="outlined" label={vocabulary.stateClosed} className={classes.closeStatusChip} icon={<StopIcon className={classes.closeStatusChip} />} />
                            }, cellStyle: (rowData) => { if (rowData && rowData.newValue) { return { backgroundColor: '#FFEEEE' }; } else return {}; }
                          },
                          {
                            title: vocabulary.action, align: 'center', field: " ", render: rowData => {
                              return <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Button
                                    variant="contained"
                                    size="large"
                                    style={Number(rowData.original_value) === 0 ? { backgroundColor: "green", color: "white" } : { backgroundColor: "orange", color: "white" }}
                                    onClick={() => handleSendCommand(rowData)}
                                    disabled={rowData.itemEquipment ? rowData.itemEquipment.name ? false : true : false}
                                    startIcon={Number(rowData.original_value) === 0 ? <PlayCircleFilledWhiteIcon /> : <StopIcon />}
                                  >
                                    {
                                      Number(rowData.original_value) === 1 ? vocabulary.closeAction : vocabulary.openAction
                                    }
                                  </Button>
                                </div>
                              </>
                            }
                          }
                        ]}
                        options={{
                          pageSize: 20,
                          search: false,
                          pageSizeOptions: [10, 20, 40, 60, 80, 100],
                          emptyRowsWhenPaging: false,
                        }}

                      />


                    }
                    {
                      realtimeData.filter(value => value.equipment_type == "Acquisition").length > 0 &&
                      <StyledMaterialTable
                        data={realtimeData.length > 0 ? realtimeData.filter(value => value.equipment_type == "Acquisition") : []}
                        title={<div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-start", paddingTop: 10 }}><Typography variant="h4">{vocabulary.realtimeMonitoringPanel}</Typography></div>}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}
                        //                             detailPanel={[
                          // {
                          //   tooltip: 'Show Details',
                          //   render: ({ rowData }) => {
                        //   return (<LineChartRealTime
                        //     realtimeData={realtimeData.length > 0 ? realtimeData: lastRealtimeData}
                        //     linedataLabel={vocabulary.realTimeData}
                        //     // equipments={equipments&&equipments}
                        //     itemRow={rowData}
                        //   // dataTypeListByArea={dataTypeListByArea}
                        //   // test={true}

                        //   />) }

                        // }]}
                        columns={[

                          { title: vocabulary.deployedDevices, field: "deployedDeviceName", render: (rowData) => <>{rowData.deployeddevicename ? rowData.deployeddevicename : rowData.deployedDeviceName}</> },
                          { title: vocabulary.port, field: "port", render: (rowData) => <>{rowData.configuration ? getPortNameType(JSON.parse(rowData.configuration), rowData.port) : rowData.port}</> },
                          { title: vocabulary.equipmentLabel, render: (rowData) => { try { let equipment = rowData.itemEquipment ? JSON.parse(rowData.itemEquipment.name).name_fr : vocabulary.loading; return equipment } catch (e) { return vocabulary.loading } } },

                          { title: vocabulary.equipmentLabel, render: rowData => { try { let equipment = rowData.itemEquipment ? typeof rowData.itemEquipment.name !== "object" ? JSON.parse(rowData.itemEquipment.name).name_fr : rowData.itemEquipment.name.name_fr : JSON.parse(rowData.equipment).name_fr; return equipment } catch (e) { return vocabulary.loading } } },
                          { title: vocabulary.datatype, field: "datatypeObject", render: (rowData) => { return rowData.hasOwnProperty('datatypeobject') ? JSON.parse(rowData.datatypeobject)['name_' + currentLanguage] : JSON.parse(rowData.datatype)['name_' + currentLanguage] } },
                          { title: vocabulary.captureTime, field: 'captureTime', render: (rowData) => <>{rowData.captureTime ? rowData.captureTime && moment(rowData.captureTime).format('YYYY-MM-DD HH:mm:ss') : rowData.capture_time && moment(rowData.capture_time).format('YYYY-MM-DD HH:mm:ss')}</>, cellStyle: rowData => { if (rowData && rowData.newValue) return { backgroundColor: '#FFEEEE' }; else return {}; } },
                          { title: vocabulary.originalValue, field: 'original_value', render: (rowData) => { return rowData.original_value ? Number(rowData.original_value).toFixed(3) : 0 }, cellStyle: rowData => { if (rowData && rowData.newValue) return { backgroundColor: '#FFEEEE' }; else return {}; } },
                          { title: vocabulary.originalUnitLabel, field: "original_unit", render: (rowData) => <>{rowData.hasOwnProperty('original_unit') ? rowData.original_unit ? rowData.original_unit : '--' : '-'}</> },
                          { title: vocabulary.calculatedValue, field: 'calculated_value', render: (rowData) => { return rowData.calculated_value === undefined ? Number(rowData.original_value).toFixed(3) : Number(rowData.calculated_value).toFixed(3) }, cellStyle: rowData => { if (rowData && rowData.newValue) return { backgroundColor: '#FFEEEE' }; else return {}; } },
                          { title: vocabulary.calculatedUnitLabel, field: "calculated_value_unit", render: (rowData) => <>{rowData.hasOwnProperty('calculated_value_unit') ? rowData.calculated_value_unit ? rowData.calculated_value_unit : '--' : '-'}</>, cellStyle: rowData => { if (rowData && rowData.newValue) return { backgroundColor: '#FFEEEE' }; else return {}; } },
                          {
                            title: vocabulary.rangeLabel, align: 'center', field: " ", render: (rowData) => {
                              return <>
                                <div style={{ width: '150px', height: '100px' }}>
                                  <ReactSpeedometer
                                    fluidWidth={true}
                                    width={150}
                                    height={100}
                                    needleColor="red"
                                    startColor="#9ACD32"
                                    segments={5}
                                    endColor="#FF6347"
                                    textColor="grey"
                                    minValue={rowData.sepecifications ? getLimit(rowData.datatypeidentifier, JSON.parse(rowData.sepecifications), "rangeMin") : rowData.calculated_value !== undefined ? Number(rowData.calculated_value).toFixed(3) < 1 ? Number(rowData.calculated_value).toFixed(3) * 100 : Number(rowData.calculated_value).toFixed(3) : Number(rowData.original_value).toFixed(3) < 1 ? Number(rowData.original_value).toFixed(3) * 100 : Number(rowData.original_value).toFixed(3)}
                                    //TODO to brainstorme, dynamic segments...
                                    // customSegmentStops={[0, 500, 750, 900, 1000]}
                                    currentValueText={rowData.calculated_value !== undefined ? vocabulary.value + ' : ' + Number(rowData.calculated_value).toFixed(3).toString() : vocabulary.value + ' : ' + Number(rowData.original_value).toFixed(3).toString()}
                                    ringWidth={10}

                                    // END TODO
                                    maxValue={rowData.sepecifications ? getLimit(rowData.datatypeidentifier, JSON.parse(rowData.sepecifications), "rangeMax") : rowData.calculated_value !== undefined ? Number(rowData.calculated_value).toFixed(3) < 1 ? Number(rowData.calculated_value).toFixed(3) * 100 : Number(rowData.calculated_value).toFixed(3) : Number(rowData.original_value).toFixed(3) < 1 ? Number(rowData.original_value).toFixed(3) * 100 : Number(rowData.original_value).toFixed(3)}
                                    value={rowData.calculated_value !== undefined ? Number(rowData.calculated_value).toFixed(3) < 1 ? Number(rowData.calculated_value).toFixed(3) * 100 : Number(rowData.calculated_value).toFixed(3) : Number(rowData.original_value).toFixed(3) < 1 ? Number(rowData.original_value).toFixed(3) * 100 : Number(rowData.original_value).toFixed(3)}
                                  // value={20}
                                  // needleColor="steelblue"
                                  />
                                </div>
                              </>
                            }
                          },
                          { title: vocabulary.formula, field: "formula", render: (rowData) => { return rowData.formula ? rowData.formula.type !== null ? rowData.formula.name ? rowData.formula.name : JSON.parse(rowData.formula).name : vocabulary.defaultLabel : vocabulary.defaultLabel } }
                        ]}
                        options={{
                          pageSize: 20,
                          search: false,
                          pageSizeOptions: [10, 20, 40, 60, 80, 100],
                          emptyRowsWhenPaging: false,

                        }}

                      />
                    }
                  </>
                  : <StyledCircularProgress timeout={true} noDataMsg={vocabulary.noRealTimeDataToDisplayLabel} size={50} label={vocabulary.loading} />

                }

              </div>
              : <StyledEmptyDataLabel
                btnLabel={vocabulary.createDeployedDevice}
                handleFunction={() => navigateToDeployedDevices}
                label={vocabulary.noRealTimeDataLabel}
              />

          }

        </div>
      }

      {realTimeNodeArray.map((node, index) =>
        <Card style={{ marginBottom: 10 }} >
          <CardHeader
            title={node.name}
            subheader=""
          />
          <CardContent>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {node.id}
            </div>

          </CardContent>
        </Card>
      )}

      <div style={{
        opacity: (appLoading || realTimeActionArray.length === 0) ? 0.4 : 1,
        margin: 10, marginTop: 10, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', flexWrap: 'wrap'
      }}>
        {realTimeActionArray.map((node, index) =>
          <Card key={index} style={{ marginBottom: 20, paddingLeft: 15 }} >
            <CardHeader
              // title="Real Time Actuators"

              // subheader={stations.length > 0 && stations.find(o => o.identifier == node.id) ? stations.find(o => o.identifier == node.id).name : node.id}
              subheader={node.name}
              style={{ marginBottom: 40 }}
            />
            <div className="stopButton" >
              <div className="stopButton-btn">
                <Button disabled={eval(automaticMode)} color="secondary" variant="contained" onClick={() => {
                  mqttClient.publish("/command/" + userId + "/" + getwayID, JSON.stringify({ type: "action", data: { Destination: node.id, Message: "A:R*/false" } }))
                }} >Stop All</Button>
              </div>
              <div className="stopButton-btn">
                <Button disabled={eval(automaticMode)} color="primary" variant="contained" onClick={() => {
                  mqttClient.publish("/command/" + userId + "/" + getwayID, JSON.stringify({ type: "action", data: { Destination: node.id, Message: "A:R*/true" } }))
                }} >Start All</Button>
              </div>

            </div>
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'row', }}>
                {Object.keys(node.data).map((key, index) =>
                  <Actuator
                    sendCommand={(port, status) => {
                      mqttClient.publish("/command/" + userId + "/" + getwayID, JSON.stringify({ type: "action", data: { Destination: node.id, Message: "A:R" + port + "/" + status } }))
                    }} isActivated={node.data[key].Status} port={key} attachedEquipment={node.data[key].Attachment} key={index}
                    disabled={eval(automaticMode)}

                  />
                )}
              </div>
            </CardContent>
          </Card>
        )}
      </div>

    </>

  );
}

export default MonitoringPage
import React from "react";
import L from "leaflet";
import {
  Marker,
  Popup,
  LayersControl,
  FeatureGroup,
  Circle,
} from "react-leaflet";
import { vocabulary } from "../../Strings";
import { customMapIcons, defaultMarker } from "../../../globalsTools/utilities";


const areaTypes = ["site", "parcel"];

const AreasMapping = ({ areas }) => {



  return (

    areaTypes.map((type) => {
      const typeCount = areas.filter((area) => area.type === type).length;
      return (
        <LayersControl.Overlay  key={type} name={`${type.toUpperCase()} (${typeCount})`} checked>
          <FeatureGroup>
            {
              areas
                .filter((area) => area.type === type)
                .map((area) => {
                  const coordinates = JSON.parse(area.center);
                  const position = [coordinates.latitude, coordinates.longitude];
                  return (
                    <Marker
                      key={`marker-${area.id}`}
                      position={position}
                      icon={customMapIcons[area.type] || defaultMarker}
                    >
                      <Popup key={`popup-${area.id}`}>
                        <div>
                          <strong>{vocabulary.site}: </strong> {area.name}
                        </div>
                        <div>
                          <strong>{vocabulary.size}: </strong> {Number(area.size / 10000).toFixed(2) + ' ' + vocabulary.hectar}
                        </div>
                        {
                          area.user && <div>
                            <strong>{vocabulary.owner}: </strong> {area.user?.firstname + ' ' + area.user?.lastname}
                          </div>
                        }

                        {
                          area.deployed_devices && <div>
                            <strong>{vocabulary.deployedDevices}: </strong> {area.deployed_devices?.length}
                          </div>
                        }
                      </Popup>

                    </Marker>
                  );
                })}
          </FeatureGroup>
        </LayersControl.Overlay>
      )
    })

  );
};

export default AreasMapping;

/* eslint-disable no-unused-vars*/
/* eslint-disable no-useless-escape*/

import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { InputAdornment, IconButton } from '@material-ui/core/';
import { Visibility, VisibilityOff } from "@material-ui/icons/";

import farm from '../images/farm.jpg';
import { vocabulary } from './Strings';
import { useHistory } from "react-router-dom";
import SeabexLogo from "../ui/SeabexLogo";
import StyledInput from "../ui/forms/StyledInput";
import { StyledSubmitButton } from "../ui/forms";
import { useForm } from 'react-hook-form';
import StyledCard from "../ui/layout/StyledCard";
import { useSnackbar } from 'notistack';
import { newUserPassword } from "../globalsTools/network";


const ResetPassword = (props) => {

  // const dispatch = useDispatch()
  const form = useRef(null);
  const { register, handleSubmit, errors } = useForm()
  const { enqueueSnackbar } = useSnackbar();
  const appLoading = useSelector(state => state.app.appLoading)
  let history = useHistory();


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,

    });
  };
  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const operationCallBack = (r) => {  // callback function called after submit operation 
    if (r) {
      if (r.status === 200) {

        snackbar(vocabulary.emailCheckPasswordChanged, 'success')
        history.push('/')
      }
      else if (r.status === 403) {
        snackbar(vocabulary.checkAccessCode, 'info')
      }
      else {
        snackbar(vocabulary.errorOccurred + r.status, 'error')

      }
    }

  }
  const newPassword = () => {

    newUserPassword(
      {
        email: email,
        password: password,
        token: props.match.params.token,
        password_confirmation: password
      }, r => operationCallBack(r)

    )

  }
  const formStyle = {
    display: "flex",
    flexDirection: "column",
  }
  const onEnterPress = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(newPassword());

    }
  };

  return (
    <>


      <div style={{
        flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: "url(" + farm + ")", padding: 10, minHeight: '100vh',
        opacity: appLoading ? 0.4 : 1
      }} >


        <StyledCard height={400} direction="row" elevation={8}>

          <div style={{
            display: "flex",
            flexDirection: "column",
            position: 'relative',
            padding: 40
          }}>
            <SeabexLogo width={200} styles={{ marginBottom: 32 }} />


            <form
              onSubmit={handleSubmit(newPassword)}
              style={{ ...formStyle }}
              ref={form}
            >

              <StyledInput
                register={register}
                rules={{ required: true, validate: validateEmail }}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                name="email"
                label={vocabulary.email}
                onKeyDown={onEnterPress}
                autoComplete="email"
                errors={errors.email}
              />
              {errors.email &&
                <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                  {vocabulary.mailInvalid}
                </span>
              }


              <StyledInput
                register={register}
                rules={{ required: true }}
                name="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
                type={showPassword ? "text" : "password"}
                label={vocabulary.password}
                autoComplete="current-password"
                onKeyDown={onEnterPress}
                errors={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <StyledSubmitButton label={vocabulary.resetPassword} />
            </form>

          </div>
        </StyledCard>

      </div>
    </>
  );

}

export default ResetPassword;
import React from 'react'
import Impersonate from '../Impersonate'
import Cycle from '../../../Cycle/CycleList'


export default function Cycles() {
     

    return (
        <Impersonate>
            <Cycle />
        </Impersonate>
    )
}


/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Chip } from '@material-ui/core/';
import { vocabulary } from '../Strings';
import {
    ValidatorForm,
    //TextValidator, SelectValidator
} from 'react-material-ui-form-validator';
import { createScenarioThenLoad, deleteIrrigationPlanRules, updateScenarioThenLoad } from '../../actions';

import AlertDialog from '../Components/alertDialog'
import { Card, CardContent } from '@material-ui/core/';
import { CONSTS } from '../globalConsts';
import ActionTableHooks from './ActionTableHooks'
import ConditionTableHooks from './ConditionTableHooks'
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledSelect from "../../ui/forms/StyledSelect";
import PaperComponent from '../Admin/DraggableDialogFunction'
import { LogicalOperator } from './LogicalOperator';

var isExisty = function isExisty(value) {
    return value !== null && value !== undefined;
};

var _isEmpty = function _isEmpty(value) {
    if (value instanceof Array) {
        return value.length === 0;
    }
    return value === '' || !isExisty(value);
};

function matchRegexp(value, regexp) {
    var validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);
    return _isEmpty(value) || validationRegexp.test(value);
}


const ScenarioForm = (props) => {

    const dispatch = useDispatch()
    const form = useRef(null);

    const { register, handleSubmit, errors } = useForm()

    const [status, setStatus] = useState('Enabled')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
    const [Actions, setActions] = useState([])
    const [Conditions, setConditions] = useState([])
    const [expressionArray, setExpressionArray] = useState([])
    const [priority, setPriority] = useState('')


    useEffect(() => {
        if (props.isEditing === false) {
            setStatus('Enabled')
            setTitle('')
            setDescription('')
            setPriority('')
            setConditions([])
            setActions([])
            setExpressionArray([])

        }

        setIsEditing(props.isEditing)
        ValidatorForm.addValidationRule('expressionValidator', (value) => {
            return matchRegexp(value, /^[&|()]*$/);
        });
    }, [props.isEditing, props.open]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        if (props.item) {
            setStatus(props.item.status)
            setTitle(props.item.title)
            setDescription(props.item.description)
            setPriority(props.item.priority)
            setConditions(props.item.conditions ? JSON.parse(props.item.conditions) : null)
            setActions(props.item.actions ? JSON.parse(props.item.actions) : null)
            setExpressionArray(props.item.equation.split())

        }
    }, [props.item])


    const validForm = () => {
        if (Actions && Actions.length === 0 || !title || Conditions && Conditions.length === 0) {
            return true
        }
        else return false
    }

    const handleConfirmePopup = () => {

        if (isEditing === false) {
            dispatch(createScenarioThenLoad(
                {
                    title: title,
                    status: status,
                    description: description,
                    conditions: JSON.stringify(Conditions),
                    actions: JSON.stringify(Actions),
                    logicalEquation: expressionArray.join(""),
                    priority: parseInt(priority)

                }))
            props.onClose()
        }
        else {
            dispatch(updateScenarioThenLoad({
                id: props.item.id,
                title: title,
                status: status,
                description: description,
                conditions: JSON.stringify(Conditions),
                actions: JSON.stringify(Actions),
                logicalEquation: expressionArray.join(""),
                priority: parseInt(priority)


            }))

            props.onClose()
        }

    }

    // componentDidUpdate() {
    //     // custom rule will have name 'isPasswordMatch'
    //     // ValidatorForm.addValidationRule('idConditionValidator', (value) => {
    //     //     return matchRegexp(value, /^[0-9a-zA-Z]*$/);
    //     // });

    // }



    const handleCloseAlert = () => {
        setOpenConfirmationDialog(false)
    };

    return (

        <div>


            <Dialog
                open={props.open}
                onClose={props.onClose}
                fullWidth={true}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

                    {isEditing === false ?
                        `${vocabulary.add} ${vocabulary.scenarioLabel}` :
                        `${vocabulary.editLabel} ${vocabulary.scenarioLabel}`
                    }


                </DialogTitle>

                <form
                    ref={form}
                    onSubmit={handleSubmit(handleConfirmePopup)}
                    onError={errors => console.log(errors)}>
                    <DialogContent>


                        <StyledSelect
                            value={status}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.status}
                            onChange={(event) => {
                                setStatus(event.target.value)
                            }}
                            name='status'
                            label={vocabulary.status}
                            autoWidth
                            data={CONSTS.STATUS_TYPES}
                            valueField='value'
                            labelField='title'
                            styles={{ width: '100%' }}

                        />


                        <StyledInput
                            register={register}
                            rules={{ required: true }}
                            errors={errors.Title}
                            name="Title"
                            label={vocabulary.title}
                            value={title}
                            onChange={(event) => {
                                setTitle(event.target.value)
                            }}
                            type="text"
                            fullWidth
                        />

                        <StyledInput
                            register={register}
                            rules={{ required: true }}
                            errors={errors.description}
                            name="Description"
                            label={vocabulary.description}
                            value={description}
                            onChange={(event) => {
                                setDescription(event.target.value)
                            }}
                            type="text"
                            fullWidth
                        />

                        <StyledInput
                            register={register}
                            rules={{ required: true }}
                            errors={errors.priority}
                            name="priority"
                            label={vocabulary.priority}
                            value={priority}
                            onChange={(event) => {
                                setPriority(event.target.value)
                            }}
                            type="number"
                            fullWidth
                        />

                        <StyledSelect
                            value={""}
                            register={register}
                            // rules={{ required: true }}
                            errors={errors.status}
                            onChange={(event) => {
                                setExpressionArray([...expressionArray, event.target.value])
                            }}
                            name='logiqueOperator'
                            label={vocabulary.operatorLabel}
                            fullWidth
                            data={LogicalOperator.TYPES}
                            valueField='value'
                            labelField='title'
                            styles={{ width: '100%' }}



                        />

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}>
                            {expressionArray && expressionArray.map((value, index) => (
                                <Chip
                                    key={index} label={value}
                                    onDelete={() => {
                                        setExpressionArray(expressionArray.filter(function (element) {
                                            return element !== value
                                        }))

                                    }}
                                    color={(value === '||' || value === '&&' || value === '(' || value === ')') ? "primary" : "secondary"}
                                />
                            ))}

                        </div>

                        <StyledInput
                            register={register}
                            // rules={{ required: true }}
                            errors={errors.description}
                            name="expressionArray"
                            label={vocabulary.equation}
                            value={expressionArray.join("")}
                            type="text"
                            fullWidth
                            disabled={true}
                        />


                        <div>


                            <Card>

                                <CardContent>


                                    <ConditionTableHooks
                                        conditionArray={Conditions ? Conditions : []}
                                        addCondition={(ConditionsItem) => {
                                            setConditions([...Conditions, ConditionsItem])
                                        }}
                                        isEditing={props.isEditing}
                                        addConditionToEquation={(ConditionsItem) => {
                                            setExpressionArray([...expressionArray, ConditionsItem])
                                        }}

                                    />
                                </CardContent>
                                {validForm() ? <div className="warning"><p>{vocabulary.warningCondition}</p></div> : ''}


                            </Card>

                            <Card >

                                <CardContent>

                                    <ActionTableHooks
                                        actionArray={Actions ? Actions : []}
                                        isEditing={props.isEditing}
                                        addAction={(ActionsItem) => {
                                            setActions([...Actions, ActionsItem])
                                        }}
                                    />
                                </CardContent>
                                {validForm() ? <div className="warning"><p>{vocabulary.warningAction}</p></div> : ''}


                            </Card>





                        </div>
                        {/* : null} */}
                    </DialogContent>
                    <DialogActions style={{ marginRight: '8px' }}>
                        <Button
                            onClick={() => {

                                props.onClose()
                            }}
                            variant="contained"
                            color="secondary">
                            {vocabulary.cancel}
                        </Button>

                        {/* calling this.props.onClose will trigger event  componentWillReceiveProps !!!!!!!!!!!!!!*/}
                        <Button
                            type="submit"
                            onClick={() => handleConfirmePopup}
                            disabled={validForm()}
                            color="primary"
                            variant="contained"

                        >
                            {vocabulary.save}
                        </Button>

                    </DialogActions>
                </form>


                <AlertDialog
                    objectType="All Irrigation Plan Rules"
                    operation={"Delete"}
                    open={openConfirmationDialog}
                    onClose={() => {
                        handleCloseAlert()
                    }}
                    confirmeDelete={() => {
                        if (props.item) {
                            dispatch(deleteIrrigationPlanRules(props.item.id))
                        }

                        handleCloseAlert()
                    }}
                    confirmUpdate={() => {
                        handleCloseAlert()
                    }}
                    cancel={() => {
                        handleCloseAlert()
                    }}
                />

            </Dialog>


        </div>
    )

}

export default ScenarioForm;

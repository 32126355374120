/* eslint-disable no-sequences */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { vocabulary } from '../Strings';
import { setCurrentPage } from '../../actions';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { GetGroupeByUserId, GetAllGroupe, FollowGroup, UnFollowGroup, DeleteGroup } from '../../globalsTools/network'
import AddGroupeForm from './AddGroupForm'
import AlertDialog from "../Components/alertDialog";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const GroupsList = () => {

    const dispatch = useDispatch()
    const userType = useSelector(state => state.app.userrights)
    const { enqueueSnackbar } = useSnackbar();
    const userId = useSelector(state => state.app.userid)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [groupeList, setGrpoupeList] = useState(null)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [showAllGroup, setShowAllGroup] = useState(false)


    useEffect(() => {
        GetGroupeByUserId(setGrpoupeList)
        dispatch(setCurrentPage(vocabulary.groupeManagment))
        document.title = vocabulary.groupeManagment

    }, [dispatch]); // N’exécute l’effet que a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.groupeManagment))
        document.title = vocabulary.groupeManagment

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const changeModalStatus = (newStatus) => {

        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const HandleShowAllGroups = () => {
        setShowAllGroup(true)
        GetAllGroupe(setGrpoupeList)
    }
    const HandleShowMyGroups = () => {

        setShowAllGroup(false)
        GetGroupeByUserId(setGrpoupeList)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.title + ' ' + vocabulary.deleteMsg, 'success')
                userType === ('Farmer' || 'Institution') ? GetGroupeByUserId(setGrpoupeList) :
                    GetAllGroupe(setGrpoupeList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteGroup = (item) => {
        DeleteGroup(item.id
            , r => operationCallBack(r)
        )
    }
    const SubscribeGroup = (row) => {
        FollowGroup(
            {
                user_id: userId,
                user_group_id: row.id

            }
            , function (r) {
                if (r) {
                    if (r.status === 201) {
                        snackbar(vocabulary.subscribeSucces + ' ' + row.title, 'success')
                        GetGroupeByUserId(setGrpoupeList)
                        setShowAllGroup(false)

                    }
                    else if (r.status === 204) {
                        snackbar(vocabulary.alreadySubscribe + ' ' + row.title, 'success')
                        GetGroupeByUserId(setGrpoupeList)
                        setShowAllGroup(false)
                    }
                    else {
                        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                    }
                }
            }
        )

    }


    const UnsubscribeGroup = (row) => {
        UnFollowGroup({
            user_id: userId,
            user_group_id: row.id

        }, function (r) {
            if (r) {
                if (r.status === 200) {
                    snackbar(vocabulary.leftGroup + ' ' + row.title, 'success')
                    GetGroupeByUserId(setGrpoupeList)

                } else {
                    snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                }
            }
        }
        )

    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {
                groupeList ?
                    <StyledCard>

                        <StyledMaterialTable
                            title={showAllGroup === false ? vocabulary.showMyGroup : vocabulary.showAllGroup}
                            data={showAllGroup === false ? groupeList.user_groups : groupeList.data}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip:vocabulary.search } }}

                            columns={[
                                { title: vocabulary.titleLabel, field: 'user_groups', render: rowData => <>{rowData.title}</> },
                                { title: vocabulary.description, field: 'user_groups', render: rowData => <>{rowData.description}</> },
                                { title: vocabulary.type, field: 'user_groups', render: rowData => <>{rowData.type}</> },

                            ]}
                            actions={[

                                row => ({
                                    icon: 'edit_outline',
                                    onClick: (event, row) => onEdit(row),
                                    hidden: showAllGroup === true ? true : false,
                                    tooltip: userId !== row.user_id ? vocabulary.disableEdit : vocabulary.editLabel,
                                    disabled: userId !== row.user_id

                                }),
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: userId !== row.user_id ? vocabulary.disableDelete : vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),
                                    hidden: showAllGroup === true ? true : false,

                                    disabled: userId !== row.user_id
                                }),
                                row => ({
                                    icon: () => <PersonAddIcon />,
                                    tooltip: vocabulary.subscribe,
                                    onClick: (event, row) => { SubscribeGroup(row); },
                                    hidden: showAllGroup === false ? true : false,
                                    // disabled: (groupeList.data && userId === row && row.users.map(el => el.pivot.user_id)) ? true : false

                                }),
                                {
                                    icon: () => <PersonAddDisabledIcon />,
                                    tooltip: vocabulary.leaveGroup,
                                    onClick: (event, row) => UnsubscribeGroup(row),
                                    hidden: showAllGroup === false ? false : true
                                },

                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpen()
                                },
                                {
                                    icon: () => showAllGroup === false ? <ViewComfyIcon /> : <ViewHeadlineIcon />,
                                    tooltip: showAllGroup === false ? vocabulary.showAllGroup : vocabulary.showMyGroup,
                                    onClick: (event, row) => showAllGroup === false ? HandleShowAllGroups() : HandleShowMyGroups(),
                                    isFreeAction: true,

                                },

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true

                            }}
                        />

                        {alertConfirmDelete === true &&
                            <AlertDialog
                                operation={vocabulary.delete}
                                open={alertConfirmDelete}
                                deletedItemName={item.title}
                                onClose={() => {
                                    closeAlertConfirmDelete();
                                }}
                                confirmeDelete={() => {
                                    if (item) {
                                        handleDeleteGroup(item)
                                    }
                                   
                                    closeAlertConfirmDelete();
                                }}

                                cancel={() => {
                                    closeAlertConfirmDelete();
                                }}
                            />}
                        <AddGroupeForm
                            open={isEditing === false ? openAdd : openEdit}
                            handleClose={() => {
                                return (
                                    isEditing === true ?
                                        setOpenEdit(false)
                                        : setOpenAdd(false)

                                )
                            }}
                            selectedItem={isEditing === true ? item : null}
                            isEditing={isEditing}
                            refetchFunc={() => userType === ('Farmer' || 'Institution') ? GetGroupeByUserId(setGrpoupeList) :
                                GetAllGroupe(setGrpoupeList)}
                        />
                    </StyledCard>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
            }
        </ModalContext.Provider>


    )
}
export default GroupsList
import {CardContent, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Area,  CartesianGrid, ComposedChart, Legend, Line, ReferenceArea,  ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { logger } from '../../../globalsTools/utilities';
import { StyledCard } from '../../../ui/layout';
import { vocabulary } from '../../Strings'

export const IndexEvolutionChart = ({data, index, min, max, backgroundStyle}) => {

    const RADIAL_DOT_SIZE = 3;
    const DOT_SHAPE = { strokeWidth: 2, r: 2 };
    const DOT_SHAPE_MINMAX = { strokeWidth: 1, r: 1 };

    const [minValue, setMinValue] = useState(null)
    const [maxValue, setMaxValue] = useState(null)
    const [backgroundValue, setBackgroundValue] = useState(null)

    useEffect(() => {
      if (min!=null && max!=null && backgroundStyle!=null) {
        setMinValue(min)
        setMaxValue(max)
        setBackgroundValue(backgroundStyle)
      }
    }, [min, max, backgroundStyle])

    const diffArray = (x) => [Number(x.min).toFixed(3), Number(x.max).toFixed(3)];

    const TooltipComponent = ({ payload, label, active }) => {
        if (active && payload) {
            return (
                <StyledCard>
                    <CardContent>
                        <Grid container direction='column'>
                            <Grid item><Typography align='left'>{'Min/Max: ' + payload[0].value }</Typography></Grid>
                            <Grid item><Typography align='left'>{vocabulary.meanLabel +' : ' + payload[1].value }</Typography></Grid>
                            <Grid item><Typography align='left'>{vocabulary.date +' : ' + label }</Typography></Grid>
                        </Grid>
                    </CardContent>
                </StyledCard>);
        
        } else {
          return ( 
            <StyledCard>
                <CardContent>
                    No Value received!
                </CardContent>
            </StyledCard>
            )
        }
    }

    useEffect(() => {
      logger("IndexEvolutionChart ->", minValue, maxValue, backgroundValue)
    }, [minValue, maxValue, backgroundValue])


    return (
        <div style={{ width: '100%', height: '350px' }}>
        <ResponsiveContainer>
          {data &&
            <ComposedChart
              width={500}
              height={500}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
                <defs>
                    <linearGradient id="colorDiffGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.2}/>
                        <stop offset="50%" stopColor="#FFEB3B" stopOpacity={0}/>
                        <stop offset="95%" stopColor="#c62828" stopOpacity={0.2}/>
                    </linearGradient>
                    <linearGradient id="gradientRedGreen" x1="0" y1="1" x2="0" y2="0">
                        <stop offset="8%" stopColor="rgb(165, 0, 38)" stopOpacity={0.2}/>
                        <stop offset="16%" stopColor="rgb(211, 50, 43)" stopOpacity={0.2}/>
                        <stop offset="24%" stopColor="rgb(241, 109, 67)" stopOpacity={0.2}/>
                        <stop offset="32%" stopColor="rgb(252, 171, 99)" stopOpacity={0.2}/>
                        <stop offset="40%" stopColor="rgb(254, 220, 140)" stopOpacity={0.2}/>
                        <stop offset="48%" stopColor="rgb(249, 247, 174)" stopOpacity={0.2}/>
                        <stop offset="56%" stopColor="rgb(215, 238, 142)" stopOpacity={0.2}/>
                        <stop offset="64%" stopColor="rgb(164, 216, 111)" stopOpacity={0.2}/>
                        <stop offset="72%" stopColor="rgb(100, 188, 97)" stopOpacity={0.2}/>
                        <stop offset="80%" stopColor="rgb(35, 150, 79)" stopOpacity={0.2}/>
                        <stop offset="88%" stopColor="rgb(35, 150, 79)" stopOpacity={0.2}/>
                    </linearGradient>
                    <linearGradient id="gradientRedBlue" x1="0" y1="1" x2="0" y2="0">
                        <stop offset="10%" stopColor="rgb(211, 50, 43)" stopOpacity={0.2}/>
                        <stop offset="20%" stopColor="rgb(241, 109, 67)" stopOpacity={0.2}/>
                        <stop offset="30%" stopColor="rgb(252, 171, 100)" stopOpacity={0.2}/>
                        <stop offset="40%" stopColor="rgb(254, 220, 144)" stopOpacity={0.2}/>
                        <stop offset="50%" stopColor="rgb(250, 248, 192)" stopOpacity={0.2}/>
                        <stop offset="60%" stopColor="rgb(220, 241, 236)" stopOpacity={0.2}/>
                        <stop offset="70%" stopColor="rgb(171, 214, 236)" stopOpacity={0.2}/>
                        <stop offset="80%" stopColor="rgb(118, 171, 208)" stopOpacity={0.2}/>
                        <stop offset="90%" stopColor="rgb(74, 116, 180)" stopOpacity={0.2}/>
                    </linearGradient>
                </defs>
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='capture_date' />
  
              <Tooltip content={TooltipComponent} />
              <Legend />
  
              <YAxis
                type='number'
                domain={[dataMin => { logger(Math.min(dataMin, min)); return Math.min(dataMin, min)}, dataMax => (Math.max(dataMax, max))]}
                width={100}
                // label={{ value: vocabulary.temperature + ' ' + " (°C) ", angle: -90, position: 'insideLeft' }}
                />
  
              {/* <ReferenceArea x1='2021-04-15' x2='2021-04-03' /> // TO MAKE IT DYNAMIC */} 
  
              <Area
                strokeWidth={0}
                name={'Min/Max: ' + index}
                type='monotone'
                dataKey={diffArray}
                fillOpacity={0.3} 
                fill="#eeeeee" 
                />
              <Line name={index} dataKey={(x)=>Number(x.mean).toFixed(3)} 
                activeDot={{ r: RADIAL_DOT_SIZE }}
                type='monotone'
                dot={DOT_SHAPE}
                strokeWidth={4}
                stroke='#004D40' />
              <Line dataKey="min" 
                activeDot={false}
                type='monotone'
                dot={DOT_SHAPE_MINMAX}
                strokeWidth={0.5}
                stroke='#c62828' />
              <Line dataKey="max" 
                activeDot={false}
                dot={DOT_SHAPE_MINMAX}
                type='monotone'
                strokeWidth={0.5}
                stroke='#4CAF50' />
              
              <ReferenceArea y1={minValue} y2={maxValue} fill={`url(#${backgroundValue})`} />
  
            </ComposedChart>}
        </ResponsiveContainer>
      </div>
    )
}

import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core/';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import { DateRange, Movie, MyLocation, ViewList, PieChart, AssignmentInd } from '@material-ui/icons/';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { makeid } from '../../globalsTools/utilities';
import { REACT_APP_ENV_VERSION } from '../../config';

const NestedLinkItem = ({ inclusionArray, exclusionArray, routeTo, icon, open, nestedTableRoute, handleClick, vocabularyText, onClick, platforms, disabled=false }) => {

    const userType = useSelector(state => state.app.userrights)


    const currentPage = useSelector(state => state.app.currentPage)

    if (exclusionArray && exclusionArray.includes(userType)) {
        return (null);
    }

    
  if (platforms && !platforms.includes(REACT_APP_ENV_VERSION))
  {
    return (null);
  }


    if ((inclusionArray == null && exclusionArray == null) || (inclusionArray && inclusionArray.includes(userType))) {
        return (
            <NavLink key={makeid()} to={(routeTo && !disabled) ? routeTo : '#'} onClick={(onClick && !disabled) ? onClick : undefined}>
                <ListItem button onClick={handleClick} disabled={disabled} 
                    selected={currentPage === vocabularyText}
                >
                    <ListItemIcon>
                        {icon}

                    </ListItemIcon>
                    <ListItemText primary={vocabularyText} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" >
                        {nestedTableRoute.map((item, index) => {
                            return <NavLink key={index+"-"+makeid()} to={item.NestedRouteTo ? item.NestedRouteTo : '#'} onClick={item.NestedOnClick ? item.NestedOnClick : undefined}>
                                <ListItem button style={{ 'paddingLeft': '20px' }} disabled={disabled | item.disabled}
                                    selected={currentPage === item.NestedVocabularyText}

                                >
                                    <ListItemIcon>
                                        {item.NestedIcon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.NestedVocabularyText} />
                                </ListItem>
                            </NavLink>
                        })

                        }


                    </List>
                </Collapse>

            </NavLink>
        )
    } else {
        return (null)
    }

}

export default NestedLinkItem
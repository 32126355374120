import React from 'react';

import { Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';
import { vocabulary } from '../Strings';

const AlertDialog = ({ onClose, cancel, confirmAjout, open, isActivated, cron1Description, cron2Description }) => {

  const innerClose = () => {
    onClose();
  };

  const innerCancel = () => {
    cancel()
    innerClose()
  }

  const innerConfirm = () => {
    confirmAjout()
    innerClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={innerClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{vocabulary.confirmationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {vocabulary.confirmationLabel} {isActivated ? vocabulary.open : vocabulary.close} {cron1Description.name_en} {vocabulary.andLabel} {!isActivated ? vocabulary.open : vocabulary.close} {cron2Description.name_en}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button variant="contained" onClick={innerCancel} color="secondary">
            {vocabulary.cancelBtn}
          </Button>
          <Button variant="contained" onClick={innerConfirm} color="primary" autoFocus>
            {vocabulary.confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default AlertDialog;
/* eslint-disable eqeqeq*/
/* eslint-disable  no-useless-concat */
/* eslint-disable no-unused-vars*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardContent, Chip, Grid, Typography, } from '@material-ui/core/';

import 'bootstrap/dist/css/bootstrap.css';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
// import { withStyles } from '@material-ui/core/styles';

import { setCurrentPage } from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';
import AddShoppingCartIcon from '@material-ui/icons/Cached';

import Button from '@material-ui/core/Button';
import { GetAllDataTypes, GetEquipmentsByDevice, GetSourcesList, GetStationsList, SaveSettings } from '../../globalsTools/network';

import SearchIcon from '@material-ui/icons/Search';
import { StyledCard, StyledModal } from '../../ui/layout';
import MultiLogsTable from './MultiLogsTable';
import StyledAutoComplete from '../../ui/forms/StyledAutoComplete';
import AreaFilteringInputs from '../Components/AreaFilteringInputs';
import { Add } from '@material-ui/icons';
import { IntervalUnit } from '../globalConsts'
import { StyledSelect } from "../../ui/forms";
import StyledInput from "../../ui/forms/StyledInput";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress'
import { Done } from '@material-ui/icons';
import { stylesThemed } from '../globalStyle';
import MultilogSettings from './MultilogSettings';

const LogsList = (props) => {
  const dispatch = useDispatch();

  // const { register, handleSubmit, errors } = useForm()
  const currentLanguage = useSelector((state) => state._globalState.preferred_language);

  const [dateFrom, setDateFrom] = useState(
    moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYY-MM-DD HH:mm'
    )
  );
  const [dateTo, setDateTo] = useState(
    moment(new Date()).format('YYYY-MM-DD HH:mm')
  );

  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedAreaName, setSelectedAreaName] = useState(null);
  const [dataTypeList, setDataTypeList] = useState(null);
  const [sourceListByAreaId, setSourceListByAreaId] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [selectedPort, setSelectedPort] = useState(null);
  const [addDataType, setAddDataType] = useState(false);
  const [reload, setReload] = useState(null);
  const [reset, setReset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deployedDeviceByAreaId, setDeployedDeviceByAreaId] = useState(null);
  const [selectedDeployedDevicesId, setSelectedDeployedDevicesId] = useState(null);
  const [selectedDeployedDevices, setSelectedDeployedDevices] = useState(null);
  const [equipmentByDeviceId, setEquipmentByDeviceId] = useState(null);
  // const [indexId, setIndexId] = useState(0);
  const [equipmentListByDeviceId, setEquipmentListByDeviceId] = useState(null);
  const [equipmentListByDevice, setEquipmentListByDevice] = useState(null);
  const [selectedEquipmentName, setSelectedEquipmentName] = useState(null);
  const [selectedDatatypeSources, setSelectedDatatypeSources] = useState([]);
  const [intervalUnit, setIntervalUnit] = useState('');
  const [inetrvalNumber, setIntervalNumber] = useState(0);
  const [aggregator, setAggregator] = useState('N/A');
  const [multilogSettingsUpdated, setMultilogSettingsUpdated] = useState(null)



  const resetForm = () => {
    setSelectedArea(null);
    setDateFrom(
      moment(new Date().setDate(new Date().getDate() - 10)).format(
        'YYYY-MM-DDTHH:mm'
      )
    );
    setDateTo(moment(new Date()).format('YYYY-MM-DDTHH:mm'));

    setDataTypeList(null);
    setSourceListByAreaId(null);
    setSelectedDataType(null);
    setSelectedDataSource(null);
    setSelectedDatatypeSources([]);
    setReload(null)
    setReset(moment())
    setIntervalNumber(0)
    setIntervalUnit(null)
    setEquipmentListByDeviceId(null)
    setSelectedEquipmentName(null)
  };

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.multiLogs));
    // GetAreasList(setAreasList);
    // GetGatewaysList(setGetwayArray)
    document.title = vocabulary.multiLogs;
  }, [dispatch]); // N’exécute l’effet que si currentLanguage a changé

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.multiLogs));
    document.title = vocabulary.multiLogs;

  }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  const handleDateChange = (key) => (date) => {
    if (key === 'dateFrom') {
      setDateFrom(date);
    } else {
      setDateTo(date);
    }
  };

  useEffect(() => {
    if (selectedArea != null) {
      GetAllDataTypes(setDataTypeList);
      GetSourcesList(setSourceListByAreaId);
      // GetStationsList();
    }
  }, [selectedArea]);


  // useEffect(() => {
  //   if (dataTypeList && dataTypeList.length > 0) {
  //     setAddDataType(true);
  //   }
  // }, [dataTypeList]);
  useEffect(() => {
    if (addDataType === false) {
      setSelectedArea(null)
      // setIntervalUnit(null)
      // setIntervalNumber(0)
      setDataTypeList(null);
      setSourceListByAreaId(null);
    }

  }, [addDataType]); // N’exécute l’effet que si count a changé

  const findLogs = () => {
    setReload(moment())
  }

  const deleteSelectedItem = (index) => {
    selectedDatatypeSources.splice(index, 1);
    setSelectedDatatypeSources([...selectedDatatypeSources]);
  };

  useEffect(() => {
    if (selectedDeployedDevices) {
      let equipments = selectedDeployedDevices.area_deployed_device_equipment
      equipments = Array.isArray(equipments) ? equipments.filter(assignedEquipment => assignedEquipment.equipment_id != null) : null
      equipments = equipments.map(equipmentObject => { let newEquipmentObject = equipmentObject.equipment; newEquipmentObject.port = equipmentObject.port; return newEquipmentObject })
      setEquipmentListByDevice(equipments)
      setEquipmentListByDeviceId(null)
    } else {
      setEquipmentListByDevice(null)
      setEquipmentListByDeviceId(null)
    }

  }, [selectedDeployedDevices])

  useEffect(() => {
    if (selectedDataSource && selectedDataSource.id !== 'SEABEX_DEVICES') {
      setSelectedDeployedDevices(null)
      setSelectedDeployedDevicesId(null)
    }
  }, [selectedDataSource])

  const classes = stylesThemed();

  const setSelection = (item, doSearch) => {
    setSelectedDatatypeSources((prevSelection) => [item, ...prevSelection]);

    if (doSearch) {
      findLogs()
    }

  }

  const handleAddSelection = () => {
    if (selectedDataType && selectedDataSource) {
      const selectedData = {
        areaId: selectedArea && selectedArea,
        areaName: selectedAreaName && selectedAreaName,
        dataTypeId: selectedDataType.id,
        dataTypeLabel: JSON.parse(selectedDataType.name).name_ar
          ? JSON.parse(selectedDataType.name)['name_' + currentLanguage]
          : JSON.parse(selectedDataType.name).name_fr,
        dataTypeIdentifier: selectedDataType.identifier,
        dataSourceId: selectedDataSource.id,
        equipmentId:
          selectedDataSource && selectedDataSource.id === 'SEABEX_DEVICES'
            ? equipmentByDeviceId
            : null,
        port:
          selectedPort && selectedDataSource.id === 'SEABEX_DEVICES'
            ? selectedPort
            : null,
        equipmentName:
          selectedDataSource && selectedDataSource.id === 'SEABEX_DEVICES'
            ? selectedEquipmentName
            : null,
        aggregator: aggregator,
      };

      setSelectedDatatypeSources((prevSelection) => [selectedData, ...prevSelection]);
    }
  };

  return (
    <div
      style={{
        padding: 10,
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      {
        <>
          <StyledCard
            styleProp={{
              width: '96%',
              padding: 30,
              paddingRight: 50,
              marginTop: 15,
            }}
          >
            <CardContent>
              <form >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 35,
                    alignItems: 'center',
                  }}
                >
                  <DateTimePicker
                    value={dateFrom}
                    onChange={handleDateChange('dateFrom')}
                    label={vocabulary.startDate}
                    maxDate={moment()}
                    ampm={false}
                    autoOk={true}
                    disableFuture={true}

                  />
                  <DateTimePicker
                    value={dateTo}
                    onChange={handleDateChange('dateTo')}
                    label={vocabulary.endDate}
                    minDate={dateFrom}
                    maxDate={moment()}
                    ampm={false}
                    autoOk={true}

                  />
                </div>

                <Grid
                  container
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    flexWrap: 'no-wrap',
                    justifyContent: 'space-around',
                    // alignItems: 'center',
                  }}
                  spacing={2}
                >
                  <div style={{ display: 'flex' }}>
                    {
                      <StyledSelect
                        value={intervalUnit}
                        onChange={(event) => {
                          setIntervalUnit(event.target.value)

                        }}
                        name='intervalUnit'
                        label={vocabulary.intervalUnitLabel}
                        autoWidth
                        data={IntervalUnit}
                        valueField='id'
                        labelField='name'
                      // emptyDataMsg={vocabulary.noParcelCreatedLabel}
                      />
                    }
                    {intervalUnit &&
                      <StyledInput
                        name={'intervalNumber'}
                        label={vocabulary.intervalNumberLabel}
                        value={inetrvalNumber}
                        onChange={(event) => {
                          setIntervalNumber(event.target.value)
                        }}
                        // register={register}
                        // rules={{ required: true }}
                        type="number"
                        // min='0'
                        InputProps={{ inputProps: intervalUnit == 'month' ? { min: 0, max: 12 } : intervalUnit == 'day' ? { min: 0, max: 31 } : intervalUnit == 'hour' ? { min: 0, max: 24 } : { min: 0, max: 60 } }}
                      // errors={errors.intervalNumber}
                      />}
                  </div>


                  <Grid
                    item
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >



                    <Grid item>
                      <Typography>{vocabulary.dataTypesDisplayed} &nbsp;</Typography>
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                      {selectedDatatypeSources &&
                        selectedDatatypeSources.length > 0 ? (
                        selectedDatatypeSources.map((item, index) => (
                          <Chip
                            key={index}
                            label={
                              item.areaName + ': ' + item.dataTypeLabel + ' (' + item.dataSourceId + ')'
                              + (item.equipmentName ? ' ' + item.equipmentName : '')
                              + (item.port ? ' ' + item.port : '')
                              + (item.aggregator && item.aggregator != 'N/A' ? ' ' + item.aggregator : '')
                            }
                            onDelete={() => deleteSelectedItem(index)}
                          />
                        ))
                      ) : (
                        <Typography>{'( ' + vocabulary.noDataTypeSelectedYetLabel + ' )'}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        onClick={() => {
                          setAddDataType(true);
                        }}
                      >
                        <Add /> &nbsp; {vocabulary.addNewDataTypeLabel}
                      </Button>

                    </Grid>

                  </Grid>
                </Grid>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  onClick={() => findLogs()}
                >
                  <SearchIcon /> &nbsp;{vocabulary.findLogs}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  onClick={() => resetForm()}
                >
                  <AddShoppingCartIcon /> &nbsp; {vocabulary.newSearch}
                </Button>
              </form>
            </CardContent>
          </StyledCard>

          <MultilogSettings setSelection={setSelection} updated={multilogSettingsUpdated} />

          {reload !== null && (
            <MultiLogsTable
              reload={reload}
              selectedDataSources={selectedDatatypeSources}
              selectedAreaId={selectedArea && selectedArea}
              dateFrom={moment(dateFrom).format('YYYY-MM-DD HH:mm:ss')}
              dateTo={moment(dateTo).format('YYYY-MM-DD HH:mm:ss')}
              intervalUnit={intervalUnit}
              inetrvalNumber={inetrvalNumber}
            />
          )}
        </>
      }



      {addDataType && (
        <StyledModal
          title={vocabulary.addDataType}
          open={addDataType}
          closeFn={() => setAddDataType(false)}
          width={800}
          modalContrainerStyles={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", alignItems: 'center' }}
        >
          {
            //sourceListByAreaId && dataTypeList ?
            (
              <>
                <AreaFilteringInputs
                  reset={reset}
                  initialAreaId={''}
                  handleChangeFn={setSelectedArea}
                  selectedAreaName={setSelectedAreaName}
                  showdetails={true}
                  setLoading={setLoading}
                  deployedDeviceByAreaList={setDeployedDeviceByAreaId}

                />
                {selectedArea &&
                  <div style={{ width: '100%' }}>
                    {sourceListByAreaId && dataTypeList ?
                      <>
                        {
                          dataTypeList && (
                            <StyledAutoComplete         // DATA TYPES
                              // styles={{ width: '100%' }}
                              loading={true}
                              data={dataTypeList && dataTypeList}
                              optionLabel={(option) => JSON.parse(option.name).name_ar ? JSON.parse(option.name)['name_' + currentLanguage] : JSON.parse(option.name).name_fr}
                              onChange={(event, newValue) => {

                                // setIndexId(indexId + 1)
                                setSelectedDataType(newValue !== null ? newValue : null);
                              }}
                              label={vocabulary.datatype}
                            />
                          )}
                        {sourceListByAreaId && (
                          <StyledAutoComplete         // DATA SOURCES
                            // styles={{ width: '100%' }}
                            loading={true}
                            data={sourceListByAreaId && sourceListByAreaId}
                            optionLabel={(option) =>
                              option.id !== null ? option.id : 'null'
                            }
                            onChange={(event, newValue) => {


                              setSelectedDataSource(newValue !== null ? newValue : null);
                            }}
                            label={vocabulary.sourceLabel}
                          />
                        )}
                        {(selectedDataSource && selectedDataSource.id == 'SEABEX_DEVICES') && (
                          <StyledAutoComplete         // DEPLOYED DEVICES 
                            // styles={{ width: '100%' }}
                            loading={true}
                            data={deployedDeviceByAreaId}
                            optionLabel={(option) =>
                              option.name !== null ? option.name + (option.seabexmodel && option.seabexmodel.seriel_number ? " (" + option.seabexmodel.seriel_number + ")" : "") : 'N/A'
                            }
                            onChange={(event, newValue) => {
                              setSelectedDeployedDevices(newValue !== null ? newValue : null);
                              setSelectedDeployedDevicesId(newValue !== null ? newValue.id : null);
                            }}
                            label={vocabulary.deployedDevices}
                          />
                        )}
                        {(selectedDeployedDevicesId && equipmentListByDevice) && (
                          <StyledAutoComplete         // EQUIPMENTS + PORT
                            // styles={{ width: '100%' }}
                            loading={true}

                            data={equipmentListByDevice}
                            optionLabel={(option) =>
                              option.port + " - " + (option.name !== null ? JSON.parse(option.name).name_fr : 'null')
                            }
                            onChange={(event, newValue) => {
                              setSelectedPort(newValue.port ?? null)
                              setEquipmentByDeviceId(newValue.id ?? null);
                              setSelectedEquipmentName(JSON.parse(newValue.name).name_fr ?? null)

                              // ADD SELECT PORT
                            }}
                            label={vocabulary.equipmentLabel}
                          />

                        )}

                        <StyledSelect
                          value={aggregator ?? ''}
                          onChange={(event) => setAggregator(event.target.value)}
                          name='Aggregator'
                          label='Aggregator'
                          data={[
                            { agg: 'N/A', label: vocabulary.defaultLabel },
                            { agg: 'MIN', label: "MIN" },
                            { agg: 'MAX', label: "MAX" },
                            { agg: 'AVG', label: "AVG" }
                          ]}

                          valueField='agg'
                          labelField='label'
                        />

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={handleAddSelection}
                            startIcon={<Add />}> {vocabulary.addSelection}
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              SaveSettings('multi-logs', selectedDatatypeSources)
                              setMultilogSettingsUpdated(true)
                            }}
                            startIcon={<Done />}
                            disabled={selectedDatatypeSources.length === 0 ? true : false}
                          >

                            {vocabulary.saveSelectionLabel}
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => { setAddDataType(false) }}
                            startIcon={<Done />}
                            disabled={selectedDatatypeSources.length === 0 ? true : false}
                          >

                            {vocabulary.confirmSelectionLabel}
                          </Button>
                        </div>

                      </>
                      : <StyledCircularProgress size={50} height={'250px'} label={vocabulary.loading} />
                      //  <Typography>{vocabulary.loading}</Typography>
                    }
                  </div>
                }


              </>
            )

          }
        </StyledModal>
      )}


    </div>
  );
};

// LogsList.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default LogsList;

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { GetPartnerDevices } from '../../../../../globalsTools/network';
import { Button, Grid, Typography } from '@material-ui/core';
import { vocabulary } from '../../../../Strings';
import StyledAutoComplete from '../../../../../ui/forms/StyledAutoComplete';
import AreaSelect from '../../../../Sites/Components/AreaSelect';
import { Refresh } from '@material-ui/icons';
import { useParams } from 'react-router-dom';



const SencropConfigureForm = forwardRef(({ currentItem, partnerId, isEditing }, ref) => {

    const [data, setData] = useState({});
    const [areas, setAreas] = useState([]);
    const [device, setDevice] = useState()
    const [isLoading, setIsLoading] = useState(false)
    let { id } = useParams();
    useEffect(() => {


        if (isEditing && currentItem) {
            setAreas(currentItem.areas)
            setDevice({
                partner_id: partnerId,
                device_id: currentItem.device_id,
                device_name: currentItem.device_name
            })
        }
        if (!isEditing) {
            setIsLoading(true)
            loadDevices()
        }

    }, [isEditing, currentItem])

    const loadDevices = () => {
        GetPartnerDevices(partnerId, data => {
            setIsLoading(false)
            setData(data)
        });
    }


    useImperativeHandle(ref, () => ({
        getData: () => ({
            device,
            areas: areas
        }),
    }));


    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={12}>
                {
                    !isLoading && !isEditing && (
                        (data)
                            ? <StyledAutoComplete
                                data={(data) ? data : []}
                                optionLabel={(option) => option?.identification ?? ''}
                                onChange={(_, newValue) => {
                                    setDevice({
                                        partner_id: partnerId,
                                        device_id: String(newValue?.identification),
                                        device_name: newValue?.contents?.name,
                                    })
                                }}
                                label={vocabulary.deviceID}
                                value={device}
                                styles={{ width: '100%' }}
                            />
                            :
                            <div>
                                <Typography>Liste indisponible, cliquez pour réessayer</Typography>
                                <Button endIcon={<Refresh />} onClick={loadDevices}>Recharger</Button>
                            </div>

                    )
                }
            </Grid>


            <Grid item xs={12} md={12}>
                <AreaSelect partnerId={id} onChangeFn={setAreas} selectedAreas={areas} multipleSelect title={vocabulary.related_areas} />
            </Grid>


        </Grid>
    );
})

export default SencropConfigureForm


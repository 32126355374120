/* eslint-disable eqeqeq*/
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ResponsiveContainer, LineChart, Line,  ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Bar, ComposedChart, Cell, ErrorBar } from 'recharts';
import { logger } from '../../globalsTools/utilities';
import { vocabulary } from '../Strings';

const MultiLogsChart = ({ data, entries }) => {

    const moment = require('moment');
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const RADIAL_DOT_SIZE = 1;
    const DOT_SHAPE = { strokeWidth: 1, r: 1 };

    const [dataUpdated, setDataUpdated] = useState([])
    const [entriesArray, setEntries] = useState(entries)


    useEffect(() => {
        if (data && entriesArray && entriesArray.length>0 && data.length>0) {
            let newEntriesArray = [...entriesArray];
            logger('-- newEntriesArray ->>', newEntriesArray)
            let dataUpdating=data.reduce((accumulator, currentObject) => {
                for (let index = 1; index < newEntriesArray.length+1; index++) {
                    currentObject["set_"+index] = Number(currentObject["set_"+index]);
                    let str = currentObject["unit_"+index]
                    if (currentObject["unit_"+index]!=null) {
                        str = str.substring(
                            str.indexOf("{") + 1, 
                            str.lastIndexOf("}")
                        )
                        let units = str.split(',')
                        str = units[0]
                        let frags = str.split('/')
                        if (frags[0]==frags[1]) {
                            currentObject["min_"+index]=0
                            currentObject["max_"+index]=100
                            currentObject["set_"+index] = Math.round(Number(currentObject["set_"+index]) * 100)
                        }
                    }
                    currentObject["minMax_"+index] = [Number(currentObject["min_"+index]), Number(currentObject["max_"+index])];
                    currentObject["unit_"+index] = str
                    newEntriesArray[index-1].unit = str
                }
                accumulator.push(currentObject);
                return accumulator;
            }, [])
            logger('-- newEntriesArray ->>', newEntriesArray)
            setEntries(newEntriesArray)
            setDataUpdated(dataUpdating);
        }
    }, [data]);

    

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    return (
        <div style={{ width: '100%', height: '550px' }}>
            {
                dataUpdated &&
                <div className='WaterNeedComposedChart' style={{ width: 'inherit', height: '500px' }}>


                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={1000}
                            data={dataUpdated}
                            margin={{
                                top: 20, right: 20, bottom: 20, left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey={'date_start'} />
                            
                            <Tooltip />
                            <Legend />

                            {/* <ReferenceLine y={0} stroke="#000" /> */}

                            {/* TODO Le Probleme c'est ici  */}
                            {/* {
                                eval(lineNumber(ComposedChartData(data)))

                            } */}

                            {
                                entriesArray.map((entry, i) => {
                                        // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                                        const randomColor = ['#993333', '#DC143C', '#333300', '#8A2BE2', '#1E90FF', '#CD5C5C', '#808000', '#FFFF00']

                                        return (<YAxis type="number" 
                                                        yAxisId={"axis_"+(i+1)} 
                                                        domain={entry.dataTypeIdentifier==='volumicWaterContent' ? [0, 100] : ['auto', 'auto']} width={100}>
                                                    <Label 
                                                            value={`${entry.dataTypeLabel} (${entry.areaName}, ${entry.dataSourceId} ${entry.port ? ', '+entry.port :   ''}) ${entry.unit ?? ''}`} 
                                                            angle={-90} 
                                                            style={{ textAnchor: 'middle' }} 
                                                            />
                                                </YAxis>

                                                )
                                        }
                                    )
                            }
                            {
                                entriesArray.map((entry, i) => {
                                        // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                                        const randomColor = ['#993333', '#DC143C', '#333300', '#8A2BE2', '#1E90FF', '#CD5C5C', '#808000', '#FFFF00']

                                        let color = i >= randomColor.length ? '#' + Math.floor(Math.random() * 16777215).toString(16) : randomColor[i];
                                        return (
                                                <Line strokeWidth={4} 
                                                        name={`${entry.dataTypeLabel} (${entry.areaName}, ${entry.dataSourceId} ${entry.port ? ', ' + entry.port : ''}) ${entry.unit ?? ''} `} 
                                                        type="monotone" 
                                                        yAxisId={"axis_"+(i+1)}
                                                        dataKey={"set_"+(i+1)} 
                                                        stroke={color} 
                                                        activeDot={{ r: RADIAL_DOT_SIZE }} 
                                                        dot={DOT_SHAPE}
                                                        >

                                                    {/* <ErrorBar dataKey={"minMax_"+(i+1)}  /> */}
                                                </Line>
                                                )
                                        }
                                    )
                            }
                            {/* <Line strokeWidth={2} type="monotone" dataKey={'Wind speed'} stroke="#CD853F" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />
                            <Line strokeWidth={2} type="monotone" dataKey={'Maximum temperature'} stroke="#008ffb" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> */}


                        </LineChart>
                    </ResponsiveContainer>
                </div>
            }

        </div>
    );
}
export default MultiLogsChart
import { DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT, PROD_ENVIRONMENT } from './App/globalConsts';


const getVars = () => {
    return process.env.REACT_APP_ENV_VERSION.split("//")
}

export const REACT_APP_ENV_VERSION = getVars()[0] 
export const REACT_DEBUG = getVars()[1]==="true" 

export const version={
    version: '3.8' ,
    date: '2023-09-29' ,
    env : REACT_APP_ENV_VERSION.toUpperCase()
} 

const envKeysDev= {
    broadcasting: {
        PUSHER_KEY: 'ccfe94862c9ebef4799d',
        PUSHER_HOST: "echoserver.seabex.com",
        PUSHER_PORT: 6001,
        URL: 'https://devback.seabex.com/api/broadcasting/auth',
    },
    paymee: {
        url: 'https://sandbox.paymee.tn'
    },
    GLOBAL_URL: 'https://devback.seabex.com',
    BROKER_URL : 'wss://nexus.seabex.com:5000',
    stripe_key: "pk_test_51IcIdnK4WeBXWjDfFLDeB4jHj3EQETpiAFzeGEtpX0HcBhfaCTzKXiq2XNpDjK4gqyPJc2SODvi7f1PreMPekrzN00SMPkc6Sr"
}

const envKeysProd= {
    broadcasting: {
        PUSHER_KEY: 'ccfe94862c9ebef4799d',
        PUSHER_HOST: "echoserver.seabex.com",
        PUSHER_PORT: 6001,
        URL: 'https://back.seabex.com/api/broadcasting/auth',
    },
    paymee: {
        url: 'https://app.paymee.tn'
    },
    GLOBAL_URL: 'https://back.seabex.com',
    BROKER_URL : 'wss://nexus.seabex.com:5000',
    stripe_key: "pk_live_51IcIdnK4WeBXWjDfFMmWK5IYYyTAkGSWtTVCp4m2QR1imyEHoNmU42NcP2UW4uzrdDEOQDVH8gxCbvrSi4D7ZPX900hEA4nSpH"
}


const envKeysNetirrig= {
    broadcasting: {
        PUSHER_KEY: 'ccfe94862c9ebef4799d',
        PUSHER_HOST: "echoserver.seabex.com",
        PUSHER_PORT: 6001,
        URL: 'https://netirrigback.seabex.com/api/broadcasting/auth',
    },
    paymee: {
        url: 'https://app.paymee.tn'
    },
    GLOBAL_URL: 'https://netirrigback.seabex.com',
    BROKER_URL : 'wss://nexus.seabex.com:5000',
    stripe_key: "pk_live_51IcIdnK4WeBXWjDfFMmWK5IYYyTAkGSWtTVCp4m2QR1imyEHoNmU42NcP2UW4uzrdDEOQDVH8gxCbvrSi4D7ZPX900hEA4nSpH"
}

const envKeysDevNetirrig= {
    broadcasting: {
        PUSHER_KEY: 'ccfe94862c9ebef4799d',
        PUSHER_HOST: "echoserver.seabex.com",
        PUSHER_PORT: 6001,
        URL: 'https://devnetirrigback.seabex.com/api/broadcasting/auth',
    },
    paymee: {
        url: 'https://sandbox.paymee.tn'
    },
    GLOBAL_URL: 'https://devnetirrigback.seabex.com',
    BROKER_URL : 'wss://nexus.seabex.com:5000',
    stripe_key: "pk_test_51IcIdnK4WeBXWjDfFLDeB4jHj3EQETpiAFzeGEtpX0HcBhfaCTzKXiq2XNpDjK4gqyPJc2SODvi7f1PreMPekrzN00SMPkc6Sr"
}

const getEnvKeys = () => { switch (REACT_APP_ENV_VERSION) {
    case PROD_ENVIRONMENT:
        return envKeysProd;

    case NETIRRIG_ENVIRONMENT:
        return envKeysNetirrig;

    case DEV_NETIRRIG_ENVIRONMENT:
        return envKeysDevNetirrig;

    default:
        return envKeysDev;
}}

export const envKeys = getEnvKeys()

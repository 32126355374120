import {
  AREAS_RIGHT,
  AREAS_NUMBER,
  AREAS_LIST,
  DEPLOYED_DEVICES_NUMBER,
  PRODUCTION_CYCLES_RIGHT,
  PRODUCTION_CYCLES_NUMBER,
  PRODUCTION_CYCLES_LIST,
  USER_RIGHTS,
  USER_STATUS,
  PREFERRED_LANGUAGE,
  SET_CURRENTLANGUAGE,
  USER_COUNTRY,
  PAYMENT_PROVIDER,
  CURRENCY,
  CURRENCY_DECIMALS,
  ACTIVE_SERVICES_PRODUCTION_CYCLES,
  STATS_LOADING,
  USER_ID,
  ACTIVE_SERVICES_SENCROP_WEATHER_STATIONS,
  SENCROP_WEATHER_STATIONS_NUMBER,
  CAN_CREATE_SENCROP_STATION
} from '../actions/actionTypes';

const _globalStateReducer = (
  state = {
    areas_number: 0,
    areas_list: 0,
    new_areas_right: false,
    production_cycles_number: 0,
    production_cycles_list: null,
    deployed_devices_number: 0,
    active_services_production_cycles: 0,
    active_services_sencrop_weather_stations: 0,
    sencrop_weather_stations_number: 0,
    canCreateSencropStation: false,
    new_production_cycles_right: false,
    user_status: 'Free',
    preferred_language: 'fr',
    user_country: '',
    payment_provider: '',
    currency: '',
    currency_decimals: 0,
    stats_loading: true,
    user_id: null
  },
  action = null
) => {
  switch (action.type) {
    case AREAS_NUMBER:
      return { ...state, areas_number: action.payload };
    case AREAS_RIGHT:
      return { ...state, new_areas_right: action.payload };
    case PRODUCTION_CYCLES_NUMBER:
      return { ...state, production_cycles_number: action.payload };
    case PRODUCTION_CYCLES_RIGHT:
      return { ...state, new_production_cycles_right: action.payload };
    case DEPLOYED_DEVICES_NUMBER:
      return { ...state, deployed_devices_number: action.payload };
    case USER_RIGHTS:
      return { ...state, rights: action.payload };
    case USER_STATUS:
      return { ...state, user_status: action.payload };
    case PREFERRED_LANGUAGE:
      return { ...state, preferred_language: action.payload };
    case PRODUCTION_CYCLES_LIST:
      return { ...state, production_cycles_list: action.payload };
    case AREAS_LIST:
      return { ...state, areas_list: action.payload };
    case USER_COUNTRY:
      return { ...state, user_country: action.payload };
    case PAYMENT_PROVIDER:
      return { ...state, payment_provider: action.payload };
    case CURRENCY:
      return { ...state, currency: action.payload };
    case CURRENCY_DECIMALS:
      return { ...state, currency_decimals: action.payload };
    case ACTIVE_SERVICES_PRODUCTION_CYCLES:
      return { ...state, active_services_production_cycles: action.payload };
    case ACTIVE_SERVICES_SENCROP_WEATHER_STATIONS:
      return {
        ...state, active_services_sencrop_weather_stations: action.payload
      };
    case SENCROP_WEATHER_STATIONS_NUMBER:
      return { ...state, sencrop_weather_stations_number: action.payload };
    case CAN_CREATE_SENCROP_STATION:
      return { ...state, canCreateSencropStation: action.payload };
    case STATS_LOADING:
      return { ...state, stats_loading: action.payload };
    case USER_ID:
      return { ...state, user_id: action.payload };
    case SET_CURRENTLANGUAGE:
      localStorage.setItem('preferred_language', action.payload)
      return { ...state, preferred_language: action.payload };
    default:
      return { ...state };
  }
};

export default _globalStateReducer;

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeid } from '../../globalsTools/utilities'

export const StyledDialog = ({children, title, actions, open, handleClose, variant='_DEFAULT_'}) => {

    const [titleStyle, setTitleStyle] = useState(null);
    const id = makeid();

    useEffect(() => {

            switch(variant) {
                case '_SUCCESS_': 
                        setTitleStyle({background:'#26a728', color:'white'});
                        break;
                case '_FAILED_': 
                        setTitleStyle({background:'#D64726', color:'white'});
                        break;
                default:
                        setTitleStyle(null)
        }
    }, [variant])
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            aria-labelledby={"alert-dialog-title" + id} 
            aria-describedby={"alert-dialog-description" + id}>
            <DialogTitle id={"alert-dialog-title" + id} style={titleStyle}>
                {title}
            </DialogTitle>
            {children && 
                <DialogContent id={"alert-dialog-description" + id} dividers>
                    {children}
                </DialogContent>
            }
            { actions &&
                <DialogActions>
                    {actions}
                </DialogActions>
            }
        </Dialog>
    )
}

import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../../globalConsts";
import { useHistory } from "react-router-dom";
import {
  GuideContext,
  steps,
} from "../../../ui/layout/contextLayout/GuideContext";

function AreasListStepper({
  setMapView,
  setTab,
  handleChangeMapView,
  setOpenAdd,
  GoToSelectedSite,
}) {
  const { guide, dispatchGuide } = useContext(GuideContext);
  const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (
          steps[nextStepIndex].element === ".vertical-timeline-element--work"
        ) {
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: CURRENT_STEP, payload: "homePage" });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          history.push("/app/home");
        } else if (steps[nextStepIndex].element === ".goToButton") {
          dispatchGuide({ type: CURRENT_STEP, payload: "seabexMap" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          handleChangeMapView();
        } else if (steps[nextStepIndex].element === ".saveBtnAddArea") {
          setMapView(true);
          setTab(1);
          dispatchGuide({ type: CURRENT_STEP, payload: "addAreaModalBack" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          setOpenAdd(true);
        } else if (steps[nextStepIndex].element === ".introCycle") {
          dispatchGuide({ type: CURRENT_STEP, payload: "cycles" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          history.push("/app/cycles");
        } else if (steps[nextStepIndex].element === ".cycleList") {
          dispatchGuide({ type: CURRENT_STEP, payload: "cycles" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          history.push("/app/cycles");
        } else if (steps[nextStepIndex].element === ".onePagerMeteo") {
          dispatchGuide({ type: CURRENT_STEP, payload: "seabexMap" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          GoToSelectedSite();
        } else if (steps[nextStepIndex].element === ".realTimeintro") {
          dispatchGuide({ type: CURRENT_STEP, payload: "realTime" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          history.push("/app/realtime");
        }
      }}
      options={{
        tooltipClass: "steps",
      }}
      onExit={(e) =>
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        })
      }
    />
  );
}

export default AreasListStepper;

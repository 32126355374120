import React from 'react';
import { useSelector } from 'react-redux';
import { REACT_APP_ENV_VERSION } from '../../config';

// import { DateRange, Movie, MyLocation, ViewList, PieChart, AssignmentInd } from '@material-ui/icons/';


const ListGroup = ({ children, inclusionArray, exclusionArray, platforms }) => {

  const userType = useSelector(state => state.app.userrights)
  const impersonate = useSelector(state => state.app.impersonate)

  
  if (exclusionArray && exclusionArray.includes(userType)) {
    return (null);
  }

  if (inclusionArray && !inclusionArray.includes(userType)  || impersonate)
  {
    return (null);
  }
  
  if (platforms && !platforms.includes(REACT_APP_ENV_VERSION)) {
    return (null);
  }

  return (
      <>
      { children }
      </>
  )
  

}

export default ListGroup
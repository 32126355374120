/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/

import React  from 'react';
import { useParams } from 'react-router-dom';

import FieldPartnerConfig from './FieldPartnerConfig'
import WeatherPartnerConfig from './WeatherPartnerConfig'

const PartnerConfiguration = () => {

    let { id } = useParams();
 
    if (id == 'BAYER') {
        return (
            <FieldPartnerConfig />
        );
    }

    return (
        <WeatherPartnerConfig />
    );

}



export default PartnerConfiguration;




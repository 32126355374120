import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';

import { REACT_APP_ENV_VERSION } from '../../config';
import { GetProductionCycleById } from '../../globalsTools/network';
import { logger } from '../../globalsTools/utilities';
import { DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT } from '../globalConsts';
import TodayWeather from '../MonitoringAndSensors/TodayWeather';
import { vocabulary } from '../Strings';
import WaterRequirementProductionCycle from '../WaterRequirement/WaterRequirementProductionCycle';
import CycleDetails from './CycleDetails';
import workingAnimation from '../../assets/animations/TractorAnimation.json';

const CycleInformationCard = ({production_cycle_id}) => {
    const [productionCycle, setProductionCycle] = useState(null);
    const [underRecalculation, setUnderRecalculation] = useState(false);

    const recalculatingCycles = useSelector(state => state.cycle.recalculatingCycles)

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: workingAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    useEffect(() => {
        GetProductionCycleById(production_cycle_id, setProductionCycle);
    }, [production_cycle_id]);

    useEffect(() => {
      if (recalculatingCycles.includes(production_cycle_id)) {
        setUnderRecalculation(true)
      } else {
        setUnderRecalculation(false)
      }
    }, [recalculatingCycles])
    

    return (
        productionCycle && (
            <>
                {(REACT_APP_ENV_VERSION !== NETIRRIG_ENVIRONMENT && REACT_APP_ENV_VERSION !== DEV_NETIRRIG_ENVIRONMENT) && (
                    <div
                        className='onePagerMeteo'
                        style={{ marginTop: '10px' }}
                    >
                        <TodayWeather areaId={productionCycle.area_id} />
                    </div>
                )}

                {
                    !underRecalculation && <>
                        <Accordion
                            className='onePagerCycleDetails'
                            elevation={8}
                            defaultExpanded={true}
                            style={{
                                borderRadius: '10px',
                                width: '100%',
                                marginTop: '10px',
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                            >
                                <Typography id='cultureName'>
                                    {vocabulary.cycleDetails}{' '}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                <CycleDetails
                                    item={productionCycle}
                                    onePagerView={true}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <WaterRequirementProductionCycle
                            productionCycleId={productionCycle.id}
                        />
                    </>
                }
                {
                    underRecalculation && 
                    <Card style={{marginTop:32,padding:20,borderRadius:10, border:`solid 1px green`, textAlign:'center'}}>
                        <Lottie options={lottieOptions} width={500} height={400} />
                        <Typography variant='h6' style={{color:'green'}}>Cycle de production en cours de recalcul !<br/>Il sera rechargé automatiquement à la fin du recalcul !</Typography>
                    </Card>

                }
            </>
        )
    );
};

export default CycleInformationCard;

/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from "react";

import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { vocabulary } from "../../App/Strings";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ItemAddForm from '../../App/Admin/ItemManagment/ItemAddForm'

export default (props) => {

    const [openAddModal, setOpenAddModal] = useState(false);

    let { register,
        emptyDataMsg,
        indexV,
        rules,
        selectValue,
        styles,
        name,
        secondLabelField,
        errors,
        dataType,
        triggerValidation,
        label,
        data,
        valueField,
        labelField,
        render,
        addBtn,
        refetchFunc,
        financialGateroiesId,
        ...properties } = props

    const [selectedValue, setSelectedValue] = useState(selectValue)

    const useStyle = makeStyles((theme) => ({
        root: {
            margin: 8,
            ...styles,
            '& p': {
                fontSize: 12
            },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            width: '100%',
        },
        '& .MuiListItem': {
            root: {
                '&$selected': {
                    color: 'white',
                    backgroundColor: 'rgba(200, 200, 200, 0.14)',
                    borderLeft: 'solid 4px',
                    paddingLeft: 12,
                },
                color: '#333333',
            },

        },
    }))

    useEffect(() => {
        setSelectedValue(selectValue)
    }, [selectValue])

    const classes = useStyle();

    const checkErrors = (errorType) => {
        switch (errorType) {
            case 'required':
                return vocabulary.required
            case 'minLength':
                return vocabulary.minLength + rules.minLength

            default:
                return true
        }
    }


    return (
        <>
            <FormControl className={classes.root} >
                <div style={{ flexGrow: 1 }}>
                    <InputLabel id={name + "-label"}>{label}</InputLabel>
                    <Select
                        register={register && register(rules)}
                        {...properties}
                        required={rules && rules.required}
                        name={name}
                        id={name}
                        labelId={name + "-label"}
                        fullWidth
                        error={errors ? true : false}
                    // value={selectedValue}
                    >
                        {/* To do in future because there is some errors to handle  */}

                        {
                            data == null ?
                                <MenuItem key={''} value={''}>{vocabulary.loading}</MenuItem> : data.length == 0 ?
                                    <span style={{ width: '100%' }}>{emptyDataMsg ? emptyDataMsg : 'N/A'}</span> : null
                        }


                        {
                            data && data.length > 0 && data.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={indexV ? index : dataType ? item.data_type[valueField] : valueField ? item[valueField] : item}
                                    >
                                        {render ? render(item) : (secondLabelField ? (item[secondLabelField] + ' ' + item[labelField])
                                            : (labelField ? item[labelField] : item))}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>

                    <FormHelperText>{errors ? checkErrors(errors.type) : null}</FormHelperText>
                </div>



                <IconButton
                    edge="end"
                    disabled={financialGateroiesId == null ? true : false}
                >
                    <AddIcon
                        color='primary'
                        fontSize='medium'
                        onClick={() => setOpenAddModal(true)}
                    />
                </IconButton>


            </FormControl>
            {
                openAddModal == true &&
                <ItemAddForm
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    item={null}
                    width={600}
                    // itemId={item && item.id}
                    isEditing={false}
                    refetchFunc={refetchFunc}
                    setIsLoading={() => setOpenAddModal(false)}
                    expensesProp={true}
                    financialGateroiesIdProp={financialGateroiesId}

                />
            }

        </>
    )

}
import React from 'react';
import { ResponsiveCalendar } from '@nivo/calendar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveCalendar = (props) => {

    const moment = require('moment');

    const mapData = (input) => {

        const data = [];


        if (input) {
            input.map((e,i) => {
               return data.push({ day: (moment(e.start_time).format('YYYY-MM-DD')) ,value:Number(e.water_volume) });

            })
            
        }
        return data
    }
    

    return (
        <div style={{ height: '450px' }}>
            {
                props.data &&
                <ResponsiveCalendar
                    data={mapData(props.data)}
                    from={props.dateFrom}
                    to={props.dateTo}
                    emptyColor="#eeeeee"
                   // colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                    yearSpacing={40}
                    monthBorderColor="#ffffff"
                    dayBorderWidth={2}
                    dayBorderColor="#ffffff"
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'row',
                            translateY: 36,
                            itemCount: 4,
                            itemWidth: 42,
                            itemHeight: 36,
                            itemsSpacing: 14,
                            itemDirection: 'right-to-left'
                        }
                    ]}
                />
            }

        </div>

    )

}
export default MyResponsiveCalendar
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, Button, Chip } from '@material-ui/core/';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import {
  DateRange,
  MyLocation,
  PieChart,
  AssignmentInd, Tune,
  Equalizer,
  Timeline,
  Check,
  ShoppingCart,
  WbSunny,
  YouTube,
  NewReleases,
  PeopleAlt,
  Storefront,
  Receipt,
  ReceiptOutlined,
  AccountCircle, PanTool,
  AccessAlarm,
  ContactSupport,
  Warning,
  DeviceHub,
  NetworkCheckSharp
} from '@material-ui/icons/';
import {
  Assignment as AssignmentIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Event as EventIcon,
  ContactPhone as AdviceIcon,
  DepartureBoard as PlanningIcon,
  People as PeopleIcon,
  GroupWork as GroupWorkIcon,
  Functions as FormulasIcon,
  Loop as LoopIcon,
  Business as BusinessIcon,
  // Grain as GrainIcon,
  Build,
  Bookmarks,
  ViewList,
  Storage,
  //  Home as HomeIcon,
  Menu as MenuIcon
} from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import OpacityIcon from '@material-ui/icons/Opacity';
// import GroupIcon from '@material-ui/icons/Group';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import SpeedIcon from '@material-ui/icons/Speed';
import ErrorIcon from '@material-ui/icons/Error';
// import CardMembershipIcon from '@material-ui/icons/CardMembership';
import EventNoteIcon from '@material-ui/icons/EventNote';
import classNames from 'classnames';
import { Route, Redirect } from 'react-router-dom';
import {
  setToken,
  setUsername, syncAuth
} from '../../actions';
// import logo from '../../assets/logoplatform_white.png';
import AutomationPlansTable from '../AutomationPlan/AutomationPlansTable';
import Cycle from '../Cycle/CycleList';
import Subscriptions from '../Subscriptions/SubscriptionsList';
import WaterRequirement from '../WaterRequirement/WaterRequirement';
import WaterNeedForm from '../WaterRequirement/WaterNeedForm';
import UserManagement from '../Admin/UserManagement/UserListTable';
import OrganizationUserPartnerManagement from '../Organization-user/PartnersManagement/PartnerListTable';
import OrganizationPartnerManagement from '../Organization/PartnersManagement/PartnerListTable';
import OrganizationUserManagement from '../Organization/UsersManagement/UserListTable';
import OrganizationManagement from '../Admin/OrganizationManagement/OrganizationListTable';
import InvoiceManagement from '../Admin/BillingManagement/InvoiceManagement/InvoiceListTable';
import CustomerManagement from '../Admin/BillingManagement/CustomerManagement/CustomerListTable';
import ProductManagement from '../Admin/BillingManagement/ProductManagement/ProductListTable';
import VoucherManagement from '../Admin/BillingManagement/VoucherManagement/VoucherListTable';
import SoilManagementList from '../Admin/SoilManegement/SoilFamilyList';
import CultureManagementList from '../Admin/CultureManagement/CultureFamilyList';
import userGeoDataManagement from '../Admin/GeoDataManagement/UserGeoDataManagement';
import UserEquipmentManagement from '../Admin/EquipmentManagement/EquipmentFamilyList';
import DashboardReqList from '../Admin/DashboardMangement/DashboardReqList';
import PublicationList from '../Admin/PublicationManagment/PublicationList';
import CatalogPacksList from '../Admin/CatalogManagement/CatalogSeabexPackList';
import IrrigationFamilyList from '../Admin/IrrigationManagement/IrrigationFamilyList';
import DataTypeList from '../Admin/SensorDataTypeManagment/SensorDataTypeList';
// import statistics from '../statistics/statistics';
import SettingsIcon from '@material-ui/icons/Settings';
import { stylesDashboard } from '../globalStyle';
import Logs from '../Logs/LogsList';
import MultiLogs from '../Logs/MultiLogsList';
import MonitoringAndSensors from '../MonitoringAndSensors/MonitoringPage';
import Scenarios from '../Scenarios/Scenario';
//import MapArea from '../Sites/MapArea';
import Formulas from '../Formules/FormulasList';
import Station from '../Stations/Station';
import { vocabulary } from '../Strings';
import EventsList from '../Events/Eventslist';
import IrrigationList from '../Irrigation/IrrigationList';
import PluvioList from '../Irrigation/PluvioList';

import Advices from '../Advices/AdviceList';
import Followers from '../Advices/Followers';
import Planification from '../Advices/Planification';
import ListallOrganizations from '../Organizations/ListallOrganizations';
import ListAvailableOrganizations from '../Organizations/ListActiveAndAvailableOrganizations';
import Statistics1 from '../Advices/Statistics';
import Agents from '../Agents/AgentList';
import UserPreference from '../UserPreferences/UserPreference';
import UserProfile from '../Farmers/FarmerProfile';
import HomePage from '../HomePage/HomePage';
import FinancialResourceList from '../FinancialResource/FinancialResourceList';
import PartnersIntegrationsList from '../PartnersIntegrations/ListPartners';
import PartnerConfiguration from '../PartnersIntegrations/PartnerConfiguration';
import financialCategorieListTest from '../Admin/FinancialCategorie/FinancialCategorieList';
import ItemList from '../Admin/ItemManagment/ItemList';
import ActivitiesTypesList from '../Admin/ActivitiesManagement/ActivitiesTypesList';
import ActivitiesList from '../UserActivities/ActivitiesList';
// import LiveHelpIcon from '@material-ui/icons/LiveHelp';
// import bill from '../../images/bill.png';
import culture from '../../images/culture.png';
// import { useHistory } from "react-router-dom";
// import settings from '../../images/settings.png';
import ListAltIcon from '@material-ui/icons/ListAlt';
import QuotasManagmentList from '../ManaginQuotas/QuotasManagmentList';
import { removeCookie } from 'tiny-cookie';
import LinkItem from './LinkItem';
import SeabexLogoWhite from '../../ui/SeabexLogoWhite';
import ListGroup from './ListGroup';
import Signin from '../signin';
import { AuthorizationContext } from '../../ui/layout/contextLayout/AuthorizationContext';
import MonitoringPage from '../MonitoringAndSensors/MonitoringPage';
import AreasList from '../Sites/AreasList';
import OrganizationAreasList from '../Organization/AreaManagment/AreasList';
import ErrorList from '../Admin/ErrorManagement/ErrorList';
import GroupsList from '../GroupManagment/GroupsList';
import UserFinancialItemList from '../FinancialResource/UserFinancialItemList';
import GrainIcon from '@material-ui/icons/Grain';
import './style.css';
// class SubTitle extends React.Component {
//   render() {
//     return (
//       <Typography variant="h2" gutterBottom color="secondary" style={{ marginLeft: 16 }}>
//         <div> {props.title}</div>
//       </Typography>
//     )
//   }
// }
// const drawerWidth = 240
import { REACT_APP_ENV_VERSION, version } from '../../config';
import HelpAndSupport from '../HelpAndSupport/HelpAndSupport';
import FaqHelp from '../HelpAndSupport/FaqHelp';
import RevenuesExpensesList from '../RevenueExpenses/RevenuesExpensesList';
// import { mqttEnd } from '../MqttController/MqttController';
import NestedLinkItem from './NestedLinkItem';
import {
  CA_ENVIRONMENT,
  DEV_ENVIRONMENT,
  DEV_NETIRRIG_ENVIRONMENT,
  NETIRRIG_ENVIRONMENT,
  PROD_ENVIRONMENT,
} from '../globalConsts';
import NetirrigSignin from '../_partners/_Netirrig/NetirrigSignin';
import WhiteLogoNetIrrigBySeabex from '../_partners/_Netirrig/assets/WhiteLogoNetIrrigBySeabex';
import CA_Signin from '../_partners/_CA/CA_Signin';
import CAPlatformBySeabex from '../_partners/_CA/assets/CAPlatformBySeabex';
import OrdersList from '../CustomerOrders/OrdersList';
import BroadcastingManager from '../_broadcasting/BroadcastingManager';
import AreasManagement from '../Admin/AreasManagement/AreasManagement';
import { setGlobalLanguage } from '../../actions/_globalStatusActions';
import NetirrigSolsList from '../Admin/__NETIRRIG/NetirrigSol/NetirrigSolsList';
import MeteoStationsList from '../Admin/MeteoStationsManagement/MeteoStationsList';
import InvoiceAddForm from '../Admin/BillingManagement/InvoiceManagement/InvoiceAddForm';
import { logoutUser } from '../../globalsTools/network';
import { useSnackbar } from 'notistack';
import { logger } from '../../globalsTools/utilities';
import { GetUserStatus } from '../../globalsTools/network';
import { useCallback } from 'react';
import OrdersListManagement from '../Admin/OrdersManagement/OrdersListManagement';
import { updateAlert } from '../../actions/actions';
import SeabexOffers from '../Offers/SeabexOffers';
import ImpersonateAreas from '../Admin/Impersonation/Area/AreaList';
import ImpersonateCycle from '../Admin/Impersonation/Cycles/CyclesList';

import ImpersonateOrganizationAreas from '../Organization/Impersonation/Area/AreaList';
import ImpersonateOrganizationCycle from '../Organization/Impersonation/Cycles/CyclesList';

import ImpersonateOrganizationUserAreas from '../Organization-user/Impersonation/Area/AreaList';
import ImpersonateOrganizationUserCycle from '../Organization-user/Impersonation/Cycles/CyclesList';
// import { GlobalContext } from '../../globalsTools/GlobalContext';


const Dashboard = () => {
  // const {globalState, dispatchGlobalState} = useContext(GlobalContext)

  const classes = stylesDashboard();
  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,

    });
  };

  // const appLoading = useSelector(state => state.appLoading)
  // const currentLanguage = useSelector(state => state._globalState.preferred_language)
  const currentPage = useSelector((state) => state.app.currentPage);
  const userType = useSelector((state) => state.app.userrights);
  const username = useSelector((state) => state.app.username);
  const companyName = useSelector((state) => state.app.company_name);
  const loginToken = useSelector((state) => state.app.token);
  const disconnect = useSelector((state) => state.app.disconnect);
  const areas_number = useSelector((state) => state._globalState.areas_number);
  const impersonate = useSelector(state => state.app.impersonate)


  const production_cycles_number = useSelector(
    (state) => state._globalState.production_cycles_number
  );
  const production_cycles_right = useSelector(
    (state) => state._globalState.production_cycles_right
  );
  const active_services_production_cycles = useSelector(
    (state) => state._globalState.active_services_production_cycles
  );
  const deployed_devices_number = useSelector(
    (state) => state._globalState.deployed_devices_number
  );
  const userStatsLoading = useSelector(state => state._globalState.stats_loading)

  const currentLanguage = useSelector(
    (state) => state._globalState.preferred_language
  );

  const alert = useSelector(state => state.app.alert)


  const accessToken = localStorage.getItem('login_Token');
  const userRight = localStorage.getItem('userrights');
  const userName = localStorage.getItem('username');
  const userid = localStorage.getItem('userid');
  const company_name = localStorage.getItem('company_name');


  useEffect(() => {
    dispatch(
      syncAuth(
        company_name,
        accessToken,
        userid,
        userRight,
        userName,
        currentLanguage
      )
    );

    // dispatch(updateAlert())
  }, []);



  const [redirect, setRedirect] = useState(false);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  // const styles = props.classes;

  const handleDrawer = (newState) => {
    setOpen(newState);
  };
  const [openCollapse, setOpenCollapse] = useState(false);
  const [multiOpenCollapse, setMultiOpenCollapse] = useState(false);
  const [nestedPartnersMenu, setNestedPartnersMenu] = useState(false);

  const [showOffers, setShowOffers] = useState(false)


  const handleClickNested = () => {
    setOpenCollapse(!openCollapse);
  };
  const handleMultiClickNested = () => {
    setMultiOpenCollapse(!multiOpenCollapse);
  };
  // const handleDrawerOpen = handleDrawer(true);
  // const handleDrawerClose = handleDrawer(false);

  // const handleDrawerClose = () => {
  //   setOpen(false)
  // };

  const protectedRoutes = [
    '/app/billingManagement/invoices', '/app/billingManagement/customers',
    '/app/billingManagement/customers', '/app/billingManagement/vouchers',
    '/app/dashboardManagement', '/app/billingManagement/products',
    '/app/organizationManagement', '/app/areaManagement', '/app/userSoilManagement',
    '/app/netirrigSoils', '/app/meteoStations', '/app/userCultureManagement',
    '/app/equipmentManagement', '/app/catalogManagement', '/app/irrigationManagement',
    '/app/dataTypes', '/app/activitiesManegement', '/app/errorHandler', '/app/publicationManagment',
    '/app/formulas', '/app/ordersManagement'
  ];

  const DeniedAccess = () => {
    return <div style={{ position: 'absolute', top: 0, left: 0, width: `100%`, height: `100%`, backgroundColor: 'red', zIndex: 9999 }}>
      <div style={{ color: 'white', position: 'absolute', top: `calc(50% - 108px)`, left: `calc(50% - 135px)`, fontSize: 32, border: `solid 2px white`, borderRadius: 20, padding: 20, textAlign: 'center' }}><PanTool style={{ width: 60, height: 60, marginBottom: 20 }} /><br />Accès Interdit...<br />Denied Access!</div>
    </div>
  }

  const LoadingProfileType = () => {
    return <div style={{ position: 'absolute', top: 0, left: 0, width: `100%`, height: `100%`, backgroundColor: 'white', zIndex: 9999 }}>
      <div style={{ color: '#dfdfdf', position: 'absolute', top: `calc(50% - 108px)`, left: `calc(50% - 135px)`, fontSize: 32, border: `solid 2px white`, borderRadius: 20, padding: 20, textAlign: 'center' }}><AccessAlarm style={{ width: 60, height: 60, marginBottom: 20 }} /><br />chargement...<br />Loading...</div>
    </div>
  }

  const swi = useCallback(
    (Component, props) => {
      // GetUserStatus();

      const { location } = props;
      const isProtectedRoute = protectedRoutes.includes(location?.pathname);

      if (userType === '') {
        return <LoadingProfileType />;
      }

      if (
        (isProtectedRoute && userType !== 'Super')
      ) {
        return <DeniedAccess />;
      }

      return <Component {...props} />;

    },
    [userType, protectedRoutes],
  )


  const removeCookiesLogOut = () => {


    removeCookie('login_Token');
    removeCookie('userid');
    removeCookie('username');
    removeCookie('userrights');
    removeCookie('currentLanguage');
    removeCookie('preferred_language');

    dispatch(setToken(null));
    dispatch(setUsername(''));

    setRedirect(true);

    logoutUser((response) => {

      if (response?.status != 204) {
        snackbar(vocabulary.errorOccurred, 'error')
        return;
      }


    })


    // mqttEnd(userId);
  };

  useEffect(() => {
    if (disconnect) {
      setRedirect(true);
    }
  }, [disconnect]);


  function redirectTo() {
    return <Redirect to='/' />;
  }

  const contextValue = {
    redirectToSignin: redirect,
    setRedirectToSignin: setRedirect,
  };

  if (redirect) {
    return redirectTo();
  } else {
    return (
      <AuthorizationContext.Provider value={contextValue}>
        <div className={classes.root}>
          {/* <div style={{flexDirection:'column',display:'flex'}}>
            <div style={{position:'fixed'}} className={classNames(styles.toolbarImageSpace, !open && styles.Hidden)}>
                <img  className={styles.toolbarImageProperties}
                 src={logo} alt="logo" />              
            </div>           */}

          {showOffers && <SeabexOffers open={showOffers} withoutContext={true} closeFn={() => setShowOffers(false)} />}

          <AppBar
            position='fixed'
            className={classNames(classes.appBar, open && classes.appBarShift)}
          >
            <>
              <Toolbar style={{ backgroundColor: '#522311' }}>
                <IconButton
                  color='inherit'
                  aria-label='Open drawer'
                  onClick={() => handleDrawer(true)}
                  edge='start'
                  className={classNames(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component='h6'
                  color='inherit'
                  noWrap
                  style={{ fontSize: '15px' }}
                // className={styles.title}
                >
                  {currentPage}
                </Typography>

                <Typography
                  component='h6'
                  color='inherit'
                  style={{ textAlign: 'end', flexGrow: 1 }}
                  noWrap
                >
                  {username} ({companyName ? companyName : vocabulary[userType]}){' '}
                  {userStatsLoading !== true &&
                    <>
                      <Chip
                        style={{
                          marginInlineStart: 12,
                          backgroundColor: active_services_production_cycles > 0 ? '#206400' : '#FFC107',
                          color: active_services_production_cycles > 0 ? 'white' : 'black',
                        }}
                        icon={
                          production_cycles_right ? (
                            <Check style={{ color: 'white' }} />
                          ) : undefined
                        }
                        label={active_services_production_cycles > 0 ? "Abonnement actif" : "Pas d'abonnement actif"}
                      ></Chip>
                      {active_services_production_cycles === 0 && <Button onClick={() => setShowOffers(true)} variant='contained' style={{ borderRadius: 25, marginInlineStart: 10, backgroundColor: 'green', color: 'white' }} > S'abonner </Button>}
                    </>
                  }
                </Typography>

                <BroadcastingManager style={{ margin: 12 }} />
              </Toolbar>
              {
                alert.show === 4 && <Typography style={{ backgroundColor: '#ff9600', padding: 8 }}><Warning style={{ marginInlineEnd: 12 }} />{vocabulary[alert.message] ?? alert.message}</Typography>
              }
            </>

          </AppBar>

          <Drawer
            variant='permanent'
            classes={{
              paper: classNames(
                classes.scrollbars,
                open ? classes.drawerOpen : classes.drawerClose
              ),
            }}
            className={classNames(
              classes.drawer,
              open ? classes.drawerOpen : classes.drawerClose
            )}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <LinkItem
                routeTo='/app/home'
                icon={
                  (REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? (
                    <WhiteLogoNetIrrigBySeabex
                      className={classes.toolbarImg}
                      height={36}
                    />
                  ) : (REACT_APP_ENV_VERSION === CA_ENVIRONMENT ? (
                    <CAPlatformBySeabex
                      className={classes.toolbarImg}
                      height={36}
                    />
                  ) : (
                    <SeabexLogoWhite
                      className={classes.toolbarImg}
                      height={36}
                    />
                  ))
                }
              />

              <div className={classes.toolbar} style={{ display: 'flex' }}>
                <IconButton
                  onClick={() => handleDrawer(false)}
                  style={{ border: '1px solid #ffffff' }}
                >
                  {currentLanguage === 'ar' ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
            </div>

            <Divider />

            <List className='DashboardList'>

              {
                // impersonate   && impersonate.scopes && (
                impersonate   && (

                  <>
                    {/* {impersonate.scopes.includes('area-section-access') && ( */}
                      <LinkItem
                        routeTo={`/app/area/impersonate`}
                        icon={<MyLocation />}
                        vocabularyText={vocabulary.sites}
                      />
                    {/* )} */}

                    {/* {impersonate.scopes.includes('cycle-section-access') && ( */}
                      <LinkItem
                        routeTo={`/app/cycle/impersonate`}
                        icon={<LoopIcon />}
                        vocabularyText={vocabulary.cycles}
                      />
                    {/* )} */}
                  </>


                )
              }



              <ListGroup
                inclusionArray={['Organization', 'Organization-User']}
              >

                {
                  userType === 'Organization' && (
                    <LinkItem
                      routeTo='/app/organization/sites'
                      icon={<MyLocation />}
                      vocabularyText={vocabulary.sites}
                      style={{ backgroundColor: '#8E5F00' }}
                      badge={areas_number}
                    />
                  )
                }


                <LinkItem
                  routeTo={userType === 'Organization' ? '/app/partners' : '/app/organization/user/partners'}
                  icon={<GroupWorkIcon />}
                  vocabularyText={vocabulary.partnerManagement}
                />

                {
                  userType === 'Organization' &&
                  <LinkItem

                    routeTo='/app/users'
                    icon={<PeopleIcon />}
                    vocabularyText={vocabulary.userManagement}
                  />
                }

              </ListGroup>

              <ListGroup
                inclusionArray={['Organization', 'Institution', 'Agent']}
              >

                {/* <LinkItem
                  routeTo='/app/advices'
                  icon={<AdviceIcon />}
                  vocabularyText={vocabulary.AdvicesService}
                />
                <LinkItem
                  routeTo='/app/agents'
                  icon={<AssignmentInd />}
                  vocabularyText={vocabulary.agents}
                />
                <LinkItem
                  routeTo='/app/plannings'
                  icon={<PlanningIcon />}
                  vocabularyText={vocabulary.planification}
                /> */}
                <LinkItem
                  routeTo='/app/statistics1'
                  inclusionArray={['Institution']}
                  icon={<PieChart />}
                  vocabularyText={vocabulary.Statistics}
                />

                <Divider />
              </ListGroup>
              <ListGroup inclusionArray={['Super']}>
                <LinkItem
                  routeTo='/app/dashboardManagement'
                  icon={<DashboardIcon />}
                  vocabularyText={vocabulary.dashboardLabel}
                />
                <LinkItem
                  routeTo='/app/userManagement'
                  icon={<PeopleIcon />}
                  vocabularyText={vocabulary.userManagement}
                />
                <LinkItem
                  routeTo='/app/ordersManagement'
                  icon={<ShoppingCart />}
                  vocabularyText="Achats et Abonnements"
                />

                <LinkItem
                  routeTo='/app/organizationManagement'
                  icon={<BusinessIcon />}
                  vocabularyText={vocabulary.organizationManagement}
                />
                <LinkItem
                  routeTo='/app/areaManagement' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<MyLocation />}
                  vocabularyText={vocabulary.areaManagement}
                />
                <LinkItem platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  routeTo='/app/userSoilManagement'
                  icon={<PieChart />}
                  vocabularyText={vocabulary.userSoilManagement}
                />
                <LinkItem platforms={[NETIRRIG_ENVIRONMENT, DEV_NETIRRIG_ENVIRONMENT]}
                  routeTo='/app/netirrigSoils'
                  icon={<PieChart />}
                  vocabularyText="Liste des Sols Netirrig"
                />
                <LinkItem
                  routeTo='/app/meteoStations'
                  icon={<WbSunny />}
                  vocabularyText="Liste des Stations Météo"
                />
                <LinkItem
                  routeTo='/app/userCultureManagement'
                  icon={<img alt='cultureImage' src={culture} />}
                  vocabularyText={vocabulary.userCultureManagement}
                />
                {/* <LinkItem routeTo="/app/userGeoDataManagement" icon={<MyLocation />} vocabularyText={vocabulary.userGeoDataManagement} /> */}
                <LinkItem
                  routeTo='/app/equipmentManagement' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<Build />}
                  vocabularyText={vocabulary.userEquipmentManagment}
                />
                <LinkItem
                  routeTo='/app/catalogManagement' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<Bookmarks />}
                  vocabularyText={vocabulary.userCatalogManagement}
                />
                <LinkItem
                  routeTo='/app/irrigationManagement' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<ViewList />}
                  vocabularyText={vocabulary.userIrrigationManagement}
                />
                <LinkItem
                  routeTo='/app/dataTypes' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<Storage />}
                  vocabularyText={vocabulary.dataTypeManagment}
                />
                {/* <LinkItem routeTo="/app/financialCategories" icon={<AttachMoneyIcon />} vocabularyText={vocabulary.financialCategorie} /> */}
                <LinkItem
                  routeTo='/app/activitiesManegement' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<LocalActivityIcon />}
                  vocabularyText={vocabulary.activitiesManagement}
                />
                <LinkItem
                  routeTo='/app/errorHandler' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<ErrorIcon />}
                  vocabularyText={vocabulary.errorHandler}
                />
                <LinkItem
                  routeTo='/app/publicationManagment' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<DashboardIcon />}
                  vocabularyText={vocabulary.publicationManagment}
                />
                <NestedLinkItem
                  routeTo='/app/financialCategories' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<AttachMoneyIcon />}
                  vocabularyText={vocabulary.financialCategorie}
                  nestedTableRoute={[
                    {
                      NestedIcon: <AssignmentIcon />,
                      NestedRouteTo: '/app/itemManagment',
                      NestedVocabularyText: vocabulary.itemManagmentLabel,
                    },
                  ]}
                  open={openCollapse}
                  handleClick={handleClickNested}
                />
                <NestedLinkItem
                  platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT, DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT]}
                  icon={<AttachMoneyIcon />}
                  vocabularyText={vocabulary.accountingManagement}
                  nestedTableRoute={[
                    {
                      NestedIcon: <Receipt />,
                      NestedRouteTo: '/app/billingManagement/invoices',
                      NestedVocabularyText: vocabulary.invoiceManagement,
                    },
                    {
                      NestedIcon: <PeopleAlt />,
                      NestedRouteTo: '/app/billingManagement/customers',
                      NestedVocabularyText: vocabulary.customerManagement,
                    },
                    {
                      NestedIcon: <Storefront />,
                      NestedRouteTo: '/app/billingManagement/products',
                      NestedVocabularyText: vocabulary.productManagement,
                    },
                    {
                      NestedIcon: <ReceiptOutlined />,
                      NestedRouteTo: '/app/billingManagement/vouchers',
                      NestedVocabularyText: vocabulary.voucherManagement,
                    }
                  ]}
                  open={openCollapse}
                  handleClick={handleClickNested}
                />
                <LinkItem platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  routeTo='/app/formulas'
                  icon={<FormulasIcon />}
                  vocabularyText={vocabulary.formules}
                />

                <Divider />
              </ListGroup>

              <ListGroup
                inclusionArray={['Institution', 'Farmer']}
              >
                {/* <LinkItem routeTo="/app/home" icon={<HomeIcon />} vocabularyText={vocabulary.homePageLabel} /> */}
                <LinkItem
                  routeTo='/app/sites'
                  icon={<MyLocation />}
                  vocabularyText={vocabulary.sites}
                  style={{ backgroundColor: '#8E5F00' }}
                  badge={areas_number}
                />
                {/* <Divider /> */}
              </ListGroup>
              {/* <ListGroup inclusionArray={["Super", "Farmer"]}>
                <LinkItem routeTo="/app/formulas" icon={<FormulasIcon />} vocabularyText={vocabulary.formules} />
                
              </ListGroup> */}

              <ListGroup inclusionArray={['Farmer']}>
                <LinkItem
                  routeTo='/app/cycles'
                  icon={<LoopIcon />}
                  vocabularyText={vocabulary.cycles}
                  badge={production_cycles_number}
                />
                {/* <LinkItem routeTo="/app/waterRequirement" icon={<GrainIcon />} vocabularyText={vocabulary.waterRequirementLabel} /> */}
                {/* <LinkItem routeTo="/app/waterNeedOnDemand" icon={<OpacityIcon />} vocabularyText={vocabulary.waterNeedOnDemand} /> */}

                <LinkItem
                  routeTo='/app/irrigation'
                  icon={<EventIcon />}
                  vocabularyText={vocabulary.irrigationMangement}
                />
                <LinkItem
                  routeTo='/app/RainfallManagement'
                  icon={<GrainIcon />}
                  vocabularyText={vocabulary.rainfallManagement}
                // disabled={true}
                />
                <LinkItem
                  routeTo='/app/activities' platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<LocalActivityIcon />}
                  vocabularyText={vocabulary.culturalActivities}
                  disabled={false}
                />

                <Divider />
                <LinkItem
                  routeTo='/app/stations'
                  icon={<SettingsInputAntenna />}
                  vocabularyText={vocabulary.deployedDevices}
                  badge={deployed_devices_number}
                />
                {(deployed_devices_number !== 0) &&
                  <>
                    <LinkItem
                      routeTo='/app/realtime'
                      icon={<SpeedIcon />}
                      vocabularyText={vocabulary.realTimeSensorsAndActuators}
                      disabled={deployed_devices_number === 0}
                    />
                    <LinkItem
                      routeTo='/app/automationplan'
                      icon={<DateRange />}
                      vocabularyText={vocabulary.automationPlans}
                      disabled={deployed_devices_number === 0}
                    />
                    <NestedLinkItem
                      icon={<AssignmentIcon />}
                      vocabularyText={vocabulary.Logs}
                      nestedTableRoute={[
                        {
                          NestedIcon: <Equalizer />,
                          NestedRouteTo: '/app/multilogs',
                          NestedVocabularyText: vocabulary.multiLogs,
                        },
                        {
                          NestedIcon: <Timeline />,
                          NestedRouteTo: '/app/logs',
                          NestedVocabularyText: vocabulary.Logs,
                        },
                      ]}
                      open={openCollapse}
                      handleClick={handleClickNested}
                    />

                    <LinkItem
                      routeTo='/app/events'
                      icon={<EventNoteIcon />}
                      vocabularyText={vocabulary.Events}
                      disabled={deployed_devices_number > 0}
                    />
                  </>}
                {/* <LinkItem routeTo="/app/subscriptions" icon={<CardMembershipIcon />} vocabularyText={vocabulary.subscriptions} /> */}
                <Divider />

                <NestedLinkItem
                  routeTo='/app/integrations'
                  platforms={[DEV_ENVIRONMENT, DEV_NETIRRIG_ENVIRONMENT, PROD_ENVIRONMENT, NETIRRIG_ENVIRONMENT]}
                  icon={<NetworkCheckSharp />}
                  vocabularyText={vocabulary.network}
                  disabled={false}
                  open={nestedPartnersMenu}
                  handleClick={() => setNestedPartnersMenu(!nestedPartnersMenu)}
                  nestedTableRoute={[
                    {
                      NestedIcon: <DeviceHub />,
                      NestedRouteTo: '/app/integrations',
                      NestedVocabularyText: vocabulary.partnerIntegrations,
                      disabled: false,
                    },

                  ]}

                />

                <NestedLinkItem
                  routeTo='/app/financialManagment'
                  platforms={[DEV_ENVIRONMENT, PROD_ENVIRONMENT]}
                  icon={<AttachMoneyIcon />}
                  vocabularyText={vocabulary.financialManagment}
                  disabled={false}
                  open={multiOpenCollapse}
                  handleClick={handleMultiClickNested}
                  nestedTableRoute={[
                    {
                      NestedIcon: <AssignmentIcon />,
                      NestedRouteTo: '/app/itemManagment',
                      NestedVocabularyText: vocabulary.itemManagmentLabel,
                      disabled: false,
                    },
                    {
                      NestedIcon: <ListAltIcon />,
                      NestedRouteTo: '/app/expenses_revenues',
                      NestedVocabularyText: vocabulary.expensesRevenue,
                      disabled: false,
                    },
                  ]}
                // NestedIcon={<AssignmentIcon />}
                // NestedRouteTo="/app/financialItem"
                // NestedVocabularyText={vocabulary.itemManagmentLabel}
                // open={openCollapse}
                // handleClick={handleClickNested}
                />

                {/* <LinkItem routeTo="/app/statistics" icon={<img alt='diagramImage' src={diagram} />} vocabularyText={vocabulary.statistics} /> */}
                <LinkItem
                  routeTo='/app/userPreference'
                  icon={<Tune />}
                  vocabularyText={vocabulary.userPreferencesLabel}
                />
                <LinkItem
                  routeTo='/app/orders'
                  icon={<ShoppingCart />}
                  vocabularyText={vocabulary.ordersMenu}
                />
                <LinkItem
                  routeTo='/app/userProfile'
                  icon={<AccountCircle />}
                  vocabularyText={vocabulary.userPersonnelInfo}
                />
                <Divider />
                <LinkItem
                  routeTo='/app/ManagingQuotas'
                  icon={<SettingsIcon />}
                  vocabularyText={vocabulary.managingQuotasLabel}
                  disabled={true}
                />
                {/* <LinkItem routeTo="/app/formulas" icon={<FormulasIcon />} vocabularyText={vocabulary.formules} /> */}

                {/* <LinkItem routeTo="/app/helpAndSupport" icon={<Help />} vocabularyText={vocabulary.helpAndSupport} /> */}
                {/* <LinkItem routeTo="/app/FAQ" icon={<LiveHelpIcon />} vocabularyText={'FAQ'} /> */}
                {/* <LinkItem style={{ display: 'none' }} routeTo="/app/LiveSupport" icon={<Help />} vocabularyText={'Live Support'} /> */}
              </ListGroup>

              <Divider />

              {/* <LinkItem routeTo="/app/groupsMangement" icon={<GroupIcon />} vocabularyText={vocabulary.groupeManagment} /> */}
              {/* <LinkItem routeTo="/app/Activeorganizations" inclusionArray={["Farmer"]} icon={<PeopleIcon />} vocabularyText={vocabulary.organization} />
              <LinkItem routeTo="/app/organizations" inclusionArray={["Institution"]} icon={<PeopleIcon />} vocabularyText={vocabulary.organization} /> */}

              <LinkItem exclusionArray={["Super"]}
                routeTo='/app/profile'
                icon={<DashboardIcon />}
                vocabularyText={vocabulary.profile}
                disabled={true}
              />
              <LinkItem
                onClick={() => window.open('https://youtube.com/playlist?list=PLYxB4V-E7tRU_sDSncbKUJMoZMKqZ0bmx', '_blank')}
                icon={<YouTube />}
                vocabularyText={vocabulary.videosTutoriaux} nochip={true}
                badge={<NewReleases />}
              />
              <LinkItem
                onClick={() => window.open('https://forms.gle/8CieVXWbp6ZbkPT49', '_blank')}
                icon={<ContactSupport />}
                vocabularyText={vocabulary.demandeAssistance} nochip={true}
                badge={<NewReleases />}
              />
              {
                !impersonate && (
                  <LinkItem
                    icon={<ExitToAppIcon />}
                    vocabularyText={vocabulary.logout}
                    onClick={() => removeCookiesLogOut()}
                  />
                )
              }
              <Divider />
            </List>
            <List
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '110px',
              }}
            >
              <div
                className='changeLanguage'
                style={
                  open === false
                    ? { display: 'flex', flexDirection: 'column' }
                    : { margin: '20px', textAlign: 'center' }
                }
              >
                <Button
                  style={
                    currentLanguage === 'en'
                      ? { color: 'white' }
                      : { color: '#03465b' }
                  }
                  onClick={() => {
                    dispatch(setGlobalLanguage('en'));
                    vocabulary.setLanguage('en');
                  }}
                >
                  En
                </Button>
                <Button
                  style={
                    currentLanguage === 'fr'
                      ? { color: 'white' }
                      : { color: '#03465b' }
                  }
                  onClick={() => {
                    dispatch(setGlobalLanguage('fr'));
                    vocabulary.setLanguage('fr');
                  }}
                >
                  Fr
                </Button>
                <Button
                  style={
                    currentLanguage === 'ar'
                      ? { color: 'white' }
                      : { color: '#03465b' }
                  }
                  onClick={() => {
                    dispatch(setGlobalLanguage('ar'));
                    vocabulary.setLanguage('ar');
                  }}
                >
                  Ar
                </Button>
              </div>
              {open === true ? (
                <div
                  style={{
                    color: '#a2a2a2',
                    fontSize: 'smaller',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {'v' +
                    version.version +
                    ' - ' +
                    version.date +
                    (REACT_APP_ENV_VERSION === DEV_ENVIRONMENT
                      ? ' ' + version.env
                      : '')}
                </div>
              ) : (
                <div
                  style={{
                    color: '#a2a2a2',
                    fontSize: 'smaller',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {'V   : ' + version.version} <br />
                  {version.date} <br />
                  {REACT_APP_ENV_VERSION === DEV_ENVIRONMENT
                    ? ' ' + version.env
                    : ''}
                </div>
              )}
            </List>
          </Drawer>

          <main className={classes.content}>
            {loginToken && (
              <div className={classes.toolbar}>
                <Route
                  path='/app/home'
                  render={(props) => swi(HomePage, props)}
                />
                <Route

                  path='/app/sites'
                  render={(props) => swi(AreasList, props)}
                />
                <Route

                  path='/app/organization/sites'
                  render={(props) => swi(OrganizationAreasList, props)}
                />
                <Route
                  path='/app/realtime'
                  render={(props) => swi(MonitoringPage, props)}
                />
                <Route
                  path='/app/profile'
                  render={(props) => swi(UserProfile, props)}
                />
                <Route
                  path='/app/userPreference'
                  render={(props) => swi(UserPreference, props)}
                />
                <Route
                  path='/app/userProfile'
                  render={(props) => swi(UserProfile, props)}
                />
                <Route
                  path='/app/financialManagment'
                  render={(props) => swi(FinancialResourceList, props)}
                />
                <Route
                  exact
                  path='/app/integrations'
                  render={(props) => swi(PartnersIntegrationsList, props)}
                />
                <Route
                  exact
                  path='/app/integrations/:id/configure'
                  render={(props) => swi(PartnerConfiguration, props)}
                />

                <Route
                  path='/app/expenses_revenues'
                  render={(props) => swi(RevenuesExpensesList, props)}
                />

                <Route
                  path='/app/financialItem'
                  render={(props) => swi(UserFinancialItemList, props)}
                />
                <Route
                  path='/app/stations'
                  render={(props) => swi(Station, props)}
                />
                <Route
                  path='/app/events'
                  render={(props) => swi(EventsList, props)}
                />
                <Route
                  path='/app/automationplan'
                  render={(props) => swi(AutomationPlansTable, props)}
                />
                {/* <Route path="/app/expenses" render={(props) => swi(AddExpenses, props)} />
                <Route path="/app/revenues" render={(props) => swi(AddRevenues, props)} /> */}
                <Route
                  path='/app/logs'
                  render={(props) => swi(Logs, props)}
                />
                <Route
                  path='/app/multilogs'
                  render={(props) => swi(MultiLogs, props)}
                />
                <Route
                  path='/app/irrigation'
                  render={(props) => swi(IrrigationList, props)}
                />
                <Route
                  path='/app/RainfallManagement'
                  render={(props) => swi(PluvioList, props)}
                />

                <Route
                  path='/app/scenario'
                  render={(props) => swi(Scenarios, props)}
                />
                <Route
                  path='/app/formulas'
                  render={(props) => swi(Formulas, props)}
                />
                <Route
                  path='/app/advices'
                  render={(props) => swi(Advices, props)}
                />
                <Route
                  path='/app/partners'
                  render={(props) => swi(OrganizationPartnerManagement, props)}
                />

                <Route
                  exact
                  path='/app/organization/user/partners'
                  render={(props) => swi(OrganizationUserPartnerManagement, props)}
                />

                <Route
                  path='/app/users'
                  render={(props) => swi(OrganizationUserManagement, props)}
                />

                <Route
                  path='/app/followers'
                  render={(props) => swi(Followers, props)}
                />
                <Route
                  path='/app/subscriptions'
                  render={(props) => swi(Subscriptions, props)}
                />
                <Route
                  path='/app/plannings'
                  render={(props) => swi(Planification, props)}
                />
                <Route
                  path='/app/organizations'
                  render={(props) => swi(ListallOrganizations, props)}
                />
                <Route
                  path='/app/Activeorganizations'
                  render={(props) => swi(ListAvailableOrganizations, props)}
                />
                <Route
                  path='/app/monitoringAndSensors'
                  render={(props) => swi(MonitoringAndSensors, props)}
                />
                <Route
                  path='/app/statistics1'
                  render={(props) => swi(Statistics1, props)}
                />
                <Route
                  path='/app/agents'
                  render={(props) => swi(Agents, props)}
                />
                <Route
                  path='/app/cycles'
                  render={(props) => swi(Cycle, props)}
                />
                <Route
                  path='/app/ManagingQuotas'
                  render={(props) => swi(QuotasManagmentList, props)}
                />
                <Route
                  path='/app/waterRequirement'
                  render={(props) => swi(WaterRequirement, props)}
                />
                <Route
                  path='/app/waterNeedOnDemand'
                  render={(props) => swi(WaterNeedForm, props)}
                />
                <Route
                  path='/app/helpAndSupport'
                  render={(props) => swi(HelpAndSupport, props)}
                />
                <Route
                  path='/app/FAQ'
                  render={(props) => swi(FaqHelp, props)}
                />

                {/* <Route path="/app/statistics" render={(props) => swi(statistics, props)} /> */}
                <Route
                  path='/app/UserManagement'
                  render={(props) => swi(UserManagement, props)}
                />
                <Route
                  path='/app/OrganizationManagement'
                  render={(props) => swi(OrganizationManagement, props)}
                />
                <Route
                  path='/app/areaManagement'
                  render={(props) => swi(AreasManagement, props)}
                />
                <Route
                  path='/app/dashboardManagement'
                  render={(props) => swi(DashboardReqList, props)}
                />
                <Route
                  path='/app/userSoilManagement'
                  render={(props) => swi(SoilManagementList, props)}
                />
                <Route
                  path='/app/netirrigSoils'
                  render={(props) => swi(NetirrigSolsList, props)}
                />
                <Route
                  path='/app/meteoStations'
                  render={(props) => swi(MeteoStationsList, props)}
                />
                <Route
                  path='/app/userCultureManagement'
                  render={(props) => swi(CultureManagementList, props)}
                />
                <Route
                  path='/app/userGeoDataManagement'
                  render={(props) => swi(userGeoDataManagement, props)}
                />
                <Route
                  path='/app/equipmentManagement'
                  render={(props) => swi(UserEquipmentManagement, props)}
                />
                <Route
                  path='/app/catalogManagement'
                  render={(props) => swi(CatalogPacksList, props)}
                />
                <Route
                  path='/app/irrigationManagement'
                  render={(props) => swi(IrrigationFamilyList, props)}
                />
                <Route
                  path='/app/dataTypes'
                  render={(props) => swi(DataTypeList, props)}
                />
                <Route
                  path='/app/financialCategories'
                  render={(props) => swi(financialCategorieListTest, props)}
                />
                <Route
                  path='/app/activitiesManegement'
                  render={(props) => swi(ActivitiesTypesList, props)}
                />
                <Route
                  path='/app/activities'
                  render={(props) => swi(ActivitiesList, props)}
                />
                <Route
                  path='/app/errorHandler'
                  render={(props) => swi(ErrorList, props)}
                />
                <Route
                  path='/app/groupsMangement'
                  render={(props) => swi(GroupsList, props)}
                />
                <Route
                  path='/app/publicationManagment'
                  render={(props) => swi(PublicationList, props)}
                />
                <Route
                  path='/app/itemManagment'
                  render={(props) => swi(ItemList, props)}
                />

                <Route
                  exact
                  path='/app/billingManagement/invoices/add'
                  render={(props) => swi(InvoiceAddForm, props)}
                />
                <Route
                  exact
                  path='/app/billingManagement/invoices'
                  render={(props) => swi(InvoiceManagement, props)}
                />

                <Route
                  path='/app/billingManagement/customers'
                  render={(props) => swi(CustomerManagement, props)}
                />
                <Route
                  exact
                  path='/app/billingManagement/customers/:id'
                  render={(props) => swi(InvoiceAddForm, props)}
                />
                <Route
                  exact
                  path='/app/billingManagement/products'
                  render={(props) => swi(ProductManagement, props)}
                />
                <Route
                  exact
                  path='/app/billingManagement/vouchers'
                  render={(props) => swi(VoucherManagement, props)}
                />
                <Route
                  exact
                  path='/app/orders'
                  render={(props) => swi(OrdersList, props)}
                />
                <Route
                  exact
                  path='/app/ordersManagement'
                  render={(props) => swi(OrdersListManagement, props)}
                />

                <Route
                  exact
                  path='/app/area/impersonate'
                  render={(props) => swi(ImpersonateAreas, props)}
                />


                <Route
                  exact
                  path='/app/cycle/impersonate'
                  render={(props) => swi(ImpersonateCycle, props)}
                />

                <Route
                  exact
                  path='/app/organization/area/impersonate'
                  render={(props) => swi(ImpersonateOrganizationAreas, props)}
                />


                <Route
                  exact
                  path='/app/organization/cycle/impersonate'
                  render={(props) => swi(ImpersonateOrganizationCycle, props)}
                />

                <Route
                  exact
                  path='/app/organization/user/area/impersonate'
                  render={(props) => swi(ImpersonateOrganizationUserAreas, props)}
                />


                <Route
                  exact
                  path='/app/organization/user/cycle/impersonate'
                  render={(props) => swi(ImpersonateOrganizationUserCycle, props)}
                />


                <Route
                  path='/login'
                  component={
                    (REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT)
                      ? NetirrigSignin
                      : REACT_APP_ENV_VERSION === CA_ENVIRONMENT
                        ? CA_Signin
                        : Signin
                  }
                />
              </div>
            )}
          </main>
        </div>
      </AuthorizationContext.Provider>
    );
  }
};

export default Dashboard;

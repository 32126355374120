import React from "react";
import { Typography } from "@material-ui/core";

const style = { color: 'red', fontSize: 10, lineHeight: 20 }

export default (error) => {

    return (
        <Typography
            style={style}
            // style={{}}
        >
            {error}
        </Typography>
    )

}
/* eslint-disable no-useless-concat*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';

import { StyledMaterialTable } from '../../ui/display';
import {  GetLogsByAreaAndMultiDataType } from "../../globalsTools/network";
// import LineChartLogs from '../Charts/LineChart/LineChartLogs'
// import Excel from '../../images/icons8-xls-32.png'
// import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
// import {  withStyles } from '@material-ui/core/';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import MultiLogsChart from './MultiLogsChart'
import { StyledCard } from '../../ui/layout';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const LogsTable = ({ selectedDataSources,  dateFrom, dateTo, reload, intervalUnit, inetrvalNumber }) => {

    // const moment = require('moment');
    // const currentLanguage = useSelector(state => state.app.currentLanguage)
    // const { enqueueSnackbar } = useSnackbar();
    // const RayonnementSolaireIdentifier = 'solarRadiation'

    const [logList, setLogList] = useState(null);
    const [pageSize, setPageSize] = useState(1000);
    const [isLoading, setIsLoading] = useState(false)
    const [entriesArray, setEntries] = useState(selectedDataSources)

    const tableRef = React.createRef();

    // const snackbar = (msg, type) => {
    //     enqueueSnackbar(msg, { variant: type });
    // };
    // const operationCallBack = (r) => {
    //     if (r) {
    //         if (r.status === 200) {
    //             setIsLoading(false)

    //             const url = window.URL.createObjectURL(new Blob([r.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', 'file.xlsx');
    //             document.body.appendChild(link);
    //             link.click();

    //         } else {
    //             setIsLoading(false)

    //             snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
    //         }
    //     }
    // }


    // const headerTable = vocabulary.datatype + "," + vocabulary.calculatedValue + "," + vocabulary.isoUnit + "," + vocabulary.captureTime + "," + vocabulary.area + "," + vocabulary.sourceLabel


    // const DownloadLogsTable = () => {
    //     setIsLoading(true)

    //     DownloadLogs(
    //         {
    //             header: headerTable,
    //             area_id: selectedAreaId,
    //             prm_data_type_id: selectedDataSources.DataTypeId,
    //             start_date: dateFrom,
    //             end_date: dateTo,
    //             download: true
    //         }, r => operationCallBack(r)
    //     )


    // };

    useEffect(() => {
        if (reload !== null) {
            tableRef.current.onQueryChange();
        }
    }, [reload]);

    const formatData = (data) => {

        let dataUpdating=data
        if (data && entriesArray && entriesArray.length>0 && data.length>0) {
                let newEntriesArray = [...entriesArray];
                let dataUpdating=data.reduce((accumulator, currentObject) => {
                    for (let index = 1; index < newEntriesArray.length+1; index++) {
                        currentObject["set_"+index] = Number(currentObject["set_"+index]);
                        let str = currentObject["unit_"+index]
                        if (currentObject["unit_"+index]!=null) {
                            str = str.substring(
                                str.indexOf("{") + 1, 
                                str.lastIndexOf("}")
                            )
                            let units = str.split(',')
                            str = units[0]
                            let frags = str.split('/')
                            if (frags[0]==frags[1]) {
                                currentObject["min_"+index]=0
                                currentObject["max_"+index]=100
                                currentObject["set_"+index] = Math.round(Number(currentObject["set_"+index]) * 100)
                            }
                        }
                        currentObject["minMax_"+index] = [Number(currentObject["min_"+index]), Number(currentObject["max_"+index])];
                        currentObject["unit_"+index] = str
                        newEntriesArray[index-1].unit = str
                    }
                    accumulator.push(currentObject);
                    return accumulator;
                }, [])

                setEntries(newEntriesArray)
                setLogList(dataUpdating)
            }
        return dataUpdating
    }
    
    const columns = [
        { title: vocabulary.captureTime, field: 'date_start' },
        ...selectedDataSources.map((value, index)=> { 
            return  { title: `${value.dataTypeLabel} (${value.areaName}, ${value.dataSourceId} ${value.port ? ', '+value.port :   ''}) ${value.unit ?? ''}`, field:"set_"+(index+1) }
        })
    ]
    
    return (
        <StyledCard
            styleProp={{
            width: '96%',
            padding: 30,
            paddingRight: 50,
            marginTop: 15,
            }}
        >
            {(logList && logList !== null && logList.length > 0) &&
                <MultiLogsChart 
                    entries={selectedDataSources}
                    data={logList}
                />}



            {!isLoading ?
                <StyledMaterialTable
                    tableRef={tableRef}
                    title={vocabulary.Logs}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage } }}
                    // onChangePage={page => pageNumber(page + 1 )}
                    onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                    // data={logListByAreaId ? logListByAreaId : []}
                    data={query => new Promise((resolve, reject) => {
                        GetLogsByAreaAndMultiDataType(selectedDataSources, dateFrom, dateTo, intervalUnit, inetrvalNumber, query.pageSize, (query.page + 1))
                            .then(result => {

                                if (result && result.data) {
                                    resolve({
                                        data: formatData(result.data.data ? result.data.data : result.data),
                                        page: result.data.current_page - 1,
                                        totalCount: result.data.total,
                                        pageSize: result.data.per_page,
                                    })
                                    window.scrollTo(0, 0);
                                }
                            })
                    })
                    }
                    columns={ columns }
                    actions={[


                        // {
                        //     icon: () => <img src={Excel} alt="excelIcon" />,
                        //     tooltip: vocabulary.downloadLabel,
                        //     isFreeAction: true,
                        //     onClick: (event) => DownloadLogsTable()
                        // }

                    ]}

                    options={{
                        pageSizeOptions: [100, 500, 1000, 2500, 5000, 10000],
                        pageSize: pageSize,
                        actionsColumnIndex: -1,
                        search: false,
                        paginationPosition:'both',
                        exportMenu: [{
                            label: 'Export PDF',
                            exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SeabexMultiLogPDF')
                          }, {
                            label: 'Export CSV',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SeabexMultiLogCSV')
                          }]
                    }}
                />
                : <StyledCircularProgress size={50} label={vocabulary.loadingFilelogs} />
            }
        </StyledCard>
    )
}

export default LogsTable
import React, { useState } from 'react';

import { vocabulary } from '../Strings';
import { StyledMaterialTable } from '../../ui/display';
import ActionForm from './ActionForm'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';

export default function ActionTableHooks({ addAction, actionArray }) {

    const [openActionForm, setOpenActionForm] = useState(false)

    const isJson = (data) => {
        try {
            JSON.parse(data);
        } catch (e) {
            return false;
        }
        return true;
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenActionForm(!openActionForm)
        } else {
            setOpenActionForm(newStatus)
        }
    }

    const contextValue = { open: openActionForm, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={contextValue}>

            <StyledMaterialTable
                title={vocabulary.actions}
                data={(isJson(actionArray) ? JSON.parse(actionArray) : actionArray)}
                columns={[
                    { title: vocabulary.astationOrAutomation, field: 'DeviceID', render: rowData => <>{rowData.DeviceID ? rowData.DeviceID + " (Station identifier)" : rowData.AutomationPlan.Title + " (Automation plan)"}</> },
                    { title: vocabulary.equipmentLabel, field: 'Port' },
                    { title: vocabulary.action, field: 'subscription', render: rowData => <>{rowData.Action === true ? vocabulary.enabled : vocabulary.disabled}</> },

                ]}
                actions={[

                    {
                        icon: 'add',
                        tooltip: vocabulary.add,
                        isFreeAction: true,
                        onClick: (event, row) => setOpenActionForm(true)
                    }

                ]}
            />

            <ActionForm
                open={openActionForm}
                onClose={() => {
                    setOpenActionForm(false)
                }}
                addAction={addAction}
                width={500}
            />
        </ModalContext.Provider>

    );

}
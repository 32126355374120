import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Echo from 'laravel-echo';
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';
import { envKeys } from '../../config';
import {
    Badge,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
    Block,
    ClearAll,
    Close,
    NotificationsActive,
    NotificationsNone,
} from '@material-ui/icons';
import { formatDateTimeStr } from '../../utils';
import { logger } from '../../globalsTools/utilities';
import _ from 'lodash';
import { vocabulary } from '../Strings';

import { updateRecalculatingCycles, fetchMeteoData, removeRecalculatingCycle } from '../../actions/actions';
import { useDispatch } from 'react-redux';
import { eventPublish } from '../../globalsTools/events';


const connectEcho = (connectionCallback, disconnectionCallback) => {


    const accessToken = localStorage.getItem('login_Token');

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: envKeys.broadcasting.PUSHER_KEY,
        wsHost: envKeys.broadcasting.PUSHER_HOST,
        wsPort: envKeys.broadcasting.PUSHER_PORT,
        wssPort: envKeys.broadcasting.PUSHER_PORT,
        forceTLS: false,
        encrypted: false,
        enableLogging: true,
        disableStats: true,
        //cluster: 'eu',
        enabledTransports: ['ws', 'wss'],
        authEndpoint: envKeys.broadcasting.URL,
        auth: {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        }
    });

    window.Echo.connector.pusher.connection.bind(
        'connected',
        connectionCallback
    );

    window.Echo.connector.pusher.connection.bind(
        'disconnected',
        disconnectionCallback
    );
};

const disconnectEcho = () => {
    window.Echo.disconnect();
};

const registerProductionCycleCalculationEvent = (
    user_id,
    productionCycleCalculationCallback
) => {
    window.Echo.private('App.User.' + user_id).listen(
        '.cycle.calculation.start',
        productionCycleCalculationCallback
    );
};

const unRegisterProductionCycleCalculationEvent = (user_id) => {
    window.Echo.private(
        'App.User.' + user_id
    ).stopListening('.cycle.calculation.start');
    window.Echo.leave('App.User.' + user_id);
};

const registerProductionCycleCalculationFinishedEvent = (
    user_id,
    productionCycleCalculationCallback
) => {
    window.Echo.private('App.User.' + user_id).listen(
        '.cycle.calculation.finished',
        productionCycleCalculationCallback
    );
};

const unRegisterProductionCycleCalculationFinishedEvent = (user_id) => {
    window.Echo.private(
        'App.User.' + user_id
    ).stopListening('.cycle.calculation.finished');
    window.Echo.leave('App.User.' + user_id);
};



const BroadcastingManager = () => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    // const [notificationsList, setNotificationsList] = useState([]);
    const [showNotificationsList, setShowNotificationsList] = useState(false);

    const RA_REINIT = 'reinitialize',
        RA_ADD_ITEM = 'add_item',
        RA_REMOVE_ITEM = 'remove_item';
    const notificationListReducer = (state, action) => {
        switch (action.type) {
            case RA_REINIT:
                return { notifications: [] };
            case RA_ADD_ITEM:
                return {
                    notifications: [
                        action.notification,
                        ...state.notifications,
                    ],
                };
            case RA_REMOVE_ITEM:
                let copy = [...state.notifications];
                _.pullAt(copy, action.index);
                return { notifications: [...copy] };

            default:
                return state;
        }
    };


    const [notificationsState, dispatchNotificationsListEvent] = useReducer(
        notificationListReducer,
        { notifications: [] }
    );

    // Handle Popover -------

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // ------------------------



    const cycles = useSelector(
        (state) => state._globalState.production_cycles_list
    );
    // const user_id = useSelector(
    //     (state) => state._globalState.user_id
    // );
    const currentLanguage = useSelector(
        (state) => state._globalState.preferred_language
    );

    const user_id = useSelector((state) => state.app.userid);

    const notify = (content, type = 'default') => {
        if (content) {
            dispatchNotificationsListEvent({
                type: RA_ADD_ITEM,
                notification: {
                    content: content,
                    type: type,
                    time: new Date(),
                    notification_type: type,
                },
            });
            enqueueSnackbar(content, {
                variant: type,
            });
        }
    };


    /**
     * Echo connection routine
     */
    useEffect(() => {

        connectEcho(
            (e) => notify(vocabulary.nowConnected),
            (e) => notify(vocabulary.nowDisconnected)
        )


        return () => {
            try {
                disconnectEcho();
            } catch (e) { }
        };
    }, []);

    /**
     * Cycles registration routine
     */
    useEffect(() => {

        logger('User_id', user_id)

        registerProductionCycleCalculationEvent(user_id, (data) => {
            if (data) {
                let content = vocabulary.productionCycleCalculationNotification.replace('%culture_name%', data.culture_name).replace('%area_name%', data.area_name);
                let type = 'info';
                // let message = {
                //     content: content,
                //     type: type,
                //     time: new Date(),
                // };
                // dispatchNotificationsListEvent({
                // 	type: RA_ADD_ITEM,
                // 	notification: message,
                // });
                dispatch(updateRecalculatingCycles({
                    id: data.id,
                }))
                notify(content, type)
            }
        });

        registerProductionCycleCalculationFinishedEvent(user_id, (data) => {
            if (data) {
                let content = vocabulary.waterNeedRecalculated;
                let type = 'info';

                notify(content, type)

                logger("Finished Event Data", data)

                dispatch(removeRecalculatingCycle(data.productionCycleId))
                eventPublish('recalculated.' + data.productionCycleId)
            }


        });

        return () => {

            unRegisterProductionCycleCalculationEvent(user_id);
            unRegisterProductionCycleCalculationFinishedEvent(user_id);

        };

    }, [user_id]); // USE REDUX SELECTOR

    return (
        <div style={{ marginInlineStart: 16, marginInlineEnd: 16 }}>
            <Badge
                aria-describedby={id}
                badgeContent={notificationsState.notifications && notificationsState.notifications.length}
                onClick={(event) => {
                    setShowNotificationsList(true);
                    handleClick(event);
                }}
            >
                {notificationsState.notifications &&
                    (notificationsState.notifications.length > 0 ? (
                        <NotificationsActive />
                    ) : (
                        <NotificationsNone />
                    ))}
            </Badge>
            {showNotificationsList && showNotificationsList === true && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    PaperProps={{
                        style: { width: 300, textAlign: 'end' },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Button
                        size='small'
                        endIcon={<ClearAll />}
                        style={{ marginInlineEnd: 16 }}
                        onClick={() => dispatchNotificationsListEvent({ type: RA_REINIT })}
                    >
                        <i>Clear All</i>
                    </Button>
                    <List style={{ background: '#3a6050', width: 300 }}>
                        {notificationsState.notifications &&
                            notificationsState.notifications.map((item, index) => {
                                return (
                                    <ListItem key={'notifications' + index}>
                                        <ListItemText
                                            disableTypography
                                            style={{ color: 'white' }}
                                            primary={<>{item.content}</>}
                                            secondary={
                                                <div style={{ color: '#b7b7b7' }}>
                                                    {formatDateTimeStr(
                                                        item.time,
                                                        currentLanguage
                                                    )}
                                                </div>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge='end'
                                                aria-label='delete'
                                                onClick={() => dispatchNotificationsListEvent({ type: RA_REMOVE_ITEM, index: index })}
                                            >
                                                <Close style={{ color: 'white' }} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                    </List>
                </Popover>
            )}
        </div>
    );
};

export default BroadcastingManager;

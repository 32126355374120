/* eslint-disable eqeqeq*/
/* eslint-disable no-unreachable*/

import React, {  useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from "../../ui/display/StyledMaterialTable";
import StyledCircularProgress from "../../ui/display/StyledCircularProgress";
import { vocabulary } from "../Strings";
import { setCurrentPage } from "../../actions";
import { ModalContext } from "../../ui/layout/contextLayout/ModalContext";
import {
  getAllDeployedDevices,
  GetAreasList,
  GetSeabexCategories,
  DeleteStation,
  updateDeviceStatus
} from "../../globalsTools/network";
import PropTypes from "prop-types";
import AlertDialog from "../Components/alertDialog";
// import { useHistory } from "react-router-dom";
// import { styles } from "../globalStyle";
// import { withStyles } from "@material-ui/core/styles";
// import compose from "recompose/compose";
// import { compose } from 'redux';
import AddStationForm from "./AddStationForm";
import DoneIcon from "@material-ui/icons/Done";
import NavigationIcon from "@material-ui/icons/Navigation";
import RouterIcon from "@material-ui/icons/Router";
// import SettingsInputAntenna from "@material-ui/icons/SettingsInputAntenna";
import ToysIcon from "@material-ui/icons/Toys";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudIcon from "@material-ui/icons/Cloud";
import StationDetail from "./StationDetail";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { stylesThemed } from "../globalStyle";
// import CurrentPage from "../Components/CurrentPage";
// import {
//   GuideContext,
//   steps,
// } from "../../ui/layout/contextLayout/GuideContext";
// import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../globalConsts";
// import StationStepper from "./StationStepper";

const StationList = (props) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state._globalState.preferred_language);
  const { enqueueSnackbar } = useSnackbar();
  // const { guide, dispatchGuide } = useContext(GuideContext);

  const [deployedDevices, setDeployedDevices] = useState(null);
  const [siteList, setSiteList] = useState(null);
  const [seabexCategoriePack, setSeabexCategoriePack] = useState(null);

  const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
  const [item, setItem] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [openAddStepper, setOpenAddStepper] = useState(false);

  // let history = useHistory();

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.deployedDevices));
    getAllDeployedDevices(setDeployedDevices);
    GetAreasList(setSiteList);
    GetSeabexCategories(setSeabexCategoriePack);
    document.title = vocabulary.deployedDevices;
  }, [dispatch]); // N’exécute l’effet qu'a la première render '

  useEffect(() => {
    // dispatch(setCurrentPage(<CurrentPage name={vocabulary.deployedDevices} helpFn={helpFn} />))
    dispatch(setCurrentPage(vocabulary.deployedDevices));
    document.title = vocabulary.deployedDevices;
  }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  // useEffect(() => {
  //     setTimeout(() => {
  //         if (steps[guide.initialStep].element === ".introDeployedDevices" && guide.currentStep === 'deployedDevice')
  //             dispatchGuide({ type: IS_ENABLED, payload: true })
  //     }, 1000);
  // }, [])
  // useEffect(() => {
  //     if (guide.currentStep === "deployedDevice" && steps[guide.initialStep].element === ".introDeployedDevices" && guide.isEnabled === false) {
  //         setTimeout(() => {
  //             dispatchGuide({ type: IS_ENABLED, payload: true })
  //         }, 1000);
  //     }
  // }, [openAddStepper])

  // const helpFn = () => {
  //     dispatchGuide({ type: INITIAL_STEP, payload: steps.findIndex(step => step.element === ".introDeployedDevices") })
  //     dispatchGuide({ type: CURRENT_STEP, payload: 'deployedDevice' })
  //     setTimeout(() => {
  //         dispatchGuide({ type: IS_ENABLED, payload: true })
  //     }, 500);

  // }

  const openAlertConfirmDelete = (row) => {
    setAlertConfirmDelete(true);
    setItem(row);
  };
  const closeAlertConfirmDelete = () => {
    setAlertConfirmDelete(false);
  };
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleClickCloseAdd = () => {
    setOpenAdd(false);
  };
  const handleClickEditClose = () => {
    setIsEditing(false);
    setOpenEdit(false);
  };
  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };
  const changeModalStatus = (newStatus) => {

    if (newStatus == null) {
      setOpenEdit(!openEdit);
      setOpenAdd(!openAdd);
    } else {
      setOpenEdit(newStatus);
      setOpenAdd(newStatus);
      setIsEditing(newStatus);
    }
  };
  const operationCallBack = (r) => {
    if (r) {
      if (r.status === 204) {
        snackbar(item.name + " " + vocabulary.deleteMsg, "success");
        getAllDeployedDevices(setDeployedDevices);
      } else {
        snackbar(vocabulary.errorOccurred + " " + r.status, "error");
      }
    }
  };
  const handleDeleteStation = (item) => {
    DeleteStation(item.id, (r) => operationCallBack(r));
  };
  const handleClickOpenEdit = (row) => {
    setOpenEdit(true);
    setIsEditing(true);
    setItem(row);
  };
  const deviceType = (type) => {
    switch (type) {
      case "ACTION":
        return (
          <Chip
            icon={<ToysIcon className={classes.textLockOpenColor} />}
            label={vocabulary.action}
            className={[
              classes.chip,
              classes.colorLockOpenType,
              classes.textLockOpenColor,
            ]}
            variant="outlined"
          />
        );
        break;
      case "GATEWAY":
        return (
          <Chip
            icon={<RouterIcon className={classes.gatewayTextColor} />}
            label={vocabulary.gateway}
            className={[
              classes.chip,
              classes.colorGateway,
              classes.gatewayTextColor,
            ]}
            variant="outlined"
          />
        );
        break;
      case "ACQUISITION":
        return (
          <Chip
            icon={<CloudIcon className={classes.monitoringTextColor} />}
            label={vocabulary.monitoring}
            className={[
              classes.chip,
              classes.colorMonitoring,
              classes.monitoringTextColor,
            ]}
            variant="outlined"
          />
        );
        break;

      default:
        return (
          <Chip
            icon={<CancelIcon />}
            label={type}
            className={classes.chip}
            color="default"
            deleteIcon={<DoneIcon />}
            variant="outlined"
          />
        );
        break;
    }
  };

  const updateStatus = (row) => {
    updateDeviceStatus({
      id: row.id,
    })
  }

  const classes = stylesThemed();
  
  const contextValueEdit = {
    open: openEdit,
    changeModalStatus: changeModalStatus,
  };
  const contextValueAdd = {
    open: openAdd,
    changeModalStatus: changeModalStatus,
  };
  return (
    <>
      {/* {guide.isEnabled && guide.currentStep === 'deployedDevice' &&
                <StationStepper
                    setOpenAdd={setOpenAdd}
                />
            } */}

      <ModalContext.Provider
        value={isEditing === false ? contextValueAdd : contextValueEdit}
      >
        {!isLoading && deployedDevices && siteList ? (
          <StyledCard>
            <StyledMaterialTable
              title={vocabulary.deployedDevices}
              data={deployedDevices && deployedDevices.data}
              localization={{
                body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay },
                header: { actions: vocabulary.actions },
                pagination: {
                  nextTooltip: vocabulary.nextpage,
                  previousTooltip: vocabulary.previouspage,
                  lastTooltip: vocabulary.lastpage,
                  firstTooltip: vocabulary.firstpage,
                  labelRowsSelect: vocabulary.rowsLabel,
                },
                toolbar: {
                  searchPlaceholder: vocabulary.search,
                  searchTooltip: vocabulary.search,
                },
              }}
              detailPanel={[
                {
                    tooltip: 'Show Details',
                    render: ({ rowData }) => {
                      return (
                        <StationDetail // deployed Device equipment list
                          itemRow={rowData}
                        />
                      );}
                    }]}
              columns={[
                {
                  title: vocabulary.type,
                  field: "user",
                  render: (rowData) => (
                    <>
                      {rowData.seabex_model_id ? (
                        deviceType(JSON.parse(rowData.seabexmodel.type).type)
                      ) : (
                        <Chip
                          icon={<CancelIcon />}
                          label={vocabulary.noModelYet}
                          className={classes.chip}
                          color="default"
                          deleteIcon={<DoneIcon />}
                          variant="outlined"
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: vocabulary.identifier,
                  field: "identifier",
                  render: (rowData) => (
                    <>{rowData.identifier ? rowData.identifier : null}</>
                  ),
                },
                { title: vocabulary.stationName, field: "name" },
                {
                  title: vocabulary.site,
                  field: "area",
                  render: (rowData) => (
                    <>
                      {rowData.area && rowData.area.parent
                        ? rowData.area.parent.name + " / " + rowData.area.name
                        : rowData.area && rowData.area.name
                        ? rowData.area && rowData.area.name
                        : vocabulary.noAreaYet}
                    </>
                  ),
                },
              ]}
              actions={[
               
                {
                  icon: "edit_outline",
                  tooltip: !siteList
                    ? vocabulary.loading
                    : vocabulary.editLabel,
                  onClick: (event, row) => handleClickOpenEdit(row),
                  disabled: !siteList,
                },
                (row) => ({
                  icon: "delete_outline",
                  tooltip: vocabulary.delete,
                  onClick: (event, row) => openAlertConfirmDelete(row),
                }),
                row => ({
                    icon: () => <NavigationIcon />,
                    tooltip: 'Update device status',
                     onClick: (event, row) => updateStatus(row),
                    // disabled: row.realposition ? false : true

                }),

                {
                  icon: () => (
                    <AddIcon
                      fontSize="large"
                      color="primary"
                      className="addDeployedDeviceBtn"
                    />
                  ),
                  tooltip: !siteList
                    ? vocabulary.loading
                    : vocabulary.addStation,
                  isFreeAction: true,
                  disabled: !siteList,
                  onClick: (event) => handleClickOpenAdd(),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 40, 60, 80, 100],
              }}
            />

            {alertConfirmDelete === true && (
              <AlertDialog
                operation={vocabulary.delete}
                open={alertConfirmDelete}
                deletedItemName={item.name}
                onClose={() => {
                  closeAlertConfirmDelete();
                }}
                confirmeDelete={() => {
                  if (item) {
                    handleDeleteStation(item);
                  }
                  closeAlertConfirmDelete();
                }}
                cancel={() => {
                  closeAlertConfirmDelete();
                }}
              />
            )}
            {(openAdd || openEdit) && (
              <AddStationForm
                open={isEditing === false ? openAdd : openEdit}
                handleClickClose={
                  isEditing === false
                    ? handleClickCloseAdd
                    : handleClickEditClose
                }
                item={isEditing === true ? item : null}
                deployedDevicesProps={deployedDevices && deployedDevices.data}
                areas={siteList && siteList}
                isEditing={isEditing}
                refetchFunc={() => getAllDeployedDevices(setDeployedDevices)}
                seabexCategoriePack={seabexCategoriePack && seabexCategoriePack}
                setIsLoading={setIsLoading}
              />
            )}
          </StyledCard>
        ) : (
          <StyledCircularProgress
            size={50}
            label={
              isLoading
                ? vocabulary.add +
                  " " +
                  vocabulary.deployedDevices +
                  " " +
                  vocabulary.cycleStatus
                : vocabulary.loading
            }
          />
        )}
      </ModalContext.Provider>
    </>
  );
};
// StationList.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
export default StationList;

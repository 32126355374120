import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import EquipmentTypeAddForm from './EquipmentTypeAdd'
import EquipmentDataTypeList from './EquipmentDataTypeList'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { GetEquipmentTypesList, DeleteEquipment } from '../../../globalsTools/network';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { useSnackbar } from 'notistack';

const EquipmentsTypeList = ({ item }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [open, setOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [equipmentList, setEquipmentList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const openAlertConfirmDelete = (row) => {
        setSelectedItem(row)
        setAlertConfirmDelete(true)
    };

    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false)
    };

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpen(!open)
            setOpenEdit(!openEdit)
            setIsEditing(!isEditing)
        } else {
            setOpen(newStatus)
            setOpenEdit(newStatus)
        }
    }
    useEffect(() => {

        if (item) {
            GetEquipmentTypesList(item.name, setEquipmentList)
        }

    }, [item])

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(JSON.parse(selectedItem.name)['name_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetEquipmentTypesList(item.name, setEquipmentList)



            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteEquipmentType = (selectedItem) => {

        DeleteEquipment(selectedItem.id
            , r => operationCallBack(r)
        )
    }

    const contextValue = { open: open, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    const openAddEquipment = () => {
        setOpen(true)
        setIsEditing(false)
    }

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setSelectedItem(row)
    }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            <>
                {(!isLoading && equipmentList) ?
                    <StyledMaterialTable
                        title={vocabulary.equipmentTypeOfFamily + ": " + (item && item.name)}
                        data={equipmentList.data}
                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: ({ rowData }) => { return (<EquipmentDataTypeList itemRow={rowData} />) }
                        }]}
                        columns={[
                            { title: vocabulary.name_label, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name_fr}</> },
                            { title: vocabulary.reference, field: 'reference' },
                            { title: vocabulary.description, field: 'description', render: rowData => <>{JSON.parse(rowData.description).description}</> },
                            {
                                title: vocabulary.datatype, field: 'prmdatatypes', render: rowData => <>{
                                    rowData.prmdatatypes.length > 0 ?
                                        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '2' }}>
                                            {
                                                rowData.prmdatatypes.map((el, index) => {
                                                    return <span>{rowData.prmdatatypes.length > 0 && JSON.parse(el.name).name_ar ? JSON.parse(el.name)['name_' + currentLanguage] : JSON.parse(el.name).name_fr}</span>
                                                })
                                            }
                                        </div>
                                        : ''
                                }
                                </>
                            },

                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editLabel,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                                disabled: row.id < 2000
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.add,
                                isFreeAction: true,
                                onClick: (event) => openAddEquipment()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false

                        }}
                    />

                    :

                    <StyledLoadingRow />
                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(selectedItem.name)['name_' + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (selectedItem) {
                                handleDeleteEquipmentType(selectedItem);
                            }
                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
                {
                    openEdit === false ?
                        <EquipmentTypeAddForm
                            open={open}
                            onClose={() => {
                                return (setOpen(false),
                                    setIsEditing(false)
                                )
                            }}
                            itemId={item && item.id}
                            isEditing={isEditing}
                            chosenType={item && item.name}
                            refetchFunc={() => GetEquipmentTypesList(item.name, setEquipmentList)}
                            setIsLoading={setIsLoading}

                        />
                        :
                        <EquipmentTypeAddForm
                            open={openEdit}
                            onClose={() => {
                                return (setOpenEdit(false),
                                    setIsEditing(false)
                                )
                            }}
                            itemId={item && item.id}
                            item={selectedItem}
                            isEditing={isEditing}
                            chosenType={item && item.name}
                            refetchFunc={() => GetEquipmentTypesList(item.name, setEquipmentList)}
                            setIsLoading={setIsLoading}


                        />
                }

            </>

        </ModalContext.Provider>

    )
}
export default EquipmentsTypeList;
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import DataTypeAddForm from './SensorDataTypeAddForm'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { setCurrentPage } from '../../../actions';
import { GetDataTypeList, DeletelDataType } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
 
const DataTypeList = () => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [dataTypeList, setDataTypeList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.dataTypeManagment));
        GetDataTypeList(setDataTypeList)
        document.title = vocabulary.dataTypeManagment


    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.dataTypeManagment));
        document.title = vocabulary.dataTypeManagment

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)['name_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetDataTypeList(setDataTypeList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteDataType = (item) => {

        DeletelDataType(item.id
            , r => operationCallBack(r)
        )
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>

                {(!isLoading && dataTypeList) ?
                    <StyledMaterialTable
                        title={vocabulary.datatype}
                        data={dataTypeList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                        columns={[
                            { title: vocabulary.name_label, field: 'name', render: rowData => <>{JSON.parse(rowData.name)['name_' + currentLanguage]}</> },
                            { title: vocabulary.defaultUnit, field: 'iso_unit' },
                            { title: vocabulary.identifier, field: 'identifier' },
                            { title: vocabulary.dataTypeNature, field: 'nature' },
                            {
                                title: vocabulary.unitListsLabel, field: 'units_array', render: rowData => <>{rowData.units_array !== null ?
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '2' }}>
                                        {
                                            JSON.parse(rowData.units_array).map((el, index) => {
                                                return <span>{el.abbr}</span>
                                            })
                                        }

                                    </div>
                                    : ''
                                }</>
                            },
                            { title: vocabulary.description, field: 'description' },

                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editDataType,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                                disabled: row.id < 2000
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addDataType,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],

                        }}
                    /> : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }


                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item.name)['name_' + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteDataType(item);
                            }

                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                {(openEdit == true || openAdd == true) &&
                    <DataTypeAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={600}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetDataTypeList(setDataTypeList)}
                        setIsLoading={setIsLoading}

                    />}
            </StyledCard>
        </ModalContext.Provider>

    )
}
export default DataTypeList
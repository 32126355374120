/* eslint-disable no-useless-escape*/
/* eslint-disable no-unused-vars*/

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeid } from '../../../../globalsTools/utilities';
// import { loadCitiesByIdCountries, displayWarning } from '../../../actions';

// import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../../globalStyle';
import { Typography, Grid, FormControlLabel, Checkbox } from "@material-ui/core/";
import { ModalContext } from '../../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledInput from "../../../../ui/forms/StyledInput";
import { addVoucher, GetAllSeabexPack, GetUsersList, UpdateVoucher } from "../../../../globalsTools/network";
import { StyledModal } from '../../../../ui/layout'
import StyledModalFooter from '../../../../ui/layout/StyledModalFooter';
import { vocabulary } from '../../../Strings';
import StyledAutoComplete from '../../../../ui/forms/StyledAutoComplete';
import { SettingsBackupRestoreRounded } from '@material-ui/icons';
import { StyledSelect } from '../../../../ui/forms';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';


const VoucherAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const { enqueueSnackbar } = useSnackbar();

    const voucherTypes = ['PAYMENT', 'DISCOUNT'];
    const offerGroups = ['SEABEX_IRRIGATION', 'SATELLITE', 'WEATHER_STATION'];
    const [packList, setPackList] = useState(null)
    const [usersList, setUsersList] = useState(null)
    const [currentItem, setCurrentItem] = useState({
        code: makeid(20),
        user_id: '',
        seabex_pack_id: '',
        details: {
            type: 'DISCOUNT',
            end_date: '',
            value: '',
            included_groups: ['SEABEX_IRRIGATION']
        },
    })
    const [inform, setInform] = useState(false)

    useEffect(() => {


        GetAllSeabexPack(setPackList);
        GetUsersList(setUsersList)

    }, [setPackList, setUsersList])

    useEffect(() => {
        if (item) {
            setCurrentItem(item)

        }
    }, [item, isEditing]);

    const packFilterBygroup = () => {

        return packList?.data.filter(item => {
            if (item?.unique_group_id && currentItem?.details?.included_groups.includes(item?.unique_group_id)) {
                return true;
            }
            return false;
        });
    }


    const renderPackName = (name) => {
        try {
            return JSON.parse(name)[currentLanguage]
        } catch (e) {
            return "N/A"
        }
    }
    const handleResponse = (response, success, status) => {
        if (response) {
            if (success) {

                //snackbarMsg(vocabulary.emailCheckAccount, 'warning');
                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response?.response?.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }
    const handleFormSubmit = (values) => {

        if (isEditing) {
            UpdateVoucher(currentItem.id, {
                code: currentItem.code,
                user_id: currentItem.user_id,
                seabex_pack_id: currentItem.seabex_pack_id,
                details: currentItem.details,
                inform
            }, handleResponse)
        } else {
            addVoucher({
                code: currentItem.code,
                user_id: currentItem.user_id,
                seabex_pack_id: currentItem.seabex_pack_id,
                details: currentItem.details,
                inform
            }, handleResponse)
        }
    }

    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewVoucher : vocabulary.EditVoucher}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>

                    {/* <StyledInfoBubble type='info'>{vocabulary.invitationVoucherWarning}</StyledInfoBubble> */}

                    <form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        style={{ ...formStyle }}>

                        <Grid item xs style={{ display: 'flex' }}>
                            <StyledSelect
                                value={currentItem?.details?.type}
                                errors={errors.type}
                                onChange={(event) => {
                                    setCurrentItem({
                                        ...currentItem,
                                        details: {
                                            ...currentItem.details,
                                            type: event.target.value
                                        }
                                    })
                                }}

                                data={voucherTypes}
                                name='type'
                                label={vocabulary.type}
                                autoWidth
                                styles={{ width: `100%` }}
                            />

                        </Grid>

                        <Grid item xs style={{ display: 'flex' }}>

                            <StyledAutoComplete
                                data={offerGroups}
                                label={vocabulary.datatype}
                                optionLabel={(option) => option}
                                styles={{ width: '100%' }}
                                multiple={true}
                                onChange={(event, newValue) => {

                                    setCurrentItem({
                                        ...currentItem,
                                        details: {
                                            ...currentItem.details,
                                            included_groups: newValue
                                        }
                                    })
                                }}
                                defaultValue={currentItem?.details?.included_group}

                            />


                        </Grid>

                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            {
                                packList && <Grid item xs style={{ display: 'flex' }}>

                                    <StyledAutoComplete
                                        data={packFilterBygroup() ?? []}
                                        optionLabel={(option) => renderPackName(option.name)}
                                        onChange={(event, newValue) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                seabex_pack_id: newValue?.id
                                            })
                                        }}
                                        label={vocabulary.seabexOffers}
                                        styles={{ width: `100%` }}
                                    />
                                </Grid>
                            }
                            {
                                usersList && <Grid item xs style={{ display: 'flex' }}>

                                    <StyledAutoComplete
                                        data={usersList ?? []}
                                        optionLabel={(option) => option?.email || "Unknown Email"}
                                        onChange={(event, newValue) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                user_id: newValue?.id
                                            })
                                        }}
                                        label={vocabulary.owner}
                                        styles={{ width: `100%` }}
                                    />
                                </Grid>
                            }

                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="code"
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.code}
                                    label={vocabulary.code}
                                    value={currentItem?.code}
                                    onChange={(event) => {
                                        console.log("newValue", event.target.value);
                                        setCurrentItem({
                                            ...currentItem,
                                            code: event.target.value
                                        })
                                    }}
                                    type="text"
                                    fullWidth
                                />
                                <SettingsBackupRestoreRounded onClick={() => {

                                    setCurrentItem({
                                        ...currentItem,
                                        code: makeid(20)
                                    })
                                }
                                } />
                            </Grid>

                        </Grid>


                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            {
                                currentItem.details['type'] == 'DISCOUNT' && (
                                    <Grid item xs style={{ display: 'flex' }}>
                                        <StyledInput

                                            errors={errors.percent}
                                            register={register}
                                            rules={{ required: true }}
                                            style={{ marginTop: "-7px" }}
                                            name="percent"
                                            value={currentItem?.details?.percent}
                                            onChange={(event) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    details: {
                                                        ...currentItem.details,
                                                        percent: event.target.value
                                                    }
                                                })
                                            }}
                                            label={vocabulary.value}
                                            type="text"
                                            fullWidth

                                        />

                                    </Grid>
                                )
                            }
                            <Grid item xs={12}>
                                <DatePicker

                                    errors={errors.end_date}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="end_date"
                                    value={currentItem?.details?.end_date}
                                    onChange={(date) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            details: {
                                                ...currentItem.details,
                                                end_date: date
                                            }
                                        })
                                    }}

                                    minDate={new Date()}
                                    label={vocabulary.endDate}
                                    format={currentLanguage === 'fr' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                    fullWidth

                                />

                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex' }}>
                                <StyledInput

                                    errors={errors.value}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="message"
                                    value={currentItem?.details?.message}
                                    onChange={(event) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            details: {
                                                ...currentItem.details,
                                                message: event.target.value
                                            }
                                        })
                                    }}
                                    label={vocabulary.otherInformations}
                                    type="text"
                                    fullWidth

                                />

                            </Grid>
                            {
                                currentItem?.user_id && (
                                    <Grid item xs style={{ display: 'flex' }}>

                                        <FormControlLabel
                                            errors={errors.inform}
                                            register={register}
                                            rules={{ required: true }}
                                            control={
                                                <Checkbox
                                                    checked={inform}
                                                    onChange={(event, newValue) => setInform(newValue)}
                                                    name="debase"
                                                    color="primary"
                                                />
                                            }
                                            label={vocabulary.send}
                                        />

                                    </Grid>
                                )
                            }
                        </Grid>



                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}
export default VoucherAddForm
/* eslint-disable no-useless-concat*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq*/
/* eslint-disable no-unreachable*/
/* eslint-disable no-unused-vars*/

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import { vocabulary } from '../Strings';
import { StyledMaterialTable } from '../../ui/display';
import RouterIcon from '@material-ui/icons/Router';
import AccessIcon from '@material-ui/icons/Accessibility';
import ComputerIcon from '@material-ui/icons/Computer';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { Chip } from '@material-ui/core/';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import PropTypes from 'prop-types';
import EventDetails from './EventDetails'
import { GetEventsByAreaId, GetEventsByDeployedDeviceId } from "../../globalsTools/network";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockCloseIcon from '@material-ui/icons/Lock';
import ErrorIcon from '@material-ui/icons/Error';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import SettingsInputSvideoSharpIcon from '@material-ui/icons/SettingsInputSvideoSharp';

import engineering from '../../images/engineering.png';

import EventHorizantalTimeLine from './EventHorizantalTimeLine'
import { stylesThemed } from '../globalStyle';

const EventsListTable = ({ deviceId, startGateData, selectedAreaId, dateFrom, dateTo, type }) => {

    const classes = stylesThemed();

    const moment = require('moment');
    const tableRef = React.createRef();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [eventList, setEventList] = useState(null);
    const [eventChartList, setEventChartList] = useState(null);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        if (startGateData) {
            tableRef.current.onQueryChange();  // refresh table after any change in startGateData value (refetch the data)
        }

    }, [startGateData]);

    const eventType = (type) => {
        switch (type) {
            case 'ERROR':
                return <Chip
                    icon={<ErrorIcon className={classes.textErrorColor} />}
                    label={type}
                    className={[classes.chip, classes.colorEroorType, classes.textErrorColor]}
                    variant="outlined"
                />
                break;
            case "CONNECT":
                return <Chip
                    icon={<SettingsInputAntennaIcon className={classes.textConnectColor} />}
                    label={type}
                    className={[classes.chip, classes.colorConnectType, classes.textConnectColor]}
                    variant="outlined"

                />
                break;
            case 'EXECUTION':
                return <Chip
                    icon={<SlowMotionVideoIcon className={classes.textExcutionColor} />}
                    label={type}
                    variant="outlined"
                    className={[classes.chip, classes.colorExcutionType, classes.textExcutionColor]}
                />
                break
            case "COMMAND" || "command":
                return <Chip
                    icon={<SettingsInputSvideoSharpIcon className={classes.textCommandColor} />}
                    label={type.toUpperCase()}
                    className={[classes.chip, classes.colorCommandType, classes.textCommandColor]}
                    variant="outlined"

                />
                break
            case 'AutomationPlanExecution':
                return <Chip
                    icon={<img alt='engineering' src={engineering} />}
                    label={type}
                    variant="outlined"
                    className={[classes.chip, classes.colorAutomationPlanExecution, classes.textAutomationPlanExecution]}
                />
                break
            default:
                return type
                break;
        }
    }
    const EventReasonDetail = (eventDetail) => {
        switch (eventDetail.type) {
            case 'EXECUTION':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {eventDetail.reason == 'UserAction' ?
                        eventDetail.state == 'true' ? vocabulary.youHaveActivatedLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                            + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.theLabel + ' ' + moment(eventDetail.start_time).format('DD-MM-YYYY')
                            + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                            : vocabulary.youHaveDesactivatedLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                            + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + vocabulary.theLabel + moment(eventDetail.start_time).format('DD-MM-YYYY')
                            + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                        : 'test'
                    }

                </span>
                break;
            case 'ERROR':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {vocabulary.errorOccuredOnLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                        + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.onTheEquipmentLabel + ' ' + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + ' ' + moment(eventDetail.start_time).format('DD-MM-YYYY')
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')

                    }
                </span>
                break;
            case 'CONNECT':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {vocabulary.youAreConnectedLabel + ' ' + vocabulary.onTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.theLabel + ' ' + moment(eventDetail.start_time).format('DD-MM-YYYY')
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                    }
                </span>
                break;
            case 'COMMAND':

                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {vocabulary.sentACommandLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                        + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.onTheEquipmentLabel + " " + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + ' ' + moment(eventDetail.start_time).format('DD-MM-YYYY')
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')

                    }
                </span>
                break;
            case 'AutomationPlanExecution':
                return <span style={{ fontSize: 'large', color: '#03465b' }}>
                    {eventDetail.state === "true" || eventDetail.state === "1" ?
                        vocabulary.youOpenedLabel + ' ' + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                        + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + '  ' + vocabulary.onTheEquipmentLabel + " " + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + ' ' + moment(eventDetail.start_time).format('DD-MM-YYYY')
                        + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                        : eventDetail.state === "false" || eventDetail.state === "0" ?
                            vocabulary.youClosedLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.port + ' '
                            + eventDetail.port + ' ' + vocabulary.ofTheDeviceLabel + ' ' + eventDetail.deployed_device.name + ' ' + vocabulary.onTheEquipmentLabel + " " + JSON.parse(eventDetail.equipment.name).name_fr + ' ' + vocabulary.theLabel + " " + moment(eventDetail.start_time).format('DD-MM-YYYY')
                            + ' ' + vocabulary.atTimeLabel + " " + moment(eventDetail.start_time).format('HH:mm:ss')
                            : '--'
                    }
                </span>
                break;
            default:
                return eventDetail.type;

        }
    }

    const mapData = () => {

        const eventDetail = [];

        if (eventList) {

            eventList.map(e => {
                eventDetail.push(
                    {
                        date: currentLanguage == "en" ? moment(e.start_time).locale(currentLanguage).format('MMMM Do YYYY') : moment(e.start_time).locale(currentLanguage).format(' Do MMMM  YYYY'),
                        s: vocabulary.atTimeLabel + ' ' + moment(e.start_time).format('HH:mm:ss'),
                        name: EventReasonDetail(e),
                        type: eventType(e.type),

                    }
                )
                    ;
                return true
            })
            return eventDetail
        }
    }

    return (
        <>

            {eventList && eventList.length > 0 &&
                <EventHorizantalTimeLine
                    data={mapData()}

                />
            }

            <StyledMaterialTable
                title={vocabulary.Events}
                tableRef={tableRef}
                detailPanel={[
                    {
                        tooltip: 'Show Details',
                        render: ({ rowData }) => {
                        return (
                            <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <EventDetails item={rowData} />
                                {/* // show event by id (detail of the event */}
                            </div>
                        )}
                    }]}
                localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                data={query => new Promise((resolve, reject) => {
                    (deviceId == null ?
                        GetEventsByAreaId(selectedAreaId, dateFrom, dateTo, type, query.pageSize, (query.page + 1))
                        : GetEventsByDeployedDeviceId(deviceId, dateFrom, dateTo, type, query.pageSize, (query.page + 1))
                    )
                        .then(result => {

                            if (result && result.data) {

                                setEventList(result.data.data)
                                setEventChartList(result.data.data)
                                setPageSize(result.data.per_page)

                                resolve({

                                    data: result.data.data,
                                    page: result.data.current_page - 1,
                                    totalCount: result.data.total,
                                    pageSize: result.data.per_page,
                                })
                                window.scrollTo(0, 0);
                            }

                        })
                })
                }

                columns={[
                    { title: vocabulary.deployedDevices, align: 'center', field: 'deployed_device', render: rowData => <>{rowData.deployed_device ? rowData.deployed_device.name ? rowData.deployed_device.name : rowData.deployed_device.identifier : '--'}</> },
                    { title: vocabulary.port, align: 'center', field: 'port', render: rowData => <>{rowData.port ? rowData.port : '--'}</> },
                    { title: vocabulary.equipmentLabel, align: 'center', field: 'equipment', render: rowData => <>{rowData.equipment ? (JSON.parse(rowData.equipment.name).name_fr) + ' ( ' + rowData.equipment.reference + ' )' : '--'}</> },
                    {
                        title: vocabulary.action, align: 'center', field: 'state', render: row => <>
                            {
                                row.state === "true" || row.state === "1" ?
                                    <Chip
                                        icon={<LockOpenIcon className={classes.textLockOpenColor} />}
                                        label={vocabulary.open}
                                        className={[classes.chip, classes.colorLockOpenType, classes.textLockOpenColor]}
                                        variant="outlined"

                                    />
                                    : row.state === "false" || row.state === "0" ?
                                        <Chip
                                            icon={<LockCloseIcon className={classes.textErrorColor} />}
                                            label={vocabulary.close}
                                            className={[classes.chip, classes.colorEroorType, classes.textErrorColor]}
                                            variant="outlined"

                                        />
                                        : '--'
                            }
                        </>
                    },

                    {
                        title: vocabulary.reason, align: 'center', field: 'reason', render: row => <div >
                            {(row.reason === "GETMODEAUTO" && row.state === "true") ?
                                <Chip
                                    icon={<ComputerIcon className={classes.colorTextChips} />}
                                    label={vocabulary.automaticMode}
                                    className={[classes.chip, classes.colorAutoMode, classes.colorTextChips]}
                                />
                                : (row.reason === "GETMODEAUTO" && row.state === "false") ?
                                    <Chip
                                        icon={<AccessIcon className={classes.colorTextChips} />}
                                        label={vocabulary.assistedMode}
                                        className={[classes.chip, classes.colorMonitoring, classes.colorTextChips]}
                                    />
                                    : (row.reason === "AutomationPlan") ?
                                        <Chip
                                            icon={<RouterIcon className={classes.colorTextChips} />}
                                            label={vocabulary.automationPlan}
                                            className={[classes.chip, classes.colorAutomationplan, classes.colorTextChips]}
                                        />
                                        : (row.reason === "UserAction") ?
                                            <Chip
                                                icon={<TouchAppIcon className={classes.assistedTextColor} />}
                                                label={vocabulary.AssistedAction}
                                                className={[classes.chip, classes.colorAssistedAction, classes.assistedTextColor]}
                                                variant="outlined"

                                            />
                                            :
                                            '--'

                            }
                        </div>
                    },
                    { title: vocabulary.timeLabel, align: 'center', field: 'start_time', render: rowData => <>{currentLanguage == "en" ? rowData.start_time && moment(rowData.start_time).format('YYYY-MM-DD HH:mm:ss') : rowData.start_time && moment(rowData.start_time).format('DD-MM-YYYY HH:mm:ss')}</> },
                    // { title: vocabulary.endTime, field: 'end_time', render: rowData => <>{rowData.end_time && moment(rowData.endTime).format('YYYY-MM-DD HH:mm')}</> },
                    {
                        title: vocabulary.type, align: 'center', field: 'type', render: rowData => {
                            return <div>
                                {
                                    eventType(rowData.type)
                                }
                            </div>
                        }
                    },

                ]}

                options={{
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 40, 60, 80, 100, 200, 500, 1000],
                    actionsColumnIndex: -1,
                    search: false

                }}
            />


        </>

    )
}
// EventsListTable.propTypes = {
//     classes: PropTypes.object.isRequired,
// };
export default EventsListTable

/* eslint-disable  no-eval*/
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    CardContent,
    Chip,
    CardHeader,
    Button,
} from '@material-ui/core';
import { StyledCard } from '../../../ui/layout';
import { vocabulary } from '../../Strings';
import { styles } from '../../globalStyle';
import { withStyles } from '@material-ui/styles';
import { Bar } from '@nivo/bar';
import SeabexOffers from '../../Offers/SeabexOffers';
import { GetAreaDetails, GetSoilType } from '../../../globalsTools/network';
import {
    SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE,
    SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON,
    SOIL_TEXTURE_TYPE_NETIRRIG,
    SOIL_TEXTURE_TYPE_SOILGRID,
} from '../../globalConsts';
import { useSelector } from 'react-redux';
import { logger } from '../../../globalsTools/utilities';
import { Satellite } from '@material-ui/icons';

var _ = require('lodash');

const SiteDetails = ({
    id,
    type,
    name,
    description,
    recommendedsol,
    size,
    stations,
    childs,
    deployed_devices,
    area_deployed_device_equipment,
    staticmap,
    classes,
    taux_cailloux,
    maximum_rooting,
    soil_texture,
    meteo_station
}) => {
    const [soilTypeStructure, setSoilTypeStructure] = useState(null);
    const [showOffers, setShowOffers] = useState(false);
    const [soilTexture, setSoilTexture] = useState(null);
    const [prmSol, setPrmSol] = useState(null);

    const currentLanguage = useSelector(
        (state) => state._globalState.preferred_language
      );

    useEffect(() => {
        if (recommendedsol) {
            let data = [];

            JSON.parse(recommendedsol).map((item, index) => {
                let { depth, sol, elements } = item;
                data.push({ depth, sol, ...elements });
            });

            _.reverse(data);
            setSoilTypeStructure(data);
        }
    }, [recommendedsol]);

    useEffect(() => {
        if (soil_texture) {
            let soil_texture_parsed = JSON.parse(soil_texture);
            if (soil_texture_parsed) {
                setSoilTexture(soil_texture_parsed);
            }
        }
    }, [soil_texture]);

    useEffect(() => {
      if (soilTexture && soilTexture.type === SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE) {
         GetSoilType(soilTexture.data, setPrmSol)
      } else {
        if (soilTexture) {
            logger('Soil Texture Bug', soilTexture, typeof soilTexture.data=='string' ? JSON.parse(soilTexture.data) : typeof soilTexture.data=='object' && soilTexture.data)
        }
       }

    }, [soilTexture])
    

    const keys = ['Argile', 'Limon', 'Sable'];
    const commonProps = {
        width: 600,
        height: 300,
        margin: { top: 0, right: 80, bottom: 60, left: 80 },
        indexBy: 'depth',
        keys,
        // padding: 0.2,
        layout: 'horizontal',
        labelTextColor: 'inherit:darker(1.4)',
        labelSkipWidth: 16,
        labelSkipHeight: 16,
        legends: [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1,
                        },
                    },
                ],
            },
        ],
        colors: { scheme: 'nivo' },

        defs: [
            {
                id: 'argilePattern',
                type: 'patternLines',
                spacing: 3,
                rotation: 40,
                lineWidth: 1,
                background: '#ffffff',
                color: '#b38266',
            },
            {
                id: 'limonPattern',
                type: 'patternDots',
                size: 3,
                padding: 1,
                stagger: false,
                background: '#ffffff',
                color: '#bfbfbf',
            },
            {
                id: 'sablePattern',
                type: 'patternDots',
                size: 2,
                padding: 0,
                stagger: true,
                background: '#ffffff',
                color: '#ffd70f',
            },
        ],
        fill: [
            // match using object query
            { match: { id: 'Argile' }, id: 'argilePattern' },
            { match: { id: 'Limon' }, id: 'limonPattern' },
            { match: { id: 'Sable' }, id: 'sablePattern' },
            // match using function
            // { match: d => d.id === 'vue', id: 'gradientB' },
            // match all, will only affect 'elm', because once a rule match,
            // others are skipped, so now it acts as a fallback
            // { match: '*', id: 'gradientC' },
        ],
    };

    return (
        <>
            <StyledCard>
                <CardContent style={{ flexGrow: 1 }}>
                    <CardHeader title={vocabulary.onepage_areaDetail} />
                    {/* <Chip label={vocabulary[type]} color='primary' className={eval(`classes.chip${_.capitalize(type)}`)} /> */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            flexGrow: 1,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    style={{ textAlign: 'center' }}
                                >
                                    {_.startCase(name)}
                                </Typography>
                            </div>

                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {vocabulary.size + ' '}{' '}
                                            {vocabulary.hectar}
                                        </TableCell>
                                        <TableCell alignt='right'>
                                            {size &&
                                                Number(size / 10000).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {vocabulary.sousArea}
                                        </TableCell>
                                        <TableCell>
                                            {childs && Array.isArray(childs)
                                                ? childs.length
                                                : 0}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {vocabulary.numberDeployedDevices}
                                        </TableCell>
                                        <TableCell>
                                            {deployed_devices &&
                                            Array.isArray(deployed_devices)
                                                ? deployed_devices.length
                                                : 0}
                                        </TableCell>
                                    </TableRow>
                                    {meteo_station && <TableRow>
                                        <TableCell>
                                            Station Météo la plus proche
                                        </TableCell>
                                        <TableCell>
                                            {meteo_station.name + ' (' + Math.round(Number(meteo_station.distance)) + ' km)'}
                                        </TableCell>
                                    </TableRow>}

                                    <TableRow>
                                        <TableCell
                                            colSpan={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Button startIcon={<Satellite />}
                                                onClick={() =>
                                                    setShowOffers(true)
                                                }
                                                color='primary'
                                                variant='contained'
                                            >
                                                {vocabulary.seabexSatelliteImagesOffers}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    
                                </TableBody>
                            </Table>
                        </div>

                        {soilTexture ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    variant='h6'
                                >
                                    {vocabulary.soilStructure}
                                </Typography>

                                {(soilTexture.type ===
                                    SOIL_TEXTURE_TYPE_SOILGRID ||
                                    soilTexture.type ===
                                        SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON) && (
                                    <>
                                        <Bar
                                            {...commonProps}
                                            data={typeof soilTexture.data=='string' ? JSON.parse(soilTexture.data) : typeof soilTexture.data=='object' && soilTexture.data}
                                        />

                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        Profondeur Maximale de
                                                        Sol Exploitable
                                                    </TableCell>
                                                    <TableCell>
                                                        {maximum_rooting > 0
                                                            ? maximum_rooting +
                                                              ' cm'
                                                            : vocabulary.dontKnow}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </>
                                )}

                                {soilTexture.type ===
                                    SOIL_TEXTURE_TYPE_NETIRRIG && (
                                    <div>
                                        <div
                                            style={{
                                                margin: 5,
                                                border: `solid 0.5px #e5e5e5`,
                                                padding: 12,
                                                borderRadius: 12,
                                                backgroundColor: '#f9f7f3',
                                            }}
                                        >
                                            <h5>{soilTexture.data.sd_nom}</h5>
                                            {soilTexture.data.sd_descriptif}
                                        </div>

                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Taux de Cailloux</TableCell>
                                                    <TableCell>{taux_cailloux + ' %'}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{vocabulary.solMaximumDepth}</TableCell>
                                                    <TableCell>{maximum_rooting > 0
                                                            ? maximum_rooting +
                                                              ' cm'
                                                            : vocabulary.dontKnow}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}

                                {soilTexture.type ===
                                    SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE && prmSol && prmSol[0] && (
                                    <div>
                                        <div
                                            style={{
                                                margin: 5,
                                                border: `solid 0.5px #e5e5e5`,
                                                padding: 12,
                                                borderRadius: 12,
                                                backgroundColor: '#f9f7f3',
                                            }}
                                        >
                                            <h5>{JSON.parse(prmSol[0].name)['name_' + currentLanguage]}</h5>
                                        </div>

                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Taux de Cailloux</TableCell>
                                                    <TableCell>{taux_cailloux + ' %'}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{vocabulary.solMaximumDepth}</TableCell>
                                                    <TableCell>{maximum_rooting > 0
                                                            ? maximum_rooting +
                                                              ' cm'
                                                            : vocabulary.dontKnow}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}
                            </div>
                        ) : (
                            'waiting for soil type structure'
                        )}
                        {/* <div style={{ width: 300 }}>
                            <CardMedia image={(staticmap != null) ? 'data:image/png;base64, ' + (staticmap) : null}
                                style={{ height: (staticmap != null) ? 200 : 0, width: (staticmap != null) ? 300 : 0 }} />
                        </div> */}
                    </div>
                </CardContent>
            </StyledCard>
            <SeabexOffers
                area_id={id}
                open={showOffers}
                withoutContext={true}
                closeFn={() => setShowOffers(false)}
            />
        </>
    );
};

export default withStyles(styles)(SiteDetails);

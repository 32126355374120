/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { createSoilTypeByFamily, UpdateSoilTypeByFamily } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";

const SoilTypeAddForm = ({ isEditing, id_texturesols, onClose, item, open, width, refetchFunc, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [englishName, setEnglishName] = useState(null)
    const [frenshName, setFrenshName] = useState(null)
    const [arabicName, setArabicName] = useState(null)
    const [usefulReserve, setUsefulReserve] = useState(null)
    const [solTexture, setSolTexture] = useState(null)
    const [apparentDensity, setApparentDensity] = useState(null)
    const [useful_water, setUseful_water] = useState(null)
    const [fieldCapacity, setFieldCapacity] = useState(null)
    const [wiltingPoint, setWiltingPoint] = useState(null)
    const [solSensitivity, setSolsensitivity] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    useEffect(() => {
        if (item) {
            setEnglishName(JSON.parse(item.name).name_en)
            setFrenshName(JSON.parse(item.name).name_fr)
            setArabicName(JSON.parse(item.name).name_ar)
            setUsefulReserve(item.useful_reserve)
            setSolTexture(item.soil_texture_class)
            setApparentDensity(item.apparent_density)
            setUseful_water(item.useful_water)
            setFieldCapacity(item.field_capacity)
            setWiltingPoint(item.wilting_point)
            setSolsensitivity(item.soil_sensitivity)
        }

    }, [item])

    useEffect(() => {
        if (isEditing === false) {
            setEnglishName(null)
            setFrenshName(null)
            setArabicName(null)
            setUsefulReserve(null)
            setSolTexture(null)
            setApparentDensity(null)
            setUseful_water(null)
            setFieldCapacity(null)
            setWiltingPoint(null)
            setSolsensitivity(null)

        }

    }, [open, isEditing])

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? JSON.parse(item.name)["name_" + currentLanguage] + "  " + vocabulary.updateSucc : vocabulary.soilType + " " + vocabulary.addedSucc, 'success')
                refetchFunc(id_texturesols && id_texturesols)
                setIsLoading(false)


            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)

            }
        }
    }

    const newSoilType = () => {
        setIsLoading(true)
        if (isEditing === false) {
            createSoilTypeByFamily(
                {
                    name: JSON.stringify({ 'name_en': englishName, 'name_fr': frenshName, 'name_ar': arabicName }),
                    useful_reserve: usefulReserve,
                    soil_texture_class: solTexture,
                    field_capacity: fieldCapacity,
                    useful_water: useful_water,
                    apparent_density: apparentDensity,
                    soil_sensitivity: solSensitivity,
                    wilting_point: wiltingPoint,
                    prm_texture_sol_id: id_texturesols

                }, r => operationCallBack(r)
            )
            refetchFunc()
            onClose()


        }
    }
    const handleUpdateSoilType = () => {
        setIsLoading(true)

        UpdateSoilTypeByFamily(
            {
                id: item.id,
                name: JSON.stringify({ 'name_en': englishName, 'name_fr': frenshName, 'name_ar': arabicName }),
                useful_reserve: usefulReserve,
                soil_texture_class: solTexture,
                field_capacity: fieldCapacity,
                useful_water: useful_water,
                apparent_density: apparentDensity,
                soil_sensitivity: solSensitivity,
                wilting_point: wiltingPoint,
                prm_texture_sol_id: id_texturesols

            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        refetchFunc()
        onClose()
    }


    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleSubmit(newSoilType)() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={width}
                title={isEditing === false ? vocabulary.addSoilType : vocabulary.editSoilType}
            >
                <form
                    ref={form}
                >

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.englishName}
                        name="englishName"
                        label={vocabulary.name_en}
                        type="text"
                        fullWidth
                        onChange={(event) => { setEnglishName(event.target.value) }}
                        value={englishName}
                    // defaultValue={item && item.name && JSON.parse(item.name).name_en}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.frenshName}
                        name="frenshName"
                        label={vocabulary.name_fr}
                        type="text"
                        fullWidth
                        onChange={(event) => { setFrenshName(event.target.value) }}
                        value={frenshName}
                    //defaultValue={item && item.name && JSON.parse(item.name).name_fr}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.arabicName}
                        name="arabicName"
                        label={vocabulary.name_ar}
                        type="text"
                        fullWidth
                        onChange={(event) => { setArabicName(event.target.value) }}
                        value={arabicName}
                    // defaultValue={item && item.name && JSON.parse(item.name).name_ar}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.useful_Reserve}
                        name="useful_Reserve"
                        label={vocabulary.useful_Reserve}
                        type="text"
                        fullWidth
                        //defaultValue={item && item.useful_reserve}
                        onChange={(event) => { setUsefulReserve(event.target.value) }}
                        value={usefulReserve}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.classeTexture}
                        name="classeTexture"
                        label={vocabulary.solTexture}
                        type="text"
                        fullWidth
                        // defaultValue={item && item.soil_texture_class}
                        onChange={(event) => { setSolTexture(event.target.value) }}
                        value={solTexture}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.densiteApparente}
                        name="densiteApparente"
                        label={vocabulary.densite_apparente}
                        type="text"
                        fullWidth
                        // defaultValue={item && item.apparent_density}
                        onChange={(event) => { setApparentDensity(event.target.value) }}
                        value={apparentDensity}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.eau_utile}
                        name="eau_utile"
                        label={vocabulary.eau_utile}
                        type="text"
                        fullWidth
                        // defaultValue={item && item.useful_water}
                        onChange={(event) => { setUseful_water(event.target.value) }}
                        value={useful_water}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.fieldCapacity}
                        name="fieldCapacity"
                        label={vocabulary.humidite_capacite_au_champ}
                        type="text"
                        fullWidth
                        //defaultValue={item && item.field_capacity}
                        onChange={(event) => { setFieldCapacity(event.target.value) }}
                        value={fieldCapacity}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.wiltingPoint}
                        name="wiltingPoint"
                        label={vocabulary.humidite_au_point_fletrissement}
                        type="text"
                        fullWidth
                        // defaultValue={item && item.wilting_point}
                        onChange={(event) => { setWiltingPoint(event.target.value) }}
                        value={wiltingPoint}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.solSensitivity}
                        name="solSensitivity"
                        label={vocabulary.sensibilite}
                        type="text"
                        fullWidth
                        // defaultValue={item && item.soil_sensitivity}
                        onChange={(event) => { setSolsensitivity(event.target.value) }}
                        value={solSensitivity}
                    />

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={JSON.parse(item.name).name_ar ? JSON.parse(item.name)['name_' + currentLanguage] : JSON.parse(item.name).name_en}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateSoilType();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default SoilTypeAddForm

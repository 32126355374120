/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { CreateFiancialCategory, UpdateFiancialCategory, GetFinancialCategories } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress'


const AddFinancialResourceForm = ({ item, onClose, open, isEditing, width, refetchFunc, setIsLoading, expensesProp }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const userId = useSelector(state => state.app.userid)
    const [financialCategorieList, setFinancialCategorieList] = useState(null)
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [financialTypeId, setFinancialTypeId] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);


    useEffect(() => {
        GetFinancialCategories(setFinancialCategorieList)

    }, []); // N’exécute l’effet qu'a la première render

    useEffect(() => {

        if (isEditing === false) {
            setTitle(null)
            setDescription(null)
            setFinancialTypeId(null)
        }

    }, [open, isEditing]);
    useEffect(() => {
        if (item) {

            GetFinancialCategories(setFinancialCategorieList)
            setTitle(item.title)
            setDescription(item.description)
            setFinancialTypeId(item.parent_financial_category && item.parent_financial_category.id)
        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const validForm = () => {
        if (!title || !description) {
            return true
        }
        else return false

    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? item.title + ' ' + vocabulary.updateSucc : vocabulary.financialCategoryLabel + ' ' + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)
            }

            else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                setIsLoading(false)
            }
        }
    }
    const handleAddFinancialCategory = () => {

        setIsLoading(true)

        CreateFiancialCategory(
            {
                title: title,
                description: description,
                user_id: userId,
                parent_id: financialTypeId
            }, r => operationCallBack(r)
        )
        onClose()


    }
    const handleUpdateFinancialResource = () => {
        setIsLoading(true)

        UpdateFiancialCategory(
            {
                id: item.id,
                title: title,
                description: description,
                user_id: userId,
                parent_id: financialTypeId
            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        onClose()

    }
    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleAddFinancialCategory() : setOpenEditConfirmation(true), disabled: isEditing ? false : validForm() },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => expensesProp ? onClose() : modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={width}
                modalContext={modalContext}
                closeFn={expensesProp ? onClose : undefined}
                title={isEditing === false ? vocabulary.add + ' ' + vocabulary.financialCategoryLabel : vocabulary.editLabel + ' ' + vocabulary.financialCategoryLabel}
            >
                {
                    ((isEditing == true && financialCategorieList && financialCategorieList.data.length > 0) || isEditing == false) ?
                        <form
                            ref={form}
                            onSubmit={handleSubmit(handleAddFinancialCategory)}
                            onError={errors => console.log(errors)}>

                            <StyledInput
                                value={title}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.title}
                                onChange={(event) => { setTitle(event.target.value) }}
                                name='title'
                                type="text"
                                fullWidth
                                label={vocabulary.titleLabel}
                            />
                            <StyledInput
                                register={register}
                                name="description"
                                label={vocabulary.description}
                                value={description}
                                rules={{ required: true }}
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                                errors={errors.description}
                                maxRows={10}
                                fullWidth
                                multiline={true}

                            />

                            <StyledAutoComplete
                                styles={{ width: '100%' }}
                                data={financialCategorieList ? financialCategorieList.data : []}
                                optionLabel={(option) => option.title !== null ? option.title : ""}
                                defaultValue={isEditing == true && item.parent_financial_category}
                                onChange={(event, newValue) => {
                                    setFinancialTypeId(newValue !== null ? newValue.id : null)
                                }}
                                disabled={financialCategorieList === null ? true : false}

                                label={vocabulary.financialCategoryParent}

                            />

                            <StyledModalFooter actions={actions} />

                        </form>
                        : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }


            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.title}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateFinancialResource();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />
            }
        </>
    )
}
export default AddFinancialResourceForm
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { envKeys } from '../../../config'
import { ProcessPaymentRequest, ConfirmPaymentProcessedPaymee } from '../../../globalsTools/network'
import { logger } from '../../../globalsTools/utilities'
import { StyledSubmitButton } from '../../../ui/forms'
import { PAYMENT_PROVIDER_PAYMEE, REQUEST_TYPE_GET_TOKEN } from '../../globalConsts'
import { vocabulary } from '../../Strings'
import PaymentSuccess from '../assets/PaymentSuccess'

const PaymeePaymentForm = ({ cart, total, onSuccessFn, billingDetails, onFailureFn }) => {

    const [paymeeToken, setPaymeeToken] = useState()
    const [orderId, setOrderId] = useState()
    const [completed, setCompleted] = useState(false)
    const [paymentDone, setPaymentDone] = useState(false)
    const [transactionId, setTransactionId] = useState()

    const currency = useSelector( (state) => state._globalState.currency );

    const handleTokenRequest = (response, isSuccess, httpResponse) => {
        if (isSuccess) {
            logger(['Paymee Token Response', response.data.payment_token])
            setPaymeeToken(response.data.payment_token);
            setOrderId(response.data.order_id);
        }
      };

    const handleConfirmRequest = (response, isSuccess, httpResponse) => {
        if (isSuccess) {
            setPaymentDone(true)
            setTransactionId(response.transaction_id)
        }
    }


    const processPaymeeConfirmation = useCallback(
        (event) => {
            if(event.data.event_id === 'paymee.complete') {
                //Execute Step 3
                setCompleted(true)
            }
        },
      [],
    )
    

    useEffect(() => {
        ProcessPaymentRequest(        cart,
                {   type: PAYMENT_PROVIDER_PAYMEE, 
                    request_type: REQUEST_TYPE_GET_TOKEN, 
                }, 
                billingDetails,
                currency, 
                handleTokenRequest
            )
        window.addEventListener('message', processPaymeeConfirmation, false);
        return () => {
            window.removeEventListener('message', processPaymeeConfirmation);
          }
    }, [])


    useEffect(() => {
        if (completed && paymeeToken && orderId)
        {
            logger('Confirming Payment', paymeeToken, orderId)
            ConfirmPaymentProcessedPaymee(paymeeToken, orderId, handleConfirmRequest )
        }
    }, [completed, paymeeToken, orderId])
    
    


    return (

        <>
            { paymeeToken ? 
                    !completed ? 
                        <iframe title='Paymee' src={`${envKeys.paymee.url}/gateway/${paymeeToken}`} style={{ border: 0, width: `100%`, height: 850}}></iframe>
                    :
                        paymentDone ? 
                            <div>
                                <PaymentSuccess /><br/>
                                {vocabulary.paymentSuccessful}<br/>
                                <StyledSubmitButton
                                        submit={false}
                                        label={vocabulary.finishLabel}
                                        onClick={() => onSuccessFn()}
                                    />
                            </div>
                        :
                        <div>{vocabulary.paymentConfirming}</div>
                :
                    <div>{vocabulary.loading}</div>
            }
        </>
    )
}

export default PaymeePaymentForm
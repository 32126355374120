import React from "react";
import { Typography,Button } from "@material-ui/core";

export default ({ btnLabel, label, handleFunction }) => {

    const style = {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '300px',
        alignItems: 'center',

    }

    return (
        <div style={style}>
            <Typography style={{ fontSize: 'large', color: '#001e27' }}>
                {label}
            </Typography>
            <div style={{ marginTop: '20px' }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleFunction()}
                >
                    {btnLabel}
                </Button>
            </div>
        </div>
    )

}


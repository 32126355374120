import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, Badge, Chip } from '@material-ui/core/';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReactGA from 'react-ga'
import { REACT_APP_ENV_VERSION } from '../../config';
// import { DEV_ENVIRONMENT } from '../globalConsts';
// import { DateRange, Movie, MyLocation, ViewList, PieChart, AssignmentInd } from '@material-ui/icons/';


const LinkItem = ({ inclusionArray, exclusionArray, routeTo, icon, vocabularyText, onClick, badge, platforms, disabled=false, nochip=false }) => {

  const userType = useSelector(state => state.app.userrights)


  if(routeTo === window.location.pathname) {
    ReactGA.pageview(window.location.pathname);
  }

  if (exclusionArray && exclusionArray.includes(userType)) {
    return (null);
  }

  if (platforms && !platforms.includes(REACT_APP_ENV_VERSION))
  {
    return (null);
  }


  if ((inclusionArray==null && exclusionArray==null) || (inclusionArray && inclusionArray.includes(userType)) ) {
    return (
      <NavLink to={(routeTo && !disabled) ? routeTo : '#'} onClick={(onClick && !disabled) ? onClick : undefined}>
        <ListItem button disabled={disabled}
          // selected={currentPage === vocabularyText}>
          selected={routeTo === window.location.pathname}>
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText primary={vocabularyText} />
            { badge!=null && !nochip ? <Chip color="primary" label={badge} /> : <>{badge}</> }
        </ListItem>
      </NavLink>
    )  
  } else {
    return (null)
  }

}

export default LinkItem
import React  from 'react';

import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';
import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const  TablePaginationActions =(props)=> {

    const handleFirstPageButtonClick = event => {
        props.onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        props.onPageChange(event, props.page - 1);
    };

    const handleNextButtonClick = event => {
        props.onPageChange(event, props.page + 1);
    };

    const handleLastPageButtonClick = event => {
        props.onPageChange(
            event,
            Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1),
        );
    };


        const {count, page, rowsPerPage } = props;

        return (
            <div style={{ display: "flex", justifyContent: 'space-around' }} >
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label={vocabulary.firstpage}
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label={vocabulary.previouspage}
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label={vocabulary.nextpage}
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label={vocabulary.lastpage}
                >
                    <LastPageIcon />
                </IconButton>
            </div>
        );
    
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export   const TablePaginationActionsWrapped = withStyles(styles)(TablePaginationActions);

import React from 'react';
import { StyledModal } from '../../ui/layout';
import AddArea from '../Sites/AddArea';
import { vocabulary } from '../Strings';


const AddAreaModal = ({ isOpen, onClose, refetchFunc, currentItem }) => {



    return (
        <StyledModal
            open={isOpen}
            closeFn={onClose}
            withoutContext={true}
            title={vocabulary.seabexOffers}
        >
            <AddArea
                width={745}
                closeFn={onClose}
                withoutContext={true}
                isEditing={false}
                refetchFunc={refetchFunc}
                parentArea={undefined}
                item={currentItem}
                initialMap={currentItem?.boundary?.geometry}
                partnerArea={currentItem}
            />
        </StyledModal>

    );
};

export default AddAreaModal;

/* eslint-disable */

import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux';

import { Card, CardHeader, Divider, CardContent, FormControl, InputLabel, Select, MenuItem, Chip, Typography, CardMedia, Button, Grid, withStyles, CircularProgress } from "@material-ui/core"
import { vocabulary } from "../Strings"
import { GetAreasList, GetAreaDetails, GetGatewaysList, GetDeployedDeviceGatewayByAreaId, GetLastRealTimeCaptures } from "../../globalsTools/network";
import left from '../../images/left.png';
import { styles } from '../globalStyle'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import StyledSelect from "../../ui/forms/StyledSelect";

import StyledCircularProgress from '../../ui/display/StyledCircularProgress'
import AreaFilteringInputs from "../Components/AreaFilteringInputs";
var _ = require('lodash');

const FilteringCard = ({ initialAreaId, handleChangeFn, deployedDevice, siteName, initialGetwayID, classes, siteSetter, parcelSetter, sectorSetter, getwayIdSetter, showdetails = true, lastRealtimeData, setAreaId, loading, setLoading }) => {

    // TO BE ADDED WHEN GATEWAY IS INCLUDED
    // const [areaIdInput, setAreaIdInput] = useState(initialAreaId)

    const appLoading = useSelector(state => state.app.appLoading)
    // const { register, handleSubmit, errors } = useForm()

    const [selectedGateway, setSelectedGateway] = useState(null)
    const [getwayID, setGetwayID] = useState('')
    const [stationArray, setStationArray] = useState([])
    const [selectedArea, setSelectedArea] = useState(null)

    const [reset, setReset] = useState(false)

    const resetSelection = () => {
        setReset(!reset);
        setGetwayID('');
        setSelectedArea(null)
        setSelectedGateway(null)
        setStationArray([])
        setAreaId && setAreaId('')
      }

    useEffect(() => {
        setLoading(true)
        GetGatewaysList(setStationArray);
    }, [])

    useEffect(() => {
        if (initialGetwayID != null) {
            setGetwayID(initialGetwayID);
        }
    }, [initialGetwayID])

    useEffect(() => {
        setLoading(false)
        if (getwayID != null && stationArray != null && stationArray.length > 0) {
            setSelectedGateway(stationArray.filter(i => i.identifier === getwayID)[0])
        }
    }, [getwayID, stationArray])

    return (
        <>

            <Card style={{ marginBottom: '10px' }}>

                {showdetails && <>
                    <CardHeader
                        title={vocabulary.selectAreaToMonitor}
                        subheader={vocabulary.filterAdvice}
                    />
                    <Divider />
                </>
                }
                <CardContent>

                    <AreaFilteringInputs
                        initialAreaId={initialAreaId}
                        handleChangeFn={handleChangeFn}
                        deployedDevice={deployedDevice}
                        siteName={siteName}
                        siteSetter={siteSetter}
                        parcelSetter={parcelSetter}
                        sectorSetter={sectorSetter}
                        areaChangeFn={setSelectedArea}
                        lastRealtimeData={lastRealtimeData}
                        setLoading={setLoading}
                        reset={reset}
                        setReset={setReset}
                    />

                    {selectedArea &&
                        <Grid item>
                            <Button style={{ marginTop: '10px' }} color="secondary" variant="contained" onClick={() => { resetSelection() }} >{vocabulary.resetSelection}</Button>
                        </Grid>
                    }

                </CardContent>

                {selectedArea !== null && !loading ?

                    <>
                        <Divider />
                        <CardContent>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ flexDirection: "column", flexGrow: 1, alignItems: 'center' }}>
                                    <Chip label={vocabulary[selectedArea.type]} color='primary' className={eval(`classes.chip${_.capitalize(selectedArea.type)}`)} />
                                    {selectedGateway != null ? <Chip label={(selectedGateway.seabexstation != null ? '(' + selectedGateway.seabexstation.reference + ') - ' : '') + (selectedGateway.name ? _.startCase(selectedGateway.name) : selectedGateway.identifier)} color='primary' /> : null}
                                    <Typography>{vocabulary.selectedArea}</Typography>
                                    <Typography variant="h3" style={{ textAlign: 'center' }}>{_.startCase(selectedArea.name)}</Typography>
                                    <Typography>{_.startCase(selectedArea.description) + ' (' + Number(selectedArea.size / 10000).toFixed(2) + ' ' + vocabulary.hectar + ') - ' + (selectedArea.deployed_devices ? selectedArea.deployed_devices.length : 0) + ' ' + vocabulary.stations}</Typography>
                                    {/* // TODO:  ADD NUMBER OF ACK AND ACT STATIONS CONNECTED TO THIS AREA */}
                                </div>
                                <div style={{ width: 300 }}>
                                    <CardMedia image={(selectedArea.staticmap != null) ? 'data:image/png;base64, ' + (selectedArea.staticmap) : null}
                                        style={{ height: (selectedArea.staticmap != null) ? 150 : 0, width: (selectedArea.staticmap != null) ? 300 : 0 }} />
                                </div>
                            </div>
                        </CardContent>
                    </>
                    : (loading ?
                        <StyledCircularProgress size={50} label={vocabulary.loading} />
                        : null)

                }


            </Card>

        </>
    )
}

export default withStyles(styles)(FilteringCard)
import { FormControl, TextField } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

export default ({ loading, defaultValue, styles, data, multiple, label, disabled, optionLabel, onChange, getOptionSelected, value, autoComplete = 'new-password', ...props }) => {

    const useStyle = makeStyles((theme) => ({
        root: {
            margin: 8,
            ...styles,
            '& p': {
                fontSize: 12
            }
        },
        '& .MuiListItem': {
            root: {
                '&$selected': {
                    color: 'white',
                    backgroundColor: 'rgba(200, 200, 200, 0.14)',
                    borderLeft: 'solid 4px',
                    // paddingLeft: 12,
                },
                color: '#333333',
            },

        },
    }))
    const classes = useStyle();

    const [idgen, setIdGen] = useState('id_' + Math.floor(Math.random()*10000000))

    

    return (
        <FormControl className={classes.root}>

            <Autocomplete
                id={idgen}
                // style={style}
                options={data}
                getOptionLabel={optionLabel}
                getOptionSelected={getOptionSelected}
                onChange={onChange}
                selectOnFocus
                disabled={disabled}
                multiple={multiple}
                loading={loading}
                defaultValue={defaultValue && defaultValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: autoComplete, // disable autocomplete and autofill
                        }}
                    />
                )}
                {...props}
            />
        </FormControl>

    )
}

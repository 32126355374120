/* eslint-disable*/

import React from 'react';
// import { useDispatch } from 'react-redux';
import { Switch, FormControlLabel } from '@material-ui/core';
// import { setActuator } from '../../actions'

const Actuator = ({sendCommand,port,isActivated,disabled,attachedEquipment}) => {

  // const dispatch = useDispatch()

  const handleChange = name => event => {
    // this.props.setActuator(this.props.port, this.props.isActivated == "1" ? false : true)
    // this.setState({ [name]: event.target.checked });

    sendCommand(port, isActivated == "1" ? false : true)
  };


  return (
    <FormControlLabel
      control={
        <Switch
          checked={isActivated == "1" ? true : false}
          onChange={handleChange('checkedA')}
          value="checkedA"
          disabled={disabled}
        />
      }
      label={attachedEquipment}
    />

    // <div style={{ width: 260, height: 100 }}>
    //   <div className="switch" >
    //     <input type="checkbox" id="control" className="control" checked={this.isActivated=="1"? true : false}
    //       onChange={this.handleChangeInput}
    //     />
    //     <label for="control" className="checkbox"></label>
    //   </div>
    //   <Typography style={{ marginTop: 15 }} variant="title" color="inherit" noWrap>
    //     {"Port" + this.props.port}
    //   </Typography>
    // </div>

  );

}

export default Actuator;
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { Typography } from "@material-ui/core/";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledInput from "../../../ui/forms/StyledInput";
import { organizationAddUserPartners, organizationUserSendJoinRequests } from "../../../globalsTools/network";
import { StyledModal } from '../../../ui/layout';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';


const PartnerAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
 
    const [email, setEmail] = useState('')
 
 

    const { enqueueSnackbar } = useSnackbar();




    useEffect(() => {
        if (isEditing === false) {
            setEmail('')
        }

    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    useEffect(() => {

        if (item) {
            setEmail(item.email)
        }



    }, [item, isEditing]); // N’exécute l’effet que si item a changé

    const operationCallBack = (response, success) => {
        if (response) {
            if (success) {

                snackbarMsg(vocabulary.emailCheckAccount, 'warning');
                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response.response.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }



    const handleUserForm = () => {
        organizationUserSendJoinRequests({ email: email }, operationCallBack)
    }


    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewUser : vocabulary.EditUser}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>


                    <form
                        onSubmit={handleSubmit(handleUserForm)}
                        style={{ ...formStyle }}>


                        <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillTextAddUser}</Typography>


                        <StyledInput
                            register={register}
                            rules={{ required: true, validate: validateEmail }}
                            name="email"
                            label={vocabulary.email}
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            errors={errors.email}
                            disableAutoComplete={true}
                        />

                        {errors.email &&
                            <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                                {vocabulary.mailInvalid}
                            </span>
                        }

                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}

export default PartnerAddForm
/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import { createCultureFamily, UpdateCultureFamily } from "../../../globalsTools/network";
import { useSnackbar } from 'notistack';
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";

const CultureFamilyAddForm = ({ onClose, item, open, width, isEditing, refetchFunc, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [englishName, setEnglishName] = useState(null)
    const [frenshName, setFrenshName] = useState(null)
    const [arabicName, setArabicName] = useState(null)
    const [description, setDescription] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {
        if (isEditing === false) {
            setEnglishName(null)
            setFrenshName(null)
            setArabicName(null)
            setDescription(null)
        }

    }, [open, isEditing])
    useEffect(() => {
        if (item) {
            setEnglishName(JSON.parse(item.name).name_en)
            setFrenshName(JSON.parse(item.name).name_fr)
            setArabicName(JSON.parse(item.name).name_ar)
            setDescription(item.description ? JSON.parse(item.description).description : null)
        }

    }, [item])

    const validForm = () => {
        if (!englishName || !frenshName || !arabicName) {
            return true
        }
        else return false

    }

    const operationCallBack = (r) => {
        if (r) {
            if ([200, 201].includes(r.status)) {
                snackbar(isEditing == true ? JSON.parse(item.name)["name_" + currentLanguage] + "  " + vocabulary.updateSucc : vocabulary.cultureFamily + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }
    const newCultureFamily = () => {
        setIsLoading(true)
        createCultureFamily(
            {
                name: JSON.stringify({ "name_fr": frenshName, "name_en": englishName, "name_ar": arabicName }),
                description: JSON.stringify({ "description": description })
            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        onClose()


    }
    const handleUpdateCultureFamily = () => {
        setIsLoading(true)

        UpdateCultureFamily(
            {
                id: item.id,
                name: JSON.stringify({ "name_fr": frenshName, "name_en": englishName, "name_ar": arabicName }),
                description: JSON.stringify({ "description": description })
            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)

        onClose()
    }
    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? newCultureFamily() : setOpenEditConfirmation(true), disabled: isEditing ? false : validForm() },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={width}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addCultureFamily : vocabulary.editCultureFamily}
            >
                <form
                    onSubmit={handleSubmit(newCultureFamily)}
                    ref={form}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="englishName"
                        label={vocabulary.name_en}
                        type="text"
                        fullWidth
                        errors={errors.englishName}
                        onChange={(event) => { setEnglishName(event.target.value) }}
                        value={englishName}
                    // defaultValue={item && item.name && JSON.parse(item.name).name_en}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        label={vocabulary.name_fr}
                        type="text"
                        name="frenshName"
                        fullWidth
                        errors={errors.frenshName}
                        onChange={(event) => { setFrenshName(event.target.value) }}
                        value={frenshName}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="arabicName"
                        errors={errors.arabicName}
                        label={vocabulary.name_ar}
                        type="text"
                        fullWidth
                        onChange={(event) => { setArabicName(event.target.value) }}
                        value={arabicName}
                    />
                    <StyledInput
                        register={register}
                        name="description"
                        label={vocabulary.description}
                        type="text"
                        errors={errors.description}
                        fullWidth
                        onChange={(event) => { setDescription(event.target.value) }}
                        value={description}
                    />

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={JSON.parse(item.name)['name_' + currentLanguage]}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateCultureFamily();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </> 
    )

}
export default CultureFamilyAddForm

/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { vocabulary } from '../Strings';
import { StyledMaterialTable } from '../../ui/display';
import { setCurrentPage } from '../../actions';
import { DeletePartnerDevice, GetPartnerIntegration } from "../../globalsTools/network";
import AlertDialog from "../Components/alertDialog";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Refresh, Settings } from '@material-ui/icons';

import FieldPartnerListDetails from './FieldPartnerListDetails';
import { useParams } from 'react-router-dom';
import PartnerActivateModal from './partners/modals/PartnerActivateModal';
import AddAreaModal from './AddAreaModal';
import SyncAreaModal from './SyncAreaModal';

const FieldPartnerConfig = () => {


    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)




    const { enqueueSnackbar } = useSnackbar();


    const [item, setItem] = useState(null)

    const [openSyncAreaModal, setOpenSyncAreaModal] = useState(false)
    const [partnerData, setPartnerData] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [openConfigureModal, setOpenConfigureModal] = useState(false)
    let { id } = useParams();






    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.partnerIntegrations));
        document.title = vocabulary.partnerIntegrations

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const handleAddArea = (row) => {
        setOpen(true)
        setItem(row)
    };

    const handleSyncArea = (row) => {
        setOpenSyncAreaModal(true)
        setItem(row)
    };



    const operationCallBack = (r) => {

        if (r && r.status === 204) {

            snackbar(item.device_id + " " + vocabulary.deleteMsg, 'success')
            refreshFn()
        } else {
            snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
        }

    }

    const handleDeleteIntegration = (item) => {

        DeletePartnerDevice(item.id
            , r => operationCallBack(r)
        )
    }

    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };

    const refreshFn = () => {
        setIsLoading(true)
        GetPartnerIntegration(id, (data) => {
            setPartnerData(data)
            setIsLoading(false)
        })
    }



    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setIsLoading(true)
        GetPartnerIntegration(id, (data) => {
            setPartnerData(data)
            setIsLoading(false)
        })
    }, [id])



    return (

        <>
            {
                (!isLoading && partnerData) ?
                    <>

                        <StyledMaterialTable
                            title={vocabulary.partnerIntegrations}
                            data={partnerData ?? []}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                            detailPanel={[
                                {
                                    tooltip: 'Show Details',
                                    render: ({ rowData }) => { return (<FieldPartnerListDetails itemRow={rowData} refreshFn={refreshFn} />) }
                                }]}
                            columns={[
                                { title: vocabulary.name_label, field: 'name' },
                                { title: vocabulary.type, field: 'parent.type' },
                                { title: vocabulary.related_areas, field: 'area', render: row => row.area?.name },
                            ]}
                            actions={[
                                row => ({
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (_, row) => handleSyncArea(row)
                                }),
                                row => ({
                                    icon: () => <AddIcon
                                        fontSize='large'
                                        color={'primary'}
                                    />,
                                    tooltip: vocabulary.addArea,
                                    onClick: (_, row) => handleAddArea(row),
                                    hidden: row.area ? true : false
                                }),
                                {
                                    icon: () => <Settings
                                        fontSize='large'
                                        color={'primary'}
                                    />,
                                    tooltip: vocabulary.Settings,
                                    isFreeAction: true,
                                    onClick: () => setOpenConfigureModal(true),
                                },
                                {
                                    icon: () => <Refresh fontSize='large' color='primary' className="addCycleBtn" />,
                                    tooltip: vocabulary.refreshData,
                                    isFreeAction: true,
                                    onClick: refreshFn
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,

                            }}
                        />


                        {
                            partnerData && openConfigureModal && (
                                <PartnerActivateModal
                                    currentItem={partnerData}
                                    open={openConfigureModal}
                                    setOpen={setOpenConfigureModal}
                                />
                            )
                        }


                        <div>



                            {alertConfirmDelete === true &&
                                <AlertDialog
                                    operation={vocabulary.delete}
                                    open={alertConfirmDelete}
                                    deletedItemName={item.title}
                                    onClose={closeAlertConfirmDelete}
                                    confirmeDelete={() => {
                                        if (item) {
                                            handleDeleteIntegration(item);
                                        }

                                        closeAlertConfirmDelete();
                                    }}

                                    cancel={closeAlertConfirmDelete}
                                />}

                        </div>
                    </>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />

            }


            {
                item && (
                    <AddAreaModal
                        isOpen={open}
                        onClose={onClose}
                        refetchFunc={refreshFn}
                        currentItem={item}
                    />
                )
            }

            <SyncAreaModal
                isOpen={openSyncAreaModal}
                onClose={() => setOpenSyncAreaModal(false)}
                refetchFunc={refreshFn}
                currentItem={item}
            />
        </>
    );

}



export default FieldPartnerConfig;




import React from "react";
import sol_texture_3 from './sol_texture_3.png';
import sol_texture_5 from './sol_texture_5.png';
import sol_texture_7 from './sol_texture_7.png';
import sol_texture_10 from './sol_texture_10.png';
import sol_texture_15 from './sol_texture_15.png';
import sol_texture_20 from './sol_texture_20.png';
import sol_texture_25 from './sol_texture_25.png';

export default ({tx_cailloux, ...properties}) => {

    const textures = { sol_texture_3, sol_texture_5, sol_texture_7, sol_texture_10, sol_texture_15, sol_texture_20, sol_texture_25 }

    return (
        <>
            {
                ('sol_texture_' + tx_cailloux) in textures && <img {...properties} src={textures['sol_texture_' + tx_cailloux]} alt="Taux de Cailloux" /> 
            }
        </>
        
    )

}

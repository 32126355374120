import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SencropActivateForm from '../forms/sencrop/SencropActivateForm';
import BayerActivateForm from '../forms/bayer/BayerActivateForm';
import WeenatActivateForm from '../forms/weenat/WeenatActivateForm';
import { StyledModal } from '../../../../ui/layout';
import StyledModalFooter from '../../../../ui/layout/StyledModalFooter';
import { vocabulary } from '../../../Strings';



const INTEGRATIONS = [
    'SENCROP',
    'WEENAT',
    'BAYER'
]



const  PartnerActivateModal = ({
    isEditing,
    currentItem,
    open,
    setOpen,
    refreshFn
}) => {
 
    const onClose = () => {
        setOpen(false);
    };


    const renderPartnerForm = (partner) => {

        if (partner == INTEGRATIONS[0]) {
            return (
                <SencropActivateForm partner={partner} />
            )
        }
        if (partner == INTEGRATIONS[1]) {
            return (
                <WeenatActivateForm partner={partner} />
            )
        }
        if (partner == INTEGRATIONS[2]) {
            return (
                <BayerActivateForm partner={partner} onClose={onClose} />
            )
        }
    }


    return (
        <div>

            <StyledModal
                open={open}
                closeFn={onClose}
                withoutContext={true}
                title={isEditing === false ? vocabulary.partnerIntegrations : vocabulary.partnerIntegrations}
            >

                <>

                    {renderPartnerForm(currentItem?.partner_id)}




                </>


            </StyledModal>
        </div>
    );
}


export default PartnerActivateModal
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Typography, Button, StepButton, Step, Stepper } from '@material-ui/core';
import CreateInvoice from './FormSteps/CreateInvoice';
import ValidateInvoice from './FormSteps/ValidateInvoice';
import { useForm } from 'react-hook-form';


const initialSteps = ['Create Invoice', 'Validate Invoice']


const InvoiceAddForm = () => {
    // , 'Select Product'
    const [steps, setSteps] = useState(initialSteps);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const { handleSubmit } = useForm()
    const formRef = useRef(null);

    const [options, setOptions] = useState({
        create_customer: false,
        update_customer: false,
        create_product: false,
    })

    const lineItem = {
        product: { source_id: null },
        label: null,
        quantity: null
    }
    const [invoice, setInvoice] = useState({
        customer: { source_id: "4578a3c5-5326-46a9-8356-46e6a3bc121a" },
        line_items: [],
        date: null,
        deadline: null,
        pdf_invoice_free_text: null,
        pdf_invoice_subject: null,
        special_mention: null,
        currency: "EUR",
        draft: true
    })

    const invoiceHandler = (values, order, orderLines) => {
        console.log("Values", values)
        console.log("order", order)
        console.log("orderLines", orderLines)
    }

    const optionsChange = (event) => {

        setOptions({
            ...options,
            [event.target.name]: Boolean(event.target.value)
        });
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        formRef.current.submit()
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();

    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setSteps(initialSteps)
    };
    const renderStep = () => {

        return (
            <CreateInvoice
                formRef={formRef}
                onSubmit={handleSubmit((values, order, orderLines) => invoiceHandler(values, order, orderLines))}
            />
        )
    }


    return (


        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <>

                        <ValidateInvoice />
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                            <Button onClick={handleReset}>send</Button>
                        </Box>
                    </>
                ) : (
                    <>
                        {
                            renderStep()
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button

                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <p>ValidateInvoice</p>
                                ) : (
                                    <Button onClick={handleComplete}>
                                        {completedSteps() === totalSteps() - 1
                                            ? 'Finish'
                                            : 'Complete Step'}
                                    </Button>
                                ))}
                        </Box>
                    </>
                )}
            </div>
        </Box>

    );
}

export default InvoiceAddForm;
import React, { useEffect, useMemo, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import './StripePaymentFormStyle.css';
import { Button, Typography } from '@material-ui/core';
import { vocabulary } from '../../Strings';
import { ProcessPaymentRequest, ConfirmPaymentProcessedStripe } from '../../../globalsTools/network';
import { StyledInput } from '../../../ui/forms';
import { useSnackbar } from 'notistack';
import PoweredByStripeBlurple from '../../../images/stripe/PoweredByStripeBlurple.svg';
import { StyledDialog } from '../../../ui/display/StyledDialog';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT, PAYMENT_PROVIDER_STRIPE } from '../../globalConsts';
import { useSelector } from 'react-redux';
import { logger } from '../../../globalsTools/utilities';
import { envKeys, REACT_APP_ENV_VERSION } from '../../../config';

const useStyles = makeStyles({
  buttonRoot: {
    fontFamily: 'Arial, sans-serif',
    borderRadius: `0 0 10px 10px`,
    border: 0,
    padding: `12px 16px`,
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    display: 'block',
    transition: `all 0.2s ease`,
    boxShadow: `0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)`,
    width: '100%',
    color: '#ffffff',
  },
  buttonActive: {
    background: '#5469d4',
  },
  buttonDisabled: {
    background: 'grey',
  },
  cardElement: {
    borderRadius: `4px 4px 0 0`,
    padding: 12,
    border: `1px solid rgba(50, 50, 93, 0.1)`,
    maxHeight: 44,
    width: `100%`,
    background: 'white',
    boxSizing: `border-box`,
    boxShadow: `none`,
  },
});

/*

[PaymentMethod] 
{id: "pm_1IiVwyK4WeBXWjDfSjnbSOCV", object: "payment_method", billing_details: {…}, card: {…}, created: 1618972680, …}
billing_details:
    address: {city: null, country: null, line1: null, line2: null, postal_code: "45000", …}
    email: null
    name: null
    phone: null
    __proto__: Object
card:
    brand: "visa"
    checks: {address_line1_check: null, address_postal_code_check: null, cvc_check: null}
    country: "US"
    exp_month: 4
    exp_year: 2021
    funding: "credit"
    generated_from: null
    last4: "4242"
    networks:
        available: ["visa"]
        preferred: null
        __proto__: Object
    three_d_secure_usage: {supported: true}
    wallet: null
    __proto__: Object
created: 1618972680
customer: null
id: "pm_1IiVwyK4WeBXWjDfSjnbSOCV"
livemode: false
object: "payment_method"
type: "card"
__proto__: Object

*/

const CheckoutForm = ({ cart, total, onSuccessFn, billingDetails }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirmationDialogStatus, setConfirmationDialogStatus] =
    useState(false);
  const [receiptUrls, setReceiptUrls] = useState([]);

  const currency = useSelector( (state) => state._globalState.currency );

  useEffect(() => {
    logger('billingDetails', billingDetails)
  }, [billingDetails])
  

  // const PAYMENT_TYPE_STRIPE = 'STRIPE';

  const classes = useStyles();

  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            border: 'none',
            background: '#ff0000',
            '::placeholder': {
              color: '#32325d',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
        hidePostalCode: true,
        disabled: disabled,
      }),
      [disabled]
    );

    return options;
  };

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { enqueueSnackbar } = useSnackbar();

  const [fullname, setFullname] = useState(null);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogStatus(false);
    onSuccessFn();
  };

  const translateError = (error, language='fr') => {
    switch (error) {
      case "Your card has insufficient funds.":
        return language=='fr' ? "Votre carte n'a pas suffisamment de fonds." : error
      case "Your card's security code is incorrect.":
        return language=='fr' ? "Le code de sécurité de votre carte est incorrect." : error
      case "Your card was declined.":
        return language=='fr' ? "Votre carte a été refusée." : error
      default:
        return error;
    }
  }

  // TODO: MANAGE 3DS and show HttpStatus Code
  const handlePaymentResponse = (response, isSuccess, httpResponse) => {
    // logger("Handle Payment Response -->", response.response, isSuccess, response.response?.status)
    httpResponse=response && (response.response ?? response)
    logger("HttpResponse", httpResponse)
    if (!isSuccess) {
      if (httpResponse && httpResponse.status === 440) {
        handleCardAction(
          httpResponse.data.payment.payment,
          httpResponse.data.order_id
        );
      } else {
        enqueueSnackbar('Paiement échoué : ' + translateError(httpResponse.data), {variant:'error'});
        setProcessing(false);
      }
    } else {
      paymentConfirmed(response);
    }
  };

  const handleCardAction = (paymentObject, order_id) => {
    logger("Handle Payment Response", paymentObject, order_id)
    stripe
      .confirmCardPayment(paymentObject.client_secret)
      .then((result) => {
        logger("2FA RESULT", result)
        if (result.error) {
          enqueueSnackbar('Authentification échouée !', {variant:'error'});
          setProcessing(false);          
        } else {
          ConfirmPaymentProcessedStripe(
            result.paymentIntent,
            order_id,
            confirmToServerSuccessfulSCA
          ); // ADD ORDER ID
        }
      })
      .catch((e) => {
        logger("2FA Catch Error", e)
        enqueueSnackbar('Transaction échouée !', {variant:'error'});
        setProcessing(false);
      });
  };

  const confirmToServerSuccessfulSCA = (confirmedPaymentIntent, isSuccess) => {
    if (isSuccess) {
      paymentConfirmed(confirmedPaymentIntent);
    } else {
      enqueueSnackbar('Transaction échouée!', {variant:'error'});
    }
  };

  const paymentConfirmed = (data) => {
    enqueueSnackbar('Paiement réussi !', {variant:'success'});
    setSucceeded(true);
    setReceiptUrls(data.data.receipt_url);
    setConfirmationDialogStatus(true);
  };

  const handleSubmit = async () => {
    // Block native form submission.
    // ev.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: fullname,
      },
    });

    if (error) {
      let errorMessage = (REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? 'Paiement échoué pour une raison inconnu. Merci de nous contacter au +33 (0)2 38 69 80 69 ou par email sur <a href="mailto:netirrig@seabex.com">netirrig@seabex.com</a>.'
                                                                     : 'Paiement échoué pour une raison inconnu. Merci de nous contacter au +33 (0)2 38 69 80 69 ou par email sur <a href="mailto:contact@seabex.com">contact@seabex.com</a>.'
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {variant:'error'});
      setProcessing(false)
    } else {
      setError(null);
      // setSucceeded(true);
      // build cart and payment details
      // cart={type:'SEABEX_PACK', id:'48a7f3e0-47d5-4602-8765-f1310f9bd6a3', quantity:, area_id:}
      ProcessPaymentRequest(
        cart,
        { type: PAYMENT_PROVIDER_STRIPE, 
          method: paymentMethod, 
        },
        billingDetails,
        currency, 
        handlePaymentResponse
      );
    }
  };

  return (
    <>
      <div className='StripeElementContainer'>
        <StyledInput styles={{ border: 'none' }}
          rules={{ required: true }}
          label={vocabulary.fullNameOnCard}
          onChange={(event) => setFullname(event.target.value)}
          value={fullname ? fullname : ''}
        ></StyledInput>
        <CardElement onChange={handleChange} options={options} />
        { error && <Typography style={{color:'red'}} variant="p">{error}</Typography>}
      </div>
      <Button
        disabled={!stripe || processing || disabled || succeeded}
        variant='contained'
        className={classNames(
          classes.buttonRoot,
          !stripe || processing || disabled || succeeded
            ? classes.buttonDisabled
            : classes.buttonActive
        )}
        color='primary'
        onClick={() => handleSubmit()}
      >
        {vocabulary.PayerLabel + ' ' + total + ' €'}
      </Button>
      <img
        src={PoweredByStripeBlurple}
        alt='Powered by stripe'
        width={130}
        style={{ marginTop: 12 }}
      />

      <StyledDialog
        title='Payment Confirmation'
        actions={<Button onClick={() => closeConfirmationDialog()}>OK</Button>}
        open={confirmationDialogStatus}
        handleClose={() => closeConfirmationDialog()}
      >
        Votre paiement a été confirmé et votre commande sera traité sous peu.
        <br />
        Votre reçu est accessible sur ce(s) lien(s) :{' '}
        {receiptUrls &&
          receiptUrls.map((receiptUrl, index) => (
            <a
              key={'receiptlinks' + index}
              id={'receiptlinks' + index}
              href={receiptUrl}
              rel='noopener noreferrer'
              target='_blank'
            >
              {' '}
              Reçu N°{index+1}
            </a>
          ))}
        .
      </StyledDialog>
    </>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(envKeys.stripe_key);

const StripePaymentForm = (props) => {
  return (
    <>
    {
      stripePromise && 
      <Elements stripe={stripePromise}>
        <CheckoutForm {...props} />
      </Elements>
    }
    </>
  );
};

export default StripePaymentForm;

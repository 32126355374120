/* eslint-disable eqeqeq*/
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { vocabulary } from '../Strings';
import { useSnackbar } from 'notistack';
import { GetActivitiesList, DeleteActivity, GetActivitiesType, GetAreasList, DeleteActivitiesType } from "../../globalsTools/network";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import AlertDialog from "../Components/alertDialog";
import AddActivityForm from './AddActivityForm'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { setCurrentPage } from '../../actions';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ActivitiesTypeAddForm from '../Admin/ActivitiesManagement/ActivitiesTypeAddForm'

const ActivitiesList = () => {

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');
    const userId = useSelector(state => state.app.userid)

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [activityTypeitem, setActivityTypeitem] = useState(null)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isEditingActivityType, setIsEditingActivityType] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [openActivityType, setOpenActivityType] = useState(false)
    const [openEditActivityType, setOpenEditActivityType] = useState(false)

    const [activitiesList, setActivitiesList] = useState(null)
    const [activitiesType, setActivitiesType] = useState(null)
    const [sitesList, setSitesList] = useState(null)
    const [showActivityTypeByUser, setShowActivityTypeByUser] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.culturalActivities));
        document.title = vocabulary.culturalActivities
        GetActivitiesList(setActivitiesList)
        GetActivitiesType(setActivitiesType)
        GetAreasList(setSitesList)


    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.culturalActivities));
        document.title = vocabulary.culturalActivities

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const HandleShowActivities = () => {
        setOpenActivityType(false)
        setShowActivityTypeByUser(false)
        GetActivitiesList(setActivitiesList)
    }
    const HandleShowActivitiesType = () => {
        setShowActivityTypeByUser(true)
        GetActivitiesType(setActivitiesType)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.title + ' ' + vocabulary.deleteMsg, 'success')
                GetActivitiesList(setActivitiesList)
                GetActivitiesType(setActivitiesType)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteActivity = (item) => {

        showActivityTypeByUser ? DeleteActivitiesType(item.id
            , r => operationCallBack(r)
        ) : DeleteActivity(item.id
            , r => operationCallBack(r)
        )
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const changeModalStatusActivityType = (newStatus) => {
        if (newStatus == null) {
            setOpenEditActivityType(!openEdit)
            setOpenActivityType(!openAdd)

        } else {
            setOpenEditActivityType(newStatus)
            setOpenActivityType(newStatus)
            setIsEditingActivityType(newStatus)
        }
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const onEditActivityTypeByUser = (row) => {
        setOpenEditActivityType(true)
        setActivityTypeitem(row)
        setIsEditingActivityType(true)
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const handleClickOpenActivityTypeForm = () => {
        setOpenActivityType(true)
    };
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    const contextValueAddActivityType = { open: openActivityType, changeModalStatus: changeModalStatusActivityType }
    const contextValueEditActivityType = { open: openEditActivityType, changeModalStatus: changeModalStatusActivityType }

    return (
        <ModalContext.Provider value={showActivityTypeByUser == false ? (isEditing === false ? contextValueAdd : contextValueEdit) : (isEditingActivityType === false ? contextValueAddActivityType : contextValueEditActivityType)}>
            <StyledCard>
                {(!isLoading && activitiesList) ?
                    <StyledMaterialTable
                        title={showActivityTypeByUser ? vocabulary.activityTypeByMe : vocabulary.activitiesList}
                        data={showActivityTypeByUser ? activitiesType.data.filter(el => el.user_id !== null) : activitiesList && activitiesList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                        columns={showActivityTypeByUser == false ? [
                            { title: vocabulary.titleLabel, field: 'title' },
                            { title: vocabulary.description, field: 'description' },
                            { title: vocabulary.area, field: 'area', render: rowData => <>{rowData.area ? rowData.area.parent ? rowData.area.parent.name + ' / ' + rowData.area.name : rowData.area.name : '--'}</> },
                            { title: vocabulary.startDate, field: 'start_date', render: rowData => <>{rowData.start_date ? currentLanguage == "en" ? moment(rowData.start_date).format('YYYY-MM-DD') : moment(rowData.start_date).format('DD-MM-YYYY') : '--'}</> },
                            { title: vocabulary.endDate, field: 'start_date', render: rowData => <>{rowData.end_date ? currentLanguage == "en" ? moment(rowData.end_date).format('YYYY-MM-DD') : moment(rowData.end_date).format('DD-MM-YYYY') : '--'}</> },
                            { title: vocabulary.activityType, field: 'activity_type', render: rowData => <>{rowData.activity_type ? rowData.activity_type.title : '--'}</> },
                            { title: vocabulary.cycleLabel, field: 'production_cycle', render: rowData => <>{rowData.production_cycle ? JSON.parse(rowData.production_cycle && rowData.production_cycle.prm_culture.name)['name_' + currentLanguage] : '--'}</> },

                        ] : [
                            { title: vocabulary.titleLabel, field: 'title' },
                            { title: vocabulary.description, field: 'description' },
                            { title: vocabulary.status, field: 'status' },
                        ]
                        }
                        actions={[
                            row => ({
                                icon: 'edit_outline',
                                onClick: (event, row) => showActivityTypeByUser ? onEditActivityTypeByUser(row) : onEdit(row),
                                tooltip: userId !== row.user_id ? vocabulary.disableEdit : vocabulary.editLabel,
                                disabled: userId !== row.user_id
                            }),
                            row => ({
                                icon: 'delete_outline',
                                onClick: (event, row) => openAlertConfirmDelete(row),
                                tooltip: userId !== row.user_id ? vocabulary.disableDelete : vocabulary.delete,
                                disabled: userId !== row.user_id
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: showActivityTypeByUser ? vocabulary.add + ' ' + vocabulary.activityType : vocabulary.add + ' ' + vocabulary.activityLabel,
                                isFreeAction: true,
                                onClick: (event) => showActivityTypeByUser == true ? handleClickOpenActivityTypeForm() : handleClickOpen()
                            },
                            {
                                icon: () => showActivityTypeByUser === false ? <ViewComfyIcon /> : <ViewHeadlineIcon />,
                                tooltip: showActivityTypeByUser === false ? vocabulary.activityTypeByMe : vocabulary.activities,
                                onClick: (event, row) => showActivityTypeByUser === false ? HandleShowActivitiesType() : HandleShowActivities(),
                                isFreeAction: true,

                            },

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],

                        }}
                    /> : <StyledCircularProgress timeout={true} noDataMsg={vocabulary.noRecordToDisplay} size={50} label={vocabulary.loading} />
                }


                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.title}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteActivity(item);
                            }

                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                {showActivityTypeByUser == false &&
                    <AddActivityForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={600}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetActivitiesList(setActivitiesList)}
                        activitiesTypeList={activitiesType && activitiesType.data}
                        sitesList={sitesList}
                        setIsLoading={setIsLoading}


                    />}
                {showActivityTypeByUser == true &&
                    <ActivitiesTypeAddForm
                        open={isEditingActivityType == false ? openActivityType : openEditActivityType}
                        onClose={() => {
                            return (
                                isEditingActivityType === true ?
                                    (setOpenActivityType(false),
                                        setIsEditingActivityType(false))
                                    :
                                    setOpenActivityType(false)
                            )

                        }}
                        width={500}
                        item={isEditingActivityType === true ? activityTypeitem : null}
                        isEditing={isEditingActivityType}
                        refetchFunc={() => GetActivitiesType(setActivitiesType)}
                        setIsLoading={setIsLoading}

                    />}
            </StyledCard>
        </ModalContext.Provider>
    )
}
export default ActivitiesList
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { IconButton, InputAdornment, Typography } from "@material-ui/core/";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledInput from "../../../ui/forms/StyledInput";
import { organizationAddUser, organizationUpdateUser } from "../../../globalsTools/network";
import { StyledModal } from '../../../ui/layout';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import StyledAutoComplete from '../../../ui/forms/StyledAutoComplete';
import { Visibility, VisibilityOff } from "@material-ui/icons/";

const abilities = [ 'cycle-section-access', 'area-section-access']

const UserAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState({
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        c_password: null,
        abilities: [],
    })


    const onChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    }



    const { enqueueSnackbar } = useSnackbar();




    useEffect(() => {
        if (isEditing === false) {
            setUser('')
        }

    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    useEffect(() => {

        if (item) {
            setUser({
                id: item?.id,
                firstname: item?.firstname,
                lastname: item?.lastname,
                email: item?.email,
                abilities: item?.permissions
            })
        }



    }, [item, isEditing]); // N’exécute l’effet que si item a changé

    const operationCallBack = (response, success) => {
        if (response) {
            if (success) {

                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response.response.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }



    const handleUserForm = () => {
        let data = {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            abilities: user.abilities,
        }
        if (user.password && user.password !== "") {
            data = {
                ...data,
                password: user.password,
                c_password: user.c_password,
            }
        }
        if (!isEditing) {
            organizationAddUser(data, operationCallBack)
        } else {
            organizationUpdateUser(data, operationCallBack)
        }

    }


    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewUser : vocabulary.EditUser}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>


                    <form
                        onSubmit={handleSubmit(handleUserForm)}
                        style={{ ...formStyle }}>


                        <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillTextAddUser}</Typography>
                        <StyledInput
                            register={register}
                            rules={{ required: true }}
                            name="firstname"
                            label={vocabulary.firstname}
                            type="text"
                            value={user.firstname}
                            onChange={onChange}
                            errors={errors.firstname}
                            disableAutoComplete={true}
                        />

                        <StyledInput
                            register={register}
                            rules={{ required: true }}
                            name="lastname"
                            label={vocabulary.lastname}
                            type="text"
                            value={user.lastname}
                            onChange={onChange}
                            errors={errors.lastname}
                            disableAutoComplete={true}
                        />

                        <StyledInput
                            register={register}
                            rules={{ required: true, validate: validateEmail }}
                            name="email"
                            label={vocabulary.email}
                            type="text"
                            value={user.email}
                            onChange={onChange}
                            errors={errors.email}
                            disableAutoComplete={true}
                        />

                        {errors.email &&
                            <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                                {vocabulary.mailInvalid}
                            </span>
                        }

                        <StyledInput
                            register={register}
                            rules={{ required: !isEditing }}
                            name="password"
                            value={user.password}
                            onChange={onChange}
                            type={showPassword ? 'text' : 'password'}
                            label={vocabulary.password}
                            errors={errors.password}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <StyledInput
                            register={register}
                            rules={{ required: !isEditing }}
                            name="c_password"
                            label={vocabulary.c_password}
                            type={showPassword ? 'text' : 'password'}
                            value={user.c_password}
                            onChange={onChange}
                            errors={errors.c_password}
                        />

                        <StyledAutoComplete
                            data={abilities}
                            label={vocabulary.permissions}
                            optionLabel={(option) => option}
                            styles={{ width: '100%' }}
                            multiple={true}
                            onChange={(event, newValue) => {
                                setUser({
                                    ...user,
                                    abilities: newValue
                                });
                            }}
                            autoComplete={false}
                            defaultValue={user?.abilities ? user?.abilities?.map(item => (item.name)) : []}
                        />


                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}

export default UserAddForm
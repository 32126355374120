

import { updateLanguage } from '../globalsTools/network';
import { createCookie } from '../utils';
import { SET_CURRENTLANGUAGE } from './actionTypes';

export const setGlobalLanguage = (language) => {
    updateLanguage(language)
    return {
        type: SET_CURRENTLANGUAGE,
        payload: language,
    }
}
/* eslint-disable  no-sequences  */
/* eslint-disable no-unused-vars*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq*/

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardContent, Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core/';
// import { Add, Done } from '@material-ui/icons/';
import 'bootstrap/dist/css/bootstrap.css';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../globalStyle';

import { setCurrentPage, } from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';
import AddShoppingCartIcon from '@material-ui/icons/Cached';

import Button from '@material-ui/core/Button';
import { GetAreasList, GetDeployedDeviceGatewayByAreaId, GetAllDataTypes, GetDataSourceByAreaId, GetAreaDetails, GetSourcesList, GetDataTypeByDeviceId, GetDeployedDevicebygateway } from "../../globalsTools/network";
import { useForm } from 'react-hook-form';
import StyledSelect from "../../ui/forms/StyledSelect";
import SearchIcon from '@material-ui/icons/Search';
import { StyledCard } from "../../ui/layout";
import LogsTable from './LogsTable'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import CurrentPage from '../Components/CurrentPage';
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from '../globalConsts';
import { GuideContext, steps } from '../../ui/layout/contextLayout/GuideContext';
import LogsStepper from './logsStepper';
import { stylesThemed } from '../globalStyle';

const LogsList = () => {

  const dispatch = useDispatch()

  const { guide, dispatchGuide } = useContext(GuideContext);

  const { register, handleSubmit, errors } = useForm()
  const currentLanguage = useSelector(state => state._globalState.preferred_language)

  const [dateFrom, setDateFrom] = useState(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DD HH:mm:ss"));
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))

  const [sitesList, setSitesList] = useState(null)
  const [areaId, setAreaId] = useState(null)
  const [deployedDeviceByGateways, setDeployedDeviceByGateways] = useState([])
  const [deviceId, setDeviceId] = useState(null);
  const [selectedGetewayId, setSelectedGetewayId] = useState(null);
  const [changeVue, setChangeVue] = useState('choiceBySite');
  const [parcelsList, setParcelsList] = useState([])
  const [sectorId, setSectorId] = useState(null)
  const [parcelId, setParcelId] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const [sectorsList, setSectorsList] = useState([])
  const [dataTypeList, setDataTypeList] = useState(null);
  // const [sourceListByAreaId, setSourceListByAreaId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [dataTypeByDeviceId, setDataTypeByDeviceId] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(null);
  const [sourceListByDefault, setSourceListByDefault] = useState(null)
  const [logListByAreaId, setLogListByAreaId] = useState(null)
  // const [logListByDeviceId, setLogListByDeviceId] = useState(null)
  const [selectedDataTypeId, setSelectedDataTypeId] = useState(null)
  const [selectedDataTypeIdentifier, setSelectedDataTypeIdentifier] = useState(null)
  const [selectedDataTypeIdByDevice, setSelectedDataTypeIdByDevice] = useState(null)
  const [selectedAreaId, setSelectedAreaId] = useState(null)
  const [gatewayListByArea, setGatewayListByArea] = useState(null)
  const [startGateData, setStartGateData] = useState(0)
  const [equipmentByDataTypeList, setEquipmentByDataTypeList] = useState([])
  const [equipmentDataTypeId, setEquipmentDataTypeId] = useState(null)



  const resetForm = () => {

    setSelectedArea(null)
    setDateFrom(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DDTHH:mm"))
    setDateTo(moment(new Date()).format("YYYY-MM-DDTHH:mm"))
    setParcelsList([])
    setAreaId(null)
    setParcelId(null)
    setSelectedGetewayId(null)
    setDeviceId(null)
    // setDataTypeList(null)
    // setLogListByAreaId(null)
    // setLogListByDeviceId(null)
    setSelectedDataTypeIdByDevice(null)
    setSelectedDataTypeId(null)
    setSourceId(null)
    // setSourceListByAreaId(null)
    setStartGateData(0)
    setEquipmentDataTypeId(null)
    // selectedDataTypeIdentifier(null)
    setEquipmentByDataTypeList([])
    setSourceListByDefault(null)
  }
  const findLogs = () => {

    setStartGateData(moment())
  }

  useEffect(() => {
    if (guide.currentStep === "logs" && steps[guide.initialStep].element === ".logsIntro" && guide.isEnabled === false) {
      setTimeout(() => {
        dispatchGuide({ type: IS_ENABLED, payload: true })
      }, 1000);
    }
  }, [])

  useEffect(() => {

    dispatch(setCurrentPage(vocabulary.Logs));
    GetAreasList(setSitesList)
    // GetGatewaysList(setGetwayArray)
    GetAllDataTypes(setDataTypeList)

    document.title = vocabulary.Logs


  }, [dispatch]); // N’exécute l’effet que si currentLanguage a changé

  useEffect(() => {

    dispatch(setCurrentPage(<CurrentPage name={vocabulary.Logs} helpFn={helpFn} />));
    document.title = vocabulary.Logs


  }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  const handleDateChange = key => date => {
    key === 'dateFrom' ? setDateFrom(date) : setDateTo(date)
  }

  useEffect(() => {
    if (selectedArea != null) {
      const areaId = selectedArea.id
      setSelectedArea(selectedArea)
      categorizeAreas(selectedArea)
      setSelectedAreaId(areaId)
      // GetDataSourceByAreaId(areaId, setSourceListByAreaId)
      GetDeployedDeviceGatewayByAreaId(areaId, setGatewayListByArea)
      GetSourcesList(setSourceListByDefault);

    }
  }, [selectedArea])


  const handleChangeAreaSelect = event => {
    GetAreaDetails(event.target.value, setSelectedArea)
  };

  const categorizeAreas = (areasDetails) => {
    switch (areasDetails.type) {
      case 'site':
        setParcelsList(areasDetails["childs"])
        setAreaId(areasDetails.id)
        setParcelId(null)

        break;
      case 'parcel':
        setSectorsList(areasDetails["childs"])
        setParcelId(areasDetails.id)
        setSectorId(null)
        break;
      case 'sector':
        setSectorId(areasDetails.id)
        break;
      default:
        return null

    }
  }

  const handleVueChange = event => {
    setChangeVue(event.target.value);

  };

  const validSearch = () => {
    if (changeVue == 'choiceBySite') {
      if (!areaId || !sourceId || !selectedDataTypeId) {
        return true
      }
      else return false


    }
    else if (changeVue == 'choicebyDeployedDevice') {
      if (!areaId || !selectedGetewayId || !deviceId || !selectedDataTypeIdByDevice) {
        return true
      }
      else return false


    }


  }

  const helpFn = () => {
    dispatchGuide({ type: INITIAL_STEP, payload: steps.findIndex(step => step.element === ".logsIntro") })
    dispatchGuide({ type: CURRENT_STEP, payload: 'logs' })
    setTimeout(() => {
      dispatchGuide({ type: IS_ENABLED, payload: true })
    }, 500);

  }

  const classes = stylesThemed();

  return (
    <>
      {guide.isEnabled && guide.currentStep === 'logs' && <LogsStepper />}
      {sitesList ?

        <StyledCard
          styleProp={{
            padding: 30,
          }}>

          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 35,
            alignItems: 'center'
          }} >

            <DateTimePicker
              value={dateFrom}
              onChange={handleDateChange('dateFrom')}
              label={vocabulary.startDate}
              maxDate={moment()}
              ampm={false}
              autoOk={true}
              disableFuture={true}

            />
            <DateTimePicker
              value={dateTo}
              onChange={handleDateChange('dateTo')}
              label={vocabulary.endDate}
              minDate={dateFrom}
              maxDate={moment()}
              ampm={false}
              autoOk={true}

            />
          </div>

          <form>
            <div className="choiceLogsSource">
              <RadioGroup aria-label="gender" name="gender1" value={changeVue} onChange={handleVueChange}>
                <FormControlLabel value="choiceBySite" control={<Radio />} label={vocabulary.choiceBySite} />
                <FormControlLabel value="choicebyDeployedDevice" control={<Radio />} label={vocabulary.choiceByDeployedDevice} />
              </RadioGroup>

            </div>

            <CardContent>
              <Grid container style={{ display: 'flex', flexDirection: 'row', flexWrap: "no-wrap", justifyContent: 'space-around', alignItems: 'center' }} spacing={2}>

                {changeVue === 'choiceBySite' &&
                  <>
                    <Grid item className={classes.flexAndGrow}>

                      {sitesList ?
                        <StyledSelect
                          value={areaId}
                          register={register}
                          rules={{ required: true }}
                          errors={errors.Area}
                          onChange={handleChangeAreaSelect}
                          name='Area'
                          label={vocabulary.site}
                          autoWidth
                          data={sitesList}
                          valueField='id'
                          labelField='name'
                        />
                        : null}
                    </Grid>

                    <Grid item className={classes.flexAndGrow}>

                      {(parcelsList.length > 0 && areaId) &&
                        <StyledSelect
                          value={parcelId}
                          register={register}
                          errors={errors.Area}
                          onChange={handleChangeAreaSelect}
                          name='ParcelId'
                          label={vocabulary.parcel}
                          autoWidth
                          data={parcelsList && parcelsList}
                          valueField='id'
                          labelField='name'
                        />
                      }
                    </Grid>

                    <Grid item className={classes.flexAndGrow}>

                      {(sectorsList !== [] && parcelId) &&
                        <StyledSelect
                          value={sectorId}
                          register={register}
                          errors={errors.Area}
                          onChange={handleChangeAreaSelect}
                          name='sectorId'

                          label={vocabulary.sector}
                          autoWidth
                          data={sectorsList && sectorsList}
                          valueField='id'
                          labelField='name'
                        />
                      }
                      {
                        (areaId && dataTypeList) &&
                        <StyledAutoComplete
                          styles={{ width: '100%' }}
                          loading={true}
                          data={dataTypeList && dataTypeList}
                          optionLabel={(option) => JSON.parse(option.name).name_ar ? JSON.parse(option.name)['name_' + currentLanguage] : JSON.parse(option.name).name_fr}
                          onChange={(event, newValue) => {
                            setSelectedDataTypeId(newValue !== null ? newValue.id : null)
                            setSelectedDataTypeIdentifier(newValue !== null ? newValue.identifier : null)
                          }}
                          label={vocabulary.datatype}
                        />
                      }

                      {
                        sourceListByDefault &&
                        <StyledAutoComplete
                          styles={{ width: '100%' }}
                          loading={true}
                          data={sourceListByDefault && sourceListByDefault}
                          optionLabel={(option) => option.id !== null ? option.id : "null"}
                          onChange={(event, newValue) => {
                            setSourceId(newValue !== null ? newValue.id : null)
                          }}
                          label={vocabulary.sourceLabel}
                        />
                      }
                    </Grid>
                  </>
                }

                {changeVue === 'choicebyDeployedDevice' &&
                  <>
                    <Grid item className={classes.flexAndGrow}>

                      {sitesList ?
                        <StyledSelect
                          value={areaId}
                          register={register}
                          rules={{ required: true }}
                          errors={errors.Area}
                          onChange={handleChangeAreaSelect}
                          name='Area'
                          label={vocabulary.site}
                          autoWidth
                          data={sitesList}
                          valueField='id'
                          labelField='name'
                        />
                        : null}
                    </Grid>

                    <Grid item className={classes.flexAndGrow}>
                      {(areaId && gatewayListByArea) &&
                        <StyledSelect
                          value={selectedGetewayId}
                          register={register}
                          rules={{ required: true }}
                          errors={errors.selectGateway}
                          onChange={(event) => {
                            setSelectedGetewayId(event.target.value)
                            GetDeployedDevicebygateway(event.target.value, setDeployedDeviceByGateways)

                          }}
                          name='selectGateway'
                          label={vocabulary.selectGateway}
                          autoWidth
                          data={gatewayListByArea && gatewayListByArea.data}
                          valueField='id'
                          labelField='name'

                        />
                      }

                    </Grid>

                    <Grid item className={classes.flexAndGrow}>

                      {(selectedGetewayId && deployedDeviceByGateways !== []) &&
                        <StyledSelect
                          value={deviceId}
                          register={register}
                          rules={{ required: true }}
                          errors={errors.deviceId}
                          onChange={(event) => {
                            setDeviceId(event.target.value)
                            GetDataTypeByDeviceId(event.target.value, setDataTypeByDeviceId)

                          }}
                          name='deviceId'
                          label={vocabulary.selectStation}
                          autoWidth
                          data={deployedDeviceByGateways && deployedDeviceByGateways}
                          valueField='id'
                          labelField='name'

                        />

                      }
                      {((deviceId || selectedGetewayId) && dataTypeByDeviceId) &&
                        <StyledSelect
                          value={selectedDataTypeIdByDevice}
                          register={register}
                          errors={errors.datatype}
                          onChange={(event) => {

                            setSelectedDataTypeIdByDevice(event.target.value)
                          }}

                          render={(item) => {
                            return JSON.parse(item.data_type.name).name_fr
                          }}

                          name='datatype'
                          label={vocabulary.datatype}
                          autoWidth
                          data={dataTypeByDeviceId && dataTypeByDeviceId.data}
                          valueField='id'
                          dataType={true}
                          labelField='name'

                        />}
                    </Grid>

                  </>

                }

              </Grid>

            </CardContent>
            <Button variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => findLogs()}
              disabled={validSearch()}

            >
              <SearchIcon style={{ marginRight: '10px' }}
              />
              {vocabulary.findLogs}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => resetForm()}
            >
              <AddShoppingCartIcon
                style={{ marginRight: '10px' }}
              />
              {vocabulary.newSearch}
            </Button>

          </form>


          {/* {((logListByAreaId !== null &&logListByDeviceId === null )||(logListByAreaId === null &&logListByDeviceId !== null )) ? */}
          {
            startGateData !== 0 &&
            <LogsTable
              // logListByAreaId={deviceId===null ? logListByAreaId&&logListByAreaId.data : logListByDeviceId&&logListByDeviceId.data}
              selectedAreaId={selectedAreaId}
              selectedDataTypeId={selectedDataTypeIdByDevice ? selectedDataTypeIdByDevice : selectedDataTypeId}
              dateFrom={moment(dateFrom).format('YYYY-MM-DD HH:mm:ss')}
              dateTo={moment(dateTo).format('YYYY-MM-DD HH:mm:ss')}
              deviceId={deviceId && deviceId}
              selectedDataTypeIdByDevice={selectedDataTypeIdByDevice}
              selectedGetewayId={selectedGetewayId}
              selectedDataTypeIdentifier={selectedDataTypeIdentifier}
              sourceTypeId={sourceId}
              pageSizes={setPageSize}
              pageNumber={setPageNumber}
              logListByAreaId={logListByAreaId && logListByAreaId.data}
              startGateData={startGateData}
            // equipmentDataTypeId={equipmentDataTypeId}

            />
          }

          {/* : null} */}


        </StyledCard>
        : <StyledCircularProgress size={50} label={vocabulary.loading} />}



    </ >
  );

}


// LogsList.propTypes = {
//   classes: PropTypes.object.isRequired,
// };



export default LogsList;
import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';
import { Done, HighlightOff } from '@material-ui/icons';

import { vocabulary } from '../Strings';

const AlertEditDialogConfirmation = ({ onClose, cancel, operation, confirmUpdate, open, EditItemName }) => {


    const innerClose = () => {
        onClose();
    };

    const innerCancel = () => {
        cancel()
        innerClose()
    }

    const innerConfirm = () => {
        confirmUpdate()
        innerClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={innerClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{vocabulary.warningLabel}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {vocabulary.confirmationLabel} {operation} {EditItemName} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={innerCancel}
                        style={{ textTransform: 'none' }}
                        color="secondary"

                    >
                        <HighlightOff />
                        &nbsp;{vocabulary.cancelBtn}
                    </Button>
                    {
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={innerConfirm}
                            color="primary"
                        >
                            <Done />
                            &nbsp;{vocabulary.save}
                        </Button>}


                </DialogActions>

            </Dialog>
        </div>
    );
}


export default AlertEditDialogConfirmation;
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setCurrentPage } from '../../actions';
import { vocabulary } from '../Strings';
import { GetAreasList, GetAreaDetails, GetWaterNeedByParcelId } from "../../globalsTools/network";
import { useForm } from 'react-hook-form';
import { Grid, Card, CardContent, Button } from '@material-ui/core/';
import StyledSelect from "../../ui/forms/StyledSelect";
// import { styles } from '../globalStyle'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import WaterNeedHistory from './WaterNeedHistory'
import "./style.css";
import { logger } from '../../globalsTools/utilities';
import PrecipitationSummary from './PrecipitationSummary';
import WaterReqCardNetirrig from './WaterReqCardNetirrig';
// import HierarchyWaterNeedChart from './HierarchyWaterNeedChart'
// import CycleDetails from '../Cycle/CycleDetails'

const WaterRequirement = ({ areaId, selectedAreaProps, mapView, rerender }) => {

    const dispatch = useDispatch()
    const { register, errors } = useForm()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [siteId, setSiteId] = useState('')
    const [parcelsList, setParcelsList] = useState([])
    const [parcelId, setParcelId] = useState('')
    const [sitesList, setSitesList] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [parcelWaterNeed, setParcelWaterNeed] = useState(null)
    const [usedUnit, setUsedUnit] = useState(vocabulary.metreJour)
    const [sectorsList, setSectorsList] = useState([])
    const [sectorId, setSectorId] = useState(null)


    useEffect(() => {
        if (!areaId) {
            dispatch(setCurrentPage(vocabulary.waterRequirementLabel))
            GetAreasList(setSitesList)
            document.title = vocabulary.waterRequirementLabel
        }



    }, [dispatch, currentLanguage, areaId]); // N’exécute l’effet que si currentLanguage a changé


    useEffect(() => {

        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea]) // N’exécute l’effet que si selectedArea a changé

    useEffect(() => {
        if (areaId != null) {
            GetWaterNeedByParcelId(areaId, setParcelWaterNeed)

        }
    }, [areaId, rerender]) // N’exécute l’effet que si areaId a changé et remplir les waterNeed dans le one pager

    useEffect(() => {
      logger('parcelWaterNeed', parcelWaterNeed)
    }, [parcelWaterNeed])
    

    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };

    const resetSelection = () => {
        setParcelsList([])
        setSiteId('')
        setParcelId('')
        setParcelWaterNeed(null)


    }
    const findWaterNeed = () => {
        GetWaterNeedByParcelId(selectedArea && selectedArea.id, setParcelWaterNeed)

    }

    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSiteId(areasDetails.id)
                setParcelId('')

                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                setSectorsList(areasDetails["childs"])
                setSectorId(null)
                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;
            default:
                return ''

        }
    }

    return (
        <>
            {
                sitesList ?
                    <>
                        <Card style={{ marginBottom: '10px' }}
                            elevation={8}
                        >
                            <CardContent>
                                <Grid container style={{ display: 'flex', flexDirection: 'row', flexWrap: "no-wrap", justifyContent: 'space-around', alignItems: 'center' }} spacing={2}>
                                    <Grid item >

                                        <StyledSelect
                                            value={siteId}
                                            register={register}
                                            rules={{ required: true }}
                                            errors={errors.Area}
                                            onChange={handleChange}
                                            name='Area'
                                            label={vocabulary.site}
                                            autoWidth
                                            data={sitesList && sitesList}
                                            valueField='id'
                                            labelField='name'
                                        // indexV={true}

                                        />
                                    </Grid>
                                    <Grid item >

                                        {parcelsList.length > 0 &&
                                            <StyledSelect
                                                value={parcelId}
                                                register={register}
                                                rules={{ required: true }}
                                                errors={errors.ParcelId}
                                                onChange={handleChange}
                                                name='ParcelId'
                                                label={vocabulary.parcel}
                                                autoWidth
                                                data={parcelsList}
                                                valueField='id'
                                                labelField='name'

                                            />
                                        }

                                    </Grid>
                                    <Grid item >

                                        {(sectorsList.length > 0 && parcelId) &&
                                            <StyledSelect
                                                value={sectorId}
                                                register={register}
                                                // rules={{ required: true }}
                                                errors={errors.sectorId}
                                                onChange={handleChange}

                                                name='sectorId'
                                                styles={{ width: '100%' }}
                                                label={vocabulary.sector}
                                                data={sectorsList}
                                                valueField='id'
                                                labelField='name'
                                            />

                                        }
                                    </Grid>

                                    <Grid item>
                                        <Button style={{ marginTop: '15px' }} color="primary" variant="contained" onClick={() => { findWaterNeed() }} >{vocabulary.search}</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button style={{ marginTop: '15px' }} color="secondary" variant="contained" onClick={() => { resetSelection() }} >{vocabulary.resetSelection}</Button>
                                    </Grid>

                                </Grid>
                            </CardContent>

                        </Card>
                    </>

                    : areaId ? null : <StyledCircularProgress size={50} label={vocabulary.loading} />
            }



            {/* {parcelWaterNeed && parcelWaterNeed.production_cycle.prm_culture.prm_culture_family_id !== ARBORICULTURE_FAMILY &&
                <Card style={{ borderRadius: '10px', marginTop: '20px' }}
                    elevation={8}
                >
                    <CycleDetails
                        item={parcelWaterNeed && parcelWaterNeed.production_cycle}
                        onePagerView={mapView}
                    />
                </Card>
            } */}
            {(parcelWaterNeed !== null) && <>
                <div className="waterNeedItemCard">



                    <WaterReqCardNetirrig
                        waterNeedData={parcelWaterNeed}
                        usedUnit={setUsedUnit}
                    />
                    {/* : 
                    <WaterNeedCard
                        itemCard={parcelWaterNeed}
                        usedUnit={setUsedUnit}
                    />
                } */}

                </div>

                {/* <HierarchyWaterNeedChart
                    itemCard={parcelWaterNeed}
                    usedUnit={usedUnit}

                /> */}

                {<WaterNeedHistory
                    usedUnit={usedUnit}
                    areaId={areaId ? areaId : selectedArea && selectedArea.id}
                    onePagerView={mapView}
                    cycleDetail={parcelWaterNeed}
                    selectedArea={selectedAreaProps && selectedAreaProps}
                />
                }

                
                <PrecipitationSummary data={parcelWaterNeed.precipitation_summary} />
                {/* } */}
            </>
            }




        </>
    )
}




export default WaterRequirement;

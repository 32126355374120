import React from "react";
import { HelpOutline } from "@material-ui/icons";

function CurrentPage({ name, helpFn }) {
  return (
    <>
      {name} &nbsp;&nbsp;
      <HelpOutline style={{ cursor: "pointer" }} onClick={helpFn} />
    </>
  );
}

export default CurrentPage;

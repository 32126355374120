import React, { useRef } from 'react';

import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { StyledInput, StyledSelect } from '../../../../../ui/forms';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { GetOrdersLinesList, GetOrdersList } from '../../../../../globalsTools/network';
import StyledAutoComplete from '../../../../../ui/forms/StyledAutoComplete';
import { vocabulary } from '../../../../Strings';
import { StyledMaterialTable } from '../../../../../ui/display';





const CreateInvoice = ({ onSubmit, formRef }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm()

  const [ordersList, setOrdersList] = useState([])
  const [order, setOrder] = useState(null)
  const [orderLines, setOrderLines] = useState([])

  useEffect(() => {

    GetOrdersList(setOrdersList)


  }, []);




  return (
    <Box>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Create Invoice
      </Typography>

      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={onSubmit}
        itemRef={formRef}
      >
        <Grid container direction='row' spacing={4} width={`calc(100%)!important`}>
          <Grid item xs={12} style={{ display: 'flex' }}>

            <StyledAutoComplete
              value={order}
              register={register}
              rules={{ required: true }}
              errors={errors.order}
              name="order"
              optionLabel={(option) => String(option.number)}

              onChange={(event, newValue) => {
                setOrder({
                  ...newValue,
                  additional_information: JSON.parse(newValue?.additional_information)
                })

                GetOrdersLinesList(newValue?.id, setOrderLines)
              }}
              label={"Order"}
              fullWidth
              data={ordersList}
              valueField="id"
              labelField="id"
              styles={{ width: '100%' }}
            />

          </Grid>
          <Grid item xs={4}>
            <StyledInput
              value={order?.additional_information?.billingDetails?.company}
              name='company'
              rules={{ required: true }}
              errors={errors.company}
              fullWidth
              label={vocabulary.companyNameLabel}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledInput
              value={order?.additional_information?.billingDetails?.billingAddress}
              name='billingAddress'
              rules={{ required: true }}
              errors={errors.billingAddress}
              fullWidth
              label={vocabulary.billingAddress}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledInput
              value={order?.additional_information?.billingDetails?.notificationEmail}
              name='email'
              rules={{ required: true }}
              errors={errors.email}
              fullWidth
              label={vocabulary.email}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              value={order?.user?.firstname}
              name='firstname'
              rules={{ required: true }}
              fullWidth
              label={vocabulary.firstname}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              value={order?.user?.lastname}
              name='lastname'
              rules={{ required: true }}
              fullWidth
              label={vocabulary.lastname}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={6}>
            <StyledInput
              value={order?.order_date}
              name='firstname'
              rules={{ required: true }}
              fullWidth
              label={vocabulary.orderDate}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInput
              value={order?.ordered_total_amount}
              name='ordered_total_amount'
              rules={{ required: true }}
              fullWidth
              label={vocabulary.orderTotal}
              sx={{ mb: 2 }}

            />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex' }}>
            <StyledMaterialTable
              title={vocabulary.billingDetails}
              data={orderLines}

              columns={[
                { title: vocabulary.title, field: 'title' },
                { title: vocabulary.area, field: 'area.name' },
                { title: vocabulary.quantity, field: 'quantity' }, 
                { title: vocabulary.total, field: 'ordered_total_amount' , render: rowData => <>{rowData.ordered_total_amount + ' ' + order?.currency}</>   },
                { title: vocabulary.status, field: 'order_line_status' },
              ]}

              options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
              }}
              
            />

          </Grid>
        </Grid>
      </Box >

    </Box >
  );
};

export default CreateInvoice;

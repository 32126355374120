

import React, { useState, useRef, useEffect } from 'react';
import { vocabulary } from '../Strings';
import { StyledModal } from '../../ui/layout';
import { useSnackbar } from 'notistack';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";
import SencropConfigureForm from './partners/forms/sencrop/SencropConfigureForm';
import WeenatConfigureForm from './partners/forms/weenat/WeenatConfigureForm';
import { AddPartnerDevices, UpdatePartnerDevices } from '../../globalsTools/network';

const INTEGRATIONS = [
    'SENCROP',
    'WEENAT'
]

const PartnerIntegrationForm = ({ setIsLoading, partnerId, currentItem, isEditing, onClose, open, refetchFunc }) => {


    const dataRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);




    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };




    const addPartnerDevice = (data) => {
        AddPartnerDevices(data, response => {
            setIsLoading(true)
            if (response.status == 200) {
                snackbar(vocabulary.operationSucc, 'success');
                refetchFunc()
            } else {
                snackbar(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
            setIsLoading(false)
            onClose()
        })
    }

    const updatePartnerDevice = (data) => {
        UpdatePartnerDevices({
            id: currentItem.id,
            ...data
        }, response => {
            setIsLoading(true)
            if (response.status == 200) {
                snackbar(vocabulary.operationSucc, 'success');
                refetchFunc()
            } else {
                snackbar(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
            setIsLoading(false)
            onClose()
        })
    }

    const handleConfirmePopup = () => {

        if (!isEditing) {


            if (dataRef.current) {
                const data = dataRef.current.getData();

                addPartnerDevice(data)
            }

            setIsLoading(false)
            onClose()
        }

    }

    const handleUpdate = () => {
        setIsLoading(true)

        if (dataRef.current) {
            const data = dataRef.current.getData();

            updatePartnerDevice(data)
        }

        setOpenEditConfirmation(false)
        setIsLoading(false)
        onClose()
    }


    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleConfirmePopup() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: onClose }
    ]


    const renderPartnerForm = (partner) => {
        if (partner == INTEGRATIONS[0]) {
            return (
                <SencropConfigureForm ref={dataRef} isEditing={isEditing} currentItem={currentItem} partnerId={partnerId} />
            )
        }
        if (partner == INTEGRATIONS[1]) {
            return (
                <WeenatConfigureForm ref={dataRef} isEditing={isEditing} currentItem={currentItem} partnerId={partnerId} />
            )
        }

    }

    return (

        <>
            <StyledModal
                open={open}
                closeFn={onClose}
                width={600}
                withoutContext
                title={isEditing === false ? vocabulary.partnerIntegrations : vocabulary.partnerIntegrations}
            >

                {
                    partnerId &&
                    (
                        <>

                            {renderPartnerForm(partnerId)}

                            <StyledModalFooter actions={actions} />

                        </>
                    )
                }


            </StyledModal>

            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={currentItem?.partner_id}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (currentItem) {
                            handleUpdate();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}



        </>
    )
}

export default PartnerIntegrationForm


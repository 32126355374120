/* eslint-disable no-sequences */

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

import { setCurrentPage } from '../../actions';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
import AddShoppingCartIcon from '@material-ui/icons/Cached';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';


import { useForm } from 'react-hook-form';
import StyledSelect from "../../ui/forms/StyledSelect";
import { StyledCard } from "../../ui/layout";
import { Grid, Button, CardContent, CardActions } from '@material-ui/core/';
import IrrigationListTable from './IrrigationListTable'
import { GetAreasList, GetAreaDetails } from "../../globalsTools/network";
import AddIrrigationForm from './AddIrrigationForm'
import MultipleIrrigationForm from './MultipleIrrigationForm'
import { stylesThemed } from '../globalStyle';

const IrrigationList = () => {

    const classes = stylesThemed();

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const { register, handleSubmit, errors } = useForm()

    const [dateFrom, setDateFrom] = useState(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DDTHH:mm"));
    const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
    const [sitesList, setSitesList] = useState(null)
    const [parcelId, setParcelId] = useState('')
    const [sectorId, setSectorId] = useState('')
    const [selectedArea, setSelectedArea] = useState(null)
    const [siteId, setSiteId] = useState(null)
    const [parcelsList, setParcelsList] = useState([])
    const [sectorsList, setSectorsList] = useState([])
    const [selectedAreaId, setSelectedAreaId] = useState(null)
    // const [irrigationType, setIrrigationType] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [startGateData, setStartGateData] = useState(null)
    const [openAddMultipleForm, setOpenAddMultipleForm] = useState(false)


    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.irrigationMangement));
        document.title = vocabulary.irrigationMangement
        GetAreasList(setSitesList)


    }, [dispatch]) // first run 

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.irrigationMangement));
        document.title = vocabulary.irrigationMangement
    }, [dispatch, currentLanguage]) // excute when currentLanguage change

    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };

    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSiteId(areasDetails.id)
                setParcelId('')

                break;
            case 'parcel':
                setSectorsList(areasDetails["childs"])
                setParcelId(areasDetails.id)
                setSectorId('')
                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;
            default:
                return ''

        }
    }

    useEffect(() => {
        if (selectedArea != null) {
            const areaId = selectedArea.id

            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
            setSelectedAreaId(areaId)

        }
    }, [selectedArea]) //excute when selectedArea change 

    const resetForm = () => {  // reset form to his intial stage

        return (
            setDateFrom(moment(new Date().setDate(new Date().getDate() - 10)).format("YYYY-MM-DDTHH:mm")),
            setDateTo(moment(new Date()).format("YYYY-MM-DDTHH:mm")),
            setSectorId(''),
            setParcelsList([]),
            setSiteId(null),
            setParcelId(''),
            setSelectedArea(null),
            setStartGateData(null),
            setSelectedAreaId(null)
        )

    }

    const findIrrigation = () => {
        setStartGateData(moment())

    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenAdd(!openAdd)

        } else {
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const changeModalStatusMultipleForm = (newStatus) => {
        if (newStatus == null) {
            setOpenAddMultipleForm(!openAddMultipleForm)

        } else {
            setOpenAddMultipleForm(newStatus)
        }
    }

    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const handleClickOpenMultipleForm = () => {
        setOpenAddMultipleForm(true)
    };

    const handleDateChange = (key) => (date) => {
        if (key === 'dateFrom') {
            setDateFrom(date);
        } else {
            setDateTo(date);
        }
    };

    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    const contextValueAddMultipleForm = { open: openAddMultipleForm, changeModalStatus: changeModalStatusMultipleForm }


    return (
        <>
            <ModalContext.Provider value={openAddMultipleForm ? contextValueAddMultipleForm : contextValueAdd}>

                {sitesList ?
                    <StyledCard styleProp={{
                        width: '100%',
                        padding: 30,
                        paddingRight: 50,
                        marginTop: 15
                    }}>

                        <form onSubmit={handleSubmit(findIrrigation)}>
                            <CardContent>

                                <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                                    <Grid item xs style={{ display: 'flex' }}>
                                        <DateTimePicker
                                            value={dateFrom}
                                            onChange={handleDateChange('dateFrom')}
                                            label={vocabulary.startDate}
                                            maxDate={moment()}
                                            format={currentLanguage === 'fr' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                            style={{ width: `100%` }}

                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs style={{ display: 'flex' }}>
                                        <DateTimePicker
                                            value={dateTo}
                                            onChange={handleDateChange('dateTo')}
                                            label={vocabulary.endDate}
                                            minDate={dateFrom}
                                            format={currentLanguage === 'fr' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                            variant='outlined'
                                            style={{ width: `100%` }}
                                        />
                                    </Grid>


                                    {sitesList &&
                                        <Grid item xs style={{ display: 'flex' }}>

                                            <StyledSelect
                                                value={siteId}
                                                register={register}
                                                rules={{ required: true }}
                                                errors={errors.Area}
                                                onChange={handleChange}
                                                name='Area'
                                                label={vocabulary.site}
                                                autoWidth
                                                data={sitesList}
                                                valueField='id'
                                                labelField='name'
                                                addMargin={false}
                                            />
                                        </Grid>
                                    }

                                    {(parcelsList.length > 0 && siteId) &&
                                        <Grid item xs style={{ display: 'flex' }}>

                                            <StyledSelect
                                                value={parcelId}
                                                register={register}
                                                errors={errors.Area}
                                                onChange={handleChange}
                                                name='ParcelId'
                                                label={vocabulary.parcel}
                                                autoWidth
                                                data={parcelsList && parcelsList}
                                                valueField='id'
                                                labelField='name'
                                                addMargin={false}

                                            />
                                        </Grid>
                                    }

                                    {(sectorsList.length > 0 && parcelId) &&
                                        <Grid item xs style={{ display: 'flex' }}>

                                            <StyledSelect
                                                value={sectorId}
                                                register={register}
                                                errors={errors.Area}
                                                onChange={handleChange}

                                                name='sectorId'
                                                label={vocabulary.sector}
                                                autoWidth
                                                data={sectorsList && sectorsList}
                                                valueField='id'
                                                labelField='name'
                                                addMargin={false}

                                            />

                                        </Grid>
                                    }

                                </Grid>

                            </CardContent>
                            <CardActions>
                                <Button variant="contained" color="primary" className={classes.button} type="submit">
                                    <SearchIcon style={{ marginRight: '10px' }} />
                                    {vocabulary.findIrrigation}
                                </Button>
                                <Button variant="contained" color="secondary" className={classes.button} onClick={() => resetForm()}>
                                    <AddShoppingCartIcon style={{ marginRight: '10px' }} />
                                    {vocabulary.newSearch}
                                </Button>
                                {/* <Button variant="contained" color="primary" className={classes.button} onClick={() => handleClickOpen()}>
                                    <AddIcon style={{ marginRight: '10px' }} />
                                    {vocabulary.addIrrigation}
                                </Button> */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleClickOpenMultipleForm()}
                                    disabled={
                                        !selectedAreaId ||
                                        (parcelsList.length > 0 && !parcelId) ||
                                        (sectorsList.length > 0 && !sectorId)
                                    }
                                >
                                    <AddIcon style={{ marginRight: '10px' }} />
                                    {vocabulary.addMultipleIrrigationLabel}
                                </Button>
                            </CardActions>
                        </form>
                        {startGateData !== null ?
                            <IrrigationListTable
                                selectedAreaId={selectedAreaId}
                                dateFrom={moment(dateFrom).format('YYYY-MM-DD')}
                                dateTo={moment(dateTo).format('YYYY-MM-DD')}
                                getingSiteList={() => GetAreasList(setSitesList)}
                                sitesList={sitesList}
                                startGateData={startGateData}
                                reloadFn={() => handleSubmit(findIrrigation)}

                            />
                            : null}
                        {openAdd &&
                            <AddIrrigationForm
                                open={openAdd}
                                onClose={() => {
                                    return (
                                        isEditing === true ?

                                            setIsEditing(false)
                                            : setOpenAdd(false)

                                    )
                                }}
                                // item={isEditing === true ? item : null}
                                isEditing={isEditing}
                                AreaId={selectedAreaId}
                                sitesList={sitesList}

                            />}
                        {openAddMultipleForm &&
                            <MultipleIrrigationForm
                                areaId={selectedAreaId}
                                open={openAddMultipleForm}
                                onClose={() => {
                                    return (
                                        setOpenAddMultipleForm(false)

                                    )
                                }}
                                // item={isEditing === true ? item : null}
                                // AreaId={selectedAreaId}
                                sitesList={sitesList}
                                isEditing={false}

                            />}


                    </StyledCard>


                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }
            </ModalContext.Provider>

        </>
    );

}


// IrrigationList.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default IrrigationList;
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars*/
/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  no-useless-concat */

import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Slider,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import {
  GetAreaSoil,
  GetDefaultDataSource,
  GetSoilFamilliesList,
  GetSoilTypesList,
  createArea,
  updateAreaItem,
  GetAreaSize,
  GetNetirrigSols,
  GetAreaDetails,
  // GetSeabexOffers,
} from '../../globalsTools/network';
import {
  CURRENT_STEP,
  DEV_NETIRRIG_ENVIRONMENT,
  INITIAL_STEP,
  IS_ENABLED,
  NETIRRIG_ENVIRONMENT,
  SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE,
  SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON,
  SOIL_TEXTURE_TYPE_NETIRRIG,
  SOIL_TEXTURE_TYPE_SOILGRID,
  SOURCE_SEABEX_DEFAULT,
  SOURCE_WEATHER_MEASURES,
  TYPE_PARCEL,
  TYPE_SECTOR,
  TYPE_SITE,
} from '../globalConsts';

import { Bar } from '@nivo/bar';
import L from 'leaflet';
import SeabexMap from './Map/SeabexMap';
import { StyledInput } from '../../ui/forms';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import { StyledModal } from '../../ui/layout';
import StyledSelect from '../../ui/forms/StyledSelect';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { vocabulary } from './../Strings';
// import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
// import SkipNextIcon from '@material-ui/icons/SkipNext';
import { Done, ExpandMore, HighlightOff, Refresh, Report } from '@material-ui/icons';
import {
  GuideContext,
  steps,
} from '../../ui/layout/contextLayout/GuideContext';
import AddAreaStepper1 from './Guides/AddAreaStepper1';
import AddAreaStepper2 from './Guides/AddAreaStepper2';

import { useHistory } from 'react-router-dom';
import './style.css';
import SeabexOffers from '../Offers/SeabexOffers';
import CycleForm from '../Cycle/CycleAddUpdateForm';
import { StyledDialog } from '../../ui/display/StyledDialog';
import CustomSoilStructure from './Components/CustomSoilStructure';
import { logger } from '../../globalsTools/utilities';
import SolTexture from '../../assets/sols/SolTexture';
import { orange } from '@material-ui/core/colors';
import { isSet } from 'lodash';
import { REACT_APP_ENV_VERSION } from '../../config';

var _ = require('lodash');
var dataTypeTable = [];

// TODO : get item from id not from the list.
// TODO : REFACTOR

const AddArea = ({
  initialMap,
  open,
  closeFn,
  item,
  area_id,
  isEditing,
  refetchFunc,
  withoutContext = false,
  parentArea,
  partnerArea,
  ...properties
}) => {
  const type =
    parentArea == null
      ? TYPE_SITE
      : parentArea.type === TYPE_SITE
        ? TYPE_PARCEL
        : TYPE_SECTOR;
  const { register, handleSubmit, errors } = useForm();
  const currentLanguage = useSelector((state) => state._globalState.preferred_language);
  //const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();


  let history = useHistory();

  // const [countryId, setCountryId] = useState(null)
  // const [governateId, setGovernateId] = useState(null)
  const [siteName, setSiteName] = useState('');
  const [description, setDescription] = useState('');

  const [soilFamilyList, setSoilFamilyList] = useState(null);
  const [soilFamilyId, setSoilFamilyId] = useState('');
  const [soilTypeList, setSoilTypeList] = useState(null);
  const [soilTypeId, setSoilTypeId] = useState(SOIL_TEXTURE_TYPE_SOILGRID);
  const [areaSize, setAreaSize] = useState(null);
  const [soilType, setSoilType] = useState(null);
  const [soilTypeStructure, setSoilTypeStructure] = useState(null);
  const [choosenSoilTypeStructure, setChoosenSoilTypeStructure] = useState(null);
  const [parentId, setParentId] = useState(parentArea ? parentArea.id : null);
  const [areaMap, setAreaMap] = useState(initialMap);
  const [soilvalue, setSoilValue] = useState('recommended');
  const [step, setStep] = useState(1);
  const [defaultDataSource, setDefaultDataSource] = useState(null);
  const [selectedSourceId, setSelectedSourceId] = useState((REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? SOURCE_WEATHER_MEASURES : SOURCE_SEABEX_DEFAULT);
  const [correspondenceTable, setCorrespondenceTable] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [createAreaGuide, setCreateAreaGuide] = useState(true);
  const { guide, dispatchGuide } = useContext(GuideContext);
  const [newlyCreatedArea, setNewlyCreatedArea] = useState(null);

  const [showOffers, setShowOffers] = useState(false);
  const [showCycleForm, setShowCycleForm] = useState(false);
  const [showDialogCycleForm, setShowDialogCycleForm] = useState(false);
  const [showFormModal, setShowFormModal] = useState(true);
  const [tauxCailloux, setTauxCailloux] = useState(10);
  const [maximumRooting, setMaximumRooting] = useState(1);

  const [isLoading, setIsLoading] = useState(false)

  const [saving, setSaving] = useState(false)
  const [soilNetirrigId, setSoilNetirrigId] = useState('')
  const [soilNetirrigItem, setSoilNetirrigItem] = useState(null)
  const [netirrigSols, setNetirrigSols] = useState([])
  const [netirrigSolFamilies, setNetirrigSolFamilies] = useState([])
  const [netirrigSolFamily, setNetirrigSolFamily] = useState('')
  const [noLimitRooting, setNoLimitRooting] = useState(false)
  const [areaDetails, setAreaDetails] = useState(null)


  useEffect(() => {
    if (Array.isArray(netirrigSols)) {

      setSoilNetirrigItem(netirrigSols.find(element => element.sd_code == soilNetirrigId))
    }

  }, [netirrigSols, soilNetirrigId])

  useEffect(() => {
    if (Array.isArray(netirrigSols)) {
      setNetirrigSolFamilies([...[...netirrigSols].map(item => ({ sg_nom: item.sg_nom })).filter((v, i, a) => a.findIndex(v2 => (v2.sg_nom === v.sg_nom)) === i)])
    }
  }, [netirrigSols])

  useEffect(() => {
    logger("Netirrig Sol Families, netirrigSolFamily", netirrigSolFamilies, netirrigSolFamily)
  }, [netirrigSolFamilies, netirrigSolFamily])

  useEffect(() => {
    logger("Item Object", item)
  }, [item])



  useEffect(() => {
    GetSoilFamilliesList(setSoilFamilyList);
    if ((REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT)) {
      GetNetirrigSols(setNetirrigSols);
    }
    GetDefaultDataSource(setDefaultDataSource);
    if (
      (steps[guide.initialStep].element === '.addNewAreaModal' &&
        guide.currentStep === 'addAreaModal') ||
      (steps[guide.initialStep].element === '.saveBtnAddArea' &&
        guide.currentStep === 'addAreaModalBack')
    ) {
      setCreateAreaGuide(guide.currentStep === 'addAreaModal');
      if (guide.currentStep === 'addAreaModalBack')
        dispatchGuide({ type: CURRENT_STEP, payload: 'addAreaModal' });
      // setSiteName(vocabulary.addAreaNameGuide);
      // setDescription(vocabulary.addAreaDescriptionGuide);
      if (steps[guide.initialStep].element === '.saveBtnAddArea') {
        setStep(2);
      }
      setTimeout(() => {
        dispatchGuide({
          type: IS_ENABLED,
          payload: true,
        });
      }, 2000);
    }
    if (area_id) {
      GetAreaDetails(area_id, handleAreaDetails)
    }
  }, []); // N’exécute l’effet que si count a changé


  const handleAreaDetails = (data) => {
    if (data) {
      setAreaDetails(data)
      setSiteName(data.name)
      setDescription(data.description ?? '')
      if (data.soil_texture) {
        try {
          let soil_texture_parsed = JSON.parse(data.soil_texture)
          setSoilValue(soil_texture_parsed.type)
          if (soil_texture_parsed.data) {
            setSoilTexture(soil_texture_parsed)
          }
        } catch (e) {

        }
      }
      setTauxCailloux(Number(data.taux_cailloux))
      setMaximumRooting(Number(data.maximum_rooting))
      setNoLimitRooting(Number(data.maximum_rooting) === 0)
    }


  }

  useEffect(() => {
    setShowOffers(true)
  }, [newlyCreatedArea]);

  useEffect(() => {
    if (
      (step === 2 &&
        steps[guide.initialStep].element === '.dataSourceTable' &&
        guide.currentStep === 'addAreaModal') ||
      (step === 1 &&
        steps[guide.initialStep].element === '.nextBtnAddArea' &&
        guide.currentStep === 'addAreaModal')
    )
      setTimeout(() => {
        dispatchGuide({
          type: IS_ENABLED,
          payload: true,
        });
      }, 1000);
  }, [step]);

  useEffect(() => {
    logger("Area Details : ", areaDetails)
  }, [areaDetails])

  useEffect(() => {
    logger("Area Details : ", areaDetails)
    if (isEditing === false) {
      let mapArea = JSON.stringify(formatMap(areaMap));

      if (partnerArea) {
        setSiteName(partnerArea.name);
        setDescription(partnerArea?.parent.type);
      }

      if (!partnerArea) {
        GetAreaSize(mapArea, setAreaSize);
      }

      const areaForSoil = !partnerArea ? mapArea : partnerArea.boundary;
      GetAreaSoil(areaForSoil, setSoilType);
    } else if (isEditing === true && areaDetails) {
      setSoilType(
        areaDetails && typeof areaDetails.recommendedsol === 'object'
          ? areaDetails.recommendedsol
          : JSON.parse(areaDetails.recommendedsol)
      );
      setTauxCailloux(Number(areaDetails.taux_cailloux) ?? 30) // TODO !!! get taux cailloux from soilgrid
      setMaximumRooting(Number(areaDetails.maximum_rooting) ?? 0) // TODO !!! get taux cailloux from soilgrid
      // setAreaSize({
      //     size_in_hectare: Number(item.size / 10000).toFixed(2),
      //     price: '5'
      // })
    }
  }, [areaMap, isEditing, areaDetails]);

  useEffect(() => {
    if (defaultDataSource !== null)
      setAdditionalFields(defaultDataSource.prmdataTypes[1]);
  }, [defaultDataSource]);


  useEffect(() => {
    if (soilType) {
      let data = [];
      // let soilTypeData = isEditing ? soilType : JSON.parse(soilType).soilType;
      let soilTypeData = hasJsonStructure(soilType) ? JSON.parse(soilType).soilType : soilType;

      soilTypeData.map((item, index) => {
        let { depth, sol, elements } = item;
        data.push({ depth, sol, ...elements });
      });
      _.reverse(data);
      setSoilTypeStructure(data);
    }
  }, [soilType, isEditing]);

  const hasJsonStructure = (str) => {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    if (isEditing === true) {
      setAreaMap(null);
    }
  }, [isEditing]);

  const keys = ['Argile', 'Limon', 'Sable'];
  const commonProps = {
    width: 600,
    height: 300,
    margin: { top: 0, right: 80, bottom: 60, left: 80 },
    indexBy: 'depth',
    keys,
    // padding: 0.2,
    layout: 'horizontal',
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    colors: { scheme: 'nivo' },

    defs: [
      {
        id: 'argilePattern',
        type: 'patternLines',
        spacing: 3,
        rotation: 40,
        lineWidth: 1,
        background: '#ffffff',
        color: '#b38266',
      },
      {
        id: 'limonPattern',
        type: 'patternDots',
        size: 3,
        padding: 1,
        stagger: false,
        background: '#ffffff',
        color: '#bfbfbf',
      },
      {
        id: 'sablePattern',
        type: 'patternDots',
        size: 2,
        padding: 0,
        stagger: true,
        background: '#ffffff',
        color: '#ffd70f',
      },
    ],
    fill: [
      // match using object query
      { match: { id: 'Argile' }, id: 'argilePattern' },
      { match: { id: 'Limon' }, id: 'limonPattern' },
      { match: { id: 'Sable' }, id: 'sablePattern' },
      // match using function
      // { match: d => d.id === 'vue', id: 'gradientB' },
      // match all, will only affect 'elm', because once a rule match,
      // others are skipped, so now it acts as a fallback
      // { match: '*', id: 'gradientC' },
    ],
  };

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };
  const newFeature = (map) => {
    let newFeat;
    if (map) {
      newFeat = map._layers[Object.keys(map._layers)[0]].feature;
    }
    if (
      map &&
      map._layers &&
      map._layers[Object.keys(map._layers)[0]] &&
      map._layers[Object.keys(map._layers)[0]]._latlngs &&
      map._layers[Object.keys(map._layers)[0]]._latlngs[0]
    ) {
      let newLatLngs = map._layers[Object.keys(map._layers)[0]]._latlngs[0].map(
        (x) => {
          return [x.lng, x.lat];
        }
      );
      delete newFeat.geometry.coordinates;
      newFeat.geometry.coordinates = [newLatLngs];
    }
    return newFeat;
  };

  const operationCallBack = (r) => {
    if (r) {
      if (r.status === 200) {
        snackbar(
          isEditing
            ? areaDetails.name + ' ' + vocabulary.updateSucc
            : vocabulary.area + " " + vocabulary.addedSucc,
          'success'
        );
        if (r.data[0]) {

          setNewlyCreatedArea(r.data[0]);
        }
        refetchFunc();
        // TODO : reactivate this when Seabex Offers are done!
        // setStep(2);
        setShowFormModal(false)
        // setShowOffers(false) 
        if (!isEditing) {
          setShowDialogCycleForm(true)
        }
        // setStep(3)
        // closeFn();
      } else {
        snackbar(vocabulary.errorOccurred + ' ' + r.status, 'error');
      }
    }
    setSaving(false);
  };

  const getSoilTexture = useCallback(
    () => {
      switch (soilvalue) {
        case SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE:
          return {
            type: SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE,
            data: soilTypeId
          }
        case SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON:
          return {
            type: SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON,
            data: choosenSoilTypeStructure
          }
        case SOIL_TEXTURE_TYPE_SOILGRID:
          return {
            type: SOIL_TEXTURE_TYPE_SOILGRID,
            data: soilTypeStructure
          }
        case SOIL_TEXTURE_TYPE_NETIRRIG:
          return {
            type: SOIL_TEXTURE_TYPE_NETIRRIG,
            data: soilNetirrigItem
          }
        default:
          return {}
      }
    },
    [soilvalue, soilTypeId, choosenSoilTypeStructure, soilTypeStructure, soilNetirrigItem],
  )

  const setSoilTexture = (soil_texture) => {
    switch (soil_texture.type) {
      case SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE:
        setSoilTypeId(soil_texture.data)
        break;
      case SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON:
        setChoosenSoilTypeStructure(soil_texture.data)
        break;
      case SOIL_TEXTURE_TYPE_SOILGRID:
        setSoilTypeStructure(typeof soil_texture.data == 'string' ? JSON.parse(soil_texture.data) : typeof soil_texture.data == 'object' && soil_texture.data)
        break;
      case SOIL_TEXTURE_TYPE_NETIRRIG:
        setNetirrigSolFamily(soil_texture.data.sg_nom)
        setSoilNetirrigId(soil_texture.data.sd_code)
        // setSoilFamilyId(soil_texture.data.sg_nom)
        // setSoilNetirrigItem(soil_texture.data)
        break;
      default:
        break;
    }
  }

  const sendAddArea = () => {

    setSaving(true);
    if (steps[guide.initialStep].element !== '.dataSourceTable') {
      dispatchGuide({ type: INITIAL_STEP, payload: 0 });
      dispatchGuide({ type: CURRENT_STEP, payload: '' });
    }
    if (isEditing === false) {

      if (!partnerArea) {
        areaMap.geometry.coordinates[0] = L.GeoJSON.latLngsToCoords(
          L.GeoJSON.coordsToLatLngs(
            areaMap.geometry.coordinates[0],
            0,
            function (coords) {
              return new L.LatLng(coords[0], coords[1]);
            }
          ),
          0
        );

        areaMap.properties = {
          name: siteName,
          type:
            parentArea && parentArea.type === TYPE_SITE
              ? TYPE_PARCEL
              : parentArea && parentArea.type === TYPE_PARCEL
                ? TYPE_SECTOR
                : TYPE_SITE,
          color:
            parentArea && parentArea.type === TYPE_SITE
              ? 'red'
              : parentArea && parentArea.type === TYPE_PARCEL
                ? 'yellow'
                : 'green',
        };
      }

      let area = {
        name: siteName,
        description: description,
        boundary: !partnerArea ? JSON.stringify(formatMap(areaMap)) : JSON.stringify(partnerArea.boundary),
        parent_id: parentId ? parentId : null,
        type: type,
        soilTypeRecommanded: !partnerArea ? JSON.parse(soilType).soilType : [], // soilTypeId
        sol_id: soilvalue === 'choosen' ? soilTypeId : null,
        dataOrigin: dataTypeTable,
        taux_cailloux: tauxCailloux,
        maximum_rooting: maximumRooting,
        soil_texture: getSoilTexture()
      }

      if (partnerArea) {
        area = {
          ...area,
          partner_area: partnerArea
        }
      }

      createArea(area, operationCallBack);

    } else {
      updateAreaItem(
        {
          id: areaDetails.id,
          name: siteName,
          description: description,
          boundary: (areaMap && newFeature(areaMap)) ? JSON.stringify(newFeature(areaMap)) : areaDetails.boundary,
          parent_id: parentId && areaDetails.type !== TYPE_SITE ? parentId : null,
          type: areaDetails.type,
          sol_id: soilvalue === 'choosen' ? soilTypeId : null,
          soilType: soilType,
          taux_cailloux: tauxCailloux,
          maximum_rooting: maximumRooting,
          soil_texture: getSoilTexture()
        },
        (r) => operationCallBack(r)
      );

    }
  };
  const handleChange = (event) => {
    setSoilValue(event.target.value);
  };

  const formatMap = (revMap) => {
    if (revMap && revMap.geometry && revMap.geometry.coordinates) {
      let formattedLastCreatedLayer = revMap;
      formattedLastCreatedLayer.geometry.coordinates[0] =
        revMap.geometry.coordinates[0].map((item) => [item[1], item[0]]);
      return formattedLastCreatedLayer;
    }
  };

  const changeStep = (step) => {
    setStep(step);
  };



  useEffect(() => {
    if (step == 1) {
      formatingDataSourceTable();
    }
  }, [defaultDataSource, step]); // N’exécute l’effet que si acceptedFiles a changé

  const formatingDataSourceTable = () => {
    let columnsHeader = [
      { title: vocabulary.datatype, field: 'dataType' },
      { title: vocabulary.sourceLabel, field: 'source' },
    ];
    let columnsData = [];

    dataTypeTable = [];
    defaultDataSource &&
      defaultDataSource.prmdataTypes[0].map((rows, i) => {
        let item = {
          column: i,
          prm_data_type_id: rows.id,
          source_id: selectedSourceId,
        };

        dataTypeTable.push(item);
        columnsData.push({
          dataType: JSON.parse(rows.name)['name_' + currentLanguage],
          source: (
            <StyledSelect
              // value={selectedSourceId}
              register={register}
              errors={errors.sourceId}
              onChange={(event) => {
                setSelectedSourceId(event.target.value);
                item.source_id = event.target.value;
                // item.prm_data_type_name = newValue !== null ?newValue.identifier : null
                //  handleDataTypeChange(item)
                dataTypeTable[i].source_id = event.target.value;
              }}
              name='sourceId'
              label={vocabulary.sourceLabel}
              autoWidth
              data={defaultDataSource && defaultDataSource.sources}
              valueField='id'
              labelField='id'
              defaultValue={'SEABEX_DEFAULT'}
            />
          ),
        });
      });


    setCorrespondenceTable([columnsHeader, columnsData]);

    // dataTypeTable.push(columnsHeader, columnsData)
    // `rows` is an array of rows
    // each row being an array of cells.
  };

  const validatingSaving = useCallback(
    () => {
      let valid = (siteName != '');
      switch (soilvalue) {
        case SOIL_TEXTURE_TYPE_SOILGRID:
          valid = valid && (soilTypeStructure != null)
          break;
        case SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON:
          valid = valid && (choosenSoilTypeStructure != null && choosenSoilTypeStructure.length > 0)
          break;
        case SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE:
          valid = valid && (soilTypeId != null && soilTypeList && soilTypeList.data && soilTypeList.data.find(element => element.id == soilTypeId))
          break;
        case SOIL_TEXTURE_TYPE_NETIRRIG:
          valid = valid && (soilNetirrigItem != null)
          break;
        default:
          break;
      }

      logger("Valid >> 1 : ", valid)

      valid = valid && (maximumRooting === 0 || maximumRooting > 5)

      logger("Valid >> 2 : ", valid)

      valid = valid && !saving

      logger("Valid >> 3 : ", valid)

      logger("Validating Saving", valid, soilvalue, soilTypeId, maximumRooting, Number(areaDetails?.maximum_rooting), !saving, soilTypeStructure, soilTypeList, (soilTypeId != null && soilTypeList && soilTypeList.data && soilTypeList.data.find(element => element.id == soilTypeId)))

      return valid
      // SOIL_TEXTURE_TYPE_SOILGRID -> soilTypeStructure     SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON -> choosenSoilTypeStructure  SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE -> soilTypeId
    },
    [siteName, soilvalue, soilTypeStructure, choosenSoilTypeStructure, soilTypeId, soilNetirrigItem, maximumRooting, saving, soilTypeList],
  )


  return (
    <>
      {guide.isEnabled &&
        guide.currentStep === 'addAreaModal' &&
        step === 1 && (
          <AddAreaStepper1 closeFn={closeFn} changeStep={changeStep} />
        )}
      {guide.isEnabled &&
        guide.currentStep === 'addAreaModal' &&
        step === 2 && (
          <AddAreaStepper2
            closeFn={closeFn}
            changeStep={changeStep}
            createAreaGuide={createAreaGuide}
            sendAddArea={sendAddArea}
          />
        )}

      {step == 1 && (
        <StyledModal
          {...properties}
          open={showFormModal}
          withoutContext={withoutContext}
          // modalContext={modalContext ? modalContext : undefined}
          closeFn={closeFn ? () => closeFn() : undefined}
          title={
            isEditing && areaDetails
              ? areaDetails.type === TYPE_SITE
                ? vocabulary.editArea
                : areaDetails.type === TYPE_PARCEL
                  ? vocabulary.editParcel
                  : vocabulary.editSector
              : parentArea && parentArea.type === TYPE_SITE
                ? vocabulary.addParcel
                : parentArea && parentArea.type === TYPE_PARCEL
                  ? vocabulary.addSector
                  : vocabulary.addArea
          }
        >
          <form onSubmit={handleSubmit(sendAddArea)} className='addNewAreaModal'>
            {
              <>
                <Card style={{ marginBottom: 10 }}>
                  <CardHeader title={vocabulary.areaInformation} />
                  <CardContent style={{ display: 'flex' }}>
                    <StyledInput
                      register={register}
                      rules={{ required: true }}
                      name='name'
                      value={siteName}
                      label={vocabulary.titleLabel}
                      onChange={(event) => {
                        setSiteName(event.target.value);
                      }}
                      errors={errors.name}
                      // defaultValue={item && item.name}
                      maxRows={6}
                      multiline={true}
                      style={{ flexGrow: 1 }}
                    />

                    <StyledInput
                      register={register}
                      name='description'
                      label={vocabulary.description}
                      value={description}
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                      errors={errors.description}
                      // defaultValue={item && item.description}
                      maxRows={10}
                      multiline={true}
                      style={{ flexGrow: 1 }}
                    />
                  </CardContent>
                </Card>

                {/* ----------- SEABEX MAP ----------- */}

                <div className='areaOnMap'>
                  <SeabexMap
                    isEditing={isEditing}
                    editingItem={isEditing && areaDetails}
                    embedded={true}
                    staticMap={true}
                    goToSite={true}
                    selectedAreaId={isEditing && areaDetails ? areaDetails?.id : null}
                    initialEditableArea={areaMap}
                    editCallBack={setAreaMap}
                  />
                </div>

                {/* ----------- AREA SIZE ----------- */}

                {areaSize && (
                  <Card className='areaSize'>
                    <span style={{ fontSize: 'x-large', fontWeight: 'bold' }}>
                      {vocabulary.size +
                        ' : ' +
                        Number(areaSize.size_in_hectare) +
                        ' ' +
                        vocabulary.hectar}
                    </span>
                  </Card>
                )}

                {/* ----------- SOIL TYPE AND SELECTION ----------- */}

                <Card className='areaSoilStructure'>
                  <CardHeader title={vocabulary.soilStructure} />
                  <CardContent>
                    <RadioGroup
                      row
                      name='soiltypechoice'
                      value={soilvalue}
                      onChange={handleChange}
                    >
                      {(REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT || REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) &&
                        <FormControlLabel
                          value={SOIL_TEXTURE_TYPE_NETIRRIG}
                          control={<Radio />}
                          label="Choisissez le type de sol Netirrig"
                          onClick={() => setSoilValue(SOIL_TEXTURE_TYPE_NETIRRIG)}
                        />
                      }
                      <FormControlLabel
                        value={SOIL_TEXTURE_TYPE_SOILGRID}
                        control={<Radio />}
                        label={vocabulary.recommendedSoilType}
                        onClick={() => setSoilValue(SOIL_TEXTURE_TYPE_SOILGRID)}
                      />
                      <FormControlLabel
                        value={SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON}
                        control={<Radio />}
                        label={vocabulary.createMyCustomSoilType}
                        onClick={() => setSoilValue(SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON)}
                      />
                      <FormControlLabel
                        value={SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE}
                        control={<Radio />}
                        label={vocabulary.chooseMySoilType}
                        onClick={() => setSoilValue(SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE)}
                      />
                    </RadioGroup>

                    <>

                      {soilvalue === SOIL_TEXTURE_TYPE_SOILGRID && (
                        soilTypeStructure ? (
                          <>
                            <Bar {...commonProps} data={soilTypeStructure} />
                            <IconButton component="div" onClick={() => GetAreaSoil(!partnerArea ? areaDetails.boundary : item.boundary, (structure) => {
                              // let parsedstructure = JSON.parse(structure);
                              setSoilType(structure)
                            })}><Refresh /></IconButton>
                          </>
                        ) : (
                          <StyledLoadingRow
                            customMessage={vocabulary.soilType}
                          />
                        ))}

                      {soilvalue === SOIL_TEXTURE_TYPE_CUSTOM_TEXTURE_BY_HORIZON && (
                        <>
                          <CustomSoilStructure soilSetter={setChoosenSoilTypeStructure} style={{ marginBottom: 20 }} />
                          {(choosenSoilTypeStructure && choosenSoilTypeStructure.length > 0) && (
                            <Bar {...commonProps} data={choosenSoilTypeStructure.reverse()} />
                          )}
                        </>
                      )}

                      {soilvalue === SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE && (
                        <>

                          {soilFamilyList && (
                            <StyledSelect
                              value={soilFamilyId}
                              register={register}
                              rules={{ required: true }}
                              errors={errors.solTexture}
                              onChange={(event) => {
                                setSoilFamilyId(event.target.value);
                                GetSoilTypesList(
                                  event.target.value,
                                  setSoilTypeList
                                );
                              }}
                              render={(item) => {
                                return JSON.parse(item?.name)?.[
                                  'name_' + currentLanguage
                                ];
                              }}
                              name='solTexture'
                              label={vocabulary.solTexture}
                              fullWidth
                              data={soilFamilyList && soilFamilyList.data}
                              valueField='id'
                              labelField='name'
                            />
                          )}

                          {soilFamilyId && (
                            <StyledSelect
                              value={soilTypeId}
                              register={register}
                              rules={{ required: true }}
                              errors={errors.soilType}
                              onChange={(event) => {
                                console.log("soilTypeId", soilTypeId)
                                setSoilTypeId(event.target.value);
                              }}
                              render={(item) => {
                                return JSON.parse(item?.name)?.[
                                  'name_' + currentLanguage
                                ];
                              }}
                              name='soilType'
                              label={vocabulary.soilType}
                              fullWidth
                              data={soilTypeList && soilTypeList.data}
                              valueField='id'
                              labelField='name'
                            />
                          )}

                        </>
                      )}

                      {soilvalue === SOIL_TEXTURE_TYPE_NETIRRIG && (
                        <>

                          {netirrigSolFamilies && <StyledSelect
                            value={netirrigSolFamily}
                            onChange={(event) => {
                              setNetirrigSolFamily(event.target.value);
                            }}
                            name='solNetirrigFamily'
                            label="Famille de sols Netirrig"
                            fullWidth
                            data={netirrigSolFamilies}
                            valueField='sg_nom'
                            labelField='sg_nom'
                          />}
                          <StyledSelect
                            value={soilNetirrigId}
                            register={register}
                            errors={errors.solTexture}
                            onChange={(event) => {
                              setSoilNetirrigId(event.target.value);
                            }}
                            name='solNetirrig'
                            label="Type de sol Netirrig"
                            fullWidth
                            data={netirrigSols.filter(item => item.sg_nom === netirrigSolFamily)}
                            valueField='sd_code'
                            labelField='sd_nom'
                          />
                          {soilNetirrigItem &&
                            <div style={{
                              margin: 5,
                              border: `solid 0.5px #e5e5e5`,
                              padding: 12,
                              borderRadius: 12,
                              backgroundColor: '#f9f7f3'
                            }}>{soilNetirrigItem.sd_descriptif}</div>
                          }

                        </>
                      )}

                      {(soilvalue === SOIL_TEXTURE_TYPE_NETIRRIG || soilvalue === SOIL_TEXTURE_TYPE_CUSTOM_ONE_CHOICE) && (
                        <Grid container direction="row">
                          <Grid container direction="column" >
                            <Typography style={{ textAlign: 'start', marginTop: 12, marginInlineStart: 9 }}>Taux de Cailloux en %</Typography>
                            <div style={{ padding: 12, paddingTop: 40, paddingInlineStart: 40, paddingInlineEnd: 40 }}>
                              <CaillouxSlider key="taux_cailloux_sol_key"
                                value={tauxCailloux}
                                min={0}
                                step={1}
                                max={70}
                                onChange={(event, newValue) => { setTauxCailloux(newValue) }}
                                valueLabelDisplay="on"
                                aria-labelledby="taux_cailloux_sol"
                                getAriaValueText={valuetext}
                                marks={[
                                  { value: 3, label: '3' },
                                  { value: 5, label: '5' },
                                  { value: 7, label: '7' },
                                  { value: 10, label: '10' },
                                  { value: 15, label: '15' },
                                  { value: 20, label: '20' },
                                  { value: 25, label: '25' }]}
                              />
                            </div>
                            <SolTexture tx_cailloux={tauxCailloux} style={{ width: 570, margin: `0 auto` }} />
                          </Grid>
                        </Grid>

                      )}

                    </>
                  </CardContent>
                </Card>

                <Card style={{ marginTop: 12 }}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid container direction="column" >
                        <Typography style={{ textAlign: 'start' }}>{vocabulary.solMaximumDepth} </Typography>
                        <Grid style={{ paddingTop: 40 }} container direction='column'>
                          <Grid item style={{ flexGrow: 1 }} container direction='row'>
                            <Grid item style={{ flexGrow: 1 }}>
                              <MaximumRootingSlider key="maximum_rooting_key"
                                value={maximumRooting}
                                min={1}
                                step={1}
                                max={150}
                                onChange={(event, newValue) => { setMaximumRooting(newValue) }}
                                valueLabelDisplay={noLimitRooting ? "off" : "on"}
                                aria-labelledby="maximum_rooting"
                                getAriaValueText={valuetext}
                                disabled={noLimitRooting}
                                marks={[{ value: 50, label: '50 cm' }, { value: 100, label: '100 cm' }, { value: 150, label: '150 cm' }]}
                              />
                            </Grid>
                            {maximumRooting < 5 && !noLimitRooting &&
                              <Grid item>
                                <Report style={{ color: orange[500], marginInlineStart: 20 }} alt='Sélectionner une valeur supérieure à 5%' />
                              </Grid>
                            }
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={noLimitRooting}
                                  onChange={(event) => { setMaximumRooting(event.target.checked ? 0 : 1); setNoLimitRooting(!noLimitRooting) }}
                                  name="pasdelimite"
                                  color="primary"
                                />
                              }
                              label={vocabulary.unknownDepth}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                  </CardContent>
                </Card>


              </>
            }

            {/* ----------- BUTTON BAR ----------- */}

            {step == 1 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <Button
                  style={{ margin: '5px' }}
                  color='secondary'
                  onClick={() => closeFn()}
                  variant='contained'
                >
                  <HighlightOff /> &nbsp; {vocabulary.cancel}
                </Button>

                {isEditing === false ? (
                  <Button
                    className='saveBtnAddArea'
                    style={{ margin: '5px', fontSize: '15px' }}
                    color='primary'
                    // type='submit'
                    variant='contained'
                    disabled={!validatingSaving()}
                    onClick={() => sendAddArea()}
                  >
                    <Done /> &nbsp; {vocabulary.save}
                  </Button>
                ) : (
                  <Button
                    style={{ margin: '5px', fontSize: '15px' }}
                    color='primary'
                    type='submit'
                    variant='contained'
                    disabled={!validatingSaving()}
                  >
                    <Done /> &nbsp;{' '}
                    {isEditing === false ? vocabulary.save : vocabulary.editLabel}
                  </Button>
                )}
              </div>
            )}
          </form>
        </StyledModal>

      )}


      {/* ----------- ADD OFFERS IN FIRST PAGE -- SAVE IF NO OFFERS SELECTED ----------- */}

      {step == 2 && newlyCreatedArea && showOffers && (
        <SeabexOffers
          area_id={newlyCreatedArea.id}
          open={showOffers}
          withoutContext={true}
          closeFn={() => { setShowOffers(false); setShowDialogCycleForm(true); }}
        />
      )}

      {/* ----------- ADD OFFERS IN FIRST PAGE -- SAVE IF NO OFFERS SELECTED ----------- */}

      {step == 3 && newlyCreatedArea && showCycleForm && (
        <CycleForm
          open={showCycleForm}
          onClose={() => { setShowCycleForm(false); refetchFunc(); closeFn() }}
          area_id={newlyCreatedArea.id}
          isEditing={false}
          setIsLoading={setIsLoading}
        />
      )}

      {showDialogCycleForm &&
        <StyledDialog
          title={vocabulary.creationCycle}
          actions={
            <>
              <Button onClick={() => { refetchFunc(); closeFn(); }} style={{ alignSelf: 'start' }}>{vocabulary.dialog_notNow}</Button>
              <Button onClick={() => { setShowCycleForm(true); refetchFunc(); setStep(3) }} style={{ alignSelf: 'end' }}>{vocabulary.dialog_okAcceptance}</Button>
            </>}
          open={showDialogCycleForm}
          handleClose={() => { setShowDialogCycleForm(false); closeFn() }}
        >
          <Typography>{vocabulary.productionCycleCreateSuggestion}</Typography>
        </StyledDialog>
      }
    </>
  );
};

export default AddArea;



const CaillouxSlider = withStyles({
  root: {
    color: '#fc4e03',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FFF',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);



const MaximumRootingSlider = withStyles({
  root: {
    color: '#9e1200',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FFF',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function valuetext(value) {
  return `${value} %`;
}
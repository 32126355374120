/* eslint-disable eqeqeq*/
/* eslint-disable  no-useless-concat */

import React, { useEffect, useState } from 'react';

import moment from 'moment';
// import { useSelector } from 'react-redux';

import { CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Line, ReferenceLine, ReferenceArea, Label, ComposedChart } from 'recharts';
import { vocabulary } from '../../Strings';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

export const TemperatureEvolutionOverDay = ({ entries }) => {

  const [now, setNow] = useState(moment().format("YYYY-MM-DD HH:00"))
  const [afterNow, setAfterNow] = useState(moment().format("YYYY-MM-DD HH:mm"))
  const [afterXDays, setAfterXDays] = useState(moment().add(7, 'day').format("YYYY-MM-DD HH:00"))

  const [state, setState] = useState({
    temperatureState: true,
    dewPoinStatete: true,
    humidityState: true,
    windSpeedState: false,
    rainPrecipitationState: false


  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const RADIAL_DOT_SIZE = 3;
  const DOT_SHAPE = { strokeWidth: 2, r: 2 };
  // const currentLanguage = useSelector(state => state._globalState.preferred_language)



  useEffect(() => {
    const timer = setTimeout(() => setNow(moment().format("YYYY-MM-DD HH:00")), 60000);
    return () => clearTimeout(timer);
  }, [])
  useEffect(() => {
    setAfterNow(moment().format("YYYY-MM-DD HH:00"))
    setAfterXDays(moment().add(7, 'day').format("YYYY-MM-DD HH:00"))

  }, [now])

  useEffect(() => {
    formatData(entries)
  }, [entries])

  const formatData = (entries) => {

    const result = []
    entries.forEach(el => {
      if (el) {
        result.push({
          time: el.datetime,
          dewPoint: Number(Number(el.dewPoint.value).toFixed(2)),
          humidity: Number(Number(el.relativeHumidity.value).toFixed(2)),
          windSpeed: Number(Number(el.windSpeed.value * 3.6).toFixed(2)),
          temperature: Number(Number(el.temperature.value).toFixed(2)),
          precipIntensity: Number(Number(el.totalPrecipitation.value * 24).toFixed(2))

        })
      }

    })
    return result
  }

  return (
    <div style={{ width: '100%', height: '350px' }}>
      <div className='WaterNeedComposedChart_checkBoxChoise'>
        <FormGroup row >

          <FormControlLabel
            control={
              <Checkbox
                checked={state.temperatureState}
                onChange={handleChange}
                name="temperatureState"
                color="primary"
              />
            }
            label={vocabulary.temperature}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.dewPoinStatete}
                onChange={handleChange}
                name="dewPoinStatete"
                color="primary"
              />
            }
            label={vocabulary.dewPoint}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.humidityState}
                onChange={handleChange}
                name="humidityState"
                color="primary"
              />
            }
            label={vocabulary.humidity}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.windSpeedState}
                onChange={handleChange}
                name="windSpeedState"
                color="primary"
              />
            }
            label={vocabulary.windSpeed}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.rainPrecipitationState}
                onChange={handleChange}
                name="rainPrecipitationState"
                color="primary"

              />
            }
            label={vocabulary.rainPrecipitation}
          />


        </FormGroup>
      </div>
      <ResponsiveContainer>
        {entries &&
          <ComposedChart
            width={500}
            height={500}
            data={formatData(entries)}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis dataKey={'time'} />

            <Tooltip />
            <Legend verticalAlign="bottom" height={36} />

            <YAxis
              // yAxisId='tempYAxis'
              type='number'
              domain={['auto', 'auto']}
              width={100}
              unit='°C'
              label={{ value: vocabulary.temperature + ' /  ' + vocabulary.dewPoint, angle: -90, position: 'center' }}
            >
            </YAxis>

            {state.rainPrecipitationState &&
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                yAxisId='rainPrecipitationYAxis'
                width={100}
                // orientation='left' 
                // unit='mm'
                label={{ value: vocabulary.rainPrecipitation + ' ( mm )', angle: 90, position: 'center' }}
              >
              </YAxis>
            }


            {state.humidityState &&
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                yAxisId='humidityYAxis'
                width={100}
                orientation='right'
                unit='%'
                label={{ value: vocabulary.humidity, angle: 90, position: 'center' }}
              >
              </YAxis>
            }

            {state.windSpeedState &&
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                yAxisId='windSpeedYAxis'
                width={100}
                orientation='right'
                unit={vocabulary.windUnit}
                label={{ value: vocabulary.windSpeed, angle: 90, position: 'insideBottomRight' }}
              >
              </YAxis>
            }


            <ReferenceArea x1={moment(now).format('YYYY-MM-DD 00:00')} x2={moment(now).add(1, 'day').format('YYYY-MM-DD 00:00')} fill="rgba(0,30,39,0.25)" label={<Label value={vocabulary.today} offset={10} position="insideTop" />} />
            {/* <ReferenceArea x1={afterNow} x2={afterXDays} fill="rgba(3,70,91,0.35)" label={<Label value={vocabulary.forecastLabel} offset={10} position="insideTop" />} /> */}
            {/* <ReferenceArea x1={ moment(entries[0].time*1000).format("YYYY-MM-DD HH:mm") } x2={moment().format("YYYY-MM-DD HH:mm")} fill="rgba(0,30,39,0.15)" label={<Label value={vocabulary.historicalLabel} offset={30} position="insideTop" />}  /> */}
            {/* <ReferenceLine x={moment().format("YYYY-MM-DD")} strokeWidth={2} stroke="black" label={<Label value="Today" offset={-15} position="top" />} ></ReferenceLine> */}
            {/* <ReferenceArea x1={ currentLanguage == "en" ? moment().format("YYYY-MM-DD") : moment().format("DD-MM-YYYY")} x2={ currentLanguage == "en" ? moment(dateTo).format("YYYY-MM-DD") : moment(dateTo).format("DD-MM-YYYY")} fill="rgba(3,70,91,0.35)" label={<Label value={vocabulary.forecastLabel} offset={30} position="insideTop" />} /> */}

            <ReferenceLine x={now} stroke="green" />
            {
              (new Array(10).fill(1).map( (_, i) => i+1 )).map((index) => <ReferenceLine key={"referenceline" + index} x={moment(now).add(index, 'day').format('YYYY-MM-DD 00:00')} stroke="#ddd" />)
            }
            {/* label={<Label value={vocabulary.now} offset={30} position="insideTop" />}  */}

            {state.dewPoinStatete == true &&
              <Line
                // yAxisId='tempYAxis'
                strokeWidth={4}
                name={vocabulary.dewPoint}
                type='monotone'
                dataKey='dewPoint'
                stroke='#922a2a'
                activeDot={{ r: RADIAL_DOT_SIZE }}
                dot={DOT_SHAPE}
              ></Line>
            }
            {state.temperatureState == true &&
              <Line
                // yAxisId='tempYAxis'
                strokeWidth={4}
                name={vocabulary.temperature}
                type='monotone'
                dataKey='temperature'
                stroke='#269626'
                activeDot={{ r: RADIAL_DOT_SIZE }}
                dot={DOT_SHAPE}
              ></Line>
            }
            {state.humidityState == true &&
              <Line
                strokeWidth={4}
                yAxisId='humidityYAxis'
                name={vocabulary.humidity}
                type='monotone'
                dataKey={'humidity'}
                stroke='#3F51B5'
                activeDot={{ r: RADIAL_DOT_SIZE }}
                dot={DOT_SHAPE}
              ></Line>
            }
            {state.windSpeedState == true &&
              <Line
                strokeWidth={4}
                name={vocabulary.windSpeed}
                type='monotone'
                dataKey={'windSpeed'}
                yAxisId='windSpeedYAxis'
                stroke='#00001a'
                activeDot={{ r: RADIAL_DOT_SIZE }}
                dot={DOT_SHAPE}
              ></Line>
            }
            {state.rainPrecipitationState == true &&
              <Line
                strokeWidth={4}
                name={vocabulary.rainPrecipitation}
                type='monotone'
                dataKey={'precipIntensity'}
                yAxisId='rainPrecipitationYAxis'
                stroke='#4d4d00'
                activeDot={{ r: RADIAL_DOT_SIZE }}
                dot={DOT_SHAPE}
              ></Line>
            }

          </ComposedChart>
        }
      </ResponsiveContainer>
    </div>
  );
};

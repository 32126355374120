/* eslint-disable no-useless-escape*/

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import './../../App.css';

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../ui/layout'
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
// import StyledSelect from '../../ui/forms/StyledSelect'
import Select2 from '@material-ui/core/Select';
import { ListItemText, MenuItem, InputLabel } from '@material-ui/core/'
import { CreateAgent, GetCountriesList, GetGovernatesList } from "../../globalsTools/network";

const AddAgentsForm = ({ onClose, open, isEditing, reftchFunc, organizationsandFollowers }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [selectedVilleIndex, setSelectedVilleIndex] = useState('')
    const [mobile, setMobile] = useState('')
    const [country, setCountry] = useState('')
    const [organizationId, setOrganizationId] = useState('')
    const [countries, setCountries] = useState(null)
    const [governateList, setGovernateList] = useState(null)
    const [firstName, setFirstname] = useState(null)
    const [lastName, setLastname] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [address, setAddress] = useState(null)




    // const countries = useSelector(state => state.countries.data)

    useEffect(() => {
        if (isEditing === false) {

            setCountry('')
            setSelectedVilleIndex('')
            setMobile('')
            setOrganizationId('')
        }

    }, [open, isEditing]);



    useEffect(() => {
        GetCountriesList(currentLanguage, setCountries)

    }, [currentLanguage])
    const newAgent = (data) => {
        CreateAgent(
            {
                firstname: firstName,
                lastname: lastName,
                email: email,
                city: selectedVilleIndex,
                country: country,
                password: password,
                mobile: mobile,
                address: JSON.stringify({ 'addresse': address }),
                organization_id: organizationId
            }).then(() => reftchFunc())
        onClose()
    }
    const actions = [
        { title: vocabulary.add, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                title={vocabulary.newAgent}
                width={600}
            >
                <form
                    onSubmit={handleSubmit(newAgent)}
                    ref={form}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="firstname"
                        label={vocabulary.firstname}
                        type="text"
                        fullWidth
                        errors={errors.firstname}
                        onChange={(event) => setFirstname(event.target.value)}
                        value={firstName}


                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="lastname"
                        label={vocabulary.lastname}
                        type="text"
                        fullWidth
                        errors={errors.lastname}
                        onChange={(event) => setLastname(event.target.value)}
                        value={lastName}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true, validate: validateEmail }}
                        name="email"
                        label={vocabulary.email}
                        type="email"
                        fullWidth
                        errors={errors.email}
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}


                    />
                    {errors.email &&
                        <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                            {vocabulary.mailInvalid}
                        </span>
                    }
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="password"
                        label={vocabulary.password}
                        type="password"
                        fullWidth
                        errors={errors.password}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}

                    />


                    <PhoneInput
                        country="TN"
                        displayInitialValueAsLocalNumber
                        placeholder={vocabulary.enterPhoneNumber}
                        value={mobile}
                        onChange={(event) => {
                            setMobile(event)
                        }}

                        error={mobile ? (isValidPhoneNumber(mobile) ? undefined : vocabulary.invalidPhoneNumber) : vocabulary.requiredPhoneNumber}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="address"
                        label={vocabulary.address}
                        type="text"
                        fullWidth
                        errors={errors.address}
                        onChange={(event) => {
                            setAddress(event)
                        }}

                    />

                    <StyledAutoComplete
                        stye={{ width: 80 }}
                        data={countries ? countries : []}
                        optionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setCountry(newValue.id)

                            GetGovernatesList(currentLanguage, newValue.identifier, setGovernateList)

                        }}
                        label={vocabulary.country}

                    />

                    <StyledAutoComplete
                        stye={{ width: 80 }}
                        data={governateList && governateList.length > 0 ? governateList : []}
                        optionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setSelectedVilleIndex(newValue.label)

                        }}
                        label={vocabulary.region}
                        disabled={governateList == null ? true : false}


                    />
                    {organizationsandFollowers &&
                        <div required
                            style={{ margin: '8px', width: '100%' }}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">{vocabulary.organization}</InputLabel>

                            <Select2
                                value={organizationId}
                                onChange={(event) => {
                                    setOrganizationId(event.target.value)


                                }}
                                fullWidth
                                label={vocabulary.organization}
                            >
                                {
                                    organizationsandFollowers && organizationsandFollowers.organizationsAndFollowers.map((item, index) => (

                                        <MenuItem key={index} value={item.id}>
                                            <ListItemText primary={JSON.parse(item.name).name} />
                                        </MenuItem>
                                    ))
                                }
                            </Select2>
                        </div>}
                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
        </>
    )
}
export default AddAgentsForm

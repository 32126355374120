/* eslint-disable eqeqeq*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';

import { setCurrentPage } from '../../../actions';
import AlertDialog from "../../Components/alertDialog";
import CultureTypeList from './CultureTypeList'
import CultureFamilyAddForm from './CultureFamilyAdd'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledMaterialTable } from '../../../ui/display';
import { GetCultureFamilliesList, DeleteCultureFamily } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { StyledCard } from "../../../ui/layout";
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const CultureFamilyManagementList = (props) => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [CultureFamilies, setCultureFamilies] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userCultureManagement));
        GetCultureFamilliesList(setCultureFamilies)
        document.title = vocabulary.userCultureManagement

    }, [dispatch]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.userCultureManagement));
        document.title = vocabulary.userCultureManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)['name_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetCultureFamilliesList(setCultureFamilies)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteCultureFamily = (item) => {

        DeleteCultureFamily(item.id
            , r => operationCallBack(r)
        )
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }


    return (

        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {(!isLoading && CultureFamilies) ?
                    <StyledMaterialTable
                        title={vocabulary.cultureFamily}
                        data={CultureFamilies.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}
                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: ({ rowData }) => { return (<CultureTypeList itemRow={rowData} />) }
                            }]}

                        columns={[
                            { title: vocabulary.name_label, field: 'name', render: rowData => <>{JSON.parse(rowData.name)['name_' + currentLanguage]}</> },
                            { title: vocabulary.description, field: 'name', render: rowData => <>{rowData.description ? JSON.parse(rowData.description).description : '--'}</> },

                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editCultureFamily,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addCultureFamily,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            },


                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            detailPanelType: 'single',
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],


                        }}
                    /> : <StyledCircularProgress size={50} label={vocabulary.loading} />

                }



                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item.name)["name_" + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteCultureFamily(item)
                                    ;
                            }

                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                {(openAdd == true || openEdit == true) &&
                    <CultureFamilyAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetCultureFamilliesList(setCultureFamilies)}
                        setIsLoading={setIsLoading}

                    />}


            </StyledCard>
        </ModalContext.Provider>

    )
}
export default CultureFamilyManagementList
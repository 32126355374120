/* eslint-disable eqeqeq*/
/* eslint-disable no-sequences */

import React, { useEffect, useRef, useState, useContext, useCallback, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../../ui/layout'
import { createCultureType, UpdateCultureType } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { Check, Close, Done, HighlightOff } from '@material-ui/icons';
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import StyledChekbox from '../../../ui/forms/StyledChekbox';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { logger } from '../../../globalsTools/utilities';

const CultureTypeAddForm = ({ isEditing, familyId, item, open, width, onClose, refetchFunc, setIsLoading }) => {


    const form = useRef(null);
    const { register, errors, handleSubmit, control } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [step, setStep] = useState(1)
    const [frenshName, setFrenshName] = useState('')
    const [englishName, setEnglishName] = useState('')
    const [arabicName, setArabicName] = useState('')
    const [description, setDescription] = useState('')
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [totalDuration, setTotalDuration] = useState(0)
    const [params, setParams] = useState('')
    // {"debut_bilan":"01-04 ","editable_date":"True","duree_bilan":193}
    const [debutBilan, setDebutBilan] = useState('')
    const [rfuMax, setRfuMax] = useState(0)
    const [editableDate, setEditableDate] = useState(true)

    useEffect(() => {
      if (debutBilan && editableDate!=null && totalDuration) {
        setParams({...params, "debut_bilan":debutBilan,"editable_date":editableDate,"duree_bilan":totalDuration, "etr_maxi":rfuMax})
      }
    }, [debutBilan, editableDate, totalDuration, rfuMax])

    useEffect(() => {
      logger('Params Modified', params)
    }, [params])
    
    

    const [cultureDetailList, setCultureDetailList] = useState([{
        order: cultureDetailList?.length,
        name: '',
        length: 0,
        kc: 0,
        rootDepth: 0,
        degree: 0,
        sensibilite: 0,
        need_irrigation: true
    }])

    useEffect(() => {
        if (isEditing === false) {

            setStep(1)
            setFrenshName("")
            setArabicName('')
            setEnglishName('')
            setDescription('')
            setCultureDetailList([{
                order: cultureDetailList?.length - 1,
                name: '',
                length: 0,
                kc: 0,
                rootDepth: 0,
                degree: 0,
                need_irrigation: true
            }])
            setTotalDuration(0)
            setSelectedIndex(null)

        }

    }, [open, isEditing]); // N’exécute l’effet que si open a changé

    useEffect(() => {
        if (item && isEditing) {

            setStep(1)
            setFrenshName(JSON.parse(item.name).name_fr)
            setArabicName(JSON.parse(item.name).name_ar)
            setEnglishName(JSON.parse(item.name).name_en)
            setDescription(item.description !== null ? JSON.parse(item.description).description : '')
            if (item.params) {
                try {
                    let parsedParams = JSON.parse(item.params)            
                    setParams(parsedParams)
                    setDebutBilan(parsedParams.debut_bilan)
                    setEditableDate(parsedParams.editable_date)
                    setRfuMax(parsedParams.etr_maxi)
                } catch (error) {
                    
                }
            }
            setCultureDetailList(item.kc ? JSON.parse(item.kc) : [{
                order: 0,
                name: '',
                length: '',
                kc: '',
                rootDepth: '',
                degree: '',
                need_irrigation: true,
            }])

        }

    }, [item, isEditing]); // N’exécute l’effet que si item a changé

    useEffect(() => {
        handleCalculeTotalDuration()
    }, [cultureDetailList])
    

    const changeStep = (step) => {
        setStep(step)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const validForm = () => {
        switch (step) {
            case 1:
                {
                    if (!englishName || !frenshName || !arabicName) {
                        return true
                    }
                    else return false
                }
            case 2:
                {
                    if (!cultureDetailList) {
                        return true
                    }
                    else return false
                }

            default:
                return step
        }


    }
    const operationCallBack = (r) => {
        if (r) {
            if ([200, 201].includes(r.status)) {
                snackbar(isEditing == true ? JSON.parse(item.name)["name_" + currentLanguage] + "  " + vocabulary.updateSucc : vocabulary.culture + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }
    const newCultureType = () => {
        setIsLoading(true)
        let cultureKcDetail = []
        cultureDetailList.map(el => {

            delete el.tableData;
            return cultureKcDetail.push(el)
        })
        //  let total = Number(durationPhaseArriereSaison) + Number(durationInitialStage) + Number(durationDevelopmentPhase) + Number(durationMidSeasonPhase)
        createCultureType(
            {
                name: JSON.stringify({ "name_fr": frenshName, "name_en": englishName, "name_ar": arabicName }),
                description: JSON.stringify({ "description": description }),
                kc: cultureKcDetail,
                prm_culture_family_id: familyId,
                params
            }, r => operationCallBack(r)
        )
        refetchFunc()
        onClose()


    }
    const handleUpdateCultureType = () => {

        //  let total = Number(durationPhaseArriereSaison) + Number(durationInitialStage) + Number(durationDevelopmentPhase) + Number(durationMidSeasonPhase)

        setIsLoading(true)
        let cultureKcDetail = []


        cultureDetailList.map(el => {

            //delete el.tableData;
            return cultureKcDetail.push({
                ...el,
                need_irrigation: el.hasOwnProperty('need_irrigation') ? el.need_irrigation : true
            })
        })
        const data = {
            id: item.id,
            name: JSON.stringify({ "name_fr": frenshName, "name_en": englishName, "name_ar": arabicName }),
            description: JSON.stringify({ "description": description }),
            kc: cultureKcDetail,
            prm_culture_family_id: familyId,
            params

        };


        UpdateCultureType(
            data, r => operationCallBack(r)
        )
        refetchFunc()
        setOpenEditConfirmation(false)

        onClose()
    }


    // const addRow = () => {

    //     let obj = {
    //         order: cultureDetailList?.length + 1,
    //         name: '',
    //         length: 0,
    //         kc: 0,
    //         rootDepth: 0,
    //         degree: -1,
    //         sensibilite: 0,
    //         need_irrigation: true
    //     }
    //     setCultureDetailList(cultureDetailList => [...cultureDetailList, obj])
    //     return true
    // }


    const saveRowToList = (data) => {


        if (selectedIndex !== null) {

            const updatedCultureDetailList = [...cultureDetailList];

            updatedCultureDetailList[selectedIndex] = {
                order: selectedIndex,
                length: Number(data.length),
                name: data.phase,
                kc: Number(data.phaseKc),
                rootDepth: Number(data.rootDepth),
                degree: Number(data.degree),
                sensibilite: Number(data.sensibilite),
                need_irrigation: data.need_irrigation ?? true,
            };

            return updatedCultureDetailList;

        }

    }

    const handleCalculeTotalDuration = () => {

        let sum = 0;
        for (const d of cultureDetailList) {

            if (d.length != null) {
                sum += Number(d.length);
            }
        }

        setTotalDuration(sum)
        return sum;
    };




    const COLUMNS = [
        {
            title: vocabulary.phaseNameLabel,
            align: 'center',
            field: 'name',

        },
        {
            title: vocabulary.phaseDurationLabel + ' ( ' + vocabulary.dayLabel + ' )',
            align: 'right',
            field: 'length',
            type: 'numeric'

        },
        {
            title: 'Degré jour',
            align: 'right',
            field: 'degree',
            type: 'numeric'

        },
        {
            title: vocabulary.kc,
            align: 'right',
            field: 'kc',
            type: 'numeric'

        },
        {
            title: vocabulary.rootDepth,
            align: 'right',
            field: "rootDepth",
            type: 'numeric',
        },
        {
            title: vocabulary.sensibilite,
            align: 'right',
            field: "sensibilite",
            type: 'numeric',
        },
        {
            title: vocabulary.need_irrigation,
            align: 'center',
            field: "need_irrigation",
            type: 'boolean',
            render: rowData => <>{rowData.need_irrigation ? vocabulary.yes : vocabulary.no}</>
        }
    ];

    const ACTIONS = [
        // {
        //     icon: () => <AddIcon fontSize='large' color='primary' className="addCycleBtn" />,
        //     tooltip: vocabulary.addLigneLabel,
        //     isFreeAction: true,
        //     onClick: (event) => addRow()
        // },
        {
            icon: () => <Done />,
            tooltip: vocabulary.save,
            onClick: (event, row) => handleSubmit(saveRowToList)()

        },


    ];

    const OPTIONS = {
        actionsColumnIndex: -1,
        search: false,
        paging: true,
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, 40, 60, 80],
    };

    const onRowUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            console.log('onRowUpdate', newData)
            const dataUpdate = [...cultureDetailList];

            // In dataUpdate, find target
            const target = dataUpdate.find(
                (el) => el.order === oldData.order
            );
            const index = dataUpdate.indexOf(target);
            dataUpdate[index] = {
                order: newData.order,
                length: Number(newData.length),
                name: newData.name,
                kc: Number(newData.kc),
                rootDepth: Number(newData.rootDepth),
                degree: Number(newData.degree),
                sensibilite: Number(newData.sensibilite),
                need_irrigation: newData.need_irrigation ?? true,
            };
            setCultureDetailList([...dataUpdate]);
            resolve();

        });
    }
    const onRowAdd = (newData) => {
        return new Promise((resolve, reject) => {
            console.log('onRowAdd', newData)
            newData.id = "uuid-" + Math.random() * 10000000;
            newData.order = cultureDetailList?.length + 1;
            setCultureDetailList([...cultureDetailList, {
                order: newData.order,
                length: Number(newData.length),
                name: newData.name,
                kc: Number(newData.kc),
                rootDepth: Number(newData.rootDepth),
                degree: Number(newData.degree),
                sensibilite: Number(newData.sensibilite),
                need_irrigation: newData.need_irrigation ?? true,
            }]);
            resolve();

        });
    }

    const onRowDelete = (oldData) => {
        return new Promise((resolve, reject) => {

            const dataDelete = [...cultureDetailList];
            const target = dataDelete.find(
                (el) => el.order === oldData.order
            );
            const index = dataDelete.indexOf(target);
            dataDelete.splice(index, 1);
            setCultureDetailList([...dataDelete]);
            refetchFunc()
            resolve();

        });
    }

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={step === 1 ? width : '100%'}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addCulture : vocabulary.editCulture}
            >
                <form
                    // onSubmit={handleSubmit(newCultureType)}
                    ref={form}
                >
                    {step === 1 &&
                        <>
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.englishName}
                                name="englishName"
                                value={englishName}
                                onChange={(event) => {
                                    setEnglishName(event.target.value)
                                }}
                                label={vocabulary.name_en}
                                type="text"
                                fullWidth

                            />
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.frenshName}
                                label={vocabulary.name_fr}
                                name='frenshName'
                                value={frenshName}
                                onChange={(event) => {
                                    setFrenshName(event.target.value)
                                }}
                                type="text"
                                fullWidth


                            />
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.arabicName}
                                name="arabicName"
                                label={vocabulary.name_ar}
                                value={arabicName}
                                onChange={(event) => {
                                    setArabicName(event.target.value)
                                }}
                                type="text"
                                fullWidth

                            />


                            <StyledInput
                                register={register}
                                errors={errors.description}
                                name="description"
                                label={vocabulary.description}
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                                value={description}
                                type="text"
                                fullWidth
                                multiline={true}
                                maxRows={10}

                            />

                        </>
                    }
                    {
                        step === 2 &&
                        <Grid container direction='column'>
                            <Grid item container direction='row'>
                                <Grid item>
                                    <StyledInput
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.debutBilan}
                                        name="debutBilan"
                                        label={"Date de début de bilan (DD-MM)"}
                                        value={debutBilan}
                                        onChange={(event) => {
                                            setDebutBilan(event.target.value)
                                        }}
                                        type="text"
                                        
                                    />
                                </Grid>
                                <Grid item>
                                    <StyledInput
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.rfuMax}
                                        name="rfuMax"
                                        label={"Max de RFU"}
                                        value={rfuMax}
                                        onChange={(event) => {
                                            setRfuMax(event.target.value)
                                        }}
                                        type="number"
                                        
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={String(editableDate).toLowerCase() == "true"}
                                                onChange={() => {
                                                    setEditableDate(!(String(editableDate).toLowerCase() == "true"))
                                                }}
                                                name="editableDate"
                                                color="primary"
                                            />
                                        }
                                        label={"Date de début modifiable ?"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <StyledMaterialTable
                                    title={vocabulary.add + ' ' + vocabulary.kc}
                                    data={cultureDetailList}
                                    columns={COLUMNS}
                                    actions={ACTIONS}
                                    options={OPTIONS}
                                    // icons={{Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />)}}

                                    editable={{
                                        onRowAdd,
                                        onRowDelete,
                                        onRowUpdate
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '80%' }}>
                                    {cultureDetailList.length > 0 &&
                                        <div style={{ display: 'flex', width: '45%', alignItems: 'center', justifyContent: 'end' }}>
                                            <b style={{ fontSize: 'x-large' }}>{vocabulary.total}  : &nbsp; </b>
                                            <span style={{ fontSize: 'x-large' }}>{totalDuration} </span>
                                            <span style={{ fontSize: 'medium' }}> &nbsp; {vocabulary.dayLabel}</span>
                                        </div>
                                    }
                                </div>
                            </Grid>


                        </Grid>

                    }

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button
                            style={{ margin: '5px' }}
                            onClick={onClose}
                            color="primary"
                            variant="contained"
                        >
                            <HighlightOff /> &nbsp;  {vocabulary.cancel}
                        </Button>

                        {step === 2 &&
                            <>
                                <Button
                                    style={{ margin: '5px' }}
                                    color="secondary"
                                    onClick={() => changeStep(1)}
                                    variant="contained"
                                >
                                    <SkipPreviousIcon /> &nbsp;   {vocabulary.previous}

                                </Button>
                                <Button
                                    style={{ margin: '5px' }}
                                    color="primary"
                                    onClick={() => (isEditing == false ? newCultureType() : setOpenEditConfirmation(true))}
                                    variant="contained"
                                    disabled={isEditing == false ? validForm() : false}

                                >
                                    <Done /> &nbsp;  {isEditing === false ? vocabulary.save : vocabulary.editLabel}

                                </Button>


                            </>
                        }

                        {step === 1 &&
                            <Button
                                style={{ margin: '5px' }}
                                color="secondary"
                                onClick={() => changeStep(2)}
                                variant="contained"
                                disabled={isEditing == false ? validForm() : false}

                            >
                                <SkipNextIcon /> &nbsp; {vocabulary.next}

                            </Button>
                        }


                    </div>
                </form>
            </StyledModal>
            {
                openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={JSON.parse(item.name)['name_' + currentLanguage]}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateCultureType();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />
            }
        </>
    )

}
export default CultureTypeAddForm

import React from 'react'
import { Button } from '@material-ui/core';
import { Done, HighlightOff } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeid } from '../../globalsTools/utilities';
function    StyledModalFooter(props) {

    const { styles, actions, children, icon } = props

    let style = {
        ...styles,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 12,
        marginBottom:12
    }

    return (
        <>
            {children}
            <div style={style} key={makeid()}>
                <div key={makeid()}>
                    {actions &&
                        actions.filter(item => item.position === 'start').map(
                            (item, index) => <Button key={index + makeid()} variant={item.variant ? item.variant : "contained"}
                                onClick={item.onClick}
                                color={item.color}
                                type={item.type}
                                style={{ alignSelf: 'start', ...item.style }}>
                               {item.singup ? <ExitToAppIcon/>: item.icon ? item.icon : <HighlightOff />} &nbsp; {icon ? icon : ''} {item.title}
                            </Button>
                            )
                    }
                </div>
                <div key={makeid()}>
                    {actions &&
                        actions.filter(item => item.position === 'end').map(
                            (item, index) => <Button key={index + makeid()} variant={item.variant ? item.variant : "contained"}
                                onClick={item.onClick}
                                color={item.color}
                                type={item.type}
                                disabled={item.disabled}
                                style={{ alignSelf: 'end', ...item.style }}>
                                <Done />&nbsp; {icon ? icon : ''} {item.title}
                            </Button>)}
                </div>
            </div>
        </>
    )
}


export default StyledModalFooter


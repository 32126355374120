/* eslint-disable no-useless-concat*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';

import { StyledMaterialTable } from '../../ui/display';
import ResponsiveCalendar from '../Charts/Examples/Calendar/Calendar'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import AlertDialog from "../Components/alertDialog";
import AddIrrigationForm from './AddIrrigationForm'
import { DeleteIrrigation, GetIrrigationByAreaId, UpdateIrrigation } from '../../globalsTools/network'
import { useSnackbar } from 'notistack';
import MultipleIrrigationForm from './MultipleIrrigationForm'
import { DateTimePicker } from '@material-ui/pickers';
import { DEV_ENVIRONMENT, PROD_ENVIRONMENT } from '../globalConsts';
import { REACT_APP_ENV_VERSION } from '../../config';
// import IrrigationList from './IrrigationList'
const IrrigationListTable = ({ selectedAreaId, selectedDataTypeId, dateFrom, dateTo, startGateData, deviceId, selectedDataTypeIdByDevice, selectedGetewayId, getingSiteList, sitesList, reloadFn = null }) => {

    const moment = require('moment');
    const tableRef = React.createRef();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [irrigationList, setIrrigationList] = useState(null);
    const [isEditing, setIsEditing] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [pageSize, setPageSize] = useState(10);
    const [isEditingMultiple, setIsEditingMultiple] = useState(false)
    const [openAddMultipleForm, setOpenAddMultipleForm] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };

    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const changeModalStatusMultipleForm = (newStatus) => {
        if (newStatus == null) {
            setOpenAddMultipleForm(!openAddMultipleForm)
            setIsEditingMultiple(!isEditingMultiple)

        } else {
            setOpenAddMultipleForm(newStatus)
            setIsEditingMultiple(newStatus)
            setIsEditingMultiple(newStatus)
        }
    }

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const onEditMultiple = (row) => {
        setOpenAddMultipleForm(true)
        setIsEditingMultiple(true)
        setItem(row)
    }

    useEffect(() => {
        if (startGateData) {
            tableRef.current.onQueryChange();
        }

    }, [startGateData]);

    const handleDeleteIrrigation = (item) => {

    }

    const operationCallBack = (r) => {

        if (r && r.status === 200) {
            snackbar(vocabulary.waterVolume + ' ' + vocabulary.addedSucc, 'success')
            const result = tableRef?.current?.onQueryChange();
        }
        else {
            snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
        }
    }

    const handleIrrigationUpdate = (item) => {
        UpdateIrrigation(
            {
                id: item && item.id,
                water_volume: item.water_volume,
                iso_unit: item.iso_unit,
                start_time: moment(item.start_time).format('YYYY-MM-DD HH:mm'),
                end_time: moment(item.end_time).format('YYYY-MM-DD HH:mm'),
                prm_irrigation_type_id: item.prm_irrigation_type_id,
                mode: item.mode


            }, r => operationCallBack(r)
        )

    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    const contextValueEditMultiple = { open: openAddMultipleForm, changeModalStatus: changeModalStatusMultipleForm }
    console.log("==", REACT_APP_ENV_VERSION);
    return (
        <ModalContext.Provider value={openAddMultipleForm ? contextValueEditMultiple : isEditing === false ? contextValueAdd : contextValueEdit}>

            <>
                {irrigationList &&
                    [
                        DEV_ENVIRONMENT,
                        PROD_ENVIRONMENT
                    ].includes(REACT_APP_ENV_VERSION) &&

                    <ResponsiveCalendar
                        data={irrigationList && irrigationList}
                        dateFrom={moment(dateFrom).format('YYYY-MM-DD')}
                        dateTo={moment(dateTo).format('YYYY-MM-DD')}
                    />
                }

                <StyledMaterialTable
                    title={vocabulary.irrigationMangement}
                    tableRef={tableRef}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}
                    data={query => new Promise((resolve, reject) => {
                        GetIrrigationByAreaId(selectedAreaId, dateFrom, dateTo, query.pageSize, (query.page + 1))
                            .then(result => {

                                if (result && result.data) {
                                    setIrrigationList(result.data.data)

                                    resolve({
                                        data: result.data.data,
                                        page: result.data.current_page - 1,
                                        totalCount: result.data.total,
                                        pageSize: result.data.per_page,
                                    })
                                    window.scrollTo(0, 0);
                                }

                            })

                    })
                    }
                    columns={[
                        { title: vocabulary.waterVolumeLabel, field: 'water_volume', render: rowData => <>{rowData.hasOwnProperty('water_volume') ? rowData.water_volume : '--'}</> },
                        { title: vocabulary.irrigationType, field: 'prm_irrigation_type', render: rowData => <>{rowData.prm_irrigation_type !== null ? JSON.parse(rowData.prm_irrigation_type.name)['name_' + currentLanguage] : '--'}</> },
                        {
                            title: vocabulary.startTime,
                            field: 'start_time',
                            render: rowData => <>{rowData.hasOwnProperty('irrigation_date') ? moment(rowData.irrigation_date).format('YYYY-MM-DD') : rowData.start_time && currentLanguage == "en" ? moment(rowData.start_time).format('YYYY-MM-DD HH:mm') : moment(rowData.start_time).format('DD-MM-YYYY HH:mm')}</>,
                            editComponent: props => (
                                <DateTimePicker
                                    value={moment(props.value)}
                                    onChange={date => props.onChange(moment(date))}
                                    ampm={false}
                                    label={vocabulary.startDate}
                                    maxDate={moment()}
                                    variant='outlined'
                                />
                            )
                        },
                        {
                            title: vocabulary.endTime,
                            field: 'end_time',
                            render: rowData => <>{rowData.hasOwnProperty('irrigation_date') ? irrigationList && irrigationList[irrigationList.length].irrigation_date : rowData.end_time && currentLanguage == "en" ? moment(rowData.end_time).format('YYYY-MM-DD HH:mm') : moment(rowData.end_time).format('DD-MM-YYYY HH:mm')}</>,
                            editComponent: props => (
                                <DateTimePicker
                                    value={moment(props.value)}
                                    onChange={date => props.onChange(moment(date))}
                                    ampm={false}
                                    label={vocabulary.startDate}
                                    maxDate={moment()}
                                    variant='outlined'
                                />
                            )
                        },

                    ]}

                    options={{
                        pageSize: pageSize,
                        pageSizeOptions: [5, 10, 20, 40, 60, 80, 100],
                        actionsColumnIndex: -1,
                    }}

                    editable={{

                        onRowUpdate: (newData) => {
                            return new Promise((resolve) => {
                                handleIrrigationUpdate(newData)
                                resolve();
                            });
                        },
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    DeleteIrrigation(oldData.id
                                        , function (r) {

                                            if (r && r.status === 204) {
                                                snackbar(vocabulary.deleteMsg, 'success')

                                            } else {
                                                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                                            }

                                        })

                                    resolve();
                                }, 1000);
                            })
                    }}
                />

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.prm_irrigation_type ? JSON.parse(item.prm_irrigation_type.name)['name_' + currentLanguage] : vocabulary.waterVolume}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteIrrigation(item);
                            }


                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}


            </>

            {(openAdd || openEdit) &&
                <AddIrrigationForm
                    open={isEditing === false ? openAdd : openEdit}
                    onClose={() => {
                        return (
                            isEditing === true ?
                                (setOpenEdit(false),
                                    setIsEditing(false))
                                : setOpenAdd(false)

                        )
                    }}
                    item={isEditing === true ? item : null}
                    isEditing={isEditing}
                    AreaId={selectedAreaId}
                    getingSiteList={getingSiteList}
                    sitesList={sitesList}


                />}
            {openAddMultipleForm &&
                <MultipleIrrigationForm
                    open={openAddMultipleForm}
                    onClose={() => {
                        return (
                            setOpenAddMultipleForm(false)

                        )
                    }}
                    item={isEditingMultiple === true ? item : null}
                    isEditing={isEditingMultiple}
                    sitesList={sitesList}

                />}


        </ModalContext.Provider>

    )
}

export default IrrigationListTable
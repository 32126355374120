import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

export default () => {

    const appLoading = useSelector(state => state.app.appLoading);
    
    const style = {
        visibility: appLoading ? "visible" : "hidden",
        position: "absolute",
        left: "50%",
        top: "45%"
    }

    return (
        appLoading ? <div style={style}> <CircularProgress size={50} /> </div> : <></> 
    )
}
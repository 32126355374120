/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from "react-router-dom";
import "intro.js/introjs.css";

import { vocabulary } from '../Strings';
import { setCurrentPage } from '../../actions';
import { GetPublicationByCountry } from '../../globalsTools/network';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import StarIcon from '@material-ui/icons/Star';
import './style.css'
import Link from '@material-ui/core/Link';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { GuideContext, steps } from '../../ui/layout/contextLayout/GuideContext';
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from '../globalConsts';
import HomePageStepper from './HomePageStepper';
import CurrentPage from '../Components/CurrentPage';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';

const styledPub = {
    "alert": {
        icon: <AddAlertIcon />,
        iconStyle: { background: '#03465b', color: '#fff' },
        contentStyle: { background: '#C8D5D9', border: '2px solid #03465b', borderRadius: '10px', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  #03465b' }
    },
    "info": {
        icon: <InfoIcon />,
        iconStyle: { background: '#349ff3', color: '#fff' },
        contentStyle: { background: '#e8f4fd', border: '2px solid #349ff3', borderRadius: '10px', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  #349ff3' }

    },
    "error": {
        icon: <ErrorIcon />,
        iconStyle: { background: '#f45347', color: '#fff' },
        contentStyle: { background: '#fdecea', border: '2px solid #f45347', borderRadius: '10px', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  #f45347' }
    },
    "event": {
        icon: <EventNoteIcon />,
        iconStyle: { background: '#5bb65f', color: '#fff' },
        contentStyle: { background: '#edf7ed', border: '2px solid #5bb65f', borderRadius: '10px', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid #5bb65f' }
    },
    "warning": {
        icon: <WarningIcon />,
        iconStyle: { background: '#ffa016', color: '#fff' },
        contentStyle: { background: '#fff4e5', border: '2px solid #ffa016', borderRadius: '10px', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  #ffa016' }
    },


}
const HomePage = () => {

    const moment = require('moment');
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const dispatch = useDispatch()
    const { guide, dispatchGuide } = useContext(GuideContext)
    const GeolocationApiKey = '56a44b30-a71d-11eb-8c73-0f972c34e9c0'
    const [details, setDetails] = useState(null)
    const [publicationList, setPublicationList] = useState(null)

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.homePageLabel))
        document.title = vocabulary.homePageLabel
        GetPublicationByCountry(setPublicationList)
    }, [dispatch]); // N’exécute l’effet qu'a la première render

    const getUserGeolocationDetails = () => {
        fetch(
            `https://geolocation-db.com/json/${GeolocationApiKey}`
        )
            .then(response => response.json())
            .then(data => setDetails(data));
    };

    useEffect(() => {
        setTimeout(() => {
            if (steps[guide.initialStep].element === ".vertical-timeline-element--work" && guide.currentStep === 'homePage')
                dispatchGuide({ type: IS_ENABLED, payload: true })
        }, 1000);
    }, [])

    useEffect(() => {
        dispatch(setCurrentPage(<CurrentPage name={vocabulary.homePageLabel} helpFn={helpFn} />))
        document.title = vocabulary.homePageLabel
        getUserGeolocationDetails()
    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé



    // const history = useHistory();

    // called when we click on the interrogation '?' on the top to open the Step Guide
    const helpFn = () => {
        dispatchGuide({ type: INITIAL_STEP, payload: steps.findIndex(step => step.element === ".introSeabex") })
        dispatchGuide({ type: CURRENT_STEP, payload: 'homePage' })
        setTimeout(() => {
            dispatchGuide({ type: IS_ENABLED, payload: true })
        }, 500);

    }

    return (
        <>
            {guide.isEnabled &&
                <HomePageStepper />
            }
            { publicationList ? <VerticalTimeline>
                {

                    publicationList && publicationList.data.map((item, index) => {
                        return <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={styledPub[item.type].contentStyle ? styledPub[item.type].contentStyle : null}
                            contentArrowStyle={styledPub[item.type].contentArrowStyle ? styledPub[item.type].contentArrowStyle : null}
                            date={item.date ? moment(item.date).locale(currentLanguage).fromNow() : null}
                            iconStyle={styledPub[item.type].iconStyle}
                            icon={styledPub[item.type].icon}
                        >
                            {item.title &&
                                <h3 className="vertical-timeline-element-title">{item.title ? JSON.parse(item.title)['title_' + currentLanguage] : ' '}</h3>}
                            {item.subTitle &&
                                <h4 className="vertical-timeline-element-subtitle">{ item.subTitle ? JSON.parse(item.subTitle)['subtitle_' + currentLanguage]: ' '}</h4>}
                            {item.image &&
                                <img className="vertical-timeline-element-img" alt={JSON.parse(item.image).legend} src={JSON.parse(item.image).src} />}

                            <p className="vertical-timeline-element-details">
                                {item.description ? JSON.parse(item.description)['description_' + currentLanguage] : ' '}
                            </p>
                            <h5 className="vertical-timeline-element-author">{item.author ? JSON.parse(item.author)['author_' + currentLanguage] : ''}</h5> <br />
                            {item.link &&
                                <Link className="vertical-timeline-element-link" href={JSON.parse(item.link).link} target="_blank">
                                    {JSON.parse(item.link)['LinkTitle_' + currentLanguage]}
                                </Link>}
                        </VerticalTimelineElement>
                    })

                }

                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<StarIcon />}
                />

            </VerticalTimeline>
             : <StyledCircularProgress size={50} label={vocabulary.loading} />}
        </>
    )
}
export default HomePage
/* eslint-disable  dot-location */

import React, { useEffect, useState } from 'react';

import { vocabulary } from '../../Strings';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../actions';
import AlertDialog from "../../Components/alertDialog";
import SoilTypeList from './SoilTypeList'
import SoilFamilyAddForm from './SoilFamilyAdd'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledMaterialTable } from '../../../ui/display';
import { GetSoilFamilliesList, DeleteSoilTextureFamily } from "../../../globalsTools/network";
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { StyledCard } from "../../../ui/layout";
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const SoilManagementList = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    // const soilFamille = useSelector(state => state.soilFamily.data)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [SoilFamilyList, setSoilFamilyList] = useState(null)

    const [isEditing, setIsEditing] = useState(false)
    //const [isEditingType, setIsEditingType] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userSoilManagement));
        GetSoilFamilliesList(setSoilFamilyList)
        document.title = vocabulary.userSoilManagement


    }, [dispatch]); // exécute l’effet au premièr render

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userSoilManagement));
        document.title = vocabulary.userSoilManagement


    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const operationCallBack = (r) => {
 
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)["name_" + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetSoilFamilliesList(setSoilFamilyList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteSoilFamily = (item) => {

        DeleteSoilTextureFamily(item.id
            , r => operationCallBack(r)
        )
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {(!isLoading && SoilFamilyList) ?
                    <StyledMaterialTable
                        title={vocabulary.soilFamily}
                        data={SoilFamilyList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: ({ rowData }) => { return (<SoilTypeList itemRow={rowData} />)}
                            }]}

                        columns={[
                            { title: vocabulary.type, field: 'name', render: rowData => <>{JSON.parse(rowData.name)['name_' + currentLanguage]}</> },
                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editSoilTexture,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                                //disabled: row.id < 2000
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addSoilTexture,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            },
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],

                        }}
                    /> : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }



                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item.name)["name_" + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteSoilFamily(item)
                            }
                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}


                <SoilFamilyAddForm
                    open={isEditing === false ? openAdd : openEdit}
                    onClose={() => {
                        return (
                            isEditing === true ?
                                (setOpenEdit(false),
                                    setIsEditing(false))
                                : setOpenAdd(false)

                        )
                    }}
                    width={500}
                    item={isEditing === true ? item : null}
                    isEditing={isEditing}
                    refetchFunc={() => GetSoilFamilliesList(setSoilFamilyList)}
                    setIsLoading={setIsLoading}


                />


            </StyledCard>
        </ModalContext.Provider>

    )

}
export default SoilManagementList
import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import {
  GuideContext,
  steps,
} from "../../ui/layout/contextLayout/GuideContext";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../globalConsts";
import { useHistory } from "react-router-dom";

function HomePageStepper() {
  const { guide, dispatchGuide } = useContext(GuideContext);
  const history = useHistory();

  return (
    <Steps // you can read the documentation here: https://www.npmjs.com/package/intro.js-react 
      enabled={guide.isEnabled}  // show or hide Steps
      steps={steps} // Content
      initialStep={guide.initialStep} // from where to start
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element ===".intro") {
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: CURRENT_STEP, payload: "areasList" });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          history.push("/app/sites");
        }
      }} // actions before the step change
      options={{
        // nextLabel: 'test next',
        // prevLabel: 'test previous',
        // skipLabel: 'test skip'
        tooltipClass: "steps", 
      }} // options of styles and other functions of Steps
      onExit={() => {
        dispatchGuide({ type: IS_ENABLED, payload: false }); // define actions when Steps closed
      }}
    />
  );
}

export default HomePageStepper;

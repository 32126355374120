import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { paymentWithVoucher } from '../../../globalsTools/network';
import { StyledInput } from '../../../ui/forms';
import { PAYMENT_PROVIDER_VOUCHER } from '../../globalConsts';
import { vocabulary } from '../../Strings';
import './VouchersFormStyle.css';

const useStyles = makeStyles({
    buttonRoot: {
        fontFamily: 'Arial, sans-serif',
        borderRadius: `0 0 10px 10px`,
        border: 0,
        padding: `12px 16px`,
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
        display: 'block',
        transition: `all 0.2s ease`,
        boxShadow: `0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)`,
        width: '100%',
        color: '#ffffff',
    },
    buttonActive: {
        background: '#5469d4',
    },
    buttonDisabled: {
        background: 'grey',
    },
    cardElement: {
        borderRadius: `4px 4px 0 0`,
        padding: 12,
        border: `1px solid rgba(50, 50, 93, 0.1)`,
        maxHeight: 44,
        width: `100%`,
        background: 'white',
        boxSizing: `border-box`,
        boxShadow: `none`,
    },
});

const VouchersForm = ({ cart, total, onSuccessFn, onFailureFn }) => {
    const classes = useStyles();

    const [voucher, setVoucher] = useState('');

    const currency = useSelector((state) => state._globalState.currency);

    const handleConfirmRequest = (response, isSuccess, httpResponse) => {
        if (isSuccess) {
            onSuccessFn();
        } else {
            onFailureFn();
        }
    };

    const handlePayWithVoucher = () => {
        paymentWithVoucher(
            cart,
            {
                type: PAYMENT_PROVIDER_VOUCHER,
                voucher: voucher,
            },
            [],
            currency,

            handleConfirmRequest
        );
    };

    return (
        <div className='VouchersFormContainer'>
            <Grid container direction='column'>
                <Grid item style={{ padding: 8 }}>
                    Payer avec un bon d'achat
                </Grid>
                <Grid item>
                    <StyledInput
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault();
                                handlePayWithVoucher();
                            }
                        }}
                        helperText='Saisissez ici votre code'
                        onChange={(event) => setVoucher(event.target.value)}
                        value={voucher}
                    />
                </Grid>
                <Grid item>
                    <Button
                        disabled={voucher.length === 0}
                        variant='contained'
                        onClick={() => handlePayWithVoucher()}
                        className={classNames(
                            classes.buttonRoot,
                            voucher.length === 0
                                ? classes.buttonDisabled
                                : classes.buttonActive
                        )}
                    >
                        {vocabulary.PayerLabel + ' ' + total + ' €'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default VouchersForm;

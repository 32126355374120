/* eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomerAddForm from './CustomerAddForm'




import FollowIcon from '@material-ui/icons/HowToReg';
import UnfollowIcon from '@material-ui/icons/PersonAddDisabled'
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RestoreIcon from '@material-ui/icons/Restore';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { Refresh } from '@material-ui/icons';
import {  GetAllCustomers, GetAllInvoices, GetInvoiceById, UpdateInvoice } from '../../../../globalsTools/network';
import { setCurrentPage } from '../../../../actions';
import StyledCircularProgress from '../../../../ui/display/StyledCircularProgress';
import { StyledMaterialTable } from '../../../../ui/display';
import { StyledCard } from '../../../../ui/layout';
import { ModalContext } from '../../../../ui/layout/contextLayout/ModalContext';
import AlertDialog from '../../../Components/alertDialog';
import { vocabulary } from '../../../Strings';



const CustomerListTable = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [customersList, setCustomersList] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.customerManagement));
        GetAllCustomers(setCustomersList)
        document.title = vocabulary.customerManagement

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.customerManagement));
        document.title = vocabulary.customerManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r, row) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isActive == true ? row.name + ' ' + vocabulary.activeLabel : row.name + ' ' + vocabulary.inactiveLabel, 'success')
                GetAllInvoices(setCustomersList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const HandleDesactiveUser = (row) => {
        setIsActive(false)
        UpdateInvoice(
            {
                id: row.id,
                active: false
            }
            , r => operationCallBack(r, row)
        )

    }

    const handleActiveUser = (row) => {
        setIsActive(true)

        UpdateInvoice({
            id: row.id,
            active: true
        }
            , r => operationCallBack(r, row)
        )

    }
    const getAreaByUserList = (id) => {
        GetAllCustomers(1, setCustomersList)
    }   

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {customersList ?
                    <StyledMaterialTable
                        title={vocabulary.clientList}
                        data={customersList.customers}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[
                            { title: vocabulary.name, field: 'name' },
                            { title: vocabulary.email, field: 'emails', render: rowData => <>{rowData.emails.join(',')}</> },
                            { title: vocabulary.mobile, field: 'phone' },
                            { title: vocabulary.serielNumber, field: 'reg_no' },
                            { title: vocabulary.address, field: 'billing_address.address' },
                            { title: vocabulary.address, field: 'delivery_address.address' },
                        ]}
                        actions={[
            
                            row => ({
                                icon: 'edit_outline',
                                tooltip: vocabulary.EditUser,
                                disabled: row.type === "Super",
                                onClick: (event, row) => onEdit(row)
                            }),
                       
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addNewUser,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            },
                         

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80],
                            exportMenu: [{
                                label: 'Export PDF',
                                exportFunc: (cols, datas) => ExportPdf(cols, datas, "Users List")
                            }, {
                                label: 'Export CSV',
                                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Users List')
                            }]

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }



                {(openAdd == true || openEdit == true) &&
                    <CustomerAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetAllInvoices(setCustomersList)}
                    />}
            </StyledCard>
        </ModalContext.Provider>

    )
}
export default CustomerListTable
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { vocabulary } from '../../../../Strings';
import BayerAuthPopup from '../../modals/BayerAuthPopup'
import { useHistory } from "react-router-dom";

const BayerActivateForm = ({ isEditing, onClose, partner }) => {

    let history = useHistory();
    
    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    const currentLanguage = useSelector(state => state._globalState.preferred_language)


    const user_id = useSelector((state) => state.app.userid);


    const registerBayerSuccessEvent = (
        userId,
        callback
    ) => {
        window.Echo.private('App.User.' + userId).listen(
            '.bayer.connection.success',
            callback
        );
    };

    const registerBayerFailedEvent = (
        userId,
        callback
    ) => {
 
        window.Echo.private('App.User.' + userId).listen(
            '.bayer.connection.failed',
            callback
        );
    };

   
    

    const unRegisterBayerEvent = (userId) => {
        window.Echo.private(
            'App.User.' + userId
        ).stopListening('.bayer.connection.success');
        window.Echo.leave('App.User.' + userId);

        window.Echo.private(
            'App.User.' + userId
        ).stopListening('.bayer.connection.failed');
        window.Echo.leave('App.User.' + userId);
    };


    useEffect(() => {
        registerBayerSuccessEvent(user_id, (data) => {

            onClose()
            history.push(`/app/integrations/${partner}/configure`, { partner })
         });

        registerBayerFailedEvent(user_id, (data) => {
            onClose()
            snackbar(vocabulary.errorOccurred)
        });

        return () => {
            unRegisterBayerEvent(user_id)
        }
    }, [user_id])



    return (

        <>


            {
                !isEditing && (
                    <BayerAuthPopup />
                )
            }


        </>
    );
}

export default BayerActivateForm


/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { createActivitiesType, UpdateActivitiesType } from "../../../globalsTools/network";
import { useSnackbar } from 'notistack';
// import StyledSelect from "../../../ui/forms/StyledSelect";
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";

const ActivitiesTypeAddForm = ({ isEditing, onClose, item, open, width, refetchFunc, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const { enqueueSnackbar } = useSnackbar();
    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const modalContext = useContext(ModalContext)
    const userId = useSelector(state => state.app.userid)
    const userType = useSelector(state => state.app.userrights)

    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [color, setColor] = useState(null)
    const [statusLabel, setStatusLabel] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    const statusType = [

        {
            name: 'Alert',
            id: 'alert',
            color: '#03465b',
        },
        {
            name: 'Info',
            color: '#349ff3',
            id: 'info'
        },
        {
            name: 'Error',
            color: '#f45347',
            id: 'error'

        },
        {
            name: 'Event',
            color: '#800080',
            id: 'event'

        },
        {
            name: 'Warning',
            id: 'warning',
            color: '#ffa016'

        }
    ];
    useEffect(() => {

        if (isEditing === false) {
            setTitle(null)
            setDescription(null)
            setColor(null)
            setStatusLabel(null)
        }

    }, [open, isEditing]);
    useEffect(() => {
        if (item) {
            setTitle(item.title)
            setDescription(item.description)
            setColor(item.color)
            setStatusLabel(item.status)
        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? item.title + "  " + vocabulary.updateSucc : vocabulary.activityType + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                setIsLoading(false)

            }
        }
    }

    const handleAddActivityType = () => {
        setIsLoading(true)

        createActivitiesType(
            {
                title: title,
                description: description,
                color: color,
                status: statusLabel,
                user_id: userType == "Super" ? null : userId

            }, r => operationCallBack(r)
        )
        onClose()

    }
    const handleUpdateActivityType = () => {

        setIsLoading(true)

        UpdateActivitiesType(
            {
                id: item.id,
                title: title,
                description: description,
                color: color,
                status: statusLabel,
                user_id: userType == "Super" ? null : userId


            }, r => operationCallBack(r)
        )

        setOpenEditConfirmation(false)

        onClose()

    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleAddActivityType() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={width}
                title={isEditing === false ? vocabulary.add + '  ' + vocabulary.activityType : vocabulary.editLabel + ' ' + vocabulary.activityType}
            >
                <form
                    ref={form}
                    onSubmit={handleSubmit(handleAddActivityType)}
                    onError={errors => console.log(errors)}>

                    <StyledInput
                        value={title}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.title}
                        onChange={(event) => { setTitle(event.target.value) }}
                        name='title'
                        type="text"
                        fullWidth
                        label={vocabulary.titleLabel}
                    />
                    <StyledInput
                        register={register}
                        name="description"
                        label={vocabulary.description}
                        value={description}
                        rules={{ required: true }}
                        onChange={(event) => { setDescription(event.target.value) }}
                        errors={errors.description}
                        maxRows={10}
                        fullWidth
                        multiline={true}

                    />

                    <FormControl style={{ marginLeft: '8px', width: '100%' }}>
                        <InputLabel>{vocabulary.status}</InputLabel>
                        <Select
                            value={statusLabel}
                            onChange={(event) => {
                                setStatusLabel(event.target.value)
                                setColor(statusType.find(el => el.id == event.target.value).color)
                            }}
                            name="color"
                        >
                            {statusType.map((item, index) =>
                                <MenuItem
                                    style={{ color: statusType[index].color, fontSize: 'large' }}
                                    key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.title}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateActivityType();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default ActivitiesTypeAddForm

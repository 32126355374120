/* eslint-disable  no-useless-concat */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import AlertDialog from "../Components/alertDialog";
import SubscriptionAddForm from './SubscriptionAddForm'
import { setCurrentPage } from '../../actions';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { GetSubscriptionList, deletelSubcription } from '../../globalsTools/network'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import FollowIcon from '@material-ui/icons/HowToReg';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const SubscriptionsList = () => {

    const dispatch = useDispatch()
    const userType = useSelector(state => state.app.userrights)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [subscriptionList, setSubscriptionList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.subscriptions))
        GetSubscriptionList(setSubscriptionList)
        document.title = vocabulary.subscriptions

    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.subscriptions))
        document.title = vocabulary.subscriptions

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const changeModalStatus = (newStatus) => {

        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.title).name + ' ' + vocabulary.deleteMsg, 'success')
                GetSubscriptionList(setSubscriptionList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteSubscription = (item) => {

        deletelSubcription(item.id
            , r => operationCallBack(r)
        )
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }


    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {
               (!isLoading&& subscriptionList) ?
                    <StyledCard>


                        <StyledMaterialTable
                            title={vocabulary.subscriptions}
                            data={subscriptionList.data}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip:vocabulary.search } }}

                            columns={[
                                { title: vocabulary.titleLabel, field: 'name', render: rowData => <>{JSON.parse(rowData.title).name}</> },
                                { title: vocabulary.description, field: 'description', render: rowData => <>{JSON.parse(rowData.description).description}</> },
                                { title: vocabulary.price, field: 'price', render: rowData => <>{JSON.parse(rowData.price).totalPrice + ' ' + '(DT)'}</> },
                                { title: vocabulary.sms, field: 'channels', render: rowData => <>{JSON.parse(rowData.channels).sms ? "Active" : "Inactive"}</> },
                                { title: vocabulary.email, field: 'channels', render: rowData => <>{JSON.parse(rowData.channels).email ? "Active" : "Inactive"}</> },
                                { title: vocabulary.application, field: 'channels', render: rowData => <>{JSON.parse(rowData.channels).application ? "Active" : "Inactive"}</> },
                                { title: vocabulary.apiLabel, field: 'channels', render: rowData => <>{JSON.parse(rowData.channels).api ? "Active" : "Inactive"}</> },

                            ]}
                            actions={userType === 'Institution' ? [
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editSubscription,
                                    onClick: (event, row) => onEdit(row)
                                },
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),

                                    disabled: row.id < 2000
                                }),
                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.addSubscription,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpen()
                                }

                            ] : [
                                    row => ({
                                        icon: () => <FollowIcon />,
                                        tooltip: vocabulary.subscribe,
                                        // onClick: (event, row) => subscribe(row.id),
                                    })
                                ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true

                            }}
                        />


                        {alertConfirmDelete === true &&
                            <AlertDialog
                                operation={vocabulary.delete}
                                open={alertConfirmDelete}
                                deletedItemName={JSON.parse(item.title).name}
                                onClose={() => {
                                    closeAlertConfirmDelete();
                                }}
                                confirmeDelete={() => {
                                    if (item) {
                                        handleDeleteSubscription(item)
                                    }


                                    closeAlertConfirmDelete();
                                }}

                                cancel={() => {
                                    closeAlertConfirmDelete();
                                }}
                            />}

                        <SubscriptionAddForm
                            open={isEditing === false ? openAdd : openEdit}
                            handleClose={() => {
                                return (
                                    isEditing === true ?
                                        setOpenEdit(false)
                                        : setOpenAdd(false)

                                )
                            }}
                            selectedItem={isEditing === true ? item : null}
                            isEditing={isEditing}
                            refetchFunc={() => GetSubscriptionList(setSubscriptionList)}
                            setIsLoading={setIsLoading}

                        />
                    </StyledCard>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />

            }
        </ModalContext.Provider>
    )
}
export default SubscriptionsList
/* eslint-disable eqeqeq*/
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars*/

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ResponsiveContainer, ComposedChart, Line, Bar, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea, Label, Area, Brush, Customized, Cross } from 'recharts';
import { vocabulary } from '../../Strings';
import { FormGroup, FormControlLabel, Checkbox, Button, Grid, TableContainer, Paper, Table, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import { generateUid } from '../../../utils';
import { logger } from '../../../globalsTools/utilities';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';
import { CalendarToday, Flag, MultilineChart, Refresh, Save, Timeline, Today, WarningRounded } from '@material-ui/icons';
import _ from 'lodash';

const ComposedChartsNetirrig = ({ data, usedUnit, dateFrom, dateTo, typeOfView, reloadButton }) => {

    const [getPng, { ref, isLoading }] = useCurrentPng();

    const moment = require('moment');
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const [dataChart, setDataChart] = useState(null)
    const [lastDate, setLastDate] = useState(dateTo)
    const [sensibiliteArray, setSensibiliteArray] = useState(null)

    const sensibiliteLabel = ["Sensibilité au stress hydrique négligeable", "Peu de sensibilité au stress hydrique", "Sensibilité au stress hydrique modérée", "Sensibilité au stress hydrique forte", "Sensibilité au stress hydrique très forte", "Sensibilité au stress hydrique critique"]

    const RADIAL_DOT_SIZE = 3;
    const DOT_SHAPE = { strokeWidth: 2, r: 2 };

    /**
     *                         'adjusted_soil_water_volume'=> $row->adjusted_soil_water_volume,
                        'adjusted_yesterday_water_balance'=> $row->adjusted_yesterday_water_balance,
                        'adjusted_irrigation_volume'=> $row->adjusted_irrigation_volume,
     */

    const translatedDate = (date, addDays = 0) => {
        return currentLanguage == "en" ? moment(date).add(addDays, 'days').format("YYYY-MM-DD") : moment(date).add(addDays, 'days').format("DD-MM-YYYY")
    }

    const getFillForSensibilite = (sensibilite) => {
        switch (sensibilite) {
            case 2:
                return 'rgba(24, 207, 0, 0.1)'

            case 3:
                return 'rgba(201, 185, 0, 0.2)'

            case 4:
                return 'rgba(207, 83, 0, 0.2)'

            case 5:
                return 'rgba(207, 0, 148, 0.2)'

            default:
                return 'rgba(0, 0, 0, 0)'
        }
    }

    const simpleView = {
        actualEvapotranspirationState: false,
        irrigationQantityState: true,
        evapotranspirationState: false,
        totalPrecipitationState: true,
        // useful_ReserveState: false,
        humidite_capacite_au_champState: false,
        bas_du_rfuState: true,
        irrigationVolumeState: true,
        waterInSoilState: false,
        seuilDeclenchementState: true,
        seuilDeclenchementPluvioState: false,
        survivalReserveState: false,
        adjustedSoilWaterVolumeState: false,
        adjustedIrrigationVolumeState: false,
        adjustedSurvivalReserveState: false,
        rfuState: false,
        drainageState: true,
        debase: true,
        advanced: false,


    }

    // const simpleView = {
    //     adjustedSoilWaterVolumeState: false,
    //     adjustedIrrigationVolumeState: false,
    //     actualEvapotranspirationState: true,
    //     irrigationQantityState: true,
    //     evapotranspirationState: false,
    //     totalPrecipitationState: true,
    //     // useful_ReserveState: false,
    //     humidite_capacite_au_champState: true,
    //     bas_du_rfuState: true,
    //     irrigationVolumeState: true,
    //     waterInSoilState: true,
    //     seuilDeclenchementState: true,
    //     survivalReserveState: true,
    //     adjustedSurvivalReserveState: false,
    //     rfuState: false,


    // }
    const advancedView = {
        adjustedSoilWaterVolumeState: true,
        adjustedIrrigationVolumeState: true,
        actualEvapotranspirationState: true,
        irrigationQantityState: true,
        evapotranspirationState: false,
        totalPrecipitationState: true,
        // useful_ReserveState: false,
        humidite_capacite_au_champState: true,
        bas_du_rfuState: true,
        irrigationVolumeState: true,
        waterInSoilState: true,
        seuilDeclenchementState: true,
        seuilDeclenchementPluvioState: false,
        survivalReserveState: true,
        adjustedSurvivalReserveState: true,
        rfuState: true,
        drainageState: true,
        debase: false,
        advanced: true,
    }


    const [state, setState] = React.useState(simpleView);

    useEffect(() => {
        setState(typeOfView ? advancedView : simpleView)
    }, [typeOfView])

    useEffect(() => {
        if (data) {
            setDataChart(ComposedChartData(data))
            logger("MAX DATE", Math.max(...data.map(o => new Date(o.calculation_date))))
            setLastDate(moment(new Date(Math.max(...data.map(o => new Date(o.calculation_date))))).format('YYYY-MM-DD'))
        }
    }, [data])


    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const diffArray = (x) => [Number(x["humidite_capacite_au_champ"]).toFixed(3), Number(x["rfu"]).toFixed(3)];
    const diffArrayBasRFU = (x) => [Number(x["rfu"]).toFixed(3), 0];
    // const seuilDeclenchementFn = (x) => Number(Math.min(Math.max(x["unconstrainedNeed"], 0),x["rfu"])).toFixed(2);
    const seuilDeclenchementFn = (x) => (Number(x["bas_du_rfu"]) + Number(x["unconstrainedNeed"])).toFixed(2);
    const seuilDeclenchementFn2 = (x) => (Number(x["bas_du_rfu"]) + Number(x["total_etr"])).toFixed(2);
    // const seuilDeclenchementFn = (x) => Number(x["unconstrainedNeed"]).toFixed(2);
    const getDateField = (x) => currentLanguage == "en" ? (moment(x["date"]).format('YYYY-MM-DD')) : (moment(x["date"]).locale(currentLanguage).format('DD-MM-YYYY'));
    const convertDate = (date) => currentLanguage == "en" ? (moment(date).format('YYYY-MM-DD')) : (moment(date).locale(currentLanguage).format('DD-MM-YYYY'));
    const tickFormatter = (x) => getDateField(dataChart[x]);


    const convertToUsedUnit = (value, usedUnit) => {
        return usedUnit == vocabulary.hectarMcube ? (Number(value) * 10).toFixed(2) : Number(Number(value).toFixed(2))
    }

    const ComposedChartData = (data) => {

        const DataChart = []
        let sensibiliteArray = []
        const area = data.area
        if (data) {

            data.map((el, index) => {


                let tempo = {
                    date: el.calculation_date, //currentLanguage == "en" ? (moment(el.calculation_date).format('YYYY-MM-DD')) : (moment(el.calculation_date).locale(currentLanguage).format('DD-MM-YYYY')),
                    actualEvapotranspiration: convertToUsedUnit(el.actual_evapotranspiration, usedUnit),
                    evapotranspiration: convertToUsedUnit(el.reference_evapotranspiration, usedUnit),
                    // irrigationQantity: state.irrigationQantityState == true ? usedUnit == vocabulary.hectarMcube ? Number(el.irrigation_volume * 10) : Number(el.irrigation_volume) : null,
                    adjustedIrrigationVolume: convertToUsedUnit(el.adjusted_irrigation_volume, usedUnit),
                    totalPrecipitation: convertToUsedUnit(el.corrected_rainfall ?? (el.total_precipitation ? el.total_precipitation : (el.total_precipitation_intensity ? el.total_precipitation_intensity : 0)), usedUnit),
                    // useful_Reserve: state.useful_ReserveState == true ? Number(el.sol && el.sol.useful_reserve).toFixed(2) : null,
                    humidite_capacite_au_champ: convertToUsedUnit(el.field_capacity, usedUnit),
                    bas_du_rfu: convertToUsedUnit(el.bas_rfu, usedUnit),
                    irrigationVolume: (el.irrigation_volume && el.irrigation_volume == null) ? 0 : convertToUsedUnit(el.irrigation_volume, usedUnit),
                    waterInSoil: convertToUsedUnit(Number(el.soil_water_volume) + Number(el.bas_rfu), usedUnit),
                    adjustedSoilWaterVolume: convertToUsedUnit(Number(el.adjusted_soil_water_volume) + Number(el.bas_rfu), usedUnit),
                    unconstrainedNeed: convertToUsedUnit(el.today_unconstrained_need, usedUnit),
                    adjustedSurvivalReserve: convertToUsedUnit(el.adjusted_reserve_survie_mm, usedUnit),
                    survivalReserve: convertToUsedUnit(el.reserve_survi_mm, usedUnit),
                    rfu: convertToUsedUnit(el.rfu, usedUnit),
                    total_etr: convertToUsedUnit(el.total_etr, usedUnit),
                    soil_water_volume: convertToUsedUnit(el.soil_water_volume, usedUnit),
                    soil_water_volume_vide: convertToUsedUnit(Number(el.rfu) - Number(el.soil_water_volume), usedUnit),
                    reserve_survi_mm: convertToUsedUnit(el.reserve_survi_mm, usedUnit),
                    reserve_survi_vide: convertToUsedUnit(Number(el.bas_rfu) - Number(el.reserve_survi_mm), usedUnit),
                    adjusted_soil_water_volume: convertToUsedUnit(el.adjusted_soil_water_volume, usedUnit),
                    adjusted_soil_water_volume_vide: convertToUsedUnit(Number(el.rfu) - Number(el.adjusted_soil_water_volume), usedUnit),
                    adjusted_reserve_survi_mm: convertToUsedUnit(el.adjusted_reserve_survie_mm, usedUnit),
                    adjusted_reserve_survi_vide: convertToUsedUnit(Number(el.bas_rfu) - Number(el.adjusted_reserve_survie_mm), usedUnit),
                    availableWater: convertToUsedUnit(Number(el.soil_water_volume) + Number(el.reserve_survi_mm), usedUnit),
                    adjustedAvailableWater: convertToUsedUnit(Number(el.adjusted_soil_water_volume) + Number(el.adjusted_reserve_survie_mm), usedUnit),
                    netirrig_declenchement: convertToUsedUnit(Number(el.reserve_survi_mm) + Number(el.today_unconstrained_need), usedUnit),
                    drainage: convertToUsedUnit(0 - Number(el.drainage), usedUnit),
                    sensibilite: el.sensibilite,
                    phase_name: el.phase_name?.trim()
                }

                if (sensibiliteArray.length === 0) {
                    sensibiliteArray.push({ sensibilite: el.sensibilite, date_start: el.calculation_date, date_end: el.calculation_date, phase: el.phase_name && el.phase_name.trim() })
                } else {
                    if (sensibiliteArray[sensibiliteArray.length - 1].sensibilite == el.sensibilite) {
                        sensibiliteArray[sensibiliteArray.length - 1].date_end = el.calculation_date
                    } else {
                        sensibiliteArray.push({ sensibilite: el.sensibilite, date_start: el.calculation_date, date_end: el.calculation_date, phase: el.phase_name && el.phase_name.trim() })
                    }

                }
                /*
                var keys = Object.keys(tempo);

                var tmp = []
                for (var j = 0; j < keys.length; j++) {
                    var key = vocabulary[keys[j]]// keys[j].replace(/^element_/, "");

                    // tmp[key] = Number(tempo[keys[j]]);
                    tmp[key] = tempo[keys[j]];

                }

                DataChart.push(tmp)
                */
                DataChart.push(tempo)
            })
            setSensibiliteArray(sensibiliteArray);
        }

        return DataChart

    }

    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            FileSaver.saveAs(png, moment().format("YYYY-MM-DD_h-mm") + '-NetIrrigBySeabex.png');
        }
    }, [getPng]);

    return (
        <div style={{ width: '100%', height: '700px' }}>
            {
                data &&
                <div className='WaterNeedComposedChart' style={{ width: 'inherit', height: '600px', textAlign: 'center', marginBottom: 20 }}>
                    <div className='WaterNeedComposedChart_checkBoxChoise'>
                        <FormGroup row >

                            <SelectionController name="debase" checked={state.debase} label={vocabulary.simplifiedViewLabel} handleChange={handleChange} />
                            <SelectionController disabled={true} name="advanced" checked={state.advanced} label={vocabulary.recommendationsViewLabel} handleChange={handleChange} />
                            <SelectionController name="adjustedIrrigationVolumeState" checked={state.adjustedIrrigationVolumeState} label={vocabulary.adjustedIrrigationVolume} handleChange={handleChange} />
                            <SelectionController name="evapotranspirationState" checked={state.evapotranspirationState} label={vocabulary.evapotranspiration} handleChange={handleChange} />
                            <SelectionController name="actualEvapotranspirationState" checked={state.actualEvapotranspirationState} label={vocabulary.actualEvapotranspiration} handleChange={handleChange} />
                            <SelectionController name="totalPrecipitationState" checked={state.totalPrecipitationState} label={vocabulary.totalPrecipitation} handleChange={handleChange} />
                            <SelectionController name="irrigationVolumeState" checked={state.irrigationVolumeState} label={vocabulary.irrigationVolume} handleChange={handleChange} />
                            <SelectionController name="seuilDeclenchementState" checked={state.seuilDeclenchementState} label={vocabulary.seuil_declenchement} handleChange={handleChange} />
                            <SelectionController name="seuilDeclenchementPluvioState" checked={state.seuilDeclenchementPluvioState} label={vocabulary.seuil_declenchement + " avec pluvio"} handleChange={handleChange} />
                            <SelectionController name="drainageState" checked={state.drainageState} label={vocabulary.drainageLabel} handleChange={handleChange} />

                        </FormGroup>
                    </div>

                    {dataChart &&
                        <ResponsiveContainer>
                            <ComposedChart ref={ref}
                                width={500}
                                height={1000}
                                data={dataChart}
                                margin={{
                                    top: 20, right: 20, bottom: 30, left: 20,
                                }}
                            >

                                <defs>
                                    <linearGradient id="colorDiffGradient" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.3} />
                                        <stop offset="50%" stopColor="#FFEB3B" stopOpacity={0} />
                                        <stop offset="95%" stopColor="#c62828" stopOpacity={0.3} />
                                    </linearGradient>
                                    <linearGradient id="colorReserveGradient" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#084d00" stopOpacity={0.1} />
                                        <stop offset="100%" stopColor="#59d300" stopOpacity={0.0} />
                                    </linearGradient>
                                    <linearGradient id="colorReserveBasGradient" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#e64a01" stopOpacity={0.1} />
                                        <stop offset="100%" stopColor="#ff9100" stopOpacity={0.0} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey={getDateField} name={vocabulary.date} type="category" />
                                <YAxis type="number" reversed={false}
                                    domain={['auto', 'dataMax']}
                                    unit={usedUnit}

                                />
                                <Tooltip
                                    content={({ active, payload, label }) => {
                                        // logger("Tooltip Content", active, payload, label); 
                                        return (
                                            <TableContainer component={Paper}>
                                                <Table size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><Today /></TableCell>
                                                            <TableCell colSpan={2}>{vocabulary.date + " : " + label}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><Flag /></TableCell>
                                                            <TableCell colSpan={2}>{vocabulary.currentPhaseIsLabel + " " + (payload && payload[0] && _.startCase(payload[0]?.payload?.phase_name))}</TableCell>
                                                        </TableRow>
                                                        {payload && payload[0] && payload[0]?.payload?.sensibilite >= 2 &&
                                                            <TableRow style={{ backgroundColor: getFillForSensibilite((payload && payload[0]?.payload?.sensibilite)) }}>
                                                                <TableCell><WarningRounded /></TableCell>
                                                                <TableCell colSpan={2}>{sensibiliteLabel[(payload && payload[0]?.payload?.sensibilite)]}</TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            payload && payload.map(element =>
                                                                <TableRow>
                                                                    <TableCell style={{ backgroundColor: element.color + '30' }}><Timeline style={{ color: element.color }} /></TableCell>
                                                                    <TableCell>{element.name}</TableCell>
                                                                    <TableCell>{element.value}</TableCell>
                                                                </TableRow>

                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )
                                    }}
                                />
                                <Legend verticalAlign="bottom" height={36} wrapperStyle={{ bottom: -5 }} />
                                {/* <Area
                                strokeWidth={0}
                                name={'Limites de la réserve utile'}
                                type='monotone'
                                dataKey={diffArray}
                                fillOpacity={1} 
                                fill="url(#colorReserveGradient)" 
                                stroke='#8884d8'
                                />
                            <Area
                                strokeWidth={0}
                                name={'Limites du bas de la réserve utile'}
                                type='monotone'
                                dataKey={diffArrayBasRFU}
                                fillOpacity={1} 
                                fill="url(#colorReserveBasGradient)" 
                                stroke='#8884d8'
                                /> */}

                                <ReferenceLine x={translatedDate()} stroke="green" label={vocabulary.today} />
                                <ReferenceArea x1={translatedDate(dateFrom)} x2={translatedDate(undefined, -1)} fill="rgba(150,150,0,0.15)" label={<Label value={vocabulary.historicalLabel} offset={10} position="insideTopRight" />} />
                                {/* <ReferenceLine x={moment().format("YYYY-MM-DD")} strokeWidth={2} stroke="black" label={<Label value="Today" offset={-15} position="top" />} ></ReferenceLine> */}
                                <ReferenceArea x1={translatedDate(undefined, 1)} x2={translatedDate(lastDate)} fill="rgba(3,70,91,0.15)" label={<Label value={vocabulary.forecastLabel} offset={10} position="insideTopLeft" />} />

                                {
                                    sensibiliteArray.map(item => {
                                        return (
                                            <ReferenceArea key={generateUid()} x1={translatedDate(item.date_start)} x2={translatedDate(item.date_end)} fill={getFillForSensibilite(item.sensibilite)}>
                                                {/* <Label fill='#ccc' value={ item.sensibilite && "Stade végétatif : " + item.phase      } offset={35} position="insideTopLeft" />
                                            <Label fill='#ccc' value={ item.sensibilite && sensibiliteLabel[item.sensibilite]   } offset={35} position="insideTopLeft" style={{transform: `translateY(15px)`}} /> */}
                                            </ReferenceArea>
                                        )
                                    })
                                }

                                {/* { state.debase==true && <Area stackId="volumes" dataKey="soil_water_volume"      fillOpacity={0.3} fill="#4CAF50"    stroke="#4CAF50"   strokeWidth={2}  type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />  }
                            { state.debase==true && <Area stackId="volumes" dataKey="reserve_survi_mm"       fillOpacity={0.3} fill="#CDDC39"    stroke="orange" strokeWidth={2}  type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />  }
                            { state.debase==true && <Area stackId="volumes" dataKey="soil_water_volume_vide" fillOpacity={0}                      stroke="green"  strokeWidth={2}  type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE}  /> }
                            { state.debase==true && <Area stackId="volumes" dataKey="reserve_survi_vide"     fillOpacity={0}                                     strokeWidth={0}  type="monotone"   /> }
                            { state.advanced==true  && <Area stackId="adjusted_volumes" dataKey="adjusted_soil_water_volume"      fillOpacity={0.3} fill="#F48FB1"  stroke="#F48FB1"    strokeWidth={2} type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> }
                            { state.advanced==true  && <Area stackId="adjusted_volumes" dataKey="adjusted_reserve_survi_mm"       fillOpacity={0.3} fill="#F44336"  stroke="#F44336"     strokeWidth={2} type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> }
                            { state.advanced==true  && <Area stackId="adjusted_volumes" dataKey="adjusted_soil_water_volume_vide" fillOpacity={0}                   stroke="green"    strokeWidth={2} type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> }
                            { state.advanced==true  && <Area stackId="adjusted_volumes" dataKey="adjusted_reserve_survi_vide"     fillOpacity={0}                                    strokeWidth={0} type="monotone"   /> } */}

                                {/* OLD VERSION BEFORE CHANGE WITH CA45 */}
                                {/* { state.debase==true && <Area stackId="volumes" dataKey="soil_water_volume"      fillOpacity={0.3} fill="#4CAF50"    stroke="#4CAF50"   strokeWidth={2}  type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />  }
                            { state.debase==true && <Area stackId="volumes" dataKey="reserve_survi_mm"       fillOpacity={0.3} fill="#CDDC39"    stroke="orange" strokeWidth={2}  type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />  }
                            { state.advanced==true  && <Area stackId="adjusted_volumes" dataKey="adjusted_soil_water_volume"      fillOpacity={0.3} fill="#F48FB1"  stroke="#F48FB1"    strokeWidth={2} type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> }
                            { state.advanced==true  && <Area stackId="adjusted_volumes" dataKey="adjusted_reserve_survi_mm"       fillOpacity={0.3} fill="#F44336"  stroke="#F44336"     strokeWidth={2} type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> } */}

                                {state.debase == true &&
                                    <>
                                        <Line dataKey="availableWater" name={vocabulary.availableWaterInSoil} type="monotone" stroke="#4CAF50" strokeWidth={2} activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />
                                    </>
                                }

                                {state.advanced == true &&
                                    <>
                                        <Area dataKey="adjustedAvailableWater" name={vocabulary.adjustedSoilWaterVolume} fillOpacity={0.3} fill="#CDDC39" stroke="orange" strokeWidth={2} type="monotone" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} />
                                    </>
                                }
                                {state.adjustedIrrigationVolumeState == true ? <Bar name={vocabulary.adjustedIrrigationVolume} dataKey="adjustedIrrigationVolume" barSize={30} fill="#8338ec" /> : null}

                                {state.irrigationVolumeState == true ? <Bar name={vocabulary.irrigationVolume} dataKey="irrigationVolume" barSize={30} fill="#00c961" /> : null}
                                {state.totalPrecipitationState == true ? <Bar name={vocabulary.totalPrecipitation} dataKey="totalPrecipitation" barSize={30} fill="#0585ed" /> : null}
                                {state.drainageState == true ? <Bar name={vocabulary.drainageLabel} dataKey="drainage" barSize={30} fill="#f52a8c" /> : null}


                                {state.bas_du_rfuState == true ? <Line name={vocabulary.bas_du_rfu} dataKey="bas_du_rfu" type="monotone" layout="vertical" stroke="#ff9100" dot={false} legendType="none" tooltipType="none" /> : null}
                                {state.humidite_capacite_au_champState == true ? <Line name={vocabulary.humidite_capacite_au_champ} dataKey="humidite_capacite_au_champ" type="monotone" stroke="#59d300" dot={false} /> : null}

                                {state.actualEvapotranspirationState == true ? <Line name={vocabulary.actualEvapotranspiration} dataKey="actualEvapotranspiration" strokeWidth={2} height={20} stroke="#069b9b" dot={DOT_SHAPE} /> : null}
                                {state.evapotranspirationState == true ? <Line name={vocabulary.evapotranspiration} dataKey="evapotranspiration" strokeWidth={2} type="monotone" stroke="#9400D3" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> : null}

                                {state.seuilDeclenchementState == true ?
                                    <>
                                        <Line name={vocabulary.seuil_declenchement} dataKey={seuilDeclenchementFn2} strokeWidth={2} type="monotone" stroke="#9400D3" activeDot={{ r: RADIAL_DOT_SIZE }} dot={false} strokeDasharray="5 5" />
                                    </> : null}
                                {state.seuilDeclenchementPluvioState == true ?
                                    <>
                                        <Line name={vocabulary.seuil_declenchement + " sans pluvio"} dataKey={seuilDeclenchementFn} strokeWidth={2} type="monotone" stroke="#9400D3" activeDot={{ r: RADIAL_DOT_SIZE }} dot={false} strokeDasharray="5 5" />
                                    </> : null}

                                {/* {state.waterInSoilState == true                     ? <Line name={vocabulary.waterInSoil} dataKey="waterInSoil" strokeWidth={5} type="monotone" stroke="#228B22" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> : null} */}
                                {/* 
                            {state.survivalReserveState == true                 ? <Line name={vocabulary.survivalReserve} dataKey="survivalReserve" strokeWidth={2} type="monotone" stroke="#6A1B9A" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> : null}
                            {state.adjustedSoilWaterVolumeState == true         ? <Line name={vocabulary.adjustedSoilWaterVolume} dataKey="adjustedSoilWaterVolume" strokeWidth={5} type="monotone" stroke="#1b4332" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> : null}
                            {state.adjustedSurvivalReserveState == true         ? <Line name={vocabulary.adjustedSurvivalReserve} dataKey="adjustedSurvivalReserve" strokeWidth={2} type="monotone" stroke="#C62828" activeDot={{ r: RADIAL_DOT_SIZE }} dot={DOT_SHAPE} /> : null} 
                            */}


                                {/* {state.useful_ReserveState == true ? <Line strokeWidth={3} dataKey={vocabulary.useful_Reserve} height={20} stroke="#696969" /> : null} */}
                                {/* {state.bas_du_rfuState == true ? <Line strokeWidth={3} type="monotone" layout="vertical" dataKey={vocabulary.bas_du_rfu} stroke="#008ffb" activeDot={{ r: 8 }} /> : null}
                            {state.humidite_capacite_au_champState == true ? <Line strokeWidth={3} type="monotone" dataKey={vocabulary.humidite_capacite_au_champ} stroke="#CD853F" activeDot={{ r: 8 }} /> : null} */}
                                <Brush tickFormatter={tickFormatter} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    }
                    <Grid container direction='row' style={{ justifyContent: 'center' }}>
                        <Grid item>
                            <Button
                                // variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleDownload}
                                disabled={isLoading}
                                startIcon={<Save />}
                            >
                                {isLoading ? 'Téléchargement...' : 'Télécharger le graphique'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                // variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => reloadButton()}
                                disabled={isLoading}
                                startIcon={<Refresh />}
                            >
                                {isLoading ? 'Téléchargement...' : 'Réactualiser'}
                            </Button>

                        </Grid>
                    </Grid>
                </div>

            }

        </div>
    );
}

const SelectionController = ({ name, label, checked, handleChange, disabled = false }) => {


    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name={name}
                    color="primary"
                    disabled={disabled}
                />
            }
            label={label}
        />
    )
}

export default ComposedChartsNetirrig
import React, { useEffect, useState } from 'react';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import CatalogueModelList from './CatalogModelPackList'
import CatagoryAddForm from './CategoryAdd'
import { getSeabexCategorieByParentIdList, DeleteSeabexCategory } from '../../../globalsTools/network';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledMaterialTable } from '../../../ui/display';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const CategorieList = ({ itemRow }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [categoriesChildList, setCategoriesChildList] = useState(null)



    useEffect(() => {

        if (itemRow) {
            getSeabexCategorieByParentIdList(itemRow.id, setCategoriesChildList)
        }

    }, [itemRow])

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 200) {
                snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                getSeabexCategorieByParentIdList(itemRow.id, setCategoriesChildList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteCategoryFamily = (item) => {

        DeleteSeabexCategory(item.id
            , r => operationCallBack(r)
        )
    }

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = (row) => {
        setOpenAdd(true)
        // setParent_id(row.id)
    };

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    return (

        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {categoriesChildList ?
                <StyledMaterialTable
                    title={vocabulary.categoryFamily}
                    data={categoriesChildList.data}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                    detailPanel={[
                        {
                            tooltip: 'Show Details',
                            render: ({ rowData }) =>  { return (<CatalogueModelList itemRow={rowData} />) }
                        }]}

                    columns={[
                        { title: vocabulary.name_label, field: 'name' },
                    ]}
                    actions={[
                        {
                            icon: 'edit_outline',
                            tooltip: vocabulary.editLabel,
                            onClick: (event, row) => onEdit(row)
                        },
                        row => ({
                            icon: 'delete_outline',
                            tooltip: vocabulary.delete,
                            onClick: (event, row) => openAlertConfirmDelete(row),

                            //disabled: row.id < 2000
                        }),
                        {
                            icon: () => <AddIcon fontSize='large' color='primary' />,
                            tooltip: vocabulary.add,
                            isFreeAction: true,
                            onClick: (event, row) => handleClickOpen(row)
                        }

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        detailPanelType: 'single',
                        paging:false


                    }}
                />

                : <StyledLoadingRow />
            }


            {alertConfirmDelete === true &&
                <AlertDialog
                    operation={vocabulary.delete}
                    open={alertConfirmDelete}
                    deletedItemName={item.name}
                    onClose={() => {
                        closeAlertConfirmDelete();
                    }}
                    confirmeDelete={() => {
                        if (item) {
                            handleDeleteCategoryFamily(item)
                        }
                        closeAlertConfirmDelete();
                    }}

                    cancel={() => {
                        closeAlertConfirmDelete();
                    }}
                />}
            <CatagoryAddForm
                open={isEditing === false ? openAdd : openEdit}
                onClose={() => {
                    return (
                        isEditing === true ?
                            (setOpenEdit(false),
                                setIsEditing(false))
                            : setOpenAdd(false)

                    )
                }}
                width={500}
                item={isEditing === true ? item : null}
                parent_id={itemRow.id}
                isEditing={isEditing}
                refetchFunc={() => getSeabexCategorieByParentIdList(itemRow.id, setCategoriesChildList)}

            />






        </ModalContext.Provider>
    )

}
export default CategorieList
import React from "react";
import { CircularProgress } from "@material-ui/core";
import { vocabulary } from "../../App/Strings";

export default ({styles, customMessage=""}) => {

    const style = {
        ...styles,
        padding:16,
        textAlign:'center'
        // backgroundColor:'#efefef'
    }

    return (
        <div style={style}>
            <CircularProgress size={20} />&nbsp;&nbsp;{vocabulary.loading + customMessage}
        </div>
    )

}


import React from "react";
import { FormLabel, FormControlLabel,Switch } from '@material-ui/core/'

export default ({ label, checked, onChange,formLabel,value }) => {
    return (
        <>
            {formLabel?<FormLabel component="legend">{formLabel}</FormLabel>: null}

            <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        onChange={onChange}
                        color="primary"
                        value={value}
                    />
                }
                label={label}
            />
        </>
    )
}



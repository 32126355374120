import React, { useRef, useState, useContext, useEffect } from 'react';
// import { updateGroupe } from '../../actions';
import { useSelector } from 'react-redux';
import { vocabulary } from '../Strings';

import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledSelect from "../../ui/forms/StyledSelect";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../..//ui/layout/StyledModalFooter';
import { StyledModal } from '../../ui/layout'
import { CreateGroupe, updateGroupe } from "../../globalsTools/network";
import { STATUS } from '../../App/StatusType'
import { useSnackbar } from 'notistack';

const AddGroupeForm = ({ isEditing, handleClose, selectedItem, open, refetchFunc }) => {

    const form = useRef(null);
    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const userId = useSelector(state => state.app.userid)
    const { enqueueSnackbar } = useSnackbar();

    const [status, setStatus] = useState('')

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    useEffect(() => {
        if (isEditing === false) {

            setStatus('')

        }

    }, [open, isEditing]); // N’exécute l’effet que si open a changé

    useEffect(() => {
        if (isEditing === true) {

            setStatus(selectedItem.type)

        }

    }, [selectedItem, isEditing]); // N’exécute l’effet que si open a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const callBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? selectedItem.title + ' ' + vocabulary.updateSucc : vocabulary.groupAdded, 'success')
                refetchFunc()

            } else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
            }
        }
    }
    const newGroup = (data) => {
        if (isEditing === false) {
            CreateGroupe(
                {
                    title: data.title,
                    description: data.description,
                    type: status,
                    user_id: userId
                }, r => callBack(r)
            )
            handleClose()
        } else {
            updateGroupe({
                id: selectedItem.id,
                title: data.title,
                description: data.description,
                type: status,
                user_id: userId
            }, r => callBack(r)
            )
            handleClose()


        }
    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={handleClose}
                width={500}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addGroup : vocabulary.editGroup}
            >
                <form
                    onSubmit={handleSubmit(newGroup)}
                    ref={form}

                >

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="title"
                        label={vocabulary.titleLabel}
                        type="text"
                        fullWidth
                        errors={errors.title}
                        defaultValue={selectedItem && selectedItem.title}

                    />

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="description"
                        label={vocabulary.description}
                        type="text"
                        fullWidth
                        defaultValue={selectedItem && selectedItem.description}
                        errors={errors.description}
                        maxRows={4}
                        multiline={true}

                    />
                    <StyledSelect
                        register={register}
                        value={status}
                        rules={{ required: true }}
                        errors={errors.type}
                        name='type'
                        label={vocabulary.type}
                        fullWidth
                        onChange={(event) => {
                            setStatus(event.target.value)
                        }}
                        data={STATUS.TYPES}
                        valueField='value'
                        labelField='title'
                        styles={{ width: '100%' }}

                    />
                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
        </>
    )

}
export default AddGroupeForm

/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useState, useEffect } from 'react';

import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { CreateEquipmentFamily, UpdateEquipmentFamily } from '../../../globalsTools/network';
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";

const EquipmentFamilyAddForm = ({ isEditing, item, open, onClose, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()

    const modalContext = useContext(ModalContext)

    const [equipmentType, setEquipmentType] = useState(null)
    const [reference, setReference] = useState(null)
    const [description, setDescription] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    useEffect(() => {
        if (isEditing === false) {
            setEquipmentType(null)
            setReference(null)
            setDescription(null)
        }

    }, [open, isEditing])
    useEffect(() => {
        if (item) {
            setEquipmentType(item.name)
        }

    }, [item])

    const newEquipmentFamily = () => {
        setIsLoading(true)

        CreateEquipmentFamily(
            {
                name: equipmentType,
                reference: reference,
                description: JSON.stringify({ 'description': description }),
            }
        )

        onClose()


    }
    const handleUpdateEquipmentFamily = () => {
        setIsLoading(true)

        UpdateEquipmentFamily(
            {
                id: item.id,
                name: equipmentType,
                reference: reference,
                description: JSON.stringify({ 'description': description }),
            }
        )
        setOpenEditConfirmation(false)
        onClose()


    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? newEquipmentFamily() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                // fullWidth={true}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addEquipmentType : vocabulary.editEquipmentType}
            >
                <form
                    onSubmit={handleSubmit(newEquipmentFamily)}
                    ref={form}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="equipmentType"
                        label={vocabulary.equipmentType}
                        type="text"
                        fullWidth
                        errors={errors.equipmentType}
                        onChange={(event) => { setEquipmentType(event.target.value) }}
                        value={equipmentType}


                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="reference"
                        label={vocabulary.reference}
                        type="text"
                        fullWidth
                        errors={errors.reference}
                        onChange={(event) => { setReference(event.target.value) }}
                        value={reference}
                    />


                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="description"
                        label={vocabulary.description}
                        type="text"
                        fullWidth
                        onChange={(event) => { setDescription(event.target.value) }}
                        value={description}
                        errors={errors.description}
                        maxRows={4}
                        multiline={true}
                    />

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.name}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateEquipmentFamily();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default EquipmentFamilyAddForm

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { vocabulary } from '../Strings';
import calendar2 from '../../images/calendar2.png';
import priority1 from '../../images/priority1.png';
import board from '../../images/board.png';
import file from '../../images/file.png';
import status from '../../images/status.png';
import device from '../../images/device.png';
import identification from '../../images/identification.png'
import sensor from '../../images/sensor.png'
import math from '../../images/math.png'
import PaperComponent from '../Admin/DraggableDialogFunction'


const viewDetails = (props) => {

  const moment = require('moment');

  const { item } = props;
  const createdDate = moment(item.created_at).format('YYYY MMMM DD')

  const handleClose = () => {
    const { onClose } = props
    onClose()
  };


  const actionsTable = () => {

    const actionTable = JSON.parse(JSON.parse(item.actions))

    return (
      actionTable.map((item, index) => (
        <div>

          <div className="viewDetailDialogContent">
            <img alt='errorPhoto' className="iconDetails" src={device} /> {vocabulary.deviceName} : {item.DeviceName}
          </div>
          <div className="viewDetailDialogContent">
            {vocabulary.port} : {item.Port}
          </div>
          <div className="viewDetailDialogContent">
            {vocabulary.delay} : {item.Delay}
          </div>
          <div className="viewDetailDialogContent">
            {vocabulary.type} : {item.Type}
          </div>
        </div>

      )))
  }

  const conditionTable = () => {
    const scenarioConditionTable = JSON.parse(JSON.parse(item.conditions))
    return (
      scenarioConditionTable && scenarioConditionTable.map((item, index) => (
        <div>
          <div className="viewDetailDialogContent">
            <img alt='errorPhoto' className="iconDetails" src={sensor} /> {vocabulary.sensor} : {item.Sensor}
          </div>
          <div className="viewDetailDialogContent">
            <img alt='errorPhoto' className="iconDetails" src={math} /> {vocabulary.operatorLabel} : {item.Operator}
          </div>
          <div className="viewDetailDialogContent">
            {vocabulary.value} : {item.Value}
          </div>
          <div className="viewDetailDialogContent">
            <img alt='errorPhoto' className="iconDetails" src={identification} />  {vocabulary.identifier} : {item.identifier}
          </div>
        </div>

      )))
  }


  return (
    <div >

      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{vocabulary.scenarioLabel}  {item.title} </DialogTitle>
        <DialogContent className="viewDetailDialog">
          <DialogContentText >
            <div className="viewDetailDialogContent">
              <img alt='errorPhoto' className="iconDetails" src={status} />  {vocabulary.status} :  {item.status}
            </div>
            <div className="viewDetailDialogContent">
              <img alt='errorPhoto' className="iconDetails" src={file} /> {vocabulary.description} :  {item.description}
            </div>
            <div className="viewDetailDialogContent">
              <img alt='errorPhoto' className="iconDetails" src={priority1} />  {vocabulary.priority} :  {item.priority}
            </div>
            <div className="viewDetailDialogContent">
              <img alt='errorPhoto' className="iconDetails" src={calendar2} />     {vocabulary.startDate} :  {createdDate}
            </div>
            <div className="viewDetailDialogContent">
              <img className="iconDetails" alt='errorPhoto' src={board} />  {vocabulary.scEquation} :  {item.equation}
            </div>
            <div>
              {actionsTable()}
            </div>
            <div>
              {conditionTable()}
            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose}
            color="primary">
            {vocabulary.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}



export default viewDetails;
/* eslint-disable  no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useContext } from "react"
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom'
import "intro.js/introjs.css";

import { Tabs, Tab, Paper, Button, Typography, Badge, Fab } from '@material-ui/core'
import { AttachFile, Error, FormatListBulleted, List, Map, Pageview, Print, Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles'
import { vocabulary } from "../Strings"
import { GetAreasListWithMap, DeleteArea } from "../../globalsTools/network";
import { LazyMapImage, StyledStaticMap } from "../../ui/display";
import StyledMaterialTable from './../../ui/display/StyledMaterialTable';
import AddArea from './AddArea';
import { useSnackbar } from 'notistack';
import { ModalContext } from './../../ui/layout/contextLayout/ModalContext';
import SeabexMap from './Map/SeabexMap'
import AlertDialog from "../Components/alertDialog";
import { setCurrentPage } from '../../actions';
import AreaDetailsList from './AreaDetailsList'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddAreaFileModal from "./AddAreaFileModal";
import { GuideContext, steps } from '../../ui/layout/contextLayout/GuideContext';
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from '../globalConsts';
import AreasListStepper from './Guides/AreasListStepper'
import CurrentPage from "../Components/CurrentPage";
import { StyledDialog } from "../../ui/display/StyledDialog";
import StyledInfoBubble from "../../ui/display/StyledInfoBubble";
import { logger } from "../../globalsTools/utilities";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { usePDF, Margin } from "react-to-pdf";
import moment from "moment";
import { formatDateStr } from "../../utils";

export default () => {

    const dispatch = useDispatch()
    // const history = useHistory()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { guide, dispatchGuide } = useContext(GuideContext)

    const [areas, setAreas] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [mapView, setMapView] = useState(false)
    const [goToSite, setGoToSite] = useState(false)
    const [tab, setTab] = useState(0)
    const [item, setItem] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [newlyCreatedMap, setNewlyCreatedMap] = useState(undefined)
    const [parentArea, setParentArea] = useState(undefined)
    const [openAddAreaFileModal, setOpenAddAreaFileModal] = useState(false)

    const [createNewAreaDialogStatus, setCreateNewAreaDialogStatus] = useState(false)

    const new_areas_right = useSelector(state => state._globalState.new_areas_right)

    const impersonate = useSelector(state => state.app.impersonate);
    const history = useHistory();
    const { toPDF, targetRef } = usePDF({
        method: "save",
        filename: `${moment().format("DD-MM-YYYY")}.pdf`,
        page: { margin: Margin.LARGE },
    });

    useEffect(() => {
        if (impersonate && impersonate.scopes && !impersonate.scopes.includes('area-section-access')) {
            history.push('/app/organization/user/cycle/impersonate');
        }
    }, [impersonate, history]);

    useEffect(() => {
        if (areas !== null && guide.currentStep === 'areasList' && (steps[guide.initialStep].element === ".intro" || steps[guide.initialStep].element === ".areasListGuide" || steps[guide.initialStep].element === ".areasListGuideStep" || steps[guide.initialStep].element === ".mapViewBtn")) {
            dispatchGuide({ type: IS_ENABLED, payload: true })
        }
        if (areas !== null && guide.currentStep === 'seabexMap' && steps[guide.initialStep].element === ".onePagerWaterNeedHistory") {
            GoToSelectedSite(areas[0])
        }
        if (areas && areas.length < 1) {
            setCreateNewAreaDialogStatus(true)
        }
    }, [areas])

    useEffect(() => {
        if (areas !== null && guide.currentStep === 'areasListNotCreated' && steps[guide.initialStep].element === ".areasListGuide" && guide.isEnabled === false) {
            dispatchGuide({ type: CURRENT_STEP, payload: 'areasList' })
            setMapView(false)
            setTab(0)
            setTimeout(() => {
                dispatchGuide({ type: IS_ENABLED, payload: true })
            }, 900);
        } else if (guide.currentStep === 'areasList' && (steps[guide.initialStep].element === ".addIcon" || steps[guide.initialStep].element === ".mapViewBtn") && guide.isEnabled === false && mapView === true && tab === 1) {
            setMapView(false)
            setTab(0)
            setTimeout(() => {
                dispatchGuide({ type: IS_ENABLED, payload: true })
            }, 700);
        }
    }, [guide])

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenAdd(!openAdd)
        } else {
            setOpenAdd(newStatus)
        }

    }

    const { enqueueSnackbar } = useSnackbar();

    const snackbar = useCallback((msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    },
        [enqueueSnackbar]);

    let convertSize = (size) => {
        return Number(size / 10000).toFixed(2) + " " + vocabulary.hectar
    }

    const handleResult = useCallback(
        (data, result) => {

            if (result == null || result === true) {
                return setAreas(data)
            } else {
                snackbar("Error Loading Areas List", "error")
            }
        },
        [snackbar]
    )
    const onEdit = (row) => {
        setItem(row)
        setIsEditing(true)
        setOpenAdd(true)
    }
    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.sites))
        GetAreasListWithMap(handleResult)
        document.title = vocabulary.sites
    }, [handleResult, dispatch])

    useEffect(() => {
        dispatch(setCurrentPage(<CurrentPage name={vocabulary.sites} helpFn={helpFn} />))
        document.title = vocabulary.sites

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };
    const operationCallBack = (response, success, status) => { // call back function after every crud operation to show snackbar and get the data
        if (response.status) {
            if ([200, 204].indexOf(Number(status)) > -1) {
                snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                GetAreasListWithMap(setAreas)
            } else {
                snackbar(vocabulary.errorOccurred + " " + status, 'error');
            }
        }
    }
    const handleDeleteArea = (item) => {
        dispatchGuide({ type: INITIAL_STEP, payload: 0 });
        dispatchGuide({ type: CURRENT_STEP, payload: "" });
        DeleteArea(item.id, operationCallBack)
    }
    const GoToSelectedSite = (row) => { // go to selectedSite in the map
        setGoToSite(true)
        setItem(row)
        setTimeout(() => {
            setTab(1)
            setMapView(true)
        }, 500);
    }
    const handleChangeMapView = () => {
        setMapView(true)
        setTab(1)
        setItem(null)


    }
    const contextValue = { open: openAdd, changeModalStatus: changeModalStatus }

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            setMapView(false)
        }

        else {
            setMapView(true)
            setGoToSite(false)
            setItem(null)
        }
        setTab(newValue)
    }
    const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        },
    });

    const handleAddArea = (passedCreatedMap, parent) => {
        if (passedCreatedMap != null) {
            setNewlyCreatedMap(passedCreatedMap)
            setParentArea(parent)
        }
        setOpenAdd(true)
    }

    const classes = useStyles();

    useEffect(() => {
        logger("OpenAdd", openAdd)

    }, [openAdd])



    const helpFn = () => {
        dispatchGuide({ type: INITIAL_STEP, payload: steps.findIndex(step => step.element === ".intro") })
        dispatchGuide({ type: CURRENT_STEP, payload: 'areasList' })
        setMapView(false)
        setTab(0)
        setTimeout(() => {
            dispatchGuide({ type: IS_ENABLED, payload: true })
        }, 500);

    }

    const refreshFn = useCallback(() => {
        GetAreasListWithMap(handleResult)
    }, [])


    return (
        <>

            {guide.isEnabled && guide.currentStep === 'areasList' &&
                <AreasListStepper setMapView={setMapView} setTab={setTab} handleChangeMapView={handleChangeMapView} setOpenAdd={setOpenAdd} GoToSelectedSite={() => { GoToSelectedSite(areas[0]) }} />}
            <ModalContext.Provider value={contextValue}>
                <>
                    <Paper className={classes.root}>
                        <Tabs style={{ marginBottom: 8 }}
                            value={tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label={vocabulary.listView} icon={<FormatListBulleted />} />
                            <Tab label={vocabulary.mapView} disabled={!areas} icon={<Map />} />

                        </Tabs>
                    </Paper>
                    {openAdd &&
                        <AddArea width={745}
                            closeFn={() => {
                                return (
                                    setOpenAdd(false),
                                    setIsEditing(false)
                                )
                            }
                            }
                            withoutContext={true}
                            area_id={isEditing === true && item ? item.id : null}
                            item={item}
                            isEditing={isEditing}
                            refetchFunc={() => { GetAreasListWithMap(setAreas); setMapView(false); setTab(0); setOpenAdd(false); }}
                            parentArea={parentArea ? parentArea : undefined}
                            initialMap={newlyCreatedMap}
                        />
                    }
                    <div style={{ flexDirection: 'vertical' }}>

                        {mapView ?
                            <>
                                <div ref={targetRef}>
                                    <SeabexMap
                                        layersDataInitial={areas && areas}
                                        goToSite={goToSite}
                                        // setEditingItem={setItem}
                                        editingItem={item}
                                        addAreaFn={handleAddArea}
                                        isEditing={false}
                                    />
                                </div>
                                <Fab variant="extended"
                                    style={{
                                        position: 'fixed',
                                        bottom: 20,
                                        right: 20
                                    }} color='primary'
                                >
                                    <Print onClick={toPDF} style={{ marginInlineEnd: 10 }} />
                                    <List
                                        onClick={() => { setMapView(false); window.scroll(0, 0) }}
                                        style={{ marginInlineEnd: 10 }}
                                    />
                                    Mes parcelles
                                </Fab>
                            </>
                            : areas ?
                                <div className="areasListGuide areasListGuideStep">
                                    <StyledMaterialTable
                                        title={vocabulary.areasList}
                                        data={areas ?? []}
                                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}

                                        detailPanel={[
                                            {
                                                tooltip: vocabulary.detailsLabel,
                                                render: ({ rowData }) => {
                                                    return (<AreaDetailsList // parcels list
                                                        itemRow={rowData}
                                                        areasList={areas}
                                                        setMapView={setMapView}
                                                        setGoToSite={setGoToSite}
                                                        setTab={setTab}
                                                        editingItem={setItem}
                                                    />)
                                                }

                                            }]
                                        }

                                        columns={[
                                            { title: vocabulary.name_label, field: 'name' },
                                            { title: vocabulary.description, field: 'description' },

                                            { title: vocabulary.size, field: 'size', render: rowData => <>{convertSize(rowData.size)}</> },

                                            {
                                                title: vocabulary.map, field: 'staticmap', render: rowData => (
                                                    <LazyMapImage id={rowData.id} onClick={() => GoToSelectedSite(rowData)} base64={true} height={80} />
                                                )
                                            },
                                            { title: '', render: rowData => <Button startIcon={<Pageview />} onClick={() => GoToSelectedSite(rowData)} style={{ textAlign: 'start', lineHeight: `18px` }} >{vocabulary.onepage_areaDetail}</Button> },
                                        ]}
                                        parentChildData={(row, rows) => rows.find(a => a.id === row.parent_id)}
                                        actions={[
                                            {
                                                icon: () => <Badge invisible={new_areas_right} color='secondary' badgeContent='!'><AddIcon fontSize='large' color='primary' className="addIcon" /></Badge>,
                                                tooltip: new_areas_right ? vocabulary.addArea : "Vous avez atteint votre limite pour un compte gratuit! N'hésitez pas à passer à un compte premium!",
                                                isFreeAction: true,
                                                onClick: (event) => handleChangeMapView(),
                                                disabled: !new_areas_right
                                            },
                                            {
                                                icon: () => <AttachFile fontSize='large' color='primary' className="attachFileIcon" />,
                                                tooltip: vocabulary.addAreaUsingKmlFile,
                                                isFreeAction: true,
                                                onClick: (event) => setOpenAddAreaFileModal(true)
                                            },
                                            {
                                                icon: () => <Refresh fontSize='large' color='primary' />,
                                                tooltip: vocabulary.refreshData,
                                                isFreeAction: true,
                                                onClick: refreshFn
                                            },
                                            // rowData => ({
                                            //     icon: () => <SettingsInputAntenna />, // redirect to realTime menu
                                            //     tooltip: 'RealTime',
                                            //     onClick: (event, row) => getRealTimeData(rowData),
                                            //     disabled: rowData.seabex_model_id
                                            // }),
                                            // rowData => ({
                                            //     icon: () => <div style={{flex:1}}><Pageview /><p style={{fontSize:12}}>Plus de Détails</p></div>,
                                            //     tooltip: vocabulary.onepage_areaDetail, // go to one pager  get area by id
                                            //     onClick: (event, row) => GoToSelectedSite(row),
                                            //     disabled: rowData.id < 2000
                                            // }),
                                            {
                                                icon: 'edit_outline',
                                                tooltip: vocabulary.editArea,
                                                onClick: (event, rowData) => onEdit(rowData)
                                            },
                                            rowData => ({
                                                icon: 'delete_outline',
                                                tooltip: vocabulary.delete,
                                                onClick: (event, row) => openAlertConfirmDelete(row),
                                                disabled: rowData.id < 2000
                                            }),
                                        ]}
                                        options={{
                                            actionsColumnIndex: -1,
                                            pageSize: 40,
                                            pageSizeOptions: [10, 20, 40, 60, 80, 100],
                                        }}
                                    />

                                </div>

                                : <StyledCircularProgress size={50} label={vocabulary.loading} />
                        }
                        {alertConfirmDelete === true &&
                            <AlertDialog
                                operation={vocabulary.delete}
                                open={alertConfirmDelete}
                                deletedItemName={item.name}
                                onClose={() => {
                                    closeAlertConfirmDelete();
                                }}
                                confirmeDelete={() => {
                                    if (item) {
                                        handleDeleteArea(item);
                                    }
                                    closeAlertConfirmDelete();
                                }}

                                cancel={() => {
                                    closeAlertConfirmDelete();
                                }}
                            />}
                    </div>
                    {openAddAreaFileModal && <AddAreaFileModal open={openAddAreaFileModal} onClose={() => setOpenAddAreaFileModal(false)} refetchFunc={() => { GetAreasListWithMap(setAreas) }} />}
                </>
            </ModalContext.Provider>
            <StyledDialog
                title={vocabulary.addArea}
                actions={
                    <>
                        <Button onClick={() => { setCreateNewAreaDialogStatus(false); }} style={{ alignSelf: 'start' }}>{vocabulary.dialog_notNow}</Button>
                        <Button onClick={() => { setCreateNewAreaDialogStatus(false); handleChangeMapView() }} style={{ alignSelf: 'end' }}>{vocabulary.dialog_okAcceptance}</Button>
                    </>}
                open={createNewAreaDialogStatus}
                handleClose={() => setCreateNewAreaDialogStatus(false)}
            >
                <Typography>{vocabulary.dialog_newArea}</Typography>
            </StyledDialog>
        </>
    );

}
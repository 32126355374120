/* eslint-disable eqeqeq*/

import React, { useRef, useContext } from 'react';

import { vocabulary } from '../../Strings';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import StyledInput from "../../../ui/forms/StyledInput";
import { useForm } from 'react-hook-form';
import { CreateSeabexCategory, UpdateSeabexCategory } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';

const CatalogPacksAddForm = ({ isEditing, item, width, open, onClose, reftechFunc }) => {

    const form = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? item.name + "  " + vocabulary.updateSucc : vocabulary.seabexModelType + " " + vocabulary.addedSucc, 'success')
                reftechFunc()

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const newCatalogPack = (data) => {
        if (isEditing === false) {
            CreateSeabexCategory(
                {
                    name: data.DeviceCategory,
                }, r => operationCallBack(r)
            )
            onClose()
        } else {
            UpdateSeabexCategory(
                {
                    id: item.id,
                    name: data.DeviceCategory,
                }, r => operationCallBack(r)
            )
            onClose()
        }

    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <StyledModal
            open={open}
            onClose={onClose}
            width={width}
            modalContext={modalContext}
            title={isEditing === false ? vocabulary.addModelPack : vocabulary.editModelPack}
        >
            <form
                onSubmit={handleSubmit(newCatalogPack)}
                ref={form}
            >
                <StyledInput
                    register={register}
                    rules={{ required: true }}
                    name="DeviceCategory"
                    label={'Device Category'}
                    errors={errors.DeviceCategory}
                    defaultValue={item && item.name}
                    fullWidth
                />
                <StyledModalFooter actions={actions} />

            </form>
        </StyledModal>
    )
}
export default CatalogPacksAddForm

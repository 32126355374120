import React, { useState, useEffect } from 'react';
import { vocabulary } from '../../Strings';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../actions';
import AlertDialog from "../../Components/alertDialog";
import CategorieList from './CategorieListe'
import CatalogPacksAddForm from './CatalogSeabexPackAddForm'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledMaterialTable } from '../../../ui/display';
import { GetSeabexCategories, DeleteSeabexCategory } from '../../../globalsTools/network';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const CatalogPacksList = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [seabexCategoriePack, setSeabexCategoriePack] = useState(null)


    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userCatalogManagement));
        GetSeabexCategories(setSeabexCategoriePack)
        document.title = vocabulary.userCatalogManagement

    }, [dispatch]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.userCatalogManagement));
        document.title = vocabulary.userCatalogManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const openAlertConfirmDelete = (row) => {

        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 200) {
                snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                GetSeabexCategories(setSeabexCategoriePack)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteCatalogModel = (item) => {

        DeleteSeabexCategory(item.id
            , r => operationCallBack(r)
        )
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {
                seabexCategoriePack ?
                    <StyledMaterialTable
                        title={vocabulary.seabexModelType}
                        data={seabexCategoriePack.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: ({ rowData }) => { return (<CategorieList itemRow={rowData} />) }
                        }]}

                        columns={[
                            { title: vocabulary.name_label, field: 'name' },
                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editLabel,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.add,
                                isFreeAction: true,
                                onClick: (event) => handleClickOpen()
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            detailPanelType: 'single',
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],


                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
            }


            {
                alertConfirmDelete === true &&
                <AlertDialog
                    operation={vocabulary.delete}
                    open={alertConfirmDelete}
                    deletedItemName={item.name}
                    onClose={() => {
                        closeAlertConfirmDelete();
                    }}
                    confirmeDelete={() => {
                        if (item) {
                            handleDeleteCatalogModel(item);

                        }

                        closeAlertConfirmDelete();
                    }}

                    cancel={() => {
                        closeAlertConfirmDelete();
                    }}
                />}

            <CatalogPacksAddForm
                open={isEditing === false ? openAdd : openEdit}
                onClose={() => {
                    return (
                        isEditing === true ?
                            (setOpenEdit(false),
                                setIsEditing(false))
                            : setOpenAdd(false)

                    )
                }}
                width={500}
                item={isEditing === true ? item : null}
                isEditing={isEditing}
                reftechFunc={() => GetSeabexCategories(setSeabexCategoriePack)}
            />

        </ModalContext.Provider>

    )

}
export default CatalogPacksList
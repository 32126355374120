import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { leaveImpersonating } from "../../../actions";
import moment from "moment";
import { vocabulary } from "../../Strings";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
  },
}));


const Banner = () => {
  const classes = useStyles();

  const { user, expires_at } = useSelector((state) => state.app.impersonate);


  const [remainingTime, setRemainingTime] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();
 
  const leaveUserImpersonation = () => {
    dispatch(leaveImpersonating());
    history.replace("/app/organization/user/partners");

  };


  useEffect(() => {
 
    const intervalId = setInterval(() => {
      const duration = moment.duration(expires_at.utc().diff(moment.utc()));
     
      const formattedRemainingTime = duration.minutes() + ":" + duration.seconds();
      setRemainingTime(formattedRemainingTime);

      if (duration.asSeconds() <= 0) {
        clearInterval(intervalId);
        leaveUserImpersonation()
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [expires_at]);


  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">
        {vocabulary.impersonatingUser} {user.firstname} {user.lastname}
      </Typography>
      <Typography variant="subtitle1">{vocabulary.sessionEndsIn} {remainingTime}</Typography>
      <Button color="secondary" onClick={leaveUserImpersonation}>
        {vocabulary.leaveImpersonation}
      </Button>
    </div>
  );
};

export default Banner;

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField,
} from '@material-ui/core';
import { DeleteForever, HighlightOff } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EndProductionCycle } from '../../../globalsTools/network';
import { getDateFormat, logger } from '../../../globalsTools/utilities';
import StyledSelect from '../../../ui/forms/StyledSelect';
import { vocabulary } from '../../Strings';




const EndCycleDialog = ({ productionCycle, onSuccess, onCancel }) => {
    const [endDate, setEndDate] = useState(null)
    const [unit, setUnit] = useState(null)
    const [yieldValue, setYield] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const units = [
        vocabulary.quintals,
        vocabulary.tons,
        vocabulary.kilograms,
    ];



    useEffect(() => {
        if (productionCycle) {
            setEndDate(productionCycle.end_date ?? productionCycle.recommended_end_date)
        }

    }, [productionCycle])


    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (result) => {
        logger('RESULT', result)
        if (result && result.status === 200) {  // in case cycle ended by the user
            snackbar(vocabulary.cycleLabel + ' ' + vocabulary.cycleEndedOn + ' ' + moment(endDate).format(getDateFormat(currentLanguage)), 'info')
            onSuccess()
        } else {
            snackbar(vocabulary.errorOccurred + " " + result.status, 'error');
        }
    }

    const endProductionCycle = (end_date) => {
        if (end_date && productionCycle.id) {
            EndProductionCycle(productionCycle.id, endDate, {
                unit,
                yield: yieldValue
            }, operationCallBack)
        }
    }


    return (
        <Dialog
            open={true}
            onClose={() => onCancel()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                {vocabulary.warningLabel}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {vocabulary.confirmationLabelEndCycle}
                </DialogContentText>
                <DatePicker
                    label={vocabulary.endCycle}
                    value={endDate}
                    onChange={value => setEndDate(value.format('YYYY-MM-DD'))}
                    animateYearScrolling
                    style={{ margin: '8px', width: '100%' }}
                    format="YYYY-MM-DD"
                />

                <StyledSelect
                    name="unit"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    label={vocabulary.unit}
                    data={units}
                    autoWidth
                    styles={{ width: `100%` }}
                />

                <FormControl fullWidth>
                    <TextField
                        name="yield"
                        value={yieldValue}
                        onChange={(e) => setYield(e.target.value)}
                        label={vocabulary.yield}
                        fullWidth
                        type="number"
                    />
                </FormControl>

            </DialogContent>
            <DialogActions
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <Button
                    variant='contained'
                    onClick={() => onCancel()}
                    style={{ textTransform: 'none' }}
                >
                    <HighlightOff />
                    &nbsp;{vocabulary.cancelBtn}
                </Button>
                <Button
                    variant='contained'
                    type='submit'
                    onClick={() => endProductionCycle(endDate)}
                    color='secondary'
                >
                    <DeleteForever />
                    &nbsp;{vocabulary.end_cycle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EndCycleDialog;

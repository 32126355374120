/* eslint-disable  no-useless-concat */
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { vocabulary } from '../Strings';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import StyledCard from '../../ui/layout/StyledCard';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';

import { GetIrrigationPlanTask, DeleteTask,  GetDeployedDeviceGatewayByAreaId } from '../../globalsTools/network';

import CronTaskRuleForm from './CronTaskRuleForm';
import AlertDialog from "../Components/alertDialog";

import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const TasksTable = ({ rowData }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [open, setOpen] = useState(false);
    const [tasksList, setTasksList] = useState(null)
    // const [deployedDevices, setDeployedDevices] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [gatewayArray, setGatewayArray] = useState(null)

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        if (rowData) {
            GetIrrigationPlanTask(rowData.id, setTasksList)
            // getDeployedDeviceByAreaId(rowData.area.id, setDeployedDevices)
            GetDeployedDeviceGatewayByAreaId(rowData.area.id, setGatewayArray)
        }

    }, [rowData])

    const handleClose = () => {
        setOpen(false)
    };
    const handleClickOpen = () => {
        setOpen(true)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const handleDeleteTask = (item) => {

        DeleteTask(item.id
            , function (r) {
                if (r) {
                    if (r.status === 204) {
                        snackbar((item.deployed_device ? (item.deployed_device.name ? item.deployed_device.name : item.deployed_device.identifier) : '') + " " + vocabulary.deleteMsg, 'success')
                        GetIrrigationPlanTask(rowData.id, setTasksList)


                    } else {
                        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                    }
                }
            }
        )
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpen(!open)
            // setOpenEdit(!openEdit)
        } else {
            setOpen(newStatus)
            // setOpenEdit(newStatus)
        }
    }

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const contextValue = { open: open, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={contextValue}>
            <>
                {
                    (!isLoading && tasksList) ?
                        <StyledCard>


                            <StyledMaterialTable
                                title={vocabulary.tasksLabel}
                                data={tasksList}
                                localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                                columns={[

                                    { title: vocabulary.station, field: 'deployed_device', render: rowData => <>{rowData.deployed_device ? (rowData.deployed_device.name ? rowData.deployed_device.name : rowData.deployed_device.identifier) : "Station does not exist"}</> },
                                    // {
                                    //     title: vocabulary.equipmentLabel, field: 'port', render: rowData => <>{
                                    //         <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '2' }}>
                                    //             {rowData.deployed_device && rowData.deployed_device.area_deployed_device_equipment.filter(el => el.port == rowData.port).map((el, index) => {
                                    //                 return <span>{JSON.parse(el.equipment.name).name_fr + " " + "(" + vocabulary.port + " :" + el.port + " " + " )"}</span>
                                    //             })

                                    //             }
                                    //         </div>
                                    //     }</>
                                    // },
                                    { title: vocabulary.timeLabel, field: 'cron', render: rowData => <>{rowData.cron}</> },
                                    { title: vocabulary.status, field: 'status_chain', render: rowData => <>{rowData.status_chain}</> },

                                ]}
                                actions={[


                                    row => ({
                                        icon: 'delete_outline',
                                        tooltip: vocabulary.delete,
                                        onClick: (event, row) => openAlertConfirmDelete(row),

                                    }),


                                    {
                                        icon: () => <AddIcon fontSize='large' color='primary' />,
                                        tooltip: vocabulary.add,
                                        isFreeAction: true,
                                        onClick: (event) => handleClickOpen()
                                    }

                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: false



                                }}
                            />


                        </StyledCard>
                        : <StyledLoadingRow />}
                { open &&
                    <CronTaskRuleForm
                        onClose={handleClose}
                        open={open}
                        automationplan_id={rowData.id}
                        isEditing={false}
                        setIsLoading={setIsLoading}
                        refetchFunc={() => GetIrrigationPlanTask(rowData.id, setTasksList)}
                        gatewayArray={gatewayArray?.data}
                    // deployedDevices={deployedDevices ? deployedDevices.filter(station => (station.seabexmodel ? JSON.parse(station.seabexmodel.type).type === 'ACTION' : [])) : []}
                    />}

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.deployed_device ? (item.deployed_device.name ? item.deployed_device.name : item.deployed_device.identifier) : ''}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteTask(item)
                            }


                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
            </>
        </ModalContext.Provider>

    );
}
export default TasksTable;




/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */

import React, { useRef, useEffect, useState, useContext } from 'react';

import { Checkbox, Chip, FormControl, FormControlLabel, Input, InputLabel, ListItemText, MenuItem, Select, Switch, TextField } from '@material-ui/core/';
import cronsTrue from 'cronstrue';
import { DateTimePicker, TimePicker } from "@material-ui/pickers";

import AlertConfirmationAjoutTask from '../Components/alertConfirmationAjoutTask';
import { vocabulary } from '../Strings';
import './../../App.css';
import { useForm } from 'react-hook-form';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../ui/layout'

import StyledSelect from "../../ui/forms/StyledSelect";
import StyledSwitch from "../../ui/forms/StyledSwitch";
import { createCronTaskItem, getAreaDeployedDeviceEquipmentByType, getDevicesListByGatewayIdByType } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import { logger } from '../../globalsTools/utilities';
import { StyledMaterialTable } from '../../ui/display';
import { useSelector } from 'react-redux';
import { StyledInput } from '../../ui/forms';
import { addDurationToCron } from '../../globalsTools/cronUtilities';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200
        },
    },
};
// var cronstrue = require('cronstrue/i18n');

//1 -> 11
// m h dayMonth month weekday
const monthsArrayEn = [ 
    ['Every Month', "*"], 
    ['January'    , 1], 
    ['February'   , 2], 
    ['March'      , 3], 
    ['April'      , 4], 
    ['May'        , 5], 
    ['June'       , 6], 
    ['July'       , 7], 
    ['August'     , 8], 
    ['September'  , 9], 
    ['October'    , 10], 
    ['November'   , 11], 
    ['December'   , 12] 
];
const monthsArrayFr = [
    ['Chaque Mois',"*"], 
    ['Janvier'    , 1], 
    ['Février'    , 2], 
    ['Mars'       , 3], 
    ['Avril'      , 4], 
    ['Mai'        , 5], 
    ['Juin'       , 6], 
    ['Juillet'    , 7], 
    ['Août'       , 8], 
    ['Septembre'  , 9], 
    ['Octobre'    ,10], 
    ['Novembre'   ,11], 
    ['Décembre'   , 12] 
]

const monthDaysArrayEn = [['Every Month Day','*'], 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const monthDaysArrayFr = [['Chaque Jour du Mois','*'], 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const weekDaysArrayEn = [
    ['Every Day' , "*"],
    ['Monday'    , 1],
    ['Tuesday'   , 2],
    ['Wednesday' , 3],
    ['Thursday'  , 4],
    ['Friday'    , 5],
    ['Saturday'  , 6],
    ['Sunday'    , 7]
];
 
const weekDaysArrayFr = [
    ['Chaque Jour' , "*"],
    ['Lundi'       , 1],
    ['Mardi'       , 2],
    ['Mercredi'    , 3],
    ['Jeudi'       , 4],
    ['Vendredi'    , 5],
    ['Samedi'      , 6],
    ['Dimanche'    , 7]
];

// Custom Formatter component
const CronTaskRuleForm = ({ refetchFunc, isEditing, open, onClose, automationplan_id, gatewayArray, rules, setIsLoading }) => {

    const { enqueueSnackbar } = useSnackbar();

    //send two cron to open and close
    //or send period cron
    // const dispatch = useDispatch()
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    // const deployedDevices = useSelector(state => state.station.data.data.filter(station => (station.seabexmodel ? JSON.parse(station.seabexmodel.type).type === 'ACTION' : false)))

    const [selectedStationIndex, setSelectedStationIndex] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [startTime, setStartTime] = useState(moment());
    const [selectedWeekDaysArray, setSelectedWeekDaysArray] = useState(["*"]);
    const [selectedMonthDaysArray, setSelectedMonthDaysArray] = useState(['*']);
    const [selectedMonthsArray, setSelectedMonthsArray] = useState(['*']);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [cron1, setCron1] = useState('');
    const [cron1Description, setCron1Description] = useState('');
    const [cron2, setCron2] = useState('');
    const [cron2Description, setCron2Description] = useState('');
    const [isActivated, setIsActivated] = useState(false);
    const [deployedDevices, setDeployedDevices] = useState(null)
    const [filteredDeployedDevices, setFilteredDeployedDevices] = useState(null)
    const [deployedDevicesPorts, setDeployedDevicesPorts] = useState([])
    const [duration, setDuration] = useState(0)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const monthDaysArray = currentLanguage=='en' ? monthDaysArrayEn : monthDaysArrayFr ;
    const weekDaysArray = currentLanguage=='en' ? weekDaysArrayEn : weekDaysArrayFr ;
    const monthsArray = currentLanguage=='en' ? monthsArrayEn : monthsArrayFr ;


    useEffect(() => {
        if (filteredDeployedDevices?.[selectedDevice]?.id) {
            let ports  = deployedDevices?.data.filter(element => element.deployed_device.id===filteredDeployedDevices?.[selectedDevice]?.id)
            ports.sort((a,b)=> { logger("sorting", a, b, a.port<b.port); return a.port < b.port ? -1 : 1})
            setDeployedDevicesPorts(ports)
        }
    }, [selectedDevice, filteredDeployedDevices])

    useEffect(() => {
        if (deployedDevices) {
            setFilteredDeployedDevices(deployedDevices && Array.isArray(deployedDevices.data) && (deployedDevices?.data.flatMap(element => { return element.deployed_device})).reduce(function (acc, curr) {
                if (acc.findIndex(element => element.id==curr.id)==-1)
                    acc.push(curr);
                return acc;
            }, []))
        }
    }, [deployedDevices])
    

    useEffect(() => {
        getAreaDeployedDeviceEquipmentByType("Action", null, setDeployedDevices)
    }, [])
    

    const handleDateChangeFrom = key => date => {
        setFrom(date)
    };

    const handleDateChangeTo = key => date => {
        setTo(date)
    };

    const handleStartTimeChangeTo = (newValue) => {
        setStartTime(newValue)
    };



    const handleClickOpenAlert = () => {
        setOpenConfirmationDialog(true)

    };


    const createCron = () => {
        let cronString = ""

        if (selectedMonthDaysArray.indexOf("*") > -1)
            cronString = cronString + "* ";
        else {
            for (let i = 0; i < selectedMonthDaysArray.length; i++) {
                cronString = cronString + selectedMonthDaysArray[i]
                if (i < selectedMonthDaysArray.length - 1)
                    cronString = cronString + ","
                else
                    cronString = cronString + " "
            }
        }

        if (selectedMonthsArray.indexOf("*") > -1)
            cronString = cronString + "* ";
        else {
            for (let i = 0; i < selectedMonthsArray.length; i++) {
                cronString = cronString + selectedMonthsArray[i]
                if (i < selectedMonthsArray.length - 1)
                    cronString = cronString + ","
                else
                    cronString = cronString + " "
            }

        }

        if (selectedWeekDaysArray.indexOf("*") > -1)
            cronString = cronString + "*";
        else {
            for (let i = 0; i < selectedWeekDaysArray.length; i++) {
                cronString = cronString + selectedWeekDaysArray[i]
                if (i < selectedWeekDaysArray.length - 1)
                    cronString = cronString + ","
            }

        }

        // let [mm, hh] = startTime.split(":")

        // logger ("Time Minutes", startTime, mm, hh)

        let cron1 = startTime.minutes()+" "+ startTime.hours() + " " + cronString
        let cron2 = addDurationToCron(cron1, duration)

        return [cron1, cron2]
    }

    const handleConfirmePopup = () => {

        let [cron1, cron2] = createCron()

        setCron1(cron1)
        setCron2(cron2)

        logger("Cron Description", cron1, cronsTrue.toString(cron1, { locale: "fr" }), cronsTrue.toString("*/5 * * * *", { locale: "fr" }) )
        logger("Cron Description", cron2, cronsTrue.toString(cron2, { locale: "fr" }), cronsTrue.toString("*/5 * * * *", { locale: "fr" }) )
        setCron1Description({ 'name_ar': cronsTrue.toString(cron1, { locale: "ar" }), 'name_fr': cronsTrue.toString(cron1, { locale: "fr" }), 'name_en': cronsTrue.toString(cron1, { locale: "en" }) })
        setCron2Description({ 'name_ar': cronsTrue.toString(cron2, { locale: "ar" }), 'name_fr': cronsTrue.toString(cron2, { locale: "fr" }), 'name_en': cronsTrue.toString(cron2, { locale: "en" }) })
        // setCron2Description(cronsTrue.toString(cron2))

        handleClickOpenAlert()


    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const handleCloseAlert = () => {
        setOpenConfirmationDialog(false)
    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(vocabulary.tasksLabel + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            } else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }
    const handleCreateCronTaskItem = () => {
        setIsLoading(true)
        let [cron1, cron2] = createCron()

        setCron1(cron1)
        setCron2(cron2)
        let statusArray = deployedDevicesPorts
        let statusChain = [];
        let statusChainReset = [];
        statusArray.forEach(element => {
            statusChain.push(element.port + "_" + (element.checked ? 1 : 0))
            statusChainReset.push(element.port + "_0" )
        });
        statusChain.join(':')
        statusChainReset.join(':')
       createCronTaskItem(
        // logger("Back Query >> ",
            [{
                deployed_device_id: filteredDeployedDevices?.[selectedDevice]?.id,
                cron: cron1,
                description: JSON.stringify(cron1Description),
                status_chain: statusChain.join(':'),
                automation_plan_id: automationplan_id,
                start_date:moment(from).format("YYYY-MM-DD HH:mm"),
                end_date:moment(to).format("YYYY-MM-DD HH:mm")
                
            }, {
                deployed_device_id: filteredDeployedDevices?.[selectedDevice]?.id,
                cron: cron2,
                description: JSON.stringify(cron1Description),
                status_chain: statusChainReset.join(':'),
                automation_plan_id: automationplan_id,
                start_date:moment(from).format("YYYY-MM-DD HH:mm"),
                end_date:moment(to).format("YYYY-MM-DD HH:mm")
                
            }],
         r => operationCallBack(r)
     )


       handleCloseAlert()
       onClose();
    }

    const handleCheckChange = (rowData) => {
        let index = deployedDevicesPorts?.findIndex((element) => (element.deployed_device.id == rowData.deployed_device.id && element.port == rowData.port) )

        let deployedDevicesPortsCopy = [...deployedDevicesPorts]

        if (rowData.checked) {
            deployedDevicesPortsCopy[index].checked = !rowData.checked
        } else {
            deployedDevicesPortsCopy[index].checked = true
        }
        setDeployedDevicesPorts(deployedDevicesPortsCopy)
        logger("Check RowData", rowData)
    }

    const actions = [
        { title: vocabulary.confirmBtn, color: "primary", position: 'end', type: "submit", disabled: duration == 0},
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => onClose() }
    ]

     return (


        <StyledModal
            open={open}
            onClose={onClose}
            modalContext={modalContext}
            width={700}
            title={isEditing === false ? vocabulary.add + " " + vocabulary.tasksLabel : vocabulary.editCycle}
        >
            <form
                style={{ marginTop: 0, marginButtom: 60 }}
                ref={form}
                onSubmit={handleSubmit(handleConfirmePopup)}
                onError={errors => console.log(errors)}
            >
                {/* <div style={{
                    display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 35,
                }}> */}
                    {/* <StyledSelect
                        value={selectedGatewayIndex}
                        register={register}
                        rules={{ required: true }}
                        //TODO: fix error message gateway
                        errors={errors.selectedStationIndex}
                        onChange={(event) => {
                            setSelectedGatewayIndex(event.target.value)
                        }}
                        name='selectedGatewayIndex'
                        label={vocabulary.selectGateway}
                        fullWidth
                        data={gatewayArray}
                        valueField='id'
                        labelField='name'
                        indexV={true}
                        styles={{ width: '100%' }}
                        disabled={!gatewayArray}

                    /> */}
                    <StyledSelect
                        value={selectedDevice}
                        register={register}
                        onChange={(event) => {
                            setSelectedDevice(event.target.value)
                        }}
                        name='selectedDevice'
                        label={vocabulary.selectStation}
                        fullWidth
                        data={filteredDeployedDevices}
                        valueField='id'
                        labelField='identifier'
                        indexV={true}
                        styles={{ width: '100%' }}
                        disabled={!filteredDeployedDevices}


                    />

{/* 
                    <FormControl style={{ margin: '8px', width: '100%' }} >

                        <InputLabel id="demo-simple-select-outlined-label">{vocabulary.selectActuator}</InputLabel>

                        <Select
                            value={selectedActuator}
                            onChange={(event) => {
                                setSelectedActuator(event.target.value)
                            }}
                            name='selectedActuator'
                            validators={['required']}
                            errorMessages={[vocabulary.required]}
                            label={vocabulary.selectActuator}
                            disabled={!(deployedDevices && deployedDevices[selectedStationIndex])}

                        >

                            {
                                deployedDevices && deployedDevices[selectedStationIndex] ? (
                                    deployedDevices[selectedStationIndex].area_deployed_device_equipment.filter(el => el.equipment !== null).map((item, index) =>
                                        <MenuItem key={index} value={item.port}>   {item.equipment && JSON.parse(item.equipment.name).name_fr} ({vocabulary.port} : {item.port} )</MenuItem>
                                    ))
                                    : null
                            }
                        </Select>
                    </FormControl > */}


                {/* </div> */}
                {/* <div style={{ marginTop: -15, margin: '8px', width: '100%' }} >
                    <StyledSwitch
                        checked={isActivated}
                        onChange={(event) => {
                            setIsActivated(event.target.checked)
                        }}
                        value={"isActivated"}
                        label={isActivated == false ? vocabulary.offLabel : vocabulary.onLabel}
                        FormLabel={vocabulary.selectStatus}
                    />

                </div> */}

                <div style={{ display: 'flex', margin: '8px', width: '100%', justifyContent: 'space-between' }}>
                        <DateTimePicker
                            label={vocabulary.from}
                            value={from}
                            variant="inline"
                            format={
                                currentLanguage == 'fr'
                                    ? 'DD/MM/YYYY HH:mm'
                                    : 'MM/DD/YYYY HH:mm'
                            }
                            onChange={handleDateChangeFrom('from')}
                        />
                        <DateTimePicker
                            label={vocabulary.to}
                            value={to}
                            variant="inline" 
                            format={
                                currentLanguage == 'fr'
                                    ? 'DD/MM/YYYY HH:mm'
                                    : 'MM/DD/YYYY HH:mm'
                            }
                            onChange={handleDateChangeTo('to')}
                        />
                        <TimePicker
                            label={vocabulary.startTime}
                            value={startTime}
                            ampm={false}
                            variant="inline"
                            onChange={(newValue) => handleStartTimeChangeTo(newValue)}
                        />
                </div>
                <div>
                        <StyledInput 
                            InputLabelProps={{ shrink: true }} 
                            label={vocabulary.duration + ' (Minutes)'}
                            name="duration"
                            type="number"
                            helperText="Durée pendant laquelle la tâche reste active" 
                            InputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}} 
                            onChange={(event) => {
                                setDuration(event.target.value);
                              }}
                            value={duration}
                            
                            
                        />

                </div>


                {(deployedDevices?.data) ?
                        <StyledMaterialTable
                            title={vocabulary.activityType}
                            data={deployedDevicesPorts}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}

                            columns={[
                                { title: vocabulary.identifier, field: 'deployed_device.identifier' },
                                { title: vocabulary.equipmentLabel, render: rowData => <>{rowData.equipment ? JSON.parse(rowData?.equipment?.name)['name_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.site, field: 'area.name' },
                                { title: vocabulary.port, field: 'port' },

                            ]}
                            actions={[
                                rowData => ({

                                    tooltip: vocabulary.action,
                                    icon: () => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={rowData.checked ?? false}
                                                    onChange={() => handleCheckChange(rowData)}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    ),
                                    // onClick: (_, row) => {
                                    //     setAlertConfirmAction(true)
                                    //     setCurrentItem(row)
                                    // }
                                })
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 40, 60, 80, 100],

                            }}
                        />

                        : <StyledLoadingRow />
                    }


                {/* CRON COMPOSER  */}

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <FormControl >
                        <InputLabel htmlFor="select-multiple-checkbox">{vocabulary.selectedMonths}</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            value={selectedMonthsArray}
                            onChange={(event) => setSelectedMonthsArray(event.target.value)}

                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => (
                                <div >
                                    {selected.map(value => (


                                        <Chip
                                            key={value}
                                            label={monthsArray.find(element => element[1]==value)?.[0]}
                                            onDelete={() => {
                                                setSelectedMonthsArray(
                                                    selectedMonthsArray.filter(function (element) {
                                                        return element !== value
                                                    })
                                                )

                                            }}
                                            color="primary"
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {monthsArray.map(element => 
                                <MenuItem key={element[0]} value={element[1]}>
                                    <Checkbox checked={selectedMonthsArray.indexOf(element[1]) > -1} />
                                    <ListItemText primary={element[0]} />
                                </MenuItem>
         
                            )}
                        </Select>
                    </FormControl>

                    <FormControl >
                        <InputLabel htmlFor="select-multiple-checkbox">{vocabulary.selectedMonthDays} </InputLabel>
                        <Select
                            fullWidth
                            multiple
                            value={selectedMonthDaysArray}
                            onChange={(event) => setSelectedMonthDaysArray(event.target.value)}

                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => (
                                <div>
                                    {selected.map((value,index) => (

                                        < Chip
                                            key={value}
                                            label={typeof (monthDaysArray.find(element => (typeof element=="object" ? element[1] : element)==value)) == "object" ? monthDaysArray.find(element => (typeof element=="object" ? element[1] : element)==value)?.[0] : monthDaysArray.find(element => (typeof element=="object" ? element[1] : element)==value)}
                                            onDelete={() => {
                                                setSelectedMonthDaysArray(
                                                    selectedMonthDaysArray => [...selectedMonthDaysArray.filter(function (element) {
                                                        return typeof element=="object" ? element[1] !== value : element !== value
                                                    })]
                                                )
                                                selected.splice(index, 1)

                                            }}
                                            color="primary"
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {monthDaysArray.map(element => (

                                <MenuItem key={typeof element=="object" ? element[0] : element} value={typeof element=="object" ? element[1] : element}>
                                    <Checkbox checked={typeof element=="object" ? selectedMonthDaysArray.indexOf(element[1]) > -1 : selectedMonthDaysArray.indexOf(element) > -1} />
                                    <ListItemText primary={typeof element=="object" ? element[0] : element} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl >
                        <InputLabel htmlFor="select-multiple-checkbox">{vocabulary.selectedWeekDays}</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            value={selectedWeekDaysArray}
                            // onChange={(event) =>handleChange(event)}
                            onChange={(event) => setSelectedWeekDaysArray(event.target.value)}

                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => (
                                <div
                                // className={classes.chips}
                                >
                                    {selected.map(value => (

                                        <Chip
                                            key={value}
                                            label={weekDaysArray.find(element => element[1]==value)?.[0]}
                                            onDelete={() => {
                                                setSelectedWeekDaysArray(
                                                    selectedWeekDaysArray.filter(function (element) {
                                                        return element[1] !== value
                                                    })
                                                )

                                            }}
                                            //     className={classes.chip}
                                            color="primary"
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            { weekDaysArray.map(element =>
                                <MenuItem key={element[0]} value={element[1]}>
                                    <Checkbox checked={selectedWeekDaysArray.indexOf(element[1]) > -1} />
                                    <ListItemText primary={element[0]} />
                                </MenuItem>
                                ) 
                            }
                        </Select>
                    </FormControl>

                    <StyledModalFooter actions={actions} />

                </div>



            </form>

            {openConfirmationDialog &&
                <AlertConfirmationAjoutTask
                    operation={"Ajout"}
                    open={openConfirmationDialog}
                    isActivated={isActivated}
                    cron1Description={cron1Description}
                    cron2Description={cron2Description}
                    onClose={() => {
                        handleCloseAlert()
                    }}

                    confirmAjout={handleCreateCronTaskItem}
                    cancel={() => {
                        handleCloseAlert()
                    }}
                />}

        </StyledModal >

    );

}

export default CronTaskRuleForm;
/* eslint-disable eqeqeq*/

import React, {useState} from 'react';

import { vocabulary } from '../../Strings';
import StyledSwitch from "../../../ui/forms/StyledSwitch";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const LineChartLogs = ({ dataTypeName, unit, data }) => {

    const DOT_SHAPE = { strokeWidth: 2, r: 2 };

    const [autoScale, setAutoScale] = useState(0)

    const mapData = (data) => {

        const linedata = [];
        if (data) {
            // let dataTable = data.sort((a, b) =>  new Date(a.capture_time).getTime() - new Date(b.capture_time).getTime())

            data.reverse().map(e => {
                const obj = {
                    date: (moment(e.capture_time).format('DD-MM-YYYY HH:mm:ss')),
                    // value: e.calculated_value ? Number(Number(e.calculated_value).toFixed(2)) : Number(Number(e.value).toFixed(2))
                }
                obj[dataTypeName] = e.calculated_value ? Number(Number(e.calculated_value).toFixed(2)) : Number(Number(e.value).toFixed(2))
                linedata.push(obj)
                return true
            })

            return linedata

        }
    }
    const handleScaleChange = () => {
        autoScale == 'auto' ? setAutoScale(0) : setAutoScale('auto')
    }
    return (
        <div style={{ height: '500px', display: 'flex' }}>

            {data &&
                <>

                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={500}
                            data={mapData(data)}

                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke='#f5f5f5' />

                            <XAxis
                                dataKey="date"
                            />
                            {/* <YAxis /> */}
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={46} />
                            <YAxis
                                type='number'
                                domain={['auto', 'auto']}
                                width={100}
                                unit={' ' + unit}
                                yAxisId='calculatedValue'
                                // label={{ value: dataTypeName, angle: -90, position: 'insideLeft' }}
                            ></YAxis>

                            <Line
                                type="monotone"
                                dataKey={dataTypeName}
                                stroke="#03465b"
                                activeDot={{ r: 8 }}
                                strokeWidth={3}
                                dot={DOT_SHAPE}
                                yAxisId='calculatedValue'

                            />
                        </LineChart>
                    </ResponsiveContainer>

                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <StyledSwitch
                            checked={autoScale}
                            onChange={(event) => handleScaleChange(event, "autoScale")}
                            value={"autoScale"}
                            label={vocabulary.autoScaleLabel}
                        />
                    </div>

                </>
            }

        </div>
    )

}
export default LineChartLogs
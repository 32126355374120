import React from 'react'
import Impersonate from '../Impersonate'
import AreasList from '../../../Sites/AreasList'


export default function Areas() {

    return (
        <Impersonate>
            <AreasList />
        </Impersonate>
    )
}

import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { vocabulary } from '../../Strings';
import { setCurrentPage } from '../../../actions';
import { GetAllError, ErrorChecked, ErrorCorrected } from '../../../globalsTools/network'
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';

const ErrorList = () => {

    const moment = require('moment');

    const [errorList, setErrorList] = useState(null)
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.errorHandler))
        GetAllError(setErrorList)
        document.title=vocabulary.errorHandler

    }, [dispatch]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.errorHandler))
        document.title=vocabulary.errorHandler

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const changeErrorChecked = (id) => {
        ErrorChecked(
            {
                checked: true,
                corrected: false
            }, id
            , function (r) {
                if (r) {
                    if (r.status === 200) {
                        snackbar(vocabulary.errorChecked, 'success')
                        GetAllError(setErrorList)

                    } else {
                        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                    }
                }
            })


    }
    const changeErrorCorrected = (id) => {
        ErrorCorrected(
            {
                checked: true,
                corrected: true
            }, id
            , function (r) {
                if (r) {
                    if (r.status === 200) {
                        snackbar(vocabulary.errorCorrected, 'success')
                        GetAllError(setErrorList)

                    } else {
                        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                    }
                }
            })

    }
    return (
        <StyledCard>

            {
                errorList ?
                    <StyledMaterialTable
                        title={vocabulary.errorList}
                        data={errorList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay },header:{actions:vocabulary.actions},pagination:{nextTooltip:vocabulary.nextpage,previousTooltip:vocabulary.previouspage,lastTooltip:vocabulary.lastpage,firstTooltip:vocabulary.firstpage,labelRowsSelect:vocabulary.rowsLabel},toolbar:{searchPlaceholder:vocabulary.search} }}

                        columns={[
                            { title: vocabulary.captureTime, field: 'capture_time', render: rowData => <>{rowData.capture_time && moment(rowData.capture_time, 'YYYY-MM-DD').format('YYYY-MM-DD')}</> },
                            { title: vocabulary.statusCode, field: 'status_code' },
                            { title: vocabulary.error_text, field: 'error_text' },
                            { title: vocabulary.status, field: 'checked', render: rowData => <>{(rowData.checked === true && rowData.corrected === false) ? 'Checked' : 'Corrected'}</> },

                        ]}
                        actions={[
                            {
                                icon: () => <CheckIcon />,
                                tooltip: vocabulary.checkedLabel,
                                onClick: (event, row) => changeErrorChecked(row.id)
                            },
                            row => ({
                                icon: () => <DoneAllIcon />,
                                tooltip: vocabulary.correctedLabel,
                                onClick: (event, row) => changeErrorCorrected(row.id),

                                disabled: row.id < 2000
                            })


                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
            }
        </StyledCard>


    )
}
export default ErrorList
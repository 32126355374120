import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../../globalConsts";
import { useHistory } from "react-router-dom";
import {
  GuideContext,
  steps,
} from "../../../ui/layout/contextLayout/GuideContext";

function SeabexMapStepper({
  setOpenGoto,
  prevStepGuide,
  setPrevStepGuide,
  mapRef,
  disableDrawBar,
  drawControl,
  setEditingMap,
  editingItem,
  handleLayersData,
  layersDataInitial,
  geoJSONLayer,
  geoJSONOptions,
  latlngJsonToMarker,
  addNewArea,
  addAreaFn,
  markersLayer,
}) {
  const { guide, dispatchGuide } = useContext(GuideContext);
  const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element === ".addIcon") {
          dispatchGuide({ type: IS_ENABLED, payload: false });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: CURRENT_STEP, payload: "areasList" });
        } else if (steps[nextStepIndex].element === ".goToModalDialog") {
          dispatchGuide({
            type: IS_ENABLED,
            payload: false,
          });
          dispatchGuide({
            type: INITIAL_STEP,
            payload: nextStepIndex,
          });
          dispatchGuide({
            type: CURRENT_STEP,
            payload: "goToDialog",
          });
          setOpenGoto(true);
        } else if (
          steps[nextStepIndex].element === ".drawAreaBtn" &&
          prevStepGuide ===
            steps.findIndex(
              (step) => step.element === ".drawPolygonRectangleBtn"
            )
        ) {
          setPrevStepGuide(nextStepIndex);
          dispatchGuide({
            type: IS_ENABLED,
            payload: false,
          });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          mapRef.current = disableDrawBar(
            mapRef.current,
            drawControl.current,
            setEditingMap
          );
          editingItem === null &&
            handleLayersData(
              layersDataInitial.data,
              mapRef.current,
              geoJSONLayer.current,
              markersLayer.current,
              geoJSONOptions,
              latlngJsonToMarker
            );
          setTimeout(() => {
            dispatchGuide({
              type: IS_ENABLED,
              payload: true,
            });
          }, 1000);
        } else if (
          steps[nextStepIndex].element === ".drawPolygonRectangleBtn"
        ) {
          addNewArea();
          setPrevStepGuide(nextStepIndex);
          // dispatchGuide({
          //     type: IS_ENABLED,
          //     payload: false,
          //     })
          //     // TODO: select draw control on this step
          //     setTimeout(() => {
          //         dispatchGuide({
          //             type: IS_ENABLED,
          //             payload: true,
          //             })
          //     }, 700);
        } else if (steps[nextStepIndex].element === ".addNewAreaModal") {
          dispatchGuide({
            type: IS_ENABLED,
            payload: false,
          });
          dispatchGuide({
            type: INITIAL_STEP,
            payload: nextStepIndex,
          });
          dispatchGuide({
            type: CURRENT_STEP,
            payload: "addAreaModal",
          });
          addAreaFn({
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [47.98032933241871, 1.790484006153332],
                  [47.98038535557031, 1.79063479545106],
                  [47.98034636491941, 1.790664059516312],
                  [47.98029309714941, 1.79052074377172],
                  [47.97974704302054, 1.790970780936774],
                  [47.97587484075826, 1.794376366228585],
                  [47.97586582690788, 1.79442622213231],
                  [47.97905463876832, 1.803013810883338],
                  [47.97907618346315, 1.803024851163388],
                  [47.98262815022268, 1.79993477223733],
                  [47.98297483125467, 1.799633225539838],
                  [47.98726512200058, 1.795888449045942],
                  [47.98734732686049, 1.795815875188098],
                  [47.98735136155555, 1.795744838476825],
                  [47.9865609479073, 1.794543127103294],
                  [47.98505095905075, 1.792246684467682],
                  [47.98459910005821, 1.791560655492774],
                  [47.98445376743015, 1.791321882113506],
                  [47.98436068979451, 1.791128031276523],
                  [47.98425247871674, 1.790852056094496],
                  [47.9833792819142, 1.787888524033106],
                  [47.983361916613, 1.787878724611791],
                  [47.98032933241871, 1.790484006153332],
                ],
              ],
            },
          });
        } else if (steps[nextStepIndex].element === ".mapViewBtn") {
          dispatchGuide({ type: IS_ENABLED, payload: false });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: CURRENT_STEP, payload: "areasList" });
        } else if (steps[nextStepIndex].element === ".realTimeintro") {
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: CURRENT_STEP, payload: "realTime" });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          history.push("/app/realtime");
        }
      }}
      options={{
        tooltipClass: "steps",
      }}
      // onBeforeExit={()=>false}
      onExit={() => {
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        });
      }}
    />
  );
}

export default SeabexMapStepper;

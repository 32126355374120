/* eslint-disable no-useless-escape*/
/* eslint-disable no-unused-vars*/

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../../globalStyle';
import { Typography, Grid, FormControlLabel, Checkbox } from "@material-ui/core/";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledAutoComplete from "../../../ui/forms/StyledAutoComplete";
import StyledInput from "../../../ui/forms/StyledInput";
import { AddOrganization, GetUsersList, UpdateOrganizations } from "../../../globalsTools/network";
import { StyledModal } from '../../../ui/layout'
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';


const OrganizationAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)


    const [id, setId] = useState()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [selectedOwnerId, setSelectedOwnerId] = useState(null)
    const [address, setAddress] = useState('')
    const [status, setStatus] = useState('')
    const [hasOwner, setHasOwner] = useState(false)


    const { enqueueSnackbar } = useSnackbar();


    const [usersList, setUsersList] = useState(null)

    useEffect(() => {

        GetUsersList(setUsersList)

    }, [setUsersList])




    useEffect(() => {
        if (isEditing === false) {
            setName('')
            setMobile('')
            setEmail('')
            setSelectedOwnerId('')
            setAddress('')
        }

    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    useEffect(() => {

        if (item) {

            setId(item?.id)
            setName(item?.name)
            setEmail(item?.email)
            setMobile(item?.mobile)
            setAddress(item?.address)
            setStatus(item?.status)
            setSelectedOwnerId(item?.owner_id)

            //setAddress(item?.address && JSON.parse(item?.address) && JSON.parse(item?.address).address)


        }




    }, [item, isEditing]); // N’exécute l’effet que si item a changé

    const handleResponse = (response, success) => {
        if (response) {
            if (success) {

                //snackbarMsg(vocabulary.emailCheckAccount, 'warning');
                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response.response.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }
    const handleFormSubmit = () => {

        if (isEditing) {
            UpdateOrganizations(
                {
                    id,
                    name,
                    email,
                    mobile,
                    address : JSON.stringify(address),
                    status,
                    owner_id: selectedOwnerId,

                }, handleResponse
            )
        } else {
            let data = {
                id,
                name,
                email,
                mobile,
                address : JSON.stringify(address),
                status
            }
            if(selectedOwnerId){
                data = {...data, owner_id : selectedOwnerId}
            }
            AddOrganization(data, handleResponse
)
        }
    }


    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewUser : vocabulary.EditUser}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>

                    {/* <StyledInfoBubble type='info'>{vocabulary.invitationVoucherWarning}</StyledInfoBubble> */}

                    <form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        style={{ ...formStyle }}>


                        <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillTextAddUser}</Typography>
                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="name"
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.name}

                                    label={vocabulary.lastname}
                                    type="text"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    styles={{ width: `100%` }}
                                />
                            </Grid>
                            {
                                usersList && hasOwner && <Grid item xs style={{ display: 'flex' }}>

                                    <StyledAutoComplete
                                        data={usersList ?? []}
                                        defaultValue={usersList?.find((item) => item.id == selectedOwnerId)}
                                        // defaultValue={selectedOwnerId}
                                        optionLabel={(option) => option?.email ?? ''}
                                        onChange={(event, newValue) => {
                                            console.log('New Value ', newValue);
                                            setSelectedOwnerId(newValue ? newValue.id : '')
                                        }}
                                        label={vocabulary.owner}
                                        styles={{ width: `100%` }}
                                    />
                                </Grid>
                            }
                        </Grid>

                        {
                            !hasOwner && (
                                <>
                                    <StyledInput
                                        register={register}
                                        rules={{ required: true, validate: validateEmail }}
                                        name="email"
                                        label={vocabulary.email}
                                        type="text"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        errors={errors.email}
                                        disableAutoComplete={true}
                                    />
                                    {
                                        errors.email &&
                                        <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                                            {vocabulary.mailInvalid}
                                        </span>
                                    }
                                </>
                            )
                        }
                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput

                                    errors={errors.address}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="address"
                                    value={address}
                                    onChange={(event) => {
                                        setAddress(event.target.value)

                                    }}
                                    label={vocabulary.address}

                                    type="text"
                                    fullWidth

                                />

                            </Grid>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    errors={errors.mobile}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="mobile"
                                    value={mobile}
                                    onChange={(event) => {
                                        setMobile(event.target.value)

                                    }}
                                    label={vocabulary.enterPhoneNumber}

                                    type="text"
                                    fullWidth

                                />
                            </Grid>

                        </Grid>
                        <Grid item xs style={{ display: 'flex' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasOwner}
                                        onChange={() => setHasOwner(!hasOwner)}
                                        name="owner"
                                        color="primary"
                                    />
                                }
                                label={vocabulary.owner}
                            />
                        </Grid>

                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}

export default OrganizationAddForm
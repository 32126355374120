import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../../globalConsts";
// import { useHistory } from "react-router-dom";
import { GuideContext, steps } from "../../../ui/layout/contextLayout/GuideContext";

const AddAreaStepper1 = ({ closeFn, changeStep }) => {
  
  const { guide, dispatchGuide } = useContext(GuideContext);
  // const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element === ".drawPolygonRectangleBtn") {
          dispatchGuide({
            type: IS_ENABLED,
            payload: false,
          });
          dispatchGuide({
            type: INITIAL_STEP,
            payload: nextStepIndex,
          });
          dispatchGuide({ type: CURRENT_STEP, payload: "seabexMapBack" });
          closeFn();
        }
        if (steps[nextStepIndex].element === ".dataSourceTable") {
          dispatchGuide({
            type: IS_ENABLED,
            payload: false,
          });
          dispatchGuide({
            type: INITIAL_STEP,
            payload: nextStepIndex,
          });
          changeStep(2);
        }
      }}
      options={{
        tooltipClass: "steps",
      }}
      onExit={() => {
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        });
      }}
    />
  );
}

export default AddAreaStepper1;

import React from 'react';

import DataTable from './DataTable'

const GovernatesList = (props) => {

    return ( 
            <>
            <DataTable
                cities={props.cities}
                searchCity={true}
                setparams={props.setparams}
                areaParams={props.areaParams}
            />
            </>

    )
}
export default GovernatesList;
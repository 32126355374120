/* eslint-disable eqeqeq*/

import React, { useCallback, useEffect, useState } from 'react';

import { vocabulary } from '../../Strings';
// import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import { Typography, Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import MoreIcon from '@material-ui/icons/More';
import BackspaceIcon from '@material-ui/icons/Backspace';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';

import './styles.css'
import { Check, Close } from '@material-ui/icons';

const CultureTypeDetail = ({ itemRow }) => {

    const [selectedCultureKc, setSelectedCultureKc] = useState([])
    const [selectedcultureGrowthDuration, setSelectedcultureGrowthDuraation] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [gwrothDetail, setGwrothDetail] = useState(0);


    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const handleClickChangeGrowthInitialDetails = () => {
        setGwrothDetail(1)
    }
    const handleClickChangeNextGrowthDetails = () => {
        setGwrothDetail(0)
    }
    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? 'simple-popover' : undefined;

    useEffect(() => {
        setSelectedCultureKc(JSON.parse(itemRow.kc))
        setSelectedcultureGrowthDuraation(JSON.parse(itemRow.growth_duration))


    }, [itemRow])

    const mtStyleFn = useCallback((rowData) => {
        switch (Number(rowData.sensibilite)) {
            case 0:
            case 1:
                break;
            case 2:
                return { backgroundColor: '#E1F5FE' }
            case 3:
                return { backgroundColor: '#FFF8E1' }
            case 4:
                return { backgroundColor: '#FBE9E7' }
            case 5:
                return { backgroundColor: '#FF8A80' }
        
            default:
                break;
        }
        
    }, [])


    return (
        <>
            {(selectedcultureGrowthDuration && selectedCultureKc) ?
                <div className=" container-fluid row cultureDetail">

                    <div className="col-lg-6 col-md-6 col-sm-12  cultureKc ">
                        <Typography className="kcDetailTitle" variant="h4" gutterBottom>
                            <b>{vocabulary.kc}</b>
                            <div >
                                <IconButton
                                    aria-label="Help"
                                    onClick={handleClickOpenPopover}

                                >
                                    <HelpIcon style={{ fontSize: "20px" }} />
                                </IconButton>
                            </div>
                        </Typography>
                        <div>
                            <Typography className="kcDetail" variant="h6" gutterBottom>
                                <b> {vocabulary.initialStage}</b>  {' : ' + selectedCultureKc["Phase initiale"]}
                            </Typography>
                            <Typography className="kcDetail" variant="h6" gutterBottom>
                                <b> {vocabulary.developmentPhase} </b> {' : ' + selectedCultureKc["Phase de developpement"]}
                            </Typography>
                            <Typography className="kcDetail" variant="h6" gutterBottom>
                                <b>{vocabulary.midSeasonPhase}</b>   {' : ' + selectedCultureKc["Phase mi-saison"]}
                            </Typography>
                            <Typography className="kcDetail" variant="h6" gutterBottom>
                                <b>{vocabulary.phaseArriereSaison}</b>  {' : ' + selectedCultureKc["Phase arrière-saison"]}
                            </Typography>
                        </div>

                    </div>

                    {selectedcultureGrowthDuration ?
                        <div className="col-lg-6 col-md-6 col-sm-12 growthDuration">
                            <Typography className="kcDetailTitle" variant="h4" gutterBottom>
                                <b> {vocabulary.growthDurationLabel}</b>
                                <div >
                                    <IconButton
                                        aria-label="MoreIcon"
                                        onClick={gwrothDetail == 0 ? handleClickChangeGrowthInitialDetails : handleClickChangeNextGrowthDetails}

                                    >
                                        {gwrothDetail == 0 ? <MoreIcon style={{ fontSize: "20px" }} /> : <BackspaceIcon style={{ fontSize: "20px" }} />
                                        }                                    </IconButton>
                                </div>
                            </Typography>
                            <div>
                                <Typography className="kcDetail" variant="h6" gutterBottom>
                                    <b>{vocabulary.total}</b>  {gwrothDetail == 0 ? ' : ' + selectedcultureGrowthDuration[0].Total + ' ' + vocabulary.dayLabel : ' : ' + selectedcultureGrowthDuration[1].Total + ' ' + vocabulary.dayLabel}
                                </Typography>
                                <Typography className="kcDetail" variant="h6" gutterBottom>
                                    <b>{vocabulary.initialStage}</b>   {gwrothDetail == 0 ? ' : ' + selectedcultureGrowthDuration[0]["Phase initiale"] + ' ' + vocabulary.dayLabel : ' : ' + selectedcultureGrowthDuration[1]["Phase initiale"] + ' ' + vocabulary.dayLabel}
                                </Typography>
                                <Typography className="kcDetail" variant="h6" gutterBottom>
                                    <b> {vocabulary.developmentPhase}</b>  {gwrothDetail == 0 ? ' : ' + selectedcultureGrowthDuration[0]["Phase de developpement"] + ' ' + vocabulary.dayLabel : ' : ' + selectedcultureGrowthDuration[1]["Phase de developpement"] + ' ' + vocabulary.dayLabel}
                                </Typography>
                                <Typography className="kcDetail" variant="h6" gutterBottom>
                                    <b>{vocabulary.midSeasonPhase}</b>  {gwrothDetail == 0 ? ' : ' + selectedcultureGrowthDuration[0]["Phase mi-saison"] + ' ' + vocabulary.dayLabel : ' : ' + selectedcultureGrowthDuration[1]["Phase mi-saison"] + ' ' + vocabulary.dayLabel}
                                </Typography>
                                <Typography className="kcDetail" variant="h6" gutterBottom>
                                    <b>{vocabulary.phaseArriereSaison}</b>  {gwrothDetail == 0 ? ' : ' + selectedcultureGrowthDuration[0]["Phase arrière-saison"] + ' ' + vocabulary.dayLabel : ' : ' + selectedcultureGrowthDuration[1]["Phase arrière-saison"] + ' ' + vocabulary.dayLabel}
                                </Typography>
                            </div>
                        </div> : null}
                    {anchorEl !== false &&
                        <div >
                            <Popover
                                id={idPopover}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}

                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <Typography style={{ padding: '20px', textAlign: 'justify', lineHeight: '2', whiteSpace: 'break-spaces', fontSize: '14px' }}>
                                    {/* <p style={{ textAlign: "justify", whiteSpace: 'break-spaces', fontSize: '14px' }}> */}
                                    {vocabulary.cropCoefficientDescription}
                                    {/* </p> */}

                                </Typography>
                            </Popover>
                        </div>
                    }
                </div>
                : <StyledMaterialTable
                    title={vocabulary.cultureDetailLabel}
                    data={selectedCultureKc}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                    columns={[
                        { title: vocabulary.order, field: 'name', render: rowData => <>{rowData.name ?? '-'} </> },
                        { title: vocabulary.phaseNameLabel, field: 'name', render: rowData => <>{rowData.name ?? '-'} </> },
                        { title: vocabulary.phaseDurationLabel + ' ( ' + vocabulary.dayLabel + ' )', field: 'length', render: rowData => <>{rowData.length ?? '-'}</> },
                        { title: vocabulary.kc, field: 'kc', render: rowData => <>{rowData.kc ? Number(rowData.kc).toFixed(2) : '-'}</> },
                        { title: vocabulary.rootingDepth, field: 'rootDepth', render: rowData => <>{ rowData.rootDepth ? Number(rowData.rootDepth).toFixed(2) : '-' }</> },
                        { title: vocabulary.sensibilite, field: 'sensibilite', render: rowData => <>{ rowData.sensibilite ?? '-' }</> },
                        { title: vocabulary.need_irrigation, field: 'need_irrigation', render: rowData => <>{ rowData.need_irrigation ? vocabulary.yes :  vocabulary.no }</> },
                        { title: vocabulary.DegreeDayOfGrowthLabel +' ( DJ )' , field: 'degree', render: rowData => <>{rowData.degree ?? '-'}</> },
                        { title: 'kRFU' , field: 'krfu', render: rowData => <>{rowData.max_rfu ?? '-'}</> },

                    ]}


                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        rowStyle:mtStyleFn,
                    }}
                />
            }

        </>
    )
}
export default CultureTypeDetail

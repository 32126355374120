import FormControl from '@material-ui/core/FormControl';
import React from 'react';

import { ListItem, ListItemText, InputLabel, Select, MenuItem } from '@material-ui/core';
import { vocabulary } from '../../Strings';
import StyledInput from "../../../ui/forms/StyledInput";

import { useForm } from 'react-hook-form';

const EquipmentPortListItem = ({ ports, port, index, isEditing, Equipments, handlePortChange }) => {

    const { register, errors } = useForm()

    const handleChange = (event, field) => {
        handlePortChange(index, field, event.target.value)
    }



    return (
        <ListItem key={index}>

            <div style={{ display: 'flex' }}>
                <ListItemText style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '10px' }}>
                    {vocabulary.port + ' ' + port.port_name + ' :'}
                </ListItemText>



                <FormControl style={{ margin: '8px' }}>
                    <InputLabel>{vocabulary.stationEquipments}</InputLabel>


                    {Equipments.length > 0 &&

                        <Select
                            onChange={(event) => handleChange(event, "equipment")}
                            defaultValue={port && port.equipment} // in edit case 
                            //value={port.equipment}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.Equipments}
                            name="Equipments"
                            autoWidth
                            label={vocabulary.stationEquipments}
                        >
                            <MenuItem value={null}>
                                <em>{' '}</em>
                            </MenuItem>
                            {Equipments.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {/* {JSON.parse(item.name)["name_" + currentLanguage]} */}
                                    {isEditing ? (item.name && JSON.parse(item.name).name_fr) : JSON.parse(item.name).name_fr}

                                </MenuItem>
                            ))}
                        </Select>
                    }



                </FormControl>

                <StyledInput

                    name="port_type"
                    label={vocabulary.description}
                    type="text"
                    register={register}
                    // rules={{ required: true }}
                    errors={errors.port_type}
                    defaultValue={port && port.port_type} // in edit case 

                    onChange={(event) => handleChange(event, "port_type")}

                />
            </div>


        </ListItem>


    )

}


export default EquipmentPortListItem
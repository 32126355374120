/* eslint-disable no-useless-escape*/
/* eslint-disable no-unused-vars*/

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCitiesByIdCountries, displayWarning } from '../../../actions';
import { vocabulary } from '../../Strings';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../../globalStyle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton, InputAdornment, RadioGroup, FormControlLabel, Radio, ListItem, ListItemText, List, Typography, Grid } from "@material-ui/core/";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledAutoComplete from "../../../ui/forms/StyledAutoComplete";
import StyledInput from "../../../ui/forms/StyledInput";
import PlacesAutocomplete from "react-places-autocomplete";
import { GetCountriesList, signUpUser, UpdateUser } from "../../../globalsTools/network";
import { StyledModal } from '../../../ui/layout'
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { logger } from '../../../globalsTools/utilities';


const UserAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const dispatch = useDispatch()
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [selectedCountryId, setSelectedCountryId] = useState('')
    const [type, setType] = useState('Farmer')
    const [address, setAddress] = useState('')
    const [showPassword, setShowPassword] = useState('')
    // const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [c_password, setC_password] = useState('')

    const { enqueueSnackbar } = useSnackbar();
    const [companyName, setCompanyName] = useState(null)


    const [countriesList, setCountriesList] = useState(null)

    useEffect(() => {
        if (currentLanguage) {
            GetCountriesList((currentLanguage ?? 'fr'), (data) => {
                setCountriesList(data ? data.filter(n => n).reverse() : []);
            })

        }

    }, [currentLanguage])

    const generatePassword = () => {
        let randomstring = Math.random().toString(36).slice(-8);
        setPassword(randomstring)
        setC_password(randomstring)
    }


    useEffect(() => {
        if (isEditing === false) {
            setFirstName('')
            setLastName('')
            setMobile('')
            setEmail('')
            setSelectedCountryId('')
            setPassword('')
            setType('')
            setC_password('')
            setAddress('')
        }

    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    useEffect(() => {

        if (item) {
            setFirstName(item.firstname)
            setLastName(item.lastname)
            setEmail(item.email)
            setMobile(item.mobile)
            setSelectedCountryId(item.country)
            setType(item.type)
            setAddress(item.address && JSON.parse(item.address) && JSON.parse(item.address).address)

        }


        if (!isEditing) {
            generatePassword()
        }

    }, [item, isEditing]); // N’exécute l’effet que si item a changé

    const operationCallBack = (response, success, status) => {
        if (response) {
            if (success) {

                snackbarMsg(vocabulary.emailCheckAccount, 'warning');
                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response.response.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }



    const handleUserForm = () => {

        const activesince = new Date()
        let data = {
            firstname: firstName,
            lastname: lastName,
            email: email,
            mobile: mobile,
            prm_country_id: selectedCountryId?.id,
            type: 'Farmer',
            isSeabexClient: true,
            status: 'Free',
            company_name: companyName,
            activesince: activesince,
            invitation_voucher: "__CA45__",
            preferred_language: currentLanguage
        }

        if (password) {
            data = {
                ...data,
                password: password,
                c_password: c_password,
            }
        }

        if (isEditing) {
            UpdateUser({ id: item.id, ...data }, operationCallBack)
        } else {
            signUpUser(data, operationCallBack)
        }


    }

    const getErrorMessage = (data) => {
        switch (data) {
            case "_MOBILE_ALREADY_EXISTS_":
                return vocabulary._MOBILE_ALREADY_EXISTS_

            default:
                break;
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const validatePassword = (c_password) => {
        if (password !== c_password) {
            return false
        }
        return true
    }
    const handleChangeRadio = (event) => {
        setType(event.target.value)
    }
    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewUser : vocabulary.EditUser}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>

                    {/* <StyledInfoBubble type='info'>{vocabulary.invitationVoucherWarning}</StyledInfoBubble> */}

                    <form
                        onSubmit={handleSubmit(handleUserForm)}
                        style={{ ...formStyle }}>


                        <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillTextAddUser}</Typography>
                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="firstName"
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.firstName}

                                    label={vocabulary.firstname}
                                    type="text"
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    styles={{ width: `100%` }}
                                />
                            </Grid>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="lastName"
                                    errors={errors.lastName}

                                    register={register}
                                    rules={{ required: true }}
                                    label={vocabulary.lastname}
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                    type="text"
                                    styles={{ width: `100%` }}


                                />
                            </Grid>
                        </Grid>

                        <StyledInput
                            register={register}
                            rules={{ required: true, validate: validateEmail }}
                            name="email"
                            label={vocabulary.email}
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            errors={errors.email}
                            disableAutoComplete={true}
                        />
                        {errors.email &&
                            <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                                {vocabulary.mailInvalid}
                            </span>
                        }
                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="password"
                                    id="outlined-adornment-password"
                                    register={register}
                                    rules={{ required: !isEditing, minLength: 8 }}
                                    type={showPassword ? 'text' : 'password'}
                                    label={vocabulary.password}
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                    errors={errors.password}
                                    helperText={vocabulary.securePasswordConditions}
                                    // disableAutoComplete={true}

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={() =>
                                                        setShowPassword(!showPassword)

                                                    }
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    styles={{ width: `100%` }}


                                />

                            </Grid>
                            <Grid item xs style={{ display: 'flex' }}>
                                <StyledInput
                                    name="c_password"
                                    id="outlined-adornment-c_password"
                                    register={register}
                                    rules={{ required: !isEditing, validate: validatePassword }}
                                    type={showPassword ? 'text' : 'password'}
                                    label={vocabulary.c_password}
                                    value={c_password}
                                    onChange={(event) => setC_password(event.target.value)}
                                    styles={{ width: `100%` }}

                                />
                            </Grid>
                        </Grid>
                        {errors.c_password && <span>{vocabulary.passwordDntMatch}</span>}

                        <StyledAutoComplete
                            data={countriesList ?? []}
                            optionLabel={(option) => option.name && isEditing ? JSON.parse(option.name)['name_' + currentLanguage] : option.name}
                            defaultValue={isEditing ? selectedCountryId : ''}
                            onChange={(event, newValue) => {
                                setSelectedCountryId(newValue ? newValue.id : '')
                            }}
                            valueField="id"
                            label={vocabulary.country}

                        />

                        <FormControlLabel style={{ alignItems: 'center', alignSelf: 'flex-start', marginLeft: 8, marginRight: 8 }}
                            labelPlacement="start"
                            label={vocabulary.userType}
                            control={<RadioGroup style={{ margin: `0px 10px` }} row aria-label="gender" name="userStatus" value={type} onChange={handleChangeRadio}>
                                <FormControlLabel value="Farmer" control={<Radio />} label={vocabulary.Farmer} />
                                <FormControlLabel value="Comapany" control={<Radio />} label={vocabulary.CompanyLabel} />
                            </RadioGroup>}
                        />

                        {type == "Comapany" &&
                            <StyledInput
                                name="company"
                                register={register}
                                errors={errors.company}
                                label={vocabulary.companyNameLabel}
                                type="text"
                                value={companyName}
                                onChange={(event) => setCompanyName(event.target.value)}
                            />
                        }

                        <FormControlLabel label={vocabulary.getContactedBySeabex} style={{ alignItems: 'flex-start', backgroundColor: '#fbf5e3', margin: 0, padding: 10, border: `1px #e4e4e4 solid`, borderRadius: 7 }}
                            labelPlacement='top'
                            control={<StyledInput
                                label={vocabulary.enterPhoneNumber}
                                name='phone_number'
                                value={mobile}
                                onChange={(event) => {
                                    setMobile(event.target.value)
                                }}

                            // variant="text"
                            />}
                        />


                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}

export default UserAddForm
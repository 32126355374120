/* eslint-disable  dot-location */
/* eslint-disable  no-useless-concat */

import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';

// import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import { GetEquipmentById } from "../../../globalsTools/network";


const EquipmentDataTypeList = ({ itemRow }) => {


    // const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [selectedEquipmentDetails, setSelectedEquipmentDetails] = useState(null)
    const [specificationList, setSpecificationList] = useState(null)


    useEffect(() => {

        if (itemRow) {
            GetEquipmentById(itemRow.id, setSelectedEquipmentDetails)
        }

    }, [itemRow])

    useEffect(() => {

        if (selectedEquipmentDetails) {
            setSpecificationList(selectedEquipmentDetails.sepecifications)
        }

    }, [selectedEquipmentDetails])

    return (

        <StyledCard>

            {specificationList ?
                <StyledMaterialTable
                    title={'Specification of ' + ' ' + JSON.parse(itemRow.name).name_fr}
                    data={specificationList ? JSON.parse(specificationList) : []}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                    columns={[
                        { title: vocabulary.datatype, field: 'dataTypeName' },
                        { title: 'Range Min', field: 'rangeMin' },
                        { title: 'Range Max', field: 'rangeMax' },
                        { title: 'Resolution', field: 'resolution' },
                        { title: 'Accuracy', field: 'accuracy' },
                        { title: 'Unit', field: 'unit' },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false
                    }}
                />
                :
                <StyledLoadingRow />
            }

        </StyledCard>
        // </ModalContext.Provider>
    )
}
export default EquipmentDataTypeList
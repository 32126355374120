import {
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { logger } from '../../globalsTools/utilities';
import { StyledCard } from '../../ui/layout';
import NoCycleFoundedCard from '../Components/NoCycleFoundedCard';
import CycleInformationCard from '../Cycle/CycleInformationCard';
import { vocabulary } from '../Strings';
import SiteDetails from './Components/SiteDetails';

export const AreaOnePager = ({ initialSelectedArea, initialWaterNeedProp }) => {
    const [selectedArea, setSelectedArea] = useState();
    const [waterNeedProp, setWaterNeedProp] = useState();

    useEffect(() => {
        setSelectedArea(initialSelectedArea);
        setWaterNeedProp(initialWaterNeedProp);
    }, [initialSelectedArea, initialWaterNeedProp]);

    return selectedArea ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* {timeLineData &&
                    <EventHorizantalTimeLine
                        data={timeLineData}
                    />
                } */}
            <div style={{ marginTop: '10px' }}>
                <SiteDetails style={{ marginTop: '10px' }} {...selectedArea} />
            </div>

            {(selectedArea.productioncycles &&
            selectedArea.productioncycles.length > 0) ? (

                selectedArea.productioncycles.map((productionCycle) => <CycleInformationCard production_cycle_id={productionCycle.id} />)
                
            ) : (
                <NoCycleFoundedCard
                    areaId={selectedArea.id}
                    selectedAreaProps={selectedArea}
                />
            )}
        </div>
    ) : waterNeedProp ? null : (
        <StyledCard styleProp={{ padding: 12 }}>
            <Typography>{vocabulary.doubleClickToShowAreaDetail}</Typography>
        </StyledCard>
    );
};

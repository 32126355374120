import {
    Box, Card,
    CardContent,
    CardMedia, Grid, IconButton,
    Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeid } from '../../globalsTools/utilities'
import { vocabulary } from '../Strings'
import PaymentManager from './../Payments/PaymentManager'
import { useCart } from './../Payments/useCart'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { GetSeabexPackByProvider } from '../../globalsTools/network'
import { StyledInput, StyledSubmitButton } from '../../ui/forms'
import { StyledModal } from '../../ui/layout'


export const StationOfferModal = ({ isOpen, onClose, partnerId, refreshFn }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const currencyDecimals = useSelector(
        (state) => state._globalState.currency_decimals
    );

    const currency = useSelector((state) => state._globalState.currency);


    const [offers, setOffers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [offer, setOffer] = useState(null)
    const [showPaymentForm, setShowPaymentForm] = useState(false)

    const {
        cartData, addCartItem, cartSum,
        cartSumHT, removeCartItem
    } = useCart([]);


    useEffect(() => {
        setIsLoading(true)
        GetSeabexPackByProvider(partnerId.toLowerCase(), data => {

            setOffers(data)
            setIsLoading(false)
        })


    }, [partnerId])


    useEffect(() => {
        if (offer) {
            onCartChange(offer);
        }


    }, [offer, quantity]);



    const onCartChange = (offer) => {
        removeCartItem(offer)
        addCartItem({
            id: offer.id,
            type: 'SEABEX_PACK',
            quantity,
            price: Number(JSON.parse(offer.tarifs)['price']),
            total_in_vat: (Number(JSON.parse(offer.tarifs)['price']) * 1.2) * quantity,
            total_x_vat: (Number(JSON.parse(offer.tarifs)['price'])) * quantity,
            tax_rate: offer.tax_rate ?? 20,
            provider: offer.provider,
            label: JSON.parse(offer.name)[currentLanguage],
            unique_group_id: offer.unique_group_id
        })
    };





    return (
        <>
            <StyledModal
                open={isOpen}
                closeFn={onClose}
                withoutContext={true}
                title={vocabulary.seabexOffers}
            >

                {
                    offers.map((offer, index) => {
                        if (offer.name != null) {
                            return (

                                <Card sx={{ display: 'flex' }} key={offer.name + makeid()}>

                                    <Grid container rowSpacing={1}  >
                                        <Grid item xs={4}  >
                                            <CardMedia component={'img'} width={200} height={200} src='https://uk.blog.sencrop.com/content/images/2021/09/Sencrop---logo-carr-.jpg' />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography noWrap={false} style={{ fontSize: 18, textAlign: 'start' }}>
                                                        {JSON.parse(offer.name)[currentLanguage]}
                                                    </Typography>
                                                    <Grid item style={{ flexGrow: 1 }}>
                                                        <Typography variant='subtitle1' color='textSecondary' noWrap={false}>
                                                            {JSON.parse(offer.description)[currentLanguage]}
                                                        </Typography>
                                                    </Grid>

                                                </CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

                                                    <Grid item  >
                                                        <StyledInput
                                                            value={quantity}
                                                            onChange={(e) => {
                                                                setQuantity(e.target.value)
                                                                setOffer(offer)
                                                            }}
                                                            InputProps={{ inputProps: { min: 0 } }}
                                                            type="number"
                                                            name="quantity"
                                                            label={vocabulary.stations}
                                                        />
                                                    </Grid>

                                                    <IconButton aria-label="previous">
                                                        <StyledSubmitButton
                                                            submit={false}
                                                            label={vocabulary.PayerLabel + ' ' + cartSum(currencyDecimals) + " " + currency + " (" + cartSumHT(currencyDecimals) + " " + currency + " HT)"}
                                                            onClick={() => setShowPaymentForm(true)}
                                                            disabled={isLoading || quantity < 1}
                                                        />
                                                    </IconButton>

                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Card>
                            )
                        }
                    })
                }
            </StyledModal>

            {
                showPaymentForm &&
                <StyledModal
                    width={560}
                    open={showPaymentForm}
                    title={vocabulary.paymentFormLabel}
                    closeFn={() => {
                        setShowPaymentForm(false)
                        setQuantity(0)
                    }}
                    withoutContext={true}
                >
                    <PaymentManager cart={cartData} onSuccessFn={() => {
                        refreshFn()
                        setShowPaymentForm(false);
                        onClose()
                    }} onFailureFn={() => { }} />

                </StyledModal>
            }

        </>
    )
}

export default StationOfferModal
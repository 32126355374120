/* eslint-disable eqeqeq*/
/* eslint-disable no-sequences*/
/* eslint-disable no-unreachable*/

import React, { useRef, useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../../ui/layout'
import StyledSelect from "../../../ui/forms/StyledSelect";

import { createEquipment, UpdateEquipment, GetDataTypeList } from '../../../globalsTools/network';
import { Button, List, ListItemText, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { HighlightOff, Done } from '@material-ui/icons';
import StyledAutoComplete from "../../../ui/forms/StyledAutoComplete";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import EditIcon from '@material-ui/icons/Edit';

const EquipmentTypeAddForm = ({ item, onClose, chosenType, open, itemId, setIsLoading, refetchFunc, isEditing }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [step, setStep] = useState(0)
    const [selectedDataTypeId, setSelectedDataTypeId] = useState([])
    const [dataTypeList, setDataTypeList] = useState(null)
    const [equipmentName, setEquipmentName] = useState(null)
    const [description, setDescription] = useState(null)
    const [reference, setReference] = useState(null)
    const [rangeMax, setRangeMax] = useState(null)
    const [rangeMin, setRangeMin] = useState(null)
    const [resolution, setResolution] = useState(null)
    const [accuracy, setAccuracy] = useState(null)
    const [unit, setUnit] = useState(null)
    const [unitList, setUnitList] = useState([])
    const [optionalSelectedDataTypeId, setOptionalSelectedDataTypeId] = useState(null)
    const [optionalSelectedDataTypeName, setOptionalSelectedDataTypeName] = useState(null)
    const [index, setIndex] = useState(null)


    useEffect(() => {
        GetDataTypeList(setDataTypeList)
    }, [])

    const validForm = () => {
        switch (step) {
            case 0:
                if (!equipmentName || !reference) {
                    return true
                }
                else return false
                break;

            case 1:
                if (selectedDataTypeId.length == 0) {
                    return true
                }
                else return false
                break;

            case 2:
                if (!optionalSelectedDataTypeId || !rangeMax) {
                    return true
                }
                else return false
                break;

            default:
                return step
        }



    }
    useEffect(() => {
        if (isEditing == true) {
            if (item) {

                setUnitList(item.sepecifications ? JSON.parse(item.sepecifications) : [])
                setSelectedDataTypeId(item.prmdatatypes)
                setStep(0)
                setEquipmentName(JSON.parse(item.name).name_fr)
                setDescription(JSON.parse(item.description).description)
                setReference(item.reference)

            }
        }


    }, [item, isEditing]); // N’exécute l’effet que si item a changé

    useEffect(() => {
        if (isEditing === false) {
            setStep(0)
            setReference(null)
            setDescription(null)
            setEquipmentName(null)
            setRangeMax(null)
            setRangeMin(null)
            setResolution(null)
            setAccuracy(null)
            setUnitList([])
            setOptionalSelectedDataTypeId(null)
            setUnit(null)
            setIndex(null)
        }
    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg,
            {
                variant: type,
                preventDuplicate: true,

            });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? JSON.parse(item.name).name_fr + "  " + vocabulary.updateSucc : vocabulary.datatype + " " + vocabulary.equipmentLabel + ' ' + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            } else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }

    const deleteUnit = (index) => {
        unitList.splice(index, 1)
        setUnitList([...unitList]);
    }

    const emptyArray = () => {

        setRangeMax(null)
        setRangeMin(null)
        setAccuracy(null)
        setResolution(null)
        setUnit(null)
        setOptionalSelectedDataTypeId(null)
        setIndex(null)

    }
    useEffect(() => {
        if (step == 2 && index == null) {
            emptyArray()
        }
    }, [step, index])

    const addList = () => {
        // let obj = {};
        if (optionalSelectedDataTypeId) {
            let obj = {
                datatypeId: optionalSelectedDataTypeId,
                dataTypeName: optionalSelectedDataTypeName,
                rangeMin: rangeMin,
                rangeMax: rangeMax,
                resolution: resolution,
                accuracy: accuracy,
                unit: unit
            }
            setUnitList(unitList => [...unitList, obj])
        }


    }
    const editList = () => {
        let obj = {
            datatypeId: optionalSelectedDataTypeId,
            dataTypeName: optionalSelectedDataTypeName,
            rangeMin: rangeMin,
            rangeMax: rangeMax,
            resolution: resolution,
            accuracy: accuracy,
            unit: unit
        }

        unitList.splice(index, 1, obj)
        setUnitList([...unitList])
    }

    const newEquipmentType = (data) => {
        setIsLoading(true)
        if (isEditing === false) {
            createEquipment(
                {
                    name: JSON.stringify({ 'name_fr': equipmentName }),
                    reference: reference,
                    type: chosenType,
                    description: JSON.stringify({ 'description': description }),
                    prm_equipment_family_id: null,
                    prmdatatypes: selectedDataTypeId,
                    specifications: JSON.stringify(unitList)
                }, r => operationCallBack(r)
            )
            onClose()
        } else {
            UpdateEquipment(
                {
                    id: item.id,
                    name: JSON.stringify({ 'name_fr': equipmentName }),
                    reference: reference,
                    type: chosenType,
                    description: JSON.stringify({ 'description': description }),
                    prm_equipment_family_id: null,
                    prmdatatypes: selectedDataTypeId,
                    specifications: JSON.stringify(unitList)


                }, r => operationCallBack(r)
            )
            onClose()

        }
    }
    const changeStep = (step) => {
        setStep(step)
    }

    const modalContext = useContext(ModalContext)

    const getEquipmentDataTypeDetails = (item, index) => {
        setRangeMax(item.rangeMax)
        setRangeMin(item.rangeMin)
        setResolution(item.resolution)
        setAccuracy(item.accuracy)
        setUnit(item.unit)
        setOptionalSelectedDataTypeId(item.datatypeId)
        setOptionalSelectedDataTypeName(item.dataTypeName)
        setIndex(index)

    };

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={600}
                title={isEditing === false ? vocabulary.addEquipment : vocabulary.editEquipment}
            >
                <form
                    onSubmit={handleSubmit(newEquipmentType)}
                    ref={form}

                >

                    {step === 0 &&
                        <>
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.name}
                                name="name"
                                label={vocabulary.name_label}
                                type="text"
                                onChange={(event) => {
                                    setEquipmentName(event.target.value)
                                }}
                                value={equipmentName}
                                fullWidth
                            />
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.reference}
                                name="reference"
                                label={vocabulary.reference}
                                type="text"
                                fullWidth
                                onChange={(event) => {
                                    setReference(event.target.value)
                                }}
                                value={reference}

                            />

                            <StyledInput
                                register={register}
                                errors={errors.description}
                                name="description"
                                label={vocabulary.description}
                                type="text"
                                fullWidth
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                                value={description}
                                maxRows={4}
                                multiline={true}
                            />
                        </>
                    }
                    {
                        step == 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom>
                                {vocabulary.attachDataType}
                            </Typography>

                            {dataTypeList &&
                                <StyledAutoComplete
                                    data={dataTypeList.data}
                                    label={vocabulary.datatype}
                                    // defaultValue={item && item.prm_data_type}
                                    optionLabel={(option) => JSON.parse(option.name)['name_' + currentLanguage]}
                                    styles={{ width: '87%' }}
                                    multiple={true}
                                    onChange={(event, newValue) => {
                                        setSelectedDataTypeId(newValue !== null ? newValue : null)
                                    }}
                                    defaultValue={item && item.prmdatatypes}

                                />}

                        </div>

                    }

                    {step === 0 &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                style={{ margin: '5px' }}
                                color="secondary"
                                onClick={() => modalContext.changeModalStatus(false)}
                                variant="contained"
                            >
                                <HighlightOff /> &nbsp; {vocabulary.cancel}

                            </Button>
                            <Button
                                style={{ margin: '5px' }}
                                color="primary"
                                onClick={() => changeStep(1)}
                                variant="contained"
                                disabled={validForm()}

                            >
                                <SkipNextIcon /> &nbsp; {vocabulary.next}

                            </Button>
                        </div>

                    }
                    {
                        step == 2 &&
                        <>
                            <div className="form_footer" >
                                <div className="unit_list">
                                    {vocabulary.unitListsLabel}
                                </div>
                                <div className="unit_container">
                                    <div className="list">
                                        {unitList && unitList.length > 0 &&
                                            <div>
                                                {unitList.map((item, index) => (

                                                    <List component="nav">
                                                        <ListItem button onClick={() => getEquipmentDataTypeDetails(item, index)}>
                                                            <ListItemText primary={item.dataTypeName} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                >
                                                                    <DeleteIcon onClick={() => deleteUnit(index)} />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </List>


                                                )
                                                )}
                                            </div>
                                        }
                                    </div>
                                    <div className="unit_select"  >

                                        <StyledSelect
                                            value={optionalSelectedDataTypeId}
                                            register={register}
                                            errors={errors.optionalSelectedDataTypeId}
                                            onChange={(event) => {
                                                setOptionalSelectedDataTypeId(event.target.value)
                                                setOptionalSelectedDataTypeName(selectedDataTypeId.find(el => el.id == event.target.value).identifier)

                                            }}
                                            name='optionalSelectedDataTypeId'
                                            render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
                                            label={vocabulary.datatype}
                                            data={selectedDataTypeId}
                                            valueField='id'
                                            labelField='name'
                                            styles={{ width: '30%' }}

                                        />
                                        <StyledInput
                                            register={register}
                                            errors={errors.rangeMax}
                                            name="rangeMax"
                                            value={rangeMax === null ? '' : rangeMax}
                                            label={'Range Max'}
                                            type="text"
                                            fullWidth
                                            onChange={(event) => {
                                                setRangeMax(event.target.value)
                                            }}
                                            maxRows={6}
                                            multiline={true}
                                            styles={{ width: '30%' }}
                                        />
                                        <StyledInput
                                            register={register}
                                            errors={errors.rangeMin}
                                            name="rangeMin"
                                            value={rangeMin === null ? "" : rangeMin}
                                            label={'Range Min'}
                                            type="text"
                                            onChange={(event) => {
                                                setRangeMin(event.target.value)
                                            }}
                                            fullWidth
                                            maxRows={6}
                                            multiline={true}
                                            styles={{ width: '30%' }}
                                        />
                                        <StyledInput
                                            register={register}
                                            errors={errors.resolution}
                                            name="resolution"
                                            value={resolution === null ? "" : resolution}
                                            label={'Resolution'}
                                            type="text"
                                            fullWidth
                                            onChange={(event) => {
                                                setResolution(event.target.value)
                                            }}
                                            maxRows={6}
                                            multiline={true}
                                            styles={{ width: '30%' }}
                                        />
                                        <StyledInput
                                            register={register}
                                            errors={errors.Accuracy}
                                            name="Accuracy"
                                            value={accuracy === null ? "" : accuracy}
                                            label={'Accuracy'}
                                            type="text"
                                            fullWidth
                                            onChange={(event) => {
                                                setAccuracy(event.target.value)
                                            }}
                                            maxRows={6}
                                            multiline={true}
                                            styles={{ width: '30%' }}
                                        />
                                        <StyledInput
                                            register={register}
                                            errors={errors.Unit}
                                            name="Unit"
                                            value={unit === null ? "" : unit}
                                            label={'Unit'}
                                            type="text"
                                            fullWidth
                                            onChange={(event) => {
                                                setUnit(event.target.value)
                                            }}
                                            maxRows={6}
                                            multiline={true}
                                            styles={{ width: '30%' }}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'right', marginLeft: '-45px' }}>
                                            <Button
                                                style={{ margin: '8px', width: '50%' }}
                                                color="secondary"
                                                size="small"
                                                variant="contained"
                                                onClick={() => emptyArray()}
                                            >
                                                <RotateLeftIcon /> &nbsp;{vocabulary.reset}

                                            </Button>
                                            <Button
                                                style={{ margin: '8px', width: '50%' }}
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                onClick={() => index == null ? addList() : editList()}
                                            >
                                                {index == null ? <AddIcon /> : <EditIcon />}&nbsp; {index == null ? vocabulary.add : vocabulary.editLabel}

                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button
                                        style={{ margin: '5px' }}
                                        color="secondary"
                                        onClick={() => modalContext.changeModalStatus(false)}
                                        variant="contained"
                                    >
                                        <HighlightOff /> &nbsp; {vocabulary.cancel}
                                    </Button>
                                    <Button
                                        style={{ margin: '5px' }}
                                        color="primary"
                                        onClick={() => changeStep(1)}
                                        variant="contained"
                                    >
                                        <SkipPreviousIcon /> &nbsp; {vocabulary.previous}
                                    </Button>
                                    <Button
                                        style={{ margin: '5px' }}
                                        color="primary"
                                        // onClick={() => changeStep(2)}
                                        variant="contained"
                                        type='submit'
                                        disabled={isEditing == false ? validForm() : false}


                                    >
                                        <Done /> &nbsp; {vocabulary.save}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                    {
                        step == 1 &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                style={{ margin: '5px' }}
                                color="secondary"
                                onClick={() => modalContext.changeModalStatus(false)}
                                variant="contained"
                            >
                                <HighlightOff /> &nbsp; {vocabulary.cancel}

                            </Button>
                            <Button
                                style={{ margin: '5px' }}
                                color="primary"
                                onClick={() => changeStep(0)}
                                variant="contained"
                            >
                                <SkipPreviousIcon /> &nbsp; {vocabulary.previous}

                            </Button>
                            <Button
                                style={{ margin: '5px' }}
                                color="primary"
                                onClick={() => changeStep(2)}
                                variant="contained"
                                disabled={validForm()}
                            >
                                <SkipNextIcon /> &nbsp; {vocabulary.next}

                            </Button>


                        </div>
                    }

                </form>
            </StyledModal>
        </>
    )

}
export default EquipmentTypeAddForm

/* eslint-disable no-unused-vars*/

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';

// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../../globalStyle';
// import compose from 'recompose/compose';
// import MapArea from '../../Sites/MapArea'
import { loadCitiesByIdCountries, loadDelegationByIdGovernates, 
    // loadSectorByIdDelegations 
} from '../../../actions';
import { Card, CardContent } from "@material-ui/core/";
import DataTable from './DataTable'
import { AppBar, Tab, Tabs } from '@material-ui/core';
import CountriesList from './CountriesList'
import GovernatesList from './GeoDataCitiesList'
const CountiresList = (props) => {

    const dispatch = useDispatch()

    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const governates = useSelector(state => state.cities.data)
    const delegations = useSelector(state => state.delegations.data)
    const sectors = useSelector(state => state.sector.data)
    // const countries = useSelector(state => state.countries.data)


    const [area, setArea] = useState({})
    // const [delegationList, setDelegationList] = useState([])
    const [activeTab, setActiveTab] = useState(0)




    //     useEffect(() => {
    //  setArea(props.countries)

    //         }, [props.countries]); // N’exécute l’effet que si count a changé

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };
  


    // const mapChangeCenter = (polygons, zooooom) => {
    //     // console.log('polygoooooooooooon', polygons)

    //     return <MapArea

    //         cities={polygons}
    //         geoData={true}
    //         center={zooooom ? JSON.parse(area.center) : [9.645996, 36.668419]}
    //         zoom={zooooom}
    //     />
        
    // }
    const TabMenuItem = (activeTab) => {
        switch (activeTab) {
            case 0:
                return <CountriesList
                    countries={props.countries}
                    setparams={setparams}
                    areaParams={setAreaParams}
                    activeTab={activeTab}


                />

            case 1:
                return <GovernatesList
                    cities={governates}
                    setparams={setparams}
                    areaParams={setAreaParams}
                    activeTab={activeTab}

                />

            case 2:
                return <DataTable
                    cities={delegations}
                    setparams={setparams}
                    areaParams={setAreaParams}
                    activeTab={activeTab}

                />
            case 3:
                return <DataTable
                    cities={sectors}
                    setparams={setparams}
                    areaParams={setAreaParams}
                    activeTab={activeTab}


                />


            default:
                return true
        }
    }
    // setparams(type, data)

    const setparams = (id_country, id_governate, id_delegation) => {
        // console.log('setparams', id_country)
        // console.log('setparams', id_governate)
        // console.log('setparams', id_delegation)

        return (
            (id_country !== undefined) ? (dispatch(loadCitiesByIdCountries(id_country)), setActiveTab(1)) : null,
            (id_governate !== id_country) && (id_delegation === id_country) ? (dispatch(loadDelegationByIdGovernates(id_governate)), setActiveTab(2)) : null
             //(id_delegation !== undefined) ? (dispatch(loadSectorByIdDelegations(id_delegation)), setActiveTab(3)) : null

        )

        // return true
    }
    const setAreaParams = (area) => {
        setArea(area)
    }
    // console.log('activeTab', activeTab)
    // console.log("area_map", area)

    return (
        <>

            <Card>


                <CardContent>
                    <AppBar position="static" style={{ width: '60%', backgroundColor: '#0A6A9C' }}>
                        <Tabs value={activeTab}
                            onChange={handleChange}
                            aria-label="simple tabs example">
                            <Tab label={vocabulary.country} hidden={activeTab < 0} />
                            <Tab label={vocabulary.governates} hidden={activeTab < 1} />
                            <Tab label={vocabulary.delegations} hidden={activeTab < 2} />
                            <Tab label={vocabulary.sector} hidden={activeTab < 3} />

                        </Tabs>
                    </AppBar>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className="geoDataTable" style={{ width: '60%' }} >
                            {TabMenuItem(activeTab)}
                        </div>

                        <div className='GeoDataMap' style={{ width: '40%', marginTop: '18px' }}>
                            {/* <MapArea
                                cities={mapChangeData()}
                                geoData={true}
                                center={governates.length > 0 ? JSON.parse(area.center) : [9.645996, 36.668419]}
                                zoom={governates.length > 0 ? true : false}

                            /> */}


                            {/* {(countries.length > 0 && (governates.length !== 0 || delegations.length !== 0)) ?
                                (governates.length !== 0 && delegations.length === 0) ? sectors.length !== 0 ? mapChangeCenter(sectors, true) :
                                    mapChangeCenter(governates, true) : mapChangeCenter(delegations, true) : mapChangeCenter(countries, false)} */}
                        </div>
                    </div>


                </CardContent>

            </Card>

        </>
    )
}
export default CountiresList;
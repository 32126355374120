import React, { useState } from 'react';




import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import { vocabulary } from '../Strings';
import { StyledMaterialTable, StyledStaticMap } from '../../ui/display';
import AlertDialog from "../Components/alertDialog";
import { UpdatePartnerDevices } from '../../globalsTools/network';
import { useSnackbar } from 'notistack';
import SeabexMap from '../Sites/Map/SeabexMap';

const FieldPartnerListDetails = ({ itemRow, refreshFn }) => {

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [polygone, setPolygone] = useState(null)

    const { enqueueSnackbar } = useSnackbar();

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };

    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false)
    };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    const operationCallBack = (r) => {
        if (r && [200, 204].includes(r.status)) {
            refreshFn()
            snackbar(vocabulary.operationSucc, 'success')
        }
        else {
            snackbar(vocabulary.errorOccurred, 'error')

        }
    }

    const handleItemDelete = (item) => {


        let related_areas = itemRow.areas.filter(el => el.id !== item.id);
        const areas = related_areas.map((area) => (typeof area === 'object' ? area.id : area));
        UpdatePartnerDevices({
            id: itemRow.id,
            device: {
                device_id: itemRow.device_id,
                device_name: itemRow.device_name,
            },
            areas
        }, response => {

            if (response.status == 200) {
                snackbar(vocabulary.operationSucc, 'success');
                refreshFn()
            } else {
                snackbar(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }


        })
    }


    return (

        itemRow ?
            <>
                 
                    <StyledStaticMap image={itemRow.staticmap} base64={true} height={'100%'} />
               

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.title}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleItemDelete(item)

                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />
                }
            </>

            : <StyledLoadingRow />




    )

}
export default FieldPartnerListDetails
import { Link, Typography, IconButton, InputAdornment, Button } from "@material-ui/core/";
import { Visibility, VisibilityOff } from "@material-ui/icons/";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, resetUserPassword, } from "../actions";

import { vocabulary } from "./Strings";
import { useHistory } from "react-router-dom";
import SeabexLogo from "../ui/SeabexLogo";
import { useForm } from 'react-hook-form';
import { StyledBackground, StyledColoredLayout } from "../ui/layout/";
import StyledCard from "../ui/layout/StyledCard";
import StyledFlyingBlock from "../ui/layout/StyledFlyingBlock";
import StyledInput from "../ui/forms/StyledInput";
import StyledAppLoading from "../ui/tools/StyledAppLoading";

import { useSnackbar } from 'notistack';
import { StyledSubmitButton } from "../ui/forms";

// import { mqttSubs } from "./MqttController/MqttController";
import { GetUserStatus } from "../globalsTools/network";
import { setGlobalLanguage } from "../actions/_globalStatusActions";
import { logger } from "../globalsTools/utilities";
import { USER_ID } from "../actions/actionTypes";


function redirectToPage(userType) {

 
  switch (userType) {
    case 'Organization':
      return '/app/organization/sites';
    case 'Organization-User':
      return '/app/organization/user/partners';
    default:
      return '/app/sites';
  }

}


const SignIn = () => {

  const dispatch = useDispatch();
  let history = useHistory();

  const { register, handleSubmit, errors } = useForm()
  const { register: registerForgotPassword, handleSubmit: handleSubmitForgotPassword,
    //errors: errorsForgotPassword 
  } = useForm()

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [resetEmail, setResetEmail] = useState("");

  const [password, setPassword] = useState("");
  const [actualForm, setActualForm] = useState("signin");

  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,

    });
  };

  const login = data => {
    dispatch(
      loginUser(
        {
          email: data ? data?.username : email,
          password: data ? data?.password : password
        },
        function (r) {
          logger(r, r?.response)
          if (r) {
            if (r?.status === 200) {

              dispatch({ type: USER_ID, payload: r.data.userid })


              snackbar(vocabulary.accessGranted, 'success');
              if (r?.data && r?.data.user_type !== 'Super') {
                localStorage.setItem('login_Token', r?.data.token)
                localStorage.setItem('userrights', r?.data.user_type);
                localStorage.setItem('userid', r?.data.userid);
                localStorage.setItem('username', r?.data.username);
                localStorage.setItem('company_name', r?.data.company_name ?? '');
                localStorage.setItem('preferred_language', r?.data.preferred_language ?? 'fr');
                dispatch(setGlobalLanguage(r?.data.preferred_language ?? 'fr'));

                GetUserStatus();

                history.push(redirectToPage(r?.data.user_type))

              } else {
                history.push("/app/dashboardManagement");
              }

            } else if (r?.status === 403) {
              snackbar(vocabulary.checkAccessCode, 'error');
            }
            else if (r.status === 401) {
              snackbar(vocabulary.loginInvalidLabel, 'error');
            }
            else {
              snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
          }
        }
      )
    );
  };

  const resetPassword = data => {
    dispatch(resetUserPassword({ email: resetEmail },
      function (r) {
        if (r) {
          if (r.status === 200) {
            snackbar(vocabulary.emailCheckPassword, 'warning')
            setActualForm("signin")
          }
          else if (r.status === 403) {
            // setState({ snackbar: { show: true, message: "Veuilliez verifier vos codes d'accès" } }); 
            snackbar(vocabulary.checkAccessCode, 'error')
          }
          else {
            // setState({ snackbar: { show: true, message: "Une erreur est survenue" + r.status } });
            snackbar(vocabulary.errorOccurred + " " + r.status, 'error')

          }
        }
      }
    )
    );
  }

  const onEnterPress = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (actualForm === "signin") {
        handleSubmit(login());
      } else {
        handleSubmitForgotPassword(login());
      }
    }
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
  }


  return (
    <>

      <StyledAppLoading />

      <StyledBackground>
        <StyledCard height={400} direction="row" elevation={8}>
          <StyledColoredLayout
            width="50%"
            justifyContent="center"
            alignItems="center">
            <Typography>{vocabulary.dontHaveAnAccount},<br />{vocabulary.youCanCreateOne}</Typography>
            <Button
              variant="outlined" style={{ color: 'white', borderColor: 'white', marginTop: 20 }}
              onClick={() => {
                history.push("/signup");
              }}
            >
              {vocabulary.signup}
            </Button>
            {/* <StyledFlyingBlock bottom={10}>
              <Link component="button" style={{ color: 'white' }}>{vocabulary.privacyPolicy}</Link>&nbsp;-&nbsp;
                  <Link component="button" style={{ color: 'white' }}>{vocabulary.termsConditions}</Link>
            </StyledFlyingBlock> */}
          </StyledColoredLayout>



          <div style={{
            display: "flex",
            flexDirection: "column",
            position: 'relative',
            padding: 40
          }}>
            <SeabexLogo width={200} styles={{ marginBottom: 32 }} />

            {actualForm === "signin"

              ? <form
                onSubmit={handleSubmit(login)}
                style={{ ...formStyle }}>

                <StyledInput
                  register={register}
                  rules={{ required: true }}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  name="username"
                  label={vocabulary.email}
                  onKeyDown={onEnterPress}
                  autoComplete="username"
                  errors={errors.email}
                />

                <StyledInput
                  register={register}
                  rules={{ required: true }}
                  name="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  type={showPassword ? "text" : "password"}
                  label={vocabulary.password}
                  autoComplete="current-password"
                  onKeyDown={onEnterPress}
                  errors={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <StyledSubmitButton label={vocabulary.signin} />

                <StyledFlyingBlock bottom={10}>
                  <Link component="button"
                    color="primary"
                    onClick={() => { setActualForm("resetpassword") }}>
                    {vocabulary.forgetPassword}
                  </Link>
                </StyledFlyingBlock>
              </form>

              : <form
                onSubmit={handleSubmitForgotPassword(resetPassword)}
                style={{ ...formStyle, display: (actualForm === "resetpassword" ? "flex" : "none") }}>


                <StyledInput
                  register={registerForgotPassword}
                  rules={{ required: true }}
                  name="resetEmail"
                  label={vocabulary.email}
                  value={resetEmail}
                  onChange={(event) => {
                    setResetEmail(event.target.value)
                  }}
                  onKeyDown={onEnterPress}
                  autoComplete="username"
                  errors={errors.email}
                />
                <StyledSubmitButton label={vocabulary.resetPassword} />
                <Link component="button"
                  color="primary"
                  onClick={() => { setActualForm("signin") }}>
                  {vocabulary.signin}
                </Link>
              </form>
            }
          </div>
        </StyledCard>
        {/* 
        <div>
            <Typography>Présenté par</Typography>
            <img src="" />>
        </div> */}
      </StyledBackground>
    </>
  );
};

export default SignIn;

/* eslint-disable  no-useless-concat */
/* eslint-disable eqeqeq*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, CardActions, CardContent, Grid } from '@material-ui/core';
import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { setCurrentPage } from '../../../actions';
import { GetAreasStats, GetUsersStats } from '../../../globalsTools/network';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { vocabulary } from '../../Strings';
import DashboardMap from './DashboardMap';

const DashboardReqList = () => {

  const [usersStats, setUsersStats] = useState(null);
  const [areasStats, setAreasStats] = useState(null);

  const [tab, setTab] = useState(0);

  const [requestDate, setRequestDate] = useState(null);

  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state._globalState.preferred_language);

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.dashboardLabel));
    document.title = vocabulary.dashboardLabel;
    GetUsersStats(setUsersStats);
    GetAreasStats(setAreasStats);
  }, [dispatch]); // N’exécute l’effet que si count a changé

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.dashboardLabel));
    document.title = vocabulary.dashboardLabel;
  }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  return (
    <>
      {/* <StyledCard displayCard={'block'}> */}
      {usersStats ? (
        <Grid container spacing={2}>
          <Grid item container direction='column'  >
            <Card>
              <CardContent style={{ width: `100%`}}>
                <DashboardMap />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container direction='column'  >
            <Card>
              <CardContent style={{ width: `100%`, height: 400 }}>
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={500}
                    data={usersStats}
                    margin={{
                      top: 15,
                      right: 10,
                      left: 10,
                      bottom: 25,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey='date'>
                      <Label position='bottom' style={{ textAnchor: 'middle' }}>
                        Number of Newly Subscribed Users
                      </Label>
                    </XAxis>
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey='new_subscribers_number'
                      fill='#061257'
                      label='All Newly Subscribed Users'
                    />
                    <Bar
                      dataKey='active_new_subscribers_number'
                      fill='#1bbf02'
                      label='Active Newly Subscribed Users'
                    />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
              <CardActions>
                <Button onClick={() => GetUsersStats(setUsersStats)}>
                  Click to reload
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item container direction='column' xs='6'>
            <Card>
              <CardContent style={{ width: `100%`, height: 400 }}>
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={400}
                    data={areasStats}
                    margin={{
                      top: 15,
                      right: 10,
                      left: 10,
                      bottom: 25,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey='date'>
                      <Label position='bottom' style={{ textAnchor: 'middle' }}>
                        Newly created Areas
                      </Label>
                    </XAxis>
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey='new_areas_total_size'
                      fill='#061257'
                      label='Total Size of New Areas Created'
                    />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
              <CardActions>
                <Button onClick={() => GetAreasStats(setAreasStats)}>
                  Click to reload
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <StyledCircularProgress size={50} label={vocabulary.loading} />
      )}
      {/* </StyledCard> */}
    </>
  );
};
export default DashboardReqList;

import React, { useState } from 'react';

 import { Dialog,  DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';
// import { Done, HighlightOff } from '@material-ui/icons';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { EmptySpecificArea } from "../../../globalsTools/network";
import RestoreIcon from '@material-ui/icons/Restore';
import AlertRestoreUserConfirmationDialog from "../../Components/AlertRestoreUserConfirmationDialog";
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { vocabulary } from '../../Strings';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';

const UserRestoreDialog = ({ onClose, cancel, operation, confirmeRestore, open, restoreItem, areaListByUserId, refetchFunc }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [openRestoreConfirmation, setOpenRestoreConfirmation] = useState(false);
    const [item, setItem] = useState(null);

    const innerClose = () => {
        onClose();
    };

    // const innerCancel = () => {
    //     cancel()
    //     innerClose()
    // }

    // const innerConfirm = () => {
    //     confirmeRestore()
    //     innerClose()
    // }
    const convertSize = (size) => {
        return Number(size / 10000).toFixed(2) + " " + vocabulary.hectar
    }

    const handleOpenRestoreConfirmation = (row) => {

        setOpenRestoreConfirmation(true)
        setItem(row)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(item.name + " " + vocabulary.restoreLabel, 'success')
                refetchFunc()


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleRestoreItem = () => {
        EmptySpecificArea(item.id, r => operationCallBack(r)
        )
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={innerClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth={true}
            >
                {/* <DialogTitle id="alert-dialog-title">{vocabulary.warningLabel}</DialogTitle> */}
                <DialogTitle  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                        <Typography variant="h6">{vocabulary.warningLabel}</Typography>
                        {onClose ? (
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </div>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                            <Typography className="restoreDescription" variant="subtitle1" gutterBottom>
                                {vocabulary.confirmationLabel} {operation}  ?
                            </Typography>

                            <Typography className="restoreDescription" variant="subtitle2" gutterBottom>
                                {vocabulary.restoreDescription}  <b>{vocabulary.restoredItem}</b>
                            </Typography>

                            {areaListByUserId && areaListByUserId.length > 0 ?
                                <StyledMaterialTable
                                    title={vocabulary.area}
                                    data={areaListByUserId}
                                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                                    columns={[

                                        { title: vocabulary.name_label, field: 'name', render: rowData => <>{rowData.name}</> },
                                        { title: vocabulary.type, field: 'type', render: rowData => <>{rowData.type}</> },
                                        { title: vocabulary.size, field: 'size', render: rowData => <>{convertSize(rowData.size)}</> },


                                    ]}
                                    actions={[

                                        row => ({
                                            icon: () => <RestoreIcon />,
                                            tooltip: vocabulary.restoreLabel,
                                            onClick: (event, row) => handleOpenRestoreConfirmation(row),
                                            // disabled: row.active == false

                                        }),


                                    ]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        search: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 40, 60, 80]

                                    }}
                                />
                                : <StyledCircularProgress timeout={true} noDataMsg={vocabulary.noAreaToDisplayLabel} size={50} label={vocabulary.loading} />
                            }

                        </div>

                    </DialogContentText>
                </DialogContent>


            </Dialog>
            {openRestoreConfirmation === true &&
                <AlertRestoreUserConfirmationDialog
                    operation={vocabulary.restoreLabel}
                    open={openRestoreConfirmation}
                    restoreItem={item && item.name}
                    onClose={() => {
                        setOpenRestoreConfirmation(false);
                    }}
                    confirmeRestore={() => {
                        if (item) {
                            handleRestoreItem();
                        }

                        setOpenRestoreConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenRestoreConfirmation(false);
                    }}
                />}
        </div>
    );
}


export default UserRestoreDialog;
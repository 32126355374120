import React, { useState } from 'react';

import { StyledMaterialTable } from '../../ui/display';
import ConditionForm from './ConditionForm'
import { vocabulary } from '../Strings';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';


export default function useConditionTableHooks({ addCondition, isEditing, conditionArray, addConditionToEquation }) {



    const [openCondition, setOpenCondition] = useState(false);

    const isJson = (data) => {
        try {
            JSON.parse(data);
        } catch (e) {
            return false;
        }
        return true;
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenCondition(!openCondition)
        } else {
            setOpenCondition(newStatus)
        }
    }

    const contextValue = { open: openCondition, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={contextValue}>
         
            <StyledMaterialTable
                title={vocabulary.conditions}
                data={isJson(conditionArray) ? JSON.parse(conditionArray) : conditionArray}
                columns={[
                    { title: vocabulary.identifier, field: 'identifier' },
                    { title: vocabulary.stations, field: 'DeviceID' },
                    { title: vocabulary.sensor, field: 'Sensor' },
                    { title: vocabulary.operator, field: 'Operator' },

                ]}
                actions={[


                    row => ({
                        icon: 'Check',
                        tooltip: 'Add condition to equation',
                        isFreeAction: true,
                        onClick: (event, row) => addConditionToEquation(row.identifier)
                    }),
                    {
                        icon: 'add',
                        tooltip: vocabulary.add,
                        isFreeAction: true,
                        onClick: (event, row) => setOpenCondition(true)
                    }

                ]}
                options={{
                    actionsColumnIndex: -1,
                    search: true

                }}
            />

            <ConditionForm
                open={openCondition}
                isEditing={isEditing}
                onClose={() => {
                    setOpenCondition(false)
                }}
                addCondition={addCondition}
                width={600}
            />
                </ModalContext.Provider>

    );


}
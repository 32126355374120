import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {CircularProgress } from '@material-ui/core/';
// import { Add, Done } from '@material-ui/icons/';
import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';
import {setCurrentPage} from '../../actions';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
import { stylesThemed } from '../globalStyle';



const Planification =(props)=> {

  const dispatch = useDispatch()
  const appLoading = useSelector(state => state.app.appLoading)
  const currentLanguage = useSelector(state => state._globalState.preferred_language)





  useEffect(() => {
   dispatch(setCurrentPage(vocabulary.planification))
   document.title=vocabulary.planification

  }, [dispatch,currentLanguage]); // N’exécute l’effet que si count a changé


  const classes = stylesThemed();


    return (
      <div className={classes.container}
        style={{
          padding: 10,
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >

        <div style={{
          visibility: appLoading ? 'visible' : 'hidden',
          position: 'absolute',
          left: '50%',
          top: '45%',

        }}>
          <CircularProgress size={50} />
        </div>

        <div style={{ opacity: appLoading ? 0.4 : 1 }} >

          <h1>coming soon</h1> 
        </div>


      </div >
    );
  
}


// Planification.propTypes = {
//   classes: PropTypes.object.isRequired,
// };



export default Planification;
import { Badge, Button, Grid, Input, Slider, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { GetAllSoilTypes, GetSoilFamilliesList } from '../../../globalsTools/network';
import { logger } from '../../../globalsTools/utilities';
import { StyledMaterialTable } from '../../../ui/display';
import { StyledDialog } from '../../../ui/display/StyledDialog';
import StyledInput from '../../../ui/forms/StyledInput';
import { vocabulary } from '../../Strings';

const CustomSoilStructure = ({soilSetter, ...properties}) => {
    const EDITABLE_COLUMNS = [
        { title: 'Depth', field: 'depth' },
        { title: 'Argile', field: 'elements.Argile' },
        { title: 'Limon', field: 'elements.Limon' },
        { title: 'Sable', field: 'elements.Sable' },
        { title: 'Sol', field: 'sol' },
    ];

    /*

[
   {
      "depth":"5-15cm",
      "elements":{
         "Argile":42.600000000000001,
         "Limon":42.700000000000003,
         "Sable":14.699999999999999
      },
      "sol":"AL"
   },
   {
      "depth":"15-30cm",
      "elements":{
         "Argile":44.700000000000003,
         "Limon":41.899999999999999,
         "Sable":13.4
      },
      "sol":"AL"
   },
   {
      "depth":"30-60cm",
      "elements":{
         "Argile":47,
         "Limon":40.200000000000003,
         "Sable":12.800000000000001
      },
      "sol":"ALO"
   },
   {
      "depth":"60-100cm",
      "elements":{
         "Argile":45.200000000000003,
         "Limon":41.5,
         "Sable":13.300000000000001
      },
      "sol":"ALO"
   },
   {
      "depth":"100-200cm",
      "elements":{
         "Argile":43.5,
         "Limon":42.5,
         "Sable":14
      },
      "sol":"AL"
   }
]
    */

    const [data, setData] = useState([]);

    const [editing, setEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);

    const startEdit = (index) => {
        logger('>> Start Editing', index);
        setEditingIndex(index);
        setEditing(true);
    };

    const removeRow = (index) => {
        let copyData = [...data];
        copyData.splice(index,1)
        setData(copyData)
    };

    const saveTableElement = (element, index) => {
        if (element) {
            let copyData = [...data];
            if (index!=null) {
                copyData[index] = element;
            } else {
                copyData = [...data, element];
            }
            setData(copyData.sort((a,b)=>(a.depthFrom-b.depthFrom)));
            closeTableElement();
        }
    };

    const closeTableElement = () => {
        setEditing(false);
        setEditingIndex(null);
    };

	useEffect(() => {
	  if(data) {
		soilSetter(data)
	  }
	}, [data])
	

	

    return (
        <div {...properties}>
            <StyledMaterialTable 
                title={vocabulary.customSoilStructure}
                data={data}
                actions={[
                    {
                        icon: () => (
                            <AddCircle fontSize='large' color='primary' />
                        ),
                        tooltip: vocabulary.addNewSoilHorizon,
                        isFreeAction: true,
                        onClick: () => startEdit(),
                    },
                    {
                        icon: 'edit_outline',
                        tooltip: vocabulary.editArea,
                        onClick: (event, rowData) =>
                            startEdit(rowData.tableData.id),
                    },
                    {
                        icon: 'delete_outline',
                        tooltip: vocabulary.remove,
                        onClick: (event, rowData) =>
                            removeRow(rowData.tableData.id),
                    },
                ]}
                columns={EDITABLE_COLUMNS}
            />

            {editing && (
                <CustomSoilStructureRow
                    data={data}
                    saveFn={saveTableElement}
                    closeFn={() => {
                        setEditing(false);
                        setEditingIndex(null);
                    }}
                    edit={editing}
                    index={editingIndex}
                />
            )}
        </div>
    );
};

const CustomSoilStructureRow = ({
    data,
    saveFn,
    closeFn,
    edit = false,
    index = null,
}) => {
    const 	[lastValueChanged, setLastValueChanged] = useState(null);
	const [soilList, setSoilList] = useState([])

	const currentLanguage = useSelector(
		(state) => state._globalState.preferred_language
	  );

    const 	UPDATE_VALUE = 'UPDATE_VALUE',
			UPDATE_SOIL_TYPE = 'UPDATE_SOIL_TYPE',
			CHANGE_DEPTH_FROM = 'CHANGE_DEPTH_FROM', 
			CHANGE_DEPTH_TO = 'CHANGE_DEPTH_TO',
			SOIL_STRUCTURE_CHANGE = 'SOIL_STRUCTURE_CHANGE';

    const soilStructureReducer = (state, action) => {
        switch (action.type) {
            case SOIL_STRUCTURE_CHANGE:
                return {
					depthFrom:extractDepth('from', action.payload.depth),
					depthTo:extractDepth('to', action.payload.depth),
                    depth: action.payload.depth,
                    elements: {
                        Argile: action.payload.elements.Argile,
                        Limon: action.payload.elements.Limon,
                        Sable: action.payload.elements.Sable,
                    },
					Argile: action.payload.elements.Argile,
					Limon: action.payload.elements.Limon,
					Sable: action.payload.elements.Sable,
					sol: action.payload.sol,
                };
            case UPDATE_VALUE:
                let newState = { ...state };
                if (Array.isArray(action.payload)) {
                    action.payload.forEach((item) => {
						logger("Value Type >> ", item)
						if (('valuetype' in item) && item.valuetype!=null && Number.isInteger(item.value)) {
							newState = { ...newState, ...{elements: {...newState.elements, [item.valuetype]:item.value }, [item.valuetype]:item.value }};
						}
                    });
                }
				let soiltype = findSoilType(newState.elements.Argile, newState.elements.Sable)
                return { ...newState, 'sol': soiltype };
            case UPDATE_SOIL_TYPE:
                return { ...state, sol:action.payload };
            case CHANGE_DEPTH_FROM:
                return { ...state, depthFrom:action.payload, depth:(action.payload+"-"+state.depthTo+'cm') };
            case CHANGE_DEPTH_TO:
                return { ...state, depthTo:action.payload, depth:(state.depthFrom+"-"+action.payload+'cm') };
            default:
                return state;
        }
    };

	const extractDepth = (whichDepth, depth) => {
		depth = depth.replace('cm','')
		return depth.split('-')[ whichDepth==='from' ? 0 : 1 ]
	}
	
    const findSoilType = (Argile, Sable) => {
        let soilType = 'NA';
        if (Argile > 45) {
            soilType = 'ALO';
        } else if (Argile > 30) {
            if (Sable < 20) {
                soilType = 'AL';
            } else if (Sable < 45) {
                soilType = 'A';
            } else {
                soilType = 'AS';
            }
        } else if (Argile > 17.5) {
            if (Sable < 15) {
                soilType = 'LA';
            } else if (Sable < 35) {
                soilType = 'LAS';
            } else if (Sable < 55) {
                soilType = 'LSA';
            } else {
                soilType = 'SA';
            }
        } else if (Argile > 7.5) {
            if (Sable < 15) {
                soilType = 'LM';
            } else if (Sable < 35) {
                soilType = 'LMS';
            } else if (Sable < 55) {
                soilType = 'LS';
            } else if (Sable < 80) {
                soilType = 'SL';
            } else {
                soilType = 'S';
            }
        } else if (Sable < 15) {
            soilType = 'LL';
        } else if (Sable < 55) {
            soilType = 'LLS';
        } else if (Sable < 80) {
            soilType = 'SL';
        } else {
            soilType = 'S';
        }

        return soilType;
    };

    const [soilStructureState, dispatchSoilStructureEvent] = useReducer(
        soilStructureReducer,
        {
            depthFrom: '0',
            depthTo: '0',
            depth: '0-0',
            elements: {
                Argile: 33,
                Limon: 33,
                Sable: 34,
            },
            sol: '',
        }
    );

    const validateFrom = (value) => {
        if (value) {
            if (data.length > 0 && index==null) {
                let filter = data.filter((element) => {
                    let [from, to] = element.depth.replace('cm','').split('-');
                    return Number(value) >= Number(from) && Number(value) < Number(to);
                })
                return filter.length === 0
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const validateTo = (value) => {
        if (Number(value)===0 || Number(value)<=Number(soilStructureState.depthFrom)) {
            return false;
        }
        if (value!=null) {
            if (data.length > 0 && index==null) {
                return (
                    data.filter((element) => {
                        let [from, to] = element.depth.replace('cm','').split('-');
                        return Number(value) > Number(from) && Number(value) <= Number(to);
                    }).length  === 0
                );
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const adjustValueToLimits = (value) => {
        if (value < 0) {
            return 0;
        } else if (value > 100) {
            return 100;
        } else {
			return value
		}
    };


    const automaticCalibration = (newValueType, newValue = 0) => {
		logger("Autocalibration Received : newValueType, newValue, lastValueChanged", newValueType, newValue, lastValueChanged)
		let adjustments= []
        if (newValueType) {
            if (lastValueChanged != null) {
                let typeToUpdate = ['Argile', 'Sable', 'Limon'].filter(
                    (n) => ![newValueType, ...lastValueChanged].includes(n)
                )[0];
                let valueOfLastValueTypeChanged =
                    soilStructureState.elements[lastValueChanged.filter((n) => n !== newValueType)[0]];
                let newValueAdjusted =
                    100 - (newValue + valueOfLastValueTypeChanged);
                logger(
                    newValue,
                    valueOfLastValueTypeChanged,
                    newValueAdjusted,
                    adjustValueToLimits(newValueAdjusted)
                );
                adjustments = [
                        {
                            valuetype: typeToUpdate,
                            value: adjustValueToLimits(newValueAdjusted),
                        },
                    ];
            }
			adjustments = [{
					valuetype: newValueType,
					value: adjustValueToLimits(newValue),
				}, ...adjustments
			]

			logger('Adjustments >>', adjustments)
			dispatchSoilStructureEvent({
				type: UPDATE_VALUE,
				payload: adjustments,
			});
            if (
                lastValueChanged == null ||
                newValueType !== lastValueChanged[0]
            ) {
                setLastValueChanged(
                    lastValueChanged
                        ? [newValueType, ...lastValueChanged].slice(0, 2)
                        : [newValueType]
                );
            }
        }
    };

    useEffect(() => {
        if (data!=null && index!=null && data[index]!=null) {
            logger("Dispatching New Data", index, data[index])
            dispatchSoilStructureEvent({type:SOIL_STRUCTURE_CHANGE, payload:data[index]});
        }
    }, [data, index]);

    useEffect(() => {
        logger('>> Horizon -> Soil Structure State', soilStructureState, validateFrom(soilStructureState.depthFrom), validateTo(soilStructureState.depthTo));
    }, [soilStructureState]);

	useEffect(() => {
		GetAllSoilTypes(setSoilList);
	}, [])

	useEffect(() => {
		logger(">> Soil List", soilList)
	}, [soilList])

	const getSoilName = useCallback(
	  () => {
		if (soilList && Array.isArray(soilList)) {
			let soilType = soilList.filter(n => n.soil_texture_class===soilStructureState.sol) 
			if (soilType.length===1) {
				soilType = soilType[0]
				try {
					return JSON.parse(soilType.name)['name_'+currentLanguage]
				} catch(e) {
					logger(e)
					return "-"
				}
			}
		} else {
			return "--"
		}
	  },
	  [soilStructureState, currentLanguage, soilList],
	)
	
	
	

    return (
        <StyledDialog
            open={true}
            title={!edit ? 'Ajouter un Horizon' : 'Editer un Horizon'}
            actions={
                <>
                    <Button
                        onClick={() => {
                            closeFn();
                        }}
                        style={{ alignSelf: 'start' }}
                    >
                        {vocabulary.cancel}
                    </Button>
                    <Button
                        onClick={() => {
                            saveFn(soilStructureState, index);
                        }}
                        style={{ alignSelf: 'end' }}
                        disabled={!(validateFrom(soilStructureState.depthFrom) 
                                        && validateTo(soilStructureState.depthTo) 
                                        && soilStructureState.sol!==''  
                                        && (soilStructureState.Argile + soilStructureState.Sable + soilStructureState.Limon) ===100 )}
                    >
                        {vocabulary.save}
                    </Button>
                </>
            }
        >
			<Grid container>
				<Grid item container direction='row'  width={`calc(100%)!important`}>
					<Grid item xs style={{display:'flex'}}>
						<Typography id='input-from'>From</Typography>
						<StyledInput
							value={soilStructureState.depthFrom}
							errors={!validateFrom(soilStructureState.depthFrom) ? 'Données Invalides' : null}
							inputProps={{
								step: 1,
								min: 0,
								type: 'number',
								'aria-labelledby': 'input-from',
							}}
							onChange={(event) => {
								dispatchSoilStructureEvent({type:CHANGE_DEPTH_FROM, payload:event.target.value});
							}}
							type='number'
						/>
					</Grid>
					<Grid item xs style={{display:'flex'}}>
						<Typography id='input-to'>To</Typography>
						<StyledInput
							value={soilStructureState.depthTo}
							errors={!validateTo(soilStructureState.depthTo) ? 'Données Invalides' : null}
							inputProps={{
								step: 1,
								min: 0,
								type: 'number',
								'aria-labelledby': 'input-to',
							}}
							onChange={(event) => {
								dispatchSoilStructureEvent({type:CHANGE_DEPTH_TO, payload:event.target.value});
							}}
						/>
					</Grid>
				</Grid>
				<Grid item container spacing={2} direction='row' style={{display:'flex'}} width={`calc(100%)!important`}>
					<ElementInputSlider
						valueSetter={automaticCalibration}
						elementText='Argile'
						value={soilStructureState}
						valueType='Argile'
					/>
					<ElementInputSlider
						valueSetter={automaticCalibration}
						elementText='Limon'
						value={soilStructureState}
						valueType='Limon'
					/>
					<ElementInputSlider
						valueSetter={automaticCalibration}
						elementText='Sable'
						value={soilStructureState}
						valueType='Sable'
					/>
				</Grid>
				<Grid item container direction='row' spacing={2} width={`calc(100%)!important`} style={{marginTop:12}}>
					<Grid item xs>
						<Typography>Element Type</Typography>
					</Grid>
					<Grid item xs style={{border:`1px solid`}}>
						<Typography>{soilStructureState.sol }</Typography>
					</Grid>
					<Grid item xs style={{border:`1px solid`}}>
						<Typography>{getSoilName() }</Typography>
					</Grid>
				</Grid>
			</Grid>
        </StyledDialog>
    );
};

const ElementInputSlider = ({
    valueSetter,
    elementText,
    value,
    valueType,
    width = 200,
}) => {
    const handleSliderChange = (event, newValue) => {
        valueSetter(valueType, newValue);
    };

    const handleInputChange = (event) => {
        valueSetter(
            valueType,
            event.target.value === '' ? '' : Number(event.target.value)
        );
    };

    const handleBlur = () => {
        if (value < 0) {
            valueSetter(valueType, 0);
        } else if (value > 100) {
            valueSetter(valueType, 100);
        }
    };

    return (

		<Grid item container>
			<Typography id='input-slider' gutterBottom>
				{elementText}
			</Typography>
            <Grid  item container direction='row' spacing={2} alignItems="center" wrap="nowrap">
				<Grid item xs >
					<Slider
						value={(typeof value.elements[valueType] === 'number' ? value.elements[valueType] : 0 ) || 0}
						onChange={handleSliderChange}
						aria-labelledby='input-slider'
					/>
				</Grid>
                <Grid item>
                    <Input 
                        value={value.elements[valueType] || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: 100,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
							style: {minWidth:0}
                        }} 
                        type='number' 
						style={{minWidth:0}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CustomSoilStructure;

/*

        editable={{
          onBulkUpdate: (changes) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                let copyData = [...data];
                setData(getNewDataBulkEdit(changes, copyData));
                resolve();
              }, 1000);
            })
          },
          onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
          onRowUpdateCancelled: (rowData) => console.log("Row editing cancelled"),
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                newData.id = "uuid-" + Math.random() * 10000000;
                setData([...data, newData]);
                resolve();
              }, 1000);
            });
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                // In dataUpdate, find target
                const target = dataUpdate.find((el) => el.id === oldData.tableData.id);
                const index = dataUpdate.indexOf(target);
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
                resolve();
              }, 1000);
            });
          },
          onRowDelete: (oldData) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data];
                const target = dataDelete.find((el) => el.id === oldData.tableData.id);
                const index = dataDelete.indexOf(target);
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve();
              }, 1000);
            });
          },
        }}

 */

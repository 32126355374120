/* eslint-disable  dot-location */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import SoilTypeAddForm from './SoilTypeAdd'

import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { GetSoilTypesList } from '../../../globalsTools/network';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { DeleteSoilType } from "../../../globalsTools/network";
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const SoilTypeList = ({ itemRow, selectedItem }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [soilTypeList, setSoilTypeList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (itemRow) {
            GetSoilTypesList(itemRow.id, setSoilTypeList)
        }

    }, [itemRow])

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)["name_" + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetSoilTypesList(item.prm_texture_sol_id, setSoilTypeList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteSoilType = (item) => {

        DeleteSoilType(item.id
            , r => operationCallBack(r)
        )
    }

    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenType(!openType)
            setOpenEdit(!openEdit)
        } else {
            setOpenType(newStatus)
            setOpenEdit(newStatus)
        }
    }
    const openAddSoilType = () => {
        setOpenType(true)
        setIsEditing(false)
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const contextValue = { open: openType, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    return (

        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            <StyledCard>

                {(!isLoading && soilTypeList) ?
                    <StyledMaterialTable
                        title={vocabulary.soilType + ': ' + JSON.parse(itemRow.name)['name_' + currentLanguage]}
                        data={soilTypeList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[
                            { title: vocabulary.name_label, field: 'name', render: rowData => <>{JSON.parse(rowData.name)['name_' + currentLanguage]}</> },
                            { title: vocabulary.solTexture, field: 'soil_texture_class' },
                            { title: vocabulary.useful_Reserve, field: 'useful_reserve' },
                            { title: vocabulary.densite_apparente, field: 'apparent_density' },
                            { title: vocabulary.eau_utile, field: 'useful_water' },
                            { title: vocabulary.humidite_capacite_au_champ, field: 'field_capacity' },
                            { title: vocabulary.humidite_au_point_fletrissement, field: 'wilting_point' },
                            { title: vocabulary.soilSensibilite, field: 'soil_sensitivity' },

                        ]}
                        actions={[
                            {
                                icon: 'edit_outline',
                                tooltip: vocabulary.editSoilType,
                                onClick: (event, row) => onEdit(row)
                            },
                            row => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                                disabled: row.id < 2000
                            }),
                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addSoilType,
                                isFreeAction: true,
                                onClick: (event) => openAddSoilType()
                            },


                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            paging: false

                        }}
                    />
                    :
                    <StyledCircularProgress size={50} label={vocabulary.loading} />

                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item.name)["name_" + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteSoilType(item)

                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
                {

                    <SoilTypeAddForm
                        open={isEditing === true ? openEdit : openType}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenType(false),
                                        setIsEditing(false))
                                    :
                                    setOpenType(false)
                            )

                        }}
                        width={500}
                        id_texturesols={itemRow.id}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetSoilTypesList(itemRow.id, setSoilTypeList)}
                        setIsLoading={setIsLoading}

                    />
                }

            </StyledCard>
        </ModalContext.Provider>
    )
}
export default SoilTypeList
import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';

import {Chip } from '@material-ui/core/';
import { useHistory } from 'react-router-dom';

// import { withStyles } from '@material-ui/core/styles';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
import IconButton from '@material-ui/core/IconButton';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';



import Dialog from '@material-ui/core/Dialog';

import AppIcon from '@material-ui/icons/Computer';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import 'react-phone-number-input/style.css'

import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { stylesThemed } from '../globalStyle';

const ListOrganizationFollowers = ({ currentRow, currentFarmers,open1, handleClose1,Transition,currentNonSeabexFarmers }) => {
    const classes = stylesThemed();

    const history = useHistory();

    const getRealTimeData = (row) => {
        //if (row.gateway_id) {
        history.push("../app/monitoringAndSensors", { "stations": row });

    }
    return (
        <Dialog fullScreen onClose={handleClose1} TransitionComponent={Transition} open={open1}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" onClick={handleClose1} color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography title className={classes.title}>

                        {currentRow.name ? JSON.parse(currentRow.name).name : ''}
                    </Typography>
                </Toolbar>
            </AppBar>
            <StyledMaterialTable
                            title={'Followers'}
                            data={currentFarmers}
                            columns={[
                                { title: vocabulary.fullName, field: 'user',render: rowData => <>{rowData.user.firstname} {rowData.user.lastname}</>  },
                                { title: vocabulary.mobile, field: 'user',render: rowData => <>{rowData.user.mobile}</> },
                                { title: vocabulary.email, field: 'user' ,render: rowData => <>{rowData.user.email}</> },
                                { title: vocabulary.ville, field: 'user',render: rowData => <>{JSON.parse(rowData.user.address).address}</> } ,
                                { title: vocabulary.memberSince, field: 'start_date' },

                                { title: vocabulary.SubscriptionType, field: 'channels',render: rowData => <div style={{display:'flex'}}>
                                {JSON.parse(JSON.parse(rowData.channels)).sms ?
                                                <Chip
                                                    icon={<SmsIcon className={classes.colorTextChips} />}
                                                    label={vocabulary.sms}
                                                    className={[classes.chip, classes.colorAction, classes.colorTextChips]}
                                                />
                                                : null
                                            }
                                            {JSON.parse(JSON.parse(rowData.channels)).email ?
                                                <Chip
                                                    icon={<EmailIcon className={classes.colorTextChips} />}
                                                    label={vocabulary.email}
                                                    className={[classes.chip, classes.colorGateway, classes.colorTextChips]}
                                                />
                                                : null}
                                            {JSON.parse(JSON.parse(rowData.channels)).application ?
                                                <Chip
                                                    icon={<AppIcon className={classes.colorTextChips} />}
                                                    label={vocabulary.application}
                                                    className={[classes.chip, classes.colorMonitoring, classes.colorTextChips]}
                                                />
                                                : null}
                                                {JSON.parse(JSON.parse(rowData.channels)).api ?
                                                <Chip
                                                    icon={<AppIcon className={classes.colorTextChips} />}
                                                    label={vocabulary.apiLabel}
                                                    className={[classes.chip, classes.colorMonitoring, classes.colorTextChips]}
                                                />
                                                : null}
                                </div> },


                            ]}
                            actions={[
                               
                                
                                
                                rowData =>(
                                    {
                                        icon: ()=> <SettingsInputAntenna/>,
                                        tooltip: 'RealTime',
                                        onClick: (event, row) => getRealTimeData(row.deployedDevices),
                                        
                                        disabled: rowData.deployedDevices.length === 0
                                    }
                                ) 

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true

                            }}
                        />
            
        </Dialog>
    )

}
// ListOrganizationFollowers.propTypes = {
//     classes: PropTypes.object.isRequired,
// };
export default ListOrganizationFollowers
/* eslint-disable  */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadIrrigationPlans } from '../../actions';
import {  FormControl,  InputLabel, Select } from '@material-ui/core/';
import { vocabulary } from '../Strings';
import { MenuItem } from '@material-ui/core/';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledSelect from "../../ui/forms/StyledSelect";
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import  StyledSwitch  from "../../ui/forms/StyledSwitch";

const ActionsForm = (props) => {

    const dispatch = useDispatch()
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()

    //  const deployedDevices = useSelector(state => state.station.data.filter(station => (station.seabexstation ? station.seabexstation.type === 'ACTION' : false)))
    const automationplans = useSelector(state => state.irrigationPlan.data)
    const deployedDevices = useSelector(state => state.station.data.data.filter(station => (station.seabexmodel ? JSON.parse(station.seabexmodel.type).type === 'ACTION' : false)))

    //const [port, setPort] = useState('')
    const [Action, setAction] = useState(true)
    const [isEditing, setIsEditing] = useState(false)
    const [selectedStationIndex, setSelectedStationIndex] = useState('')
    const [delay, setDelay] = useState('')
    const [selectedActionType, setSelectedActionType] = useState(1)
    const [selectedAutomationPlanIndex, setSelectedAutomationPlanIndex] = useState('')
    const [selectedActuator, setSelectedActuator] = useState('')

    const modalContext = useContext(ModalContext)




    useEffect(() => {
        if (props.isEditing === false) {
            setSelectedActionType(1)
            setAction(true)
            setDelay('')
            setSelectedAutomationPlanIndex('')
            setSelectedActuator('')

        }

        setIsEditing(props.isEditing)
    }, [props.open, props.isEditing]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(loadIrrigationPlans());

    }, [dispatch])

    const confirmAddForm = (data) => {

        selectedActionType === 1 ?
            props.addAction(
                {
                    DeviceID: deployedDevices[selectedStationIndex].identifier,
                    DeviceName: deployedDevices[selectedStationIndex].name,
                    Port: selectedActuator,
                    Action: Action,
                    Delay: delay,
                    Type: 'assistedMode'

                }
            )
            :
            props.addAction(
                {
                    AutomationPlanID: selectedAutomationPlanIndex,
                    AutomationPlan: automationplans.find(elem => elem.id === selectedAutomationPlanIndex),
                    //Action: true,
                    Type: 'automationPlan'
                }
            )
        props.onClose()
    }

    const actionType = [{ 'name': vocabulary.automationPlan, 'id': 0 }, { 'name': vocabulary.AssistedAction, 'id': 1 }]

    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" ,onClick:()=>confirmAddForm() },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (

        <div>


            <StyledModal
                open={props.open}
                onClose={props.onClose}
                modalContext={modalContext}
                width={props.width}
                title={vocabulary.add + " " + vocabulary.actions}
            >
                <form
                    ref={form}
                    //onSubmit={handleSubmit(confirmAddForm)}
                >
                    <StyledSelect
                        value={selectedActionType}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.selectedActionType}
                        onChange={(event) => {
                            setSelectedActionType(event.target.value)
                        }}
                        name='selectedActionType'
                        label={vocabulary.actionType}
                        fullWidth
                        data={actionType}
                        valueField='id'
                        labelField='name'
                        styles={{ width: '100%' }}

                    />

                    <br />

                    {selectedActionType === 1 ?
                        <>
                            <StyledSelect
                                value={selectedStationIndex}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.selectedStationIndex}
                                onChange={(event) => {
                                    setSelectedStationIndex(event.target.value)
                                }}
                                name='selectedStationIndex'
                                label={vocabulary.selectStation}
                                fullWidth
                                data={deployedDevices}
                                valueField='id'
                                labelField='name'
                                indexV={true}
                                styles={{ width: '100%' }}

                            />
                           

                            <FormControl required
                                style={{ width: '100%', margin: '8px' }}>
                                <InputLabel id="demo-simple-select-outlined-label">{vocabulary.selectActuator}</InputLabel>
                                <Select
                                    value={selectedActuator}
                                    onChange={(event) => {
                                        setSelectedActuator(event.target.value)
                                    }}
                                    label={vocabulary.selectActuator}
                                    name='selectedActuator'
                                >
                                    {
                                        deployedDevices[selectedStationIndex] && (
                                            deployedDevices[selectedStationIndex].area_deployed_device_equipment.map((item, index) =>

                                                <MenuItem key={index} value={item.port}>{JSON.parse(item.equipment.name).name_fr} </MenuItem>

                                            )

                                        )
                                    }
                                </Select>

                            </FormControl>

                            <StyledSwitch
                                checked={Action}
                                onChange={(event) => {
                                    setAction(event.target.checked)
                                }}
                                value={"Action"}
                                label={"Port " + selectedActuator}
                            />


                            <StyledInput
                                name="Delay"
                                label={vocabulary.delayInMinute}
                                value={delay}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.Delay}
                                onChange={(event) => {
                                    setDelay(event.target.value)
                                }}
                                type="number"
                                fullWidth
                            />
                        </>
                        :
                        <StyledSelect
                            value={selectedAutomationPlanIndex}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.selectedStationIndex}
                            onChange={(event) => {
                                setSelectedAutomationPlanIndex(event.target.value)
                            }}
                            name='selectedStationIndex'
                            label={vocabulary.selectStation}
                            fullWidth
                            data={automationplans}
                            valueField='id'
                            labelField='Title'
                            indexV={true}

                        />

                    }

                    <StyledModalFooter actions={actions} />

                </form>


            </StyledModal>

        </div>
    )


}

export default ActionsForm;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { vocabulary } from "../Strings";
import { setCurrentPage } from "../../actions";
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AlertDialog from "../Components/alertDialog";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { GetAreasList, DeleteQuota, GetQuotasList } from "../../globalsTools/network";
import AddQuotaForm from './AddQuotaForm'

const QuotasManagmentList = () => {

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [quotasList, setQuotasList] = useState(null)
    const [siteList, setSiteList] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.managingQuotasLabel))
        GetAreasList(setSiteList)
        GetQuotasList(setQuotasList)


    }, [dispatch])

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.managingQuotasLabel));
        document.title = vocabulary.managingQuotasLabel

    }, [dispatch, currentLanguage])

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const handleClickOpen = () => {
        setOpenAdd(true)
    };

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(vocabulary.quota + ' ' + vocabulary.ofLabel + ' ' + item.area.name + ' ' + vocabulary.deleteMsg, 'success')
                GetQuotasList(setQuotasList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteQuota = (item) => {
        DeleteQuota(item.id
            , r => operationCallBack(r)
        )
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }

    const changeModalStatus = (newStatus) => {

        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }


    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            <StyledCard>
                {
                    (!isLoading && siteList) ?
                        <StyledMaterialTable
                            title={vocabulary.managingQuotasLabel}
                            data={quotasList ? quotasList : []}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}

                            columns={[
                                { title: vocabulary.area, field: 'area', render: rowData => <>{rowData.area ? rowData.area.name : '--'}</> },
                                { title: vocabulary.quotaLabel, field: 'quota', render: rowData => <>{Number(rowData.allowed_volume)}</> },
                                { title: vocabulary.seasonStartLabel, field: 'start_date', render: rowData => <>{rowData.start_date ? currentLanguage === "en" ? moment(rowData.start_date).format('YYYY-MM-DD') : moment(rowData.start_date).format('DD-MM-YYYY') : '-'}</> },
                                { title: vocabulary.seasonEndLabel, field: 'end_date', render: rowData => <>{rowData.end_date ? currentLanguage === "en" ? moment(rowData.end_date).format('YYYY-MM-DD') : moment(rowData.end_date).format('DD-MM-YYYY') : '-'}</> },

                            ]}
                            actions={[

                                row => ({
                                    icon: 'edit_outline',
                                    onClick: (event, row) => onEdit(row),
                                    tooltip: vocabulary.editLabel,

                                }),
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),

                                }),


                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpen()
                                },


                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                pageSize: 10,
                                pageSizeOptions: [5, 10, 20, 40, 60, 80],

                            }}
                        />
                        : <StyledCircularProgress size={50} label={vocabulary.loading} />

                }

                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={vocabulary.quota + ' ' + vocabulary.ofLabel + ' ' + item.area.name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteQuota(item)
                            }


                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />
                }
                {(openAdd || openEdit) &&
                    <AddQuotaForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?

                                    setIsEditing(false)
                                    : setOpenAdd(false)

                            )
                        }}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        siteList={siteList}
                        setIsLoading={setIsLoading}
                        refetchFunc={() => GetQuotasList(setQuotasList)}


                    />
                }
            </StyledCard>

        </ModalContext.Provider>
    )
}
export default QuotasManagmentList
/* eslint-disable eqeqeq*/

import React from "react";
import { useSnackbar } from "notistack";
import mqtt from "mqtt";
import { vocabulary } from "../Strings";
import { logger } from "../../globalsTools/utilities";
import { envKeys } from "../../config";
// import { getCookie, setCookie } from 'tiny-cookie';
// import { userId} from '../../App/HomePage/HomePage';
//import { readCookie } from '../../utils';

//let userIdCookies = localStorage.getItem('userid');

const options = {
  // userid:userIdCookies
};
//logger("mqtt connack.userIduserId", userIdCookies);

export const mqttClient = mqtt.connect(envKeys.BROKER_URL, options); //wss://broker.seabex.com:1880
export const mqttSubs = (userid) => {
  mqttClient.subscribe(
    [
      "/notify/" + userid + "/#",
      "/command/" + userid + "/#",
      "/error/" + userid + "/#",
    ],
    { qos: 2 },
    function (err, granted) {
      if (err) {
        logger("MQTT Subscribe ERROR", err);
      } else {
        logger("MQTT Subscribe SUCCESS", granted);
      }
    }
  );
  mqttClient.on("connect", function (connack) {
    logger("mqtt connack.returnCode", connack.returnCode);
    switch (connack.returnCode) {
      case 0:
        logger("MQTT connack returnCode: " + connack.returnCode + " Connection accepted");
        mqttClient.subscribe(
          [
            "/notify/" + userid + "/#",
            "/command/" + userid + "/#",
            "/error/" + userid + "/#",
          ],
          { qos: 2 },
          function (err, granted) {
            if (err) {
              logger("MQTT Subscribe ERROR", err);
            } else {
              logger("MQTT Subscribe SUCCESS", granted);
            }
          }
        );
        break;
      case 1:
        logger("MQTT connack returnCode: " + connack.returnCode + " Connection refused, unacceptable protocol version");
        break;
      case 2:
        logger("MQTT connack returnCode: " + connack.returnCode + " Connection refused, identifier rejected");
        break;
      case 3:
        logger("MQTT connack returnCode: " + connack.returnCode + " Connection refused, server unavailable");
        break;
      case 4:
        logger("MQTT connack returnCode: " + connack.returnCode + " Connection refused, bad user name or password");
        break;
      case 5:
        logger("MQTT connack returnCode: " + connack.returnCode + " Connection refused, not authorized");
        break;
      default:
        logger("Unknown MQTT connack returnCode: ");
    }
  });
};

export const mqttEnd = (userid) => {
  mqttClient.unsubscribe([
    "/notify/" + userid + "/#",
    "/command/" + userid + "/#",
    "/error/" + userid + "/#",
    "/realtime/" + userid + "/#",
  ]);
};

const MqttController = () => {
  const { enqueueSnackbar } = useSnackbar();
  const moment = require('moment');

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,
      // resumeHideDuration: 6,
      autoHideDuration: 10000,
      style: { whiteSpace: "pre-line" },
    });
  };
  mqttClient.on("message", async function (topic, message) {
    logger("MQTT HEDI ON ALL Message Topic: " + topic + " Data: ", message);
    topic = topic.toString();
    message = JSON.parse(message.toString());
    logger("MQTT ON ALL Message Topic: " + topic + " Data: ", message);
    let topicArray = topic.toString().split("/");
    let notification = typeof message == "object" ? message : JSON.parse(message)
    logger("MQTT notification: ", notification);

    let action = notification.hasOwnProperty('Details') ? notification.Details.Action == "true" ? vocabulary.activatedLabel : vocabulary.desactivatedLabel : ' ';

    switch (topicArray[1]) {
      case "notify":
        logger("MQTT ON notify Message Topic: " + topic + " Data: ", message);
        switch (message.Type) {
          case "CONNECT":
            snackbar(
              `${vocabulary.youAreConnectedToGatewayLabel} ${message.GatewayID} ${vocabulary.at} ${message.Time}`,
              "success"
            );
            break;
          case "EXECUTION":

            logger('notificationnotificationnotificationDetailExecution', notification.Details)
            let titleTxt = notification.Details.Type !== "UserAction" ? (notification.Details.AutoPlaneTitle ? notification.Details.AutoPlaneTitle : notification.Details.ScenarioTitle) : ""
            snackbar(vocabulary.port + ' ' + notification.Details.Port + ' ' + vocabulary.onSurLabel +
              ' ' + notification.DeviceID + ' ' + vocabulary.isLabel + ' ' + action + ' , ' + vocabulary.caseLabel + " : " +  vocabulary[notification.Details.Type], 'success')
            if (notification.Details?.Action === "true") {
              snackbar(vocabulary.relayActivationLabel + ' ' + notification.Details.Port + ' ' + vocabulary.fromDeviceLabel + ' ' + notification.DeviceID + " (" + vocabulary[notification.Details.Type] + titleTxt + ")", 'success')
            }
            else {
              snackbar(vocabulary.relayDeactivation + ' ' + notification.Details.Port + ' ' + vocabulary.fromDeviceLabel + ' ' + notification.DeviceID + " (" + vocabulary[notification.Details.Type] + titleTxt + ")", 'success')
            }
            // snackbar(`${vocabulary.successfullyExecutionOn} ${message.DeviceID} ${vocabulary.at} ${message.Time}`, "success");
            break;

          case "AutomationPlanExecution":
            snackbar(vocabulary.automationPlanExecutionOn + ' ' + vocabulary.port + notification.Details.Port + ' ' + vocabulary.onSurLabel + ' ' + notification.DeviceID + ' ' + vocabulary.isLabel + ' ' + action + ' , ' + vocabulary.caseLabel + " : " + notification.Details.Type, 'warning')

            // snackbar(`${vocabulary.automationPlanExecutionOn} ${message.DeviceID} ${vocabulary.at} ${message.Time}`, "warning");
            break;
          case "AutomationPlanExecution_Failure":
            snackbar(`${vocabulary.automationPlanExecutionFailedOn} ${message.DeviceID} ${vocabulary.at} ${message.Time}`, "error");
            break;
          case "SCENARIO":
            if (notification.Details?.Action === "true") {
              snackbar(vocabulary.relayActivationLabel + ' ' + notification.Details.Port + vocabulary.fromDeviceLabel + notification.DeviceID + vocabulary.dueScenarioLabel)
            } else {
              snackbar(vocabulary.relayDeactivation + ' ' + notification.Details.Port + vocabulary.fromDeviceLabel + notification.DeviceID)
            }
            break;
          default:
            snackbar(`Unkown message type ${JSON.stringify(message)}`, "error");
            break;
        }
        break;
      case "command":
        logger("MQTT ON command Message Topic: " + topic + " Data: ", message);
        snackbar(vocabulary[message.type], "info"); // sending command
        break;
      // case "MODEAUTO":
      //   snackbar(notification.Message, "info")
      //   break;

      case "error":
        logger("MQTT ON error Message Topic: " + topic + " Data: ", message);
        if(message.hasOwnProperty('Type')){
          snackbar(message,'error')
        }else{
          snackbar(vocabulary.deployedDevices + ' : ' + (message.hasOwnProperty('Details') ? message.Details.DeviceID : message.DeviceID) + "\n" + vocabulary.errorOccurred + ' ' + vocabulary.onSurLabel + ' ' + vocabulary.theLabel + ' ' + vocabulary.equipmentLabel + ' ' + (message.hasOwnProperty('Details') ? message.Details.Equipment : message.Equipment) + ' ' + vocabulary.attachedOnLabel + ' ' + vocabulary.port + ' ' + (message.hasOwnProperty('Details') ? message.Details.Port : message.Port)
          + ' ' + vocabulary.at + ' ' + (message.hasOwnProperty('Details') ? moment(message.Details.CaptureTime).format('YYYY-MM-DD HH:mm:ss') : moment(message.CaptureTime).format('YYYY-MM-DD HH:mm:ss')) + '\n' + vocabulary.possibleReasonLabel, 'error')
       
        }
        // snackbar(`${vocabulary.errorOccurred}: ${message.DeviceID} ${vocabulary.at} ${message.CaptureTime}`, "error");
        break;
      // case "IRRIGATIONPLAN":
      //   if (notification.Cron) {
      //     setTimeout(
      //       function () {
      //         if (notification.Cron.Action === "true") {
      //           snackbar(vocabulary.relayActivationLabel + notification.Cron.Port + " de la carte " + notification.Cron.id + " Suite a la validation du plan d'irrigation: " + notification.Title)
      //         } else {
      //           snackbar(vocabulary.relayDeactivation + notification.Cron.Port + " de la carte " + notification.Cron.id + " Suite a la validation du plan d'irrigation: " + notification.Title)
      //         }
      //       }.bind(this),
      //       1500
      //     );
      //   }
      //   break;
      default:
        if (topicArray[1] != 'realtime')
          snackbar(`Unkown topic type ${topicArray[1]}`, "error");
        break;
    }
  });


  mqttClient.on('end', () => {
    logger('MQTT CLOSE SESSION')
  })

  mqttClient.on('disconnect', (packet) => {
    logger('MQTT DISCONNECT', packet)
  })
  return <></>;
};

export default MqttController;

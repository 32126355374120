import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import moment from "moment";
// import { useHistory } from "react-router-dom";

import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../globalConsts";
import { GetAreaDetails, createCycle } from "../../globalsTools/network";
import { GuideContext, steps } from "../../ui/layout/contextLayout/GuideContext";

const CycleFormStepper = ({ selectedArea, setSelectedArea, onClose, sitesList, setIsLoading, operationCallBack }) => {

  const { guide, dispatchGuide } = useContext(GuideContext);
  // const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element === ".addCycleBtn") {
          dispatchGuide({ type: IS_ENABLED, payload: false });
          dispatchGuide({ type: CURRENT_STEP, payload: "cycles" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          onClose();
        } else if (steps[nextStepIndex].element === ".addCycleModal") {
          GetAreaDetails(sitesList[0].id, setSelectedArea);
        } else if (steps[nextStepIndex].element === ".cycleList") {
          setIsLoading(true);
          createCycle(
            {
              area_id: selectedArea && selectedArea.id,
              prm_culture_id: "b72a37cb-2860-4f84-9e3f-17d4dda60b9e",
              start_date: moment(),
              production_params: JSON.stringify({ depth: "0.3", unit: "m" }),
              soil_water_volume: 10,
              additional_informations: JSON.stringify({
                field_capacity: "20",
                wilting_point: "3",
              }),
              periodic_irrigation_days: "3",
              cycle: JSON.stringify({
                Total: 150,
                "Phase initiale": 15,
                "Phase de developpement": 30,
                "Phase mi-saison": 65,
                "Phase arrière-saison": 40,
              }),
            },
            (r) => operationCallBack(r)
          );
          onClose();
          dispatchGuide({ type: CURRENT_STEP, payload: "cycles" });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
        }
      }}
      options={{
        tooltipClass: "steps",
      }}
      onExit={() =>
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        })
      }
    />
  );
}

export default CycleFormStepper;

/* eslint-disable eqeqeq*/

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { vocabulary } from '../Strings';
import { useSnackbar } from 'notistack';
import { GetFinancialCategories, DeleteFinancialCategory } from "../../globalsTools/network";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import AlertDialog from "../Components/alertDialog";
import AddFinancialResourceForm from './AddFinancialResourceForm'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { setCurrentPage } from '../../actions';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

const FinancialResourceList = () => {

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const userId = useSelector(state => state.app.userid)

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [financialCategorieList, setFinancialCategorieList] = useState(null)
    const [showFinancialByUser, setShowFinancialByUser] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    //financialManagment
    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.financialManagment));
        document.title = vocabulary.financialManagment
        GetFinancialCategories(setFinancialCategorieList)

    }, [dispatch, currentLanguage]); // N’exécute l’effet qu'a la première render

    // useEffect(() => {
    //     dispatch(setCurrentPage(vocabulary.financialManagment));
    //     document.title = vocabulary.financialManagment

    // }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.title + ' ' + vocabulary.deleteMsg, 'success')
                GetFinancialCategories(setFinancialCategorieList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteCategory = (item) => {

        DeleteFinancialCategory(item.id
            , r => operationCallBack(r)
        )
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const HandleShowActivitiesType = () => {
        setShowFinancialByUser(true)
        GetFinancialCategories(setFinancialCategorieList)
    }
    const HandleShowActivities = () => {
        setShowFinancialByUser(false)
        GetFinancialCategories(setFinancialCategorieList)
    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            <StyledCard>
                {(!isLoading && financialCategorieList) ?
                    <StyledMaterialTable
                        title={showFinancialByUser ? vocabulary.myFinancialCategorieLabel : vocabulary.financialCategorie}
                        data={showFinancialByUser ? financialCategorieList.data.filter(el => el.user_id !== null) : financialCategorieList.data}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}
                        columns={[
                            { title: vocabulary.titleLabel, field: 'title' },
                            { title: vocabulary.description, field: 'description' },
                            { title: vocabulary.financialCategoryParent, field: 'parent_financial_category', render: rowData => <>{rowData.parent_financial_category ? rowData.parent_financial_category.title : ''}</> },
                            // { title: vocabulary.financialType, field: 'financialType' },

                        ]}
                        actions={
                            showFinancialByUser ? [
                                row => ({
                                    icon: 'edit_outline',
                                    onClick: (event, row) => onEdit(row),
                                    tooltip: userId !== row.user_id ? vocabulary.disableEdit : vocabulary.editLabel,
                                    disabled: userId !== row.user_id
                                    // hidden: showAllGroup === true ? true : false,

                                }),
                                row => ({
                                    icon: 'delete_outline',
                                    onClick: (event, row) => openAlertConfirmDelete(row),
                                    tooltip: userId !== row.user_id ? vocabulary.disableDelete : vocabulary.delete,
                                    disabled: userId !== row.user_id
                                    // hidden: showAllGroup === true ? true : false,

                                }),
                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpen()
                                },
                                {
                                    icon: () => showFinancialByUser === false ? <ViewComfyIcon /> : <ViewHeadlineIcon />,
                                    tooltip: showFinancialByUser === false ? vocabulary.myFinancialCategorieLabel : vocabulary.financialCategorie,
                                    onClick: (event, row) => showFinancialByUser === false ? HandleShowActivitiesType() : HandleShowActivities(),
                                    isFreeAction: true,

                                },

                            ] :
                                [
                                    {
                                        icon: () => <AddIcon fontSize='large' color='primary' />,
                                        tooltip: vocabulary.add,
                                        isFreeAction: true,
                                        onClick: (event) => handleClickOpen()
                                    },
                                    {
                                        icon: () => showFinancialByUser === false ? <ViewComfyIcon /> : <ViewHeadlineIcon />,
                                        tooltip: showFinancialByUser === false ? vocabulary.myFinancialCategorieLabel : vocabulary.financialCategorie,
                                        onClick: (event, row) => showFinancialByUser === false ? HandleShowActivitiesType() : HandleShowActivities(),
                                        isFreeAction: true,

                                    },
                                ]
                        }
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 40, 60, 80, 100],

                        }}
                    /> : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }


                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.title}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteCategory(item);
                            }

                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                {(openAdd == true || openEdit == true) &&
                    <AddFinancialResourceForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={600}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetFinancialCategories(setFinancialCategorieList)}
                        setIsLoading={setIsLoading}

                    />}
            </StyledCard>
        </ModalContext.Provider>
    )
}
export default FinancialResourceList
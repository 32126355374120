import React from "react";
import { NETIRRIG_ENVIRONMENT } from "../App/globalConsts";
import logo from '../images/seabex/SeabexPlatformWhiteBG.svg';
import logo_netirrig from '../App/_partners/_Netirrig/assets/NetIrrigBySeabex.png';
import { REACT_APP_ENV_VERSION } from "../config";

export default (props) => {

    const {styles, ...properties} = props

    let style= {
        margin: 8,
        ...styles,
    }

    return (
        REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT ? 
        <img {...properties} src={logo_netirrig} style={style} alt="NetIrrig By Seabex Platform" />
        :
        <img {...properties} src={logo} style={style} alt="Seabex Platform" />
    )

}

/* eslint-disable no-useless-escape*/
import React, { useEffect, useRef, useState, useContext } from 'react';
import {  loadCitiesByIdCountries } from '../../actions';
import { useDispatch } from 'react-redux';
import { vocabulary } from '../Strings';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";

import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../ui/layout'
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import {CreateAgent} from '../../globalsTools/network'
const AgentForm = ({ isAgentEditing, agentOpen, currentOrganismeId, onClose, countriesList, citiesList }) => {

    const dispatch = useDispatch()
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const [selectedVilleIndex, setSelectedVilleIndex] = useState('')
    const [mobile, setMobile] = useState('')
    const [country, setCountry] = useState('Tunisia')


    useEffect(() => {
        if (isAgentEditing === false) {

            setSelectedVilleIndex('')
            setMobile('')
            setCountry('')

        }

    }, [agentOpen, isAgentEditing]); // N’exécute l’effet que si count a changé

    const newAgent = (data) => {
        dispatch(CreateAgent({
            firstname: data.firstName,
            lastname: data.lastName,
            email: data.email,
            city: selectedVilleIndex,
            country: country,
            password: data.password,
            mobile: mobile,
            address: JSON.stringify({ 'address': data.agentAddress }),
            organisme: currentOrganismeId,
            type: 'Organization'
        }))
        onClose()
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
  
    const actions = [
        { title: vocabulary.add, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    return (
        <>
            <StyledModal
                open={agentOpen}
                onClose={onClose}
                width={500}
                modalContext={modalContext}
                title={vocabulary.newAgent}
            >
                <form
                    onSubmit={handleSubmit(newAgent)}
                    ref={form}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="firstname"
                        errors={errors.firstname}
                        label={vocabulary.firstname}
                        type="text"
                        fullWidth

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        label={vocabulary.lastname}
                        type="text"
                        fullWidth
                        name="lastName"
                        errors={errors.lastName}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true, validate: validateEmail }}
                        name="email"
                        label={vocabulary.email}
                        type="text"
                        fullWidth
                        errors={errors.email}

                    />
                    {errors.email && <span>Email Invalide</span>}

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="password"
                        label={vocabulary.password}
                        type="text"
                        errors={errors.password}
                        fullWidth

                    />

                    <PhoneInput
                        country="TN"
                        displayInitialValueAsLocalNumber
                        placeholder={vocabulary.enterPhoneNumber}
                        value={mobile}
                        onChange={(event) => {
                            setMobile(event)
                        }}

                        error={mobile ? (isValidPhoneNumber(mobile) ? undefined : vocabulary.invalidPhoneNumber) : vocabulary.requiredPhoneNumber}
                    />

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="agentAddress"
                        label={vocabulary.address}
                        type="text"
                        fullWidth
                        errors={errors.address}

                    />

                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    
                            <StyledAutoComplete                             
                                stye={{ width: 80 }}
                                data={countriesList}                                  
                                optionLabel={(option) => option.label}
                                onChange={(event,newValue) => {
                                    setCountry(newValue.countyId)
                                    dispatch(loadCitiesByIdCountries(newValue.value))

                                }}
                                label={vocabulary.country}
                                
                            /> 
                             <StyledAutoComplete                              
                              stye={{ width: 80 }}
                              data={citiesList}                                  
                              optionLabel={(option) => option.label}
                              onChange={(event,newValue) => {
                                setSelectedVilleIndex(newValue.label)

                            }}
                              label={vocabulary.region}
                              
                          />     
                                                                        
                    </div>

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
        </>
    )
}
export default AgentForm
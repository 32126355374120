import React, { useEffect, useState } from 'react'
import { DeleteSettingByName, GetSettingsByName } from '../../globalsTools/network'
import { vocabulary } from '../Strings'
import { StyledMaterialTable } from '../../ui/display'
import { SearchOutlined } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/AddCircleOutline';


export const MultilogSettings = ({ setSelection, updated }) => {

    const [settings, setSettings] = useState([])


    useEffect(() => {
        GetSettingsByName('multi-logs', setSettings)
    }, [updated])

    const COLUMNS = [
        { title: "areaId", field: 'areaId' },
        { title: "areaName", field: 'areaName' },
        { title: "dataTypeIdentifier", field: 'dataTypeIdentifier' },
        { title: "dataTypeLabel", field: 'dataTypeLabel' },
        { title: "dataSourceId", field: 'dataSourceId' },
        { title: "equipmentName", field: 'equipmentName' },
        { title: "aggregator", field: 'aggregator' },
        { title: "port", field: 'port' },
    ];


    return (
        <StyledMaterialTable
            title={vocabulary.Settings}
            data={settings}
            columns={COLUMNS}
            styles={{ width: '96%', marginTop: 5 }}
            actions={[

                row => ({
                    icon: () => <SearchOutlined fontSize='large' color='primary' />,
                    tooltip: vocabulary.search,
                    onClick: (event, row) => setSelection(row, true),
                }),

                row => ({
                    icon: () => <AddIcon fontSize='large' color='primary' />,
                    tooltip: vocabulary.add,
                    onClick: (event, row) => setSelection(row, false),
                }),

            ]}

            editable={{
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataDelete = [...settings];
                            const index = oldData?.tableData?.id;
                            dataDelete.splice(index, 1);
                            console.log('dataDelete', dataDelete);
                            setSettings([...dataDelete]);
                            DeleteSettingByName('multi-logs', dataDelete)
                            GetSettingsByName('multi-logs', setSettings)
                            resolve();
                        }, 1000);
                    })
            }}
            options={{
                actionsColumnIndex: -1,
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 40, 60, 80, 100],

            }}
        />
    )
}


export default MultilogSettings
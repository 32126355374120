import React from "react";
import { Button } from "@material-ui/core";
import { Done } from '@material-ui/icons';

export default ({ label,styles, submit = true, disabled = false, onClick = undefined }) => {

  return (
    <div  style= { styles? styles:{
      
        display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      margin: 20
    }}>
      
      <Button
        type={submit ? "submit" : undefined}
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={onClick ? () => onClick() : undefined}
      >
       <Done /> &nbsp; {label}
      </Button>
    </div>
  )

}
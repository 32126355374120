/* eslint-disable eqeqeq*/
/* eslint-disable no-unused-vars*/

import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { CreateActivity, UpdateActivity, GetAreaDetails, GetProductionCycleByAreaId } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';
import StyledSelect from "../../ui/forms/StyledSelect";
// import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress'
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";

const AddActivityForm = ({ item, onClose, open, isEditing, width, refetchFunc, activitiesTypeList, sitesList, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const userId = useSelector(state => state.app.userid)

    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [startdate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [activityType, setActivityType] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [parcelsList, setParcelsList] = useState([])
    const [parcelId, setParcelId] = useState(null)
    const [selectedSiteId, setSelectedSiteId] = useState(null)
    const [sectorsList, setSectorsList] = useState([])
    const [sectorId, setSectorId] = useState(null)
    const [selectedAreaCycle, setSelectedAreaCycle] = useState(null)
    const [cycleId, setCycleId] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);


    useEffect(() => {

        if (isEditing === false) {
            setTitle(null)
            setDescription(null)
            setStartDate(null)
            setEndDate(null)
            setActivityType(null)
            setCycleId(null)
            setSelectedAreaCycle(null)
            setSelectedSiteId(null)
            setParcelId(null)
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (item) {
            if (item.area) {
                GetAreaDetails(item.area.parent !== null ? item.area.parent.id : item.area_id, setSelectedArea)
                GetProductionCycleByAreaId(item.area.parent !== null ? item.area.id : item.area_id, setSelectedAreaCycle)
            }

            setSelectedSiteId(item.area ? (item.area.parent !== null ? item.area.parent.id : item.area_id) : null)
            setParcelId(item.area ? (item.area.parent !== null ? item.area_id : null) : null)
            setStartDate(item.start_date)
            setEndDate(item.end_date)
            setTitle(item.title)
            setDescription(item.description)
            setActivityType(item.activity_type ? item.activity_type.id : null)
            setCycleId(item.production_cycle_id)
        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea])

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const validForm = () => {
        if (!title || !description || !startdate || !selectedSiteId) {
            return true
        }
        else return false

    }
    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
        GetProductionCycleByAreaId(event.target.value, setSelectedAreaCycle)
    };
    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSelectedSiteId(areasDetails.id)
                // setParcelId(null)


                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                setSectorsList(areasDetails["childs"])
                setSectorId(null)

                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;

            default:
                return ''

        }
    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? item.title + ' ' + vocabulary.updateSucc : vocabulary.activityLabel + ' ' + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            }

            else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                setIsLoading(false)

            }
        }
    }
    const handleAddActivity = () => {
        setIsLoading(true)

        CreateActivity(
            {
                title: title,
                description: description,
                userId: userId,
                start_date: startdate,
                end_date: endDate,
                status: 'test',
                activity_type_id: activityType,
                area_id: selectedArea && selectedArea.id,
                production_cycle_id: cycleId
            }, r => operationCallBack(r)
        )
        onClose()


    }
    const handleUpdateActivity = () => {
        setIsLoading(true)


        UpdateActivity(
            {
                id: item.id,
                title: title,
                description: description,
                userId: userId,
                start_date: startdate,
                end_date: endDate,
                status: 'test',
                activity_type_id: activityType,
                area_id: parcelId == null ? selectedSiteId : sectorId == null ? parcelId : sectorId,
                production_cycle_id: cycleId


            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)

        onClose()



    }
    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleAddActivity() : setOpenEditConfirmation(true), disabled: isEditing ? false : validForm() },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]


    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={width}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.add + ' ' + vocabulary.activityLabel : vocabulary.editLabel + ' ' + vocabulary.activityLabel}
            >
                {((isEditing == true && selectedArea != null && selectedAreaCycle && selectedAreaCycle.length > 0) || isEditing == false) ?
                    <form
                        ref={form}
                        onSubmit={handleSubmit(handleAddActivity)}
                        onError={errors => console.log(errors)}>

                        <StyledSelect
                            value={selectedSiteId}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.Area}
                            onChange={handleChange}
                            name='Area'
                            label={vocabulary.site}
                            data={sitesList}
                            styles={{ width: '100%' }}
                            valueField='id'
                            labelField='name'
                        />
                        <br />

                        {(parcelsList.length > 0 && selectedSiteId) &&
                            <StyledSelect
                                value={parcelId}
                                register={register}
                                errors={errors.ParcelId}
                                onChange={handleChange}
                                name='ParcelId'
                                styles={{ width: '100%' }}
                                label={vocabulary.parcel}
                                data={parcelsList}
                                valueField='id'
                                labelField='name'
                            />
                        }

                        <StyledSelect
                            value={cycleId}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.cycle}
                            styles={{ width: '100%' }}
                            onChange={(event) => {
                                setCycleId(event.target.value)
                                //getCultureByFamilyList(event.target.value, setCultureList)
                            }}
                            render={(item) => { return JSON.parse(item.prm_culture['name'])['name_' + currentLanguage] }}
                            name='cycle'
                            label={vocabulary.cycle}
                            data={selectedAreaCycle}
                            disabled={selectedAreaCycle == null || selectedAreaCycle.length === 0 ? true : false}

                            valueField='id'
                        />
                        <StyledInput
                            value={title}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.title}
                            onChange={(event) => { setTitle(event.target.value) }}
                            name='title'
                            type="text"
                            fullWidth
                            label={vocabulary.titleLabel}
                        />
                        <StyledInput
                            register={register}
                            name="description"
                            label={vocabulary.description}
                            value={description}
                            rules={{ required: true }}
                            onChange={(event) => {
                                setDescription(event.target.value)
                            }}
                            errors={errors.description}
                            maxRows={10}
                            fullWidth
                            multiline={true}

                        />
                        <DatePicker
                            label={vocabulary.startDate}
                            value={startdate}
                            onChange={setStartDate}
                            animateYearScrolling
                            disableFuture
                            style={{ margin: '8px', width: '100%' }}
                        />
                        <DatePicker
                            label={vocabulary.endDate}
                            value={endDate}
                            onChange={setEndDate}
                            animateYearScrolling
                            // minDate={moment()}
                            style={{ margin: '8px', width: '100%' }}
                        />
                        <StyledSelect
                            value={activityType}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.activityType}
                            onChange={(event) => {
                                setActivityType(event.target.value)
                            }}
                            styles={{ width: '100%' }}
                            name='activityType'
                            label={vocabulary.activityType}
                            fullWidth
                            data={activitiesTypeList}
                            valueField='id'
                            labelField='title'

                        />
                        <StyledModalFooter actions={actions} />

                    </form>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />}


            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.title}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateActivity();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )
}
export default AddActivityForm
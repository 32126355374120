/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars*/

import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { createFormulaItem, GetAreaDetails, UpdateFormula, GetPrmDataTypeByEquipmentId } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import { MathfieldComponent } from "react-mathlive";
// import MathJax from 'react-mathjax2'
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';

import StyledSelect from "../../ui/forms/StyledSelect";
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
// import { Controlled as CodeMirror } from 'react-codemirror2';
import { Button } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import './formuleStyle.css'
// import $ from 'jquery';
// require('codemirror/lib/codemirror.css');
// require('codemirror/theme/material.css');
// require('codemirror/theme/neat.css');
// require('codemirror/mode/xml/xml.js');
// require('codemirror/mode/javascript/javascript.js');

var dataTypeTable = [];

const FormulaAddForm = ({ item, onClose, open, isEditing, width, refetchFunc, equipments, sitesList, setIsLoading }) => {


    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const userType = useSelector(state => state.app.userrights)

    const [selectedEquipmentId, setSelectedEquipmentId] = useState(null)
    const [selectedDatatypeId, setSelectedDatatypeId] = useState(null)
    const [formulaName, setFormulaName] = useState(null)
    const [description, setDescription] = useState(null)
    const [latex, setLatex] = useState('');
    const [equipmentDataTypeList, setEquipmentDataTypeList] = useState(null)
    const [formulaType, setFormulaType] = useState('formula');
    const [algorythmeData, setAlgorythmeData] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null)
    const [parcelId, setParcelId] = useState(null)
    const [sectorsList, setSectorsList] = useState([])
    const [sectorId, setSectorId] = useState(null)
    const [parcelsList, setParcelsList] = useState([])
    const [selectedSiteId, setSelectedSiteId] = useState('')
    const [step, setStep] = useState(0)
    const [formulaStep, setFormulaStep] = useState(0)

    const [selectedDatatypeList, setSelectedDatatypeList] = useState(null)
    const [variable, setVariable] = useState(null)
    const [optionalSelectedDataTypeId, setOptionalSelectedDataTypeId] = useState(null)
    const [correspondenceTable, setCorrespondenceTable] = useState([])


    const onMathChange = (mathText) => {
        setLatex(mathText)
    }

    /* TO NOT REMOVE*/
    // // useEffect(() => {
    // //     document.addEventListener("click", function () {
    // //         if (document.getElementsByClassName("is-visible")[0]) {
    // //             document.getElementsByClassName("is-visible")[0].style.zIndex = '100000000'
    // //         }
    // //     });
    // // }, [])
    // $('.ML__virtual-keyboard-toggle').on('click', function () {
    //     // var newRow = $(this).parent().parent().parent('.sample').clone();
    //     // const keyboard=document.querySelectorAll('.apple,.is-visible,.ML__keyboard')
    //     // const child = document.getElementsByClassName('ML__keyboard')
    //     // const parent = document.getElementsByTagName("BODY")['']
    //     // $('#SampleModal .modal-body').html(newRow);
    //     // $('#SampleModal').modal();
    // });

    // // const handler = (e)=> {
    // //     // new_e = new e.constructor(e.type, e);
    // //     document.getElementsByTagName("BODY")[0].dispatchEvent(e);
    // // }

    // useEffect(() => {
    //     const child = document.getElementsByClassName('ML__keyboard')
    //     const parent = document.getElementsByTagName("BODY")
    //     // loop through list items and add listener to click event
    //     for (const item of parent) {
    //         item.addEventListener('click', clickHandler);
    //     }
    //     if (child[0] && parent[0]) {
    //         if (parent[0].contains(child[0])) {
    //             const keyboard = parent[0].removeChild(child[0])
    //             const newParent = document.getElementById('new-parent-keyboard')
    //             // keyboard.addEventListener("click",  function (e) {
    //             // })
    //             newParent.appendChild(keyboard.cloneNode(true))
    //             // keyboard.addEventListener("click",  function (e) {
    //             // })

    //         }


    //         // document.getElementById("source").addEventListener("click", handler);
    //         // keyboard.addEventListener("click", handler())


    //     }


    //     // const keyboard=document.getElementsByClassName("KL apple is-visible")// Get is-visible Element
    //     // keyboard[0].id="old-parent-keyboard";
    //     // if (document.getElementById('new-parent-keyboard')) document.getElementById('new-parent-keyboard').appendChild(document.getElementById('old-parent-keyboard'));
    //     // // document.addEventListener("click", function(){// Click Event Listener
    //     //     // const keyboard=document.getElementsByClassName("KL apple is-visible")// Get is-visible Element
    //     //     const keyboard=document.querySelectorAll('.apple,.is-visible,.keyboard-layer')
    //     //     const keyboard2=document.querySelectorAll('.apple,.is-visible,.keyboard-layer,.keyboard')

    //     // if(keyboard[0]?.id && document.getElementById('new-parent-keyboard').hasChildNodes()){ // If Exist

    //     //     keyboard[0].id="old-parent-keyboard";
    //     //     // var node = document.getElementById("old-parent-keyboard");
    //     //     // var list = document.getElementById("new-parent-keyboard");
    //     //     // list.insertBefore(node, list.childNodes[0]);

    //     //     // setTimeout(() => {
    //     //     //     if( document.getElementById('new-parent-keyboard'))
    //     //         // document.getElementById('new-parent-keyboard').appendChild(document.getElementById('old-parent-keyboard'));
    //     //     // }, 1000);

    //     //     // var newParent = document.getElementById('new-parent-keyboard');
    //     //     // var oldParent = document.getElementById('old-parent-keyboard');
    //     //     // while (oldParent.childNodes.length > 0) {

    //     //     //     newParent.appendChild(oldParent.childNodes[0]);
    //     //     // }
    //     // }
    //     //   });
    // })

    useEffect(() => {
        setTimeout(() => {
            if (document.querySelectorAll("[class^=makeStyles-modal-]")[0]) {
                document.querySelectorAll("[class^=makeStyles-modal-]")[0].style.alignItems = "flex-start"
                document.querySelectorAll("[class^=makeStyles-modal-]")[0].style.marginTop = "20px"
            }
        }, 500);
    }, [open])

    useEffect(() => {

        if (isEditing === false) {
            setFormulaName(null)
            setDescription(null)
            setLatex('')
            setSelectedSiteId('')
            setSelectedEquipmentId(null)
            setSelectedDatatypeId(null)
            setFormulaType('formula')
            setEquipmentDataTypeList(null)
            setStep(0)
            setFormulaStep(0)
            dataTypeTable = []
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (step == 1) {
            formatingDataSourceTable()
        }

    }, [selectedDatatypeList, step]); // N’exécute l’effet que si acceptedFiles a changé
    useEffect(() => {
        if (formulaStep == 1) {
            formatingDataSourceTable()
        }

    }, [selectedDatatypeList, formulaStep]); // N’exécute l’effet que si acceptedFiles a changé



    useEffect(() => {
        if (item) {
            dataTypeTable = []
            setFormulaName(item.name)
            setDescription(JSON.parse(item.description).description)
            setFormulaType(item.type)
            setAlgorythmeData(item.source ? JSON.parse(item.source).algorithm : null)
            setLatex(item.formula)
            setSelectedEquipmentId(item.formula_prm_data_type_user.length > 0 ? item.formula_prm_data_type_user[0].equipment_id : null)
            item.formula_prm_data_type_user.length > 0 && GetPrmDataTypeByEquipmentId(item.formula_prm_data_type_user[0].equipment_id, setEquipmentDataTypeList)
            item.formula_prm_data_type_user.length > 0 && setSelectedDatatypeList(item.formula_prm_data_type_user.map(el => el.prm_data_type))
        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? item.name + ' ' + vocabulary.updateSucc : vocabulary.formula + ' ' + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)
            }

            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }
    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea])
    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea])

    const changeStep = (step) => {
        setStep(step)
    }
    const changeFormulaStep = (step) => {
        setFormulaStep(step)
    }
    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSelectedSiteId(areasDetails.id)
                // setParcelId(null)


                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                setSectorsList(areasDetails["childs"])
                setSectorId(null)

                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;

            default:
                return ''

        }
    }

    const formatingDataSourceTable = () => {

        let columnsHeader = [

            { title: vocabulary.datatype, field: "dataType" },
            { title: 'varibale', field: "variable" },


        ]
        let columnsData = []

        selectedDatatypeList && selectedDatatypeList.map((rows, i) => {

            let item = {
                column: i,
                prm_data_type_identifier: rows.id,
                variable: variable,


            }

            dataTypeTable.push(item)
            columnsData.push({

                dataType: <StyledSelect
                    register={register}
                    errors={errors.typeId}
                    onChange={(event) => {
                        setOptionalSelectedDataTypeId(event.target.value)
                        item.prm_data_type_identifier = event.target.value
                        dataTypeTable[i].prm_data_type_identifier = event.target.value

                    }}
                    name='typeId'
                    render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
                    label={vocabulary.datatype}
                    autoWidth
                    data={selectedDatatypeList}
                    valueField='identifier'
                    labelField='name'

                />,

                variable:

                    <StyledInput
                        register={register}
                        errors={errors.variable}
                        name="variable"
                        label={'variable'}
                        type="text"
                        fullWidth
                        onChange={(event) => {
                            setVariable(event.target.value)
                            item.variable = event.target.value
                            dataTypeTable[i].variable = event.target.value


                        }}
                    />

            })
        })


        setCorrespondenceTable([columnsHeader, columnsData])

    }

    const saveFormula = () => {
        setIsLoading(true)
        if (isEditing === false) {
            createFormulaItem(
                {
                    area_id: selectedArea && selectedArea.id,
                    formula: latex,
                    equipment_id: selectedEquipmentId,
                    prm_data_type_id: selectedDatatypeId,
                    name: formulaName,
                    description: JSON.stringify({ 'description': description }),
                    params: null,
                    type: formulaType,
                    admin: userType == "Super" ? true : false,
                    data_type_list: JSON.stringify(dataTypeTable),
                    source: formulaType == "algorithme" ?
                        JSON.stringify({
                            "provider": "moleculer",
                            "algorithm": algorythmeData
                        }) : null

                }, r => operationCallBack(r)

            )
            onClose()
        } else {
            UpdateFormula(
                {
                    id: item.id,
                    area_id: selectedArea && selectedArea.id,

                    formula: latex,
                    equipment_id: selectedEquipmentId,
                    prm_data_type_id: selectedDatatypeId,
                    name: formulaName,
                    description: JSON.stringify({ 'description': description }),
                    params: null,
                    type: formulaType,
                    data_type_list: JSON.stringify(dataTypeTable),
                    admin: userType == "Super" ? true : false,
                    source: formulaType == "algorithme" ?
                        JSON.stringify({
                            "provider": "moleculer",
                            "algorithm": algorythmeData
                        }) : null

                }, r => operationCallBack(r)
            )
        }
        onClose()


    }
    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]

    const handleChangeArea = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };

    const handleChange = event => {
        setFormulaType(event.target.value);
    };

    return (
        <StyledModal
            open={open}
            onClose={onClose}
            width={width}
            modalContext={modalContext}
            title={isEditing === false ? vocabulary.add + ' ' + vocabulary.formula : vocabulary.editLabel + ' ' + vocabulary.formula}
        >

            <form
                ref={form}
                onSubmit={handleSubmit(saveFormula)}
            >

                {userType == 'Farmer' &&
                    <StyledSelect
                        value={selectedSiteId}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Area}
                        onChange={handleChangeArea}
                        name='Area'
                        label={vocabulary.site}
                        data={sitesList}
                        styles={{ width: '100%' }}
                        valueField='id'
                        labelField='name'
                    />}
                <br />

                {(parcelsList.length > 0 && selectedSiteId) &&
                    <StyledSelect
                        value={parcelId}
                        register={register}
                        errors={errors.ParcelId}
                        onChange={handleChangeArea}
                        name='ParcelId'
                        styles={{ width: '100%' }}
                        label={vocabulary.parcel}
                        data={parcelsList}
                        valueField='id'
                        labelField='name'
                    />
                }

                <StyledInput
                    register={register}
                    rules={{ required: true }}
                    errors={errors.FormulaName}
                    name="FormulaName"
                    label={vocabulary.FormulaName}
                    type="text"
                    fullWidth
                    value={formulaName}
                    onChange={(event) => {
                        setFormulaName(event.target.value)
                    }}

                />
                <StyledInput
                    label={vocabulary.description}
                    onChange={(event) => {
                        setDescription(event.target.value)
                    }}
                    register={register}
                    rules={{ required: true }}
                    errors={errors.description}
                    name="description"
                    maxRows={10}
                    fullWidth
                    multiline={true}
                    value={description}
                />

                {equipments &&
                    <StyledSelect
                        value={selectedEquipmentId}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.equipment}
                        onChange={(event) => {
                            setSelectedEquipmentId(event.target.value)
                            GetPrmDataTypeByEquipmentId(event.target.value, setEquipmentDataTypeList)
                        }}
                        name='equipment'
                        render={(item) => { return JSON.parse(item['name']).name_fr }}
                        label={vocabulary.equipmentLabel}
                        data={equipments}
                        valueField='id'
                        labelField='name'
                        styles={{ width: '100%' }}

                    />
                }

                {equipmentDataTypeList &&
                    <StyledAutoComplete
                        styles={{ width: '100%' }}
                        data={equipmentDataTypeList.prmdatatypes}
                        optionLabel={(option) => JSON.parse(option.name).name_ar ? JSON.parse(option.name)['name_' + currentLanguage] : JSON.parse(option.name).name_fr}
                        defaultValue={isEditing == true ? item && item.formula_prm_data_type_user.map(el => el.prm_data_type) : []}
                        onChange={(event, newValue) => {
                            setSelectedDatatypeId(newValue !== null ? newValue.map(el => el.id) : null)
                            setSelectedDatatypeList(newValue !== null ? newValue : null)

                        }}
                        multiple={true}
                        label={vocabulary.datatype}

                    />

                }

                {userType == "Super" &&
                    <RadioGroup row aria-label="gender" name="formulaType" value={formulaType} onChange={handleChange}>
                        <FormControlLabel value="formula" control={<Radio />} label={vocabulary.useFormulaLabel} />
                        <FormControlLabel value="algorithme" control={<Radio />} label={vocabulary.useAlgorithmLabel} />
                    </RadioGroup>}


                {formulaType == 'formula' ?
                    <div>
                        {
                            <>
                                {formulaStep == 0 ?
                                    <div id="mathfield" style={{ fontSize: '32px', padding: '8px', borderRadius: '8px', border: '1px solid rgba(0, 0, 0, .3)', boxShadow: '0 0 8px rgba(0, 0, 0, .2)' }}>
                                        <MathfieldComponent
                                            initialLatex={isEditing === true ? latex : ''}
                                            onChange={onMathChange}
                                            latex={latex}
                                            mathfieldConfig={{
                                                // defaultMode: "text",
                                                virtualKeyboardMode: "manual",
                                                virtualKeyboardLayout: "azerty",
                                                locale: currentLanguage,
                                            }}
                                        />
                                    </div>


                                    : <StyledMaterialTable
                                        title={vocabulary.addVariableToDataType}
                                        data={correspondenceTable[1]}
                                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                                        columns={correspondenceTable[0]}
                                        options={{
                                            actionsColumnIndex: -1,
                                            search: false,
                                            paging: false

                                        }}


                                    />
                                }
                            </>
                        }

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{ margin: '5px' }}
                                color="secondary"
                                onClick={() => changeFormulaStep(0)}
                                variant="contained"
                                disabled={formulaStep == 0 ? true : false}
                            >
                                <SkipPreviousIcon /> {vocabulary.previous}
                            </Button>

                            <Button
                                style={{ margin: '5px' }}
                                color="primary"
                                onClick={() => changeFormulaStep(1)}
                                variant="contained"
                                disabled={(selectedDatatypeList == null || latex == '' || formulaStep == 1) ? true : false}
                            >
                                <SkipNextIcon />  {vocabulary.next}

                            </Button>
                        </div>

                    </div>


                    : < div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step == 0 ? <></>
                            // <CodeMirror
                            //     value={algorythmeData}
                            //     options={{
                            //         mode: 'javascript',
                            //         theme: 'material',
                            //         lineNumbers: true
                            //     }}
                            //     onBeforeChange={(editor, data, value) => {
                            //         setAlgorythmeData(value);
                            //     }}
                            //     onChange={(editor, data, value) => {
                            //         setAlgorythmeData(value)
                            //     }}
                            // /> 
                            :
                            <StyledMaterialTable
                                title={vocabulary.addVariableToDataType}
                                data={correspondenceTable[1]}
                                localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                                columns={correspondenceTable[0]}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: false

                                }}


                            />
                        }
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{ margin: '5px' }}
                                color="secondary"
                                onClick={() => changeStep(0)}
                                variant="contained"
                                disabled={step == 0 ? true : false}
                            >
                                <SkipPreviousIcon /> {vocabulary.previous}
                            </Button>
                            <Button
                                style={{ margin: '5px' }}
                                color="primary"
                                onClick={() => changeStep(1)}
                                variant="contained"
                                disabled={(selectedDatatypeList == null || algorythmeData == null || formulaStep == 1) ? true : false}
                            >
                                <SkipNextIcon />  {vocabulary.next}

                            </Button>

                        </div>

                    </div>

                }

                <StyledModalFooter actions={actions} />


            </form>

        </StyledModal >
    )
}
export default FormulaAddForm
import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { StyledModal } from "../../../ui/layout";
import { StyledInput, StyledSubmitButton } from "../../../ui/forms";
import { vocabulary } from "../../Strings";

const MarkerCoords = ({ setAddNewStationMarker, setEditingStationMarker, openMarkerCoords, setOpenMarkerCoords }) => {
  // const [loading, setLoading] = useState(false)
  const [selectedPlaceLatitude, setSelectedPlaceLatitude] = useState("")
  const [selectedPlaceLongitude, setSelectedPlaceLongitude] = useState("")

  const AddCoordinateMarker = () => {
    setAddNewStationMarker(true)
    setEditingStationMarker({ latlng: { lat: selectedPlaceLatitude, lng: selectedPlaceLongitude } })
    setOpenMarkerCoords(false)
  }
  return (
    <>
      <StyledModal title={vocabulary.navigateTo} open={openMarkerCoords} closeFn={() => setOpenMarkerCoords(false)} styles={{ zIndex: 2100 }}>
        <Grid className="goToModalDialog" container direction="column" style={{ flexGrow: 1 }}>
          <div style={{ marginTop: 10 }}>
            <Grid item container direction="row">
              <StyledInput name='latitude' label={vocabulary.LatitudeLabel} value={selectedPlaceLatitude} onChange={(event) => { setSelectedPlaceLatitude(event.target.value) }} />
              <StyledInput name='longitude' label={vocabulary.LongitudeLabel} value={selectedPlaceLongitude} onChange={(event) => { setSelectedPlaceLongitude(event.target.value) }} />
            </Grid>
            <Grid item>
              <StyledSubmitButton disabled={selectedPlaceLatitude === "" || selectedPlaceLongitude === ""}
                onClick={() => AddCoordinateMarker()}
                label={vocabulary.confirmDeviceCoordinates} />
            </Grid>
          </div>
        </Grid>
      </StyledModal>
    </>

  )
}

export default MarkerCoords
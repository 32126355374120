import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { vocabulary } from '../../Strings';
import { StyledModal } from '../../../ui/layout';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import SencropConfigureForm from './forms/sencrop/SencropConfigureForm';
import WeenatConfigureForm from './forms/weenat/WeenatConfigureForm';
import BayerConfigureForm from './forms/bayer/BayerConfigureForm';
import { useSelector } from 'react-redux';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const INTEGRATIONS = [
    'SENCROP',
    'WEENAT',
    'BAYER'
]



export default function PartnerConfigureModal({
    isEditing,
    currentItem,
    open,
    setOpen
}) {




    const onClose = () => {
        setOpen(false);
    };


    const renderPartnerForm = (partner) => {

        if (partner == INTEGRATIONS[0]) {
            return (
                <SencropConfigureForm />
            )
        }
        if (partner == INTEGRATIONS[1]) {
            return (
                <WeenatConfigureForm />
            )
        }
        if (partner == INTEGRATIONS[2]) {
            return (
                <BayerConfigureForm />
            )
        }
    }


    const submit = () => {
        return
    };

    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: submit },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: onClose }
    ]


    return (
        <div>

            <StyledModal
                open={open}
                closeFn={onClose}
                withoutContext={true}
                title={isEditing === false ? vocabulary.partnerIntegrations : vocabulary.partnerIntegrations}
            >

                <>

                    {renderPartnerForm(currentItem?.partner_id)}


                    <StyledModalFooter actions={actions} />

                </>


            </StyledModal>
        </div>
    );
}

/* eslint-disable eqeqeq*/
/* eslint-disable no-unused-vars*/
/* eslint-disable no-mixed-operators */

import React, { useRef, useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { CreateExpensesRevenues, UpdateExpensesRevenues, GetItemByFinancialCategoryId, GetAreaDetails, GetFinancialCategories, GetProductionCycleByAreaId } from '../../globalsTools/network';
import { useSnackbar } from 'notistack';
import StyledSelect from "../../ui/forms/StyledSelect";
import StyledSeletctFinancialCategory from "../../ui/display/FinancialCategoryStyledSeletct";


import { DatePicker } from '@material-ui/pickers';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress'
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";
import AddFinancialResourceForm from '../FinancialResource/AddFinancialResourceForm'
import ItemByFinancialCategoryStyledSelect from "../../ui/display/ItemByFinancialCategoryStyledSelect";


const RevnuesAddForm = ({ isEditing, onClose, item, open, width, refetchFunc, setIsLoading, sitesList }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    // const userId = useSelector(state => state.app.userid)
    // const userType = useSelector(state => state.app.userrights)

    const [title, setTitle] = useState(null);
    const [price, setPrice] = useState(null);
    const [type, setType] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [financialItemId, setFinancialItemId] = useState(null);
    const [startdate, setStartDate] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [parcelsList, setParcelsList] = useState([])
    const [parcelId, setParcelId] = useState(null)
    const [selectedSiteId, setSelectedSiteId] = useState(null)
    const [sectorsList, setSectorsList] = useState([])
    const [sectorId, setSectorId] = useState(null)
    const [itemByFinancialCategoryList, setItemByFinancialCategoryList] = useState(null);
    const [financialGateroiesId, setFinancialGateroiesId] = useState(null);
    const [selectedAreaCycle, setSelectedAreaCycle] = useState(null)
    const [cycleId, setCycleId] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [financialCategorieList, setFinancialCategorieList] = useState(null)


    const ItemTypeList = [
        {
            title: vocabulary.expenses,
            id: 'expenses'
        }
        , {
            title: vocabulary.revenues,
            id: 'revenues'
        }
    ]
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)

        }
    }, [selectedArea])
    useEffect(() => {
        if (financialGateroiesId != null) {
            GetItemByFinancialCategoryId(financialGateroiesId, setItemByFinancialCategoryList)

        }

    }, [financialGateroiesId])

    useEffect(() => {
        GetFinancialCategories(setFinancialCategorieList)

    }, [])
    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
        GetProductionCycleByAreaId(event.target.value, setSelectedAreaCycle)
    };

    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setSelectedSiteId(areasDetails.id)
                // setParcelId(null)


                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                setSectorsList(areasDetails["childs"])
                setSectorId(null)

                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;

            default:
                return ''

        }
    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? item.note + "  " + vocabulary.updateSucc : vocabulary.item + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)


            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)

            }
        }
    }
    useEffect(() => {

        if (isEditing === false) {
            setTitle(null)
            setType(null)
            setFinancialGateroiesId(null)
            setPrice(null)
            setCycleId(null)
            setSelectedAreaCycle(null)
            setSelectedSiteId(null)
            setParcelId(null)
            setStartDate(null)
            setQuantity(null)
            setFinancialItemId(null)
            setItemByFinancialCategoryList(null)
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (item) {
            GetAreaDetails(item.production_cycle.area.parent !== null ? item.production_cycle.area.parent.id : item.production_cycle.area_id, setSelectedArea)
            GetProductionCycleByAreaId(item.production_cycle.area.parent !== null ? item.production_cycle.area.id : item.production_cycle.area_id, setSelectedAreaCycle)
            setSelectedSiteId(item.production_cycle.area.parent !== null ? item.production_cycle.area.parent.id : item.production_cycle.area_id)
            setParcelId(item.production_cycle.area.parent !== null ? item.area_id : null)
            setTitle(item.note)
            setType(item.type)
            setPrice(item.total)
            setFinancialGateroiesId(item.item.financial_category_id)
            setQuantity(item.quantity)
            setStartDate(item.date)
            setCycleId(item.production_cycle_id)
            setFinancialItemId(item.item.id)
            GetItemByFinancialCategoryId(item.item.financial_category_id, setItemByFinancialCategoryList)


        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    const handleAddRevenue = () => {
        setIsLoading(true)
        CreateExpensesRevenues(
            {
                title: title,
                total: price,
                type: type,
                quantity: quantity,
                item_id: financialItemId,
                date: startdate,
                production_cycle_id: cycleId


            }, r => operationCallBack(r)
        )
        onClose()



    }
    const handleUpdateRevenues = () => {
        setIsLoading(true)
        UpdateExpensesRevenues(
            {
                id: item.id,
                title: title,
                total: price,
                type: type,
                financial_category_id: financialGateroiesId,
                production_cycle_id: cycleId,
                date: startdate,
                item_id: financialItemId,
                quantity: quantity,




            }, r => operationCallBack(r)
        )
        onClose()




    }
    const validForm = () => {
        if (!selectedSiteId || !title || !price || !quantity || !type || !cycleId || !financialGateroiesId || !startdate || !financialItemId) {
            return true
        }
        else return false

    }
    // useEffect(() => {
    //     GetProductionCycleByUserId(setProductionCycleList)
    // }, [])

    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleAddRevenue() : setOpenEditConfirmation(true), disabled: isEditing == false ? validForm() : false },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={width}
                title={isEditing === false ? vocabulary.add + ' ' + vocabulary.expenses + ' / ' + vocabulary.revenues : vocabulary.editLabel + ' ' + vocabulary.expenses + ' / ' + vocabulary.revenues}
            >
                {
                    ((isEditing == true && selectedArea != null && selectedAreaCycle && selectedAreaCycle.length > 0 && itemByFinancialCategoryList && itemByFinancialCategoryList.data.length > 0) || isEditing == false) ?
                        <form
                            onSubmit={handleSubmit(handleAddRevenue)}
                            ref={form}
                        >
                            <StyledSelect
                                value={selectedSiteId}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.Area}
                                onChange={handleChange}
                                name='Area'
                                label={vocabulary.site}
                                data={sitesList}
                                styles={{ width: '100%' }}
                                valueField='id'
                                labelField='name'
                            />
                            <br />

                            {(parcelsList.length > 0 && selectedSiteId) &&
                                <StyledSelect
                                    value={parcelId}
                                    register={register}
                                    errors={errors.ParcelId}
                                    onChange={handleChange}
                                    name='ParcelId'
                                    styles={{ width: '100%' }}
                                    label={vocabulary.parcel}
                                    data={parcelsList}
                                    valueField='id'
                                    labelField='name'
                                />
                            }

                            <StyledSelect
                                value={cycleId}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.cycle}
                                styles={{ width: '100%' }}
                                onChange={(event) => {
                                    setCycleId(event.target.value)
                                }}
                                render={(item) => { return JSON.parse(item.prm_culture['name'])['name_' + currentLanguage] }}
                                name='cycle'
                                label={vocabulary.cycle}
                                data={selectedAreaCycle}
                                disabled={(selectedAreaCycle && selectedAreaCycle.length === 0 || selectedAreaCycle == null) ? true : false}

                                valueField='id'
                            />
                            <StyledInput
                                value={title}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.title}
                                onChange={(event) => { setTitle(event.target.value) }}
                                name='title'
                                type="text"
                                fullWidth
                                label={vocabulary.titleLabel}
                            />

                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.price}
                                name="price"
                                label={vocabulary.price}
                                onChange={(event) => { setPrice(event.target.value) }}
                                value={price}
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 999999999 } }}
                                fullWidth

                            />
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                errors={errors.quantity}
                                name="quantity"
                                label={vocabulary.quantity}
                                onChange={(event) => { setQuantity(event.target.value) }}
                                value={quantity}
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 999999999 } }}
                                fullWidth

                            />
                            <StyledSelect
                                value={type}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.type}
                                onChange={(event) => {
                                    setType(event.target.value)
                                }}
                                name='type'
                                label={vocabulary.type}
                                fullWidth
                                data={ItemTypeList}
                                valueField='id'
                                labelField='title'
                                styles={{ width: '100%' }}
                            />

                            {
                                financialCategorieList && financialCategorieList?.data?.length > 0 &&
                                <StyledSeletctFinancialCategory
                                    value={financialGateroiesId}
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.financialGateroiesId}
                                    onChange={(event) => {
                                        setFinancialGateroiesId(event.target.value)
                                    }}
                                    name='financialGateroiesId'
                                    label={vocabulary.financialCategoryLabel}
                                    fullWidth
                                    data={financialCategorieList && financialCategorieList.data}
                                    disabled={financialCategorieList == null ? true : false}
                                    refetchFunc={() => GetFinancialCategories(setFinancialCategorieList)}


                                    valueField='id'
                                    labelField='title'

                                />
                            }

                            <ItemByFinancialCategoryStyledSelect
                                value={financialItemId}
                                register={register}
                                rules={{ required: true }}
                                errors={errors.financialItemId}
                                onChange={(event) => {
                                    setFinancialItemId(event.target.value)
                                }}
                                name='financialItemId'
                                label={vocabulary.item}
                                fullWidth
                                disabled={(itemByFinancialCategoryList && itemByFinancialCategoryList.data.length === 0 || itemByFinancialCategoryList == null) ? true : false}
                                data={itemByFinancialCategoryList ? itemByFinancialCategoryList.data : []}
                                valueField='id'
                                labelField='title'
                                styles={{ width: '100%' }}
                                refetchFunc={() => GetItemByFinancialCategoryId(financialGateroiesId, setItemByFinancialCategoryList)}
                                financialGateroiesId={financialGateroiesId}



                            />



                            <DatePicker
                                label={vocabulary.date}
                                value={startdate}
                                onChange={setStartDate}
                                animateYearScrolling
                                disableFuture
                                format={currentLanguage == 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                                style={{ margin: '8px', width: '100%' }}
                            />

                            <StyledModalFooter actions={actions} />

                        </form>
                        : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }

            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.note}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateRevenues();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}

        </>
    )

}
export default RevnuesAddForm

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { vocabulary } from '../../Strings';

// import compose from 'recompose/compose';
// import { styles } from '../../globalStyle';
// import { withStyles } from '@material-ui/core/styles';

// import {loadCitiesByIdCountries } from '../../../actions';

import { TablePaginationActionsWrapped } from '../../Advices/tablePaginationActions'
import { FormControl } from '@material-ui/core/';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


const DataTable = (props) => {

    const dispatch = useDispatch()

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [item, setItem] = useState(null)
    const [search, setSearch] = useState('')
    const [center, setCenter] = useState('')
    const [selectedItem, setselectedItem] = useState({})
    // const [oldActiveTab, setOldActiveTab] = useState(props.ActiveTab - 1)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const test = (id_country, id_governate, id_delegation) => {
        props.setparams(id_country, id_governate, id_delegation)
    }
    // const CleanSearch = () => {
    //     const oldActiveTab = props.ActiveTab - 1
    //     return (
    //         props.activeTab !== oldActiveTab ?
    //             setSearch('') : null
        
    //     )}
    useEffect(() => {


        if (item !== null) {

           //  dispatch(loadCitiesByIdCountries(item.id_country))
            props.areaParams(item)
            test(item.identifier, item.identifier, item.identifier)
            setCenter(JSON.parse(item.center))
            setselectedItem(item)
        }
    }, [item, dispatch,props]); // N’exécute l’effet que si count a changé

    const handleChangePage = (event, page) => {
        setPage(page)
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
    };
    return (
        <>
            <ValidatorForm style={{ width: '100%' }}>
                <FormControl style={{ margin: '8px', width: '99%' }}>
                    <TextValidator
                        autoFocus
                        margin="dense"
                        name="search"
                        label={vocabulary.search}
                        type="search"
                        fullWidth
                        value={search}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        validators={['required']}
                        errorMessages={[vocabulary.required]}
                    // helperText={vocabulary.required}
                    />
                </FormControl>
            </ValidatorForm>
            <Table style={{ width: '100%' }} >
                <TableHead>
                    <TableRow>
                        <TableCell>{vocabulary.name_en}</TableCell>
                        <TableCell>{vocabulary.name_fr}</TableCell>
                        <TableCell>{vocabulary.name_ar} </TableCell>


                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.cities && props.cities.length > 0 ? props.cities && props.cities
                        .filter(
                            el =>
                            JSON.parse(el.name)["name_" + currentLanguage]
                                    .toLowerCase() // lower case name 
                                    .indexOf(search.toLowerCase()) !== -1)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row => {
                            return (
                                <TableRow key={row.id}
                                    style={{ cursor: 'pointer' }}
                                    selected={row.id === selectedItem.id ? true : false}

                                    onClick={() => setItem(row)
                                        // setDelegationStatus(true)

                                    }
                                >
                                     <TableCell>{JSON.parse(row.name).name_en}</TableCell>
                                    <TableCell>{JSON.parse(row.name).name_fr}</TableCell>
                                    <TableCell>{JSON.parse(row.name).name_ar}</TableCell>


                                </TableRow>
                            )
                        })
                        :
                        null
                    }

                </TableBody>
                <TableFooter >
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            colSpan={6}
                            count={props.cities && props.cities.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    )
}
export default DataTable;
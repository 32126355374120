/* eslint-disable react-hooks/exhaustive-deps */

import { Checkbox, FormControl, Grid, Input, InputLabel, ListItemText, makeStyles, MenuItem, Select, withStyles } from "@material-ui/core";
import { Button } from "bootstrap";
import React, { useState, useEffect } from "react"

import { GetAreaDetails, GetAreasListing, GetLastRealTimeCaptures, getDeployedDeviceByAreaId, GetChildsByParentId } from "../../globalsTools/network";
import { StyledSelect } from "../../ui/forms";
import { styles } from "../globalStyle";
import { vocabulary } from "../Strings";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));


 

const AreaFilteringInputs = ({
    initialAreaId, handleChangeFn, deployedDevice,
    siteName, siteSetter, parcelSetter, sectorSetter,
    classes, areaChangeFn, reset = false, setLoading,
    embeddedInForm = true, selectedAreaName, lastRealtimeData,
    deployedDeviceByAreaList, setReset,
    multiplePluviot = false, handleParcelsChange, selectedParcles
}) => {// TODO use prope-types to replace the propes ecrasment
    // Those for this components
    const [sitesList, setSitesList] = useState(null)
    const [parcelsList, setParcelsList] = useState([])
    const [sectorsList, setSectorsList] = useState([])
    const [selectedArea, setSelectedArea] = useState(null)
    // Tose for Realtime data // TODO why ?????????
    const [siteId, setSiteId] = useState('')
    const [parcelId, setParcelId] = useState('')
    const [sectorId, setSectorId] = useState('')


    const classesStyled = useStyles();



    handleChangeFn = handleChangeFn.bind(this)
    // When selecting an area
    const handleChange = event => {
        if (event.target.value) {
            setLoading && setLoading(true)
            GetAreaDetails(event.target.value, data => { setSelectedArea(data); setLoading && setLoading(false) })
            deployedDeviceByAreaList && getDeployedDeviceByAreaId(event.target.value, deployedDeviceByAreaList)
            lastRealtimeData && GetLastRealTimeCaptures(event.target.value, data => { lastRealtimeData(data); setLoading(false) })

        }
    };
    // Used when selecting the reset bouton and from params
    // TODO check monitoring page 
    const resetSelection = () => {
        setSelectedArea(null)
        setParcelsList([])
        setSectorsList([])
        setSiteId('')
        setParcelId(null)
        setSectorId(null)
        if (siteSetter) { siteSetter('+') }
        if (parcelSetter) { parcelSetter('+') }
        if (sectorSetter) { sectorSetter('+') }
        handleChangeFn(null)

    }
    // Case by case fill the filter
    const categorizeAreas = (areasDetails) => {
        if (areasDetails) {
            switch (areasDetails.type) {
                case 'site':
                    setParcelsList(areasDetails["childs"])
                    setSiteId(areasDetails.id)
                    if (siteName) { siteName(areasDetails.name) }
                    if (siteSetter) { siteSetter(areasDetails.id) }
                    if (parcelSetter) { parcelSetter('+') }
                    if (sectorSetter) { sectorSetter('+') }
                    setParcelId('')

                    setSectorId('')

                    break;
                case 'parcel':
                    setSiteId(areasDetails.parent_id)
                    setParcelId(areasDetails.id)
                    GetChildsByParentId(areasDetails.parent_id, setParcelsList)
                    setSectorsList(areasDetails["childs"])
                    setSectorId('')
                    if (siteName) { siteName(areasDetails.name) }
                    if (parcelSetter) { parcelSetter(areasDetails.id) }
                    if (sectorSetter) { sectorSetter('+') }

                    break;
                case 'sector':
                    setSectorId(areasDetails.id)
                    if (siteName) { siteName(areasDetails.name) }
                    if (sectorSetter) { sectorSetter(areasDetails.id) }
                    break;
                default:
                    return ''


            }
        }
    }
    // Get area list on startup
    useEffect(() => {
        GetAreasListing(setSitesList)
    }, [])

    // Get area by id details
    useEffect(() => {
        if (initialAreaId) {
            GetAreaDetails(initialAreaId, setSelectedArea)
        }
    }, [initialAreaId])

    // TODO why ????????
    useEffect(() => {
        if (reset) {
            resetSelection();
            setReset && setReset(!reset);
        }
    }, [reset])

    // Handle change selected area
    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
            handleChangeFn(selectedArea.id)
            if (selectedAreaName) {
                selectedAreaName && selectedAreaName(selectedArea.name)

            }
            if (areaChangeFn) {
                areaChangeFn(selectedArea)
            }
            if (deployedDevice) {
                deployedDevice(selectedArea.deployed_devices)
            }
        }
    }, [selectedArea])

    return (
        <>

            <Grid container style={{ display: 'flex', flexDirection: 'row', flexWrap: "no-wrap", justifyContent: 'space-between', alignItems: 'center' }} spacing={2}>
                <Grid item className={classes.flexAndGrow}>
                    <StyledSelect
                        value={siteId}
                        rules={{ required: true }}
                        onChange={handleChange}
                        name='Area'
                        label={vocabulary.site}
                        autoWidth
                        disabled={sitesList === null ? true : false}
                        data={sitesList}
                        valueField='id'
                        labelField='name'
                        emptyDataMsg={vocabulary.noAreaCreatedLabel}
                    />
                </Grid>

                {parcelsList.length > 0 ?
                    <Grid item className={classes.flexAndGrow}>
                        <StyledSelect
                            value={parcelId}
                            onChange={handleChange}
                            name='ParcelId'
                            label={vocabulary.parcel}
                            autoWidth
                            disabled={parcelsList && (parcelsList.length === 0 ? true : undefined)}
                            data={parcelsList && parcelsList}
                            valueField='id'
                            labelField='name'
                            emptyDataMsg={vocabulary.noParcelCreatedLabel}
                        />
                    </Grid> : null}

                {sectorsList.length > 0 && parcelId ?
                    <Grid item className={classes.flexAndGrow}>
                        <StyledSelect
                            value={sectorId}
                            onChange={handleChange}
                            name='sectorId'
                            label={vocabulary.sector}
                            autoWidth
                            disabled={sectorsList && (sectorsList.length === 0 ? true : undefined)}
                            data={sectorsList && sectorsList}
                            valueField='id'
                            labelField='name'
                            emptyDataMsg={vocabulary.noSectorCreatedLabel}
                        />
                    </Grid> : null}
                {selectedArea && !embeddedInForm &&
                    <Grid item>
                        <Button style={{ marginTop: '10px' }} color="secondary" variant="contained" onClick={() => { resetSelection() }} >{vocabulary.resetSelection}</Button>
                    </Grid>
                }
                {
                    sitesList && sitesList?.length > 0 && siteId && multiplePluviot &&

                    <FormControl className={classesStyled.formControl}>
                        <InputLabel id="demo-mutiple-checkbox-label">{vocabulary.parcels}</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={selectedParcles}
                            onChange={handleParcelsChange}
                            input={<Input />}
                            renderValue={(selected) =>
                                selected.map((obj) => obj.name).join(", ")
                            }
                          
                        >
                            {
                                parcelsList.map((item) => (
                                    parcelId != item.id &&
                                        <MenuItem key={item.name} value={{ id: item.id, name: item.name }}   >
                                            <Checkbox checked={selectedParcles.indexOf(item.name) > -1} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
            </Grid>

        </>
    )

}

export default withStyles(styles)(AreaFilteringInputs);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { GetBayerAuthorizationUrl } from '../../../../globalsTools/network';

const BayerAuthPopup = () => {
    const user_id = useSelector((state) => state.app.userid);

    const [authUrl, setAuthUrl] = useState(null);


    useEffect(() => {
        const fetchAuthUrl = async () => {

            const response = await GetBayerAuthorizationUrl();
            setAuthUrl(response.data); // Set the fetched authorization URL


        };

        fetchAuthUrl();

    }, [user_id]);




    return (
        <div>

            {
                authUrl && (
                    <iframe src={authUrl} frameBorder="0" height="600" width="400" />
                )
            }

        </div>
    );
};

export default BayerAuthPopup;







import React from "react";
import { Button } from "@material-ui/core";

export default ({ label,styles, type='reset',submit = true, disabled = false, onClick = undefined }) => {

  return (
    <div  style= { styles? styles:{
      
        display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      margin: 20
    }}>
      <Button
        type={type}
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={onClick ? () => onClick() : undefined}
      >
        {label}
      </Button>
    </div>
  )

}
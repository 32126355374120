/* eslint-disable eqeqeq*/

import SoilMoisture from '../../../assets/5TE.png';
import DayLight from '../../../assets/brightness.png';
import SoilElecConductivity from '../../../assets/electrode.png';
import SoilTemperature from '../../../assets/plantTemp.png';
import Temperature from '../../../assets/Temp.png';
import SoilWaterContent from '../../../assets/water.png';
import SoilELecPermittivity from '../../../assets/permetivity.png'
import SoilSalinity from '../../../assets/salinity.png'
import Humidity from '../../../assets/Humidity.png'
import moment from 'moment';
import { vocabulary } from '../../Strings';

const SENSOR_DHT21 = "DHT21"

const iconObject = {
    "temperature": Temperature, "soilWaterContent": SoilWaterContent, "soilElecConductivity": SoilElecConductivity, "soilTemperature": SoilTemperature,
    "dayLight": DayLight, "soilMoisture": SoilMoisture, "soilELecPermittivity": SoilELecPermittivity, "soilSalinity": SoilSalinity, "relativeHumidity": Humidity
}

export const windDirection = (angle) => {
    if (angle < 22)
        return {
            lettre: "N",
            name_ar: "شمال",
            name_en: "North",
            name_fr: "Nord"
        }
    else if (angle < 67)
        return {
            lettre: "NE",
            name_ar: "شمال شرق",
            name_en: "North East",
            name_fr: "Nord Est"
        }
    else if (angle < 112)
        return {
            lettre: "E",
            name_ar: "شرق",
            name_en: "East",
            name_fr: "Est"
        }
    else if (angle < 157)
        return {
            lettre: "SE",
            name_ar: "جنوب شرق",
            name_en: "South East",
            name_fr: "Sud Est"
        }
    else if (angle < 212)
        return {
            lettre: "S",
            name_ar: "جنوب",
            name_en: "South",
            name_fr: "Sud"
        }
    else if (angle < 247)
        return {
            lettre: "SW",
            name_ar: "جنوب غرب",
            name_en: "South West",
            name_fr: "Sud Ouest"
        }
    else if (angle < 292)
        return {
            lettre: "W",
            name_ar: "غرب",
            name_en: "West",
            name_fr: "Ouest"
        }
    else if (angle < 337)
        return {
            lettre: "NW",
            name_ar: "شمال غرب",
            name_en: "North West",
            name_fr: "Nord Ouest"
        }
    else
        return {
            lettre: "N",
            name_ar: "شمال",
            name_en: "North",
            name_fr: "Nord"
        }
}

export const windSpeed = (speed) => {
    if (speed < 2)
        return {
            name_ar: "هادئ",
            name_en: "Calm",
            name_fr: "Calme"
        }
    else if (speed >= 2 && speed < 4)
        return {
            name_ar: "هادئ",
            name_en: "Light Air",
            name_fr: "Air léger"
        }

    else if (speed >= 4 && speed < 8)
        return {
            name_ar: "نسيم عليل",
            name_en: "Light Breeze",
            name_fr: "Air léger"
        }
    else if (speed >= 8 && speed < 13)
        return {
            name_ar: "نسيم لطيف",
            name_en: "Gentle Breeze",
            name_fr: "Petite brise"
        }

    else if (speed >= 13 && speed < 18)
        return {
            name_ar: "مناخ معتدل ",
            name_en: "Moderate climate",
            name_fr: "Climat tempéré "
        }
    else if (speed >= 18 && speed < 25)

        return {
            name_ar: "هواء نقي ",
            name_en: "Fresh Breeze",
            name_fr: " Brise fraîche"
        }

    else if (speed >= 25 && speed < 31)
        return {
            name_ar: "نسيم قوي",
            name_en: "Strong Breeze",
            name_fr: " Brise forte"
        }
    else if (speed >= 31 && speed < 39)
        return {
            name_ar: "بالقرب من العاصفة",
            name_en: "Near Gale",
            name_fr: "Près de Gale"
        }
    else
        return {
            name_ar: "هادئ",
            name_en: "Calm",
            name_fr: "Calme"
        }
}

export const getPercent = (value, sensorType, sensorName) => {
    switch (sensorType) {
        case 'temperature':
            // : -40℃ ~ +80℃
            if (sensorName == SENSOR_DHT21) {
                return Number(value) * 100 / 120
            }
            else {
                // sensorName=="DS18B20"
                // --55 ->125
                return Number(value) * 100 / 180
            }

        case 'soilELectricalPermittivity':
            return Number(value) * 100 / 80

        case 'soilElectricalConductivity':
            return Number(value).toFixed(3)
        // return Number(value) > 1000 ? (Math.abs(Number(value)) * 100 / 23 / 1000).toFixed(3) : (Math.abs(Number(value)) * 100 / 23).toFixed(3)

        case 'soilTemperature':
            return Math.abs(Number(value)) * 100 / 90

        case 'dayLight':
            return Math.abs(Number(value)) * 100 / 1023

        case 'soilMoisture': 
        case 'volumicWaterContent':
            return Number(value).toFixed(3)

        case 'SoilWaterContent':
            return Number(value).toFixed(3) + " %";


        case 'soilSalinity':

            if (value.includes("NaN")) {
                return 0;
            }
            else {
                return Number(value) * 100 / 50
            }


        case 'relativeHumidity':
            return Number(value)
        default: 

    }
}

/**
* ISOLATED Function to transform Realtime Raw Data to Object
*/
export const RawToDataObject = (result) => {
    let dataKeys = []
    let realTimeObject = {}
    if (result) {
        for (let i = 0; i < Object.keys(result.Log.Data).length; i++) {
            let key = Object.keys(result.Log.Data)[i]
            if (key.charAt(0) !== "U") {
                dataKeys.push(key)
            }
        }
        for (let i = 0; i < dataKeys.length; i++) {
            if (dataKeys[i].charAt(0) !== "U") {
                realTimeObject[dataKeys[i] + result.Log.Pin] = {
                    value: result.Log.Data[dataKeys[i]], unit: result.Log.Data["U" + dataKeys[i]], pin: result.Log.Pin,
                    icon: iconObject[dataKeys[i]], keyWithoutPin: dataKeys[i], name: result.Log.Sensor, captureTime: result.CaptureTime
                }
            }
        }
    }
    return realTimeObject
}

export const affectRTValuesToSensors = (rawData, realTNodesArray) => {
    let RTObject = RawToDataObject(rawData)
    let realTimeNodeArrayCopy = [...realTNodesArray]

    if (realTimeNodeArrayCopy.findIndex(i => i.id === RTObject.DeviceID) === -1) {
        // setRealTimeNodeArray([...realTimeNodeArray,
        //   { id: result.DeviceID, data: RTObject }])
        realTimeNodeArrayCopy.push({ id: RTObject.DeviceID, data: RTObject })
    } else {
        let index = realTimeNodeArrayCopy.findIndex(i => i.id === RTObject.DeviceID)

        let updatedDataObj = realTimeNodeArrayCopy[index].data

        for (let j = 0; j < Object.keys(RTObject).length; j++) {
            let key = Object.keys(RTObject)[j]
            updatedDataObj[key] = RTObject[key]
        }
        realTimeNodeArrayCopy[index] = { id: RTObject.DeviceID, data: updatedDataObj }
    }

    return realTimeNodeArrayCopy;

}

export const affectActuators = (result, rTActionNodesArray) => {
    let realTimeObject = {}

    if (result) {
        for (let i = 0; i < result.Log.length; i++) {
            realTimeObject[result.Log[i].Port] = {
                "Status": result.Log[i].Status,
                "Attachment": result.Log[i].Attachment ? result.Log[i].Attachment + "( Port" + result.Log[i].Port + ")" : "Port" + result.Log[i].Port
            }
        }
    }

    let realTimeActionArrayCopy = [...rTActionNodesArray]

    if (realTimeActionArrayCopy.findIndex(i => i.id === result.DeviceID) === -1) {
        realTimeActionArrayCopy.push({ id: result.DeviceID, data: realTimeObject })

    } else {
        let index = realTimeActionArrayCopy.findIndex(i => i.id === result.DeviceID)
        let updatedDataObj = realTimeActionArrayCopy[index].data

        for (let j = 0; j < Object.keys(realTimeObject).length; j++) {
            let key = Object.keys(realTimeObject)[j]
            updatedDataObj[key] = realTimeObject[key]
        }
        realTimeActionArrayCopy[index] = { id: result.DeviceID, data: updatedDataObj }
    }

    return realTimeActionArrayCopy
}


export const calibrate10HSandEC5Value = (value, name) => {

    let result = 0

    switch (name) {
        case "10HS":
            result = 5.7 * Math.pow(10, -4) * value - 0.376
            break;
        case "EC5":
            result = 8.5 * Math.pow(10, -4) * value - 0.481
            break;

        default:
            break;
    }
    // let result = 0.00000000297 * Math.pow(parseFloat(value).toFixed(2), 3) - 0.00000737 * Math.pow(parseFloat(value).toFixed(2), 2) + 0.00669 * parseInt(value).toFixed(2) - 1.92;

    if (result < 0.01)
        result = 0
    // if (result > 0.57)
    //   result = 0.57

    return result * 100;

}

export const calibrate5TE = (value, dataType) => {
    if (dataType === "soilWaterContent") {
        var A = 4.3 * Math.pow(10, -6) * Math.pow(value, 3);
        var B = 5.5 * Math.pow(10, -4) * Math.pow(value, 2);
        var C = 2.92 * Math.pow(10, -2) * value;
        value = A - B + C - (5.3 * Math.pow(10, -2));
        value = String(value.toFixed(4) * 100)
    }
    return value

}

export const unixTimeToDay = (unixtime, currentLanguage) => {
    let returnValue = moment.unix(unixtime).locale(currentLanguage).calendar().split(" ")[0]
    switch (returnValue) {
        case "Today":
            return vocabulary.today

        case "Yesterday":
            return vocabulary.Yesterday

        default:
            return moment.unix(unixtime).locale(currentLanguage).format("MM-DD")
    }
}

export const formatWeatherData = (weatherData, currentLanguage) => {

    return {
        apparentTemperature: {
            valueMin: weatherData && JSON.parse(weatherData.data).daily.apparentTemperatureMin,
            valueMax: weatherData && JSON.parse(weatherData.data).daily.apparentTemperatureMax,
        },
        temperature: {
            valueMin: weatherData && JSON.parse(weatherData.data).daily.temperatureMin,
            valueMax: weatherData && JSON.parse(weatherData.data).daily.temperatureMax,
        },
        icon: {
            image: "https://darksky.net/images/weather-icons/" + JSON.parse(weatherData.data).daily.icon + ".png",
            value: weatherData && JSON.parse(weatherData.data).daily.summary,
        },
        windSpeed: {
            value: weatherData && JSON.parse(weatherData.data).daily.windSpeed + " " + windSpeed(weatherData && JSON.parse(weatherData.data).daily.windSpeed)["name_" + currentLanguage],
        },
        windBearing: {
            value: windDirection(weatherData && JSON.parse(weatherData.data).daily.windBearing)["name_" + currentLanguage],
        },
        humidity: {
            value: weatherData && (JSON.parse(weatherData.data).daily.relativeHumidity * 100).toFixed(2),
        },
        precipIntensity: {
            value: weatherData && JSON.parse(weatherData.data).daily.precipIntensity,
        },
        cloudCover: {
            value: weatherData && (JSON.parse(weatherData.data).daily.cloudCover * 100).toFixed(2),
        },
        dewPoint: {
            value: weatherData && JSON.parse(weatherData.data).daily.dewPoint,

        }
    }
};

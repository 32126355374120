/* eslint-disable eqeqeq*/

import { GetAreaDetails, createDataSource, updateDataSource } from "../../globalsTools/network";
import React, { useContext, useEffect, useRef, useState } from 'react';

import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import { StyledModal } from '../../ui/layout'
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import StyledSelect from "../../ui/forms/StyledSelect";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { vocabulary } from '../Strings';

const AddSourceForm = ({ open, onClose, item, siteList, isEditing, refetchFunc, dataTypeList, sourceList }) => {

    const form = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [area_id, setArea_id] = useState(null);
    const [selectedArea, setSelectedArea] = useState([]);
    const [parcelsList, setParcelsList] = useState([])
    const [sectorsList, setSectorsList] = useState([])
    const [parcelId, setParcelId] = useState(null)
    const [sectorId, setSectorId] = useState('')
    const [selectedDataTypeId, setSelectedDataTypeId] = useState(null)
    const [sourceId, setSourceId] = useState(null)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? vocabulary.updateSucc : vocabulary.addedSucc, 'success')
                refetchFunc()
            }

            else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    useEffect(() => {

        if (isEditing === false) {
            setArea_id(null)
            setSelectedArea(null)
            setSourceId(null)
            setSelectedDataTypeId(null)
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (item) {
            GetAreaDetails(item.area.parent !== null ? item.area.parent.id : item.area_id, setSelectedArea)

            if (item.area.parent_id !== null) {
                setArea_id(item.area.parent_id)
                setParcelId(item.area.id)
            } else
                setArea_id(item.area.id)
            setSelectedDataTypeId(item.prm_data_type.id)
            setSourceId(item.data_source_id)


        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea]) // N’exécute l’effet que si selectedArea a changé 

    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };

    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setArea_id(areasDetails.id)
                // setParcelId(null)

                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                setSectorsList(areasDetails["childs"])
                setSectorId(null)

                break;
            case 'sector':
                setSectorId(areasDetails.id)
                break;

            default:
                return ''

        }
    }
    const handleAddSource = (data) => {

        if (isEditing === false) {
            createDataSource(
                {
                    area_id: selectedArea.id,
                    prm_data_type_id: selectedDataTypeId,
                    data_source_id: sourceId
                }, r => operationCallBack(r)
            )
            onClose()
        }
        else {
            updateDataSource(
                {
                    id: item.id,
                    area_id: selectedArea.id,
                    prm_data_type_id: selectedDataTypeId,
                    data_source_id: sourceId
                }, r => operationCallBack(r)
            )
            onClose()
        }


    }
    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]


    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={600}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.add + ' ' + vocabulary.sourceLabel : vocabulary.editLabel + ' ' + vocabulary.sourceLabel}
            >

                <form
                    ref={form}
                    onSubmit={handleSubmit(handleAddSource)}
                    onError={errors => console.log(errors)}>

                    <StyledSelect
                        value={area_id}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Area}
                        onChange={handleChange}
                        name='Area'
                        label={vocabulary.site}
                        styles={{ width: '100%' }}
                        data={siteList && siteList.data}
                        valueField='id'
                        labelField='name'

                    />
                    {((parcelsList.length > 0 && area_id) || parcelId) &&
                        <StyledSelect
                            value={parcelId}
                            register={register}
                            errors={errors.ParcelId}
                            onChange={handleChange}
                            name='ParcelId'
                            label={vocabulary.parcel}
                            fullWidth
                            data={parcelsList}
                            valueField='id'
                            labelField='name'
                            styles={{ width: '100%' }}

                        />
                    }

                    <StyledAutoComplete
                        styles={{ width: '100%' }}
                        data={dataTypeList.data}
                        optionLabel={(option) => JSON.parse(option.name).name_ar ?  JSON.parse(option.name)['name_' + currentLanguage] : JSON.parse(option.name).name_fr}
                        defaultValue={item && item.prm_data_type}
                        onChange={(event, newValue) => {
                            setSelectedDataTypeId(newValue !==null ? newValue.id : null)

                        }}
                        label={vocabulary.datatype} 

                    />
                    <StyledSelect
                        value={sourceId}
                        register={register}
                        errors={errors.source}
                        onChange={(event) => { setSourceId(event.target.value) }}
                        name='source'
                        label={vocabulary.sourceLabel}
                        fullWidth
                        data={sourceList}
                        valueField='id'
                        labelField='id'
                        styles={{ width: '100%' }}

                    />
                    <StyledModalFooter actions={actions} />

                </form>





            </StyledModal>

        </>
    )
}
export default AddSourceForm
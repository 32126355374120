/* eslint-disable eqeqeq*/

import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Typography, } from '@material-ui/core/';
import { vocabulary } from '../Strings';
import { StyledCard } from "../../ui/layout";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import CycleForm from '../Cycle/CycleAddUpdateForm'

import "./styles.css";

const NoCycleFoundedCard = ({ areaId, selectedAreaProps }) => {

    const [openCycleAddForm, setOpenCycleAddForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleClickOpenCycleAddForm = () => {
        setOpenCycleAddForm(true)
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenCycleAddForm(!openCycleAddForm)

        } else {
            setOpenCycleAddForm(newStatus)
        }
    }
    const contextValueAdd = { open: openCycleAddForm, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={contextValueAdd}>
            <StyledCard additionalStyle={{ height: '100px', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }} >
                <div className=" container-fluid NoCycleAvailableCard"  style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                    <Typography variant="h5" gutterBottom>
                        {vocabulary.nocycleFoundedInSelectedArea}

                    </Typography>
                    <div>
                        <Button
                            variant="contained"
                            onClick={handleClickOpenCycleAddForm}
                            style={{ textTransform: 'none' }}
                            color="primary"

                        >
                            <AddIcon />
            &nbsp;{vocabulary.add}
                        </Button>
                    </div>

                </div>


            </StyledCard>
            { openCycleAddForm == true &&
                <CycleForm
                    open={openCycleAddForm}
                    onClose={() => setOpenCycleAddForm(false)}
                    // sitesList={sitesList && sitesList.data}
                    isEditing={false}
                    selectedAreaId={areaId}
                    cycleAddOnePager={true}
                    selectedAreaFromOnePage={selectedAreaProps}
                    setIsLoading={setIsLoading}

                />}
        </ModalContext.Provider>

    )

}
export default NoCycleFoundedCard
import { Button, CircularProgress, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { StyledInput } from '../../../../../ui/forms';
import { vocabulary } from '../../../../Strings';
import { useSnackbar } from 'notistack';
import { ActivateIntegrations } from '../../../../../globalsTools/network';
import { useHistory } from 'react-router-dom';


const WeenatActivateForm = ({ isEditing, partner }) => {

    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const activate = () => {
        setIsLoading(true)
        ActivateIntegrations(partner, {
            credentials: {
                email,
                password
            }
        }, (data) => {
            setIsLoading(false)
            if (data.status == 200) {
                
                history.push(`/app/integrations/${partner}/configure`, { partner })
            } else {
                snackbar(vocabulary.errorOccurred + data.status, 'error')
            }

        })
    }



    return (

        <>

            {
                !isEditing && (
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <StyledInput
                                name={vocabulary.email}
                                label={vocabulary.email}
                                type="text"
                                fullWidth
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value)
                                }}

                            />
                        </Grid>


                        <Grid item xs={9}>
                            <StyledInput
                                name={vocabulary.password}
                                label={vocabulary.password}
                                type="password"
                                fullWidth
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value)
                                }}

                            />
                        </Grid>

                        <Grid item xs={3} style={{ alignSelf: 'center', marginTop: "15px" }}>
                            {
                                !isLoading ? <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={activate}
                                    disabled={isLoading}
                                >
                                    {vocabulary.connect}

                                </Button>
                                    :
                                    <CircularProgress size={30} />
                            }
                        </Grid>
                    </Grid>
                )
            }

        </>
    );
}

export default WeenatActivateForm


import React, {useRef, useContext } from 'react';

import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../ui/layout'
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { SendMessage } from "../../globalsTools/network";

const NotificationForm = ({mobile,open,onClose}) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()

    const modalContext = useContext(ModalContext)

    const sendNotification = (data) => {
        SendMessage({
            title: data.nameNotif,
            description: data.descriptionNotif,
            mobile: mobile
        })
    }

    const actions = [
        { title: vocabulary.send, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={500}
                modalContext={modalContext}
                title={vocabulary.newNotification}
            >
                <form
                    onSubmit={handleSubmit(sendNotification)}
                    ref={form}
                >
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                name="nameNotif"
                                label={vocabulary.title}
                                type="text"
                                fullWidth                              
                                errors={errors.nameNotif}
                         
                            />
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                name="descriptionNotif"
                                label={vocabulary.content}
                                type="text"
                                errors={errors.descriptionNotif}
                                fullWidth                                

                            />
                    <StyledModalFooter actions={actions} />
                    
                </form>
            </StyledModal>
        </>
    )
}
export default NotificationForm
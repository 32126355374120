/* eslint-disable no-useless-escape*/

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { FormControl, MenuItem, Divider } from '@material-ui/core/';
import { Checkbox, InputLabel, FormLabel, ListItemText, Input } from '@material-ui/core/'
import { vocabulary } from '../Strings';
import Select2 from '@material-ui/core/Select';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import StyledChekbox from "../../ui/forms/StyledChekbox";
import { GetAllCulture, CreateSubscription, updateSubscription } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';

const SubscriptionAddForm = ({ isEditing, open, refetchFunc, selectedItem, handleClose,setIsLoading }) => {


    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();

    // const [description, setDescription] = useState('')
    const [sms, setSms] = useState(false)
    const [api, setApi] = useState(false)
    const [email, setEmail] = useState(false)
    const [application, setApplication] = useState(false)
    const [cultureList, setCultureList] = useState(null)

    const [selectedCultureIndex, setSelectedCultureIndex] = useState([])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    useEffect(() => {
        if (isEditing === false) {

            setSelectedCultureIndex([])
        }
    }, [open, isEditing]);

    useEffect(() => {

        if (selectedItem && isEditing === true) {


            setSelectedCultureIndex(selectedItem.cultures ? JSON.parse(selectedItem.cultures).cultures : '')
            setSms(selectedItem.channels ? JSON.parse(selectedItem.channels).sms : false)
            setEmail(selectedItem.channels ? JSON.parse(selectedItem.channels).email : false)
            setApplication(selectedItem.channels ? JSON.parse(selectedItem.channels).application : false)
            setApi(selectedItem.channels ? JSON.parse(selectedItem.channels).api : false)

        }

    }, [selectedItem, isEditing]); // N’exécute l’effet que si selectedItem a changé

    useEffect(() => {


        GetAllCulture(setCultureList)


    }, []); // N’exécute l’effet dans le 1ère render

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing ? vocabulary.updateSucc : vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            }

            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }

    const newSubcription = (data) => {
        const totalPrice = Number(data.smsPrice) + Number(data.emailPrice) + Number(data.applicationPrice) + Number(data.ApiPrice)
        setIsLoading(true)
        if (isEditing === false) {
            CreateSubscription(
                {
                    title: JSON.stringify({ 'name': data.title }),
                    description: JSON.stringify({ 'description': data.description }),
                    channels: JSON.stringify({ 'email': email, 'sms': sms, 'application': application, 'api': api }),
                    cultures: JSON.stringify({ 'cultures': selectedCultureIndex }),
                    price: JSON.stringify({ 'smsPrice': data.smsPrice, 'emailPrice': data.emailPrice, 'applicationPrice': data.applicationPrice, 'apiPrice': data.apiPrice, 'totalPrice': totalPrice && totalPrice })

                }, r => operationCallBack(r)
            )


            handleClose()
        } else {
            updateSubscription(
                {
                    id: selectedItem.id,
                    title: JSON.stringify({ 'name': data.title }),
                    description: JSON.stringify({ 'description': data.description }),
                    channels: JSON.stringify({ 'email': email, 'sms': sms, 'application': application, 'api': api }),
                    cultures: JSON.stringify({ 'cultures': selectedCultureIndex }),
                    price: JSON.stringify({ 'smsPrice': data.smsPrice, 'emailPrice': data.emailPrice, 'applicationPrice': data.applicationPrice, 'apiPrice': data.apiPrice, 'totalPrice': totalPrice })

                }, r => operationCallBack(r)
            )

            handleClose()

        }
    }
    const actions = [
        { title:  isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={handleClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addSubscription : vocabulary.editSubscription}
            >
                <form
                    ref={form}
                    onSubmit={handleSubmit(newSubcription)}

                >

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.title}
                        name="title"
                        label={vocabulary.title}
                        type="text"
                        fullWidth
                        defaultValue={selectedItem && selectedItem.title && JSON.parse(selectedItem.title).name}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="description"
                        label={vocabulary.description}
                        type="text"
                        fullWidth
                        errors={errors.description}
                        maxRows={4}
                        multiline={true}
                        defaultValue={selectedItem && selectedItem.description && JSON.parse(selectedItem.description).description}

                    />

                    {cultureList &&
                        <FormControl required
                            variant="outlined"
                            style={{ margin: '8px', width: '100%' }}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">{vocabulary.culture}</InputLabel>

                            <Select2
                                multiple
                                value={selectedCultureIndex}
                                onChange={(event) => {
                                    setSelectedCultureIndex(event.target.value)
                                }}
                                input={<Input id="select-multiple-checkbox" />}
                                label={vocabulary.culture}
                                renderValue={selected => { return cultureList && cultureList.data.filter(item => selected.indexOf(item.id) > -1).map(n => JSON.parse(n.name)["name_" + currentLanguage]).join(', ') }}
                                MenuProps={MenuProps}
                            >
                                {
                                    cultureList && cultureList.data.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            <Checkbox checked={selectedCultureIndex.indexOf(item.id) > -1} />
                                            <ListItemText primary={JSON.parse(item.name)["name_" + currentLanguage]} />
                                        </MenuItem>
                                    ))
                                }
                            </Select2>
                        </FormControl>}

                    <Divider />
                    <FormLabel component="legend" style={{ margin: '10px' }}>{vocabulary.SubscriptionType}</FormLabel>

                    <div>
                        <div row style={{ display: 'flex', justifyContent: 'space-around' }} >
                            <StyledChekbox
                                checked={sms}
                                onChange={(event) => {
                                    setSms(event.target.checked)
                                }}
                                label={vocabulary.sms}
                            />


                            <StyledInput
                                register={register}
                                rules={{ required: sms === true ? true : false }}
                                errors={errors.smsPrice}
                                name="smsPrice"
                                label={vocabulary.smsPrice}
                                type="text"
                                defaultValue={selectedItem && selectedItem.price && JSON.parse(selectedItem.price).smsPrice}

                            />
                        </div>

                        <br />

                        <div row style={{ display: 'flex', justifyContent: 'space-around' }} >
                            <StyledChekbox
                                checked={email}
                                onChange={(event) => {
                                    setEmail(event.target.checked)
                                }}
                                label={vocabulary.email}
                            />


                            <StyledInput
                                register={register}
                                rules={{ required: email === true ? true : false }}
                                errors={errors.emailPrice}
                                name="emailPrice"
                                label={vocabulary.emailPrice}
                                type="text"
                                defaultValue={selectedItem && selectedItem.price && JSON.parse(selectedItem.price).emailPrice}

                            />


                        </div>
                        <br />

                        <div row style={{ display: 'flex', justifyContent: 'space-around' }} >
                            <StyledChekbox
                                checked={api}
                                onChange={(event) => {
                                    setApi(event.target.checked)
                                }}
                                label={'api'}
                            />

                            <StyledInput
                                register={register}
                                rules={{ required: api === true ? true : false }}
                                errors={errors.ApiPrice}
                                name="ApiPrice"
                                label={'Api Price'}
                                type="text"
                                defaultValue={selectedItem && selectedItem.price && JSON.parse(selectedItem.price).apiPrice}

                            />
                        </div>
                        <br />
                        <div row style={{ display: 'flex', justifyContent: 'space-around' }} >

                            <StyledChekbox
                                checked={application}
                                onChange={(event) => {
                                    setApplication(event.target.checked)
                                }}
                                label={vocabulary.application}
                            />
                            <StyledInput
                                register={register}
                                rules={{ required: application === true ? true : false }}
                                errors={errors.applicationPrice}
                                name="applicationPrice"
                                label={vocabulary.appPrice}
                                type="text"
                                defaultValue={selectedItem && selectedItem.price && JSON.parse(selectedItem.price).applicationPrice}

                            />

                        </div>
                    </div>
                    <StyledModalFooter actions={actions} />
                </form>
            </StyledModal>
        </>
    )
}
export default SubscriptionAddForm;


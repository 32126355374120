/* eslint-disable no-useless-escape*/
/* eslint-disable no-unused-vars*/

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { loadCitiesByIdCountries, displayWarning } from '../../../actions';

// import compose from 'recompose/compose';

import { Typography, Grid } from "@material-ui/core/";
import { ModalContext } from '../../../../ui/layout/contextLayout/ModalContext';

import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import StyledInput from "../../../../ui/forms/StyledInput";
import { addCustomer, GetAllSeabexPack, GetUsersList, UpdateCustomer } from "../../../../globalsTools/network";
import { StyledModal } from '../../../../ui/layout'
import StyledModalFooter from '../../../../ui/layout/StyledModalFooter';
import { vocabulary } from '../../../Strings';
import StyledAutoComplete from '../../../../ui/forms/StyledAutoComplete';
import StyledSelect from '../../../../ui/forms/StyledSelect';

const CustomerAddForm = ({ open, isEditing, item = null, refetchFunc, onClose }) => {

    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const { enqueueSnackbar } = useSnackbar();


    const [currentItem, setCurrentItem] = useState({})
    const [usersList, setUsersList] = useState(null)
    const [customer, setCustomer] = useState(null)

    useEffect(() => {

        GetUsersList(setUsersList)

    }, [setUsersList])

    useEffect(() => {
        if (item) {
            setCurrentItem(item)
        }
    }, [item, isEditing]);

    const onChange = (event) => {

        setCurrentItem({
            ...currentItem,
            [event.target.name]: event.target.value
        });
    }


    const renderPackName = (name) => {
        try {
            return JSON.parse(name)[currentLanguage]
        } catch (e) {
            return "N/A"
        }
    }
    const handleResponse = (response, success, status) => {
        if (response) {
            if (success) {

                //snackbarMsg(vocabulary.emailCheckAccount, 'warning');
                snackbarMsg(vocabulary.registerDone, 'success');
                if (refetchFunc) {
                    refetchFunc()
                }
                onClose()
            }
            else if (response.response.status === 403) {
                snackbarMsg(vocabulary.checkAccessCode, 'error')
            }
            else {
                snackbarMsg(vocabulary.errorOccurred + " : " + vocabulary[response.response.data.error], 'error')

            }
        } else {
            snackbarMsg(vocabulary.errorOccurred, 'error')
        }
    }
    const handleFormSubmit = (values) => {

        if (isEditing) {
            UpdateCustomer(currentItem.source_id, {
                ...currentItem,
                ...values
            }, handleResponse)
        } else {
            addCustomer({
                ...currentItem,
                ...values
            }, handleResponse)
        }
    }

    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }
    const actions = [
        { title: vocabulary.save, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addNewUser : vocabulary.EditUser}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                    padding: 5,
                    width: '100%'
                }}>

                    {/* <StyledInfoBubble type='info'>{vocabulary.invitationVoucherWarning}</StyledInfoBubble> */}

                    <form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        style={{ ...formStyle }}>


                        <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillTextAddUser}</Typography>
                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            {
                                usersList && <Grid item xs={12}>

                                    <StyledAutoComplete
                                        data={usersList ?? []}
                                        optionLabel={(option) => option?.firstname + ' ' + option?.lastname ?? ''}
                                        onChange={(event, newValue) => {
                                            setCustomer(newValue)
                                        }}
                                        label={vocabulary.owner}
                                        styles={{ width: `100%` }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <StyledSelect
                                    value={isEditing ? currentItem?.customer_type : null}
                                    errors={errors.customer_type}
                                    register={register}
                                    rules={{ required: false }}
                                    onChange={onChange}
                                    name='customer_type'
                                    label={vocabulary.customer_type}
                                    autoWidth
                                    data={['company', 'individual']}
                                    styles={{ width: `100%` }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledInput
                                    register={register}
                                    rules={{ required: false }}
                                    name="reg_no"
                                    label={vocabulary.serielNumber}
                                    type="text"
                                    value={currentItem?.reg_no}
                                    onChange={onChange}
                                    fullWidth
                                    errors={errors.reg_no}
                                    disableAutoComplete={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledInput
                                    name="name"
                                    register={register}
                                    rules={{ required: true }}
                                    errors={errors.name}
                                    label={vocabulary.name}
                                    value={currentItem?.name}
                                    onChange={onChange}
                                    type="text"
                                    styles={{ width: `100%` }}
                                />
                            </Grid>

                        </Grid>

                        <StyledInput
                            register={register}
                            rules={{ required: false }}
                            name="address"
                            label={vocabulary.billingAddress}
                            type="text"
                            value={currentItem?.billing_address?.address}
                            onChange={(event) => {
                                setCurrentItem({
                                    ...currentItem,
                                    billing_address: {
                                        ...currentItem.billing_address,
                                        address: event.target.value
                                    }
                                })
                            }}
                            errors={errors.address}
                            disableAutoComplete={true}
                        />

                        <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                            <Grid item xs={12}>
                                <StyledInput

                                    errors={errors.postal_code}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="postal_code"
                                    value={currentItem?.billing_address?.postal_code}
                                    onChange={(event) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            billing_address: {
                                                ...currentItem.billing_address,
                                                postal_code: event.target.value
                                            }
                                        })
                                    }}
                                    label={vocabulary.department}
                                    type="text"
                                    fullWidth

                                />

                            </Grid>
                            <Grid item xs={12}>
                                <StyledInput

                                    errors={errors.city}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="city"
                                    value={currentItem?.billing_address?.city}
                                    onChange={(event) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            billing_address: {
                                                ...currentItem.billing_address,
                                                city: event.target.value
                                            }
                                        })
                                    }}
                                    label={vocabulary.city}
                                    type="text"
                                    fullWidth

                                />

                            </Grid>
                            <Grid item xs={12}>
                                <StyledInput
                                    errors={errors.country_alpha2}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="country_alpha2"
                                    value={currentItem?.billing_address?.country_alpha2}
                                    onChange={(event) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            billing_address: {
                                                ...currentItem.billing_address,
                                                country_alpha2: event.target.value
                                            }
                                        })
                                    }}
                                    label={vocabulary.CounterLabel}

                                    type="text"
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs={12}>
                                <StyledInput

                                    style={{ marginTop: "-7px" }}
                                    name="emails"
                                    value={currentItem?.emails ?  currentItem?.emails[0] : ''}
                                    onChange={(event) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            emails: {
                                                ...event.target.value
                                            }
                                        })
                                    }}
                                    label={vocabulary.email}
                                    type="text"
                                    fullWidth

                                />

                            </Grid>

                            <Grid item xs={12}>
                                <StyledInput
                                    errors={errors.reference}
                                    register={register}
                                    rules={{ required: false }}
                                    style={{ marginTop: "-7px" }}
                                    name="reference"
                                    value={currentItem?.reference}
                                    onChange={onChange}
                                    label={vocabulary.reference}
                                    type="text"
                                    fullWidth

                                />
                            </Grid>
                        </Grid>


                        <StyledModalFooter actions={actions} />
                    </form>
                </div>
            </StyledModal>
        </>
    )
}
export default CustomerAddForm
/* eslint-disable no-useless-escape*/
/* eslint-disable eqeqeq*/

import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    IconButton, InputAdornment, RadioGroup, FormControlLabel, Radio
    // ListItem, ListItemText, List
    , Typography, Checkbox, Button, Grid
} from "@material-ui/core/";
import { ArrowBack, Visibility, VisibilityOff } from "@material-ui/icons/";
import {
    loadAllCountries, displayWarning,
    // loadCitiesByIdCountries, 
} from "../actions";
import { vocabulary } from "./Strings";
import { useHistory } from "react-router-dom";
import SeabexLogo from "../ui/SeabexLogo";
import { useForm } from 'react-hook-form';
import { StyledBackground } from "../ui/layout";
import StyledCard from "../ui/layout/StyledCard";
import StyledInput from "../ui/forms/StyledInput";
import StyledAppLoading from "../ui/tools/StyledAppLoading";
import StyledAutoComplete from "../ui/forms/StyledAutoComplete";

import { useSnackbar } from 'notistack';
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { GetCountriesList, signUpUser } from "../globalsTools/network";
// import PlacesAutocomplete from "react-places-autocomplete";
import StyledModalFooter from '../ui/layout/StyledModalFooter';
import { GuideContext } from "../ui/layout/contextLayout/GuideContext";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "./globalConsts";
import { StyledDialog } from "../ui/display/StyledDialog";
import StyledInfoBubble from "../ui/display/StyledInfoBubble";
import { logger } from "../globalsTools/utilities";
import { setGlobalLanguage } from "../actions/_globalStatusActions";

const SignupUser = ({embedded=false, successFn}) => {

    const dispatch = useDispatch();
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { register, handleSubmit, errors } = useForm()
    
    const [countries, setCountries] = useState([])

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    // const cities = useSelector(state => state.cities.data)

    const { dispatchGuide } = useContext(GuideContext)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    // const [selectedVilleId, setSelectedVilleId] = useState('')
    const [selectedCountryId, setSelectedCountryId] = useState('')
    const [type, setType] = useState('Farmer')
    // const [address, setAddress] = useState('')
    const [showPassword, setShowPassword] = useState('')
    // const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [c_password, setC_password] = useState('')
    const [companyName, setCompanyName] = useState(null)
    const [termsAcceptance, setTermsAcceptance] = useState(false)

    const [disabledSignup, setDisabledSignup] = useState(true);
    const [confirmationDialogStatus, setConfirmationDialogStatus] = useState(false)
    const [successfulCreation, setSuccessfulCreation] = useState(false)
    const [lastError, setLastError] = useState('')
    const [processing, setProcessing] = useState(false)
    const [invitationVoucher, setInvitationVoucher] = useState('')
    
    const [countriesList, setCountriesList] = useState(null)

    const snackbarMsg = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {
        if (currentLanguage) {
            GetCountriesList((currentLanguage ?? 'fr'), (data) => {
                setCountriesList(data ? data.filter(n=>n).reverse() : []);
            })
    
        }
    
    }, [currentLanguage])


    useEffect(() => {

        dispatch(loadAllCountries())
        document.title = vocabulary.signup


    }, [dispatch]); // N’exécute l’effet que si count a changé


    // const citiesList = cities.map(item => ({
    //     value: item["id"],
    //     label: JSON.parse(item.name)["name_" + currentLanguage]
    // }));


    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const validatePassword = (c_password) => {
        if (password !== c_password) {
            return false
        }
        return true
    }
    const handleChangeRadio = (event) => {
        setType(event.target.value)
    }

    const actions = [
        { title: processing ? 'Processing...' : vocabulary.signup, color: "primary", position: 'end', type: "submit", disabled: disabledSignup || processing },
        { title: vocabulary.alreadyRegistered, color: "secondary", position: 'start', variant: 'flat', style:{fontSize:12}, icon: <ArrowBack />, onClick: () => history.push('/') }
    ]

    const ERRORS = {_EMAIL_ALREADY_EXISTS_ : vocabulary.accountExistLabel, _INVALID_INVITATION_VOUCHER_ : vocabulary.invalidInvitationVoucher}

    const operationCallBack = (r, success) => {  // callback function called after submit operation 
        if (r.response) r = r.response;

        if (r) {
            if (r && r.status) {
                if (r.status === 200) {
                    setSuccessfulCreation(true)
                    setConfirmationDialogStatus(true)
                }
                else if (r.status === 400) {
                    setProcessing(false);
                    setSuccessfulCreation(false)
                    setConfirmationDialogStatus(true)

                    if (r.data.error) {
                        setLastError(ERRORS[r.data.error])
                    }
                }
                else {
                    setProcessing(false);
                    setSuccessfulCreation(false)
                    setConfirmationDialogStatus(true)

                    setLastError(r.data.error)

                }
            }
        }

    }

    const handleSuccess = () => {
        snackbarMsg(vocabulary.emailCheckAccount, 'success')
        if (!embedded) {
            history.push('/')
        } else {
            if (successFn) {
                successFn()
            }
        }
    }

    const closeConfirmationDialog = () => {
        setConfirmationDialogStatus(false)
    }

    const signUp = () => {

        const activesince = new Date()
        dispatchGuide({ type: INITIAL_STEP, payload: 0 });
        dispatchGuide({ type: CURRENT_STEP, payload: "homePage" });
        dispatchGuide({ type: IS_ENABLED, payload: true })

        setProcessing(true)


        signUpUser(
            {
                firstname: firstName,
                lastname: lastName,
                email: email,
                password: password,
                c_password: c_password,
                mobile: mobile,
                // prm_governate_id: selectedVilleId,
                prm_country_id: selectedCountryId,
                type: 'Farmer',
                isSeabexClient: true,
                status: 'Free',
                // address: JSON.stringify({ 'address': address }),
                company_name: companyName,
                activesince: activesince,
                invitation_voucher:invitationVoucher,
                preferred_language:currentLanguage

            }, r => operationCallBack(r)
        )




    }
    

    useEffect(() => {
        if (firstName && lastName && email && password && c_password && selectedCountryId && termsAcceptance) {
            setDisabledSignup(false)
        } else {
            setDisabledSignup(true)
        }
    }, [firstName, lastName, email, password, c_password, selectedCountryId, termsAcceptance])


    const formStyle = {
        display: "flex",
        flexDirection: "column",
    }

    // const { isDirty, isValid, isSubmitting, touched, submitCount } = formState;

    return (
        <>

            {/* <StyledAppLoading /> */}

            <StyledBackground>
                <StyledCard direction="row" elevation={8} width={`80%`} additionalStyle={{margin:24}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        position: 'relative',
                        padding: 40,
                        width: '100%'
                    }}>
                        <div className="seabexSignUpLogo" style={{ display: 'flex', justifyContent: 'center' }}>
                            <SeabexLogo width={200} styles={{ marginBottom: 32, marginTop: 32 }} />
                        </div>

                        {/* <StyledInfoBubble type='info'>{vocabulary.invitationVoucherWarning}</StyledInfoBubble> */}

                        <form
                            onSubmit={handleSubmit(signUp)}
                            style={{ ...formStyle }}>
                            
                            <Typography style={{ alignSelf: 'flex-start', marginInlineStart: 8, marginTop: 11 }}>{vocabulary.formFillText}</Typography>
                            <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                                <Grid item xs style={{display:'flex'}}>
                                    <StyledInput
                                        name="firstName"
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.firstName}

                                        label={vocabulary.firstname}
                                        type="text"
                                        value={firstName}
                                        onChange={(event) => setFirstName(event.target.value)} 
                                        styles={{width:`100%`}}
                                    />
                                </Grid>
                                <Grid item xs style={{display:'flex'}}>
                                    <StyledInput
                                        name="lastName"
                                        errors={errors.lastName}

                                        register={register}
                                        rules={{ required: true }}
                                        label={vocabulary.lastname}
                                        value={lastName}
                                        onChange={(event) => setLastName(event.target.value)}
                                        type="text"
                                        styles={{width:`100%`}}


                                    />
                                </Grid>
                            </Grid>

                            <StyledInput
                                register={register}
                                rules={{ required: true, validate: validateEmail }}
                                name="email"
                                label={vocabulary.email}
                                type="text"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                errors={errors.email} 
                                disableAutoComplete={true}
                            />
                            {errors.email &&
                                <span style={{ margin: '10px', color: '#0F4E62', fontSize: 'large', fontWeight: '500' }}>
                                    {vocabulary.mailInvalid}
                                </span>
                            }
                            <Grid container direction='row' spacing={2} width={`calc(100%)!important`}>
                                <Grid item xs style={{display:'flex'}}>
                                    <StyledInput
                                        name="password"
                                        id="outlined-adornment-password"
                                        register={register}
                                        rules={{ required: true, minLength: 8 }}
                                        type={showPassword ? 'text' : 'password'}
                                        label={vocabulary.password}
                                        value={password}
                                        onChange={(event) => { 
                                            setPassword(event.target.value);
                                        }}
                                        errors={errors.password}
                                        helperText={vocabulary.securePasswordConditions}
                                        // disableAutoComplete={true}

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(!showPassword)

                                                        }
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        styles={{width:`100%`}}


                                    />

                                </Grid>
                                <Grid item xs style={{display:'flex'}}>
                                    <StyledInput
                                        name="c_password"
                                        id="outlined-adornment-c_password"
                                        register={register}
                                        rules={{ required: true, validate: validatePassword }}
                                        type={showPassword ? 'text' : 'password'}
                                        label={vocabulary.c_password}
                                        value={c_password}
                                        onChange={(event) => setC_password(event.target.value)}
                                        styles={{width:`100%`}}

                                    />
                                </Grid>
                            </Grid>
                                    {errors.c_password && <span>{vocabulary.passwordDntMatch}</span>}

                            <StyledAutoComplete
                                data={countriesList ?? []}
                                optionLabel={(option) => option.name ?? ''}
                                onChange={(event, newValue) => {
                                    setSelectedCountryId(newValue ? newValue.id : '')
                                }}
                                label={vocabulary.country}

                            />

                            <FormControlLabel style={{ alignItems: 'center', alignSelf: 'flex-start', marginLeft: 8, marginRight: 8 }}
                                labelPlacement="start"
                                label={vocabulary.userType}
                                control={<RadioGroup style={{ margin: `0px 10px` }} row aria-label="gender" name="userStatus" value={type} onChange={handleChangeRadio}>
                                    <FormControlLabel value="Farmer" control={<Radio />} label={vocabulary.Farmer} />
                                    <FormControlLabel value="Comapany" control={<Radio />} label={vocabulary.CompanyLabel} />
                                </RadioGroup>}
                            />

                            {
                                type == "Comapany" &&
                                <StyledInput
                                    name="company"
                                    register={register}
                                    errors={errors.company}
                                    label={vocabulary.companyNameLabel}
                                    type="text"
                                    value={companyName}
                                    onChange={(event) => setCompanyName(event.target.value)}
                                />
                            }

                            <FormControlLabel label={vocabulary.getContactedBySeabex} style={{ alignItems: 'flex-start', backgroundColor: '#fbf5e3', margin: 0, padding: 10, border: `1px #e4e4e4 solid`, borderRadius: 7 }}
                                labelPlacement='top'
                                control={<StyledInput
                                    label={vocabulary.enterPhoneNumber}
                                    name='phone_number'
                                    value={mobile}
                                    onChange={(event) => {
                                        setMobile(event.target.value)
                                    }}

                                // variant="text"
                                />}
                            />

                            <FormControlLabel
                                name="terms"
                                control={<Checkbox color="primary" />}
                                label={<div dangerouslySetInnerHTML={{ __html: vocabulary.acceptTerms }}></div>}
                                labelPlacement="end"
                                onChange={(event) => { setTermsAcceptance(event.target.checked) }}
                            />
                            <StyledModalFooter actions={actions} />


                        </form>
                        <div className="changeLanguage" style={{ top: 10, textAlign: "center", position: 'absolute', right: 10 }}>

                            <Button style={currentLanguage == "en" ? { color: 'Black', fontWeight: 'bold', borderBottom: `2px solid`, borderRadius: 0, fontSize: 12 } : { color: '#03465b', fontSize: 12 }} onClick={() => { dispatch(setGlobalLanguage('en')); vocabulary.setLanguage("en") }} >English</Button>
                            <Button style={currentLanguage == "fr" ? { color: 'Black', fontWeight: 'bold', borderBottom: `2px solid`, borderRadius: 0, fontSize: 12 } : { color: '#03465b', fontSize: 12 }} onClick={() => { dispatch(setGlobalLanguage('fr')); vocabulary.setLanguage("fr") }} >Français</Button>
                            {/* <Button style={currentLanguage == "ar" ? { color: 'white' } : { color: '#03465b' }} onClick={() => { dispatch(setGlobalLanguage('ar')); vocabulary.setLanguage("ar") }} >Arabic</Button> */}

                        </div>

                    </div>

                </StyledCard>

            </StyledBackground>

            <StyledDialog
                title={successfulCreation ? vocabulary.registrationSuccLabel : vocabulary.registrationErrorLabel}
                actions={<Button onClick={() => { if (successfulCreation) { handleSuccess() } else { closeConfirmationDialog() } }}>OK</Button>}
                open={confirmationDialogStatus}
                handleClose={() => closeConfirmationDialog()}
                variant={successfulCreation ? '_SUCCESS_' : '_FAILED_'}
            >
                {successfulCreation
                    ?
                    <Typography>{vocabulary.successfulSingup}</Typography>
                    :
                    <>
                        <Typography>{vocabulary.errorInSignUp}</Typography>
                        <Typography style={{fontSize:18, marginTop:18}}>{lastError}</Typography>
                    </>
                }
            </StyledDialog>

        </>
    );
};

export default SignupUser;

import React from 'react';

const Configuration=()=>{

    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,height:700}}>
         <h3>Under construction </h3>
        </div>
    )
}
export default Configuration
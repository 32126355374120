/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { createDataType, UpdateDataType } from "../../../globalsTools/network";
import { useSnackbar } from 'notistack';
import './styles.css'
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";

const DataTypeAddForm = ({ item, onClose, open, isEditing, width, refetchFunc, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [abbreviation, setAbbreviation] = useState(null)
    const [measure, setMeasure] = useState(null)
    const [system, setSystem] = useState(null)
    const [singular, setSingular] = useState(null)
    const [plural, setPlural] = useState(null)
    const [unitList, setUnitList] = useState([])
    const [englishName, setEnglishName] = useState(null)
    const [frenshName, setFrenshName] = useState(null)
    const [arabicName, setArabicName] = useState(null)
    const [description, setDescription] = useState(null)
    const [dataTypeUnit, setDataTypeUnit] = useState(null)
    const [dataTypeIdentifier, setDataTypeIdentifier] = useState(null)
    const [dataTypeNature, setDataTypeNature] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    const modalContext = useContext(ModalContext)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    useEffect(() => {
        if (isEditing === false) {
            setAbbreviation(null)
            setMeasure(null)
            setSystem(null)
            setSingular(null)
            setPlural(null)
            setUnitList([])
            setEnglishName(null)
            setFrenshName(null)
            setArabicName(null)
            setDescription(null)
            setDataTypeUnit(null)
            setDataTypeIdentifier(null)
            setDataTypeNature(null)
        }

        // setIsEditing(isEditing)
    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    useEffect(() => {

        if (item) {
            setEnglishName(JSON.parse(item.name).name_en)
            setFrenshName(JSON.parse(item.name).name_fr)
            setArabicName(JSON.parse(item.name).name_ar)
            setDescription(item.description ? item.description : null)
            setDataTypeIdentifier(item.identifier ? item.identifier : null)
            setDataTypeUnit(item.iso_unit ? item.iso_unit : null)
            setDataTypeNature(item.nature ? item.nature : null)

        }

        if (item && item.units_array && JSON.parse(item.units_array).length > 0) {
            setAbbreviation(JSON.parse(item.units_array)[0].abbr)
            setMeasure(JSON.parse(item.units_array)[0].measure)
            setSystem(JSON.parse(item.units_array)[0].system)
            setSingular(JSON.parse(item.units_array)[0].singular)
            setPlural(JSON.parse(item.units_array)[0].plural)
            setUnitList(JSON.parse(item.units_array))


        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? JSON.parse(item.name)['name_' + currentLanguage] + "  " + vocabulary.updateSucc : vocabulary.datatype + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)

            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }

            else {
                setIsLoading(false)
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
            }
        }
    }

    const newDataType = () => {

        setIsLoading(true)
        createDataType(
            {
                name: JSON.stringify({ "name_fr": frenshName, "name_en": englishName, "name_ar": arabicName }),
                identifier: dataTypeIdentifier,
                iso_unit: dataTypeUnit,
                description: description,
                nature: dataTypeNature,
                units_array: JSON.stringify(unitList)

            }, r => operationCallBack(r)
        )
        onClose()


    }
    const handleUpdateDataType = () => {
        setIsLoading(true)


        UpdateDataType(
            {
                id: item.id,
                name: JSON.stringify({ "name_fr": frenshName, "name_en": englishName, "name_ar": arabicName }),
                identifier: dataTypeIdentifier,
                iso_unit: dataTypeUnit,
                description: description,
                nature: dataTypeNature,
                units_array: JSON.stringify(unitList)

            }, r => operationCallBack(r)
        )
        onClose()

    }
    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleSubmit(newDataType)() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancelBtn, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]


    const deleteUnit = (index) => {

        setUnitList(unitList => unitList.splice(index, 1))
    }

    const emptyArray = () => {
        setAbbreviation(null)
        setMeasure(null)
        setSystem(null)
        setSingular(null)
        setPlural(null)

    }

    const addList = () => {
        let obj = {
            abbr: abbreviation,
            measure: measure,
            system: system,
            singular: singular,
            plural: plural
        }

        setUnitList(unitList => [...unitList, obj])

    }

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={width}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addDataType : vocabulary.editDataType}
            >
                <form
                    ref={form}
                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="englishName"
                        label={vocabulary.name_en}
                        type="text"
                        fullWidth
                        errors={errors.englishName}
                        onChange={(event) => { setEnglishName(event.target.value) }}
                        value={englishName}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        label={vocabulary.name_fr}
                        type="text"
                        name="frenshName"
                        fullWidth
                        errors={errors.frenshName}
                        onChange={(event) => { setFrenshName(event.target.value) }}
                        value={frenshName}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="arabicName"
                        errors={errors.arabicName}
                        label={vocabulary.name_ar}
                        type="text"
                        fullWidth
                        onChange={(event) => { setArabicName(event.target.value) }}
                        value={arabicName}
                    />

                    <StyledInput
                        register={register}
                        name="unit"
                        label={vocabulary.unity}
                        type="text"
                        fullWidth
                        errors={errors.unit}
                        onChange={(event) => { setDataTypeUnit(event.target.value) }}
                        value={dataTypeUnit}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="identifier"
                        label={vocabulary.identifier}
                        type="text"
                        fullWidth
                        errors={errors.identifier}
                        onChange={(event) => { setDataTypeIdentifier(event.target.value) }}
                        value={dataTypeIdentifier}
                    />
                    <StyledInput
                        register={register}
                        errors={errors.description}
                        name="description"
                        label={vocabulary.description}
                        type="text"
                        fullWidth
                        onChange={(event) => { setDescription(event.target.value) }}
                        value={description}
                        maxRows={6}
                        multiline={true}
                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.nature}
                        name="nature"
                        label={vocabulary.dataTypeNature}
                        type="text"
                        fullWidth
                        onChange={(event) => { setDataTypeNature(event.target.value) }}
                        value={dataTypeNature}
                        maxRows={6}
                        multiline={true}
                    />
                    <div className="form_footer" >
                        <div className="unit_list">
                            {vocabulary.unitListsLabel}
                        </div>
                        <div className="unit_container">
                            <div className="list">
                                {
                                    <ul style={{ lineHeight: '4' }}>
                                        {unitList.map((item, index) => (
                                            <li style={{ boder: '1px solid red', display: 'flex', justifyContent: 'space-around' }}>
                                                {item.abbr}
                                                <IconButton edge="end" aria-label="delete">
                                                    <DeleteIcon onClick={() => deleteUnit(index)} />
                                                </IconButton>
                                            </li>
                                        )
                                        )}
                                    </ul>
                                }
                            </div>
                            <div className="unit_select"  >
                                <StyledInput
                                    register={register}
                                    errors={errors.abbreviation}
                                    name="abbreviation"
                                    value={abbreviation === null ? '' : abbreviation}
                                    label={vocabulary.abbreviationLabel}
                                    type="text"
                                    fullWidth
                                    onChange={(event) => {
                                        setAbbreviation(event.target.value)
                                    }}
                                    // defaultValue={item&&item.units_array !== null ? JSON.parse(item && item.units_array)[0].abbr : ''}
                                    maxRows={6}
                                    multiline={true}
                                    styles={{ width: '30%' }}
                                />
                                <StyledInput
                                    register={register}
                                    errors={errors.measure}
                                    name="measure"
                                    value={measure === null ? "" : measure}
                                    label={vocabulary.measureLabel}
                                    type="text"
                                    onChange={(event) => {
                                        setMeasure(event.target.value)
                                    }}
                                    fullWidth
                                    // defaultValue={item&&item.units_array !== null ? JSON.parse(item && item.units_array)[0].measure : ''}
                                    maxRows={6}
                                    multiline={true}
                                    styles={{ width: '30%' }}

                                />
                                <StyledInput
                                    register={register}
                                    errors={errors.system}
                                    name="system"
                                    value={system === null ? "" : system}
                                    label={vocabulary.systemLabel}
                                    type="text"
                                    fullWidth
                                    onChange={(event) => {
                                        setSystem(event.target.value)
                                    }}
                                    // defaultValue={item&&item.units_array !== null ? JSON.parse(item && item.units_array)[0].system : ''}
                                    maxRows={6}
                                    multiline={true}
                                    styles={{ width: '30%' }}

                                />
                                <StyledInput
                                    register={register}
                                    errors={errors.singular}
                                    name="singular"
                                    value={singular === null ? "" : singular}
                                    label={vocabulary.SingularLabel}
                                    type="text"
                                    fullWidth
                                    onChange={(event) => {
                                        setSingular(event.target.value)
                                    }}
                                    // defaultValue={item&&item.units_array !== null ? JSON.parse(item && item.units_array)[0].singular : ' '}
                                    maxRows={6}
                                    multiline={true}
                                    styles={{ width: '30%' }}

                                />
                                <StyledInput
                                    register={register}
                                    errors={errors.plural}
                                    name="plural"
                                    value={plural === null ? "" : plural}
                                    label={vocabulary.pluralLabel}
                                    type="text"
                                    fullWidth
                                    onChange={(event) => {
                                        setPlural(event.target.value)
                                    }}
                                    // defaultValue={item&&item.units_array !== null ? JSON.parse(item && item.units_array)[0].plural : ''}
                                    maxRows={6}
                                    multiline={true}
                                    styles={{ width: '30%' }}

                                />
                                <div style={{ display: 'flex', justifyContent: 'right', marginRight: '24px' }}>
                                    <Button
                                        style={{ margin: '8px', width: '50%' }}
                                        color="secondary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => emptyArray()}

                                    >
                                        <RotateLeftIcon /> {vocabulary.reset}

                                    </Button>
                                    <Button
                                        style={{ margin: '8px', width: '50%' }}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => addList()}

                                    >
                                        <AddIcon /> {vocabulary.add}

                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={JSON.parse(item.name)['name_' + currentLanguage]}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateDataType();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default DataTypeAddForm

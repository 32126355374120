import React from "react";
import logo from '../images/seabex/SeabexPlatformColoredBG.svg';

export default (props) => {

    const {style, width, height, ...properties} = props

    let styles= {
        margin: 8,
        width: width,
        height: height,
        ...style,
    }

    return (
        <img {...properties} src={logo} style={styles} alt="Seabex Platform" />
    )

}

/* eslint-disable eqeqeq*/

import React, { useRef, useContext } from 'react';

import { useForm } from 'react-hook-form';

import { vocabulary } from '../../Strings';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import StyledInput from "../../../ui/forms/StyledInput";
import { CreateDeviceModel, UpdateSeabexStationByCategory } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';

const CatalogModelAddForm = ({ isEditing, onClose, item, id_seabexcategorie, open, refetchFunc }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();


    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? item.name + "  " + vocabulary.updateSucc : vocabulary.seabexModelCategory + " " + vocabulary.addedSucc, 'success')
                refetchFunc()

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const newModelCategorie = (data) => {
        if (isEditing === false) {
            CreateDeviceModel(
                {
                    name: data.Modelname,
                    seriel_number: data.serialNumber,
                    type: JSON.stringify({ 'type': data.type }),
                    description: JSON.stringify({ 'specifications': data.description }),
                    seabex_category_id: id_seabexcategorie,
                    inputs: JSON.stringify([{ 'port_name': data.portName, 'port_type': data.portType }])

                }, r => operationCallBack(r)
            )
            onClose()
        } else (
            UpdateSeabexStationByCategory(
                {
                    id: item.id,
                    name: data.Modelname,
                    seriel_number: data.serialNumber,
                    type: JSON.stringify({ 'type': data.type }),
                    description: JSON.stringify({ 'description': data.description }),
                    seabex_category_id: id_seabexcategorie,
                    inputs: JSON.stringify([{ 'port_name': data.portName, 'port_type': data.portType }])

                }, r => operationCallBack(r)
            )

        )
        onClose()

    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', type: "submit" },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                width={500}
                modalContext={modalContext}
                title={isEditing === false ? vocabulary.addCategoryModel : vocabulary.editCategoryModel}
            >
                <form
                    onSubmit={handleSubmit(newModelCategorie)}
                    ref={form}
                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="Modelname"
                        label={vocabulary.deviceName}
                        type="text"
                        fullWidth
                        errors={errors.Modelname}
                        defaultValue={item && item.name}


                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="serialNumber"
                        label={vocabulary.serielNumber}
                        type="text"
                        fullWidth
                        errors={errors.serialNumber}
                        defaultValue={item && item.seriel_number}

                    />

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="type"
                        label={vocabulary.type}
                        fullWidth
                        errors={errors.type}
                        defaultValue={item && JSON.parse(item.type).type}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="portName"
                        label={vocabulary.portNameLabel}
                        type="text"
                        fullWidth
                        errors={errors.portName}
                        defaultValue={item && item.inputs && JSON.parse(item.inputs)}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="portType"
                        label={vocabulary.portTypeLabel}
                        type="text"
                        fullWidth
                        errors={errors.portType}
                        defaultValue={item && item.inputs && JSON.parse(item.inputs).port_type}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        name="description"
                        label={vocabulary.description}
                        fullWidth
                        errors={errors.description}
                        defaultValue={item && JSON.parse(item.description).specifications}
                        maxRows={4}
                        multiline={true}

                    />
                    <StyledModalFooter actions={actions} />
                </form>
            </StyledModal>
        </>
    )

}
export default CatalogModelAddForm

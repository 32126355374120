import {
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { REACT_APP_ENV_VERSION } from '../../config';
import {
    ConfirmPaymentViaVoucher,
    verifyCart,
} from '../../globalsTools/network';
import { logger } from '../../globalsTools/utilities';
import { StyledMaterialTable } from '../../ui/display';
import { StyledInput } from '../../ui/forms';
import {
    DEV_NETIRRIG_ENVIRONMENT,
    NETIRRIG_ENVIRONMENT,
    PAYMENT_PROVIDER_PAYMEE,
    PAYMENT_PROVIDER_STRIPE,
} from '../globalConsts';
import { vocabulary } from '../Strings';
import PaymeePaymentForm from './Paymee/PaymeePaymentForm';
import StripePaymentForm from './Stripe/StripePaymentForm';
import { useCart } from './useCart';
import VouchersForm from './Vouchers/VouchersForm';

const PaymentManager = ({ cart, onSuccessFn, onFailureFn }) => {
    const { cartSum } = useCart(cart);
    const [paymentMethod, setPaymentMethod] = useState('cb');
    const [cartError, setCartError] = useState(false);
    const [cartErrorObject, setCartErrorObject] = useState(null);
    const [verifying, setVerifying] = useState(true);
    const [billing, setBilling] = useState(null);

    const {
        register,
        formState: { errors },
    } = useForm();

    const currencyDecimals = useSelector(
        (state) => state._globalState.currency_decimals
    );

    const currentLanguage = useSelector(
        (state) => state._globalState.preferred_language
    );

    const changePaymentMethod = (event) => {
        setPaymentMethod(event.target.value);
    };
    const paymentProvider = useSelector(
        (state) => state._globalState.payment_provider
    );

    const handleVerification = (response, isSuccess, httpResponse) => {
        httpResponse = response && (response.response ?? response);
        if (!isSuccess) {
            setCartError(true);
            setCartErrorObject(httpResponse?.data);
        }
        setVerifying(false);
    };

    const handleBillingChange = (event) => {
        setBilling({
            ...billing,
            [event.target.name]: event.target.value,
        });
    };
    

    useEffect(() => {
        verifyCart(cart, handleVerification);
    }, []);

    return (
        <>
            <StyledMaterialTable
                title='Récaptiulatif de la commande'
                data={cart}
                options={{
                    search: false,
                    paging: false,
                }}
                columns={[
                    { title: vocabulary.titleLabel, field: 'label' },
                    { title: vocabulary.quantity, field: 'quantity' },
                    {
                        title: vocabulary.total,
                        field: 'total_in_vat',
                        render: (rowData) =>
                            Number(rowData.total_in_vat).toFixed(
                                currencyDecimals
                            ),
                    },
                ]}
            />

            {!verifying && !cartError && (
                <>
                    <Typography variant='h6' style={{marginTop:24}}>{vocabulary.billingDetails}</Typography>
                    <Grid container item xs={12} spacing={1} direction='column' style={{marginTop:12, border: `solid 1px #dedede`, borderRadius:10, boxShadow:`rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px`}}>
                        <Grid item container direction='row'>
                            <Grid item xs={6}>
                                <StyledInput
                                    name='company'
                                    {...register(
                                        'company',
                                        vocabulary.fieldRequired
                                    )}
                                    errors={errors?.company}
                                    label={vocabulary.companyNameLabel + ' *'}
                                    type='text'
                                    value={billing?.company}
                                    onChange={handleBillingChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledInput
                                    name='tva'
                                    {...register(
                                        'tva',
                                        vocabulary.fieldRequired
                                    )}
                                    errors={errors?.tva}
                                    label={vocabulary.tva + ' *'}
                                    type='text'
                                    value={billing?.tva}
                                    onChange={handleBillingChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item xs={6}>
                                <StyledInput
                                    name='billingAddress'
                                    label={vocabulary.billingAddress}
                                    type='text'
                                    value={billing?.billingAddress}
                                    onChange={handleBillingChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledInput
                                    name='notificationEmail'
                                    value={billing?.notificationEmail}
                                    label={vocabulary.email}
                                    type='email'
                                    onChange={handleBillingChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    <FormControl style={{ marginTop: 24 }}>
                        <Typography variant='h6'>
                            Méthode de paiement
                        </Typography>
                        <RadioGroup
                            aria-labelledby='payment-method-group-label'
                            value={paymentMethod}
                            onChange={changePaymentMethod}
                            name='payment-method-group'
                            row
                        >
                            <FormControlLabel
                                value='cb'
                                control={<Radio />}
                                label='Carte bancaire'
                            />
                            <FormControlLabel
                                // disabled={(REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT)}
                                value='voucher'
                                control={<Radio />}
                                label="Bon d'achat"
                            />
                            <FormControlLabel
                                value='other'
                                control={<Radio />}
                                label='Autre'
                            />
                        </RadioGroup>
                    </FormControl>

                    {!(
                        paymentMethod !== 'cb' ||
                        (paymentMethod === 'cb' &&
                            paymentProvider !== PAYMENT_PROVIDER_STRIPE)
                    ) && (
                        <StripePaymentForm
                            cart={cart}
                            total={cartSum()}
                            billingDetails={billing}
                            onSuccessFn={
                                () => onSuccessFn()
                                // setShowPaymentForm(false);
                                // modalprops.closeFn();
                            }
                            onFailureFn={() => onFailureFn()}
                        />
                    )}

                    {!(
                        paymentMethod !== 'cb' ||
                        (paymentMethod === 'cb' &&
                            paymentProvider !== PAYMENT_PROVIDER_PAYMEE)
                    ) && (
                        <PaymeePaymentForm
                            cart={cart}
                            total={cartSum()}
                            billingDetails={billing}
                            onSuccessFn={
                                () => onSuccessFn()
                                // setShowPaymentForm(false);
                                // modalprops.closeFn();
                            }
                            onFailureFn={() => onFailureFn()}
                        />
                    )}
                    {paymentMethod === 'voucher' && (
                        <VouchersForm
                            cart={cart}
                            total={cartSum()}
                            onSuccessFn={
                                () => onSuccessFn()
                                // setShowPaymentForm(false);
                                // modalprops.closeFn();
                            }
                            onFailureFn={() => onFailureFn()}
                        />
                    )}
                    {paymentMethod === 'other' && (
                        <div
                            style={{
                                border: `#dcdcdc solid 0.5px`,
                                borderRadius: 20,
                                backgroundColor: '#fbfff7',
                                padding: 16,
                            }}
                        >
                            <Typography>
                                Merci de nous contacter pour vous assister au{' '}
                                <a href='tel:+33238698069'>
                                    +33 (0) 2 38 69 80 69
                                </a>{' '}
                                ou par email sur{' '}
                                <a href={(REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? 'mailto:netirrig@seabex.com' : 'mailto:contact@seabex.com'}>
                                {(REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? 'netirrig@seabex.com' : 'contact@seabex.com'}
                                </a>
                            </Typography>
                        </div>
                    )}
                </>
            )}

            {!verifying && cartError && cartErrorObject && (
                <div
                    style={{
                        color: 'red',
                        border: `red solid 2px`,
                        borderRadius: 20,
                        padding: 24,
                        marginTop: 16,
                    }}
                >
                    Il y a une erreur dans votre commande dont ci-dessous les
                    détails. Si cette erreur persiste n'hésitez pas à nous
                    contacter au{' '}
                    <a href='tel:+33238698069'>+33 (0) 2 38 69 80 69</a> ou par
                    email sur{' '}
                    <a href='mailto:netirrig@seabex.com'>netirrig@seabex.com</a>{' '}
                    :<br />
                    <br />
                    Erreur code : {cartErrorObject['code']}
                    <br />
                    Message :{' '}
                    {cartErrorObject['message'][currentLanguage ?? 'fr']}
                    <br />
                </div>
            )}
        </>
    );
};

export default PaymentManager;

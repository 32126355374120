
import React from 'react';

import MathJax from 'react-mathjax2'
import { Button, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog } from '@material-ui/core/';
import { vocabulary } from '../Strings';
import {  HighlightOff } from '@material-ui/icons';

const SelectedFormula = ({ open, handleClose, Transition, currentFormula }) => {
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xs'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {vocabulary.formula}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {currentFormula ?
                            <MathJax.Context input='tex'>
                                <div>
                                    <MathJax.Node inline>{currentFormula}</MathJax.Node>
                                </div>
                            </MathJax.Context>
                            : null
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color="secondary">
                       <HighlightOff />  {vocabulary.close}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default SelectedFormula
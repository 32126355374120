import React, { useEffect, useState } from 'react'
import { vocabulary } from '../../Strings';
import { StyledSelect } from '../../../ui/forms';
import { GetAreasTree, GetPartnerAreasTree } from '../../../globalsTools/network';
import { logger } from '../../../globalsTools/utilities';
import { Button, Chip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';

const AreaSelect = ({ onChangeFn, multipleSelect, title, partnerId, ...props }) => {

  const [areaId, setAreaId] = useState(multipleSelect ? props?.selectedAreas ?? [] : '');
  const [areasList, setAreasList] = useState([]);
  const [allParcelsAdded, setAllParcelsAdded] = useState(false);

  useEffect(() => {
    if (partnerId) {
      GetPartnerAreasTree(partnerId, setAreasList)
    } else {
      GetAreasTree(setAreasList)
    }
  }, [])

  const renderItem = (item) => {
    return <div>{
      (item.level > 1 ? (item.level === 2 ? <span style={{ padding: 2, backgroundColor: '#578757', color: 'white', borderRadius: 10 }}>└─&gt; {vocabulary.subArea} </span>
        : <span style={{ padding: 2, marginInlineStart: 35, backgroundColor: '#eda014', color: 'white', borderRadius: 10 }}>└─&gt;&gt; {vocabulary.sector} </span>)
        : '')}&nbsp;&nbsp;{item.name}</div>
  }


  const formatSelection = (selection) => {
    return (multipleSelect ? selection.filter(element => typeof element == "string") : selection)
  }

  const addAll = () => {
    if (multipleSelect) {
      setAreaId(areasList.map(element => element.id))
      onChangeFn(areasList.map(element => element.id))
      setAllParcelsAdded(true)
    }
  }


  return (
    <>
      {areasList && areasList.length > 0 ?
        <>
          <StyledSelect
            value={areaId}
            rules={{ required: true }}
            onChange={(event) => { setAreaId(formatSelection(event.target.value)); onChangeFn(formatSelection(event.target.value)) }}
            name='Area'
            label={title ?? vocabulary.site}
            autoWidth
            disabled={areasList === null ? true : false}
            data={areasList}
            valueField='id'
            {...(multipleSelect && { multiple: true })}
            render={renderItem}
            emptyDataMsg={vocabulary.noAreaCreatedLabel}
            {...(multipleSelect && {
              renderValue: (selected) => {
                return (
                  <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                    {selected.map((value) => (
                      <Chip style={{ margin: 2 }} key={value} label={areasList && areasList.find(item => {
                        if ((item.id === value.id) || (item.id === value)) {
                          return item
                        }
                      })?.name} />
                    ))}
                  </div>
                )
              }
            })}
            {...props}
          />
          {multipleSelect && !allParcelsAdded && <Button title='Ajouter toutes les parcelles' onClick={() => addAll()}>Ajouter toutes les parcelles</Button>}
        </>
        :
        <StyledLoadingRow />
      }
    </>
  );
}

export default AreaSelect;
/* eslint-disable eqeqeq*/
/* eslint-disable no-unreachable*/
/* eslint-disable no-useless-concat*/

import React from "react";

// import { Timeline } from "react-beautiful-horizontal-timeline";
// import { withStyles } from '@material-ui/core/';
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import PropTypes from 'prop-types';



import "./styles.css";

const EventHorizantalTimeLine = ({ data }) => {

  return (
    <div style={{ width: '100%', marginTop: '40px' }} >
      {/* <Timeline
        myList={data}
        labelWidth={300}
        amountMove={350}
        lineColor={"#03465b"}
        darkMode={false}
        eventTextAlignCenter={true}
        showSlider={true}
        arrowsSize={false}
      /> */}
    </div>
  );
}

// EventHorizantalTimeLine.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
export default EventHorizantalTimeLine;

import React, { useEffect, useState } from 'react';
import { CreateNetirrigSols, DeleteNetirrigSols, GetNetirrigSols, UpdateNetirrigSols } from '../../../../globalsTools/network';
import { StyledMaterialTable } from '../../../../ui/display';
import { useSnackbar } from 'notistack';
import { vocabulary } from '../../../Strings';




const NetirrigSolsList = () => {

    const [netIrrigSols, setNetIrrigSols] = useState([])

    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {
        GetNetirrigSols(setNetIrrigSols)
    }, [])


    const COLUMNS = [
        { title: "sg_code", field: 'sg_code' },
        { title: "sg_nom", field: 'sg_nom' },
        { title: "sg_descriptif", field: 'sg_descriptif' },
        { title: "sg_id", field: 'sg_id' },
        { title: "Expr1", field: 'Expr1' },
        { title: "sd_code", field: 'sd_code' },
        { title: "sd_nom", field: 'sd_nom' },
        { title: "sd_descriptif", field: 'sd_descriptif' },
        { title: "sd_id", field: 'sd_id' },
        { title: "sg_code", field: 'sg_code' },
        { title: "Expr2", field: 'Expr2' },
        { title: "Taux de cailloux", field: 'cs_tcx' },
        { title: "Chape Calcaire", field: 'cs_enracin' },
        { title: "Horizon 1", field: 'cs_eph1ru' },
        { title: "RU 1", field: 'cs_ru1' },
        { title: "Horizon 2", field: 'cs_eph2ru' },
        { title: "RU 2", field: 'cs_ru2' },
        { title: "RU 3", field: 'cs_ru3' },

    ];


    const operationCallBack = (r) => {  
        if (r && [200,204].includes(r.status)) {

            GetNetirrigSols(setNetIrrigSols)
            snackbar(vocabulary.operationSucc, 'success')

        }

        else {
            snackbar(vocabulary.errorOccurred, 'error')

        }


    }

    return (
        <>
            <StyledMaterialTable
                title="Liste des Sols NETIRRIG"
                data={netIrrigSols}

                columns={COLUMNS}

                editable={{
                    onRowAdd: (newData) => {
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                CreateNetirrigSols(newData, operationCallBack)
                                
                                resolve();
                            }, 1000);
                        });
                    },
                    onRowUpdate: (newData) => {
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                UpdateNetirrigSols(newData, operationCallBack)
                                resolve();
                            }, 1000);
                        });
                    },
                    onRowDelete: (oldData) => {
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                DeleteNetirrigSols(oldData.id, operationCallBack)
                                resolve();
                            }, 1000);
                        });
                    },
                }}


                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 40, 60, 80, 100],

                }}
            />

        </>
    )
}

export default NetirrigSolsList;
/* eslint-disable  dot-location */

import React, { useState, useEffect } from 'react';

import { vocabulary } from '../Strings';
import AlertDialog from "../Components/alertDialog";

import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import { GetAreaDetails, DeleteArea } from "../../globalsTools/network";
import { StyledStaticMap } from "../../ui/display";
import { useSnackbar } from 'notistack';
import AddArea from './AddArea';
import {Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/AddCircleOutline';

const ParcelDetailsList = ({ itemRow, setMapView, setGoToSite, setTab, editingItem }) => {

    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [areaDetailsList, setAreaDetailsList] = useState(null)

    useEffect(() => {

        if (itemRow) {
            GetAreaDetails(itemRow.id, setAreaDetailsList)
        }

    }, [itemRow])
    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const convertSize = (size) => {
        return Number(size / 10000).toFixed(2) + " " + vocabulary.hectar
    }
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenType(!openType)
            setOpenEdit(!openEdit)
        } else {
            setOpenType(newStatus)
            setOpenEdit(newStatus)
        }
    }
    const handleDeleteArea = (item) => {
        DeleteArea(item.id
            , function (r) {
                if (r) {
                    if (r.status === 204) {
                        snackbar(item.name + ' ' + vocabulary.deleteMsg, 'success')
                        GetAreaDetails(itemRow.id, setAreaDetailsList)

                    } else {
                        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
                    }
                }
            })
    }
    const GoToSelectedSite = (row) => {
        setMapView(true)
        setGoToSite(true)
        editingItem(row)
        setTab(1)
    }

    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }

    const contextValue = { open: openType, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    return (

        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            <StyledCard variant="outlined" elevation={1} borderRadius={0} additionalStyle={{ border: 'none', borderLeft: '5px solid #c2e0a1', margin: 6 }}>

                {areaDetailsList ?
                    areaDetailsList.childs.length > 0 ?
                        <StyledMaterialTable
                            title={vocabulary.sector}
                            data={areaDetailsList && areaDetailsList.childs}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                            columns={[
                                { title: vocabulary.name_label, field: 'name' },
                                { title: vocabulary.description, field: 'description' },

                                { title: vocabulary.size, field: 'size', render: rowData => <>{convertSize(rowData.size)}</> },

                                { title: vocabulary.map, field: 'staticmap', render: rowData => <StyledStaticMap image={rowData.staticmap} base64={true} height={80} /> },
                            ]}
                            actions={[
                                rowData => ({
                                    icon: 'map',
                                    tooltip: vocabulary.mapView,
                                    onClick: (event, row) => GoToSelectedSite(row),
                                    disabled: rowData.id < 2000
                                }),
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => onEdit(row)
                                },
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),

                                    disabled: row.id < 2000
                                }),


                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false

                            }}
                        />
                        : <div className='emptyParcelLabel'>
                            {vocabulary.emptySectorLabel}
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={()=>GoToSelectedSite(itemRow)}
                            >
                             <AddIcon /> &nbsp;  {vocabulary.add}
                            </Button>
                        </div>
                    :
                    <StyledLoadingRow />


                }
                {openEdit &&
                    <AddArea width={745}
                        closeFn={() => {
                            return (
                                setOpenEdit(false),
                                setIsEditing(false)
                            )
                        }
                        }
                        withoutContext={true}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetAreaDetails(item.id, setAreaDetailsList)}
                        selectedAreaId={item.id}
                        areasList={areaDetailsList && areaDetailsList.childs}
                        parcelEdit={true}

                    />
                }
                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.name}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteArea(item)

                                    ;
                            }

                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}
                {


                }

            </StyledCard>
        </ModalContext.Provider>
    )
}
export default ParcelDetailsList
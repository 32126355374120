import React, { useEffect } from 'react';
import { vocabulary } from '../../Strings';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, loadAllCountriesWithBoundry } from '../../../actions';
import CountiresList from './GetDataCountryList'
import { CircularProgress } from '@material-ui/core/';

const UserGeoDataManagement = (props) => {

    const dispatch = useDispatch()
    const countries = useSelector(state => state.countries.data)
    const appLoading = useSelector(state => state.app.appLoading)
    const currentLanguage = useSelector(state => state.app.currentLanguage)

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.userGeoDataManagement));
        dispatch(loadAllCountriesWithBoundry())
        document.title=vocabulary.userGeoDataManagement



    }, [dispatch,currentLanguage]); // N’exécute l’effet que si count a changé
    return (

        <>
            <div style={{
                visibility: appLoading ? 'visible' : 'hidden',
                position: 'absolute',
                left: '50%',
                top: '45%',

            }}>
                <CircularProgress size={50} />
            </div>
               
                 <CountiresList
                    countries={countries}
                />
            


        </>
    )

}
export default UserGeoDataManagement
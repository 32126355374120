/* eslint-disable  no-useless-concat */

import React, { useEffect, useState, useCallback } from 'react'
import { ArrowDropDown } from '@material-ui/icons'

//import { CYCLE_PHASE_INITIALE, CYCLE_PHASE_DEVELOPPEMENT, CYCLE_PHASE_ARRIERE_SAISON, CYCLE_PHASE_MI_SAISON } from '../../App/globalConsts'
import { Typography, Tooltip, Grid, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { vocabulary } from '../../App/Strings';
import { formatDateStr } from '../../utils';
import { useSelector } from 'react-redux';
// import EditPhaseModal from '../../App/Cycle/EditPhaseModal'
// import { Button } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
const StyledProgressCycle = ({ dayToday, cycle, cycleProgress, onPhaseClick }) => {

    const [globalCycle, setGlobalCycle] = useState(null)
    const [totalDays, setTotalDays] = useState(null)
    const [progressBarRender, setProgressBarRender] = useState(null)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)


    const CycleProductionStyle = [
        { backgroundColor: "#02BBCA" }, { backgroundColor: "#F39402" }, { backgroundColor: "#E46C6D" }, { backgroundColor: "#FED352" },
        { backgroundColor: "#7877B8" }, { backgroundColor: "#AE2C47" }, { backgroundColor: "#ECC955" }, { backgroundColor: "#6A950E" },
        { backgroundColor: "#860202" }, { backgroundColor: "#4396AF" }, { backgroundColor: "#D500F9" }, { backgroundColor: "#76FF03" },
        { backgroundColor: "#00BFA5" }, { backgroundColor: "#827717" }, { backgroundColor: "#BA68C8" }, { backgroundColor: "#C5E1A5" }
    ]
    const Empty = { style: { backgroundColor: "#cdcdcd" }, label: "Phase d'Arrière Saison" };


    useEffect(() => {
        if (cycleProgress) {
            for (let [key, value] of cycleProgress.entries()) {
                let phase = Object.values(globalCycle).filter(object => object.name === key)[0]
                let phaseLength = phase?.length
                let width = ((value?.length / phaseLength) * 100).toFixed(2)
                let widthEmpty = (((phaseLength - value?.length) / phaseLength) * 100).toFixed(2)

                if (width > 0 && widthEmpty > 0) {
                    // setCurrentPhaseLabel(phase?.name)
                    break;
                }
            }
            setProgressBarRender(buildDiv(cycleProgress))

        }
    }, [cycleProgress])



    const PhaseNames = () => {

        if (cycleProgress) {
            let index = 0
            let elements = Array.from(cycleProgress?.entries(), ([key, value]) => {
                let phase = Object.values(globalCycle).filter(object => object.name === key)[0]
                let phaseLength = phase?.length
                let colorIndex = index
                let inProgress = Math.floor(((value.length / phaseLength) * 100)) > 0
                index++;
                return (
                    <Grid item xs={4} container direction='row' wrap='nowrap'
                        key={key}
                        onClick={ 
                            () => onPhaseClick({ 'stade': key, 'specs': value, 'phase': phase })
                        } >
                    <Grid item style={{marginInlineEnd:8}}>
                        <Box
                            height={20}
                            width={20}
                            style={{
                                cursor : 'pointer',
                                backgroundColor: inProgress
                                    ? CycleProductionStyle[colorIndex]?.backgroundColor
                                    : Empty?.style?.backgroundColor
                            }}
                        />
                    </Grid>
                    <Grid item>

                        <Typography style={{ marginBottom: 5, textAlign:'start' }}>
                            {phase?.name + ' (' + formatDateStr(value.start_date, currentLanguage) + ' ' + vocabulary.to + ' ' + formatDateStr(value.end_date, currentLanguage)+')'}
                        </Typography>
                    </Grid>



                </Grid>
                ) 
            });

            return <Grid container item direction='row'>
                    { elements }
                </Grid>

        }
        return null;

    }

    const buildDiv = (cycleProgress) => {
        let buffer = []
        let color = 0;
        for (let [key, value] of cycleProgress.entries()) {
            let phase = Object.values(globalCycle).filter(object => object.name === key)[0]
            let phaseLength = phase?.length
            let width = ((value.length / phaseLength) * 100).toFixed(2)
            let widthEmpty = (((phaseLength - value.length) / phaseLength) * 100).toFixed(2)
            let flexGrowCalc = phaseLength / totalDays

            buffer.push(
                <Tooltip key={'phase_' + key} title={phase?.name + ' ' + '(' + value.length + '/ ' + phase?.length + " " + vocabulary.dayLabel + ') ' + vocabulary.from + ' ' + value.start_date + ' ' + vocabulary.to + ' ' + value.end_date} arrow>
                    <div className='progressBar' key={key} onClick={() => { onPhaseClick({ 'stade': key, 'specs': value, 'phase': phase }) }}
                        style={{
                            //width: barWidth,
                            position: "relative",
                            marginRight: 2,
                            display: "flex",
                            flexGrow: flexGrowCalc,
                            cursor: 'pointer'
                        }}>
                        <div style={{
                            ...CycleProductionStyle[color],
                            borderRadius: (color === 0 ? '12px 0px 0px 12px' : (color + 1) === cycleProgress.length ? '0px 12px 12px 0px' : undefined),
                            position: "relative",
                            width: width + "%",
                            height: '100%'
                        }}

                        >

                        </div>
                        <div key={key + 'Empty'} style={{
                            ...Empty.style,
                            position: "relative",
                            borderRadius: (color === 0 && width === 0 ? '12px 0px 0px 12px' : color + 1 === cycleProgress.length ? '0px 12px 12px 0px' : undefined),
                            width: widthEmpty + "%",
                            height: '100%',
                            display: widthEmpty === 0 ? 'none' : undefined
                        }}
                        ></div>
                        <ArrowDropDown
                            style={{ position: "absolute", top: -15, right: -12, cursor: 'pointer' }}

                        // onClick={() => handleOpenModal()}

                        />
                        <Typography variant="body1" style={{ fontSize: 10, position: "absolute", bottom: -30, right: 0, paddingRight: 4, paddingTop: 4, borderRight: 'solid 1px', width: `calc(100%)`, whiteSpace:'nowrap', textOverflow: 'ellipsis',overflow: 'hidden' }}>{phase["name"]}</Typography>

                    </div>
                </Tooltip>
            )
            color += 1
        }
        return (<>{buffer}</>)
    }

    useEffect(() => {

        if (cycle) {
            let totalDays = 0

            for (const [key, value] of Object.entries(cycle)) {
                totalDays += value.length
            }
            setTotalDays(totalDays)
            // let phases = getPhasePercentages(dayToday, cycle, totalDays)
            // logger("Cycle Phases", phases, cycle)
            setGlobalCycle(cycle)
            // setCycleProgress(phases)
        }
    }, [dayToday, cycle])
    return (
        <Grid container direction='column'>
            <Grid item>
                <div style={{ display: 'flex', width: `calc(95%)`, flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: "flex", width: `calc(100%)`, height: 28, marginBottom: 30, position: 'relative', borderRadius: 12, /* overflow:'hidden' */ }}>
                        {progressBarRender && <> {progressBarRender} </>}
                    </div>
                </div>
            </Grid>
            <Grid item style={{marginTop:20, display:'none'}}>
                <Accordion>
                    <AccordionSummary>
                        <Typography>Détails des stades phénologiques</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PhaseNames />
                    </AccordionDetails>

                </Accordion>
            </Grid>
        </Grid>
    )
}

export default StyledProgressCycle



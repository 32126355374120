import { Grid } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import StyledAutoComplete from '../../../../../ui/forms/StyledAutoComplete';
import AreaSelect from '../../../../Sites/Components/AreaSelect';
import { vocabulary } from '../../../../Strings';
import { GetPartnerDevices } from '../../../../../globalsTools/network';
import { useParams } from 'react-router-dom';


const WeenatConfigureForm = forwardRef(({ currentItem, partnerId, isEditing }, ref) => {

    const [data, setData] = useState({});
    const [areas, setAreas] = useState([]);
    const [device, setDevice] = useState()
    const [isLoading, setIsLoading] = useState(false)
    let { id } = useParams();

    useEffect(() => {


        if (currentItem) {
            setAreas(currentItem.areas)
            setDevice({
                partner_id: partnerId,
                device_id: currentItem.device_id,
                device_name: "device test"
            })
        
        }

        if (!isEditing) {
            setIsLoading(true)
            GetPartnerDevices(partnerId, data => {
                setData(data)
                setIsLoading(false)
            });
        }

    }, [isEditing, currentItem])


    useImperativeHandle(ref, () => ({
        getData: () => ({
            device,
            areas: areas
        }),
    }));


    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={12}>
                {
                    !isLoading && !isEditing &&
                    <StyledAutoComplete
                        data={data}
                        optionLabel={(option) => option && option.name ? option?.name : ''}
                        onChange={(_, newValue) => {
                            setDevice({
                                partner_id: partnerId,
                                device_id: String(newValue?.id),
                                device_name: newValue?.name,
                            })
                        }}
                        label={vocabulary.deviceID}
                        value={device}
                        styles={{ width: '100%' }}
                    />
                }
            </Grid>

            <Grid item xs={12} md={12}>
                <AreaSelect partnerId={id} onChangeFn={setAreas} selectedAreas={areas} multipleSelect title={vocabulary.related_areas} />
            </Grid>


        </Grid>
    );
})

export default WeenatConfigureForm


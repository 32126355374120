import _ from "lodash";
import { useCallback, useState } from "react";
import { logger } from "../../globalsTools/utilities";

export const useCart = (defaultData = {}) => {
  const [cartData, setCartData] = useState(defaultData);

  const addCartItem = (newItem) => {
    setCartData((data) => ([...data, {
      id: newItem.id,
      type: newItem.type,
      quantity: Number(newItem.quantity) ?? 1,
      price: Number(newItem.price),
      area_id: newItem.area_id,
      total_in_vat: newItem.total_in_vat,
      total_x_vat: newItem.total_x_vat,
      tax_rate: newItem.tax_rate,
      provider: newItem.provider,
      label: newItem.label,
      voucherCode: newItem.voucherCode,
      unique_group_id: newItem.unique_group_id
    }]));
  }

  const removeCartItem = (oldItem) => {
    // TO BE MODIFIED
    setCartData((data) => (_.remove(data, (obj) => obj.id !== oldItem.id)));
  }

  const emptyCart = () => {
    setCartData([]);
  }

  const updateCart = (newCart) => {
    setCartData(newCart);
  }

  const cartSum = useCallback(
    (currencyDecimals = 2) => {
      let total = _.sumBy(
        cartData, "total_in_vat"
      );
      return Number(total).toFixed(currencyDecimals);
    },
    [cartData],
  )

  const cartSumHT = useCallback(
    (currencyDecimals = 2) => {
      let total = _.sumBy(
        cartData, "total_x_vat"
      );
      return Number(total).toFixed(currencyDecimals);
    },
    [cartData],
  )


  return {
    cartData,
    addCartItem,
    removeCartItem,
    updateCart,
    cartSum,
    cartSumHT,
    emptyCart
    //... your other methods
  };
}


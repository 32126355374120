import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StyledCircleText from '../../ui/display/StyledCircleText';
import { StyledSelect } from '../../ui/forms';
import { vocabulary } from '../Strings';
import { DEV_ENVIRONMENT, PROD_ENVIRONMENT } from '../globalConsts';
import { REACT_APP_ENV_VERSION } from '../../config';
import { convertNeedInHours } from '../../globalsTools/NeedInHours';
import { logger } from '../../globalsTools/utilities';


const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 'medium',
    // flexBasis: '33.33%',
    marginRight: 12,
    fontWeight: 'bold',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.palette.text.secondary,
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));


const WaterReqCardNetirrig = ({ waterNeedData, usedUnit }) => {

  const [startDate, setStartDate] = useState(moment(waterNeedData && waterNeedData.waterBalance && waterNeedData.waterBalance.calculation_date, 'YYYY-MM-DD'));

  const currentLanguage = useSelector((state) => state._globalState.preferred_language);
  // const recalculatingCycles = useSelector(state => state.cycle.recalculatingCycles)
  // const currentRecalculatedCycle = recalculatingCycles.includes(waterNeedData?.production_cycle?.id)
  const [unity, setUnity] = useState(vocabulary.metreJour);

  const unityType = [
    vocabulary.metreJour,
    vocabulary.precipitationUnit,
    vocabulary.hectarMcube
  ];

  const classes = useStyles();

  const convertToUnit = (value, desiredUnit) => {

    const unitConversion = {
      [vocabulary.precipitationUnit]: (val) => val,
      [vocabulary.hectarMcube]: (val) => val * 10,
    };

    const conversionFunction = unitConversion[desiredUnit];

    if (conversionFunction) {
      return Math.round(conversionFunction(Number(value)));
    }

    return Math.round(Number(value));
  };


  return (
    waterNeedData &&
    (

      <Card
        className='onePagerWaterNeed'
        style={{
          width: '100%', borderRadius: '10px'
        }}
        elevation={8}
      >

        <Accordion
          style={{
            width: '100%',
            borderRadius: '10px'
          }}
          defaultExpanded={true}
          elevation={8}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <Typography className={classes.heading}>
              {vocabulary.waterRequirementLabel + ' : ' +
                (currentLanguage === 'en'
                  ? startDate && startDate.format('YYYY/MM/DD')
                  : startDate && startDate.format('DD/MM/YYYY'))}
            </Typography>
            <Typography className={classes.heading} style={{ marginInlineStart: 12 }}>
              {"  " + vocabulary.currentPhaseLabel + ' : ' + ((waterNeedData && waterNeedData.waterBalance) ? waterNeedData.waterBalance.phase_name : '-')}
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            style={{
              // cursor: currentRecalculatedCycle ? 'wait' : 'default',
              // opacity: currentRecalculatedCycle ? '0.1' : 1,
              position: 'relative'
            }}
            className={classes.accordionContent}>
            <div style={{ margin: '10px' }}>
              <StyledSelect
                label={vocabulary.unity}
                value={unity}
                onChange={(event) => {
                  setUnity(event.target.value);
                  usedUnit(event.target.value);
                }}
                name='unity'
                data={unityType}
              />
            </div>
            {
              (waterNeedData && waterNeedData.waterBalance) &&
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Grid style={{ margin: 20, textAlign: 'center' }} item>
                  <Typography style={{ marginBottom: 20, height: 52, fontSize: 16 }}>
                    {vocabulary.easily_useful_Reserve}
                  </Typography>
                  <StyledCircleText height={200} borderColor="#239ed1">
                    <div style={{ fontSize: 80, lineHeight: `102px` }}>{Math.round(Number(waterNeedData.waterBalance.soil_water_volume))}</div>
                    <div style={{ fontSize: 24, lineHeight: `24px` }}>mm</div>
                  </StyledCircleText>
                </Grid>
                <Grid style={{ margin: 20, textAlign: 'center' }} item>
                  <Typography style={{ marginBottom: 20, height: 52, fontSize: 16 }}>
                    {vocabulary.survivalReserve}
                  </Typography>
                  <StyledCircleText height={200} borderColor="#239ed1">
                    <div style={{ fontSize: 80, lineHeight: `102px` }}>{Math.round(Number(waterNeedData.waterBalance.reserve_survi_mm))}</div>
                    <div style={{ fontSize: 24, lineHeight: `24px` }}>mm</div>
                  </StyledCircleText>
                </Grid>
                <Grid style={{ margin: 20, textAlign: 'center' }} item>
                  <Typography style={{ marginBottom: 20, height: 52, fontSize: 16 }}>
                    {vocabulary.todayWaterNeed}
                  </Typography>
                  {
                    [DEV_ENVIRONMENT, PROD_ENVIRONMENT].includes(REACT_APP_ENV_VERSION) ? (
                      <StyledCircleText height={200} borderColor="#239ed1">
                        <div style={{ fontSize: 80, lineHeight: `102px` }}>{convertToUnit(waterNeedData.waterBalance.actual_evapotranspiration, unity)}</div>
                        <div style={{ fontSize: 24, lineHeight: `24px` }}>{unity}</div>
                      </StyledCircleText>
                    ) :
                      (
                        <StyledCircleText height={200} borderColor="#239ed1">
                          <div style={{ fontSize: 80, lineHeight: `102px` }}>{Number(waterNeedData.waterBalance.actual_evapotranspiration).toFixed(1)}</div>
                          <div style={{ fontSize: 24, lineHeight: `24px` }}>mm</div>
                        </StyledCircleText>
                      )
                  }

                </Grid>
                {(waterNeedData && waterNeedData.production_cycle
                  && waterNeedData.production_cycle.irrigation_type
                  && waterNeedData.production_cycle.irrigation_params)
                  && <Grid style={{ margin: `16px 5px`, textAlign: 'center' }} item>
                    <Typography style={{ marginBottom: 20, height: 52, fontSize: 16, maxWidth: 235 }}>
                      {vocabulary.todayWaterNeed} en temps d'irrigation
                    </Typography>
                    <StyledCircleText height={200} borderColor="#239ed1">
                      <div style={{ fontSize: 32, lineHeight: `36px` }}>
                        {Number(waterNeedData.waterBalance.need_for_irrigation) > 0 ?
                          convertNeedInHours(
                            waterNeedData.production_cycle.irrigation_type,
                            waterNeedData.production_cycle.irrigation_params,
                            waterNeedData.waterBalance.need_for_irrigation,
                            currentLanguage
                          )
                          : "Pas besoin d'irrigation"}
                      </div>
                    </StyledCircleText>
                  </Grid>
                }
                <Grid style={{ margin: 20, textAlign: 'center' }} item>
                  <Typography style={{ marginBottom: 20, height: 52, fontSize: 16 }}>
                    {vocabulary.totalPrecipitationForecasted}
                  </Typography>
                  <StyledCircleText height={200} borderColor="#239ed1">
                    <div style={{ fontSize: 80, lineHeight: `102px` }}>{(Number(waterNeedData.waterBalance.total_precipitation)).toFixed(1)}</div>
                    <div style={{ fontSize: 24, lineHeight: `24px` }}>mm</div>
                  </StyledCircleText>
                </Grid>
              </Grid>
            }
          </AccordionDetails>
          {/* {
          (currentRecalculatedCycle) && (
            <Typography style={{
              position: 'absolute',
              cursor : 'wait',
              width: "100px",
              heigth: "100px",
              top: '45%',
              left: '43%',
            }}
              variant='h5'
            >...Calculation
            </Typography>
          )
        } */}
        </Accordion>



      </Card>


    )
  )
}

export default WaterReqCardNetirrig

import React, { useEffect, useState } from 'react';

import {  Typography, Grid,  Paper } from '@material-ui/core/';
 
const WaterNeedParamsItem = ({ title, value, unit, comment, icon }) => {
    return (
        <Grid item style={{ flex: `1 1 0px` }}>
            <Paper elevation={2} style={{ padding: 8, height: 140 }}>
                <Grid container direction='column' alignItems='center'>
                    <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign:'center' }}>
                            <b>{title}</b>
                        </div>
                    </Grid>
                    {icon &&
                        <Grid item>
                            <img src={icon} alt={title} width={48} length={48} />
                        </Grid>
                    }
                    <Grid>
                        <Typography style={{ fontSize: 36 }}>{value}</Typography>
                    </Grid>
                    {/* <Grid item></Grid> */}
                    <Grid item style={{ textAlign: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{comment ?? '-'}</span>
                            {/* <span>{windDirection}</span> */}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default WaterNeedParamsItem

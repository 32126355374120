import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import { setCurrentPage} from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';

import FollowIcon from '@material-ui/icons/HowToReg';
import UnfollowIcon from '@material-ui/icons/PersonAdd'

import 'react-phone-number-input/style.css'
// import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import AddAgentsForm from './AddAgentsForm'
import { GetOrganisationAgents, GetMyOrganizationsandFollowers, UpdateAgent } from '../../globalsTools/network'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const ListallAgents = () => {

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const currentLanguage = useSelector(state => state._globalState.preferred_language)


  const [open, setOpen] = useState(false);
  const [organisationAgentsList, setOrganisationAgentsList] = useState(null);
  const [organizationsandFollowers, setOrganizationsandFollowers] = useState(null);

  useEffect(() => {

    dispatch(setCurrentPage(vocabulary.agents));
    GetMyOrganizationsandFollowers(setOrganizationsandFollowers)
    GetOrganisationAgents(setOrganisationAgentsList)
    document.title = vocabulary.agents

  }, [dispatch]); // N’exécute l’effet qu'a la première render'

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.agents));
    document.title = vocabulary.agents

  }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };
  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const DisableAgent = (row) => {
    UpdateAgent(
      {
        active: false
      }, row.id
      , function (r) {
        if (r) {
          if (r.status === 200) {
            snackbar(vocabulary.agent + ' ' + row.firstname + ' ' + row.lastname + ' ' + vocabulary.disabled, 'success')
            GetOrganisationAgents(setOrganisationAgentsList)
          } else {
            snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
          }
        }
      })


  }

  const EnableAgent = (row) => {
    UpdateAgent(
      {
        active: true
      }, row.id
      , function (r) {
        if (r) {
          if (r.status === 200) {
            snackbar(vocabulary.agent + ' ' + row.firstname + ' ' + row.lastname + ' ' + vocabulary.enabled, 'success')
            GetOrganisationAgents(setOrganisationAgentsList)

          } else {
            snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
          }
        }
      })

  }

  const changeModalStatus = (newStatus) => {
    if (newStatus == null) {
      setOpen(!open)

    } else {
      setOpen(newStatus)
    }
  }

  const contextValueAdd = { open: open, changeModalStatus: changeModalStatus }
  return (
    <ModalContext.Provider value={contextValueAdd}>

      <StyledCard>
        {organisationAgentsList ?
          <StyledMaterialTable
            title={vocabulary.agents}
            data={organisationAgentsList.agents}
            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}
            columns={[
              { title: vocabulary.firstname, field: 'firstname', },
              { title: vocabulary.lastname, field: 'lastname', },
              { title: vocabulary.mobile, field: 'mobile' },
              { title: vocabulary.email, field: 'email', },
              { title: vocabulary.address, field: 'address', render: rowData => <>{JSON.parse(rowData.address).addresse}</> },
              { title: vocabulary.SentNotifNumber, field: 'owner_advices_count' },
              { title: vocabulary.status, field: 'active', render: rowData => <>{rowData.active ? "Active" : "Not active"}</> },


            ]}
            actions={[

              row => ({
                icon: () => row.active ? <FollowIcon /> : <UnfollowIcon />,
                tooltip: row.active ? 'Disable' : 'Active',
                onClick: (event, row) => row.active === true ? DisableAgent(row) : EnableAgent(row),
              }),


              {
                icon: () => <AddIcon fontSize='large' color='primary' />,
                tooltip: vocabulary.newAgent,
                isFreeAction: true,
                onClick: (event) => handleClickOpen()
              }

            ]}
            options={{
              actionsColumnIndex: -1,
              search: true

            }}
          />
          : <StyledCircularProgress size={50} label={vocabulary.loading} />}


        {open &&
          <AddAgentsForm
            open={open}
            onClose={() => {
              handleClose()
            }}
            isEditing={false}
            reftchFunc={() => GetOrganisationAgents(setOrganisationAgentsList)
            }
            organizationsandFollowers={organizationsandFollowers && organizationsandFollowers}
          />}
      </StyledCard>



    </ModalContext.Provider>

  );

}



export default ListallAgents

/* eslint-disable eqeqeq*/

import React, { useRef, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';

import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { createIrrigationType, UpdateIrrigationType } from "../../../globalsTools/network";
import { useSnackbar } from 'notistack';
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation"; 

const IrrigationTypeForm = ({ isEditing, itemId, onClose, item, open, reftchFunc, setIsLoading }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [englishName, setEnglishName] = useState(null)
    const [frenshName, setFrenshName] = useState(null)
    const [arabicName, setArabicName] = useState(null)
    const [description, setDescription] = useState(null)
    const [efficiency, setEfficiency] = useState(null)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);


    const modalContext = useContext(ModalContext)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {
        if (isEditing === false) {
            setEnglishName(null)
            setFrenshName(null)
            setArabicName(null)
            setDescription(null)
            setEfficiency(null)
        }

    }, [open, isEditing])
    useEffect(() => {
        if (item) {
            setEnglishName(JSON.parse(item.name).name_en)
            setFrenshName(JSON.parse(item.name).name_fr)
            setArabicName(JSON.parse(item.name).name_ar)
            setDescription(JSON.parse(item.description).description)
            setEfficiency(item.efficiency)
        }

    }, [item])
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? JSON.parse(item.name)["name_" + currentLanguage] + "  " + vocabulary.updateSucc : vocabulary.irrigationType + " " + vocabulary.addedSucc, 'success')
                reftchFunc(itemId)
                setIsLoading(false)

            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)
            }
        }
    }
    const validateNumber = (value) => {
        const re = /^[0-9]*$/;
        return re.test(value);
    }

    const newIrrigationType = () => {

        setIsLoading(true)
        createIrrigationType(
            {
                name: JSON.stringify({ 'name_en': englishName, 'name_fr': frenshName, 'name_ar': arabicName }),
                description: JSON.stringify({ 'description': description }),
                efficiency: efficiency,
                prm_irrigation_family_id: itemId && itemId

            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        onClose()


    }
    const handleUpdateIrrigationType = () => {

        setIsLoading(true)
        UpdateIrrigationType(
            {
                id: item.id,
                name: JSON.stringify({ 'name_en': englishName, 'name_fr': frenshName, 'name_ar': arabicName }),
                description: JSON.stringify({ 'description': description }),
                efficiency: efficiency,
                prm_irrigation_family_id: itemId && itemId

            }, r => operationCallBack(r)
        )
        setOpenEditConfirmation(false)
        onClose()

    }
    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? newIrrigationType() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={500}
                title={isEditing === false ? vocabulary.addIrrigationType : vocabulary.editIrrigationType}
            >
                <form
                    onSubmit={handleSubmit(newIrrigationType)}
                    ref={form}

                >
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.englishName}
                        name="englishName"
                        label={vocabulary.name_en}
                        type="text"
                        fullWidth
                        onChange={(event) => { setEnglishName(event.target.value) }}
                        value={englishName}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.frenshName}
                        name="frenshName"
                        label={vocabulary.name_fr}
                        type="text"
                        fullWidth
                        onChange={(event) => { setFrenshName(event.target.value) }}
                        value={frenshName}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.arabicName}
                        name="arabicName"
                        label={vocabulary.name_ar}
                        type="text"
                        fullWidth
                        onChange={(event) => { setArabicName(event.target.value) }}
                        value={arabicName}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true, validate: validateNumber }}
                        errors={errors.efficiency}
                        name="efficiency"
                        label={vocabulary.efficiency}
                        fullWidth
                        type="text"
                        onChange={(event) => { setEfficiency(event.target.value) }}
                        value={efficiency}
                    />

                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.description}
                        name="description"
                        label={vocabulary.description}
                        type="text"
                        fullWidth
                        onChange={(event) => { setDescription(event.target.value) }}
                        value={description}
                        maxRows={4}
                        multiline={true}
                    />

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={JSON.parse(item.name)['name_' + currentLanguage]}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateIrrigationType();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}
        </>
    )

}
export default IrrigationTypeForm

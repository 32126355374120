/* eslint-disable eqeqeq*/

import React, { useRef, useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../../ui/forms/StyledInput";
import StyledModalFooter from '../../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../../ui/layout'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { CreateItem, UpdateItem, GetFinancialCategories } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';
import StyledSelect from "../../../ui/forms/StyledSelect";
import AlertEditDialogConfirmation from "../../Components/AlertEditDialogConfirmation";
import StyledSeletctFinancialCategory from "../../../ui/display/FinancialCategoryStyledSeletct";

const ItemAddForm = ({ isEditing, onClose, item, open, width, refetchFunc, setIsLoading, expensesProp, financialGateroiesIdProp }) => {

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    // const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const userId = useSelector(state => state.app.userid)
    const userType = useSelector(state => state.app.userrights)

    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [price, setPrice] = useState(null);
    const [type, setType] = useState(null);
    const [qte, setQte] = useState(1);
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);
    const [financialGateroiesId, setFinancialGateroiesId] = useState(null);
    const [financialCategorieList, setFinancialCategorieList] = useState(null)

    const ItemTypeList = [
        {
            title: vocabulary.expenses,
            id: 'expenses'
        }
        , {
            title: vocabulary.revenues,
            id: 'revenues'
        }
    ]
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    useEffect(() => {
        GetFinancialCategories(setFinancialCategorieList)

    }, [])
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing == true ? item.title + "  " + vocabulary.updateSucc : vocabulary.item + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)


            } else if (r.status === 400) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)

            }
        }
    }
    useEffect(() => {

        if (isEditing === false && expensesProp == false) {
            setTitle(null)
            setDescription(null)
            setType(null)
            setQte(1)
            setFinancialGateroiesId(null)
            setPrice(null)
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (expensesProp == true) {
            setFinancialGateroiesId(financialGateroiesIdProp)
        }

    }, [financialGateroiesIdProp, expensesProp])

    useEffect(() => {
        if (item) {
            setTitle(item.title)
            setDescription(item.description)
            setType(item.type)
            setQte(item.qte)
            setPrice(item.price)
            setFinancialGateroiesId(item.financial_category_id)


        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé
    const handleAddItem = (data) => {
        setIsLoading(true)
        CreateItem(
            {
                title: title,
                description: description,
                price: price,
                qte: qte,
                type: type,
                financial_category_id: financialGateroiesId,
                userId: userType == "Super" ? null : userId

            }, r => operationCallBack(r)
        )
        onClose()

    }

    const validForm = () => {
        if (!title || !price || !type || !financialGateroiesId) {
            return true
        }
        else return false

    }
    const handleUpdateItem = () => {
        setIsLoading(true)

        UpdateItem(
            {
                id: item.id,
                title: title,
                description: description,
                price: price,
                qte: qte,
                type: type,
                financial_category_id: financialGateroiesId,
                userId: userType == "Super" ? null : userId


            }, r => operationCallBack(r)
        )
        onClose()


    }


    const actions = [
        { title: isEditing === false ? vocabulary.add : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing == false ? handleAddItem() : setOpenEditConfirmation(true), disabled: isEditing == false ? validForm() : false },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => expensesProp ? onClose() : modalContext.changeModalStatus(false) }
    ]
    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                width={width}
                closeFn={expensesProp ? onClose : undefined}

                title={isEditing === false ? vocabulary.add + ' ' + vocabulary.item : vocabulary.editLabel + ' ' + vocabulary.item}
            >
                <form
                    onSubmit={handleSubmit(handleAddItem)}
                    ref={form}
                >

                    <StyledInput
                        value={title}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.title}
                        onChange={(event) => { setTitle(event.target.value) }}
                        name='title'
                        type="text"
                        fullWidth
                        label={vocabulary.titleLabel}
                    />
                    <StyledInput
                        register={register}
                        name="description"
                        label={vocabulary.description}
                        value={description}
                        onChange={(event) => { setDescription(event.target.value) }}
                        errors={errors.description}
                        maxRows={10}
                        fullWidth
                        multiline={true}

                    />
                    <StyledInput
                        register={register}
                        rules={{ required: true }}
                        errors={errors.price}
                        name="price"
                        label={vocabulary.price}
                        onChange={(event) => { setPrice(Number(event.target.value)) }}
                        value={price}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 999999999 } }}
                        fullWidth

                    />

                    <StyledInput
                        register={register}
                        rules={{ required: false }}
                        errors={errors.qte}
                        name="qte"
                        label={vocabulary.quantity}
                        onChange={(event) => { setQte(Number(event.target.value)) }}
                        value={qte}
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        fullWidth

                    />
                    <StyledSelect
                        value={type}
                        register={register}
                        rules={{ required: false }}
                        errors={errors.type}
                        onChange={(event) => {
                            setType(event.target.value)
                        }}
                        name='type'
                        label={vocabulary.type}
                        fullWidth
                        data={ItemTypeList}
                        valueField='id'
                        labelField='title'
                        styles={{ width: '100%' }}


                    />

                    <StyledSeletctFinancialCategory
                        value={financialGateroiesId}
                        // register={register}
                        // rules={{ required: true }}
                        errors={errors.irrigationMode}
                        onChange={(event) => {
                            setFinancialGateroiesId(event.target.value)
                        }}
                        name='financialGateroiesId'
                        label={vocabulary.financialCategoryLabel}
                        fullWidth
                        data={expensesProp ? [{ 'id': financialGateroiesIdProp, 'title': financialCategorieList && financialCategorieList.data.find(el => el.id == financialGateroiesIdProp).title }] : financialCategorieList && financialCategorieList.data}

                        // data={financialCategorieList && financialCategorieList.data}
                        valueField='id'
                        labelField='title'
                        disabled={financialCategorieList == null ? true : false}
                        refetchFunc={() => GetFinancialCategories(setFinancialCategorieList)}


                    />

                    <StyledModalFooter actions={actions} />

                </form>
            </StyledModal>

            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={item.title}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateItem();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />
            }

        </>
    )

}
export default ItemAddForm

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Bar, BarChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { StyledMaterialTable } from '../../ui/display'
import { vocabulary } from '../Strings'

const IrrigationSummary = ({ data }) => {

    const [irrigationData, setIrrigationData] = useState([])

    useEffect(() => {
        if (data) {
            setIrrigationData(
                data.map((element, index) => {
                    if (element) {
                        return {
                            ...element,
                            cumul: _.sumBy(_.take(data, index + 1),
                                (o) => Math.round(Number(o.total_irrigation_sum)))
                        }
                    }
                }
                )
            )
        }
    }, [data])


    return (
        <Accordion
            style={{
                width: '100%',
                borderRadius: '10px'
            }}
            defaultExpanded={true}
            elevation={8}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
            >
                <Typography id='cultureName'>
                    {vocabulary.irrigationPerMonth}
                </Typography>

            </AccordionSummary>

            <AccordionDetails>

                <Grid container direction='row' style={{ flexWrap: 'nowrap' }}>
                    <Grid item style={{ width: 450 }}>
                        <StyledMaterialTable
                            title={vocabulary.totalCumulativePrecipitation}
                            data={irrigationData}

                            columns={[
                                { title: "Mois", field: 'month_year' },
                                { title: "Total de Irrigations", field: 'total_irrigation_sum' },
                                { title: "Cumul de Irrigations", field: 'cumul' },

                            ]}

                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false,
                            }}
                        />
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                        <ResponsiveContainer>
                            <BarChart width={500}
                                height={1000} data={data} margin={{
                                    top: 20, right: 20, bottom: 30, left: 20,
                                }}>
                                <XAxis dataKey="month_year" label={<Label value="Mois" position="centerBottom" dy={20} />} />
                                <YAxis />
                                <Tooltip />
                                {/* <Legend wrapperStyle={{top: -20}} /> */}
                                <Bar dataKey="total_irrigation_sum" name="Irrigations" fill="#1c4ba3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Grid>
                </Grid>

            </AccordionDetails>
        </Accordion>
    )
}

export default IrrigationSummary
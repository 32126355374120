/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import { Card, CardContent, CardHeader, Divider, FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { getAreaDeployedDeviceEquipmentByType, getDeployedDeviceStatus, SendActionToDeployedDeviceEquipment } from "../../globalsTools/network";
import { styles } from '../globalStyle';
import { vocabulary } from "../Strings";

import { logger } from "../../globalsTools/utilities";
import { StyledMaterialTable } from "../../ui/display";
import StyledLoadingRow from "../../ui/display/StyledLoadingRow";
var _ = require('lodash');

const ActionsCard = ({ areaId, devicesStatus }) => {
    // { initialAreaId, handleChangeFn, deployedDevice, siteName, initialGetwayID, classes, siteSetter, parcelSetter, sectorSetter, getwayIdSetter, showdetails = true, lastRealtimeData, setAreaId, loading, setLoading }

    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [areaDeployedDeviceEquipment, setAreaDeployedDeviceEquipment] = useState([])
    const [alertConfirmAction, setAlertConfirmAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState(null)


    const sendAction = (row, action) => {
        
        SendActionToDeployedDeviceEquipment({
            device_id: row?.deployed_device?.id,
            equipment_id: row?.equipment.id,
            command: action,
            port: row?.port
        })
        
    }

    useEffect(() => {
        setIsLoading(true)
        getAreaDeployedDeviceEquipmentByType('Action', areaId, setAreaDeployedDeviceEquipment)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        logger("areaDeployedDeviceEquipment : ", areaDeployedDeviceEquipment)
        if (areaDeployedDeviceEquipment.data && Array.isArray(areaDeployedDeviceEquipment.data)) {
            areaDeployedDeviceEquipment.data.filter((v, i, a) => a.findIndex(item => item?.deployed_device?.id === v?.deployed_device?.id)==i).forEach(
                element => { 
                    logger("Deployed Device : ", element.deployed_device.id) ; 
                    getDeployedDeviceStatus( element.deployed_device.id )
                }
            )
        }
    }, [areaDeployedDeviceEquipment])

    useEffect(() => {
      logger("devicesStatus", devicesStatus)
    }, [devicesStatus])
    
    

    return (
        <>

            <Card style={{ marginBottom: '10px' }}>

                <CardHeader
                    title={vocabulary.selectAreaToMonitor}
                    subheader={vocabulary.filterAdvice}
                />

                <Divider />
                <CardContent>
                    {(!isLoading && areaDeployedDeviceEquipment?.data) ?
                        <StyledMaterialTable
                            title={vocabulary.activityType}
                            data={areaDeployedDeviceEquipment?.data}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}

                            columns={[
                                { title: vocabulary.identifier, field: 'deployed_device.identifier' },
                                { title: vocabulary.equipmentLabel, render: rowData => <>{rowData.equipment ? JSON.parse(rowData?.equipment?.name)['name_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.site, field: 'area.name' },
                                { title: vocabulary.port, field: 'port' },

                            ]}
                            actions={[
                                rowData => ({

                                    tooltip: vocabulary.action,
                                    icon: () => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={devicesStatus && devicesStatus[rowData.deployed_device.identifier] && devicesStatus[rowData.deployed_device.identifier][rowData.port]==1}
                                                    onChange={() =>devicesStatus && devicesStatus[rowData.deployed_device.identifier] && sendAction(rowData, devicesStatus[rowData.deployed_device.identifier][rowData.port]==0)}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    ),
                                    onClick: (_, row) => {
                                        setAlertConfirmAction(true)
                                        setCurrentItem(row)
                                    }
                                })
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 40, 60, 80, 100],

                            }}
                        />

                        : <StyledLoadingRow />
                    }
                </CardContent>
            </Card>
{/* 
            {
                alertConfirmAction &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.confirmAction}
                    open={alertConfirmAction}
                    EditItemName={JSON.parse(currentItem?.equipment?.name)['name_' + currentLanguage]}
                    onClose={() => setAlertConfirmAction(false)}
                    confirmUpdate={() => {
                        if (currentItem) {
                            sendAction(currentItem);
                        }
                        setAlertConfirmAction(false)
                    }}
                    cancel={() => setAlertConfirmAction(false)}
                />
            } */}

        </>
    )
}

export default withStyles(styles)(ActionsCard)
/* eslint-disable no-unused-vars*/
/* eslint-disable eqeqeq*/

import L from "leaflet";
import { REACT_APP_ENV_VERSION } from "../../../../config";
import { GetAreaDetails, GetAreasList } from "../../../../globalsTools/network";
import { logger } from "../../../../globalsTools/utilities";
import { TYPE_SITE, TYPE_PARCEL, TYPE_SECTOR, NETIRRIG_ENVIRONMENT, DEV_NETIRRIG_ENVIRONMENT } from "../../../globalConsts";

const circleToPolygon = require("circle-to-polygon");

var _ = require('lodash')



const FlyOptions = {
    animate: true,
    duration: 1
};

/** 
 *
 * SITE -> Area with no parent, may have childs
 * PARCEL -> Area with one parent, may have childs
 * SECTOR -> Area with one parent, with no childs
 *
 */

/**
 * add popup to each area
 * manage click and dblclick on layer
 * click: popup
 * dblclick:enable layer editing
 * select by click initial station marker position while adding a new marker
 * @param {*} feature 
 * @param {*} layer 
 * @param {*} addNewStationMarker 
 * @param {*} selectedAreaId 
 * @param {*} setCurrentSelectedSite 
 * @param {*} handleAreaSelectionChange 
 * @param {*} drawControl 
 * @param {*} layerDrawOptions 
 * @param {*} map 
 * @param {*} setEditingStationMarker 
 */
const onEachFeatureFn = (feature, layer, markersDraggable, selectedAreaId, setCurrentSelectedSite,
    handleAreaSelectionChange, setLoading, setSelectedArea,
    handleGetAreasResult, setEditingStationMarker, setEditingMap) => {
    // if (feature && feature.properties && markersDraggable === false) {
    //     layer.bindPopup(feature.properties && feature.properties.name);
    // }
    if (selectedAreaId == null) {
        layer.on({
            click: (e) => {
                L.DomEvent.stopPropagation(e); // stop propagation while it is zooming on area
            },
            dblclick: (e) => {
                setCurrentSelectedSite({ lat: e.latlng.lat, lng: e.latlng.lng })
                if (feature.properties.id) {
                    layer.editing.enable()

                    handleAreaSelectionChange(feature.properties.id, setLoading, setSelectedArea,
                        handleGetAreasResult);
                } else {
                    setEditingMap(feature)
                }
                L.DomEvent.stopPropagation(e); // stop propagation while it is zooming on area
            },
        })
    } else {
        layer.on({
            click: (e) => {
                setEditingStationMarker(e)
            }
        })
    }
}
const coordsFormator = (coords) => { //  create lat lng object using coords
    return new L.LatLng(coords[0], coords[1])
}
const coordsFormatorInverted = (coords) => { // reverse lat and lng order function and create lat lng object using coords
    return new L.LatLng(coords[1], coords[0])
}
/**
 * create random string for map id
 * @param {*} length 
 */
const randomString = (length) => {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

/**
 * add icon to marker
 * @param {*} deviceType 
 */
export const iconTypeChange = (deviceType) => {
    const iconParams = { iconSize: [25, 41] }
    if (deviceType !== null) {
        switch (deviceType) {
            case "ACTION":
                return new L.icon({ iconUrl: '/assets/stations/action.png', ...iconParams })
            case "ACQUISITION":
                return new L.icon({ iconUrl: '/assets/stations/monitoring.png', ...iconParams })
            case "GATEWAY":
                return new L.icon({ iconUrl: '/assets/stations/gateway.png', ...iconParams })
            case "GOTO":
                return new L.icon({ iconUrl: '/assets/markerRed.png', ...iconParams })
            default:
                break;
        }
    }

}
/**
 * called when we click on go to button 
 * @param {*} data 
 * @param {*} map 
 * @param {*} setOpenGoto 
 */
export const gotoPosition = (data, map, setOpenGoto) => {
    new L.Marker(new L.LatLng(data.latitude, data.longitude))
        .addTo(map).setIcon(iconTypeChange('GOTO')) // add marker to chosen destination on map
    map.flyTo(L.latLng(data.latitude, data.longitude), 16, FlyOptions) // fly to the chose destination on map
    setOpenGoto(false) // close go to modal
}

/**
 * open or close modal Go To
 * @param {*} newStatus 
 * @param {*} setOpenGoto 
 * @param {*} openGoto 
 */
export const changeModalStatusGoto = (newStatus, setOpenGoto, openGoto) => {
    if (newStatus == null) {
        setOpenGoto(!openGoto)
    } else {
        setOpenGoto(newStatus)
    }
}
/**
 * back arrow function to change the zoom when we click on back button
 * @param {*} map 
 * @param {*} setSelectedArea 
 * @param {*} currentSelectedSite 
 */
export const backArrow = (map, setSelectedArea, currentSelectedSite, handleLayersData, layersDataInitial, geoJSONLayer, markersLayer, geoJSONOptions, latlngJsonToMarker, setEditingItem, isEditingStation) => {
    // this function will be called when there is no parrent_id value
    //todo: verify if it is not needed and remove it
    //map.flyTo(L.latLng(currentSelectedSite.lat, currentSelectedSite.lng), 7)
    handleLayersData(layersDataInitial, map, geoJSONLayer, markersLayer, geoJSONOptions, latlngJsonToMarker, isEditingStation)
    setSelectedArea(null)
    setEditingItem(null)
}

/**
 * Add Markers and Layers to the Map
 * @param {Array(AreaObject)} layersData 
 */
export const handleLayersData = (layersData, map, geoJSONLayer, markersLayer, geoJSONOptions, latlngJsonToMarker, isEditingStation) => {
    try {
        if (map && layersData) {
            geoJSONLayer.clearLayers();
            markersLayer.clearLayers();
            if (Array.isArray(layersData)) {
                //add areas
                layersData.forEach(newLayer => {
                    let feature = JSON.parse(newLayer.boundary)
                    // format coords to use it for image implementation
                    // let imageCoords=[]
                    // feature.geometry.coordinates[0].forEach(coords=>{imageCoords.push([coords[1],coords[0]])})
                    feature.properties.id = newLayer.id
                    if (feature != null) {
                        L.geoJSON(feature, {
                            coordsToLatLng: coords => coordsFormatorInverted(coords),
                            ...geoJSONOptions
                        }).addTo(geoJSONLayer)
                        // add image spacesense for every site
                        // let imageOverlay= L.imageOverlay(newLayer.imageUrl, imageCoords);
                        // imageOverlay.addTo(map);
                        if (newLayer && newLayer.center) {
                            //add for each area a marker on the area center (the blue one) and popup when we click on that marker
                            let markerCenter = latlngJsonToMarker(newLayer.center)
                            if (markerCenter != null) {
                                markerCenter.addTo(geoJSONLayer).bindPopup(newLayer.name)
                            }
                        }
                    }
                    //todo:remove it if it is not needed
                    // let markers = isEditingStation ? null : areaToMarkers(newLayer)
                    // fly to bound on edit station case
                    // if (isEditingStation) {
                    //     let bounds = geoJSONLayer.getBounds()
                    //     map.flyToBounds(bounds)
                    // }

                    // add station markers to an area in all cases except when we edit a station
                    if (isEditingStation !== true) {
                        let markers = areaToMarkers(newLayer)
                        addMarkersToMap(markers, markersLayer)
                    }
                });
            } else {
                //case of water need on demand
                L.geoJSON(layersData, {
                    coordsToLatLng: coords => coordsFormator(coords),
                    ...geoJSONOptions
                }).addTo(geoJSONLayer)
            }
            let bounds = geoJSONLayer.getBounds()
            map.whenReady(()=>refreshMap(map))            
            map.flyToBounds(bounds, FlyOptions)


        } else if(map){
            //case when cancel button is clicked in water requirement on demand
            geoJSONLayer.clearLayers();
            markersLayer.clearLayers();
            map.whenReady(()=>refreshMap(map))            
            map.flyTo([36, 9],4, FlyOptions)

        }

        

    } catch (e) { logger("HANDLELAYERSDATA", e) }
}

const refreshMap = (map) => {
    setTimeout(function(){ 
        logger('>> MAP RELOADED', map, map.getZoom())
        map.invalidateSize()
    }, 400);
}

/**
 * return marker form latlng JSON object 
 * @param {*} center 
 * @param {*} draggable 
 */
export const latlngJsonToMarker = (center, draggable = false) => {

    if (center != null) {
        try {
            let markerObject = JSON.parse(center)
            if (markerObject != null) {
                return L.marker([markerObject.latitude, markerObject.longitude], { bubblingMouseEvents: true, draggable: draggable })
            }
        } catch (e) {
        }
    }
    return null
}

/**
 * return station markers array of an area 
 * @param {*} area 
 */

// return the markers list of an area
// Prepare markers Array to add them to map (or return one marker)
export const areaToMarkers = (area) => {

    let markersArray = []
    if (area && area.area_deployed_device_equipment && Array.isArray(area.area_deployed_device_equipment) && area.area_deployed_device_equipment.length > 0) {
        area.area_deployed_device_equipment.forEach(device => {
            try {
                let position = null
                if (device.deployeddevice && device.deployeddevice.realposition) { // fill position depends on where it is stocked
                    position = JSON.parse(device.deployeddevice.realposition)
                }
                if (device.deployed_device && device.deployed_device.realposition) {// fill position depends on where it is stocked
                    position = JSON.parse(device.deployed_device.realposition)
                }
                if (position != null) { // Prepare markers Array to add them to map
                    markersArray.push({ marker: L.marker(position.geometry.coordinates).setIcon(iconTypeChange(position.properties.type.toUpperCase())), popUpText: `${position.properties.name} (${position.properties.type})` })
                }
            } catch (e) {
            }
        })
    }
    return markersArray
}

L.Control.Watermark = L.Control.extend({
    onAdd: function(map) {
        var img = L.DomUtil.create('img');

        img.src = '/assets/SeabexLogoBlanc.svg';
        img.style.width = '150px';

        return img;
    },

    onRemove: function(map) {
        // Nothing to do here
    }
});

L.control.watermark = (opts) => {
    return new L.Control.Watermark(opts);
}


/**
 *  layer draw options
 * @param {*} featureGroup 
 * @param {*} waterNeedProp 
 */
// control the draw buttons list(bar) when to show and when to hide buttons. 
const layerDrawOptions = (featureGroup, layersDataInitial, selectedAreaId, previousPosType, stationEditAdd) => {
    if (featureGroup instanceof L.LayerGroup) {
        featureGroup = L.featureGroup(featureGroup.getLayers())
    }
    return ({
        draw: {

            // {
            //     shapeOptions: {
            //         color: '#f357a1',
            //         weight: 10
            //     }
            // },
            // polygon: {
            //     // allowIntersection: true, // Restricts shapes to simple polygons
            //     drawError: {
            //         color: '#e1e100', // Color the shape will turn when intersects
            //         message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
            //     },
            //     shapeOptions: {
            //         color: '#bada55'
            //     }
            // },

            // rectangle: {
            //     shapeOptions: {
            //         clickable: false
            //     }
            // },
            polyline: false,
            rectangle: !layersDataInitial && selectedAreaId && !previousPosType && !stationEditAdd ? false : true, // show it in the case of not editing
            polygon: !layersDataInitial && selectedAreaId && !previousPosType && !stationEditAdd ? false : true, // show it in the case of not editing
            circle: true, // Turns off this drawing tool
            marker: false, // Turns off this drawing tool
            circlemarker: false, // Turns off this drawing tool
        },
        edit: {
            featureGroup: featureGroup, //REQUIRED!!
            edit: !layersDataInitial && selectedAreaId && !previousPosType && !stationEditAdd ? true : false, // show it in case of editing
            remove: false, // hide remove button
        },
        tap:false,

    })
};

/**
 * close or open modal
 * @param {*} newStatus 
 * @param {*} setOpenAdd 
 * @param {*} openAdd 
 */
export const changeModalStatus = (newStatus, setOpenAdd, openAdd) => {
    if (newStatus == null) {
        setOpenAdd(!openAdd)
    } else {
        setOpenAdd(newStatus)
    }

}

/**
 * add station markers to each area
 * @param {*} markers 
 * @param {*} markersLayerToAdd 
 */
export const addMarkersToMap = (markers, markersLayerToAdd) => {
    if (markers !== null && Array.isArray(markers)) { // in case markes array to add
        markers.forEach(marker => {
            try {
                //station markers
                marker.marker.addTo(markersLayerToAdd).bindPopup(marker.popUpText) // add marker and pop up content
            } catch (e) {
            }
        })
    } else {
        markers.marker.addTo(markersLayerToAdd).bindPopup(markers.popUpText) // in case one marker to add
    }
}

/**
 * full screen map
 * @param {*} setMapStyle 
 * @param {*} styleMaxSize 
 * @param {*} setFullscreen 
 */

// when we click on full screen map view
export const maximizeSize = (setMapStyle, styleMaxSize, setFullscreen) => {
    setMapStyle(styleMaxSize)
    setFullscreen(true)
}
/**
 * normal size map
 * @param {*} setMapStyle 
 * @param {*} style 
 * @param {*} setFullscreen 
 */

// when we click of normal size screen map view
export const normalSize = (setMapStyle, style, setFullscreen) => {
    setMapStyle(style)
    setFullscreen(false)
}

/**
 * Handle AreaId Selection and get area data
 * @param {String} areaId 
 */
export const handleAreaSelectionChange = (areaId, setLoading,
    setSelectedArea, handleGetAreasResult) => {
    setLoading(true)
    if (areaId !== null) {
        GetAreaDetails(areaId, setSelectedArea).then(() => setLoading(false))
    } else {
        GetAreasList(handleGetAreasResult).then(() => setLoading(false))
    }
    setSelectedArea(null)
};

/**
 * Drawing function to edit or create new Area on map
 * @param {*} layer 
 * @param {*} feature 
 * @param {*} map 
 * @param {*} drawControl 
 * @param {*} selectedArea 
 * @param {*} setEditingMap 
 * @param {*} markersLayer 
 * @param {*} geoJSONLayer 
 * @param {*} geoJSONOptions 
 */
// draw new area or edit area
export const newDrawing = (layer = null, feature = null, map, drawControl, selectedArea, setEditingMap, markersLayer, geoJSONLayer, geoJSONOptions) => {
    if (map && drawControl) {
        map.removeControl(drawControl)
    }
    if (layer === null) {
        if (selectedArea === null) { // draw new area case
            if (feature == null) {
                geoJSONLayer.clearLayers() // remove areas from map when we are adding a new area
                markersLayer.clearLayers() // remove markers from map when we are adding a new area 
                let editingMapVariable = new L.featureGroup() // creation of feature
                setEditingMap(editingMapVariable) // call useEffect based on [editingMap] value change and handle creation of new area
            } else {
                setEditingMap(L.geoJSON(feature))// call useEffect based on [editingMap] value change and handle edit area
            }
        } else {
            let editingMapVariable = new L.featureGroup()
            setEditingMap(editingMapVariable) // call useEffect based on [editingMap] value change and handle edit area
        }
    } else {
        if (feature !== null) {
            markersLayer.clearLayers()
            geoJSONLayer.clearLayers()
            L.geoJSON(feature, {
                coordsToLatLng: coords => coordsFormator(coords),
                ...geoJSONOptions
            }).addTo(geoJSONLayer);
            setEditingMap(geoJSONLayer) // call useEffect based on [editingMap] value change and handle edit area
        } else {
            let editingLayerVariable = new L.layerGroup([layer])
            setEditingMap(editingLayerVariable) // call useEffect based on [editingMap] value change and handle edit area
        }
    }
    return ({ map, drawControl })
}

/**
 * disable the draw bar
 * @param {*} map 
 * @param {*} drawControl 
 * @param {*} setEditingMap 
 */
export const disableDrawBar = (map, drawControl, setEditingMap) => {
    if (drawControl)
        map.removeControl(drawControl)
    setEditingMap(null)
    return map
}

/**
 *  add new area
 * @param {*} map 
 * @param {*} drawControl 
 * @param {*} setEditingMap 
 * @param {*} setLastCreatedLayer 
 * @param {*} waterNeedProp 
 * @param {*} getPolygone 
 */
export const creditAreas = (map, drawControl, setEditingMap, setLastCreatedLayer, waterNeedProp, getPolygone, editCallBack) => {
    map.on(L.Draw.Event.CREATED, (e) => {
        let feature;
        if (e.layerType=='circle') {
            let coordinates = [e.layer._latlng.lng, e.layer._latlng.lat]; //[lon, lat]
            let radius = e.layer._mRadius; // in meters
            let cToPOptions = { numberOfEdges: 32 }; //optional, that defaults to { numberOfEdges: 32 }
    
            let polygon = circleToPolygon(coordinates, radius, cToPOptions);    
            feature = {geometry: polygon, type:"Feature", properties:{name:"Freshly Added"}};
        } else {
            feature = e.layer.toGeoJSON() // Returns a GeoJSON representation of the polygon (as a GeoJSON Polygon or MultiPolygon Feature).
            feature.properties.name = "Freshly Added"
        }
        // This function reverse lat and long orders in coordinates
        feature.geometry.coordinates[0] = L.GeoJSON.latLngsToCoords(
            L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates[0], 0, coords => coordsFormator(coords)), 0)
        if (waterNeedProp === true) {
            getPolygone(feature)
        }
        map = disableDrawBar(map, drawControl, setEditingMap)
        setLastCreatedLayer(feature)
    });

    map.on(L.Draw.Event.EDITED, (e) => {
        if (editCallBack) {
            editCallBack(e.layers)
        }
    });

    return map
}

/**
 *initialisation of id state 
 * @param {*} id 
 * @param {*} randomString 
 */
export const initialiseId = (id) => {
    return randomString(20)
}
/**
 * geoJSON object initialisation
 * @param {*} onEachFeatureFn 
 * @param {*} addNewStationMarker 
 * @param {*} selectedAreaId 
 * @param {*} setCurrentSelectedSite 
 * @param {*} handleAreaSelectionChange 
 * @param {*} drawControl 
 * @param {*} layerDrawOptions 
 * @param {*} map 
 * @param {*} setEditingStationMarker 
 * @param {*} featureGroup 
 * @param {*} waterNeedProp 
 */
export const geoJSONInit = (markersDraggable,
    selectedAreaId, setCurrentSelectedSite,
    setLoading, setSelectedArea,
    handleGetAreasResult, setEditingStationMarker, setEditingMap) => {
    return {
        style: (feature) => { // add style to layer (color of area)
            if (feature.properties && feature.properties.type) {

                switch (feature.properties.type) {
                    case TYPE_SITE:
                        return { color: "#00FFFF" }
                    case TYPE_PARCEL:
                        return { color: "red" }
                    case TYPE_SECTOR:
                        return { color: "yellow" }
                    default:
                        return { color: "white" }
                }

            } else {
                return { color: "white" }
            }
            // return feature.properties.style;
        },
        onEachFeature: (feature, layer) => { // define layer and add events (click and dblclick) on each layer
            onEachFeatureFn(feature, layer, markersDraggable, selectedAreaId, setCurrentSelectedSite,
                handleAreaSelectionChange, setLoading, setSelectedArea,
                handleGetAreasResult, setEditingStationMarker, setEditingMap)
        }
    }
}

/**
 * area markers double click event to flyto that area
 * @param {*} e 
 * @param {*} setCurrentSelectedMarker 
 */
export const markersGroupClick = (e, setCurrentSelectedMarker) => {
    setCurrentSelectedMarker({ lat: e.latlng.lat, lng: e.latlng.lng })
}

/** update main layer with the selected area*/
export const updateMainLayer = (selectedArea, map, geoJSONLayer, markersLayer, geoJSONOptions, latlngJsonToMarker, isEditingStation) => {
    let { childs, ...mainArea } = selectedArea
    let layerdata = _.concat(mainArea, childs)
    handleLayersData(layerdata, map, geoJSONLayer, markersLayer, geoJSONOptions, latlngJsonToMarker, isEditingStation)
}

/**
 * useEffect initialisation function
 * @param {*} setMapStyle 
 * @param {*} style 
 * @param {*} map 
 * @param {*} id 
 * @param {*} staticMap 
 * @param {*} drawnItems 
 * @param {*} geoJSONLayer 
 * @param {*} geoJSONOptions 
 * @param {*} markersLayer 
 * @param {*} controlLayers 
 * @param {*} setCurrentSelectedMarker 
 * @param {*} drawControl 
 * @param {*} setEditingMap 
 * @param {*} setLastCreatedLayer 
 * @param {*} waterNeedProp 
 * @param {*} getPolygone 
 * @param {*} creditAreas 
 * @param {*} markersGroupClick 
 */
// Creation of Map 
export const initialiseMap = (setMapStyle, style, map, id, staticMap,
    drawnItems, geoJSONLayer, geoJSONOptions, markersLayer, controlLayers,
    setCurrentSelectedMarker, drawControl,
    setEditingMap, setLastCreatedLayer, waterNeedProp, getPolygone,
    markersGroupClick, BaseMaps, initialLayer, editCallBack, overlayLayer) => {
    setMapStyle(style)
    // create new map : center: center the view on a coordinates; zoom: zoom on centered coordinates 
    map = new L.map(id, {
        center: (REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ? [47.9135432,1.7601246] : [36, 9],
        zoom: 7,
        // maxZoom:17,
        minZoom:1,
        bounceAtZoomLimits:true,
        trackResize:true,
        boxZoom:true,
        zoomAnimation: true,
        

        // drawControl: false,
        attributionControl: false, //  control buttons on map 
        layers: [initialLayer],
        dragging: (staticMap === false) ? true : false
    })
    map.whenReady(()=>refreshMap(map))            
         

  
    drawnItems = new L.FeatureGroup()  // FeatureGroup: allows you to add click event and dblclick event on features and other functionnalites.
    geoJSONLayer = L.geoJSON(null, { // reformat coords to geoJSON
        coordsToLatLng: function (coords) {
            return new L.LatLng(coords[0], coords[1], coords[2]);
        },
        ...geoJSONOptions
    }).addTo(map)

    L.control.watermark({ position: 'bottomright' }).addTo(map);

    //add layers markers
    geoJSONLayer = L.featureGroup().addTo(map).on("dblclick", e => markersGroupClick(e, setCurrentSelectedMarker))
    //add station markers
    markersLayer = L.featureGroup().addTo(map)
    overlayLayer = L.layerGroup().addTo(map)
    let OverlayMaps = { "Your Seabex Stations": markersLayer, "Your Sites": geoJSONLayer }
    controlLayers = L.control.layers(BaseMaps, OverlayMaps).addTo(map) // base maps are added layers(penWeatherMapLayer, OpenStreetMapLayer...) from leaflet

    return {
        drawnItems,
        geoJSONLayer,
        markersLayer,
        controlLayers,
        overlayLayer,
        map: creditAreas(map, drawControl, setEditingMap, setLastCreatedLayer,
            waterNeedProp, getPolygone, editCallBack)
    }
}

/** called on add/edit area */
export const editingMapEffect = (editingMap, map, drawControl, geoJSONLayer, initialEditableArea, layersDataInitial, selectedAreaId, previousPosType, stationEditAdd) => {
    L.Draw.Polyline.prototype._onTouch = L.Util.falseFn;
    try {
        let editingMapFeature = editingMap
        if (editingMap != null && geoJSONLayer != null) { // editing case for area or area contains sub areas
            // added conditions to not handle errors
            if (editingMap.hasOwnProperty('boundary')) {
                let editableLayers = L.FeatureGroup(editingMap)
                if (!(editingMapFeature instanceof L.Marker)) { // not instance of L.Marker
                    if (editingMapFeature instanceof L.LayerGroup) { // instance of LayerGroup
                        editingMapFeature.eachLayer((sublayer) => { 
                            sublayer.addTo(editableLayers) // add sublayer to editableLayers
                            sublayer.editing.enable() // enable edititng
                        })
                    } else {
                        editingMapFeature.addTo(editableLayers) // edit when there is no sub area
                        editingMapFeature.editing.enable()
                    }
                }
            }
            if (geoJSONLayer._leaflet_id === 35 || !initialEditableArea)  {// add a new area case 
                drawControl = new L.Control.Draw(layerDrawOptions(editingMapFeature, layersDataInitial, selectedAreaId, previousPosType, stationEditAdd)).addTo(map) // draw new area with draw bar that show buttons depend on cases
            }
        } else {
            drawControl = new L.Control.Draw(layerDrawOptions(editingMapFeature, layersDataInitial, selectedAreaId, previousPosType, stationEditAdd)).addTo(map) // draw new area
        }
        editingMapFeature.addTo(geoJSONLayer)
        let bounds = geoJSONLayer.getBounds() // get bounds of created areas
        if (bounds && bounds._northEast) {
            map.flyToBounds(bounds, FlyOptions)  // show map view on created area bounds
        }
    } catch (e) { console.error(e) }

    return { drawControl, map }
}


export const assignOverlays = (imageOverlay, overlayLayer,geoJSONLayer, opacity, addTo=false) => {
    if (overlayLayer && !addTo) {
        overlayLayer.clearLayers()
        if(geoJSONLayer) {
            geoJSONLayer.setStyle(feature => {
                return {fill:true}
            })
        }
    }    

    if (overlayLayer && imageOverlay && imageOverlay.bounds && imageOverlay.path) {
        // Initiating leaflet map visualisation 
        // ref : https://leafletjs.com/reference-1.7.1.html#map-example
        // var map = L.map('map', {
        //   center: [51.505, -0.09],
        //   zoom: 13
        // });
        
        // add bounds from the json file
        // ref : https://leafletjs.com/reference-1.7.1.html#latlngbounds
        var imageBounds = imageOverlay.bounds;
        
        // path to png image (can be a link to the api or a local file).
        var imageUrl = imageOverlay.path;
        
        // create the image overlay
        // ref : https://leafletjs.com/reference-1.7.1.html#imageoverlay
        imageOverlay = L.imageOverlay(imageUrl, imageBounds, {opacity: opacity});
        
        // assign the overlay to your map
        // ref : https://leafletjs.com/reference-1.7.1.html#imageoverlay-addto
        imageOverlay.addTo(overlayLayer);

        geoJSONLayer.setStyle(feature => {
            return {fill:false}
        })
        

        
        // remove the overlay from the map
        // imageOverlay.removeFrom(map);
        
        // change the current overlay
        // ref: https://leafletjs.com/reference-1.7.1.html#imageoverlay-setbounds
        // ref : https://leafletjs.com/reference-1.7.1.html#imageoverlay-seturl
          
        /** Another way to do it */
        // change the bounds with the new bounds from the json file
        // ref : https://spacesense.gitlab.io/product_support/developer_site/docs/API/download_file/#response-details--json
        // look at the download with extention 
        // imageBounds = [[40.712216, -74.22655], [40.773941, -74.12544]];
        // change the link to the image
        // ref: https://spacesense.gitlab.io/product_support/developer_site/docs/API/download_file/#image
        // imageUrl = "another_image_path.png";
        // update the overlay using the currently displayed overlay
        // imageOverlay.setUrl(imageUrl);
        // imageOverlay.setBounds(imageBounds);
    }
}

/**
 * styles
 */
export const mapStyles = (styles) => {
    return {
        ...styles,
        width: "100%",
        height: "60vh",
        marginTop: 0,
        flexDirection: 'vertical',
        marginBottom: 18,
        position: 'relative'
    }
}

export const styleMaxSize = {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    zIndex: 2000,
    height: '100vh',
}

export const styleCommands = { backgroundColor: 'white', borderRadius: 6 }
import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';
import {  HighlightOff } from '@material-ui/icons';
import RestoreIcon from '@material-ui/icons/Restore';

import { vocabulary } from '../Strings';

const AlertRestoreUserConfirmationDialog = ({ onClose, cancel, operation, confirmeRestore, open, restoreItem }) => {


    const innerClose = () => {
        onClose();
    };

    const innerCancel = () => {
        cancel()
        innerClose()
    }

    const innerConfirm = () => {
        confirmeRestore()
        innerClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={innerClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{vocabulary.warningLabel}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {vocabulary.confirmationLabel} {operation} {restoreItem} ?
            </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={innerCancel}
                        style={{ textTransform: 'none' }}
                        color="secondary"

                    >
                        <HighlightOff />
            &nbsp;{vocabulary.cancelBtn}
                    </Button>
                    {
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={innerConfirm}
                            color="primary"
                            >
                            <RestoreIcon />
              &nbsp;{vocabulary.restoreLabel}
                        </Button>}


                </DialogActions>

            </Dialog>
        </div>
    );
}


export default AlertRestoreUserConfirmationDialog;
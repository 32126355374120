import React, { PureComponent } from 'react';
// import { Table } from 'antd';
// import 'antd/dist/antd.css';
import drag from '../../../images/dragicon.png';
// import { Button } from 'antd';
import { setScenariosPriorityThenLoadData, navigateScenario, deleteScenario } from '../../../actions'
import { connect } from 'react-redux';
import { vocabulary } from '../../Strings';
import { Edit, Delete, Visibility, Add, Save } from "@material-ui/icons";
import AlertDialog from "../../Components/alertDialog";
import ViewDetails from "../viewDetails"
import ScenarioForm from '../ScenarioForm'
import "../style.css";

const closest = function (el, selector, rootNode) {
    rootNode = rootNode || document.body;
    const matchesSelector =
        el.matches ||
        el.webkitMatchesSelector ||
        el.mozMatchesSelector ||
        el.msMatchesSelector;
    while (el) {
        const flagRoot = el === rootNode;
        if (flagRoot || matchesSelector.call(el, selector)) {
            if (flagRoot) {
                el = null;
            }
            break;
        }
        el = el.parentElement;
    }
    el.setAttribute('style', 'border: 50px solid red;');
    return el;
};

class DataTablePage extends PureComponent {


    constructor(props) {
        super(props);


        let dataTable = this.props.data
        let nbrepage = this.props.nbrepage
        // let next_url = this.props.next_url
        // let previous_url = this.props.previous_url
        let currentPage = this.props.currentPage
        this.state = {
            data: dataTable,
            dragIndex: -1,
            draggedIndex: -1,
            nbrepage: nbrepage,
            currentPage: currentPage,
            alertConfirmDelete: false,
            item: null,
            isEditing: false,
            openEdit: false,
            openAdd: false,
            openDetails: false
        };





        this.columns = [
            {
                title: vocabulary.chapriority,
                dataIndex: 'priority',
                key: 'priority',
            },
            {
                title: vocabulary.title,
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: vocabulary.status,
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: vocabulary.description,
                dataIndex: 'description',
                key: 'description',
            },

            {
                title: vocabulary.changePriority,
                key: 'operator',
                render: (text, record, index) =>
                    <span   >
                        {(this.state.dragIndex >= 0 &&
                            this.state.dragIndex !== this.state.draggedIndex &&
                            index === this.state.draggedIndex &&
                            <span
                                className={`drag-target-line ${this.state.draggedIndex <
                                    this.state.dragIndex
                                    ? 'drag-target-top'
                                    : ''}`}
                            />) ||
                            ''}
                        <a
                            className="drag-handle"
                            draggable="false"
                            onMouseDown={this.onMouseDown}
                            href="/#"
                        >
                            <img alt="Error" src={drag} style={{ width: 40 }} />

                        </a>
                    </span>,
            },
            {
                title: vocabulary.actions,
                key: 'actions',

                render: (item) =>
                    this.onDelete(item)


            },

        ];
    }


    onSelectItemToDelete = () => {
        const { data } = this.state
        return (
            data.map((item, index) => (
                this.onDelete(item)
            )

            )
        )

    }
    onUpdateScenario = (item) => {
        return (

            <div>

                <Edit
                    style={{ marginLeft: "10px", cursor: 'pointer' }}

                    color="primary"
                    onClick={() => {
                        this.setState({
                            item: item,

                            openEdit: true,
                            isEditing: true,
                        });
                    }}
                />




            </div>
        )
    }
    onViewDetails = (item) => {
        return (

            <div >

                <Visibility
                    style={{ marginLeft: "10px", cursor: 'pointer' }}

                    color="primary"
                    onClick={() => {
                        this.setState({
                            item: item,

                            openDetails: true,
                        });
                    }}
                />




            </div>
        )

    }
    onEdit = (row) => {
        this.setState({
            item: row,
            openEdit: true,
            isEditing: true,
        });
    }
    viewDetails = (row) => {
        this.setState({
            item: row,

            openDetails: true,
        });
    }
    onDelete = (item) => {

        return (

            <div style={{ display: 'flex' }}>
                {this.onUpdateScenario(item)}
                {this.onViewDetails(item)}

                <Delete

                    style={{ marginLeft: "10px", cursor: 'pointer' }}
                    color="secondary"
                    onClick={() => {
                        this.setState({ item: item });
                        this.openAlertConfirmDelete(item);
                        //  this.props.deleteScenario(item)
                    }}
                />



            </div>
        )
    }
    openAlertConfirmDelete = (row) => {
        this.setState({
            alertConfirmDelete: true,
            item: row
        });
    };


    //  this will triger after a PROPS!!! changes  
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.data) {
            this.setState({
                data: nextProps.data,
            })
        }
    }

    onMouseDown = (e) => {
        const target = this.getTrNode(e.target);
        if (target) {
            target.setAttribute('draggable', true);
            target.ondragstart = this.onDragStart;
            target.ondragend = this.onDragEnd;
        }
    }

    onDragStart = (e) => {
        const target = this.getTrNode(e.target);
        if (target) {
            e.dataTransfer.effectAllowed = 'move';
            target.parentElement.ondragenter = this.onDragEnter;
            target.parentElement.ondragover = function (ev) {
                ev.preventDefault();
                return true;
            };
            const dragIndex = target.rowIndex - 1;
            this.setState({ dragIndex, draggedIndex: dragIndex });
        }
    }
    closeAlertConfirmDelete = () => {
        this.setState({
            alertConfirmDelete: false
        });
    };
    onDragEnter = (e) => {
        const target = this.getTrNode(e.target);
        this.setState({
            draggedIndex: target ? target.rowIndex - 1 : -1,
        });
    }

    onDragEnd = (e, result) => {
        const target = this.getTrNode(e.target);
        if (target) {
            target.setAttribute('draggable', false);
            target.ondragstart = null;
            target.ondragend = null;
            target.parentElement.ondragenter = null;
            target.parentElement.ondragover = null;
            this.changeRowIndex();
        }

    }

    getTrNode(target) {
        return closest(target, 'tr');
    }

    changeRowIndex() {

        const result = {};

        const currentState = this.state;
        result.dragIndex = result.draggedIndex = -1;
        if (
            currentState.dragIndex >= 0 &&
            currentState.dragIndex !== currentState.draggedIndex
        ) {
            const { dragIndex, draggedIndex, data: oldData } = currentState;
            const data = [...oldData];
            //       const data = oldData;
            const item = data.splice(dragIndex, 1)[0];
            data.splice(draggedIndex, 0, item);
            result.data = data;
            result.dragIndex = -1;
            result.draggedIndex = -1;
        }
        this.setState(result);

        this.setState(state => {
            const list = state.data.map((i, index) => i.priority = index + 1);
            return {
                list,
            };
        })
    }
    loadNextPage = () => {

        let { currentPage } = this.state
        currentPage = currentPage + 1;
        this.setState({ currentPage })

        this.props.navigateScenario(currentPage)
    }
    loadPreviousPage = () => {
        let { currentPage } = this.state
        currentPage = currentPage - 1;
        this.setState({ currentPage })

        this.props.navigateScenario(currentPage)
    }
    loadScenarioPerPage = (index) => {
        this.props.navigateScenario(index)
        this.setState({ currentPage: index })


    }
    handleClose = () => {
        this.state.isEditing ? this.setState({ openEdit: false }) : this.setState({ openAdd: false });
    };
    handleCloseDetail = () => {
        this.setState({ openDetails: false });
    };

    sendFiltredData = () => {
        const { setScenariosPriorityThenLoadData } = this.props
        const { data } = this.state
        setScenariosPriorityThenLoadData(data)

    }

    handleClickOpenAdd = () => {
        this.setState({ openAdd: true })
    };

    render() {

        const items = []
        // items.push(<Button type="primary" style={{ margin: '5px' }} onClick={() => this.loadPreviousPage()} disabled={this.state.currentPage === 1}  >previews</Button>)
        // for (let index = 1; index <= this.props.nbrepage; index++) {
        //     items.push(<Button style={{ margin: '5px' }} type={index === this.state.currentPage ? "danger" : "primary"} onClick={() => this.loadScenarioPerPage(index)}  >{index}</Button>)
        // }
        // items.push(<Button type="primary" style={{ margin: '5px' }} onClick={() => this.loadNextPage()} disabled={this.state.currentPage === this.props.nbrepage} >next</Button>)
        return (
            <div style={{ margin: 20 }}>


                {/* <Table
                    className={(this.state.dragIndex >= 0 && 'dragging-container') || ''}

                    columns={this.columns}
                    pagination={false}
                    dataSource={this.state.data}
                /> */}
                {/* <StyledMaterialTable

          title={vocabulary.ScenarioLabel}
          data={this.state.data}
          pagination={false}

          columns={[
            { title: vocabulary.chapriority, field: 'priority', },
            { title: vocabulary.title, field: 'title', },
            { title: vocabulary.status, field: 'status' },
            { title: vocabulary.description, field: 'description', },
            { title: vocabulary.operationn, operator: 'address', render: (text, record, index) => <>
             <span   >
                        {(this.state.dragIndex >= 0 &&
                            this.state.dragIndex !== this.state.draggedIndex &&
                            index === this.state.draggedIndex &&
                            <span
                                className={`drag-target-line ${this.state.draggedIndex <
                                    this.state.dragIndex
                                    ? 'drag-target-top'
                                    : ''}`}
                            />) ||
                            ''}
                        <a
                            className="drag-handle"
                            draggable="false"
                            onMouseDown={this.onMouseDown}
                            href="/#"
                        >
                            <img alt="Error" src={drag} style={{ width: 40 }} />

                        </a>
                    </span>,
            </> },


          ]} 
          actions={[
            {
                icon: 'edit_outline',
                tooltip: vocabulary.editLabel,
                onClick: (event, row) => this.onEdit(row)
            },
            
            row => ({
                icon: 'delete_outline',
                tooltip: vocabulary.delete,
                onClick: (event, row) => this.openAlertConfirmDelete(row),

            }),
            row => ({
                icon:()=> <Visibility/>,
                tooltip: 'show',
                onClick: (event, row) => this.viewDetails(row),

            }),
            // {
            //     icon: 'add',
            //     tooltip: vocabulary.add,
            //     isFreeAction: true,
            //     onClick: (event,row) => handleClickOpen(row)
            // }

        ]}
          options={{
            actionsColumnIndex: -1,
            search: true,


          }}
        /> */}
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: 15 }}>
                    {/* <Button
                        type="primary"
                        onClick={() => this.sendFiltredData()}
                        style={{ marginRight: 15 }}>
                        <Save />&nbsp;&nbsp;{vocabulary.save}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => this.handleClickOpenAdd()}
                    ><Add />&nbsp;&nbsp;{vocabulary.add}</Button> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>{items}</div>

                <AlertDialog
                    operation={vocabulary.delete}
                    open={this.state.alertConfirmDelete}
                    deletedItemName={
                        this.state.item && this.state.item.title}
                    onClose={() => {
                        this.closeAlertConfirmDelete();
                    }}
                    confirmeDelete={() => {
                        if (this.state.item) {
                            this.props.deleteScenario(this.state.item.id);
                        }

                        this.closeAlertConfirmDelete();
                    }}

                    cancel={() => {
                        this.closeAlertConfirmDelete();
                    }}
                />
                {
                    <ScenarioForm
                        open={this.state.isEditing ? this.state.openEdit : this.state.openAdd}
                        isEditing={this.state.isEditing}
                        onClose={() => {
                            this.handleClose()
                        }}
                        item={this.state.isEditing ? this.state.item : null}
                    />
                }

                {this.state.openDetails ?
                    <ViewDetails
                        open={this.state.openDetails}
                        onClose={() => {
                            this.handleCloseDetail()
                        }}
                        item={this.state.item}
                    />
                    : ''
                }

            </div>

        );
    }
}
export default connect(null, { setScenariosPriorityThenLoadData, navigateScenario, deleteScenario })(DataTablePage);
/* eslint-disable eqeqeq*/
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import { DatePicker, Day } from '@material-ui/pickers';
// import moment from 'moment';
import { useForm } from 'react-hook-form';
import StyledInput from '../../ui/forms/StyledInput';
// import Select2 from '@material-ui/core/Select';
import StyledSelect from '../../ui/forms/StyledSelect';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { StyledModal } from '../../ui/layout';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import {
    GetAreaDetails,
    GetCultureFamilliesList,
    getCultureByFamilyList,
    createCycle,
    updateCycle,
    GetAreasList,
    GetRUFromSoilType,
    GetChildsByParentId,
} from '../../globalsTools/network';
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import {
    GuideContext,
    steps,
} from '../../ui/layout/contextLayout/GuideContext';
import { ASPERSION_IRRIGATION, CURRENT_STEP, DRIP_IRRIGATION, INITIAL_STEP, IS_ENABLED, LOCAlIZED_IRRIGATION, MICRO_ASPERSION_IRRIGATION, PIVO_IRRIGATION, SUBMERSION_IRRIGATION } from '../globalConsts';
import CycleFormStepper from './CycleFormStepper';
import AlertEditDialogConfirmation from '../Components/AlertEditDialogConfirmation';
import { useHistory } from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Slider,
    withStyles,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { logger } from '../../globalsTools/utilities';
import { Warning } from '@material-ui/icons';
import SeabexOffers from '../Offers/SeabexOffers';
import IrrigationTypes from './IrrigationTypes/IrrigationTypes';

const CycleAddUpdateForm = ({
    isEditing,
    open,
    item,
    onClose,
    refetchFunc,
    sitesList,
    setIsLoading = false,
    area_id = null,
    selectedAreaId,
    cycleAddOnePager = false,
    selectedAreaFromOnePage,
}) => {
    // const dispatch = useDispatch()
    let history = useHistory();

    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm();
    const modalContext = useContext(ModalContext);
    const { enqueueSnackbar } = useSnackbar();

    const [selectedSiteId, setSelectedSiteId] = useState(
        cycleAddOnePager == true ? selectedAreaId : ''
    );
    const [internalSitesList, setInternalSitesList] = useState([]);

    const [id_culture, setIdCulture] = useState('');

    const [startdate, setStartDate] = useState(null);
    const [family, setFamily] = useState('');
    const [familyName, setFamilyName] = useState(null);

    const [parcelsList, setParcelsList] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [parcelId, setParcelId] = useState('');
    const [CultureList, setCultureList] = useState(null);
    const [sectorId, setSectorId] = useState('');

    const [families, setFamilies] = useState(null);
    const [sectorsList, setSectorsList] = useState([]);
    const [cultureKc, setCultureKc] = useState('');

    const [irrigationNumberDays, setIrrigationNumberDays] = useState(1);
    const [ruSol, setRuSol] = useState(null);
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    const [tauxTassement, setTauxTassement] = useState(0);
    const [tauxMatieresOrganiques, setTauxMatieresOrganiques] = useState(0);
    const [editableStartDate, setEditableStartDate] = useState(true);

    const currentLanguage = useSelector(
        (state) => state._globalState.preferred_language
    );
    const canCreateProductionCycle = useSelector(state => state._globalState.new_production_cycles_right)
    const userStatsLoading = useSelector(state => state._globalState.stats_loading)

    const [showOffers, setShowOffers] = useState(false)



    const [selectedIrrigationType, setSelectedIrrigationType] = useState(null)
    const [irrigation_params, setIrrigationParams] = useState({})
    const [useHourly, setUseHourly] = useState(false);

    const irrigationTypes = [
        { name: vocabulary.irrigationAspersion, value: ASPERSION_IRRIGATION },
        { name: vocabulary.irrigationLocalizedDrip, value: DRIP_IRRIGATION },
        { name: vocabulary.irrigationLocalizedMicroAspersion, value: MICRO_ASPERSION_IRRIGATION },
        { name: vocabulary.irrigationSubmersion, value: SUBMERSION_IRRIGATION },
        { name: vocabulary.irrigationPivot, value: PIVO_IRRIGATION },
    ]


    const onChangeIrrigationParams = (e) => {
        setIrrigationParams({
            ...irrigation_params,
            [e.target.name]: Number(e.target.value)
        })
    }

    useEffect(() => {
        if (!canCreateProductionCycle) {
            setShowOffers(true)
        }
    }, [canCreateProductionCycle])


    // const cultures = useSelector(state => state.culture.data)
    const { guide, dispatchGuide } = useContext(GuideContext);

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    useEffect(() => {
        if (sitesList) {
            setInternalSitesList(sitesList);
        } else {
            GetAreasList(setInternalSitesList);
        }
        return () => {
            setInternalSitesList([]);
        };
    }, []);

    useEffect(() => {
        if (area_id) {
            GetAreaDetails(area_id, setSelectedArea);
        }
    }, [area_id]);

    useEffect(() => {
        GetCultureFamilliesList(handleFamiliesCallback);
        setTimeout(() => {
            if (
                open === true &&
                steps[guide.initialStep].element === '.addCycleModal' &&
                guide.currentStep === 'addCycleModal'
            )
                dispatchGuide({
                    type: IS_ENABLED,
                    payload: true,
                });
        }, 300);
    }, [open]);

    const handleFamiliesCallback = (data, response, status) => {
        if (data.hasOwnProperty('data') && Array.isArray(data.data)) {
            setFamilies(data.data);
        } else {
            setFamilies(data);
        }
    };

    useEffect(() => {
        getCultureByFamilyList(family, setCultureList);
    }, [family]);

    useEffect(() => {
        logger('selectedArea', selectedArea);
        if (selectedArea != null) {
            setSelectedSiteId(selectedArea.id);
            categorizeAreas(selectedArea);
            if (selectedArea.sol) {
                GetRUFromSoilType(
                    selectedArea.sol.soil_texture_class,
                    setRuSol
                );
            } else {
                let recommendedsol = JSON.parse(selectedArea.recommendedsol);
                if (recommendedsol[1]) {
                    GetRUFromSoilType(recommendedsol[1].sol, setRuSol);
                }
            }
        }
    }, [selectedArea]);

    useEffect(() => {
        if (isEditing === false) {
            setSelectedSiteId(cycleAddOnePager == true ? selectedAreaId : null);
            setIdCulture('');
            setStartDate(null);
            setFamily(null);
            setParcelId(null);
            setIrrigationNumberDays(1);
            setTauxTassement(0);
            setTauxMatieresOrganiques(0);
        }

        // setIsEditing(isEditing)
    }, [open, isEditing]); // N’exécute l’effet que si count a changé

    const validForm = () => {
        // function to valid the input form (in case of null or empty input the submit btn will be disabled )
        if (
            guide.isEnabled &&
            open === true &&
            steps[guide.initialStep].element === '.addCycleModal' &&
            guide.currentStep === 'addCycleModal' &&
            selectedArea &&
            selectedArea.id
        ) {
            return false;
        } else if (
            !family ||
            !id_culture ||
            !selectedSiteId ||
            useHourly && !selectedIrrigationType ||
            !irrigation_params ||
            !irrigationNumberDays
        ) {
            return true;
        } else return false;
    };
    useEffect(() => {
        if (item) {
            setSelectedSiteId(
                item.area.parent !== null ? item.area.parent.id : item.area_id
            );
            setParcelId(item.area.parent !== null ? item.area_id : null);
            if (family == null) {
                setFamily(item.prm_culture.prm_culture_family_id);
            }
            if (id_culture == '') {
                setIdCulture(item.prm_culture.id);
            }
            GetAreaDetails(
                item.area.parent !== null ? item.area.parent.id : item.area_id,
                setSelectedArea
            );
            setStartDate(item.start_date);
            setIrrigationNumberDays(item.periodic_irrigation_days);
            setTauxTassement(item.taux_tassement ?? 0);
            setTauxMatieresOrganiques(item.taux_matieres_organiques ?? 0);
            setCultureKc(item.cycle);
            setSelectedIrrigationType(irrigationTypes.find(el => el.value == item.irrigation_type))
            setUseHourly(item?.irrigation_type ? true : false)
            setIrrigationParams(item?.irrigation_params)
        }
    }, [item, CultureList, internalSitesList]); // N’exécute l’effet que si item a changé

    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails['childs']);
                setSelectedSiteId(areasDetails.id);
                // setParcelId(null)

                break;
            case 'parcel':
                setParcelId(areasDetails.id);
                setSelectedSiteId(areasDetails.parent_id);
                GetChildsByParentId(areasDetails.parent_id, setParcelsList);
                // GetAreaDetails(areasDetails.parent_id, setSelectedArea)
                setSectorsList(areasDetails['childs']);
                setSectorId(null);

                break;
            case 'sector':
                setSectorId(areasDetails.id);
                break;

            default:
                return '';
        }
    };
    const handleChange = (event) => {
        GetAreaDetails(event.target.value, setSelectedArea);
    };

    const operationCallBack = (r) => {
        // callback function called after add or edit operation
        setIsLoading(false);
        if (r) {
            if ((r.status === 200) & (cycleAddOnePager == false)) {
                snackbar(
                    isEditing
                        ? JSON.parse(item.prm_culture.name)[
                        'name_' + currentLanguage
                        ] +
                        ' ' +
                        vocabulary.cycleLabel +
                        ' ' +
                        vocabulary.updateSucc
                        : vocabulary.cycleLabel + ' ' + vocabulary.addedSucc,
                    'success'
                );
                if (refetchFunc) {
                    refetchFunc();
                }
            } else if ((r.status === 200) & (cycleAddOnePager == true)) {
                snackbar(
                    vocabulary.cycleLabel + ' ' + vocabulary.addedSucc,
                    'success'
                );
                history.push('/app/cycles');
            } else if (r.status === 203) {
                // in case aded a new cycle  with an exist area id  and an exist culture type id
                snackbar(vocabulary.cycleExist, 'info');
                setIsLoading(false);
                if (refetchFunc) {
                    refetchFunc();
                }
            } else {
                snackbar(
                    vocabulary.errorOccurred +
                    ' ' +
                    vocabulary.serverSideLabel +
                    ' ,  ' +
                    vocabulary.tryAgainLabel,
                    'error'
                );
                setIsLoading(false);
            }
        }
    };
    const handleConfirmePopup = () => {
        dispatchGuide({ type: INITIAL_STEP, payload: 0 });
        dispatchGuide({ type: CURRENT_STEP, payload: '' });

        if (isEditing === false) {
            setIsLoading(true);
            createCycle(
                {
                    area_id:
                        cycleAddOnePager == true
                            ? selectedAreaId
                            : selectedArea && selectedArea.id,
                    prm_culture_id: id_culture,
                    start_date: startdate,
                    periodic_irrigation_days: irrigationNumberDays,
                    cycle: cultureKc,
                    irrigation_params: irrigation_params,
                    irrigation_type: selectedIrrigationType,
                    taux_tassement: tauxTassement,
                    taux_matieres_organiques: tauxMatieresOrganiques,
                },
                (r) => operationCallBack(r)
            );

            onClose();
        }
    };

    useEffect(() => {
        logger('id_culture', CultureList, id_culture);
        if (id_culture) {
            let culture =
                CultureList && CultureList.find((el) => el.id == id_culture);
            if (culture) {
                setCultureKc(culture.kc);
                // try {
                let params = culture.params && JSON.parse(culture.params);
                if (params && params.debut_bilan) {
                    let [day, month] = params?.debut_bilan?.split('-');
                    if (day && month) {
                        let debut_day = new Date();
                        debut_day.setDate(day);
                        debut_day.setMonth(month - 1);
                        if (item == null || (item != null && item.start_date == null)) {
                            setStartDate(debut_day);
                        }
                        setEditableStartDate(
                            params.editable_date
                                ? (typeof params.editable_date == 'boolean' ? params.editable_date : params.editable_date.toLowerCase() === 'true')
                                : true
                        );
                    }
                }
            }
        }
    }, [id_culture, CultureList]);

    const handleUpdateIrrigation = () => {
        setIsLoading(true);
        let data = {
            id: item.id,
            area_id: selectedArea && selectedArea.id,
            // area_id: parcelId == null ? selectedSiteId : sectorId == null ? parcelId : sectorId,
            prm_culture_id: id_culture,
            start_date: startdate,
            periodic_irrigation_days: irrigationNumberDays,
            cycle: cultureKc,
            taux_tassement: tauxTassement,
            taux_matieres_organiques: tauxMatieresOrganiques,

        }
        if (useHourly) {
            data = {
                ...data,
                irrigation_params: irrigation_params,
                irrigation_type: selectedIrrigationType,
            }
        }
        updateCycle(
            data,
            (r) => operationCallBack(r)
        );

        setOpenEditConfirmation(false);
        onClose();
    };
    const actions = [
        {
            title: isEditing === false ? vocabulary.save : vocabulary.editLabel,
            color: 'primary',
            position: 'end',
            onClick: () =>
                isEditing == false
                    ? handleConfirmePopup()
                    : setOpenEditConfirmation(true),
            disabled: isEditing ? false : validForm(),
        },
        {
            title: vocabulary.cancel,
            color: 'secondary',
            position: 'start',
            onClick: () => modalContext.changeModalStatus(false),
        },
    ];

    useEffect(() => {
        try {
            logger(
                'startdate',
                typeof startdate == 'object',
                typeof startdate == 'object'
                    ? startdate.format('YYYY-MM-DD')
                    : startdate
            );
        } catch (e) { }
    }, [startdate]);

    return (
        <>
            {userStatsLoading !== true && (!canCreateProductionCycle && !isEditing) ?

                <SeabexOffers
                    open={showOffers}
                    withoutContext={true}
                    closeFn={() => setShowOffers(false)}
                />
                : (
                    <>
                        {guide.isEnabled &&
                            guide.currentStep === 'addCycleModal' &&
                            open === true && (
                                <CycleFormStepper
                                    selectedArea={selectedArea}
                                    setSelectedArea={setSelectedArea}
                                    onClose={onClose}
                                    sitesList={internalSitesList}
                                    setIsLoading={setIsLoading}
                                    operationCallBack={operationCallBack}
                                />
                            )}

                        <StyledModal
                            open={open}
                            onClose={onClose}
                            modalContext={modalContext}
                            width={600}
                            title={
                                isEditing === false
                                    ? vocabulary.addCycle
                                    : vocabulary.editCycle
                            }
                        >
                            {(isEditing == true &&
                                selectedArea != null &&
                                CultureList &&
                                CultureList.length > 0) ||
                                isEditing == false ? (
                                <form
                                    onSubmit={handleSubmit(handleConfirmePopup)}
                                >
                                    <StyledSelect
                                        value={selectedSiteId ?? ''}
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.Area}
                                        onChange={handleChange}
                                        name='Area'
                                        label={vocabulary.site}
                                        data={
                                            cycleAddOnePager
                                                ? [
                                                    {
                                                        id: selectedAreaId,
                                                        name:
                                                            selectedAreaFromOnePage &&
                                                            selectedAreaFromOnePage.name,
                                                    },
                                                ]
                                                : internalSitesList
                                        }
                                        // data={internalSitesList.data}
                                        styles={{ width: '100%' }}
                                        valueField='id'
                                        labelField='name'
                                    />
                                    <br />

                                    {parcelsList &&
                                        parcelsList.length > 0 &&
                                        selectedSiteId && (
                                            <StyledSelect
                                                value={parcelId}
                                                register={register}
                                                errors={errors.ParcelId}
                                                onChange={handleChange}
                                                name='ParcelId'
                                                styles={{ width: '100%' }}
                                                label={vocabulary.parcel}
                                                data={parcelsList}
                                                valueField='id'
                                                labelField='name'
                                            />
                                        )}
                                    {sectorsList &&
                                        sectorsList.length > 0 &&
                                        parcelId && (
                                            <StyledSelect
                                                value={sectorId}
                                                register={register}
                                                // rules={{ required: true }}
                                                errors={errors.sectorId}
                                                onChange={handleChange}
                                                name='sectorId'
                                                styles={{ width: '100%' }}
                                                label={vocabulary.sector}
                                                data={sectorsList}
                                                valueField='id'
                                                labelField='name'
                                            />
                                        )}
                                    <br />

                                    {selectedSiteId ? (
                                        <>
                                            <Grid container direction='column'>
                                                <Typography>
                                                    {vocabulary.compactionRate} %
                                                </Typography>
                                                <div style={{ padding: 12 }}>
                                                    <TassementSlider
                                                        key='taux_tassement_sol_key'
                                                        value={tauxTassement}
                                                        name='taux_tassement_sol'
                                                        min={0}
                                                        step={1}
                                                        max={30}
                                                        onChange={(
                                                            event,
                                                            newValue
                                                        ) => {
                                                            setTauxTassement(
                                                                newValue
                                                            );
                                                        }}
                                                        valueLabelDisplay='auto'
                                                        aria-labelledby='taux_tassement_sol'
                                                        getAriaValueText={valuetext}
                                                        marks={[
                                                            {
                                                                value: 0,
                                                                label: '0 %',
                                                            },
                                                            {
                                                                value: 10,
                                                                label: '10 %',
                                                            },
                                                            {
                                                                value: 20,
                                                                label: '20 %',
                                                            },
                                                            {
                                                                value: 30,
                                                                label: '30 %',
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </Grid>

                                            <StyledSelect
                                                value={family ?? ''}
                                                register={register}
                                                rules={{ required: true }}
                                                errors={errors.family}
                                                styles={{ width: '100%' }}
                                                onChange={(event) => {
                                                    setFamily(event.target.value);
                                                    setFamilyName(
                                                        JSON.parse(
                                                            families.find(
                                                                (el) =>
                                                                    el.id ==
                                                                    event.target
                                                                        .value
                                                            ).name
                                                        ).name_en
                                                    );
                                                }}
                                                render={(item) => {
                                                    return JSON.parse(item['name'])[
                                                        'name_' + currentLanguage
                                                    ];
                                                }}
                                                name='family'
                                                label={vocabulary.cultureFamily}
                                                data={families}
                                                valueField='id'
                                            />
                                        </>
                                    ) : null}
                                    <br />

                                    {family && CultureList ? (
                                        <StyledSelect
                                            value={id_culture}
                                            register={register}
                                            rules={{ required: true }}
                                            errors={errors.Culture}
                                            onChange={(event, index) => {
                                                setIdCulture(event.target.value);
                                            }}
                                            name='Culture'
                                            label={vocabulary.culture}
                                            styles={{ width: '100%' }}
                                            render={(item) => {
                                                return JSON.parse(item['name'])[
                                                    'name_' + currentLanguage
                                                ];
                                            }}
                                            data={CultureList}
                                            valueField='id'
                                            labelField='name'
                                        />
                                    ) : null}
                                    {item &&
                                        item.prm_culture_id &&
                                        item.prm_culture_id != id_culture && (
                                            <Grid
                                                container
                                                direction='row'
                                                wrap='nowrap'
                                                style={{
                                                    alignItems: 'center',
                                                    color: 'orange',
                                                }}
                                            >
                                                <Grid style={{ margin: 8 }}>
                                                    <Warning
                                                        style={{ color: 'orange' }}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    Attention ! Changer de culture
                                                    effacera automatiquement vos
                                                    modifications de date de début
                                                    de phase du cycle en cours.
                                                </Grid>
                                            </Grid>
                                        )}
                                    <br />
                                    {id_culture && (
                                        <StyledInput
                                            name='irrigationNumberDays'
                                            label={
                                                vocabulary.numberOfDaysPerIrrigation
                                            }
                                            value={irrigationNumberDays}
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    setIrrigationNumberDays(
                                                        event.target.value
                                                    );
                                                }
                                            }}
                                            register={register}
                                            // rules={{ required: true }}
                                            type='number'
                                            InputProps={{
                                                inputProps: { min: 0, max: 31 },
                                            }}
                                            errors={errors.irrigationNumberDays}
                                            fullWidth
                                        />
                                    )}
                                    {id_culture && (
                                        <DatePicker
                                            minDate={moment().subtract(1, 'year')}
                                            label={vocabulary.startDate}
                                            value={startdate}
                                            onChange={setStartDate}
                                            animateYearScrolling
                                            // disableFuture
                                            format={
                                                currentLanguage == 'fr'
                                                    ? 'DD/MM/YYYY'
                                                    : 'MM/DD/YYYY'
                                            }
                                            style={{ margin: '8px', width: '100%' }}
                                            name='startdate'
                                            disabled={!editableStartDate}
                                        />
                                    )}


                                    {/* {
                                        id_culture && (
                                            <Grid
                                                xs
                                                style={{ margin: 8 }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={useHourly}
                                                            onChange={(e) => setUseHourly(e.target.checked)}
                                                            name="debase"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={vocabulary.irrigationType}
                                                />
                                            </Grid>
                                        )
                                    }
                                    {
                                        id_culture && useHourly && (
                                            <Grid xs>

                                                <StyledSelect
                                                    value={selectedIrrigationType}
                                                    register={register}
                                                    errors={errors.irrigationType}
                                                    onChange={(e) => setSelectedIrrigationType(e.target.value)}
                                                    name='irrigationType'
                                                    styles={{ width: '100%' }}
                                                    label={vocabulary.irrigationType}
                                                    data={irrigationTypes}
                                                    labelField='name'
                                                    valueField='value'
                                                />
                                            </Grid>
                                        )
                                    }

                                    {
                                        id_culture && useHourly && (
                                            <Grid item xs style={{ margin: 8 }}>
                                                <IrrigationTypes
                                                    type={selectedIrrigationType}
                                                    values={irrigation_params}
                                                    setValues={onChangeIrrigationParams}
                                                />
                                            </Grid>
                                        )
                                    } */}


                                    <StyledModalFooter actions={actions} />
                                </form>
                            ) : (
                                <StyledCircularProgress
                                    size={50}
                                    label={vocabulary.gettingCycleInformation}
                                />
                            )}
                        </StyledModal>
                        {openEditConfirmation === true && (
                            <AlertEditDialogConfirmation
                                operation={vocabulary.editLabel}
                                open={openEditConfirmation}
                                EditItemName={
                                    JSON.parse(item.prm_culture.name)[
                                    'name_' + currentLanguage
                                    ] +
                                    ' ' +
                                    vocabulary.ofLabel +
                                    ' ' +
                                    item.area.name
                                }
                                onClose={() => {
                                    setOpenEditConfirmation(false);
                                }}
                                confirmUpdate={() => {
                                    if (item) {
                                        handleUpdateIrrigation();
                                    }

                                    setOpenEditConfirmation(false);
                                }}
                                cancel={() => {
                                    setOpenEditConfirmation(false);
                                }}
                            />
                        )}
                    </>
                )}
        </>
    );
};

export default CycleAddUpdateForm;

const TassementSlider = withStyles({
    root: {
        color: '#fc4e03',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#FFF',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const MatiereOrganiqueSlider = withStyles({
    root: {
        color: '#299900',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#FFF',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

function valuetext(value) {
    return `${value} %`;
}

import React from 'react';

import { useSelector } from 'react-redux';

import { CircularProgress } from "@material-ui/core/";
import { useHistory } from "react-router-dom";
import StationList from './StationList'

const Station=()=> {

  // const [openPosition, setOpenPosition] = useState(false);

  const appLoading = useSelector(state => state.app.appLoading)
  let history = useHistory();



  return (
    <>
      <div
        style={{
          visibility: appLoading ? "visible" : "hidden",
          position: "absolute",
          left: "50%",
          top: "45%"
        }}
      >
        <CircularProgress size={50} />
      </div>

      <div
        className="stationLoadTable"
        style={{ opacity: appLoading ? 0.4 : 1, width: '100%', margin: '10px' }}
      >
        <StationList history={history} />
        
      </div>
      
    </>

  );

}
export default Station;

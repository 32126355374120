export const LogicalOperator = 
{
    TYPES: [
      { title:'And "&&"', value:'&&'},
      { title:'OR "||"', value:"||"},
      { title:'Parentheses Open "("', value:"("},
      { title:'Parentheses Close ")"', value:")"},
      
    ],
    condition: [
      { title:'LESS-THAN OR EQUAL &le', value:"<="},
      { title:'GREATER-THAN OR EQUAL &ge', value:">="}     
    ]
    
  };

import React, { useState, useRef, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputAdornment, TextField } from '@material-ui/core/';
// import { Add, Done } from '@material-ui/icons/';
import 'bootstrap/dist/css/bootstrap.css';

import { checkUsersNumber } from '../../actions';
import { vocabulary } from '../Strings';
import './../../App.css';
import Button from '@material-ui/core/Button';


import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledModal } from '../../ui/layout'
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import StyledSelect from "../../ui/forms/StyledSelect";
import StyledMultipleSelect from "../../ui/forms/StyledMultipleSelect";
import { getCultureByFamilyList, GetCultureFamilliesList } from "../../globalsTools/network";
import { SaveAdvice } from "../../globalsTools/network";

const AddAdviceNotif = ({ onClose, open, agentNotifList, adviceList }) => {

  const dispatch = useDispatch()

  const form = useRef(null);
  const nbUsers = useSelector(state => state.advice.totalUsers)
  const userType = useSelector(state => state.app.userrights)
  const currentLanguage = useSelector(state => state._globalState.preferred_language)

  const { register, handleSubmit, errors } = useForm()
  const modalContext = useContext(ModalContext)


  const [type, setType] = useState('')
  const [selectedCultureIndex, setSelectedCultureIndex] = useState([])
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)

  const [CultureList, setCultureList] = useState(null)
  const [cultureFamilies, setCultureFamilies] = useState(null)
  const [cultureFamily, setCultureFamily] = useState(null)


  useEffect(() => {
    GetCultureFamilliesList(handleFamiliesCallback)

  }, [])

  const handleFamiliesCallback = (data, response, status) => {
    if (data.hasOwnProperty('data') && Array.isArray(data.data)) {
      setCultureFamilies(data.data)
    } else {
      setCultureFamilies(data)
    }
  }

  const checkUsersNumberAdvice = () => {

    dispatch(checkUsersNumber({
      type: type,
      cultures: selectedCultureIndex,
      // city: selectedVilleIndex
    }, null))
  }

  const sendNotification = (data) => {
    SaveAdvice(
      {
        type: type,
        cultures: selectedCultureIndex,
        //city: selectedVilleIndex,
        name: title,
        description: description
      }, function (r) {
        if (r) {
          if (r.status === 200) {
            if (userType && userType === 'Agent') {
              adviceList();
            } else {
              agentNotifList();
            }
            onClose()

          }
        }

      })
  }
  const actions = [
    { title: vocabulary.send, color: "primary", position: 'end', type: "submit" },
    { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
  ]
  const notifType = [
    {
      title: vocabulary.advice, id: 'advice'
    },
    {
      title: vocabulary.alert, id: 'alert'
    }
  ]


  return (
    <>
      <StyledModal
        open={open}
        onClose={onClose}
        modalContext={modalContext}
        title={vocabulary.newNotification}
        width={600}
      >
        <form
          ref={form}
          onSubmit={handleSubmit(sendNotification)}
        >
          <StyledInput
            register={register}
            rules={{ required: true }}
            name="name"
            label={vocabulary.titleLabel}
            type="text"
            fullWidth
            errors={errors.name}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value)
            }}

          />
          <StyledInput
            register={register}
            rules={{ required: true }}
            value={description}
            onChange={(event) => {
              setDescription(event.target.value)
            }}
            name="description"
            label={vocabulary.content}
            type="text"
            fullWidth
            errors={errors.description}

          />

          <StyledSelect

            data={notifType}
            label={vocabulary.type}
            labelField={'title'}
            valueField={'id'}
            name={vocabulary.type}
            onChange={(event) => {
              setType(event.target.value)
            }}
            styles={{ width: '100%' }}

            register={register}
            rules={{ required: true }}
            errors={errors.type}


          />
          <br />
          <StyledSelect
            value={cultureFamily}
            ref={register}
            rules={{ required: true }}
            errors={errors.family}
            styles={{ width: '100%' }}
            onChange={(event) => {
              setCultureFamily(event.target.value)
              getCultureByFamilyList(event.target.value, setCultureList)

            }}
            render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
            name='family'
            label={vocabulary.cultureFamily}
            data={cultureFamilies ? cultureFamilies : []}
            valueField='id'
            disabled={cultureFamilies == null ? true : false}

          />
          {
            <StyledMultipleSelect

              data={CultureList ? CultureList : []}
              label={vocabulary.culture}
              labelField={'name'}
              valueField={'id'}
              name={vocabulary.culture}
              onChange={(event) => {
                setSelectedCultureIndex(event.target.value)
              }}
              styles={{ width: '100%' }}
              register={register}
              rules={{ required: true }}
              errors={errors.type}
              currentLanguage={currentLanguage}
              selectedIndex={selectedCultureIndex}
              disabled={CultureList == null ? true : false}


            />}




          <br />
          {
            selectedCultureIndex.length > 0 && type.length > 0 ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Button variant="outlined" color="primary"
                  onClick={checkUsersNumberAdvice} size="large">
                  {vocabulary.nbbeneficiers}
                </Button>
                <TextField
                  disabled
                  id="outlined-adornment-weight"
                  variant="outlined"
                  style={{ width: "200px" }}
                  margin="dense"
                  value={nbUsers}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{vocabulary.followers}</InputAdornment>,
                  }}
                />
              </div>
              : null
          }

          <StyledModalFooter actions={actions} />


        </form>
      </StyledModal>
    </>
  )
}
export default AddAdviceNotif


export const CONSTS = 
{
    TYPES: [
      { title:'Public', value:'public'},
      { title:'Private', value:'private'},
      
    ]
  };
  
  
  
  
  
  
import React, { useEffect, useState } from 'react'

import AreasMapping from './AreasMapping'
import { LayersControl, MapContainer, TileLayer } from 'react-leaflet'
import { GetStatsMapping } from '../../../globalsTools/network';
import DevicesMapping from './DevicesMapping';
import WeatherStationsMapping from './WeatherStationsMapping';

const center = [36.3178255, 9.486694250000001];



const DashboardMap = () => {

  const [stats, setStats] = useState([]);

  useEffect(() => {
    GetStatsMapping(setStats);
  }, []);



  return (


    <MapContainer center={center} zoom={6}  style={{ width : '100%' , marginTop : 0}} >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LayersControl position="topright" key={"layer"}>
        {stats && stats.areas && <AreasMapping areas={stats.areas} />}
        {stats && stats.devices && <DevicesMapping devices={stats.devices} />}
        {stats && stats.devices && <WeatherStationsMapping weather_stations={stats.weather_stations} />}

      </LayersControl>
    </MapContainer>

  )
}


export default DashboardMap
import React from "react";

export default (props) => {

    const {base64, styles, image, height, onClick, width} = props

    const style = {
        ...styles,
        width: width,
        height: height,
        borderRadius: 5,
        cursor: onClick ? 'pointer' : null
    }

    return (
        <img style={style} width={width} src={base64 ? 'data:image/png;base64, ' + image : image } alt = "" onClick={onClick} />
    )

}



import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardContent from '@material-ui/core/CardContent';
import 'moment/locale/ar'
import 'moment/locale/fr'
import 'moment/locale/en-au'
import { setCurrentPage } from '../../actions';
import { vocabulary } from '../Strings';
import { useSnackbar } from 'notistack';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff, Edit, Done, HighlightOff } from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

import { styles } from '../globalStyle';
import { GetProfil, updateFarmerProfil, GetCountriesList, GetGovernatesList } from "../../globalsTools/network";
import StyledInput from "../../ui/forms/StyledInput";
import { useForm } from 'react-hook-form';
import { StyledCard } from "../../ui/layout";
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import AvatarUploader from '../../ui/specific/AvatarUploader'
import { isObject } from 'lodash';

const ProfileFarmer = (props) => {

    const appLoading = useSelector(state => state.app.appLoading)
    const userType = useSelector(state => state.app.userrights)
    const userId = useSelector(state => state.app.userid)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const dispatch = useDispatch()
    const form = useRef(null);

    const { register, errors } = useForm()
    const { enqueueSnackbar } = useSnackbar();

    const [countries, setCountries] = useState(null)
    const [password, setPassword] = useState('')
    const [c_password, setC_password] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showC_password, setShowC_password] = useState(false)
    const [profil, setProfil] = useState(null)

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.profile))
        document.title = vocabulary.profile

        GetProfil(setProfil)
        GetCountriesList(currentLanguage, setCountries)


    }, [dispatch]); // N’exécute l’effet que a la première dender

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.profile))
        document.title = vocabulary.profile


    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(vocabulary.profile + vocabulary.updateSucc, 'success')


            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');

            }
        }
    }

    const onChange = (e, json = null) => {
        setProfil({
            ...profil,
            [e.target.name]: json ? json : e.target.value
        })
    }

    const onChangeCountry = (e, newValue) => {
        setProfil({
            ...profil,
            'prm_country_id': newValue.id
        })
    }

    const EditFarmerProfil = () => {

        let updatedUser = {
            id: profil.id,
            firstname: profil.firstname,
            lastname: profil.lastname,
            mobile: profil.mobile,
            address: JSON.stringify(profil.address),
            prm_country_id: profil.prm_country_id,
        }

        if (password) {
            updatedUser = {
                ...updatedUser,
                password: password,
                c_password: c_password,
            }
        }

        return (
            setIsEditing(false),
            updateFarmerProfil(updatedUser, r => operationCallBack(r))
        )

    }


    return (

        <>


            {profil ?
                <StyledCard style={{
                    elevation: 1,
                    background: '#eee',
                }} >
                    <div style={{ width: '100%', display: 'flex' }}>
                        <form
                            ref={form}
                            style={{ width: '50%' }}
                        >
                            <CardContent >
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {isEditing === false ?
                                        <Edit fontSize="large" style={{ cursor: 'pointer' }} color="primary" onClick={() => { setIsEditing(true) }}
                                        />
                                        : <>
                                            <HighlightOff fontSize="large"
                                                color="primary"
                                                style={{ cursor: 'pointer', marginRight: 15 }}
                                                onClick={() => { setIsEditing(false) }}
                                            />
                                            <Done fontSize="large"
                                                color="primary"
                                                type='submit'
                                                onClick={() => EditFarmerProfil()} />
                                        </>}

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <StyledInput
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.englishName}
                                        name="firstName"
                                        label={vocabulary.firstname}
                                        type="text"
                                        onChange={onChange}
                                        fullWidth
                                        disabled={!isEditing}
                                        value={profil.firstname}
                                    />

                                    <StyledInput
                                        value={profil.lastname}
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.lastName}
                                        name="lastName"
                                        label={vocabulary.lastname}
                                        type="text"
                                        onChange={onChange}
                                        fullWidth
                                        disabled={!isEditing}

                                    />
                                    <StyledInput
                                        value={profil.email}
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.email}
                                        name="email"
                                        label={vocabulary.email}
                                        type="text"
                                        onChange={onChange}
                                        fullWidth
                                        disabled={!isEditing}

                                    />

                                    <StyledInput
                                        value={profil.mobile}
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.mobile}
                                        name="mobile"
                                        label={vocabulary.mobile}
                                        type="text"
                                        onChange={onChange}
                                        fullWidth
                                        disabled={!isEditing}

                                    />


                                    {vocabulary.otherInformations}



                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <StyledInput
                                            value={isObject(profil.address) ? profil?.address?.address : ''}
                                            register={register}
                                            rules={{ required: true }}
                                            errors={errors.address}
                                            name="address"
                                            label={vocabulary.address}
                                            type="text"
                                            onChange={(e) => onChange(e, { address: e.target.value })}
                                            fullWidth
                                            disabled={!isEditing}
                                        />
                                    </div>

                                    <StyledAutoComplete
                                        defaultValue={isEditing ? profil.country : ''}
                                        data={countries ? countries : []}
                                        optionLabel={(option) => option.name}
                                        name="prm_country_id"
                                        onChange={onChangeCountry}
                                        valueField="id"
                                        label={vocabulary.country}
                                        disabled={!isEditing}
                                    />

                                    <StyledInput
                                        value={password}
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.password}
                                        name="password"
                                        label={vocabulary.password}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => setPassword(e.target.value)}
                                        fullWidth
                                        disableAutoComplete
                                        disabled={!isEditing}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    {isEditing ? <StyledInput
                                        value={c_password}
                                        register={register}
                                        rules={{ required: true }}
                                        errors={errors.c_password}
                                        name="c_password"
                                        label={vocabulary.c_password}
                                        type={showC_password ? 'text' : 'password'}
                                        onChange={(e) => setC_password(e.target.value)}
                                        fullWidth
                                        disableAutoComplete
                                        disabled={!isEditing}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={() => setShowC_password(!showC_password)}
                                                    >
                                                        {showC_password ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    /> : null}



                                </div>


                            </CardContent>
                        </form>
                        <div className='userPersonalInformation' style={{ width: '50%', margin: "20px" }}>



                            <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', gap: '20px', textAlign: 'justify' }}>
                                <span>  {vocabulary.joinedLabel + moment(profil.activesince).locale(currentLanguage).format("DD/MMMM/YYYY")} </span>

                                <span>  {vocabulary.lastConnectionLabel + moment(profil.last_connection).locale(currentLanguage).format("DD/MMMM/YYYY HH:mm")} </span>
                            </div>


                        </div>

                    </div>
                </StyledCard>
                : <StyledCircularProgress size={50} label={vocabulary.loading} />
            }

        </>

    )

}


export default ProfileFarmer;



import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledMaterialTable } from '../../ui/display'
import { StyledCard } from '../../ui/layout'
import { vocabulary } from '../Strings'
import { GetOrdersList } from '../../globalsTools/network'
import { Check, Delete, History, Refresh, RotateLeft, SyncProblem } from '@material-ui/icons'
import OrderDetails from './OrderDetails'
import { Chip } from '@material-ui/core'

const OrdersList = props => {

    const [ordersList, setOrdersList] = useState([])

    useEffect(() => {
        GetOrdersList(setOrdersList)
    }, [])

    
    const mtDetailPanelFn = useCallback(({ rowData }) => {
            return (<OrderDetails orderObject={rowData} />)
        }, [])

    
    const refreshFn = useCallback(() => {
        GetOrdersList(setOrdersList)
    }, [])

    const mtRenderStatus = useCallback((rowData) => {

    switch (rowData.order_status) {
        case "PAID":
            return <Chip style={{ backgroundColor: '#64DD17', color: 'white', }} 
                         icon={<Check style={{ color: 'white' }} />} 
                         label="Payé" />

        case "FAILED":
            return <Chip style={{ backgroundColor: '#DD2C00', color: 'white', }} 
                         icon={<Delete style={{ color: 'white' }} />} 
                         label="Echoué" />

        case "INCOMPLETE":
            return <Chip style={{ backgroundColor: '#37474F', color: 'white', }} 
                         icon={<SyncProblem style={{ color: 'white' }} />} 
                         label="Incomplet" />
    
        case "CANCELED_REFUNDED":
            return <Chip style={{ backgroundColor: '#FF6D00', color: 'white', }} 
                         icon={<RotateLeft style={{ color: 'white' }} />} 
                         label="Annulé / Remboursé" />
    
        case "CREATING":
            return <Chip style={{ backgroundColor: '#CFD8DC', color: 'grey', }} 
                         icon={<History style={{ color: 'grey' }} />} 
                         label="Crée sans suite" />
    
        default:
            return <></>;
    }
    }, [])


    
    const mtRenderTotal = useCallback((rowData) => rowData.ordered_total_amount + " " + rowData.currency
    , [])

    const mtRenderUsername = useCallback((rowData) => rowData.user.firstname + " " + rowData.user.lastname
    , [])



    return (
        <StyledCard>
            <StyledMaterialTable
                title={vocabulary.ordersList}
                data={ordersList}
                detailPanel={[
                    {
                        tooltip: vocabulary.viewDetailsLabel,
                        render: mtDetailPanelFn
                }]}
                columns={[
                    { title: vocabulary.orderReference, 
                        field: 'number'
                    },

                    { title: vocabulary.orderDate, 
                        field: 'order_date', 
                    },
                    { title: vocabulary.firstname, field:'user.lastname',
                        // render:mtRenderUsername
                    },
                    { title: vocabulary.lastname, field:'user.firstname'},
                    { 
                        title: vocabulary.status, 
                        field: 'ordered_total_amount',
                        render:mtRenderTotal
                    },
                    { 
                        title: vocabulary.orderStatus, 
                        field: 'order_status',
                        render:mtRenderStatus

                    },

                ]}

                actions={[

                    {
                        icon: () => <Refresh fontSize='large' color='primary' />,
                        tooltip: vocabulary.refreshData,
                        isFreeAction: true,
                        onClick: refreshFn
                    },

                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 50,
                    pageSizeOptions: [20, 50, 100],
                    search:true,
                }}

            />
        </StyledCard>
    )
}

OrdersList.propTypes = {

}

export default OrdersList

import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Chip, Divider, Grid, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { CheckCircle, Error, HourglassEmpty, Refresh } from '@material-ui/icons'
import { GetOrdersLinesList, ManuallyProcessOrderLine } from '../../globalsTools/network'
import { StyledMaterialTable } from '../../ui/display'
import { vocabulary } from '../Strings'
import { useSnackbar } from 'notistack'
import { logger } from '../../globalsTools/utilities'
import { StyledInput, StyledSelect } from '../../ui/forms'

const OrderDetails = ({orderObject}) => {

    const [orderLinesList, setOrderLinesList] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [order, setOrder] = useState(null)
    const [showManuallyPayForm, setShowManuallyPayForm] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(null)

    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
        variant: type,
        preventDuplicate: true,

        });
    };
            
    const relaunchOrderProcessing = useCallback((order_id) => {
        setIsLoading(true)
        ManuallyProcessOrderLine(order_id, processResponse)
    }, [])

    const processResponse = (response, success, status) => {
        setIsLoading(false)

        if (success && (status === 200 || status === 204)) {
            refreshFn()
            snackbar(vocabulary.operationSucc, 'success')
        } else {
            snackbar(vocabulary.response, 'error')
        }
    }
        
    const refreshFn = useCallback(() => {
        GetOrdersLinesList(order?.id, setOrderLinesList)
    }, [order])

    useEffect(() => {
        if (orderObject) {
            try {
                setOrder({...orderObject, additional_information:JSON.parse(orderObject.additional_information)})
                GetOrdersLinesList(orderObject?.id, setOrderLinesList)
            } catch(e) {
                setOrder(orderObject)
            }
        }
    }, [orderObject])
    


    const mtRenderOrderLineStatusFn    = useCallback((rowData) => { return (
        rowData.order_line_status==="FAILED" 
            ?   <Chip   icon={<Error style={{color:'white'}} />} 
                        style={{backgroundColor : "orange",color:'white'}} 
                        label={rowData.order_line_status} 
                        clickable onClick={()=>rowData && relaunchOrderProcessing(rowData.order_id)} /> 
            :   (rowData.order_line_status==="SUCCESS" 
                ? <Chip icon={<CheckCircle style={{color:'white'}} />}   style={{backgroundColor : "green", color:'white'}} label={rowData.order_line_status} 
                        clickable onClick={()=>rowData && relaunchOrderProcessing(rowData.order_id)}  />
                : <Chip icon={<HourglassEmpty  style={{color:'white'}}/>} style={{backgroundColor : "grey", color:'white' }} label={rowData.order_line_status} />)
        )},[])

    return (
        <div style={{padding:20, backgroundColor:'#efefef'}}>

            {
                order?.order_status === "TO_BE_PAID" ? 

                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Commande non payée</TableCell>
                                <TableCell><Button onClick={()=>setShowManuallyPayForm(true)} variant='contained'>Payer Maintenant</Button></TableCell>
                                {
                                    showManuallyPayForm && 
                                    <>
                                        <TableCell>
                                            <StyledSelect
                                                    name="payment_method"
                                                    value={paymentMethod}
                                                    onChange={(event) => {
                                                        setPaymentMethod(event.target.value)}}
                                                >
                                                <MenuItem value="check">Chèque</MenuItem>
                                                <MenuItem value="wire_transfer">Virement</MenuItem>
                                                <MenuItem value="cash">Espèce</MenuItem>
                                            </StyledSelect>
                                        </TableCell>
                                        <TableCell>Commande non payée</TableCell>
                                        <TableCell>Commande non payée</TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                :

                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Informations Carte de paiement</TableCell>
                                <TableCell colSpan={3}>{order?.additional_information?.full_payment_object?.charges?.data?.map((element) => {
                                    let card = element.payment_method_details?.card;
                                    if (card) {
                                        return (
                                            <Grid container direction='row' style={{justifyContent:'space-between'}}>
                                                <Grid item wrap='nowrap'>
                                                    <Typography>
                                                        <Chip style={{ backgroundColor: '#efefef', color: 'black', }} 
                                                            label={card.brand.toUpperCase()} />
                                                        {"**** " + card.last4 + " (" + card.exp_month + "/" + card.exp_year + ")"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                <Chip style={{ backgroundColor: '#efefef', color: 'black', }} 
                                                    label={card.funding.toUpperCase()} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography>Authentification 3DSecure&nbsp;&nbsp;&nbsp; {card.three_d_secure ? 
                                                                                                <Chip style={{ backgroundColor: '#dafbd6', color: 'black', }} 
                                                                                                    label={"Oui"} />
                                                        : <Chip style={{ backgroundColor: '#efefef', color: 'black', }} 
                                                        label={" Non"} />}</Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    } else {
                                        return "N/A"
                                    }
                                })}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reçu</TableCell>
                                <TableCell>{ order?.additional_information?.receipt_url?.map((receipt, index)=><a key={'receipt' + order?.id + index} href={receipt} rel="noopener noreferrer" target='_blank'>Reçu N°{index+1}&nbsp;</a>)}</TableCell>
                                <TableCell>Heure de l'opération</TableCell>
                                <TableCell>{ order?.additional_information?.full_payment_object?.created && new Date(order?.additional_information?.full_payment_object?.created * 1000)?.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Société</TableCell>
                                <TableCell>{order?.additional_information?.billingDetails?.company ?? "-"}</TableCell>
                                <TableCell>TVA / SIRET</TableCell>
                                <TableCell><a href={'https://www.societe.com/cgi-bin/search?champs=' + order?.additional_information?.billingDetails?.tva} rel="noopener noreferrer" target='_blank'>{order?.additional_information?.billingDetails?.tva ?? "-"}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Adresse</TableCell>
                                <TableCell>{order?.additional_information?.billingDetails?.billingAddress ?? "-"}</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>{order?.additional_information?.billingDetails?.notificationEmail ?? "-"}</TableCell>
                            </TableRow>
                            { order && order.user && order.user.mobile &&
                                <TableRow>
                                    <TableCell>Tel</TableCell>
                                    <TableCell>{order?.user?.mobile ?? "-"}</TableCell>
                                </TableRow>
                            }
                    </TableBody>
                    </Table>
                </TableContainer>

            }

            <StyledMaterialTable 
                styles={{marginTop:10}}
                title={vocabulary.orderDetail}
                data={orderLinesList}
                columns={[
                    { title: vocabulary.title, 
                        field: 'title'
                    },

                    { title: vocabulary.area.capitalize(), 
                        field: 'area.name'
                    },

                    { title: vocabulary.quantity, 
                        field: 'quantity', 
                    },
                    { 
                        title: vocabulary.total, 
                        field: 'ordered_total_amount'
                    },
                    { 
                        title: vocabulary.status, 
                        field: 'order_line_status',
                        render: mtRenderOrderLineStatusFn
                    },

                ]}
                
                
                actions={[

                    {
                        icon: () => <Refresh fontSize='large' color='primary' />,
                        tooltip: vocabulary.refreshData,
                        isFreeAction: true,
                        onClick: refreshFn
                    },

                ]}
            
            />
            <Snackbar 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                    open={isLoading}
                    autoHideDuration={6000}
                    message={vocabulary.loading}
                    />
        </div>
    )
}

export default OrderDetails

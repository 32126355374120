/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { vocabulary } from '../Strings';
import { useSnackbar } from 'notistack';
import { DeleteExpensesRevenues, GetExpensesRevenues, GetAreasList } from "../../globalsTools/network";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import AlertDialog from "../Components/alertDialog";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { setCurrentPage } from '../../actions';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RevnuesAddForm from './RevnuesAddForm'
import { Chip, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core/';
// import FactCheckIcon from '@material-ui/icons/FactCheck';
import PropTypes from "prop-types";
import CancelIcon from "@material-ui/icons/Cancel";
// import compose from 'recompose/compose';
// import { styles } from '../globalStyle';
import expenses from '../../images/expenses.png';
import revenues from '../../images/revenues.png';
import { stylesThemed } from '../globalStyle';
// import RevenueCharts from './RevenueCharts'

const RevenuesExpensesList = () => {

    const classes = stylesThemed();
    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const userId = useSelector(state => state.app.userid)
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [expensesRevenuesList, setExpensesRevenuesList] = useState(null)
    // const [financialCategorieList, setFinancialCategorieList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sitesList, setSitesList] = useState(null)
    const [type, setType] = useState('all')

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.expensesRevenue));
        document.title = vocabulary.expensesRevenue
        GetExpensesRevenues(setExpensesRevenuesList)
        // GetFinancialCategories(setFinancialCategorieList)
        GetAreasList(setSitesList)


    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.expensesRevenue));
        document.title = vocabulary.expensesRevenue

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.note + ' ' + vocabulary.deleteMsg, 'success')
                GetExpensesRevenues(setExpensesRevenuesList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteRevenue = (item) => {

        DeleteExpensesRevenues(item.id
            , r => operationCallBack(r)
        )
    }

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const itemType = (type) => {
        switch (type) {
            case "expenses":
                return (
                    <Chip
                        icon={<div style={{ marginBottom: '2px', marginLeft: '10px' }}><img alt='expenses' src={expenses} /></div>}
                        label={vocabulary.expenses}
                        className={[
                            classes.chip,
                            classes.colorLockOpenType,
                            classes.textLockOpenColor,
                        ]}
                        variant="outlined"
                    />
                );
                break;
            case "revenues":
                return (
                    <Chip
                        icon={<div style={{ marginBottom: '4px', marginLeft: '10px' }}><img alt="revenues" src={revenues} /></div>}
                        label={vocabulary.revenues}
                        className={[
                            classes.chip,
                            classes.colorEroorType,
                            classes.textErrorColor,
                        ]}
                        variant="outlined"
                    />
                );
                break;
            default:
                return type

        }
    };
    const tableTitle = (type) => {
        switch (type) {
            case "all":
                return (
                    <Typography variant="h6" component="h2">
                        {vocabulary.expensesRevenue}
                    </Typography>
                );
                break;
            case "Revenues":
                return (
                    <Typography variant="h6" component="h2">
                        {vocabulary.revenues}
                    </Typography>
                );
                break;
            case "Expenses":
                return (
                    <Typography variant="h6" component="h2">
                        {vocabulary.expenses}
                    </Typography>
                );
                break;
            default:
                return type


        }
    };
    const formatedData = (type) => {
        switch (type) {
            case "all":
                return (
                    expensesRevenuesList.data
                );
                break;
            case "Revenues":
                return (
                    expensesRevenuesList.data.filter(el => el.type == 'revenues')
                );
                break;
            case "Expenses":
                return (
                    expensesRevenuesList.data.filter(el => el.type == "expenses")
                );
                break;
            default:
                return type

        }
    };

    const handleChange = event => {
        setType(event.target.value);
    };

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            <StyledCard>
                {(!isLoading && expensesRevenuesList) ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        {/* <RevenueCharts
                            data={formatedData(type)}
                            dateFrom={moment(new Date().setDate(new Date().getDate() - 30)).format("YYYY-MM-DD")}
                            dateTo={moment(new Date()).format("YYYY-MM-DD")}
                            type={type}
                        /> */}
                        <StyledMaterialTable
                            title={
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px' }}>
                                    {tableTitle(type)}
                                    <div style={{ marginTop: '10px' }}>
                                        <RadioGroup row aria-label="gender" name="soiltypechoice" value={type} onChange={handleChange}>
                                            <FormControlLabel value="all" control={<Radio />} label={'All'} />
                                            <FormControlLabel value="Revenues" control={<Radio />} label={vocabulary.revenues} />
                                            <FormControlLabel value="Expenses" control={<Radio />} label={vocabulary.expenses} />

                                        </RadioGroup>
                                    </div>

                                </div>
                            }
                            data={formatedData(type)}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}
                            columns={[
                                { title: vocabulary.title, field: 'note', redner: rowData => <>{rowData.note ? rowData.note : ''}</> },
                                { title: vocabulary.area, field: 'area', render: rowData => <>{rowData.production_cycle ? (rowData.production_cycle.area.parent ? rowData.production_cycle.area.name + ' / ' + rowData.production_cycle.area.parent.name : rowData.production_cycle.area.name) : '--'}</> },
                                { title: vocabulary.cycleLabel, field: 'production_cycle', render: rowData => <>{rowData.production_cycle ? JSON.parse(rowData.production_cycle && rowData.production_cycle.prm_culture.name)['name_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.date, field: 'type', render: rowData => <>{rowData.date ? currentLanguage == "en" ? moment(rowData.date).format('YYYY-MM-DD') : moment(rowData.date).format('DD-MM-YYYY') : ''}</> },
                                { title: vocabulary.price, field: 'total', redner: rowData => <>{rowData.total ? Number(rowData.total).toFixed(3) : ''}</> },
                                { title: vocabulary.quantity, field: 'quantity', redner: rowData => <>{rowData.quantity ? Number(rowData.quantity).toFixed(3) : ''}</> },
                                {
                                    title: vocabulary.type, align: 'center', field: 'type', render: rowData => (
                                        <>
                                            {rowData.type ? (
                                                itemType(rowData.type)
                                            ) : (
                                                <Chip
                                                    icon={<CancelIcon />}
                                                    label={'No Type'}
                                                    className={classes.chip}
                                                    color="default"
                                                    variant="outlined"
                                                />
                                            )}
                                        </>
                                    )

                                },
                                { title: vocabulary.financialCategoryLabel, field: 'financial_category', render: rowData => <>{rowData.item ? rowData.item.financial_category ? rowData.item.financial_category.title : '' : ''}</> },
                                { title: vocabulary.itemsLabel, field: 'type', render: rowData => <>{rowData.item ? rowData.item.title : ''}</> },

                            ]}
                            actions={
                                [
                                    row => ({
                                        icon: 'edit_outline',
                                        onClick: (event, row) => onEdit(row),
                                        tooltip: userId !== row.user_id ? vocabulary.disableEdit : vocabulary.editLabel,
                                        disabled: userId !== row.user_id
                                        // hidden: showAllGroup === true ? true : false,

                                    }),
                                    row => ({
                                        icon: 'delete_outline',
                                        onClick: (event, row) => openAlertConfirmDelete(row),
                                        tooltip: userId !== row.user_id ? vocabulary.disableDelete : vocabulary.delete,
                                        disabled: userId !== row.user_id
                                        // hidden: showAllGroup === true ? true : false,

                                    }),
                                    {
                                        icon: () => <AddIcon fontSize='large' color='primary' />,
                                        tooltip: vocabulary.add,
                                        isFreeAction: true,
                                        onClick: (event) => handleClickOpen()
                                    },


                                ]
                            }
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 40, 60, 80, 100],

                            }}
                        />
                    </div>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }


                {alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.note}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeleteRevenue(item);
                            }

                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}

                {(openAdd == true || openEdit == true) &&
                    <RevnuesAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={600}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        setIsLoading={setIsLoading}
                        refetchFunc={() => GetExpensesRevenues(setExpensesRevenuesList)}
                        // financialCategorieList={financialCategorieList && financialCategorieList.data}
                        sitesList={sitesList}
                    />}
            </StyledCard>
        </ModalContext.Provider>
    )
}
// RevenuesExpensesList.propTypes = {
//     classes: PropTypes.object.isRequired,
// };
export default RevenuesExpensesList

import React from 'react'

const StyledCircleText = ({height, children, borderColor, border=10}) => {
  return (
    <div style={{height: `${height}px`, width:`${height}px`, lineHeight:`${height}px`, borderRadius:`50%`, textAlign:'center', border:`${border}px solid`, borderColor:borderColor, margin:20, padding:20}}>
        {children}
    </div>
  )
}

export default StyledCircleText
import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../../globalConsts";
// import { useHistory } from "react-router-dom";
import { GuideContext, steps } from "../../../ui/layout/contextLayout/GuideContext";

const GotoDialogStepper = ({ setOpenGoto }) => {
  
  const { guide, dispatchGuide } = useContext(GuideContext);
  // const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element === ".drawAreaBtn" || steps[nextStepIndex].element === ".goToButton") {
          dispatchGuide({
            type: IS_ENABLED,
            payload: false,
          });
          dispatchGuide({
            type: INITIAL_STEP,
            payload: nextStepIndex,
          });
          dispatchGuide({
            type: CURRENT_STEP,
            payload: "seabexMap",
          });
          //context.changeModalStatus(false)
          setOpenGoto(false);
        }
      }}
      options={{
        tooltipClass: "steps",
      }}
      // onBeforeExit={()=>false}
      onExit={() =>
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        })
      }
    />
  );
}

export default GotoDialogStepper;

import React, { useEffect, useState } from 'react';

import { vocabulary } from '../Strings';
import { logger } from '../../globalsTools/utilities';
import PrecipitationSummary from './PrecipitationSummary';
import './style.css';
import WaterNeedHistory from './WaterNeedHistory';
import WaterReqCardNetirrig from './WaterReqCardNetirrig';
import { GetWaterNeedByProductionCycle } from '../../globalsTools/network';
import { eventSubscribe, eventUnsubscribe } from '../../globalsTools/events';
import IrrigationSummary from './IrrigationSummary';

const WaterRequirementProductionCycle = ({ productionCycleId }) => {

    const [parcelWaterNeed, setParcelWaterNeed] = useState(null);
    const [usedUnit, setUsedUnit] = useState(vocabulary.metreJour);

    const reloadData = () => {
        logger('reloading Data after calculation')
        GetWaterNeedByProductionCycle(productionCycleId, setParcelWaterNeed);
    }

    useEffect(() => {
        console.log('parcelWaterNeed', parcelWaterNeed);
    }, [parcelWaterNeed]);

    useEffect(() => {
        GetWaterNeedByProductionCycle(productionCycleId, setParcelWaterNeed);
        eventSubscribe('recalculated.' + productionCycleId, reloadData);
        return () => {
            eventUnsubscribe('recalculated.' + productionCycleId, reloadData);
        };
    }, [productionCycleId]);

    return (
        <>
            {parcelWaterNeed !== null && (
                <>
                    {!parcelWaterNeed.production_cycle.end_date &&
                        <WaterReqCardNetirrig
                            waterNeedData={parcelWaterNeed}
                            usedUnit={setUsedUnit}
                        />
                    }
                    <WaterNeedHistory
                        usedUnit={usedUnit}
                        productionCycle={parcelWaterNeed.production_cycle}
                        cycleDetail={parcelWaterNeed}
                    />

                    <PrecipitationSummary
                        data={parcelWaterNeed.precipitation_summary}

                    />

                    <IrrigationSummary
                        data={parcelWaterNeed.irrigation_summary}

                    />
                </>
            )}
        </>
    );
};

export default WaterRequirementProductionCycle;

/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import PropTypes from 'prop-types';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import AutomationPlanForm from './AutomationPlanForm';
import Chip from '@material-ui/core/Chip';
import { StyledMaterialTable } from '../../ui/display';
// import { StyledCard } from "../../ui/layout";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { setCurrentPage } from '../../actions';
import { GetAutomationPlanList, deleteIrrigationPlan, GetAreasList, synchronizeIrrigationPlan } from "../../globalsTools/network";
import AlertDialog from "../Components/alertDialog";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import TasksTable from './TasksTable'
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Check, Refresh } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { stylesThemed } from '../globalStyle';

const AutomationPlansTable = () => {

    const classes = stylesThemed();

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const moment = require('moment');
    const { enqueueSnackbar } = useSnackbar();

    const [openEdit, setOpenEdit] = useState(false);
    const [item, setItem] = useState(null)
    const [openAdd, setOpenAdd] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [automationPlansList, setAutomationPlansList] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [siteList, setSiteList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const handleClickOpenEdit = (row) => {
        setOpenEdit(true)
        setItem(row)
        setIsEditing(true)

    };
    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.automationPlans));
        GetAutomationPlanList(setAutomationPlansList)
        GetAreasList(setSiteList)
        document.title = vocabulary.automationPlans

    }, [dispatch]); // N’exécute l’effet que a la première render

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.automationPlans));
        document.title = vocabulary.automationPlans

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const handleClickOpenAdd = () => {
        setOpenAdd(true)
    };
    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(item.title + " " + vocabulary.deleteMsg, 'success')
                GetAutomationPlanList(setAutomationPlansList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteAutomationPlan = (item) => {

        deleteIrrigationPlan(item.id
            , r => operationCallBack(r)
        )
    }
    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };
    const statusType = (status) => {
        switch (status) {
            case 'Enabled':
                return <Chip
                    label={vocabulary[status.toString().toLowerCase()]}
                    variant="outlined"
                    className={[classes.chip, classes.colorLockOpenType, classes.textLockOpenColor]}

                />
                break;
            case "Disabled":
                return <Chip
                    label={vocabulary[status.toString().toLowerCase()]}
                    variant="outlined"
                    className={[classes.chip, classes.colorEroorType, classes.textErrorColor]}

                />
                break;
            case 'Accepted':
                return <Chip
                    label={vocabulary[status.toString().toLowerCase()]}
                    variant="outlined"
                    className={[classes.chip, classes.colorMonitoring, classes.monitoringTextColor]}

                />
                break

            case 'Proposed':
                return <Chip
                    label={vocabulary[status.toString().toLowerCase()]}
                    variant="outlined"
                    className={[classes.chip, classes.colorCommandType, classes.textCommandColor]}

                />
                break
            default:
                return status
                break;
        }
    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
            {
                (!isLoading && automationPlansList) ?
                    <>

                        <StyledMaterialTable
                            title={vocabulary.automationPlans}
                            data={automationPlansList.data}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                            detailPanel={[
                                {
                                    tooltip: 'Show Details',
                                    render: ({ rowData }) => { return (<TasksTable rowData={rowData} />) }
                                }]}
                            columns={[
                                { title: vocabulary.site, field: 'area.name' },
                                { title: vocabulary.title, field: 'title' },
                                { title: vocabulary.description, field: 'description' },
                                {
                                    title: vocabulary.status, field: 'status', render: rowData =>
                                        <div>

                                            {
                                                statusType(rowData.status)
                                            }


                                        </div>
                                },
                                {
                                    title: "Synchronisé?", field: 'is_synchronized', render: rowData =>
                                        <div>

                                            {
                                                rowData.is_synchronized ? <div style={{color:'green'}}><Check /> Synchronisé</div> 
                                                                        : <Button   size='small' variant="contained" color='primary' startIcon={<Refresh />} 
                                                                                    onClick={() => synchronizeIrrigationPlan(rowData.id, GetAutomationPlanList(setAutomationPlansList))}>Synchroniser</Button>
                                            }


                                        </div>
                                },
                                { title: vocabulary.created_at, field: 'created_at', render: rowData => <>{rowData.created_at ? currentLanguage == "en" ? moment(rowData.created_at).format('YYYY/MM/DD') : moment(rowData.created_at).format('DD/MM/YYYY') : '--'}</> },

                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => handleClickOpenEdit(row)
                                },
                                rowData => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),
                                    disabled: rowData.id < 2000
                                }),

                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.AddAutomationPlan,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpenAdd()
                                },
                                {
                                    icon: () => <Refresh fontSize='large' color='primary' className="addCycleBtn" />,
                                    tooltip: vocabulary.refreshData,
                                    isFreeAction: true,
                                    onClick: (event) => GetAutomationPlanList(setAutomationPlansList)
                                },

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,

                            }}
                        />


                        <div>

                            <AutomationPlanForm
                                open={isEditing === false ? openAdd : openEdit}
                                onClose={() => {
                                    return (
                                        isEditing === true ?
                                            (setOpenEdit(false),
                                                setIsEditing(false))
                                            : setOpenAdd(false)

                                    )
                                }}
                                item={isEditing === true ? item : null}
                                refetchFunc={() => GetAutomationPlanList(setAutomationPlansList)}
                                isEditing={isEditing}
                                siteList={siteList && siteList}
                                setIsLoading={setIsLoading}


                            />
                            {alertConfirmDelete === true &&
                                <AlertDialog
                                    operation={vocabulary.delete}
                                    open={alertConfirmDelete}
                                    deletedItemName={item.title}
                                    onClose={() => {
                                        closeAlertConfirmDelete();
                                    }}
                                    confirmeDelete={() => {
                                        if (item) {
                                            handleDeleteAutomationPlan(item);
                                        }

                                        closeAlertConfirmDelete();
                                    }}

                                    cancel={() => {
                                        closeAlertConfirmDelete();
                                    }}
                                />}

                        </div>
                    </>
                    : <StyledCircularProgress size={50} label={isLoading ? vocabulary.AddAutomationPlan + ' ' + vocabulary.cycleStatus : vocabulary.loading} />

            }
        </ModalContext.Provider>

    );

}



export default AutomationPlansTable;





import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import PartnerActivateModal from '../partners/modals/PartnerActivateModal'
import PartnerConfigureModal from './PartnerConfigureModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DisableIntegrations } from '../../../globalsTools/network';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));



export default function PartnerCard({ partner, refreshFn }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openConfigureModal, setOpenConfigureModal] = useState(false)
    const [currentItem, setCurrentItem] = useState()
    const currentLanguage = useSelector(
        (state) => state._globalState.preferred_language
    );
    let history = useHistory();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleConfigureOpen = () => {
        setOpenConfigureModal(true);
    };

    const goToConfigPage = (partner) => {

        history.push(`/app/integrations/${partner.partner_id}/configure`, { partner })
    }

    const disable = () => {
        DisableIntegrations(partner.partner_id, () => {
            refreshFn()
        })

    }

    useEffect(() => {
        setCurrentItem(partner)
    }, [partner])



    return (
        <>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe"
                            src={partner.image_url} className={classes.avatar}>

                        </Avatar>
                    }

                    title={partner.name}
                    subheader={partner.subheader}
                />

                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {partner.description[currentLanguage]}
                    </Typography>
                </CardContent>
                <CardActions  >
                    {
                        !partner.user_integrations ? (
                            <Button
                                onClick={handleOpen}
                                variant='outlined'
                                size="small"
                                color="primary">
                                Activate
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={() => goToConfigPage(partner)}
                                    variant='outlined'
                                    size="small"
                                    color="primary">
                                    Configure
                                </Button>
                                <Button
                                    onClick={() => disable(partner)}
                                    variant='outlined' size="small" color="primary">
                                    Disable
                                </Button>

                            </>
                        )
                    }


                </CardActions>



            </Card>

            <PartnerConfigureModal
                currentItem={currentItem}
                open={openConfigureModal}
                setOpen={setOpenConfigureModal}
            />

            <PartnerActivateModal
                refreshFn={refreshFn}
                currentItem={currentItem}
                open={open}
                setOpen={setOpen}
            />
        </>
    );
}

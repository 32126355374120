/* eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { Refresh } from '@material-ui/icons';
import { DeleteVoucher, GetAllVouchers, UpdateVoucher } from '../../../../globalsTools/network';
import { setCurrentPage } from '../../../../actions';
import StyledCircularProgress from '../../../../ui/display/StyledCircularProgress';
import { StyledMaterialTable } from '../../../../ui/display';
import { StyledCard } from '../../../../ui/layout';
import { ModalContext } from '../../../../ui/layout/contextLayout/ModalContext';
import AlertDialog from '../../../Components/alertDialog';
import { vocabulary } from '../../../Strings';
import VoucherAddForm from './VoucherAddForm';
import { Typography } from '@material-ui/core';



const VoucherListTable = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const moment = require('moment');

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [vouchersList, setVouchersList] = useState([])
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)
 

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.voucherManagement));
        GetAllVouchers(setVouchersList)
        document.title = vocabulary.voucherManagement

    }, [dispatch]); // N’exécute l’effet qu'a la première render'

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.voucherManagement));
        document.title = vocabulary.voucherManagement

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const handleClickOpen = () => {
        setOpenAdd(true)
    };
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const operationCallBack = (r, row) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isActive == true ? row.name + ' ' + vocabulary.activeLabel : row.name + ' ' + vocabulary.inactiveLabel, 'success')
                GetAllVouchers(setVouchersList)


            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }



    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

            <StyledCard>
                {vouchersList ?
                    <StyledMaterialTable
                        title={vocabulary.voucherList}
                        data={vouchersList}
                        localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                        columns={[
                            { title: vocabulary.name_label, field: 'code' },
                            { title: vocabulary.type, field: 'details.type' },
                            {
                                title: vocabulary.value,
                                field: 'details.percent',
                                render: rowData => <>{
                                    rowData?.details?.type === 'DISCOUNT'
                                        ? `${rowData?.details?.percent} %`
                                        : `${rowData?.pack?.reference}`
                                }
                                </>
                            },
                            {
                                title: vocabulary.endDate,
                                field: 'details.end_date',
                                render: rowData => <>
                                    <Typography>{rowData?.details?.end_date}</Typography>
                                    <Typography>{`(${moment(rowData?.details?.end_date).diff(moment(), 'days')} ${vocabulary.remainingDays})`}</Typography>
                                </>
                            },
                            {
                                title: vocabulary.groupLabel,
                                field: 'details.included_groups',
                                render: rowData => <>
                                    <Typography>{rowData?.details?.included_groups?.join(', ')}</Typography>
                                </>
                            },
                            {
                                title: vocabulary.userName,
                                field: 'user',
                                render: rowData => <>{rowData?.user ? rowData?.user?.firstname + ' ' + rowData?.user?.lastname : "*"}</>
                            },
                            {
                                title: vocabulary.seabexOffers,
                                field: 'pack.name',
                                render: rowData => <>{rowData?.pack ? JSON.parse(rowData?.pack?.name)[currentLanguage] : '*'}</>
                            },

                        ]}
                        actions={[
                            () => ({
                                icon: 'edit_outline',
                                tooltip: vocabulary.EditUser,
                                onClick: (event, row) => onEdit(row)
                            }),
                            () => ({
                                icon: 'delete_outline',
                                tooltip: vocabulary.delete,
                                onClick: (event, row) => openAlertConfirmDelete(row),

                            }),

                            {
                                icon: () => <AddIcon fontSize='large' color='primary' />,
                                tooltip: vocabulary.addNewUser,
                                isFreeAction: true,
                                onClick: () => handleClickOpen()
                            },
                            {
                                icon: () => <Refresh fontSize='large' color='primary' />,
                                tooltip: vocabulary.refreshData,
                                isFreeAction: true,
                                onClick: () => GetAllVouchers(setVouchersList)
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            pageSize: vouchersList.length,
                            pageSizeOptions: [20, 40, 80, 200, 500],

                            exportMenu: [{
                                label: 'Export PDF',
                                exportFunc: (cols, datas) => ExportPdf(cols, datas, "Users List")
                            }, {
                                label: 'Export CSV',
                                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Users List')
                            }]

                        }}
                    />
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />
                }


                {alertConfirmDelete &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={item.code}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                DeleteVoucher(item.id)
                                GetAllVouchers(setVouchersList)
                            }

                            closeAlertConfirmDelete();
                        }}

                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />}


                {(openAdd == true || openEdit == true) &&
                    <VoucherAddForm
                        open={isEditing === false ? openAdd : openEdit}
                        onClose={() => {
                            return (
                                isEditing === true ?
                                    (setOpenEdit(false),
                                        setIsEditing(false))
                                    : setOpenAdd(false)

                            )
                        }}
                        width={500}
                        item={isEditing === true ? item : null}
                        isEditing={isEditing}
                        refetchFunc={() => GetAllVouchers(setVouchersList)}
                    />}
            </StyledCard>
        </ModalContext.Provider>

    )
}
export default VoucherListTable
import React, { useState, useRef, useEffect, useContext } from 'react';

import StyledInput from "../../ui/forms/StyledInput";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { useSnackbar } from 'notistack';
import StyledSelect from "../../ui/forms/StyledSelect";
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { DateTimePicker } from '@material-ui/pickers';
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import { vocabulary } from '../Strings';
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";
import { CreateQuota, UpdateQuota } from "../../globalsTools/network";

const AddQuotaForm = ({ open, isEditing, onClose, setIsLoading, item, refetchFunc, siteList }) => {

    const modalContext = useContext(ModalContext)
    const { enqueueSnackbar } = useSnackbar();
    const { register, errors } = useForm()
    const form = useRef(null);

    const [selectedSiteId, setSelectedSiteId] = useState(null)
    const [quota, setQuota] = useState(null)
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(null);
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);

    const handleDateChangeFrom = key => date => {
        setStartDate(date)
    };
    const handleDateChangeTo = key => date => {
        setEndDate(date)
    };

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };
    useEffect(() => {

        if (isEditing === false) {
            setQuota(null)
            setSelectedSiteId(null)
            setEndDate(null)
            setStartDate(moment(new Date()).format("YYYY-MM-DD"))
        }

    }, [open, isEditing]);

    useEffect(() => {
        if (item) {
            setQuota(Number(item.allowed_volume))
            setSelectedSiteId(item.area_id)
            setEndDate(item.end_date)
            setStartDate(item.start_date)


        }

    }, [item]); // N’exécute l’effet que si selectedItem a changé

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(isEditing === true ? vocabulary.quota + ' ' + vocabulary.ofLabel + ' ' + item.area.name + vocabulary.updateSucc : vocabulary.quota + " " + vocabulary.addedSucc, 'success')
                refetchFunc()
                setIsLoading(false)


            } else if (r.status === 422) {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.checkUrData, 'error');
                setIsLoading(false)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
                setIsLoading(false)

            }
        }
    }
    const handleAddQuota = () => {
        setIsLoading(true)
        CreateQuota(
            {
                area_id: selectedSiteId,
                allowed_volume: Number(quota),
                start_date: startDate,
                end_date: moment(endDate).format('YYYY-MM-DD')


            }, r => operationCallBack(r)
        )
        onClose()
    }
    const handleUpdateQuota = () => {
        setIsLoading(true)

        UpdateQuota(
            {
                id: item.id,
                area_id: selectedSiteId,
                allowed_volume: Number(quota),
                start_date: startDate,
                end_date: moment(endDate).format('YYYY-MM-DD')


            }, r => operationCallBack(r)
        )
        onClose()


    }

    const actions = [
        { title: isEditing === false ? vocabulary.save : vocabulary.editLabel, color: "primary", position: 'end', onClick: () => isEditing === false ? handleAddQuota() : setOpenEditConfirmation(true) },
        { title: vocabulary.cancel, color: "secondary", position: 'start', onClick: () => modalContext.changeModalStatus(false) }
    ]
    return (
        <StyledModal
            open={open}
            onClose={onClose}
            modalContext={modalContext}
            width={600}
            title={isEditing === false ? vocabulary.addQuota : vocabulary.editQuota}
        >
            <form
                ref={form}

            >

                <StyledSelect
                    value={selectedSiteId}
                    register={register}
                    rules={{ required: true }}
                    errors={errors.Area}
                    onChange={(event) => {
                        setSelectedSiteId(event.target.value)
                    }}
                    name='Area'
                    label={vocabulary.site}
                    data={siteList}
                    styles={{ width: '100%' }}
                    valueField='id'
                    labelField='name'
                />
                <StyledInput
                    register={register}
                    rules={{ required: true }}
                    errors={errors.quota}
                    name="quota"
                    label={vocabulary.quotaLabel}
                    value={quota}
                    onChange={(event) => {
                        setQuota(event.target.value)
                    }}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 999999999 } }}

                    fullWidth

                />
                <div style={{ display: 'flex' }}>
                    <DateTimePicker
                        value={startDate}
                        onChange={handleDateChangeFrom('dateFrom')}
                        style={{ margin: '8px', width: '100%' }}
                        animateYearScrolling
                        label={vocabulary.seasonStartLabel}
                        ampm={false}
                    />

                    <DateTimePicker
                        value={endDate}
                        onChange={handleDateChangeTo('dateTo')}
                        label={vocabulary.seasonEndLabel}
                        minDate={startDate}
                        ampm={false}
                        style={{ margin: '8px', width: '100%' }}

                    />
                </div>
                <StyledModalFooter actions={actions} />

            </form>
            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={vocabulary.quota + ' ' + vocabulary.ofLabel + ' ' + item.area.name}
                    onClose={() => {
                        setOpenEditConfirmation(false);
                    }}
                    confirmUpdate={() => {
                        if (item) {
                            handleUpdateQuota();
                        }

                        setOpenEditConfirmation(false);
                    }}

                    cancel={() => {
                        setOpenEditConfirmation(false);
                    }}
                />}

        </StyledModal>

    )
}
export default AddQuotaForm
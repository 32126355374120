import React from 'react'
import MaterialTable from '@material-table/core';
import { logger } from '../../globalsTools/utilities';
import { vocabulary } from '../../App/Strings';

const StyledMaterialTable = ({styles,className, options, ...properties}) => {

    const style = {
        ...styles, 
        flexGrow: 1,
    }

    return (
        <MaterialTable 
            localization={{ 
                body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, 
                header: { actions: vocabulary.actions }, 
                pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, 
                toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}
            {...properties} 
            options={{emptyRowsWhenPaging: false, ...options}}
            style={style} 
            className={className?className:''} />
    )
}

export default StyledMaterialTable



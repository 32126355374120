import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { vocabulary } from '../../Strings';
import AlertDialog from "../../Components/alertDialog";
import CultureTypeAddForm from './CultureTypeAdd'
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import StyledLoadingRow from '../../../ui/display/StyledLoadingRow';
import { getCultureByFamilyList, DeletCultureByFamily } from '../../../globalsTools/network';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CultureTypeDetail from "./CultureTypeDetails"
// import ArboCultureBarChart from '../../Cycle/ArboCultureBarChart'

const CultureTypeList = ({ itemRow }) => {

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    // const ARBORICULTURE_FAMILY_NAME_FR = "Arboriculture"

    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [CultureList, setCultureList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (itemRow) {
            getCultureByFamilyList(itemRow.id, setCultureList)
        }

    }, [itemRow])

    const openAlertConfirmDelete = (row) => {
        setItem(row)
        setAlertConfirmDelete(true)
    };
    const closeAlertConfirmDelete = () => {

        setAlertConfirmDelete(false)
    };
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenType(!openType)
            setOpenUpdate(!openUpdate)
        } else {
            setOpenType(newStatus)
            setOpenUpdate(newStatus)
        }
    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.name)['name_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                getCultureByFamilyList(item.prm_culture_family_id, setCultureList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteCulture = (item) => {

        DeletCultureByFamily(item.id
            , r => operationCallBack(r)
        )
    }
    const onEdit = (row) => {
        setOpenUpdate(true)
        setIsEditing(true)
        setItem(row)
    }

    const openAddCultureType = () => {
        setOpenType(true)
        setIsEditing(false)
    }

    const contextValue = { open: openType, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openUpdate, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>

            {(!isLoading && CultureList) ?

                <StyledMaterialTable
                    title={vocabulary.culture}
                    data={CultureList}
                    localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}

                    columns={[
                        { title: vocabulary.name_en, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name_en}</> },
                        { title: vocabulary.name_fr, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name_fr}</> },
                        { title: vocabulary.name_ar, field: 'type', render: rowData => <>{JSON.parse(rowData.name).name_ar}</> },
                        { title: vocabulary.description, field: 'name', render: rowData => <>{rowData.description ? JSON.parse(rowData.description).description : '--'}</> },

                    ]}
                    detailPanel={[
                        {
                            tooltip: 'Show Details',
                            render: ({ rowData }) => { return <CultureTypeDetail itemRow={rowData} /> }

                        }]}

                    actions={[
                        {
                            icon: 'edit_outline',
                            tooltip: vocabulary.editCulture,
                            onClick: (event, row) => onEdit(row)
                        },
                        row => ({
                            icon: 'delete_outline',
                            tooltip: vocabulary.deleteCulture,
                            onClick: (event, row) => openAlertConfirmDelete(row),
                        }),
                        {
                            icon: () => <AddIcon fontSize='large' color='primary' />,
                            tooltip: vocabulary.addCulture,
                            isFreeAction: true,
                            onClick: (event) => openAddCultureType()
                        }

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false
                    }}
                />
                :
                <StyledLoadingRow />
            }


            {alertConfirmDelete === true &&
                <AlertDialog
                    operation={vocabulary.delete}
                    open={alertConfirmDelete}
                    deletedItemName={JSON.parse(item.name)["name_" + currentLanguage]}
                    onClose={() => {
                        closeAlertConfirmDelete();
                    }}
                    confirmeDelete={() => {
                        if (item) {
                            handleDeleteCulture(item)
                                ;
                        }
                        closeAlertConfirmDelete();
                    }}

                    cancel={() => {
                        closeAlertConfirmDelete();
                    }}
                />}

            {
                (openUpdate === true || openType === true) &&
                <CultureTypeAddForm
                    open={openUpdate}

                    onClose={() => {
                        return (
                            isEditing === true ?
                                (setOpenUpdate(false),
                                    setIsEditing(false))
                                :
                                setOpenType(false)
                        )

                    }}
                    width={800}
                    item={item}
                    isEditing={isEditing}
                    familyId={itemRow.id}
                    setIsLoading={setIsLoading}
                    refetchFunc={() => getCultureByFamilyList(item?.prm_culture_family_id, setCultureList)}


                />
            }


        </ModalContext.Provider>

    )
}
export default CultureTypeList
import React from 'react';
import Button from '@material-ui/core/Button';
import { DeleteForever, HighlightOff } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core/';
import { vocabulary } from '../Strings';

const AlertDialog = ({ onClose, cancel, confirmeDelete, operation, reopenCycle, confirmUpdate, open, deletedItemName, endCycle }) => {


  const innerClose = () => {
    onClose();
  };

  const innerCancel = () => {
    cancel()
    innerClose()
  }

  const innerConfirm = () => {
    if (operation === vocabulary.delete || operation === vocabulary.endCycle)
      confirmeDelete()
    else
      confirmUpdate()
    innerClose()
  }
  const innerConfirmReopenCycle = () => {
    if (operation === vocabulary.endCycle)
      reopenCycle()
    else
      confirmUpdate()
    innerClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={innerClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{vocabulary.warningLabel}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {vocabulary.confirmationLabel} {endCycle ? 'Reopen Cycle' : operation} {deletedItemName} ?
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={innerCancel} style={{ textTransform: 'none' }}>
            <HighlightOff />
            &nbsp;{vocabulary.cancelBtn}
          </Button>
          {endCycle ? '' :
            <Button variant="contained" type="submit" onClick={innerConfirm}
              color="secondary">
              <DeleteForever />
              &nbsp;{operation === vocabulary.endCycle ? vocabulary.end_cycle : vocabulary.delete}
            </Button>
          }


          {(operation === vocabulary.endCycle && endCycle) &&
            <Button variant="contained" onClick={innerConfirmReopenCycle} color="primary"  >
              <HighlightOff />
              &nbsp;{vocabulary.openCycle}
            </Button>}
        </DialogActions>

      </Dialog>
    </div>
  );
}


export default AlertDialog;
/* eslint-disable no-useless-escape*/

import React, { useRef, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signUpUser } from '../../actions';
import Select2 from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { ListItemText, MenuItem, InputLabel } from '@material-ui/core/'
import { vocabulary } from '../Strings';
import { useForm } from 'react-hook-form';
import StyledInput from "../../ui/forms/StyledInput";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import StyledSubmitButton from '../../ui/forms/StyledSubmitButton'
import { DatePicker } from "@material-ui/pickers";
import { StyledModal } from '../../ui/layout'
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import StyledChekbox from "../../ui/forms/StyledChekbox";
// import StyledSelect from "../../ui/forms/StyledSelect";

import { CreateFollowers, GetCountriesList, GetGovernatesList } from "../../globalsTools/network";


const AddFollowersForm = ({ onClose, isEditing, open, subscriptionList, refetchFunc }) => {

    const dispatch = useDispatch()
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const modalContext = useContext(ModalContext)

    // const countries = useSelector(state => state.countries.data)
    // const cities = useSelector(state => state.cities.data)
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [mobile, setMobile] = useState('')
    const [country, setCountry] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')

    const [selectedVilleIndex, setSelectedVilleIndex] = useState('')
    const [subscriptionType, setSubscriptionType] = useState('')
    const [step, setStep] = useState(1)
    // const [isEditing, setIsEditing] = useState(false);
    const [sms, setSms] = useState(false)
    const [api, setApi] = useState(false)
    const [application, setApplication] = useState(false)
    const [emailCheck, setEmailCheck] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [countries, setCountries] = useState(null)
    const [governateList, setGovernateList] = useState(null)


    useEffect(() => {
        if (isEditing === false) {

            setCountry('')
            setSelectedVilleIndex('')
            setSubscriptionType('')
            setStep(1)
        }

    }, [open, isEditing]);

    useEffect(() => {
        GetCountriesList(currentLanguage, setCountries)

    }, [currentLanguage])

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }



    const changeStep = (step) => {
        setStep(step)
    }
    const CreateFollower = (data) => {
        dispatch(signUpUser(
            {
                firstname: firstName,
                lastname: lastName,
                mobile: mobile,
                email: data.email,
                address: JSON.stringify({ 'address': address }),
                prm_country_id: country,
                password: "seabex.user",
                prm_governate_id: selectedVilleIndex,
                type: 'Farmer',
                isSeabexClient: true,
                status: 'Free',


            },
            function (r) {
                if (r) {
                    if (r.status === 200) {
                        CreateFollowers(
                            {
                                user_id: r.data.userid,
                                subscriptions: JSON.stringify([{
                                    channels: JSON.stringify({ 'sms': sms, 'api': api, 'email': emailCheck, 'application': application }),
                                    start_date: startDate,
                                    end_date: endDate,
                                    subscription_id: subscriptionType
                                }]),

                            }
                        ).then(() => refetchFunc());
                        onClose()

                    }
                }

            }
        ),
            onClose())
    }

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                modalContext={modalContext}
                title={vocabulary.newFollower}
                width={600}

            >
                <form
                    onSubmit={handleSubmit(CreateFollower)}
                    ref={form}

                >
                    {
                        step === 1 &&
                        <>
                            <StyledInput
                                register={register}
                                rules={{ required: true }}
                                name="firstName"
                                errors={errors.firstName}
                                label={vocabulary.firstname}
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value)

                                }}
                                type="text"
                                fullWidth


                            />
                            <StyledInput

                                register={register}
                                rules={{ required: true }}
                                name="lastname"
                                label={vocabulary.lastname}
                                onChange={(event) => {
                                    setLastName(event.target.value)

                                }}
                                value={lastName}
                                type="text"
                                fullWidth
                                errors={errors.lastname}


                            />
                            <PhoneInput
                                country="TN"
                                displayInitialValueAsLocalNumber
                                placeholder={vocabulary.mobile}
                                value={mobile}

                                onChange={(event) => {
                                    setMobile(event)
                                }}
                                error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid phone number') : 'Phone number required'} />

                            <StyledInput
                                register={register}
                                rules={{ required: true, validate: validateEmail }}
                                name="email"
                                label={vocabulary.email}
                                type="text"
                                fullWidth
                                errors={errors.email}

                            />
                            {errors.email && <span>Email Invalide</span>}
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                <StyledAutoComplete
                                    stye={{ width: 80 }}
                                    data={countries ? countries : []}
                                    optionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setCountry(newValue.id)
                                        GetGovernatesList(currentLanguage, newValue.identifier, setGovernateList)
                                    }}
                                    disabled={countries == null ? true : false}

                                    label={vocabulary.country}

                                />
                                <StyledAutoComplete
                                    stye={{ width: 80 }}
                                    data={governateList && governateList.length > 0 ? governateList : []}

                                    optionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setSelectedVilleIndex(newValue.value)

                                    }}
                                    label={vocabulary.region}
                                    disabled={governateList == null ? true : false}


                                />

                            </div>

                            <div style={{ width: '100%', marginTop: '18px' }}>
                                <StyledInput
                                    errors={errors.address}
                                    register={register}
                                    rules={{ required: true }}
                                    style={{ marginTop: "-7px" }}
                                    name="address"
                                    value={address}
                                    onChange={(event) => {
                                        setAddress(event.target.value)

                                    }}
                                    label={vocabulary.address}

                                    type="text"
                                    fullWidth

                                />
                            </div>
                        </>
                    }

                    {
                        step === 2 &&
                        <>
                            {subscriptionList &&
                                <div required
                                    variant="outlined"
                                    style={{ margin: '8px', width: '100%' }}
                                >
                                    <InputLabel id="demo-simple-select-outlined-label">{vocabulary.SubscriptionType}</InputLabel>

                                    <Select2
                                        value={subscriptionType}
                                        onChange={(event) => {
                                            setSubscriptionType(event.target.value)

                                            const checkId = (item) => item.id === event.target.value;
                                            let index = subscriptionList.data.findIndex(checkId)

                                            setEmailCheck(JSON.parse(subscriptionList.data[index].channels).email)
                                            setApi(JSON.parse(subscriptionList.data[index].channels).api)
                                            setApplication(JSON.parse(subscriptionList.data[index].channels).application)
                                            setSms(JSON.parse(subscriptionList.data[index].channels).sms)


                                        }}

                                        label={vocabulary.SubscriptionType}
                                    >
                                        {
                                            subscriptionList.data.map((item, index) => (

                                                <MenuItem key={index} value={item.id}>
                                                    <ListItemText primary={JSON.parse(item.title).name} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select2>
                                </div>}

                            {subscriptionType &&
                                <>
                                    <div row style={{ display: 'flex', justifyContent: 'space-around' }} >
                                        {sms === true &&
                                            <StyledChekbox
                                                checked={sms}
                                                onChange={(event) => {
                                                    setSms(event.target.checked)
                                                }}
                                                label={vocabulary.sms}
                                            />

                                        }
                                        {emailCheck === true &&
                                            <StyledChekbox
                                                checked={emailCheck}
                                                onChange={(event) => {
                                                    setEmailCheck(event.target.checked)
                                                }}
                                                label={vocabulary.email}
                                            />
                                        }


                                    </div>

                                    <br />

                                    <div row style={{ display: 'flex', justifyContent: 'space-around' }} >

                                        {api === true &&
                                            <StyledChekbox
                                                checked={api}
                                                onChange={(event) => {
                                                    setApi(event.target.checked)
                                                }}
                                                label={'api'}
                                            />

                                        }
                                        {application === true &&
                                            <StyledChekbox
                                                checked={application}
                                                onChange={(event) => {
                                                    setApplication(event.target.checked)
                                                }}
                                                label={vocabulary.application}
                                            />

                                        }


                                    </div>
                                </>
                            }
                            <DatePicker
                                label={"startDate"}
                                value={startDate}
                                onChange={setStartDate}
                                animateYearScrolling
                                style={{ margin: '8px', width: '100%' }}
                            />
                            <DatePicker
                                label={"EndDate"}
                                value={endDate}
                                onChange={setEndDate}
                                animateYearScrolling
                                style={{ margin: '8px', width: '100%' }}
                            />

                        </>
                    }


                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button
                            onClick={onClose}
                            color="secondary"
                            type="reset"
                            variant="contained"
                        >
                            {vocabulary.cancel}
                        </Button>
                        {step === 1 &&
                            <Button
                                color="primary"
                                onClick={() => changeStep(2)}
                                variant="contained"
                            >
                                {vocabulary.next}

                            </Button>
                        }
                        {step === 2 &&
                            <>
                                <Button
                                    color="inherit"
                                    onClick={() => changeStep(1)}
                                    variant="contained"
                                >
                                    {vocabulary.previous}

                                </Button>
                                <StyledSubmitButton label={vocabulary.submit} />
                            </>
                        }

                    </div>
                </form>
            </StyledModal>
        </>
    )
}

export default AddFollowersForm
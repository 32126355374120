/* eslint-disable  no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars*/

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, updateCycleThenLoadCycle, getAllCultures } from '../../actions';
import { vocabulary } from '../Strings';
import CycleForm from './CycleAddUpdateForm';
import "./style.css";

import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StopIcon from '@material-ui/icons/Stop';
import StartIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import { StyledMaterialTable } from '../../ui/display';
import { Button, Chip, Grid, Card, CardContent, Typography, CardActions } from '@material-ui/core';
import { DeleteCycle, GetCycleList, GetAreasList, EndCycle } from "../../globalsTools/network";
import CycleDetails from './CycleDetails'
import AlertDialog from "../Components/alertDialog";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { GuideContext, steps } from '../../ui/layout/contextLayout/GuideContext';
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from '../globalConsts';
// import { useHistory } from 'react-router-dom';
import CycleStepper from './CycleStepper';
import CurrentPage from '../Components/CurrentPage';
import { Refresh, WatchLater, ErrorOutline } from '@material-ui/icons';
import { logger } from '../../globalsTools/utilities';
import SeabexOffers from '../Offers/SeabexOffers';
import CycleInformationCard from './CycleInformationCard';
import { StyledCard } from '../../ui/layout';
import EndCycleDialog from './CycleComponents/EndCycleDialog';
// import CycleTimeLineCalendar from './CycleTimeLineCalendar'
// import { Tabs, Tab, Paper } from '@material-ui/core'


const Cycle = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { guide, dispatchGuide } = useContext(GuideContext)
    // const history = useHistory()
    const ARBORICULTURE_FAMILY_ID = 'a67fc3d1-cf5c-46a2-b405-8ac0d0eeb1c8000'
    const ARBORICULTURE_FAMILY_NAME_FR = "Arboriculture"

    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const canCreateProductionCycle = useSelector(state => state._globalState.new_production_cycles_right)
    const cyclesMaxNumber = useSelector(state => state._globalState.active_services_production_cycles)
    const numberOfActivesProductionCycles = useSelector(state => state._globalState.production_cycles_number)
    const userStatsLoading = useSelector(state => state._globalState.stats_loading)
     // const tableRef = React.createRef();

    const [cycleList, setCycleList] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [item, setItem] = useState(null)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [alertConfirmCycle, setAlertConfirmCycle] = useState(false);
    // const [sitesList, setSitesList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [showOffers, setShowOffers] = useState(false)
    const [tab, setTab] = useState(0)

    const [openForm, setOpenForm] = useState(false)


    const moment = require('moment');

    useEffect(() => {
      logger("CycleList isEditing, openAdd, openEdit, result", isEditing, openAdd, openEdit, isEditing === false ? openAdd : openEdit)
      setOpenForm(isEditing === false ? openAdd : openEdit)
    }, [isEditing, openAdd, openEdit])
    

    useEffect(() => {
        if (cycleList !== null && guide.currentStep === "cycles" && (steps[guide.initialStep].element === '.introCycle' || steps[guide.initialStep].element === ".cycleList")) {
            setTimeout(() => {
                dispatchGuide({ type: IS_ENABLED, payload: true })
            }, 1000);
        }
    }, [cycleList])

    useEffect(() => {
        if (guide.currentStep === "cycles" && steps[guide.initialStep].element === ".introCycle" && guide.isEnabled === false) {
            setTimeout(() => {
                dispatchGuide({ type: IS_ENABLED, payload: true })
            }, 1000);
        }
    }, [openAdd])

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.cycles))
        document.title = vocabulary.cycles
        dispatch(getAllCultures())
        GetCycleList(setCycleList)
        // GetAreasList(setSitesList)

    }, [dispatch]); // N’exécute l’effet que a la première render

    useEffect(() => {
        dispatch(setCurrentPage(<CurrentPage name={vocabulary.cycles} helpFn={helpFn} />))
        document.title = vocabulary.cycles

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    /** DATE DIFF WITH TODAY IN NUMBER OF DAYS */
    const dateDiff = (startDate, end_date) => {
        if (end_date == null) {
            end_date = moment(new Date())
        }
        let diff = end_date.diff(startDate, 'days')
        return diff
    }

    /** SECTION CONTEXT */

    // const [openAdd, setOpenAdd] = useState(false)
    // const [openEdit, setOpenEdit] = useState(false)

    const openAlertConfirmDelete = useCallback((_event, row) => {
                                        setAlertConfirmDelete(true)
                                        setItem(row)
                                    }, []);

    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };

    const openAlertConfirmCycle = (row) => {
        setAlertConfirmCycle(true);
        setItem(row)
    };
    const closeAlertConfirmCycle = () => {
        setAlertConfirmCycle(false);
    };

    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)
        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const onEdit = useCallback((_event, row) => {
                        setOpenEdit(true)
                        setIsEditing(true)
                        setItem(row)
                    }, [])
    const openCycleAdd = useCallback(() => {
                        setOpenAdd(true)
                        setIsEditing(false)
                    }, [])

    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                snackbar(JSON.parse(item.prm_culture.name)["name_" + currentLanguage] + ' ' + vocabulary.cycleLabel + ' ' + vocabulary.deleteMsg, 'success')
                GetCycleList(setCycleList)


            } else if (r.status === 201) {  // in case cycle ended by the user
                snackbar(vocabulary.cycleLabel + ' ' + vocabulary.cycleEndedOn + ' ' + moment(new Date()).format('YYYY-MM-DD'), 'info')
                GetCycleList(setCycleList)
            }
            else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }

    const handleDeleteCycle = (item) => {
        dispatchGuide({ type: INITIAL_STEP, payload: 0 });
        dispatchGuide({ type: CURRENT_STEP, payload: "" });
        DeleteCycle(item.id
            , r => operationCallBack(r)
        )
    }

    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    const helpFn = () => {
        dispatchGuide({ type: INITIAL_STEP, payload: steps.findIndex(step => step.element === ".introCycle") })
        dispatchGuide({ type: CURRENT_STEP, payload: 'cycles' })
        setTimeout(() => {
            dispatchGuide({ type: IS_ENABLED, payload: true })
        }, 500);

    }

    const refreshFn = useCallback(() => {
        GetCycleList(setCycleList)
    }, [])

    const mtDetailPanelFn = useCallback(({ rowData }) => {
                // return (<CycleDetails
                //     item={rowData} 
                //     cultureParentName={rowData.prm_culture && rowData.prm_culture.prm_culture_family && JSON.parse(rowData.prm_culture.prm_culture_family.name).name_fr}
                //     />)
                return (
                    <StyledCard direction='column' additionalStyle={{padding:30, backgroundColor:'#dbd8e7'}}>
                        <CycleInformationCard production_cycle_id={rowData.id}/>
                    </StyledCard>)
                }, [])
    const mtRenderAreaFn        = useCallback((rowData) => <>{rowData.area.parent ? rowData.area.parent.name + ' / ' + rowData.area.name : rowData.area.name}</>, [])
    const mtRenderCultureNameFn = useCallback((rowData) => <>{rowData.prm_culture ? JSON.parse(rowData.prm_culture.name)["name_" + currentLanguage] : '--'}</>, [])
    const mtRenderStartCateFn   = useCallback((rowData) => <>{rowData.start_date ? currentLanguage == "en" ? moment(rowData.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment(rowData.start_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}</>, [])
    const mtRenderEndCycleFn    = useCallback((rowData) => <>{rowData.end_date
                ? <Grid container direction='row' wrap='nowrap' alignContent='space-between' justifyContent='space-between' alignItems='center'>
                    <Grid item>{vocabulary.endOnLabel + rowData.end_date}</Grid>
                    <Grid item>{getDays(rowData)}</Grid>
                </Grid>
                :
                <Grid container direction='row' wrap='nowrap' alignContent='space-between' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        {"Encours - " + getDays(rowData) + " "}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={rowData.end_date === null ? <StopIcon /> : <StartIcon />}
                            onClick={() => { openAlertConfirmCycle(rowData) }}
                        >
                            {vocabulary.finishCycle}
                        </Button>
                    </Grid>
                </Grid>

            }</>, [])
    const mtRenderPhaseFn = useCallback((rowData) => 
                            <>
                                {
                                    Number(rowData.active_calculation)==0 
                                    ? rowData && rowData.daily_water_balance && rowData.daily_water_balance.phase_name 
                                    : <Chip icon={<WatchLater />} label={vocabulary.cycleInCalculation} />}</>, [])
    const mtStyleFn = useCallback((rowData) => {
            if (Number(rowData.active_calculation)==1) 
            {
                return { backgroundColor: "#C8E6C9" }
            }

            if (rowData.end_date) 
            {
                return { backgroundColor: '#EEE' }
            }

            if (rowData.daily_water_balance)
            {
                if (!rowData.daily_water_balance.phase_name)
                {
                    return { backgroundColor: '#F9FBE7' }
                }
                if (Number(rowData.daily_water_balance.soil_water_volume) < Number(rowData.daily_water_balance.today_unconstrained_need))
                {
                    return { backgroundColor: '#fff3ef', borderInlineStart: `solid 5px #770000` }
                }
            }

            return { backgroundColor: '#FFF' }
    }, [])


    const getDays = (rowData) => {
        if (rowData.start_date) {
            if (rowData.end_date) {
                return dateDiff(moment(rowData.start_date, 'YYYY-MM-DD'), moment(rowData.end_date, 'YYYY-MM-DD')) + " " + vocabulary.dayLabel
            } else {
                return dateDiff(moment(rowData.start_date, 'YYYY-MM-DD')) + " " + vocabulary.dayLabel
            }
        } else {
            return '-'
        }
    }

    return (
        <>

            {userStatsLoading!==true && !canCreateProductionCycle && 
            // { 
            (<>
                <Card elevation={2} style={{backgroundColor:'#f1e59c'}}>
                    <CardContent>
                        <Grid container direction='row' style={{justifyContent: 'space-between', alignItems: 'center', gap: 10, flexWrap:'nowrap'}}>
                            <Grid item><ErrorOutline /></Grid>
                            <Grid item style={{flexGrow:1}}>
                                Attention ! Vous ne pouvez plus créer de nouveaux cycles de production dans le cadre de votre abonnement, vous pouvez acheter un nouveau pack en cliquant sur le bouton ci-dessous
                            </Grid>
                            <Grid item>
                                <Button onClick={() => setShowOffers(true)}>
                                    S'abonner
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                { showOffers  && <SeabexOffers open={showOffers} withoutContext={true} closeFn={()=>setShowOffers(false) }/>}
            </>)

            }
            {guide.isEnabled && guide.currentStep === 'cycles' && <CycleStepper setOpenAdd={setOpenAdd} />}
            <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>
                <>
                    {(!isLoading && cycleList) ?
                        <>
                            {tab == 0 &&
                                <StyledMaterialTable
                                    title={`${vocabulary.cycles} (${!userStatsLoading ? numberOfActivesProductionCycles+" Cycles actifs / " + cyclesMaxNumber  : vocabulary.loading})`}
                                    data={cycleList}
                                    localization={{ 
                                        body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, 
                                        header: { actions: vocabulary.actions }, 
                                        pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, 
                                        toolbar: { searchPlaceholder: vocabulary.search, searchTooltip: vocabulary.search } }}
                                    detailPanel={[
                                        {
                                            tooltip: vocabulary.viewDetailsLabel,
                                            render: mtDetailPanelFn
                                    }]}
                                    columns={[
                                        { title: vocabulary.area, 
                                            field: 'area', 
                                            render: mtRenderAreaFn
                                        },

                                        { title: vocabulary.Culture_Name, 
                                            field: 'prm_culture.name', 
                                            render: mtRenderCultureNameFn,
                                        },
                                        { 
                                            title: vocabulary.startDate, 
                                            field: 'start_date', 
                                            render: mtRenderStartCateFn
                                        },

                                        { title: vocabulary.end_cycle, 
                                            field: 'end_date', 
                                            render: mtRenderEndCycleFn, 
                                            cellStyle: { minWidth: 300 }
                                        },
                                        {
                                            title: vocabulary.phase, 
                                            field: 'phase', 
                                            render: mtRenderPhaseFn
                                        },
                                    ]}

                                    actions={[
                                        {
                                            icon: 'edit_outline',
                                            tooltip: vocabulary.editCycle,
                                            onClick: onEdit

                                        },
                                        rowData => ({
                                            icon: 'delete_outline',
                                            tooltip: vocabulary.delete,
                                            onClick: openAlertConfirmDelete,
                                            disabled: rowData.id < 2000
                                        }),
                                        {
                                            icon: () => <AddIcon fontSize='large' style={{color: canCreateProductionCycle ? 'primary' : 'grey'}} className="addCycleBtn" />,
                                            tooltip: vocabulary.addCycle,
                                            isFreeAction: true,
                                            onClick: openCycleAdd,
                                            disabled: !canCreateProductionCycle
                                        },
                                        {
                                            icon: () => <Refresh fontSize='large' color='primary' className="addCycleBtn" />,
                                            tooltip: vocabulary.refreshData,
                                            isFreeAction: true,
                                            onClick: refreshFn
                                        },

                                    ]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        pageSize: 40,
                                        pageSizeOptions: [5, 10, 20, 40, 60, 80],
                                        rowStyle: mtStyleFn
                                    }}
                                />
                            }
                        </>
                        : <StyledCircularProgress size={50} label={isLoading ? vocabulary.addCycleLoadingMsg : vocabulary.loading} />
                    }
                    {
                        <CycleForm
                            open={openForm}
                            onClose={() => {
                                return (
                                    isEditing === true ?
                                        (setOpenEdit(false),
                                            setIsEditing(false))
                                        : setOpenAdd(false)
                                )
                            }}
                            item={isEditing === true ? item : null}
                            isEditing={isEditing}
                            refetchFunc={() => GetCycleList(setCycleList)}
                            setIsLoading={setIsLoading}

                        />}
                    {alertConfirmDelete === true &&
                        <AlertDialog
                            operation={vocabulary.delete}
                            open={alertConfirmDelete}
                            deletedItemName={item.prm_culture ? JSON.parse(item.prm_culture.name)["name_" + currentLanguage]: ' '}
                            onClose={() => {
                                closeAlertConfirmDelete();
                            }}
                            confirmeDelete={() => {
                                if (item) {
                                    handleDeleteCycle(item);
                                }
                                closeAlertConfirmDelete();
                            }}

                            cancel={() => {
                                closeAlertConfirmDelete();
                            }}
                        />}

                    {alertConfirmCycle === true &&
                        // <AlertDialog
                        //     operation={vocabulary.endCycle}
                        //     open={alertConfirmCycle}
                        //     deletedItemName={item.prm_culture ? JSON.parse(item.prm_culture.name)["name_" + currentLanguage] : ' '}
                        //     onClose={() => {
                        //         closeAlertConfirmCycle();
                        //     }}
                        //     endCycle={item.end_date && item.end_date}

                        //     confirmeDelete={() => {

                        //         if (item) {
                        //             EndCycle(item.id, true
                        //                 , r => operationCallBack(r));
                        //         }
                        //         closeAlertConfirmCycle();
                        //     }}

                        //     reopenCycle={() => {
                        //         if (item) {
                        //             dispatch(updateCycleThenLoadCycle(item.id, false
                        //             ));
                        //         }

                        //         closeAlertConfirmCycle();
                        //     }}

                        //     cancel={() => {
                        //         closeAlertConfirmCycle();
                        //     }}
                        // />
                        <EndCycleDialog productionCycle={item} onSuccess={() => {GetCycleList(setCycleList); closeAlertConfirmCycle()}} onCancel={closeAlertConfirmCycle} />
                        }

                </>
            </ModalContext.Provider>
        </>
    )

}

export default Cycle;


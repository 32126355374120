/* eslint-disable no-unreachable*/
/* eslint-disable eqeqeq*/

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { vocabulary } from '../Strings';
import PartnerIntegrationForm from './PartnerIntegrationForm';
import { StyledMaterialTable } from '../../ui/display';
import { setCurrentPage } from '../../actions';
import { DeletePartnerDevice, GetPartnerIntegration } from "../../globalsTools/network";
import AlertDialog from "../Components/alertDialog";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { AddShoppingCart, Refresh, Settings } from '@material-ui/icons';
import WeatherPartnerListDetails from './WeatherPartnerListDetails';
import { useParams } from 'react-router-dom';
import StationOfferModal from './StationOfferModal';
import PartnerActivateModal from './partners/modals/PartnerActivateModal';
import { logger } from '../../globalsTools/utilities';
import { Button, Typography } from '@material-ui/core';

const WeatherPartnerConfig = () => {


    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const activeSencropStations = useSelector(
        (state) => state._globalState.active_services_sencrop_weather_stations
    );

    const maxSencropStations = useSelector(
        (state) => state._globalState.sencrop_weather_stations_number
    );

    const canCreateSencropStation = useSelector(
        (state) => state._globalState.canCreateSencropStation
    );

    const userStatsLoading = useSelector(state => state._globalState.stats_loading)

    const { enqueueSnackbar } = useSnackbar();


    const [item, setItem] = useState(null)
    const [openAdd, setOpenAdd] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [partnerData, setPartnerData] = useState(null)
    const [partnerDevices, setPartnerDevices] = useState(null)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [canCreate, setCanCreate] = useState(false)
    const [openConfigureModal, setOpenConfigureModal] = useState(false)
    const [rerender, setRerender] = useState(Math.random()+1)
    let { id } = useParams();



    const handleClickOpenEdit = (row) => {
        setOpenAdd(true)
        setItem(row)
        setIsEditing(true)
    };

    const handlePartnerDataSaving = (data) => {
        setPartnerData(data)
        if (data && data.devices) {
            setPartnerDevices([...data.devices])
        } else {
            setPartnerDevices([])
        }
    }

    const refreshFn = useCallback(() => {
        GetPartnerIntegration(id, handlePartnerDataSaving)
    }, [])

    useEffect(() => {

        dispatch(setCurrentPage(vocabulary.partnerIntegrations));
        document.title = vocabulary.partnerIntegrations

        refreshFn()

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };


    const operationCallBack = (r) => {
        if (r && r.status === 204) {

            snackbar(item.device_id + " " + vocabulary.deleteMsg, 'success')
            refreshFn()
        } else {
            snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
        }
    }

    const handleDeleteIntegration = (item) => {
        DeletePartnerDevice( item.id, r => operationCallBack(r) )
    }

    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };


    const openOffersModel = useCallback(() => {
        setOpen(true)
    }, [])

    const onClose = () => {
        setOpen(false);
    };



    const handleAddClick = useCallback(() => {
        if (canCreate) {
            setIsEditing(false)
            setOpenAdd(true)
        } else {
            openOffersModel();
        }
    }, [canCreate])

    const getIconFn = useCallback(() => {
            return (<AddIcon
                    fontSize='large'
                    color={canCreate ? 'primary' : 'secondary'}
                />)
    }, [canCreate, rerender])
    

    const updateSetCanCreate = () => {
        setCanCreate(!canCreate)
        handlePartnerDataSaving({...partnerData})
        setRerender(Math.random()+1)
    }
    

    useEffect(() => {
        if (id === 'SENCROP') {
            setCanCreate(canCreateSencropStation)
        } else {
            setCanCreate(true)
        }
        setRerender(Math.random()+1)
    }, [canCreateSencropStation])




    const title = `${vocabulary.partnerIntegrations} (${!userStatsLoading ? activeSencropStations + " Stations actives / " + maxSencropStations : vocabulary.loading})`
    return (

        <>
            {
                (!isLoading && partnerData && partnerDevices && !userStatsLoading ) ?
                    <>
                        <StyledMaterialTable
                            key={rerender}
                            title={id === 'SENCROP' ? title : vocabulary.partnerIntegrations}
                            data={partnerDevices}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}

                            detailPanel={[
                                {
                                    tooltip: 'Show Details',
                                    render: ({ rowData }) => { return (<WeatherPartnerListDetails itemRow={rowData} refreshFn={refreshFn} />) }
                                }]}

                            columns={[
                                { title: vocabulary.deviceID, field: 'device_id' },
                                { title: vocabulary.name_label, field: 'device_name' },
                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => handleClickOpenEdit(row),
                    
                                },
                                {
                                    icon: getIconFn,
                                    // tooltip: vocabulary.addStation,
                                    tooltip: canCreate ? "Configurer une station" : "Acheter de nouvelles stations",
                    
                                    isFreeAction: true,
                                    onClick: () => handleAddClick(),
                                },
                                {
                                    icon: () => <AddShoppingCart
                                        fontSize='large'
                                        color={'primary'}
                                    />,
                    
                                    tooltip: vocabulary.addStation,
                                    isFreeAction: true,
                                    onClick: () => openOffersModel(),
                                    hidden: id != 'SENCROP'
                                },
                                {
                                    icon: () => <Settings
                                        fontSize='large'
                                        color={'primary'}
                                    />,
                                    tooltip: vocabulary.addStation,
                                    isFreeAction: true,
                                    onClick: () => setOpenConfigureModal(true),
                                },
                                {
                                    icon: () => <Refresh fontSize='large' color='primary' className="addCycleBtn" />,
                                    tooltip: vocabulary.refreshData,
                                    isFreeAction: true,
                                    onClick: () => refreshFn()
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: true,
                                pageSize: 40,
                                pageSizeOptions: [10, 40, 100]

                            }}
                        />
                    


                        {
                            partnerData && openConfigureModal && (
                                <PartnerActivateModal
                                    currentItem={partnerData}
                                    open={openConfigureModal}
                                    setOpen={setOpenConfigureModal}
                                />
                            )
                        }


                        <div>

                            {openAdd && <PartnerIntegrationForm
                                open={true}
                                onClose={() => setOpenAdd(false)}
                                partnerId={id}
                                currentItem={item}
                                refetchFunc={refreshFn}
                                isEditing={isEditing}
                                setIsLoading={setIsLoading}
                            />}

                            {alertConfirmDelete === true &&
                                <AlertDialog
                                    operation={vocabulary.delete}
                                    open={alertConfirmDelete}
                                    deletedItemName={item.title}
                                    onClose={() => {
                                        closeAlertConfirmDelete();
                                    }}
                                    confirmeDelete={() => {
                                        if (item) {
                                            handleDeleteIntegration(item);
                                        }

                                        closeAlertConfirmDelete();
                                    }}

                                    cancel={() => {
                                        closeAlertConfirmDelete();
                                    }}
                                />}

                        </div>
                    </>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />

            }

            { open && <StationOfferModal
                isOpen={true}
                onClose={onClose}
                partnerId={id}
                refreshFn={refreshFn}
            />}
        </>

    );

}
export default WeatherPartnerConfig;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledCard } from "../../../ui/layout";
import StyledMaterialTable from '../../../ui/display/StyledMaterialTable';
import { vocabulary } from '../../Strings';
import { setCurrentPage } from '../../../actions';
import { GetCountriesList, GetPublicationsList, DeletePublication } from '../../../globalsTools/network'
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../../ui/display/StyledCircularProgress';
import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AlertDialog from "../../Components/alertDialog";
import PublicationAddForm from './PublicationAddForm'
import Link from '@material-ui/core/Link';

const PublicationList = () => {

    const moment = require('moment');

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [isEditing, setIsEditing] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [item, setItem] = useState(null)
    const [countriesList, setCountriesList] = useState([])
    const [publicationList, setPublicationList] = useState(null)


    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.publicationManagment))
        document.title = vocabulary.publicationManagment
        GetPublicationsList(setPublicationList)
    }, [dispatch]); // N’exécute l’effet que si count a changé

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.publicationManagment))
        document.title = vocabulary.publicationManagment
        GetCountriesList(currentLanguage, setCountriesList)
    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé


    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)

    };
    const operationCallBack = (r) => {

        if (r) {
            if (r.status === 204) {
                snackbar(JSON.parse(item.title)['title_' + currentLanguage] + ' ' + vocabulary.deleteMsg, 'success')
                GetPublicationsList(setPublicationList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const closeAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(false)

    };
    const handleDeletePublication = (item) => {
         DeletePublication(item.id
            , r => operationCallBack(r)
        )
    }
    const openAddPublicationForm = () => {
        setOpen(true)
        setIsEditing(false)
    }
    const onEdit = (row) => {
        setOpenEdit(true)
        setIsEditing(true)
        setItem(row)
    }
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpen(!open)
            setOpenEdit(!openEdit)
        } else {
            setOpen(newStatus)
            setOpenEdit(newStatus)
        }
    }

    const contextValue = { open: open, changeModalStatus: changeModalStatus }
    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }

    return (
        <ModalContext.Provider value={isEditing === false ? contextValue : contextValueEdit}>
            <StyledCard>

                {
                    (!isLoading && publicationList && publicationList.data.length > 0) ?
                        <StyledMaterialTable
                            title={vocabulary.publicationsLabel}
                            data={publicationList.data}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage }, toolbar: { searchPlaceholder: vocabulary.search } }}
                            columns={[
                                { title: vocabulary.type, field: 'type', render: rowData => <>{rowData.type ? rowData.type : '--'}</> },
                                { title: vocabulary.title, field: 'title', render: rowData => <>{rowData.title ? JSON.parse(rowData.title)['title_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.subTitle, field: 'subTitle', render: rowData => <>{rowData.subTitle ? JSON.parse(rowData.subTitle)['subtitle_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.date, field: 'date', render: rowData => <>{rowData.date ? moment(rowData.date).format('YYYY-MM-DD') : '--'}</> }, 
                                { title: vocabulary.description, field: 'description', render: rowData => <>{rowData.description ? JSON.parse(rowData.description)['description_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.AuthorLabel, field: 'author', render: rowData => <>{rowData.author ? JSON.parse(rowData.author)['author_' + currentLanguage] : '--'}</> },
                                { title: vocabulary.country, field: 'author', render: rowData => <>{rowData.country ? JSON.parse(rowData.country.name)['name_' + currentLanguage] : vocabulary.worldWideLabel}</> },
                                {
                                    title: 'Link', field: 'Link', render: rowData => <>{rowData.link ?
                                        <div>
                                            <Link className="vertical-timeline-element-link" href={JSON.parse(rowData.link).link} target="_blank">
                                                {JSON.parse(rowData.link)['LinkTitle_' + currentLanguage]}

                                            </Link>
                                        </div>

                                        : '--'}</>
                                },


                                { title: 'image', field: 'image', render: rowData => <>{rowData.image ? <img style={{ height: '150px', width: '200px', borderRadius: '10px' }} alt={JSON.parse(rowData.image).legend} src={JSON.parse(rowData.image).src} /> : '--'}</> },

                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => onEdit(row)
                                },
                                row => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),

                                    disabled: row.id < 2000
                                }),
                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => openAddPublicationForm()
                                }

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                pagging: false
                            }}
                        />
                        : <StyledCircularProgress  timeout={true} noDataMsg={vocabulary.noRecordToDisplay} size={50} label={vocabulary.loading}  /> 
                }

                {
                    alertConfirmDelete === true &&
                    <AlertDialog
                        operation={vocabulary.delete}
                        open={alertConfirmDelete}
                        deletedItemName={JSON.parse(item.title)['title_' + currentLanguage]}
                        onClose={() => {
                            closeAlertConfirmDelete();
                        }}
                        confirmeDelete={() => {
                            if (item) {
                                handleDeletePublication(item);
                            }
                            closeAlertConfirmDelete();
                        }}
                        cancel={() => {
                            closeAlertConfirmDelete();
                        }}
                    />
                }

                <PublicationAddForm
                    open={isEditing === false ? open : openEdit}
                    onClose={() => {
                        return (
                            isEditing === false ?
                                (setOpen(false),
                                    setIsEditing(false))
                                :
                                setOpenEdit(false)
                        )

                    }}
                    item={isEditing === true ? item : null}
                    width={600}
                    itemId={item && item.id}
                    isEditing={isEditing}
                    countriesList={countriesList && countriesList}
                    refetchFunc={() => GetPublicationsList(setPublicationList)}
                    setIsLoading={setIsLoading}

                />
            </StyledCard>

        </ModalContext.Provider>
    )
}
export default PublicationList
import React, { useEffect, useState } from 'react';
import { StyledModal } from '../../ui/layout';
import AreaSelect from '../Sites/Components/AreaSelect';
import { vocabulary } from '../Strings';
import { StyledStaticMap } from '../../ui/display';
import { GetAreaDetails, SyncPartnerAreaBoundries } from '../../globalsTools/network';
import { Grid } from '@material-ui/core';
import { Button } from "@material-ui/core";

import { Done } from '@material-ui/icons';
import AlertEditDialogConfirmation from "../Components/AlertEditDialogConfirmation";
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';

const SyncAreaModal = ({ isOpen, onClose, refetchFunc, currentItem }) => {

    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [area, setArea] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [openEditConfirmation, setOpenEditConfirmation] = useState(false);


    useEffect(() => {
        if (selectedAreaId) {
            setIsLoading(true)
            GetAreaDetails(selectedAreaId, data => {
                setArea(data)
                setIsLoading(false)
            })
        }


    }, [selectedAreaId])


    const openConfirmModel = () => {
        setOpenEditConfirmation(true)
    }

    const closeConfirmModel = () => {
        setOpenEditConfirmation(false)
    }
    const handleUpdateBounries = () => {
        
        setIsLoading(true)
        const { staticmap, ...partnerArea } = currentItem;
 
        SyncPartnerAreaBoundries(selectedAreaId, {
            size: partnerArea.boundary.properties.area.q / 0.0001,
            boundary: JSON.stringify(partnerArea.boundary),
            center: JSON.stringify(partnerArea.center),
            ...partnerArea
        }, operationCallBack);

    }


    const operationCallBack = (response) => {

        if (response && [200, 204].includes(response.status)) {

            GetAreaDetails(selectedAreaId, setArea)
            refetchFunc()
            snackbar(vocabulary.operationSucc, 'success')

        }

        else {
            snackbar(vocabulary.errorOccurred, 'error')

        }

        setIsLoading(false)

    }



    return (
        <>
            <StyledModal
                open={isOpen}
                closeFn={onClose}
                withoutContext={true}
                title={vocabulary.partnerIntegrations}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AreaSelect onChangeFn={setSelectedAreaId} multipleSelect={false} title={vocabulary.area} />
                    </Grid>


                    <Grid item xs={6}>
                        {
                            area && (
                                !isLoading
                                    ? <StyledStaticMap image={area.staticmap} base64={true} height={'100%'} />
                                    : <StyledCircularProgress size={20} height={10} />
                            )

                        }
                    </Grid>

                    <Grid item xs={6}>
                        {
                            currentItem && area && (
                                <StyledStaticMap image={currentItem.staticmap} base64={true} height={'100%'} />
                            )
                        }
                    </Grid>

                    {
                        area && (
                            <Grid item xs={12}>
                                <Button
                                    className='saveBtnAddArea'
                                    style={{ margin: '5px', fontSize: '15px' }}
                                    color='primary'
                                    disabled={isLoading}
                                    variant='contained'
                                    onClick={openConfirmModel}
                                >
                                    <Done /> &nbsp; {vocabulary.save}
                                </Button>
                            </Grid>
                        )
                    }

                </Grid>
            </StyledModal>


            {openEditConfirmation === true &&
                <AlertEditDialogConfirmation
                    operation={vocabulary.editLabel}
                    open={openEditConfirmation}
                    EditItemName={area?.name}
                    onClose={closeConfirmModel}
                    confirmUpdate={handleUpdateBounries}
                    cancel={closeConfirmModel}
                />}
        </>

    );
};

export default SyncAreaModal;

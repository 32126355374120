import { logger } from "./utilities";


const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

export const addDurationToCron = (cronExpression, duration) => {


    let cronArray = cronExpression.split(" ");
    let currentMinute = parseInt(cronArray[0]);
    let currentHour = isNumber(cronArray[1]) ? parseInt(cronArray[1]) : cronArray[1];
    let currentDay = isNumber(cronArray[2]) ? parseInt(cronArray[2]) : cronArray[2];
    let currentMonth = isNumber(cronArray[3]) ? parseInt(cronArray[3]) : cronArray[3];
    let currentDayOfWeek = isNumber(cronArray[4]) ? parseInt(cronArray[4]) : cronArray[4];


    // Adding duration to minutes
    let addedMinutes = currentMinute + parseInt(duration);
    let newMinute = addedMinutes % 60;

    // Adding duration to hours
    let addedHours = Math.floor(addedMinutes / 60);
    let newHour = typeof currentHour==="number" ? (currentHour + addedHours) % 24 : currentHour;

    // Adding duration to days
    let addedDays = Math.floor((currentHour + addedHours) / 24);
    let newDay = typeof currentDay==="number" ? (currentDay + addedDays) % 32 : currentDay;

    // Adding duration to months
    let addedMonths = Math.floor((currentDay + addedDays) / 32);
    let newMonth = typeof currentMonth==="number" ? (currentMonth + addedMonths) % 13 : currentMonth;

    // Adding duration to days of week
    let addedDayOfWeek = Math.floor((currentMonth + addedMonths) / 13);
    let newDayOfWeek = typeof currentDayOfWeek==="number" ? (currentDayOfWeek + addedDayOfWeek) % 7 : currentDayOfWeek;

    logger("addDurationToCron >>", currentMinute, duration,addedMinutes,  newMinute, currentHour, newHour)

    cronArray[0] = newMinute.toString();
    cronArray[1] = newHour.toString();
    cronArray[2] = newDay.toString();
    cronArray[3] = newMonth.toString();
    cronArray[4] = newDayOfWeek.toString();
    return cronArray.join(" ");
}


export const explainCronExpression = (cronExpression, language) => {
    let cronArray = cronExpression.split(" ");
    let minute = cronArray[0];
    let hour = cronArray[1];
    let dayOfMonth = cronArray[2];
    let month = cronArray[3];
    let dayOfWeek = cronArray[4];

    let explanation = "";
    if (language === "en") {
        explanation = "This cron expression runs every: " +
            "\n- Minute: " + minute +
            "\n- Hour: " + hour +
            "\n- Day of the month: " + dayOfMonth +
            "\n- Month: " + month +
            "\n- Day of the week: " + dayOfWeek;
    } else if (language === "fr") {
        explanation = "Cette expression cron s'exécute tous les: " +
            "\n- Minute: " + minute +
            "\n- Heure: " + hour +
            "\n- Jour du mois: " + dayOfMonth +
            "\n- Mois: " + month +
            "\n- Jour de la semaine: " + dayOfWeek;
    } else {
        explanation = "Language not supported, please provide 'en' or 'fr'";
    }
    return explanation;
}


import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { GetPartnerIntegration } from '../../../../../globalsTools/network';
import StyledAutoComplete from '../../../../../ui/forms/StyledAutoComplete';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import AreaSelect from '../../../../Sites/Components/AreaSelect';
import { vocabulary } from '../../../../Strings';


const BayerConfigureForm = ({ isEditing, currentItem, setIsFormValid }) => {


    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,

        });
    };

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [devices, setDevices] = useState({});
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [areaId, setAreaId] = useState(null)
    const [shared, setShared] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [authUrl, setAuthUrl] = useState(null)


    useEffect(() => {

        if (currentItem) {

            setSelectedAreas(currentItem.related_areas)
            setAreaId(currentItem.itemId)
            setShared(currentItem.shared)
            GetPartnerIntegration(currentItem.partner_id, setDevices);

        }

    }, [isEditing, currentItem])

 
    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={12}>
                <StyledAutoComplete
                    data={devices && devices.length > 0 ? devices : []}
                    optionLabel={(option) => option?.name ?? ''}
                    onChange={(_, newValue) => setAreaId(newValue?.id)}
                    label={vocabulary.areaId}
                    value={areaId}
                    styles={{ width: '100%' }}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <AreaSelect onChangeFn={setSelectedAreas} selectedAreas={selectedAreas} multipleSelect title={vocabulary.related_areas} />
            </Grid>

            <Grid item xs={12} md={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={shared}
                            onChange={() => setShared(!shared)}
                            name="shared"
                            color="primary"
                        />
                    }
                    label={vocabulary.shared_device}
                />
            </Grid>
        </Grid>
    );
}

export default BayerConfigureForm


import React, { useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { vocabulary } from "../Strings";
import { GuideContext } from "../../ui/layout/contextLayout/GuideContext";
import { IS_ENABLED, INITIAL_STEP, CURRENT_STEP } from "../globalConsts";
import { setCurrentPage } from "../../actions";


const HelpAndSupport = () => {
  const dispatch = useDispatch()
  const { dispatchGuide } = useContext(GuideContext)
  const history = useHistory()
  const currentLanguage = useSelector(state => state._globalState.preferred_language)

  useEffect(() => {
    dispatch(setCurrentPage(vocabulary.helpAndSupport))
  }, [dispatch])

  useEffect(() => {

    dispatch(setCurrentPage(vocabulary.helpAndSupport));
    document.title = vocabulary.helpAndSupport

  }, [dispatch, currentLanguage])

  return (
    <div>
      <Button
        style={{ margin: "5px" }}
        onClick={() => {
          dispatchGuide({ type: INITIAL_STEP, payload: 0 })
          dispatchGuide({ type: CURRENT_STEP, payload: 'homePage' })
          dispatchGuide({
            type: IS_ENABLED,
            payload: true,
          })
          history.push("/app/home");
        }}
        color="primary"
        variant="contained"
      >
        {vocabulary.watchGuideAgain}
      </Button>
    </div>
  );
};

export default HelpAndSupport;

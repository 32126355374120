/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


import AddIcon from '@material-ui/icons/AddCircleOutline';
import AddSourceForm from './AddSourceForm'
import AlertDialog from "../Components/alertDialog";
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { StyledMaterialTable } from '../../ui/display';
import { useSnackbar } from 'notistack';
import { vocabulary } from '../Strings';
import { DeleteDataSource, GetAreasList, GetDataSourcesList, GetSourcesList } from '../../globalsTools/network';

const SourceMangmentList = ({ dataTypeList }) => {

    const { enqueueSnackbar } = useSnackbar();
    const currentLanguage = useSelector(state => state._globalState.preferred_language)

    const [openEdit, setOpenEdit] = useState(false);
    const [item, setItem] = useState(null)
    const [openAdd, setOpenAdd] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [siteList, setSiteList] = useState(null)
    const [sourceList, setSourceList] = useState(null)
    const [dataSourceList, setDataSourceList] = useState(null)
    const [formattedData, setFormattedData] = useState(null)

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const handleClickOpenAdd = () => {
        setOpenAdd(true)
    };
    const openAlertConfirmDelete = (row) => {
        setAlertConfirmDelete(true)
        setItem(row)
    };
    useEffect(() => {

        GetAreasList(setSiteList)
        GetDataSourcesList(setDataSourceList)
        GetSourcesList(setSourceList)
    }, []); // N’exécute l’effet que a la première render
    useEffect(() => {
        if (dataSourceList !== null)
            dataSourceList.map((rowData) => {
                setFormattedData(formattedData => formattedData !== null ? [...formattedData, {
                    ...rowData,
                    areaColumn: rowData.area && rowData.area.parent ? (rowData.area.parent.name + ' / ' + rowData.area.name) : rowData.area ? rowData.area.name : '',
                    prmDataTypeColumn: rowData.prm_data_type ? (JSON.parse(rowData.prm_data_type.name)['name_' + currentLanguage]) : '',
                    dataSourceColumn: rowData.data_source ? rowData.data_source.id : '',
                }] : [])
            })
    }, [dataSourceList, currentLanguage])
    const handleClickOpenEdit = (row) => {
        setOpenEdit(true)
        setItem(row)
        setIsEditing(true)

    };
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 204) {
                snackbar(JSON.parse(item.prm_data_type.name)['name_' + currentLanguage] + " " + vocabulary.deleteMsg, 'success')

                GetDataSourcesList(setDataSourceList)

            } else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const handleDeleteSource = (item) => {
        DeleteDataSource(item.id
            , r => operationCallBack(r)
        )
    }
    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };
    const changeModalStatus = (newStatus) => {
        if (newStatus == null) {
            setOpenEdit(!openEdit)
            setOpenAdd(!openAdd)

        } else {
            setOpenEdit(newStatus)
            setOpenAdd(newStatus)
            setIsEditing(newStatus)
        }
    }

    const contextValueEdit = { open: openEdit, changeModalStatus: changeModalStatus }
    const contextValueAdd = { open: openAdd, changeModalStatus: changeModalStatus }
    return (
        <>
            <ModalContext.Provider value={isEditing === false ? contextValueAdd : contextValueEdit}>

                {dataSourceList ?
                    <>
                        {formattedData !== null ? <StyledMaterialTable
                            title={vocabulary.sourceLabel}
                            data={formattedData}
                            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                            columns={[
                                { title: vocabulary.area, field: 'areaColumn', defaultGroupOrder: 0   /*, render: rowData => <>{rowData.area && rowData.area.parent ? (rowData.area.parent.name + ' / ' + rowData.area.name) : rowData.area ? rowData.area.name : '' }</>*/ },
                                { title: vocabulary.datatype, field: 'prmDataTypeColumn' /*,render: rowData => <>{rowData.prm_data_type ? (JSON.parse( rowData.prm_data_type.name)['name_' + currentLanguage]):''}</> */ },
                                { title: vocabulary.sourceLabel, field: 'dataSourceColumn' /*,render: rowData => <>{rowData.data_source ? rowData.data_source.id: ''}</>*/ },

                            ]}
                            actions={[
                                {
                                    icon: 'edit_outline',
                                    tooltip: vocabulary.editLabel,
                                    onClick: (event, row) => handleClickOpenEdit(row)
                                },
                                rowData => ({
                                    icon: 'delete_outline',
                                    tooltip: vocabulary.delete,
                                    onClick: (event, row) => openAlertConfirmDelete(row),
                                    disabled: rowData.id < 2000
                                }),

                                {
                                    icon: () => <AddIcon fontSize='large' color='primary' />,
                                    tooltip: vocabulary.add,
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpenAdd()
                                }

                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                // filtering: true,
                                grouping: true,
                                search: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 40, 60, 80, 100],


                            }}
                        /> : <StyledCircularProgress size={50} label={vocabulary.loading} />}
                        {(openAdd || openEdit) &&
                            <AddSourceForm
                                open={isEditing === false ? openAdd : openEdit}
                                onClose={() => {
                                    return (
                                        isEditing === true ?
                                            (setOpenEdit(false),
                                                setIsEditing(false))
                                            : setOpenAdd(false)

                                    )
                                }}
                                item={isEditing === true ? item : null}
                                refetchFunc={() => { setFormattedData(null); GetDataSourcesList(setDataSourceList) }
                                }
                                isEditing={isEditing}
                                siteList={siteList && siteList}
                                sourceList={sourceList}
                                dataTypeList={dataTypeList}

                            />}
                        {alertConfirmDelete === true &&
                            <AlertDialog
                                operation={vocabulary.delete}
                                open={alertConfirmDelete}
                                deletedItemName={JSON.parse(item.prm_data_type.name)['name_' + currentLanguage]}
                                onClose={() => {

                                    closeAlertConfirmDelete();
                                }}
                                confirmeDelete={() => {
                                    if (item) {
                                        setFormattedData(null);
                                        handleDeleteSource(item);
                                    }

                                    closeAlertConfirmDelete();
                                }}

                                cancel={() => {
                                    closeAlertConfirmDelete();
                                }}
                            />}

                    </>
                    : <StyledCircularProgress size={50} label={vocabulary.loading} />}
            </ModalContext.Provider>

        </>
    )
}
export default SourceMangmentList
export const data = [
    {
        indice: 'NDVI',
        id: '1',
        description_fr: "La superstar des indices de végétation: Depuis plus de 30 ans, le NDVI (Normalized Difference Vegetation Index) est l'indice de référence en agriculture. Il combine des bandes visibles et infrarouges proches pour fournir des informations sur la densité et la verdure de la canopée.Le NDVI est un excellent point de départ pour comprendre la croissance des cultures pendant une saison de croissance ",
        description_en: 'The Vegetation Indices superstar:  For over 30 years, the NDVI (Normalized Difference Vegetation Index) has been the reference index in agriculture. It combines visible and near infrared bands to provide information on canopy density and greenness.NDVI is a great starting point to understand crop growth during a growing season',
    },
    {
        indice: 'NDWI',
        id: '2',
        description_fr: "Indice de l'eau de différence normalisée. Mesure la teneur en eau relative des feuilles. Fournit un indicateur très clair du niveau de stress hydrique dans la culture ",
        description_en: 'Normalized Difference Water Index. Measures the relative water content in leaves. Provides a very clear indicator of the level of water stress in the crop',
    },
    {
        indice: 'SAVI',
        id: '3',
        description_fr: "Indice de végétation ajusté au sol. Il s'agit du NDVI avec un facteur de correction du sol. Utile pour les cultures dont les sols sont plus visibles (vergers, vignobles) ou pour les premiers stades de croissance.",
        description_en: 'Soil Adjusted Vegetation Index. Think of it as NDVI with a soil correction factor. Useful for crops with more visible soils (orchards, wineries) or for early growing stages.',
    },
    {
        indice: 'EVI ',
        id: '4',
        description_fr: "Indice de végétation amélioré. Pour les cultures dont la densité de la canopée est élevée, le NDVI a tendance à saturer. L'EVI est plus robuste à utiliser pour ces types de cultures ou de stades de croissance.",
        description_en: 'Enhanced Vegetation Index. For crops with high canopy density, NDVI tends to saturate. EVI is more robust to use for these types of crops or growing stages. ',
    }, {
        indice: 'LAI',
        id: '5',
        description_fr: "Indice de surface foliaire. Il s'agit d'une mesure de la surface des feuilles par unité de surface. Outre le fait qu'il permet de suivre la croissance d'une plante, l'indice de surface foliaire est également lié à la productivité des plantes, car les feuilles contiennent les pigments chlorophylliens indispensables à la photosynthèse. L'indice LAI est couramment utilisé pour les prévisions de rendement.",
        description_en: 'Leaf Area Index. A measurement of the area of leaves per unit of land. Besides being able to obviously track the growth of a plant, LAI is also linked to plant productivity as leaves house the critical chlorophyll pigments for photosynthesis. LAI is commonly used for yield predictions. ',
    },
    {
        indice: 'CHI',
        id: '6',
        description_fr: 'Indice de chlorophylle. Mesure le niveau de chlorophylle dans la plante, indicateur des besoins en nutriments et du rendement.',

        description_en: 'Chlorophyll Index. Measures the level of chlorophyll in the plant, indicative of nutrient requirements and yield'

    }
    , {
        indice: 'NDRE',
        id: '6',
        description_fr: 'Différence normalisée Bord rouge. La meilleure solution pour surveiller le stress azoté dans la culture et créer des cartes de prescription.',

        description_en: 'Normalized Difference Red Edge. The best solution to monitor the nitrogen stress in the crop, and create prescription maps'

    },
    {
        indice: 'NIRv ',
        id: '7',
        description_fr: "Proche infrarouge de la végétation. Index qui isole davantage le signal de la végétation de l'image satellite et qui est le plus lié à la productivité des plantes.",
        description_en: 'Near Infrared of Vegetation. Index which further isolates the vegetation signal from the satellite image and most related with plant productivity.'

    }
]
import React from 'react'
import { Typography } from '@material-ui/core'


const ValidateInvoice = () => {
  return (
    <Typography sx={{ mt: 2, mb: 1 }}>
      All steps completed - you&apos;re finished
    </Typography>
  )
}

export default ValidateInvoice
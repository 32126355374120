/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";

import { StyledModal } from "../../../ui/layout";
import { Grid,  ListItem, ListItemText, List } from "@material-ui/core";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { StyledInput, StyledSubmitButton } from "../../../ui/forms";
// import { ModalContext } from '../../../ui/layout/contextLayout/ModalContext';
import { vocabulary } from "../../Strings";
import { GuideContext, steps } from "../../../ui/layout/contextLayout/GuideContext";
import { IS_ENABLED } from "../../globalConsts";
import GotoDialogStepper from "./GotoDialogStepper";

const GotoDialog = ({gotoPosition, context, map, setOpenGoto}) => {

  const [selectedPlace, setSelectedPlace] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectedPlaceLatitude, setSelectedPlaceLatitude] = useState("")
  const [selectedPlaceLongitude, setSelectedPlaceLongitude] = useState("")
  const { guide, dispatchGuide } = useContext(GuideContext)

  useEffect(() => {
    if(steps[guide.initialStep].element === ".goToModalDialog" && guide.currentStep === 'goToDialog')
    setTimeout(() => {
          dispatchGuide({type: IS_ENABLED, payload: true})
      }, 700);
  }, [])

  const handleSelect = address => {
    setLoading(true);
    geocodeByAddress(address)
      .then(results   => { return getLatLng(results[0])})
      .then(latLng    => { setSelectedPlaceLatitude(latLng.lat);setSelectedPlaceLongitude(latLng.lng);setSelectedPlace(address);setLoading(false) })
      // .then(setLoading(false))
      .catch(error    => {console.error('Error', error);setLoading(false)});
  };

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => {
    return (
      <div style={{ position: 'relative', display:'flex',width:'100%'}}>
        <StyledInput {...getInputProps()} styles={{display:'flex', flexGrow:1}} placeholder={vocabulary.placeToSearch} />
        <List style={{ position: 'absolute', top: 50, zIndex: 600, opacity: 1,backgroundColor: "#ebebeb",borderRadius:6, marginBottom:15}}>
          {loading && <ListItem>{vocabulary.loading}</ListItem>}
          {suggestions.map((suggestion, key) => (
            <ListItem button {...getSuggestionItemProps(suggestion)} key={key} variant="subtitle2" style={{ color: 'black', borderRadius: 6 }}>
              <ListItemText primary={suggestion.description} />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

    
  // const modalContext = useContext(ModalContext)


  return (
  <>
        {guide.isEnabled && guide.currentStep==='goToDialog' && <GotoDialogStepper setOpenGoto={setOpenGoto} />}
  
  <StyledModal title={vocabulary.navigateTo} withoutContext={context ? true : undefined} open={context ? context.open : undefined} closeFn={context ? context.changeModalStatus : undefined} styles={{zIndex:2100}}>
    <Grid className="goToModalDialog" container direction="column"  style={{flexGrow:1}}>
        <Grid item container direction="row">
          {/* <Typography variant="caption" style={{display:'flex', alignItems:'center'}}>{vocabulary.placeToSearch}</Typography> */}
          <PlacesAutocomplete value={selectedPlace} onChange={setSelectedPlace} onSelect={handleSelect} style={{flexGrow:1}}>
            {renderFunc}
          </PlacesAutocomplete>
        </Grid>
        <div style={{ marginTop: 10 }}>
          <Grid item container direction="row">
          <StyledInput name='latitude' label={vocabulary.LatitudeLabel} value={selectedPlaceLatitude} onChange={(event)=>{setSelectedPlaceLatitude(event.target.value)}} />
                <StyledInput name='longitude' label={vocabulary.LongitudeLabel} value={selectedPlaceLongitude} onChange={(event)=>{setSelectedPlaceLongitude(event.target.value)}} />
          </Grid>
          <Grid item>
          <StyledSubmitButton disabled={selectedPlaceLatitude==="" || selectedPlaceLongitude===""} 
                        onClick={() => {gotoPosition({latitude:selectedPlaceLatitude, longitude:selectedPlaceLongitude},map,setOpenGoto)}} label="GO TO COORDINATES" />
            </Grid>
        </div>
      </Grid>
    </StyledModal>
    </>

  )
}

export default GotoDialog
/* eslint-disable array-callback-return */

import React, { useEffect, useRef, useState } from 'react';

import {
  Card,
  CardContent,
  Checkbox,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { checkVoucher, GetAreaDetails, GetSeabexOffers } from '../../globalsTools/network';
import { logger, makeid } from '../../globalsTools/utilities';
import { StyledInput, StyledSubmitButton } from '../../ui/forms';
import { StyledModal } from '../../ui/layout';

import SiteDetails from '../Sites/Components/SiteDetails';
import { vocabulary } from './../Strings';
import PaymentManager from '../Payments/PaymentManager';
import { useCart } from '../Payments/useCart';
import { Check, CheckCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 800,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 226,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const SeabexOffers = ({
  area_id,
  area_size,
  areaProp,
  showAreaDetails = false,
  ...modalprops
}) => {
  const classes = useStyles();

  const [offers, setOffers] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [currencyDecimals, setCurrencyDecimals] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [area, setArea] = useState(null);
  // const [cart, setCart] = useState([]);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const { cartData, addCartItem, removeCartItem, cartSum, cartSumHT } = useCart([]);
  const [voucher, setVoucher] = useState(null)
  const [voucherCode, setVoucherCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const checkboxRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();


  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const calculatePrice = (total) => {
    if (isDiscountVoucher()) {
      return Number(total - (total * (discountValue() / 100))).toFixed(currencyDecimals)
    }

    return Number(total).toFixed(currencyDecimals)
  }

  // TO USE useCart Methods instead
  const changeCart = (offer, checked) => {
    logger("Selected Offer", offer)
    if (checked) {
      cartData.filter((item) => item.provider === offer.provider).map(item => removeCartItem(item))
      addCartItem({
        id: offer.id,
        type: offer.type,
        quantity: 1,
        price: offer.total_in_vat,
        area_id: area && area.id,
        total_in_vat: calculatePrice(offer.total_in_vat),
        total_x_vat: calculatePrice(offer.total_x_vat),
        tax_rate: offer.tax_rate,
        provider: offer.provider,
        label: offer.name,
        voucherCode: voucherCode,
        unique_group_id: offer.unique_group_id
      })
    } else {
      removeCartItem(offer);
    }
  };

  const handleOffersResponse = (data) => {
    logger(["Data Seabex Offers >> ", data])
    if (data && data.data) {
      data = data.data
      if (data["offers"] && data["currency"] && data["currency_decimals"] && data["payment_provider"]) {
        setOffers(data["offers"])
        setCurrency(data["currency"])
        setCurrencyDecimals(data["currency_decimals"])
        setPaymentProvider(data["payment_provider"])
      }
    }
  }


  useEffect(() => {
    if (areaProp == null && area_id != null) {
      GetAreaDetails(area_id, setArea);
    } else {
      setArea(areaProp);
    }
  }, [areaProp, area_id]);

  useEffect(() => {
    if (area != null) {
      GetSeabexOffers(area.id, area.size, handleOffersResponse);
    } else {
      GetSeabexOffers(null, null, handleOffersResponse);
    }
  }, [area]);

  useEffect(() => {
    logger("Offers", offers);
  }, [offers]);

  useEffect(() => {
    logger('cartData ->> ', cartData)
  }, [cartData])

  const isDiscountVoucher = () => {
    return voucher?.details?.type == 'DISCOUNT'
  }

  const discountValue = () => {
    return voucher?.details?.percent
  }

  const offerRestricted = () => {
    return voucher?.seabex_pack_id != null
  }

  const showDiscount = (offer) => {
    const discountMessage = discountValue() && <del>{"  " + Number(offer.total_x_vat).toFixed(currencyDecimals)}</del>;

    if (offerRestricted() && offer.id === voucher?.seabex_pack_id) {
      return discountMessage;
    }

    if (!offerRestricted()) {
      return discountMessage;
    }

    return null;


  }

  const offerPrice = (cost, packId) => {

    if (voucher && !offerRestricted()) {
      return Number(cost - (cost * (discountValue() / 100))).toFixed(currencyDecimals) + " " + currency + " HT"
    }
    if (voucher && offerRestricted() && packId === voucher?.seabex_pack_id) {
      return Number(cost - (cost * (discountValue() / 100))).toFixed(currencyDecimals) + " " + currency + " HT"
    }


    return Number(cost).toFixed(currencyDecimals) + " " + currency + " HT"
  }

  const applyVoucher = async (voucher) => {
    setIsLoading(true)
    await checkVoucher({
      voucher: voucherCode,
      groupId: 'SEABEX_IRRIGATION'
    }, (response) => {
      if (response.status != 200) {
        snackbar(vocabulary.errorOccurred, 'error');
        return
      }
      snackbar('Coupon applied', 'success')
      setVoucher(response.data)


      setIsLoading(false)


    })

  }

  return (
    <StyledModal
      width={870}
      style={{ minWidth: 870 }}
      {...modalprops}
      title={vocabulary.seabexOffers}
    >



      {area != null && showAreaDetails && <SiteDetails {...area} />}

      {

        offers != null && offers.length > 0 ?
          (
            <>
              <Grid container >

                <Grid item xs={6}>
                  <StyledInput
                    value={voucherCode}
                    required
                    onChange={(event) => { setVoucherCode(event.target.value) }}
                    name='voucher'
                    type="text"
                    fullWidth
                    label={vocabulary.invitationVoucher}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledSubmitButton
                    submit={false}
                    label={vocabulary.applyVoucher}
                    onClick={() => applyVoucher(voucher)}
                    disabled={!voucherCode || isLoading}
                  />
                </Grid>
              </Grid>
              {
                offers.map((offer, index) => {
                  if (offer.name != null) {
                    return (
                      <Card style={{ marginBlockEnd: 8, backgroundColor: offer.quantity === -1 ? "#f8ffe2" : null }}>
                        <CardContent>
                          <Grid key={index + makeid()} container direction='row' wrap='nowrap' spacing={2}>
                            <Grid item>
                              {offer.quantity !== -1 ?
                                <Checkbox
                                  checked={_.findIndex(cartData, ['id', offer.id]) > -1}
                                  value={offer.title}
                                  disabled={offer.quantity === -1 || isLoading}
                                  onChange={(event) => {
                                    event.preventDefault();
                                    changeCart(offer, event.target.checked);
                                  }}
                                />
                                :
                                // <Checkbox checked={true} style={{color:'green'}} disabled={true} />
                                <CheckCircle style={{ color: "green" }} />
                              }
                            </Grid>
                            <Grid item>
                              <img
                                src={offer.image}
                                alt={offer.title}
                                title={offer.title}
                                style={{ borderRadius: 10, width: 120 }}
                              />
                            </Grid>
                            <Grid item>
                              <Grid container direction='column' style={{ flexGrow: 1 }} spacing={1}>
                                <Grid item>
                                  <Typography noWrap={false} style={{ fontSize: 18, textAlign: 'start' }}>
                                    {offer.name}
                                  </Typography>
                                </Grid>
                                <Grid item style={{ flexGrow: 1 }}>
                                  <Typography variant='subtitle1' color='textSecondary' noWrap={false}>
                                    {offer.description}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {
                                    offer.quantity !== -1 ?
                                      <Typography component='h6' variant='h6' noWrap={true}>
                                        {vocabulary.price + '  '}:{' '}
                                        {offerPrice(offer.total_x_vat, offer.id)} {showDiscount(offer)}
                                      </Typography>
                                      :
                                      <Chip icon={<Check style={{ color: 'white' }} />} label='Déjà acheté' style={{ color: 'white', backgroundColor: 'green ' }} />

                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  }
                })
              }
            </>
          )
          : (
            <Typography>{vocabulary.loading}</Typography>
          )
      }



      {cartData != null && cartData.length > 0 && (
        <>
          <StyledSubmitButton
            submit={false}
            label={vocabulary.PayerLabel + ' ' + cartSum(currencyDecimals) + " " + currency + " (" + cartSumHT(currencyDecimals) + " " + currency + " HT)"}
            onClick={() => setShowPaymentForm(true)}
            disabled={isLoading}
          />
          {showPaymentForm && (
            <StyledModal
              width={560}
              open={showPaymentForm}
              title={vocabulary.paymentFormLabel}
              closeFn={() => setShowPaymentForm(false)}
              withoutContext={true}
            >
              <PaymentManager cart={cartData} onSuccessFn={() => { setShowPaymentForm(false); modalprops.closeFn(); }} onFailureFn={() => { }} />

            </StyledModal>
          )}
        </>
      )}
    </StyledModal>
  );
};

export default SeabexOffers;

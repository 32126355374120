/* eslint-disable eqeqeq*/

import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.css';

import { vocabulary } from '../Strings';
import './../../App.css';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';

import { useSelector } from 'react-redux';
import { parseJSONObject, getNameFromJSON } from '../../utils';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import StyledProgressCycle from '../../ui/specific/StyledProgressCycle';
import { useCallback } from 'react';
import { GetProductionCycleById, RecalculateWaterNeed, updateStadesProductionCycle } from "../../globalsTools/network";
import { useSnackbar } from 'notistack';
import RecalculDialogConfirmation from './RecalculDialogConfirmation'
import { getFormattedDate, logger } from '../../globalsTools/utilities';
import { StyledModal } from '../../ui/layout';

import { DatePicker } from '@material-ui/pickers';
import { StyledDialog } from '../../ui/display/StyledDialog';
import { Info, InfoOutlined, InfoTwoTone } from '@material-ui/icons';
import { Alert } from 'bootstrap';
import { removeRecalculatingCycle, updateRecalculatingCycles } from '../../actions/actions';


const CycleDetails = ({ item, onePagerView, cultureParentName, rerenderFn }) => {

  const currentLanguage = useSelector(state => state._globalState.preferred_language)
  // const recalculatingCycles = useSelector(state => state.cycle.recalculatingCycles)
  const moment = require('moment');
  const [curratedItem, setCurratedItem] = useState(null)
  const [curratedCycle, setCurratedCycle] = useState(null)
  const [cyclePhases, setCyclePhases] = useState(null)
  const [cycleListDetail, setCycleListDetail] = useState(null)
  const { enqueueSnackbar } = useSnackbar();
  const [loadedItem, setLoadedItem] = useState(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [confirmCycleSaving, setConfirmCycleSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [editPhaseStatus, setEditPhaseStatus] = useState(false)
  const [editedPhase, setEditedPhase] = useState(null)


  const dateDiff = useCallback((startDate, end_date) => {
    let today
    if (end_date)
    {
      today = moment.min(moment(new Date()), moment(end_date))
    } else {
      today = moment(new Date())
    }
    logger("CycleDetails Today", startDate, end_date, today)

    let diff = today.diff(startDate, 'days')
    
    return diff
  }, [moment])

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      preventDuplicate: true,
    });

  };

 

  useEffect(() => {
    if (item) {
      GetProductionCycleById(item.id, setCycleListDetail)
    }
  }, [item])




  useEffect(() => {

    if (cycleListDetail) {
      setLoadedItem(cycleListDetail)
    }

  }, [cycleListDetail])

  useEffect(() => {

    if (loadedItem) {
      let culture = loadedItem.prm_culture && getNameFromJSON(loadedItem.prm_culture.name, currentLanguage)
      let cycle = parseJSONObject(loadedItem.cycle)
      let dayToday = dateDiff(loadedItem.start_date, loadedItem.end_date)
      if (cycle && dayToday > cycle.Total) {
        dayToday = cycle.Total
      }
      // dayToday = 100

      setCurratedItem({ ...loadedItem, culture: culture, cycle: cycle, dayToday: dayToday })
    }

  }, [loadedItem, currentLanguage, dateDiff])

  const operationCallBack = (r) => {
    if (r) {
      if (r.status === 200) {
        snackbar(vocabulary.waterNeedRecalculated, 'success')
        if (rerenderFn) {
           rerenderFn()
        }
      } else {
        snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
      }
    }
  }

  const recalculateWaterNeed = () => {
    setOpenConfirmation(false)
    setIsLoading(false)
    RecalculateWaterNeed(
          {
            area_id: item.area_id,
            id:item.id
          }, r => operationCallBack(r)
        )
  }

  
  const calculateCycleProgress = (dayToday, cycleProduction, percentDone, phase, start_date) => {
    let end_date 
      if (dayToday < cycleProduction[phase].length) {
          end_date = (cycleProduction[phase]["length"]>0 && moment(start_date).add(cycleProduction[phase]["length"], 'days').format('YYYY-MM-DD'))
          percentDone.set(cycleProduction[phase]['name'], 
          {          
                    length:dayToday, 
                    cycle_length:cycleProduction[phase]["length"], 
                    start_date: start_date,
                    end_date: end_date,
                    order: cycleProduction[phase]['order']
          }
          );
          dayToday = 0
      } else {
          end_date = (cycleProduction[phase]["length"]>0 && moment(start_date).add(cycleProduction[phase]["length"], 'days').format('YYYY-MM-DD'))
          percentDone.set(cycleProduction[phase]['name'], 
                            {
                              length:cycleProduction[phase]["length"], 
                              cycle_length:cycleProduction[phase]["length"], 
                              start_date: start_date,
                              end_date:end_date,
                              order: cycleProduction[phase]['order']
                            });
          dayToday -= cycleProduction[phase]["length"]
      }
      return { percentDone: percentDone, dayToday: dayToday, last_start_date: end_date }
  }
  
  const getPhasePercentages = useCallback(
    (dayToday, cycle, start_date) => {
        var percentDone = new Map();
        var last_start_date = start_date;
        if (cycle) {
          for (const [key, value] of Object.entries(cycle)) {
              if (key !== "Total") {
                  percentDone.set(value["name"], 0);
                  ({ percentDone, dayToday, last_start_date } = calculateCycleProgress(dayToday, cycle, percentDone, key, last_start_date));
              }
          }
        }

        return percentDone
    }, [])

  const handleChangeStartDatePhase = (date) => {
    let curratedCycleCopy = [...curratedCycle]
    let editedPhaseCopy = {...editedPhase}
    editedPhaseCopy.specs.start_date = date
    // curratedCycleCopy[curratedCycleCopy.findIndex(element=> element.order==editedPhase.phase.order-1)].length = date.diff(moment(cyclePhases.get(curratedItem.cycle.filter(o => o.order==editedPhase.phase.order-1)[0].name).start_date), 'days')
    const lengthDiff = curratedCycle[curratedCycle.findIndex(element=> element.order==editedPhase.phase.order)].length - moment(editedPhase.specs.end_date).diff(date, 'days')

    if (lengthDiff<=(0-curratedCycleCopy[curratedCycleCopy.findIndex(element=> element.order==editedPhase.phase.order-1)].length)) {
      snackbar("Il n'est pas possible de mettre une date avant ou égale à la date de début de la phase précédente !", 'warning')
      return
    }
    curratedCycleCopy.forEach((element) => {
      if (element.order==editedPhase.phase.order-1) {
        element.length +=lengthDiff
      }

    })
    
    // curratedCycleCopy[curratedCycleCopy.findIndex(element=> element.order==editedPhase.phase.order)].length = moment(editedPhase.specs.end_date).diff(date, 'days')
    
    if (window.confirm("Voulez-vous mettre à jour la phase sélectioné avec la nouvelle date ?")) {
        setCurratedCycle(curratedCycleCopy)
        setEditedPhase(editedPhaseCopy)  
        updateStadesProductionCycle(item.id,curratedCycleCopy)   // ADD Universal Callback
        
    }
    
  }

  const handlePhaseClick = (phaseDetails) => {
    setEditPhaseStatus(true);
    setEditedPhase(phaseDetails);
  }

  useEffect(() => {
    if (curratedItem!=null && curratedItem.cycle!=null) {
      setCurratedCycle(curratedItem.cycle)
    }
  }, [curratedItem])

  useEffect(() => {
    if (curratedCycle) {
        setCyclePhases(getPhasePercentages(curratedItem.dayToday, curratedCycle, curratedItem.start_date))
    }
  }, [curratedItem, curratedCycle, getPhasePercentages])

  useEffect(() => {
    if (editedPhase) {
      logger("Verify Condition ", cyclePhases, curratedItem, editedPhase, editedPhase.phase.order, 
              Math.min(...curratedCycle.map(o => o.order)), editedPhase.phase.order>(Math.min(...curratedCycle.map(o => o.order) )), 
              curratedItem.cycle.map(o => o.order<editedPhase.phase.order).lastIndexOf(true),
              curratedItem.cycle.at(curratedItem.cycle.map(o => o.order<editedPhase.phase.order).lastIndexOf(true))
              )
    }  
  }, [editedPhase])
  
  


  return (<>
    {
      (curratedItem && cycleListDetail && cycleListDetail.cycle) ?
        <>
          <Grid container
            direction='column'
            style={{
              display: 'flex',
              // cursor: recalculatingCycles.includes(item.id) ? 'wait' : 'default',
              // opacity: recalculatingCycles.includes(item.id) ? '0.1' : 1,
              position: 'relative'
            }}>
            <Grid item>
              <Typography style={{ fontSize: 18, textAlign: 'start', margin: 16 }}>{vocabulary.Culture_Name + ' : ' + (cycleListDetail.prm_culture && JSON.parse(cycleListDetail.prm_culture.name)['name_' + currentLanguage])}</Typography>
            </Grid>
            <Grid item style={{ justifyContent: 'center', display: 'flex', margin: 16 }}>
              <StyledProgressCycle
                dayToday={curratedItem.dayToday}
                cycle={curratedItem.cycle} 
                cycleProgress={cyclePhases}
                onPhaseClick={handlePhaseClick}
              />


            </Grid>
            <Grid item>
              
              <Typography style={{ fontSize: 14, textAlign: 'center', margin: 16, background:'#f9f2e7' }}>
                <InfoOutlined /> {vocabulary.changeStartDate}
              </Typography>
            </Grid>


            <Grid item container direction='row' justifyContent='center'>
              <Grid item alignContent='flex-start' container direction='row' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', width: `calc(95%)`, marginTop: 12, marginBottom: 12 }}>
                <div style={{ margin: 12 }}>{vocabulary.startDate + " : "} <b>{(currentLanguage == "en" ? moment(curratedItem.start_date).format('YYYY-MM-DD') : moment(curratedItem.start_date).format('DD-MM-YYYY'))}</b></div>
                {
                  curratedItem.end_date ?
                  <div style={{ margin: 12, border:`solid 3px green`, borderRadius:10, padding:4 }}>{vocabulary.endOnLabel } <b>{(currentLanguage == "en" ? moment(curratedItem.end_date).format('YYYY-MM-DD') : moment(curratedItem.end_date).format('DD-MM-YYYY'))}</b></div>
                  :
                  <div style={{ margin: 12 }}>{vocabulary.recommendedDate + " : "} <b>{(currentLanguage == "en" ? moment(curratedItem.recommended_end_date).format('YYYY-MM-DD') : moment(curratedItem.recommended_end_date).format('DD-MM-YYYY'))}</b></div>
                }
                <div style={{ margin: 12 }}>{vocabulary.compactionRate} : <b>{curratedItem.taux_tassement} %</b></div>
              </Grid>
            </Grid>

          <Grid item>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  // disabled={recalculatingCycles.includes(item.id)}
                  style={{ margin: '5px' }}
                  color="primary"
                  onClick={() => setOpenConfirmation(true)}
                  // variant="contained"
                  size="large"
                >
                  {vocabulary.recalculateLabel}
                </Button>
              </div>
            </Grid>


          </Grid>
          {/* {
            (recalculatingCycles.includes(item.id)) && (
              <Typography style={{
                position: 'absolute',
                cursor: 'wait',
                width: "100px",
                heigth: "100px",
                top: '45%',
                left: '43%',
              }}
                variant='h5'
              >...Calculation
              </Typography>
            )
          } */}

        {
          openConfirmation && 
            <RecalculDialogConfirmation
              openConfirmation={openConfirmation}
              onClose={() => setOpenConfirmation(false)}
              recalculateWaterNeed={() => recalculateWaterNeed()}
              isLoading={isLoading}

            />

        }


        { editPhaseStatus && editedPhase && editedPhase.phase.order>(Math.min(...curratedCycle.map(o => o.order) ))  && editedPhase.specs.start_date &&  
            <StyledModal 
                open={editPhaseStatus} 
                onClose={()=>setEditPhaseStatus(false)}
                closeFn={()=>setEditPhaseStatus(false)}
                title="Changer la date de début de stade phénologique">

                  <Grid container direction='column'>
                    <Grid item style={{padding:15, backgroundColor:'lightyellow', margin:`-14px -30px 0px -30px`, paddingInlineStart:43}}>
                        <Typography variant='h4'>Phase :&nbsp;{curratedItem.cycle.filter(o => o.order==editedPhase.phase.order)[0].name}</Typography>
                    </Grid>
                    <Grid item container style={{flexDirection:'column', padding:15}}>
                        <Grid item>
                            <Typography variant='subtitle2'>Date de fin de la phase :&nbsp;{getFormattedDate(editedPhase.specs.end_date, currentLanguage)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2'>Date de début de la phase précédente :&nbsp;{getFormattedDate(cyclePhases.get(Array.from(cyclePhases.keys()).at(curratedItem.cycle.map(o => o.order<editedPhase.phase.order).lastIndexOf(true)))?.start_date, currentLanguage)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2'>Date de début avant modification :&nbsp;{getFormattedDate(editedPhase.specs.start_date, currentLanguage)}</Typography>
                        </Grid>
                        <Grid item>
                            <DatePicker 
                                        label="Nouvelle date de début"
                                        value={editedPhase.specs.start_date}
                                        maxDate={editedPhase.specs.end_date}
                                        minDate={cyclePhases.get(Array.from(cyclePhases.keys()).at(curratedItem.cycle.map(o => o.order<editedPhase.phase.order).lastIndexOf(true)))?.start_date}
                                        onChange={handleChangeStartDatePhase}
                                        animateYearScrolling
                                        // disableFuture 
                                        format={currentLanguage=='fr' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                        style={{ margin: '8px', width: '100%' }} 
                                        name='edited_start_date'

                                    />
                        </Grid>
                    </Grid>
                  </Grid>

            </StyledModal>
        }


        </>

        : <StyledLoadingRow />

    }
  </>
  );

}



export default CycleDetails

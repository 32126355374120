import Steps from "intro.js-react/lib/components/Steps";
import React, { useContext } from "react";
import { CURRENT_STEP, INITIAL_STEP, IS_ENABLED } from "../globalConsts";
// import { useHistory } from "react-router-dom";
import {
  GuideContext,
  steps,
} from "../../ui/layout/contextLayout/GuideContext";

const AddStationStepper = ({ handleClickClose }) => {
  const { guide, dispatchGuide } = useContext(GuideContext);
  // const history = useHistory();

  return (
    <Steps
      enabled={guide.isEnabled}
      steps={steps}
      initialStep={guide.initialStep}
      onBeforeChange={(nextStepIndex, nextStepElement) => {
        if (steps[nextStepIndex].element === ".addDeployedDeviceBtn") {
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: CURRENT_STEP, payload: "deployedDevice" });
          dispatchGuide({ type: IS_ENABLED, payload: false });
          handleClickClose()
        } else if (
          steps[nextStepIndex].element === ".addDeployedDeviceModal1"
        ) {
          dispatchGuide({
            type: CURRENT_STEP,
            payload: "addDeployedDeviceModal",
          });
          dispatchGuide({ type: INITIAL_STEP, payload: nextStepIndex });
          dispatchGuide({ type: IS_ENABLED, payload: false });
        }
      }}
      options={{
        tooltipClass: "steps",
      }}
      onExit={() =>
        dispatchGuide({
          type: IS_ENABLED,
          payload: false,
        })
      }
    />
  );
};

export default AddStationStepper;

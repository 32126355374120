
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {  
  //FormControl, InputLabel,
   Divider } from '@material-ui/core/';
   // import { Add, Done } from '@material-ui/icons/';
   import 'bootstrap/dist/css/bootstrap.css';
   
   import PropTypes from 'prop-types';
   import {   getAvailableOrganizations, setCurrentPage,
    //unfollowOrganisme, followingOrganisme,getSpecificOrganisme
  } from '../../actions';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../globalStyle';
import { vocabulary } from '../Strings';
import './../../App.css';
//import _ from 'lodash';
// import IconButton from '@material-ui/core/IconButton';

// import Input from '@material-ui/core/Input';
import { ListItemText } from '@material-ui/core/'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import UnfollowIcon from '@material-ui/icons/PersonAdd'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import SearchIcon from '@material-ui/icons/Search';
// import InputAdornment from '@material-ui/core/InputAdornment';
//import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import { StyledCard } from "../../ui/layout";
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { stylesThemed } from '../globalStyle';

const ListActiveAndAvailableOrganizations = (props) => {

  const dispatch = useDispatch()

  const organizations = useSelector(state => state.organization.activeOrganismes)
  //const followedOrganizations = useSelector(state => state.organization.followedOrganizations)
  const currentLanguage = useSelector(state => state._globalState.preferred_language)


  const [openLatestNotifs, setOpenLatestNotifs] = useState(false);
  const [currentRow, setCurrentRow] = useState([])
  // const [searchField, setSearchField] = useState('')
  // const [isAdding, setIsAdding] = useState(false)
  // const [isEditable, setIsEditable] = useState(true)

  useEffect(() => {

    dispatch(setCurrentPage(vocabulary.organization));
    dispatch(getAvailableOrganizations())
    document.title=vocabulary.organization

  }, [dispatch,currentLanguage]); // N’exécute l’effet qu'a la première render'

  useEffect(() => {

    dispatch(setCurrentPage(vocabulary.organization));
    document.title=vocabulary.organization

  }, [dispatch,currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

  const handleCloseLatestNotifs = () => {
    setOpenLatestNotifs(false)
    setCurrentRow([])

  }

  const classes = stylesThemed();


  return (
    <>
      
      <StyledCard>

        <div style={{ display: 'flex', flexDirection: 'column',width:'100%' }}>

          <StyledMaterialTable
            title={vocabulary.organization}
            data={organizations.data}
            localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay },header:{actions:vocabulary.actions},pagination:{nextTooltip:vocabulary.nextpage,previousTooltip:vocabulary.previouspage,lastTooltip:vocabulary.lastpage,firstTooltip:vocabulary.firstpage,labelRowsSelect:vocabulary.rowsLabel},toolbar:{searchPlaceholder:vocabulary.search} }}

            columns={[
              { title: vocabulary.name_label, field: 'name', render: rowData => <>{JSON.parse(rowData.name).name}</> },
              { title: vocabulary.mobile, field: 'mobile' },
              { title: vocabulary.email, field: 'email' },
              { title: vocabulary.status, field: 'status' },
              { title: vocabulary.address, field: 'address',render: rowData => <>{JSON.parse(rowData.address).address}</> },
            ]}
            options={{
              actionsColumnIndex: -1,
              search: true

            }}
          />

          
        </div>
      </StyledCard>


      <Dialog
        maxWidth='md'
        open={openLatestNotifs}
        onClose={handleCloseLatestNotifs}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{vocabulary.lastNotifs}</DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            {currentRow.length > 0 ? currentRow.map(e => {
              return (
                <>
                  <ListItem>
                    <ListItemText primary={e.name} />
                    <ListItemText primary={e.content} secondary={e.created_at} />
                    <Divider variant="fullWidth" component="li" absolute={true} />
                  </ListItem>
                </>
              )
            }
            )
              : null
            }
          </List>
        </DialogContent>
      </Dialog>
    </>
  );

}

// ListActiveAndAvailableOrganizations.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default ListActiveAndAvailableOrganizations;
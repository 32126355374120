/* eslint-disable eqeqeq*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { vocabulary } from '../Strings';
import { getCultureByFamilyList, GetCultureFamilliesList, WaterNeedOnDemand } from "../../globalsTools/network";
import { setCurrentPage } from '../../actions';
import { useForm } from 'react-hook-form';
import StyledSelect from "../../ui/forms/StyledSelect";
import StyledInput from "../../ui/forms/StyledInput";
import SeabexMap from '../Sites/Map/SeabexMap'
import { DatePicker } from "@material-ui/pickers";
import StyledModalFooter from '../../ui/layout/StyledModalFooter';
import moment from 'moment';
import { FormControl, InputLabel, MenuItem, RadioGroup, FormControlLabel, Radio } from '@material-ui/core/';
import Select2 from '@material-ui/core/Select';
import { useSnackbar } from 'notistack';
import WaterNeedOnDemandCard from './WaterNeedOnDemandCard'

const WaterNeedForm = () => {

    const { register, handleSubmit, errors } = useForm()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()

    const [cultureFamilyId, setCultureFamilyId] = useState('')
    const [cultureList, setCultureList] = useState(null)
    const [id_culture, setIdCulture] = useState(null)
    const [depth, setDepth] = useState('')
    const [cultureFamilyList, setCultureFamilyList] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [polygone, setPolygone] = useState(null)
    const [recomndedDevCycle, setRecomndedDevCycle] = useState('recommended')
    const [kcValue, setKcValue] = useState(null)
    const [devCyleIndex, setDevCyleIndex] = useState(null)
    const [waterNeedOnDemandList, setWaterNeedOnDemandList] = useState(null)

    useEffect(() => {

        GetCultureFamilliesList(setCultureFamilyList)
        dispatch(setCurrentPage(vocabulary.waterNeedOnDemand))
        document.title = vocabulary.waterNeedOnDemand

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    const ResetSelection = () => {

        setCultureFamilyId('')
        setIdCulture(null)
        setStartDate(null)
        setDepth('')
        setKcValue(null)
        setPolygone(null)
        setWaterNeedOnDemandList(null)
        setRecomndedDevCycle('recommended')
        setDevCyleIndex(null)
    }
    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };
    const validForm = () => {
        if (!polygone || !id_culture || !startDate || !depth) {
            return true
        }
        else return false

    }
    const operationCallBack = (r) => {
        if (r) {
            if (r.status === 200) {
                setWaterNeedOnDemandList(r.data)

            }

            else {
                snackbar(vocabulary.errorOccurred + " " + r.status, 'error');
            }
        }
    }
    const getPolygone = (geometry) => { // callBack function to get polygone from seabexMap
        setPolygone(geometry)
    }
    const formatPolygone = revMap => {
        if (revMap && revMap.geometry && revMap.geometry.coordinates) {
            let formattedLastCreatedLayer = revMap
            formattedLastCreatedLayer.geometry.coordinates[0] = revMap.geometry.coordinates[0].map(item => [item[1], item[0]])
            return formattedLastCreatedLayer
        }
    }
    const FindWaterNeed = async () => {
        await WaterNeedOnDemand(
            {
                polygone: await formatPolygone(polygone),
                cycle: {
                    production_params: JSON.stringify({ 'depth': depth, 'unit': 'm' }),
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    cycle: JSON.stringify(JSON.parse(cultureList.find(item => item.id === id_culture).growth_duration)[devCyleIndex]),
                },
                kc: kcValue,
                prm_Culture_id: id_culture,
                type: kcValue == null ? 'ANONYME_CLIENT_WITH_BOUNDARY' : 'ANONYME_CLIENT_WITH_KC'
            }, async r => await operationCallBack(r)

        )
        //   resetDemand()
    }

    const handleChangeDevCycle = event => {
        setRecomndedDevCycle(event.target.value);
    };
    const actions = [
        { title: vocabulary.send, color: "primary", position: 'end', type: "submit", disabled: validForm() },
        { title: vocabulary.reset, color: "secondary", position: 'start', onClick: () => ResetSelection() }
    ]



    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '10px' }}>
            <div style={{ width: '100%' }}>
                <SeabexMap
                    waterNeedProp={true}
                    getPolygone={getPolygone}
                    layersDataInitial={polygone && polygone}


                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>

                <form style={{ width: '100%' }} onSubmit={handleSubmit(FindWaterNeed)}>

                    <StyledSelect
                        value={cultureFamilyId}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.family}
                        onChange={(event) => {
                            setCultureFamilyId(event.target.value)
                            getCultureByFamilyList(event.target.value, setCultureList)
                        }}
                        render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
                        name='family'
                        label={vocabulary.cultureFamily}
                        fullWidth
                        data={cultureFamilyList && cultureFamilyList.data}
                        valueField='id'
                        styles={{ width: '100%' }}
                    />
                    <br />

                    {cultureFamilyId && cultureList ?

                        <StyledSelect
                            value={id_culture}
                            register={register}
                            rules={{ required: true }}
                            errors={errors.Culture}
                            onChange={(event, index) => {
                                setIdCulture(event.target.value)
                            }}
                            name='Culture'
                            label={vocabulary.culture}
                            fullWidth
                            render={(item) => { return JSON.parse(item['name'])['name_' + currentLanguage] }}
                            data={cultureList}
                            valueField='id'
                            labelField='name'
                            styles={{ width: '100%' }}
                        />

                        : null
                    }
                    {id_culture &&
                        <RadioGroup aria-label="gender" name="devCycle" style={{ display: 'flex', flexDirection: 'column' }} value={recomndedDevCycle} onChange={handleChangeDevCycle}>
                            <FormControlLabel value="recommended" control={<Radio />} label={vocabulary.RecommendedProductionCycle} />
                            <FormControlLabel value="choosen" control={<Radio />} label={vocabulary.customizeProductionCycle} />
                        </RadioGroup>
                    }

                    {recomndedDevCycle == 'choosen' &&
                        <StyledInput
                            name={'KC'}
                            label={vocabulary.kc}
                            value={kcValue}
                            onChange={(event) => {
                                setKcValue(event.target.value)
                            }}
                            register={register}
                            errors={errors.KC}
                            fullWidth
                        />
                    }
                    {(id_culture && recomndedDevCycle == 'recommended') ?
                        <FormControl required
                            style={{ margin: '8px', width: '100%' }}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">{vocabulary.productionCycle}</InputLabel>
                            <Select2
                                value={devCyleIndex}
                                label={vocabulary.productionCycle}
                                onChange={(event) => {
                                    setDevCyleIndex(event.target.value)
                                }}
                                name='devCyleIndex'
                            >
                                {
                                    JSON.parse((cultureList).find(el => el.id === id_culture).growth_duration).map((item, index) => {
                                        return (

                                            < MenuItem key={index} value={index} >
                                                {Object.keys(item).map(key => vocabulary[key] + ": " + item[key] + " ")}
                                            </MenuItem>)
                                    }


                                    )
                                }

                            </Select2>
                        </FormControl>

                        : null
                    }

                    <br />
                    <DatePicker
                        label={vocabulary.startDate}
                        value={startDate}
                        onChange={setStartDate}
                        animateYearScrolling
                        disableFuture
                        style={{ margin: '8px', width: '100%' }}
                    />
                    <br />
                    <StyledInput

                        name={"Depth"}
                        label={vocabulary.depth}
                        value={depth}
                        onChange={(event) => {
                            setDepth(event.target.value)
                        }}
                        register={register}
                        rules={{ required: true }}
                        errors={errors.Depth}
                        fullWidth
                    />

                    <StyledModalFooter actions={actions} />


                </form>
            </div>
            {waterNeedOnDemandList &&
                <WaterNeedOnDemandCard
                    waterNeedOnDemandData={waterNeedOnDemandList}
                />
            }



        </div>
    )
}
export default WaterNeedForm
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
// import compose from 'recompose/compose';
// import { withStyles } from '@material-ui/core/styles';
// import { styles } from '../globalStyle'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentLanguage, loadIrrigationPlans, setCurrentPage } from '../../actions';
import { vocabulary } from '../Strings';
import ReactDragListView from 'react-drag-listview'



import DataTablePage from './DataTable/ScenarioDataTable.jsx';


    // const getListStyle = isDraggingOver => ({
    //     background: isDraggingOver ? "lightblue" : "lightgrey",
    //     padding: grid,
    //     width: 250
    // });

    // const getItemStyle = (isDragging, draggableStyle) => ({
    // // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // // styles we need to apply on draggables
    // ...draggableStyle
    // });
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };



class CustomPaginationActionsTable extends React.Component {

        constructor(props) {
        super(props);
        this.state = {
        page: 0,
        rowsPerPage: 10,
        open: false,
        data: []
        }
    }




    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };


        //  this will triger after a props changes not a state  very usefull :D !!!!!!!!!!!!!!!!!!!!!
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.scenario) {
            this.setState({
                data: nextProps.scenario,
            })
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const data = reorder(
          this.state.data,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          data
        });
    }

    render() {
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const { data } = that.state;
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ data });
                that.props.setScenariosPriority({data: that.state.data},null);   
            },
            nodeSelector: 'li',
            handleSelector: 'a'
            };
        return (

            <Paper elevation={2}  style={{margin:'10px'}} >

                {/* <AddCronJob onClose={this.handleClose} open={this.state.open} /> */}
                <div> 
                        {this.state.data ? 
                    <ReactDragListView {...dragProps} >
                        {/* <ol>
                            {
                                this.state.data.map((item, index) => (
                                    <li key={index} style={{ color: item.status == "Enabled" ? "green" : "red" , display: "flex", justifyContent: 'flex-start'}}> 
                                        <div className="scenarioTab" style={{width:'380px'}} >
                                        <div className="scenarioElement" style={{border:'1px solid '},{display:'flex'}, {justifyContent:'space-around'}}>{ index +1 } {item.title} {item.status} {item.description}                                        </div>

                                        </div>
                                        <div>
                                            <a style={{marginLeft:'87px'}} href="#"><img src ={drag} style={{  width: 20, marginRight: 20 }}/></a>
                                        </div>
                                        <div></div>
                                    </li>
                                ))
                            }
                        </ol> */}
                        
                        <DataTablePage 
                        data={this.props.scenario} 
                        next_url={this.props.next_url} 
                        previous_url={this.props.previous_url}
                         nbrepage={this.props.nbrepage}
                         currentPage={this.props.currentPage}
                         />

                        
                    </ReactDragListView>
                : null 
                }




                {/* <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            >
                            {this.state.data.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                    >
                                    { index +1 }. {item.title}
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> */}
                </div>
                {/* <div >
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>{vocabulary.title}</TableCell>
                                <TableCell >{vocabulary.description}</TableCell>
                                <TableCell >{vocabulary.status}</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.scenario.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                                return (
                                    <TableRow key={row.id} onClick={() => {
                                        this.handleClickOpen()
                                        this.setState({
                                            item: row
                                        })

                                    }}>
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell >{row.description}</TableCell>
                                        <TableCell >
                                            { row.status== "Enabled" ?
                                                <Chip
                                                    // icon={<CloudIcon className={classes.colorTextChips} />}
                                                    label={row.status}
                                                    className={[classes.chip, classes.colorAction, classes.colorTextChips]}
                                                    // onDelete={this.handleDelete}
                                                />
                                                :
                                                <Chip
                                                    // icon={<CloudIcon className={classes.colorTextChips} />}
                                                    label={row.status}
                                                    className={[classes.chip, classes.colorTextChips]}
                                                    // onDelete={this.handleDelete}
                                                    color="secondary"
                                                /> }

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 48 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    colSpan={6}
                                    count={scenario.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>

                    <ScenarioForm
                        open={this.state.open}
                        onClose={() => {
                            this.handleClose()
                        }}
                        item={this.state.item}
                        isEditing={true}

                    />

                </div> */}
            </Paper>
        );
    }
}

// CustomPaginationActionsTable.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

function mapStateToProps(state) {
    vocabulary.setLanguage(state._globalState.preferred_language);

    return {
        currentLanguage: state._globalState.preferred_language,
        scenario: state.scenario.data,
        next_url:state.scenario.next_url,
        previous_url:state.scenario.previous_url,
        nbrepage:state.scenario.nbrepage,
        currentPage:state.scenario.currentPage
    }
}


function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setCurrentLanguage,
        loadIrrigationPlans,
        setCurrentPage,
   
    }, dispatch);
}



export default CustomPaginationActionsTable;




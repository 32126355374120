/* eslint-disable eqeqeq*/
/* eslint-disable no-unused-vars*/

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GetMeteoData } from '../../globalsTools/network';
// import TodayIcon from '@material-ui/icons/Today';

import HumidityIcon from '../../images/weatherIconPack/040-humidity.svg';
// import SunriseIcon from "../../images/weatherIconPack/021-sunrise.svg";
// import SunsetIcon from "../../images/weatherIconPack/017-sunset.svg";
import WindIcon from '../../images/weatherIconPack/037-wind.svg';
import PressureIcon from '../../images/weatherIconPack/000-gauge.svg';
import DewPointIcon from '../../images/weatherIconPack/041-dewpoint.svg';
import UVIcon from '../../images/weatherIconPack/039-UVIndex.svg';
import TemperatureIcon from '../../images/weatherIconPack/001-thermometer.svg';
import RainIcon from '../../images/weatherIconPack/014-rain.svg';
// import CloudIcon from "../../images/weatherIconPack/009-cloud.svg";

// import thermometerS from '../../images/thermometerS.png';
// import sunrise from '../../images/sunrise.png';
// import sunset from '../../images/sunset.png';
// import iconWindDirection from '../../images/winddirection.png'
// import iconWindSpeed from '../../images/windCopy.png'
// import pressure from '../../images/pressure.png'
// import cloudComputing from '../../images/cloud-computing.png'
// import time_zone from '../../images/time_zone.png'
// import iconDewPoint from '../../images/dewpoint.png'

import { vocabulary } from '../Strings';
// import ReactSpeedometer from "react-d3-speedometer"
// import InputLabel from '@material-ui/core/InputLabel';
// import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { ExpandMore } from '@material-ui/icons';

// import { StyledCard } from "../../ui/layout";
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import { ReportRounded } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, CardContent, CardHeader, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import '@fontsource/roboto-condensed';
import { StyledCard } from '../../ui/layout';
import { TemperatureEvolutionOverDay } from './Weather/TemperatureEvolutionOverDay';
import { getFormattedDate, getFormattedDateTime } from '../../globalsTools/utilities';

var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderRadius: 13,
    },
    valueLineMeteo: {
        padding: 10,
        fontWeight: 400,
        fontSize: 18,
        color: 'black',
    },
}));

const TodayWeather = ({ areaId }) => {
    const classes = useStyles();

    const moment = require('moment');
    const currentLanguage = useSelector((state) => state._globalState.preferred_language);

    const [weatherDataArea, setWeatherDataArea] = useState(null);
    const [todayWeatherData, setTodayWeatherData] = useState(null);
    const [weekWeatherData, setWeekWeatherData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [weatherParams, setWeatherParams] = useState(null);

    // const [dailyWeather, setDailyWeather] = useState(null);
    // const [weatherData, setWeatherData] = useState(null);
    // const [allHourly, setAllHourly] = useState([]);
    // const [dailyDate, setDailyDate] = useState(null)

    useEffect(() => {
        setLoading(true);
        GetMeteoData(areaId, setWeatherDataArea);
    }, [areaId]);

    useEffect(() => {
        setLoading(false);

        if (weatherDataArea) {
            setTodayWeatherData(weatherDataArea.currently);
            setWeekWeatherData(weatherDataArea.data);
        }
    }, [weatherDataArea]);

    // useEffect(() => {
    //     if (todayWeatherData && todayWeatherData.length > 0) {
    //         let todaydata = JSON.parse(todayWeatherData[0].data)
    //         setWeatherData(todaydata)
    //     }
    // }, [todayWeatherData])

    useEffect(() => {
        if (todayWeatherData) {
            let data = todayWeatherData
            setWeatherParams([
                {
                    icon: TemperatureIcon,
                    value: data.temperature.value,
                    unit: '°C',
                    description: vocabulary.temperature,
                    comment: '',
                },
                {
                    icon: HumidityIcon,
                    value: Number(data.relativeHumidity.value).toFixed(2) ,
                    unit: '%',
                    description: vocabulary.humidity,
                    comment: '',
                },
                {
                    icon: WindIcon,
                    value: Number(data.windSpeed.value * 3.6).toFixed(2),
                    unit: vocabulary.windUnit,
                    description: vocabulary.windSpeed,
                    comment: windSpeed(data.windSpeed)['name_' + currentLanguage],
                    windDirection: windDirection(data.windGust)['name_' + currentLanguage]
                },

                {
                    icon: UVIcon,
                    value: data.uvIndex.value,
                    unit: '',
                    description: vocabulary.uvIndex,
                    comment: uvIndexMessage(data.uvIndex),
                },
                {
                    icon: DewPointIcon,
                    value: data.dewPoint.value,
                    unit: '°C',
                    description: vocabulary.dewPoint,
                },
                {
                    icon: PressureIcon,
                    value: Number(data.pressure.value / 10).toFixed(2),
                    unit: 'kPa',
                    description: vocabulary.pressureLabel,
                },
                {
                    icon: RainIcon,
                    value: Number(data.totalPrecipitation.value).toFixed(2),
                    unit: 'mm',
                    description: vocabulary.rainPrecipitation,
                },
            ]);
        }
    }, [todayWeatherData, currentLanguage]);
    

    const uvIndexMessage = (index) => {
        if (index < 2) {
            return vocabulary.lowLabel;
        } else if (index < 5) {
            return vocabulary.moderateLabel;
        } else if (index < 7) {
            return vocabulary.highLabel;
        } else if (index < 10) {
            return vocabulary.veryHighLabel;
        } else if (index < 20) {
            return vocabulary.extremeLabel;
        }
    };
    const windDirection = (angle) => {
        if (angle < 22)
            return {
                lettre: "N",
                name_ar: "شمال",
                name_en: "North",
                name_fr: "Nord"
            }
        else if (angle < 67)
            return {
                lettre: "NE",
                name_ar: "شمال شرق",
                name_en: "North East",
                name_fr: "Nord Est"
            }
        else if (angle < 112)
            return {
                lettre: "E",
                name_ar: "شرق",
                name_en: "East",
                name_fr: "Est"
            }
        else if (angle < 157)
            return {
                lettre: "SE",
                name_ar: "جنوب شرق",
                name_en: "South East",
                name_fr: "Sud Est"
            }
        else if (angle < 212)
            return {
                lettre: "S",
                name_ar: "جنوب",
                name_en: "South",
                name_fr: "Sud"
            }
        else if (angle < 247)
            return {
                lettre: "SW",
                name_ar: "جنوب غرب",
                name_en: "South West",
                name_fr: "Sud Ouest"
            }
        else if (angle < 292)
            return {
                lettre: "W",
                name_ar: "غرب",
                name_en: "West",
                name_fr: "Ouest"
            }
        else if (angle < 337)
            return {
                lettre: "NW",
                name_ar: "شمال غرب",
                name_en: "North West",
                name_fr: "Nord Ouest"
            }
        else
            return {
                lettre: "N",
                name_ar: "شمال",
                name_en: "North",
                name_fr: "Nord"
            }
    }

    const windSpeed = (speed) => {
        if (speed < 2)
            return {
                name_ar: 'هادئ',
                name_en: 'Calm',
                name_fr: 'Calme',
            };
        else if (speed >= 2 && speed < 4)
            return {
                name_ar: 'هادئ',
                name_en: 'Light Air',
                name_fr: 'Air léger',
            };
        else if (speed >= 4 && speed < 8)
            return {
                name_ar: 'نسيم عليل',
                name_en: 'Light Breeze',
                name_fr: 'Air léger',
            };
        else if (speed >= 8 && speed < 13)
            return {
                name_ar: 'نسيم لطيف',
                name_en: 'Gentle Breeze',
                name_fr: 'Petite brise',
            };
        else if (speed >= 13 && speed < 18)
            return {
                name_ar: 'مناخ معتدل ',
                name_en: 'Moderate climate',
                name_fr: 'Climat tempéré ',
            };
        else if (speed >= 18 && speed < 25)
            return {
                name_ar: 'هواء نقي ',
                name_en: 'Fresh Breeze',
                name_fr: ' Brise fraîche',
            };
        else if (speed >= 25 && speed < 31)
            return {
                name_ar: 'نسيم قوي',
                name_en: 'Strong Breeze',
                name_fr: ' Brise forte',
            };
        else if (speed >= 31 && speed < 39)
            return {
                name_ar: 'بالقرب من العاصفة',
                name_en: 'Near Gale',
                name_fr: 'Près de Gale',
            };
        else
            return {
                name_ar: 'هادئ',
                name_en: 'Calm',
                name_fr: 'Calme',
            };
    };

    const MeteoItem = ({ icon, value, unit, description, comment, windDirection }) => {
        return (
            <Grid item style={{ flex: `1 1 0px` }}>
                <Paper elevation={2} style={{ padding: 8, height: 140 }}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <b>{description}</b>
                            </div>
                        </Grid>
                        <Grid item>
                            <img src={icon} alt={description} width={48} length={48} />
                        </Grid>
                        <Grid className={classes.valueLineMeteo}>
                            {value} {unit}
                        </Grid>
                        {/* <Grid item></Grid> */}
                        <Grid item style={{ textAlign: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <span style={{textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{(windDirection ? comment + " - "+ windDirection : comment)}</span>
                                {/* <span>{windDirection}</span> */}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    };

    return (
        <StyledCard direction='column'>
            <CardHeader title={
                <div style={{ display: 'flex' }}>
                    <Typography variant="h5" component="h5">
                        {vocabulary.WeatherToday} 
                    </Typography>
                    <Typography variant="h5" component="h5" style={{ fontSize: '16px', fontWeight: '500', color: '#757575', display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
                        @ { todayWeatherData && getFormattedDateTime(todayWeatherData.datetime,currentLanguage) }
                    </Typography>
                </div>
            }
            />
            <CardContent>
                { (!loading) ? (
                    weatherDataArea ? (
                        <>
                            <Grid container style={{ height: 160 }} spacing={2} cols={5}>
                                <>
                                    {weatherParams && weatherParams.map((item, index) => {
                                        return (
                                            <MeteoItem
                                                key={'meteoItem' + index}
                                                icon={item.icon}
                                                value={item.value}
                                                unit={item.unit}
                                                description={item.description}
                                                comment={item.comment}
                                                windDirection={item.windDirection ? item.windDirection : ''}
                                            />
                                        );
                                    })}
                                </>
                            </Grid>

                        { weekWeatherData && 
                            <Accordion
                                elevation={8}
                                style={{ borderRadius: '10px', marginTop: '10px' }}
                                defaultExpanded={true}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography id='cultureName'>{vocabulary.temperatureDewPointWeekLabel}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TemperatureEvolutionOverDay entries={weekWeatherData.slice(0, 168)} />
                                </AccordionDetails>
                            </Accordion> }
                        </>
                    ) : (
                            <span>
                                <ReportRounded />{' '}
                                <Typography>{vocabulary.noDataAvailable}</Typography>
                            </span>
                    )
                ) :
                <>
                    <StyledCircularProgress
                        size={50}
                        label={vocabulary.loading + vocabulary.WeatherToday}
                    />                
            </> }

                {/* </div> */}
            </CardContent>
        </StyledCard>
    );
};
export default TodayWeather;

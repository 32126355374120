/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq*/

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { vocabulary } from '../Strings';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import {
  WaterNeedByDate,
  DownloadWaterNeedHistoryExcel,
  WaterNeedByProductionCycleByDate,
} from '../../globalsTools/network';
// import WaterNeedChart from '../Charts/LineChart/WaterNeedCharts'
import SearchIcon from '@material-ui/icons/Search';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Switch,
  Paper,
} from '@material-ui/core/';
import ComposedCharts from '../Charts/ComposedCharts/ComposedCharts';
import StyledLoadingRow from '../../ui/display/StyledLoadingRow';
import StyledMaterialTable from './../../ui/display/StyledMaterialTable';
import WaterNeedParamsItem from './WaterNeedParamsItem';
import { ModalContext } from '../../ui/layout/contextLayout/ModalContext';
import Excel from '../../images/icons8-xls-32.png';
import { useSnackbar } from 'notistack';
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';

import AddIrrigationForm from '../Irrigation/AddIrrigationForm';

import _ from 'lodash';
import { LibraryAdd, PlusOneRounded } from '@material-ui/icons';
import MultipleIrrigationForm from '../Irrigation/MultipleIrrigationForm';
import { DEV_NETIRRIG_ENVIRONMENT, NETIRRIG_ENVIRONMENT } from '../globalConsts';
import ComposedChartsNetirrig from '../Charts/ComposedCharts/ComposedChartsNetirrig';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { logger } from '../../globalsTools/utilities';
import { REACT_APP_ENV_VERSION } from '../../config';

const WaterNeedHistory = ({
  usedUnit,
  productionCycle,
  areaName,
  onePagerView,
  cycleDetail,
}) => {

  const currentLanguage = useSelector((state) => state._globalState.preferred_language);
  // const recalculatingCycles = useSelector(state => state.cycle.recalculatingCycles)
  // const currentRecalculatedCycle = recalculatingCycles.includes(cycleDetail?.production_cycle?.id)
  const { enqueueSnackbar } = useSnackbar();

  const [dateFrom, setDateFrom] = useState(
    cycleDetail && cycleDetail.production_cycle && cycleDetail && cycleDetail.production_cycle.start_date && moment(cycleDetail && cycleDetail.production_cycle.start_date).format(
      'YYYY-MM-DD'
    )
  );
  const [dateTo, setDateTo] = useState(
    moment(new Date()).add(14, 'days').format('YYYY-MM-DD')
  );
  const [waterNeedByDateList, setWaterNeedByDateList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [recalculate, setRecalculate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typeOfView, setTypeOfView] = useState(false);
  const [fileName, setFileName] = useState('_waterneed_data_');
  const [tableData, setTableData] = useState([])

  const handleDateChange = (key) => (date) => {
    key === 'dateFrom' ? setDateFrom(date) : setDateTo(date);
  };

  const convertToUsedUnit = useCallback(
    (value) => {
      return usedUnit == vocabulary.hectarMcube ? Number(Number(value).toFixed(2) * 10) : Number(Number(value).toFixed(2))
    },
    [usedUnit],
  )


  useEffect(() => {
    if ( waterNeedByDateList ) {
      setTableData(waterNeedByDateList.map(rowData => ({
        date: currentLanguage == 'en'
          ? moment(rowData.calculation_date)
            .locale(currentLanguage)
            .format('YYYY-MM-DD')
          : moment(rowData.calculation_date)
            .locale(currentLanguage)
            .format('DD-MM-YYYY'),

        kc: Number(convertToUsedUnit(rowData.kc)).toFixed(2),
        total_precipitation: Number(convertToUsedUnit(rowData.total_precipitation)),
        irrigation_volume: Number(convertToUsedUnit(rowData.irrigation_volume)).toFixed(2),
        drainage: Number(convertToUsedUnit(rowData.drainage)).toFixed(2),
        reference_evapotranspiration: Number(convertToUsedUnit(rowData.reference_evapotranspiration)).toFixed(2),
        actual_evapotranspiration: Number(convertToUsedUnit(rowData.actual_evapotranspiration)).toFixed(2),
        field_capacity: Number(convertToUsedUnit(rowData.field_capacity)).toFixed(2),
        max_rfu: Number(convertToUsedUnit(Number(rowData.field_capacity) - Number(rowData.bas_rfu))).toFixed(2),
        rfu: Number(convertToUsedUnit(rowData.soil_water_volume)).toFixed(2),
        reserve_survi_mm: Number(convertToUsedUnit(rowData.reserve_survi_mm)).toFixed(2),
        seuil_declenchement: Number(convertToUsedUnit(rowData.total_etr) + convertToUsedUnit(rowData.bas_rfu)).toFixed(2), // calculate the x total of etr
      })))
    }
  }, [waterNeedByDateList, convertToUsedUnit, currentLanguage])


  const FindWaterNeedHistory = () => {
    setLoading(true);
    setWaterNeedByDateList(null);
    WaterNeedByProductionCycleByDate(
      productionCycle?.id,
      moment(dateFrom).format('YYYY-MM-DD'),
      moment(dateTo).format('YYYY-MM-DD'),
      setWaterNeedByDateList
    );
  };

  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const changeModalStatus = (newStatus) => {
    if (newStatus == null) {
      setOpenAdd(!openAdd);
    } else {
      setOpenAdd(newStatus);
    }
  };

  const snackbar = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  useEffect(() => {
    FindWaterNeedHistory();
  }, []);

  useEffect(() => {
    if (recalculate == true) {
      FindWaterNeedHistory();
    }
  }, [recalculate]);

  useEffect(() => {
    if (waterNeedByDateList) {
      setLoading(false);
    }
  }, [waterNeedByDateList]);

  const getSumOf = (fieldname) => {
    if (
      waterNeedByDateList != null &&
      typeof waterNeedByDateList === 'object'
    ) {
      return _.sumBy(waterNeedByDateList, (o) =>
        Number(o[fieldname])
      ).toFixed(2);
    }
    return null;
  };

  const contextValueAdd = {
    open: openAdd,
    changeModalStatus: changeModalStatus,
  };

  useEffect(() => {
      if(dateFrom && dateTo && productionCycle) {
        setFileName(_.camelCase(_.deburr(areaName ?? productionCycle.area_id)) + "_data_from_" + moment(dateFrom).format('YYYY-MM-DD').toString() + "_to_" + moment(dateTo).format('YYYY-MM-DD').toString())
      }
  }, [areaName, productionCycle, dateFrom, dateTo])


  return (
    <>
      <ModalContext.Provider value={contextValueAdd}>
        <Accordion
          className='onePagerWaterNeedHistory'
          elevation={8}
          defaultExpanded={true}
          style={{ borderRadius: '10px', marginTop: '20px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography id='cultureName'>
              {vocabulary.waterNeedHistory}{' '}
            </Typography>
          </AccordionSummary>

          <AccordionDetails style={{
            // cursor: currentRecalculatedCycle ? 'wait' : 'default',
            // opacity: currentRecalculatedCycle ? '0.1' : 1,
            position: 'relative'
          }}>
            <Grid container direction='column'>
              <Grid
                container
                direction='row'
                spacing={2}
                justifyContent='space-between'
                style={{ marginBottom: 16 }}
              >
                <Grid item
                // direction='row'
                >
                  <DatePicker
                    value={dateFrom}
                    onChange={handleDateChange('dateFrom')}
                    label={vocabulary.startDate + " AAAA/MM/JJ"}
                    format='YYYY/MM/DD'
                    maxDate={moment()}
                    minDate={
                      cycleDetail &&
                      moment(
                        cycleDetail && cycleDetail.production_cycle && cycleDetail.production_cycle.start_date
                      ).format('YYYY-MM-DD')
                    }
                    style={{ marginInlineEnd: 20 }}
                  />
                  <DatePicker
                    value={dateTo}
                    onChange={handleDateChange('dateTo')}
                    label={vocabulary.endDate + " AAAA/MM/JJ"}
                    format='YYYY/MM/DD'
                    minDate={dateFrom}
                    maxDate={moment().add(14, 'd')}
                    style={{ marginInlineEnd: 20 }}
                  />

                  <Button
                    style={{ marginInlineEnd: 12, height: 36 }}
                    // variant="contained"
                    color='primary'
                    type='submit'
                    onClick={() => FindWaterNeedHistory()}
                    startIcon={<SearchIcon />}
                  >
                    {vocabulary.search}
                  </Button>
                </Grid>
                <Grid
                  item
                  // direction='row'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    style={{ marginInlineEnd: 12, height: 36 }}
                    // variant="contained"
                    color='primary'
                    startIcon={<LibraryAdd />}
                    onClick={() => handleClickOpen()}
                  >
                    {vocabulary.addIrrigation}
                  </Button>
                </Grid>
                <Grid
                  item
                  // direction='row'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{vocabulary.simplifiedViewLabel}</Typography>
                  <Switch disabled={true} onChange={(event, data) => setTypeOfView(data)} />
                  <Typography>{vocabulary.recommendationsViewLabel}</Typography>
                </Grid>
                {/* <Grid item  direction="row" style={{display:"flex", flexDirection:'row', alignItems:'center', cursor:'pointer'}} onClick={()=> FindWaterNeedHistory()}>
                                    <Typography>Réactualiser</Typography>
                                    <Refresh />
                                </Grid> */}
              </Grid>

              {!loading ? (
                waterNeedByDateList && (
                  <>
                    <Grid
                      item
                      container
                      direction='row'
                      style={{ marginBottom: 16 }}
                      spacing={4}
                    >
                      <WaterNeedParamsItem
                        title={vocabulary.numberWateringCrop}
                        value={
                          cycleDetail &&
                          cycleDetail.production_cycle &&
                          cycleDetail.production_cycle.periodic_irrigation_days
                        }
                        unit='mm'
                        comment='-'
                      />
                      <WaterNeedParamsItem
                        title={vocabulary.totalPrecipitationUnitLabel}
                        value={getSumOf('total_precipitation')}
                        unit='mm'
                        comment='-'
                      />
                      <WaterNeedParamsItem
                        title={vocabulary.totalIrrigationsPerformedLabel}
                        value={getSumOf('irrigation_volume')}
                        unit='mm'
                        comment='-'
                      />
                      <WaterNeedParamsItem
                        title={vocabulary.totalRecommendedIrrigations}
                        value={getSumOf('adjusted_irrigation_volume')}
                        unit='mm'
                        comment='-'
                      />
                      {/* <ParamsItem title="Efficacité de l'irrigation" value="(disponible prochainement)" unit='mm' comment='-' /> */}
                    </Grid>

                    <Grid item style={{ marginBottom: 16 }}>
                      <ComposedChartsNetirrig
                        data={waterNeedByDateList}
                        usedUnit={usedUnit}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        typeOfView={typeOfView}
                        reloadButton={FindWaterNeedHistory}
                      />
                      {/* :
                      <ComposedCharts
                        data={waterNeedByDateList}
                        usedUnit={usedUnit}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        typeOfView={typeOfView}
                      />
                    } */}
                    </Grid>

                    <Grid item style={{ marginBottom: 16 }}>
                      <Accordion
                        elevation={4}
                        defaultExpanded={false}
                        style={{ borderRadius: '10px', marginTop: '20px' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel2a-content'
                          id='panel2a-header'
                        >
                          <Typography>{vocabulary.dataDetailsLabel}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          {!isLoading ? (

(REACT_APP_ENV_VERSION === NETIRRIG_ENVIRONMENT | REACT_APP_ENV_VERSION === DEV_NETIRRIG_ENVIRONMENT) ?

                              <StyledMaterialTable
                                title="Détails des données"
                                data={tableData}
                                localization={{
                                  body: {
                                    emptyDataSourceMessage:
                                      vocabulary.noRecordToDisplay,
                                  },
                                  header: {
                                    actions: vocabulary.actions,
                                  },
                                  pagination: {
                                    nextTooltip: vocabulary.nextpage,
                                    previousTooltip: vocabulary.previouspage,
                                    lastTooltip: vocabulary.lastpage,
                                    firstTooltip: vocabulary.firstpage,
                                    labelRowsSelect: vocabulary.rowsLabel,
                                  },
                                  toolbar: {
                                    searchPlaceholder: vocabulary.search,
                                  },
                                }}
                                columns={[
                                  { title: vocabulary.date, field: "date" },
                                  // { title: "Kc", field: "kc" },
                                  { title: "Pluie", field: "total_precipitation" },
                                  { title: "Irrigation", field: "irrigation_volume" },
                                  { title: "Drainage", field: "drainage" },
                                  // { title: "ETP", field: "reference_evapotranspiration" },
                                  { title: "ETR", field: "actual_evapotranspiration" },
                                  { title: "RU", field: "field_capacity" },
                                  // { title: "Max RFU", field: "max_rfu" },
                                  { title: "RFU", field: "rfu" },
                                  { title: "Réserve de Survie", field: "reserve_survi_mm" },
                                  // { title: "Seuil de déclenchement", field: "seuil_declenchement" },
                                ]}

                                options={{
                                  actionsColumnIndex: -1,
                                  search: false,
                                  paging: false,
                                  exportMenu: [{
                                    label: 'Export PDF',
                                    exportFunc: (cols, datas) => ExportPdf(cols, datas, fileName)
                                  }, {
                                    label: 'Export CSV',
                                    exportFunc: (cols, datas) => ExportCsv(cols, datas, fileName)
                                  }]
                                }}
                              />


                              :

                              <StyledMaterialTable
                                title="Détails des données"
                                data={waterNeedByDateList}
                                columns={[
                                  {
                                    title: vocabulary.date,
                                    field: 'date',
                                    render: (rowData) => (
                                      <>
                                        {moment(rowData.calculation_date)
                                          .locale(currentLanguage)
                                          .format('DD-MM-YYYY')}
                                      </>
                                    ),
                                  },
                                  {
                                    title:
                                      vocabulary.actualEvapotranspiration +
                                      ' (' +
                                      usedUnit +
                                      ')',
                                    field: 'actual_evapotranspiration',
                                    render: (rowData) => (
                                      <>
                                        {Number(
                                          usedUnit == vocabulary.hectarMcube
                                            ? rowData.actual_evapotranspiration ==
                                              null
                                              ? 0
                                              : Number(
                                                rowData.actual_evapotranspiration
                                              ) * 10
                                            : Number(
                                              rowData.actual_evapotranspiration
                                            )
                                        ).toFixed(2)}
                                      </>
                                    ),
                                  },
                                  {
                                    title:
                                      vocabulary.totalPrecipitation +
                                      ' (' +
                                      usedUnit +
                                      ')',
                                    field: 'total_precipitation',
                                    render: (rowData) => (
                                      <>
                                        {rowData.total_precipitation == null
                                          ? Number(
                                            rowData.total_precipitation_intensity
                                          ).toFixed(2)
                                          : usedUnit == vocabulary.hectarMcube
                                            ? Number(
                                              rowData.total_precipitation * 10
                                            ).toFixed(2)
                                            : Number(
                                              rowData.total_precipitation
                                            ).toFixed(2)}
                                      </>
                                    ),
                                  },
                                  {
                                    title:
                                      vocabulary.waterVolume +
                                      ' (' +
                                      usedUnit +
                                      ')',
                                    field: 'irrigation_volume',
                                    render: (rowData) => (
                                      <>
                                        {rowData.irrigation_volume &&
                                          rowData.irrigation_volume == null
                                          ? 0
                                          : usedUnit == vocabulary.hectarMcube
                                            ? (
                                              Number(rowData.irrigation_volume) *
                                              10
                                            ).toFixed(2)
                                            : Number(
                                              rowData.irrigation_volume
                                            ).toFixed(2)}
                                      </>
                                    ),
                                  },
                                  {
                                    title:
                                      vocabulary.evapotranspiration +
                                      ' (' +
                                      usedUnit +
                                      ')',
                                    field: 'referenceEvapotranspiration',
                                    render: (rowData) => (
                                      <>
                                        {Number(
                                          usedUnit == vocabulary.hectarMcube
                                            ? rowData.reference_evapotranspiration ==
                                              null
                                              ? 0
                                              : Number(
                                                rowData.reference_evapotranspiration *
                                                10
                                              ).toFixed(2)
                                            : Number(
                                              rowData.reference_evapotranspiration
                                            )
                                        ).toFixed(2)}
                                      </>
                                    ),
                                  },
                                  {
                                    title:
                                      vocabulary.waterInSoil +
                                      ' (' +
                                      usedUnit +
                                      ')',
                                    field: 'soil_water_volume',
                                    render: (rowData) => (
                                      <>
                                        {rowData.soil_water_volume == null ||
                                          rowData.soil_water_volume === 'NaN'
                                          ? 0
                                          : usedUnit == vocabulary.hectarMcube
                                            ? Number(
                                              rowData.soil_water_volume * 10
                                            ).toFixed(2)
                                            : Number(
                                              rowData.soil_water_volume
                                            ).toFixed(2)}
                                      </>
                                    ),
                                  },
                                ]}

                                options={{
                                  actionsColumnIndex: -1,
                                  search: false,
                                  paging: false,
                                  exportMenu: [{
                                    label: 'Export PDF',
                                    exportFunc: (cols, datas) => ExportPdf(cols, datas, fileName)
                                  }, {
                                    label: 'Export CSV',
                                    exportFunc: (cols, datas) => ExportCsv(cols, datas, fileName)
                                  }]
                                }}


                              />



                          ) : (
                            <StyledCircularProgress
                              size={50}
                              label={vocabulary.loadingFilelogs}
                            />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </>
                )
              ) : (
                <StyledLoadingRow />
              )}
            </Grid>
          </AccordionDetails>
          {/* {
            (currentRecalculatedCycle) && (
              <Typography style={{
                position: 'absolute',
                cursor: 'wait',
                width: "100px",
                heigth: "100px",
                top: '45%',
                left: '43%',
              }}
                variant='h5'
              >...Calculation
              </Typography>
            )
          } */}
        </Accordion>
        {openAdd && (
          <MultipleIrrigationForm
            open={openAdd}
            onClose={() => setOpenAdd(false)}
            initialAreaId={productionCycle.area_id}
            irrigationOnePager={true}
            isEditing={false}
            recalculate={setRecalculate}
          />
        )}
      </ModalContext.Provider>
    </>
  );
};
export default WaterNeedHistory;

/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { vocabulary } from '../Strings';
import { setCurrentPage } from '../../actions';
import { StyledCard } from "../../ui/layout";
import { useDropzone } from 'react-dropzone';
import StyledMaterialTable from '../../ui/display/StyledMaterialTable';
import { GetDataTypeList, GetFileList, GetSourcesList, GetAreasList, GetAreaDetails, sendFile, DeleteFileByAreaId } from "../../globalsTools/network";
import StyledSelect from "../../ui/forms/StyledSelect";
import { Tabs, Tab, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import StyledCircularProgress from '../../ui/display/StyledCircularProgress';
import SourceMangmentList from './SourceManagmentList'
import StyledAutoComplete from "../../ui/forms/StyledAutoComplete";
import StyledSubmitButton from '../../ui/forms/StyledSubmitButton'
import StyledResetButton from '../../ui/forms/StyledResetButton'
import AddIcon from '@material-ui/icons/AddCircleOutline';
import upload from '../../images/upload.png'
import "./styles.css"
import { useForm } from 'react-hook-form';
import Configuration from './Configuration'
import AlertDialog from "../Components/alertDialog";
import xlsxParser from 'xlsx-parse-json';

var dataTypeTable = [];

const UserPreference = () => {

    const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const currentLanguage = useSelector(state => state._globalState.preferred_language)
    const classes = useStyles();
    const form = useRef(null);
    const { register, handleSubmit, errors } = useForm()
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const moment = require('moment');

    const [file, setFile] = useState(null)
    const [dataTypeList, setDataTypeList] = useState(null)
    const [selectedDataTypeId, setSelectedDataTypeId] = useState(null)
    const [isoUnitList, setIsoUnitList] = useState(null)
    const [addFile, setAddFile] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [tab, setTab] = useState(0)
    const [sourceView, setSourceView] = useState(false)
    const [sourceList, setSourceList] = useState(null)
    const [sourceId, setSourceId] = useState(null)
    const [fileReader, setFileReader] = useState(null)
    const [area_id, setArea_id] = useState(null);
    const [selectedArea, setSelectedArea] = useState([]);
    const [parcelsList, setParcelsList] = useState([])
    const [parcelId, setParcelId] = useState(null)
    const [siteList, setSiteList] = useState(null)
    const [formattedData, setFormattedData] = useState([])
    const [columnsData, setColumnsData] = useState([])
    const [loadingCorrespondenceTable, setLoadingCorrespondenceTable] = useState(false)
    const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.userPreferencesLabel))
        document.title = vocabulary.userPreferencesLabel
        GetDataTypeList(setDataTypeList)
        GetSourcesList(setSourceList)
        GetFileList(setFileList)
        GetAreasList(setSiteList)

    }, [dispatch]); // N’exécute l’effet qu'a la première render

    useEffect(() => {
        dispatch(setCurrentPage(vocabulary.userPreferencesLabel))
        document.title = vocabulary.userPreferencesLabel

    }, [dispatch, currentLanguage]); // N’exécute l’effet que si currentLanguage a changé

    useEffect(() => {
        if (fileList.length !== 0)
            fileList.map((rowData) => {

                setFormattedData(formattedData => [...formattedData, {
                    data_source_id: rowData.data_source_id,
                    date: rowData.params !== null && fileListParams(rowData.params)[2],
                    name: rowData.params !== null && fileListParams(rowData.params)[3],
                    area: rowData.area && rowData.area.parent ? (rowData.area.parent.name + ' / ' + rowData.area.name) : rowData.area ? rowData.area.name : '',
                    id: rowData.id,
                    area_id: rowData.area_id
                }])
            })
    }, [fileList])

    const snackbar = (msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    };

    const GetIndex = (dataTypeTable, item) => {

        return dataTypeTable.findIndex(x => x.column === item.column)
    }
    const handleDataTypeChange = (item) => {

        if (dataTypeTable.length < 1) {
            dataTypeTable.push(item)
        }
        else {
            let position = GetIndex(dataTypeTable, item)
            if (position == -1) {
                dataTypeTable.push(item)
            } else {
                dataTypeTable[position] = item
            }

        }

    }

    const extractingDataFromFile = () => {
        if (acceptedFiles.length > 0) {

            setColumnsData([])
            let columnsList = {};
            let objectName = ''
            acceptedFiles.map((file) => {
                if (file.type === '' && file.name.indexOf(".xlsx") !== -1 || file.name.indexOf(".csv") !== -1 || file.name.indexOf(".xls") !== -1 || file.type.indexOf("spreadsheetml.sheet") !== -1) {
                    setFile(file);
                    convertFile(file);
                    xlsxParser
                        .onFileSelection(acceptedFiles[0])
                        .then(data => {
                            var parsedData = data;
                            objectName = Object.getOwnPropertyNames(parsedData)[0]

                            Object.getOwnPropertyNames(parsedData[objectName][0]).map((e, i) => {// Foreach file get columnsList
                                columnsList[String.fromCharCode(65 + i)] = e;
                                setColumnsData(columnsData => [...columnsData, { name: e }
                                ]);
                            });
                        });
                }
                else {
                    snackbar(vocabulary.wrongChoosenXlsxFile, 'error')

                }
            })
        }
    }

    useEffect(() => {
        setLoadingCorrespondenceTable(true)
        extractingDataFromFile()
    }, [acceptedFiles]); // N’exécute l’effet que si acceptedFiles a changé

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            setSourceView(false)
        }
        else {
            setSourceView(true)
            setAddFile(false)
        }
        setTab(newValue)
    }
    const handleClickOpenAdd = () => {
        setAddFile(true)
    }
    const handleClickCloseAdd = () => {
        setAddFile(false)
        setArea_id(null)
        setSourceId(null)
        setColumnsData([])
        setFile(null)
    }
    const convertFile = async (file) => {
        let reader = new FileReader();

        reader.onload = (e) => {
            setFileReader(e.target.result)
        };
        if (file) {
            reader.readAsArrayBuffer(file);
        }

    }

    const operationCallBack = (r) => {
        if (r) {
            setIsLoading(false)

            if (r.status === 200) {
                snackbar(vocabulary.fileSended, 'success')
                setAddFile(false)
                GetFileList(setFileList)


            }
            else if (r.status === 204) {

                snackbar(item.data_source_id + " " + item.area.name + ' ' + vocabulary.deleteMsg, 'success')

                GetFileList(setFileList)

            }
            else {
                snackbar(vocabulary.errorOccurred + " " + vocabulary.serverSideLabel + ' ,  ' + vocabulary.tryAgainLabel, 'error');
            }
        }
    }

    const SendCorrespondenceTable = async () => {
        let formData = new FormData()
        await formData.append('File', new Blob([fileReader]), file.name)
        // await formData.append('File', new Blob([fileReader]), {filename:file.name, contentType:file.type, knownLength:file.size})
        formData.append('area_id', selectedArea.id)
        formData.append('data_source_id', sourceId)
        formData.append('correspondence_table', JSON.stringify(dataTypeTable))
        formData.append('_method', 'POST')

        setIsLoading(true)

        await sendFile(

            formData, r => operationCallBack(r), { 'Content-Type': 'multipart/form-data' })
    }
    useEffect(() => {
        if (selectedArea != null) {
            setSelectedArea(selectedArea)
            categorizeAreas(selectedArea)
        }
    }, [selectedArea]) // N’exécute l’effet que si selectedArea a changé 
    const handleChange = event => {
        GetAreaDetails(event.target.value, setSelectedArea)
    };

    const categorizeAreas = (areasDetails) => {
        switch (areasDetails.type) {
            case 'site':
                setParcelsList(areasDetails["childs"])
                setArea_id(areasDetails.id)

                break;
            case 'parcel':
                setParcelId(areasDetails.id)
                break;
            default:
                return ''

        }
    }

    const fileListParams = (paramsData) => {
        if (paramsData == null) {
            return ["", "", "-", "-"]
        }
        let params = paramsData.split('\/')
      

        params[2] = params[2].replace("\\", "")
        params[3] = params[3]
        return params
    }

    const closeAlertConfirmDelete = () => {
        setAlertConfirmDelete(false);
    };
    const handleDeleteFile = (item) => {
        DeleteFileByAreaId(item.id, item.area_id
            , r => operationCallBack(r)
        )
    }



    const AddItemToPreferenceTable = (dataType, unit) => {
        let item = {}
        item = {
            column: selectedRowIndex,
            field: columnsData[selectedRowIndex].name,
            datatype: dataType,
            iso_unit: unit
        }
       
        handleDataTypeChange(item)
    }


    return (
        <>

            {(!isLoading && dataTypeList) ?
                <>
                    <Paper className={classes.root}
                        elevation={8}
                    >
                        <Tabs style={{ marginBottom: 8, borderRadius: '8px' }}
                            value={tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label={vocabulary.fileManagment} />
                            <Tab label={vocabulary.sourcesManagement} />
                            {/* <Tab label={vocabulary.configurationLabel} /> */}

                        </Tabs>
                    </Paper>
                    <StyledCard>


                        {(sourceView == false && addFile == false) &&
                            <StyledMaterialTable
                                title={vocabulary.fileList}
                                data={formattedData}
                                localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                                columns={[
                                    { title: vocabulary.dataSource, field: 'data_source_id' },
                                    { title: vocabulary.date, field: 'date', render: rowData => <>{currentLanguage == 'en' ? moment(rowData.date).format('YYYY-MM-DD HH:mm') : moment(rowData.date).format('DD-MM-YYYY HH:mm')}</> },
                                    { title: vocabulary.name_label, field: 'name'/*, render: rowData => <>{fileListParams(rowData.params)[3]}</> */ },
                                    { title: vocabulary.area, field: 'area', defaultGroupOrder: 0 /*, render: rowData => <>{fileListParams(rowData.params)[3]}</> */ }


                                ]}
                                actions={[
                                    {
                                        icon: () => <AddIcon fontSize='large' color='primary' />,
                                        tooltip: vocabulary.add,
                                        isFreeAction: true,
                                        onClick: (event) => handleClickOpenAdd(),
                                    },


                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    pageSize: 10,
                                    grouping: true,
                                    pageSizeOptions: [10, 20, 40, 60, 80, 100],


                                }}
                            />

                        }
                        {alertConfirmDelete === true &&
                            <AlertDialog
                                operation={vocabulary.delete}
                                open={alertConfirmDelete}
                                deletedItemName={item.data_source_id}
                                onClose={() => {

                                    closeAlertConfirmDelete();
                                }}
                                confirmeDelete={() => {
                                    if (item) {
                                        handleDeleteFile(item);
                                    }

                                    closeAlertConfirmDelete();
                                }}

                                cancel={() => {
                                    closeAlertConfirmDelete();
                                }}
                            />}

                        {

                            (sourceView == false && addFile == true) &&
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <form
                                    onSubmit={handleSubmit(SendCorrespondenceTable)}
                                    ref={form}>
                                    <div {...getRootProps({ className: 'dropzone' })} style={{ margin: '10px', border: '3px dashed ', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                                        <input {...getInputProps()} />
                                        <div style={{ display: 'flex', alignItems: 'center', width: '70%', cursor: 'pointer' }}>
                                            <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                                                <img src={upload} alt='upload' />
                                            </div>
                                            <div style={{ fontSize: 'large', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {vocabulary.dragDropFileLabel}
                                            </div>
                                        </div>
                                    </div>

                                    {file &&

                                        <ul style={{ margin: '20px', listStyleType: 'none', lineHeight: '4' }}>
                                            <li key={file.path}>
                                                {vocabulary.fileName} : {file.path}

                                            </li>
                                            <li>
                                                {vocabulary.fileSize} : {file.size}   {vocabulary.bytesLabel}
                                            </li>
                                            <StyledSelect
                                                value={area_id}
                                                register={register}
                                                rules={{ required: true }}
                                                errors={errors.Area}
                                                onChange={handleChange}
                                                name='Area'
                                                label={vocabulary.site}
                                                // styles={{ width: '100%' }}
                                                data={siteList && siteList}
                                                valueField='id'
                                                labelField='name'

                                            />
                                            {(parcelsList.length > 0 && area_id) &&
                                                <StyledSelect
                                                    value={parcelId}
                                                    register={register}
                                                    errors={errors.ParcelId}
                                                    onChange={handleChange}
                                                    name='ParcelId'
                                                    label={vocabulary.parcel}
                                                    fullWidth
                                                    data={parcelsList}
                                                    valueField='id'
                                                    labelField='name'
                                                // styles={{ width: '100%' }}

                                                />
                                            }
                                            <StyledSelect
                                                value={sourceId}
                                                register={register}
                                                rules={{ required: true }}
                                                onChange={(event) => { setSourceId(event.target.value) }}
                                                name='source'
                                                label={vocabulary.sourceLabel}
                                                fullWidth
                                                data={sourceList}
                                                valueField='id'
                                                labelField='id'

                                            />
                                            <div style={{ display: 'flex' }}>
                                                <StyledSubmitButton
                                                    label={vocabulary.send}
                                                    styles={{ margin: '5px' }}
                                                />
                                                <StyledResetButton
                                                    label={vocabulary.backLabel}
                                                    styles={{ margin: '5px' }}
                                                    type={'reset'}
                                                    onClick={handleClickCloseAdd}
                                                />
                                            </div>
                                        </ul>
                                    }
                                    {columnsData.length > 0 &&
                                        (columnsData.length > 0 ?
                                            <StyledMaterialTable
                                                title={vocabulary.correspondenceTableLabel}
                                                data={columnsData}
                                                localization={{ body: { emptyDataSourceMessage: vocabulary.noRecordToDisplay }, header: { actions: vocabulary.actions }, pagination: { nextTooltip: vocabulary.nextpage, previousTooltip: vocabulary.previouspage, lastTooltip: vocabulary.lastpage, firstTooltip: vocabulary.firstpage, labelRowsSelect: vocabulary.rowsLabel }, toolbar: { searchPlaceholder: vocabulary.search } }}
                                                columns={[
                                                    { title: vocabulary.colmunsLabel, field: 'name' },
                                                    {
                                                        title: vocabulary.datatype, field: 'prmDataTypeColumn', render: rowData => <>
                                                            {
                                                                <StyledAutoComplete
                                                                    styles={{ width: '100%' }}
                                                                    data={dataTypeList.data}
                                                                    optionLabel={(option) => JSON.parse(option.name).name_ar ? JSON.parse(option.name)['name_' + currentLanguage] : JSON.parse(option.name).name_fr}
                                                                    loading={true}
                                                                    // defaultValue={item && item.prm_data_type}
                                                                    onChange={async (event, newValue) => {
                                                                        await setSelectedDataTypeId(newValue !== null ? newValue.id : null)
                                                                        await setIsoUnitList(newValue ? JSON.parse(newValue.units_array) : [])
                                                                        await AddItemToPreferenceTable(newValue !== null ? newValue.id : null)

                                                                    }}

                                                                    label={vocabulary.datatype}
                                                                />
                                                            }
                                                        </>
                                                    },
                                                    {
                                                        title: vocabulary.isoUnit, field: 'unit', render: rowData =>
                                                            <>{
                                                                <StyledAutoComplete
                                                                    styles={{ width: '100%' }}
                                                                    data={isoUnitList && isoUnitList.length > 0 ? isoUnitList : []}
                                                                    optionLabel={(option) => option.abbr}
                                                                    loading={true}
                                                                    onChange={(event, newValue) => {
                                                                        AddItemToPreferenceTable(selectedDataTypeId, newValue !== null ? newValue.abbr : null)
                                                                    }}
                                                                    label={vocabulary.isoUnit}
                                                                />
                                                            }
                                                            </>
                                                    },

                                                ]}
                                                onRowClick={(event, rowData) => {
                                                    setSelectedRowIndex(rowData.tableData.id)
                                                }}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    search: false,
                                                    paging: false

                                                }}
                                            /> : <StyledCircularProgress size={50} label={vocabulary.loading} />
                                        )
                                    }
                                </form>
                            </div>
                        }

                        {tab == 1 &&
                            <SourceMangmentList
                                dataTypeList={dataTypeList} />
                        }
                        {tab == 2 &&

                            <Configuration />
                        }
                    </StyledCard>
                </>
                : <StyledCircularProgress size={50} label={isLoading ? vocabulary.waitWhileFileIsSending : vocabulary.loading} />

            }
        </>
    )
}
export default UserPreference


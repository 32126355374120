import React from "react";
import {
  Marker,
  Popup,
  LayersControl,
  FeatureGroup,
} from "react-leaflet";
import { vocabulary } from "../../Strings";
import { defaultMarker } from "../../../globalsTools/utilities";
import { iconTypeChange } from "../../Sites/Map/Functions/MapFunctions";

const deviceTypes = ["sensor"];

const DevicesMapping = ({ devices }) => {



  return (

    deviceTypes.map((type) => {
      const typeCount = devices.length;
      return (
        <LayersControl.Overlay key={type} name={`${type.toUpperCase()} (${typeCount})`} checked>
          <FeatureGroup>
            {
              devices
                .map((device) => {
                  if (device.area) {
                    const coordinates = JSON.parse(device.area.center);
                    const position = [coordinates.latitude, coordinates.longitude];
                    return (
                      <Marker
                        key={`marker-${device.id}`}
                        position={position}
                        icon={iconTypeChange('ACTION') || defaultMarker}
                      >
                        <Popup key={`popup-${device.id}`}>
                          <div>
                            <strong>{vocabulary.deviceName}: </strong> {device.name}
                          </div>

                          {
                            device.area && <div>
                              <strong>{vocabulary.site}: </strong> {device.area?.name}
                            </div>
                          }
                        </Popup>

                      </Marker>
                    );
                  }
                }

                )
            }
          </FeatureGroup>
        </LayersControl.Overlay>
      )
    })


  );
};

export default DevicesMapping;


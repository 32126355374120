import React, { useState, useEffect, useRef } from 'react';
import { GetMapImage } from '../../globalsTools/network';

const LazyMapImage = ({ id, base64, styles, height, onClick, width }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imageRef = useRef(null);


    const style = {
        ...styles,
        width: imageSrc ? width : 50,
        height: imageSrc ? height : 50,
        borderRadius: 5,
        cursor: onClick ? 'pointer' : null
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {

                    GetMapImage(id, data => {
                        setImageSrc(data);
                        if (imageRef) observer.unobserve(imageRef.current);
                    })

                }
            });
        });

        if (imageRef) observer.observe(imageRef.current);

        return () => {
            if (imageRef && imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, [id]);


    return (

        <img
            ref={imageRef}
            src={imageSrc ? (base64 ? 'data:image/png;base64, ' + imageSrc : imageSrc) : '/assets/earth.gif'}
            style={style}
            width={width}
            onClick={onClick}
        />
    );
};

export default LazyMapImage;

import React, { useEffect, useState } from 'react';
import { StyledMaterialTable } from '../../../ui/display';
import { GetMeteoStations } from '../../../globalsTools/network';

const MeteoStationsList = () => {

    const [meteoStations, setMeteoStations] = useState([])

    useEffect(() => {
        GetMeteoStations(setMeteoStations)
    }, [])
    

    return (
        <StyledMaterialTable
        title="Liste des Stations Météos"
        data={meteoStations}

        columns={[
            { title:"Reference",    field: 'reference'  },
            { title:"Nom",          field: 'name'       },
            { title:"Latitude",     field: 'latitude'   },
            { title:"Longitude",    field: 'longitude'  },
            
        ]}

        options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
        }}
        />
    )
}

export default MeteoStationsList;
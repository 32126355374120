import React, { useState } from "react";
import { Typography, CircularProgress } from "@material-ui/core";

export default ({ size, label,height, timeout, noDataMsg }) => {
    const [show, setShow] = useState(true)
    const style = {
        width: '100%',
        height: height ? height :'700px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    }
    const noDataStyle = {
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#03465b',
        fontSize: 'x-large',
        width:'100%'
    }
    timeout && setTimeout(() => {
        setShow(false)
    }, 60000);

    return (
        <>
            { show ?
                <div style={style}>
                    <CircularProgress size={size} />
                    <Typography>{label}</Typography>
                </div>
                :
                <div style={noDataStyle}>
                    {noDataMsg}
                </div>
            }
        </>
    )

}


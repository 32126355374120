/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable no-unused-vars*/
/* eslint-disable no-unreachable*/
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq*/

import React, { useEffect, useCallback, useState, useContext } from "react"
import L from "leaflet"
import { useSnackbar } from "notistack";
import { Typography, Fab, Zoom, Grid, Accordion, AccordionDetails, AccordionSummary, Badge, } from "@material-ui/core"
import "leaflet.gridlayer.googlemutant/Leaflet.GoogleMutant"
import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import { isPointInPolygon } from 'geolib'
import "intro.js/introjs.css";
import moment from 'moment';

import { GetAreaDetails, GetAreasList } from "../../../globalsTools/network"
import { StyledCard } from "../../../ui/layout"
import { vocabulary } from "../../Strings";
import { ZoomOut, HourglassEmpty, HighlightOff, AddCircleOutline, LocationSearching, Fullscreen, FullscreenExit, Edit, List } from "@material-ui/icons"
import GotoDialog from "./GotoDialog"
import { useRef } from "react"
import AddLocationIcon from '@material-ui/icons/AddLocation'
import {
    mapStyles, styleMaxSize, iconTypeChange,
    gotoPosition, changeModalStatusGoto, backArrow,
    handleLayersData, latlngJsonToMarker,
    maximizeSize, normalSize, handleAreaSelectionChange, newDrawing,
    disableDrawBar, styleCommands,
    initialiseId, geoJSONInit, markersGroupClick, updateMainLayer, initialiseMap, editingMapEffect, assignOverlays
} from "./Functions/MapFunctions";
import { TYPE_SITE, TYPE_PARCEL, TYPE_SECTOR, IS_ENABLED, CURRENT_STEP, INITIAL_STEP } from "../../globalConsts"
import { GuideContext, steps } from "../../../ui/layout/contextLayout/GuideContext";
import SeabexMapStepper from "./SeabexMapStepper";
import MarkerCoords from "./MarkerCoords";
import { AreaOnePager } from "../AreaOnePager";
import { AreaSatelliteUI } from "../Satellite/AreaSatelliteUI";
import { useSelector } from "react-redux";
import { logger } from "../../../globalsTools/utilities";

const SeabexMap = ({
    layersDataInitial = null,
    getPolygone,
    styles,
    embedded = false,
    getRealPosition,
    selectedAreaId = null,
    waterNeedProp,
    staticMap = false,
    markersDraggable = false,
    goToSite,
    editingItem = null,
    isEditingStation = false,
    initialEditableArea,
    addAreaFn,
    editCallBack,
    stationEditAdd = false,
    previousPosType,
    setEditingItem,
    isEditing = false,
    overlays = null
}) => {
    /**
     * useState
     */
    const [editableArea, setEditableArea] = useState(initialEditableArea)
    const [layersData, setLayersData] = useState([])
    const [loading, setLoading] = useState(false)
    const [editingMap, setEditingMap] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [openGoto, setOpenGoto] = useState(false)
    const [lastCreatedLayer, setLastCreatedLayer] = useState(null)
    const [fullscreen, setFullscreen] = useState(false)
    const [mapStyle, setMapStyle] = useState(null)
    const [currentSelectedSite, setCurrentSelectedSite] = useState(null)
    const [addNewStationMarker, setAddNewStationMarker] = useState(false)
    const [alreadySelectedNewStationMarker, setAlreadySelectedNewStationMarker] = useState(false)
    const [editingStationMarker, setEditingStationMarker] = useState(null)
    const [currentSelectedMarker, setCurrentSelectedMarker] = useState(null)
    const [id, setId] = useState(initialiseId())
    const [disableEvent, setDisableEvent] = useState(true)
    const [spaceClickEvent, setSpaceClickEvent] = useState(null)
    const [prevStepGuide, setPrevStepGuide] = useState(null)
    const [openMarkerCoords, setOpenMarkerCoords] = useState(false)
    const [overlayImage, setOverlayImage] = useState(null)
    const [overlayImageOpacity, setOverlayImageOpacity] = useState(1)


    /**
     *  Defining Main Layer
     * useRef
     */
    const mapRef = useRef();
    const geoJSONLayer = useRef();
    const overlayLayer = useRef();
    const drawnItems = useRef();
    const markersLayer = useRef();
    const controlLayers = useRef();
    const drawControl = useRef();
    const mapContainer = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const { guide, dispatchGuide } = useContext(GuideContext)

    useEffect(() => {
      logger("Editing Item >> ", editingItem)
    }, [editingItem])
    

    /**
     * define map layers
     */
    const googleMapsRoadsLayer = L.gridLayer.googleMutant({
        type: 'roadmap',
        styles: [
            { featureType: 'water', stylers: [{ color: '#444444' }] }
        ],
        // attribution: 'Google Maps (©), for <a href="https://www.seabex.com">Seabex (©)</a>'
    })
    const googleMapsSatelliteLayer = L.gridLayer.googleMutant({
        type: 'satellite',
        //attribution: 'Google Maps (©), for <a href="https://www.seabex.com">Seabex (©)</a>'
    })
    const googleMapsTerrainLayer = L.gridLayer.googleMutant({
        type: 'terrain',
        // attribution: 'Google Maps (©), for <a href="https://www.seabex.com">Seabex (©)</a>'
    })
    const OpenWeatherMapLayer = L.tileLayer("https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=664e6a37414f7380dc6fd77a1a03244e&cities=true", {
        // attribution: 'OpenStreetMap (©), CartoDB (©), for <a href="https://www.seabex.com">Seabex (©)</a>'
    })
    const OpenStreetMapLayer = L.tileLayer("http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png", {
        // attribution: 'OpenStreetMap (©), CartoDB (©), for <a href="https://www.seabex.com">Seabex (©)</a>'

    })

    const EsriWorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {})
    const ThunderforestOutdoors = L.tileLayer('https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png', {})
    const GeoportailFranceOrthos = L.tileLayer('https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}', {})

    const OpenTopoMap = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {});
    const BaseMaps = { OpenWeatherMapLayer, OpenStreetMapLayer, googleMapsRoadsLayer, googleMapsSatelliteLayer, googleMapsTerrainLayer, EsriWorldImagery, ThunderforestOutdoors, GeoportailFranceOrthos, OpenTopoMap }

    const areas_right = useSelector(state => state._globalState.new_areas_right)

    const dragendEvent = e => {
        getRealPosition(e.target._latlng)
        return (e.target._latlng)
    }
    // format the polygon as an entery format required to the function "isPointInPolygon"
    const formatPolygon = (polygon) => {
        return polygon[0].map(item => {
            return { latitude: item[1], longitude: item[0] }
        })
    }

    /**
     * useCallback
     *  */
    const snackbar = useCallback((msg, type) => {
        enqueueSnackbar(msg, { variant: type });
    }, [enqueueSnackbar]);

    const handleGetAreasResult = useCallback(
        (data, result) => {
            if (result == null || result === true) {
                return setLayersData(data)
            } else {
                snackbar("Error Loading Areas List", "error")
            }
        },
        []
    )

    /**
     * useEffect
     */

    /** called on Add/Edit Area */
    useEffect(() => {
        if (editableArea != null) {
            let res = newDrawing(null, editableArea, mapRef.current, drawControl.current, selectedArea, setEditingMap, markersLayer.current, geoJSONLayer.current, geoJSONOptions)
            if (res.map)
                mapRef.current = res.map
            if (res.drawControl)
                drawControl.current = res.drawControl
        }
    }, [editableArea])

    /** update the map layer (used in water need) */
    useEffect(() => {
        setLayersData(layersDataInitial)
    }, [layersDataInitial])

    /** called on Add/Edit Station marker */
    useEffect(() => {
        let markerPos = null
        if (addNewStationMarker && !alreadySelectedNewStationMarker && !previousPosType.properties) {
            //Add new Station Marker with a test that the marker don't pass the area borders
            if (editingStationMarker && editingStationMarker.latlng) {
                getRealPosition(editingStationMarker.latlng)
                let marker = L.marker([editingStationMarker.latlng.lat, editingStationMarker.latlng.lng], { draggable: 'true' }).addTo(mapRef.current).bindPopup("Device type : " + previousPosType)
                    .setIcon(iconTypeChange(previousPosType))
                    .on('dragend', e => {
                        if (isPointInPolygon(dragendEvent(e), formatPolygon(JSON.parse(editingItem.boundary).geometry.coordinates))) {
                            markerPos = dragendEvent(e)
                            getRealPosition(markerPos)
                        } else {
                            marker.setLatLng(markerPos ? markerPos : editingStationMarker.latlng)
                            getRealPosition(markerPos ? markerPos : editingStationMarker.latlng)
                        }
                    })
                setAlreadySelectedNewStationMarker(true)
            }
        }
        else if (previousPosType && previousPosType.properties) {
            //Edit Station Marker with a test that the marker don't pass the area borders
            setAddNewStationMarker(true)
            getRealPosition({ lat: previousPosType.geometry.coordinates[0], lng: previousPosType.geometry.coordinates[1] })
            let markerPos = null
            setTimeout(() => {
                if (mapRef && mapRef.current) {
                    let marker = L.marker(
                        previousPosType.geometry.coordinates, { draggable: 'true' })
                        .addTo(mapRef.current).bindPopup("Device type : " + previousPosType.properties.type.toUpperCase())
                        .setIcon(iconTypeChange(previousPosType.properties.type.toUpperCase())).on('dragend',
                            e => {
                                if (isPointInPolygon(dragendEvent(e), formatPolygon(JSON.parse(editingItem.boundary).geometry.coordinates))) {
                                    markerPos = dragendEvent(e)
                                    getRealPosition(markerPos)
                                } else {
                                    marker.setLatLng(markerPos ? markerPos : { lat: previousPosType.geometry.coordinates[0], lng: previousPosType.geometry.coordinates[1] })
                                    getRealPosition(markerPos ? markerPos : { lat: previousPosType.geometry.coordinates[0], lng: previousPosType.geometry.coordinates[1] })
                                }
                            })
                }

            }, 100)
            setAlreadySelectedNewStationMarker(true)
        }
    }, [editingStationMarker])
    /**
     * initial layer colors (area to green, parcel to red..) and click and double click events and popup on layers
     * on add new Station marker, pick the clicked position to show marker on that position 
     * */
    const geoJSONOptions = geoJSONInit(markersDraggable, selectedAreaId, setCurrentSelectedSite, setLoading, setSelectedArea,
        handleGetAreasResult, setEditingStationMarker, setEditingMap)
    /** map sytle*/
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize(true)
        }
    }, [mapStyle])

    /** add layers and layers markers*/
    useEffect(() => {
        logger("Layers Data Changed", layersData)
        handleLayersData(layersData, mapRef.current, geoJSONLayer.current, markersLayer.current, geoJSONOptions, latlngJsonToMarker, isEditingStation)
    }, [layersData]);

    /** Update areas with the selected layer*/
    useEffect(
        () => {
            if (selectedArea) {
                updateMainLayer(selectedArea, mapRef.current, geoJSONLayer.current, markersLayer.current, geoJSONOptions, latlngJsonToMarker, isEditingStation)
            }
            if (setEditingItem)
                setEditingItem(selectedArea)
        },
        [selectedArea]
    );

    /** Fly to layer when double clicked on layer marker*/
    useEffect(() => {
        if (mapRef && mapRef.current) {
            mapRef.current.flyTo(L.latLng(currentSelectedMarker.lat, currentSelectedMarker.lng), 17)
        }
    }, [currentSelectedMarker])


    // handle space click event to either disable or enable draw control bar
    useEffect(() => {
        if (spaceClickEvent && spaceClickEvent.keyCode === 32 && document.getElementsByClassName("leaflet-draw-section")[0]) {
            if (disableEvent === true) {
                document.getElementsByClassName("leaflet-draw-section")[0].style.pointerEvents = 'none'
                document.getElementsByClassName("leaflet-draw-section")[0].style.opacity = '0.5'
                setDisableEvent(false)
            } else {
                document.getElementsByClassName("leaflet-draw-section")[0].style.pointerEvents = 'auto'
                document.getElementsByClassName("leaflet-draw-section")[0].style.opacity = '1'
                setDisableEvent(true)
            }
        }
    }, [spaceClickEvent])

    /**
     * first run and map initialisation
     * show the selected area on add/edit marker/area
     * initialise map with layersDataInitial if we have it in parameters
     */
    useEffect(() => {
        let result = initialiseMap(setMapStyle, style, mapRef.current, id,
            staticMap, drawnItems.current, geoJSONLayer.current,
            geoJSONOptions, markersLayer.current, controlLayers.current, setCurrentSelectedMarker, drawControl.current, setEditingMap, setLastCreatedLayer, waterNeedProp
            // , getPolygone, markersGroupClick, BaseMaps, OpenStreetMapLayer, editCallBack, overlayLayer.current)
            , getPolygone, markersGroupClick, BaseMaps, googleMapsSatelliteLayer, editCallBack, overlayLayer.current)

        mapRef.current = result.map
        drawnItems.current = result.drawnItems
        geoJSONLayer.current = result.geoJSONLayer
        overlayLayer.current = result.overlayLayer
        markersLayer.current = result.markersLayer
        controlLayers.current = result.controlLayers

        //add event listener to space button click to disable/enable the draw bar
        // document.addEventListener("keydown", setSpaceClickEvent, false)
    }, []);

    useEffect(() => {
        if (goToSite && editingItem) {
            // edit area case
            GetAreaDetails(editingItem.id, setSelectedArea).then(() => setLoading(false))
            setCurrentSelectedSite({ lat: JSON.parse(editingItem.center).longitude, lng: JSON.parse(editingItem.center).latitude })
        }

        logger(" SEABEX MAP INITIALS ", layersDataInitial, editingItem, initialEditableArea)

        if (layersDataInitial == null) {
            if (editingItem == null && initialEditableArea == null && !isEditing) {
                GetAreasList(handleGetAreasResult)
            }
        }
        else {
            // logger("Initialization UseEffect - Before HandleLayersData")
            setLayersData(layersDataInitial);
            // handleLayersData(layersDataInitial, mapRef.current, geoJSONLayer.current, markersLayer.current, geoJSONOptions, latlngJsonToMarker, isEditingStation)
        }

    }, [editingItem, layersDataInitial, isEditing])

    useEffect(() => {
      setEditableArea(initialEditableArea)
    }, [initialEditableArea])
    
    

    // use effect for guide Stepper
    useEffect(() => {
        //TODO:find a better solution
        setTimeout(() => {
            if ((steps[guide.initialStep].element === ".goToButton" || steps[guide.initialStep].element === ".drawAreaBtn") && guide.currentStep === 'seabexMap') {
                dispatchGuide({ type: IS_ENABLED, payload: true })
            }
        }, 2000);
    }, [openGoto])

    // useEffect for guide Stepper
    useEffect(() => {
        if (guide.isEnabled === false && ((steps[guide.initialStep].element === ".drawPolygonRectangleBtn" && guide.currentStep === 'seabexMapBack') || ((steps[guide.initialStep].element === ".onePagerMeteo" || steps[guide.initialStep].element === ".onePagerWaterNeedHistory") && guide.currentStep === 'seabexMap' && selectedArea && !embedded && !staticMap))) {
            guide.currentStep === 'seabexMapBack' && dispatchGuide({ type: CURRENT_STEP, payload: 'seabexMap' })
            setTimeout(() => {
                dispatchGuide({ type: IS_ENABLED, payload: true })
            }, (selectedArea ? 5000 : 700))
        }
    }, [guide, selectedArea, embedded, staticMap])

    /**
     * Add/Edit Area
     */
    useEffect(() => {
        if (editingMap != null) {
            const result = editingMapEffect(editingMap, mapRef.current, drawControl.current, geoJSONLayer.current, initialEditableArea, layersDataInitial, selectedAreaId, previousPosType, stationEditAdd)
            drawControl.current = result.drawControl
            mapRef.current = result.map
        }
    }, [editingMap])

    /**add new area/parcel/sector*/

    useEffect(() => {
        if (lastCreatedLayer !== null && (waterNeedProp !== true)) {
            if (addAreaFn) {
                addAreaFn(lastCreatedLayer, selectedArea)
            }
        }
    }, [lastCreatedLayer])

    useEffect(() => {
        assignOverlays(overlayImage, overlayLayer.current, geoJSONLayer.current, overlayImageOpacity)
    }, [overlayImage, overlayImageOpacity])

    /**go to*/
    const contextGoto = { open: openGoto, changeModalStatus: (newStatus) => { changeModalStatusGoto(newStatus, setOpenGoto, openGoto) } }

    const style = mapStyles(styles)

    const addNewArea = () => {
        let res = newDrawing(null, null, mapRef.current, drawControl.current, selectedArea, setEditingMap, markersLayer.current, geoJSONLayer.current, geoJSONOptions)
        if (res.map)
            mapRef.current = res.map
        if (res.drawControl)
            drawControl.current = res.drawControl
    }



    // TODO: change static data
    // const timeLineData = [{
    //     date: moment().format('MMMM Do YYYY'),
    //     s: vocabulary.atTimeLabel + ' ' + moment().format('HH:mm:ss'),
    //     name: "site details",
    //     type: <div>
    //         <img style={{ maxHeight: 100, maxWidth: 100 }} src="data:image/png;base64,**********" alt="spacesense" />
    //     </div>,

    // }]

    return (<div style={{ flexDirection: 'vertical' }}>
        {guide.isEnabled && guide.currentStep === 'seabexMap' &&
            <SeabexMapStepper setOpenGoto={setOpenGoto} prevStepGuide={prevStepGuide} setPrevStepGuide={setPrevStepGuide} mapRef={mapRef}
                disableDrawBar={disableDrawBar} drawControl={drawControl} setEditingMap={setEditingMap} editingItem={editingItem}
                handleLayersData={handleLayersData} layersDataInitial={layersDataInitial} geoJSONLayer={geoJSONLayer} geoJSONOptions={geoJSONOptions}
                latlngJsonToMarker={latlngJsonToMarker} addNewArea={addNewArea} addAreaFn={addAreaFn} markersLayer={markersLayer} />
        }
        <StyledCard id={id} ref={mapContainer} styleProp={mapStyle} className="goToModal">
            <Grid container spacing={1} style={{ position: 'absolute', top: 12, left: 48, zIndex: 500 }}>
                <Zoom in={true} mountOnEnter={true} timeout={{ appear: 500, enter: 300, exit: 0 }}>
                    <Grid item>
                        <Fab variant="extended" size="small" style={{ ...styleCommands, backgroundColor: 'white', color: 'black' }}
                            onClick={() => fullscreen ? normalSize(setMapStyle, style, setFullscreen) : maximizeSize(setMapStyle, styleMaxSize, setFullscreen)}>{fullscreen ? <FullscreenExit /> : <Fullscreen />}</Fab>
                    </Grid>
                </Zoom>
                <Grid item>
                    {selectedAreaId && !addNewStationMarker && stationEditAdd && <Fab variant="extended" size="small" style={styleCommands}
                        onClick={() => setAddNewStationMarker(true)}><AddLocationIcon />&nbsp;&nbsp;{/*isEditingStation ? vocabulary.updateStation : */ vocabulary.addStation}</Fab>}
                </Grid>
                <Grid item>
                    {selectedAreaId && !addNewStationMarker && stationEditAdd && <Fab variant="extended" size="small" style={{ ...styleCommands, backgroundColor: '#1f6602', color: 'white' }}
                        onClick={() => setOpenMarkerCoords(!openMarkerCoords)}><div className="goToButton" style={{ transform: 'translateX(-6px)' }}><LocationSearching />&nbsp;&nbsp;{"Add Station using coordinates"}</div></Fab>}
                </Grid>
                <Grid item>
                    {!layersDataInitial && selectedAreaId && !previousPosType && !stationEditAdd && <Fab variant="extended" size="small" style={{ ...styleCommands, backgroundColor: '#ddffff' }}
                        onClick={() => {
                            geoJSONLayer.current.eachLayer(
                                (layer) => {
                                    if (layer instanceof L.LayerGroup) {
                                        layer.eachLayer((subLayer) => {
                                            if (subLayer.editing && selectedAreaId === subLayer.feature.properties.id) {
                                                subLayer.editing.enable()
                                                let res = newDrawing(subLayer, null, mapRef.current, drawControl.current, selectedArea, setEditingMap, markersLayer.current, geoJSONLayer.current, geoJSONOptions)
                                                if (res.map)
                                                    mapRef.current = res.map
                                                if (res.drawControl)
                                                    drawControl.current = res.drawControl
                                            }
                                        })
                                    }
                                }
                            )
                        }}><Edit />&nbsp;&nbsp;{editingItem.type === TYPE_SITE ? vocabulary.editArea : editingItem.type === TYPE_PARCEL ? vocabulary.editParcel : vocabulary.editSector}</Fab>}
                </Grid>
                {!staticMap && <>
                    <Zoom in={selectedArea != null} mountOnEnter={true} unmountOnExit={true} timeout={{ appear: 500, enter: 300, exit: 0 }}>
                        <Grid item>
                            <Fab variant="extended" size="small" style={{ ...styleCommands, backgroundColor: '#ddffff' }}
                                onClick={() => selectedArea.parent_id ? handleAreaSelectionChange(selectedArea.parent_id, setLoading, setSelectedArea, handleGetAreasResult)
                                    : backArrow(mapRef.current, setSelectedArea, currentSelectedSite,
                                        handleLayersData, layersDataInitial.data, geoJSONLayer.current, markersLayer.current,
                                        geoJSONOptions, latlngJsonToMarker, setEditingItem, isEditingStation)
                                }><ZoomOut />&nbsp;&nbsp;{vocabulary.backLabel}</Fab>
                        </Grid>
                    </Zoom>
                    {(!editingItem || editingItem && editingItem.type !== TYPE_SECTOR) && <Zoom in={editingMap == null} mountOnEnter={true} unmountOnExit={true} timeout={{ appear: 500, enter: 300, exit: 0 }}>
                        <Grid item>
                            <Badge invisible={areas_right} color='secondary' badgeContent='!'>
                                <Fab variant="extended" size="small" style={styleCommands}
                                    onClick={() => addNewArea()} disabled={!areas_right}>
                                        <div className="drawAreaBtn">
                                            <AddCircleOutline />&nbsp;&nbsp;
                                                {waterNeedProp 
                                                        ? vocabulary.drawZone 
                                                        : (editingItem  ? (editingItem.type === TYPE_SITE ? vocabulary.addParcel 
                                                                                                        : vocabulary.addSector) 
                                                                        : vocabulary.addArea)}
                                        </div>
                                </Fab>
                            </Badge>
                        </Grid>
                    </Zoom>}
                    <Zoom in={editingMap != null} mountOnEnter={true} unmountOnExit={true} timeout={{ appear: 500, enter: 300, exit: 0 }}>
                        <Grid item>
                            <Fab variant="extended" size="small" style={{ ...styleCommands, backgroundColor: '#db441c', color: 'white' }}
                                onClick={() => { mapRef.current = disableDrawBar(mapRef.current, drawControl.current, setEditingMap); editingItem === null && handleLayersData(layersDataInitial.data, mapRef.current, geoJSONLayer.current, markersLayer.current, geoJSONOptions, latlngJsonToMarker) }}><HighlightOff />&nbsp;&nbsp;{vocabulary.stopEditing}</Fab>
                        </Grid>
                    </Zoom>
                    <Zoom in={true} mountOnEnter={true} timeout={{ appear: 500, enter: 300, exit: 0 }}>
                        <Grid item>
                            <Fab variant="extended" size="small" style={{ ...styleCommands, backgroundColor: '#1f6602', color: 'white' }}
                                onClick={(newStatus) => changeModalStatusGoto(newStatus, setOpenGoto, openGoto)}><div className="goToButton" style={{ transform: 'translateX(-6px)' }}><LocationSearching />&nbsp;&nbsp;{vocabulary.goToLabel}</div></Fab>
                        </Grid>
                    </Zoom>
                </>
                }
            </Grid>
            {loading &&
                <div style={{ zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.5)', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Zoom in={loading}>
                        <Typography style={{ color: 'white' }}><HourglassEmpty style={{ color: 'white' }} />{vocabulary.loading}</Typography>
                    </Zoom>
                </div>
            }

        </StyledCard>
        {openGoto &&
            <GotoDialog context={contextGoto} gotoPosition={gotoPosition} map={mapRef.current} setOpenGoto={setOpenGoto} />
        }
        {openMarkerCoords && <MarkerCoords setAddNewStationMarker={setAddNewStationMarker} setEditingStationMarker={setEditingStationMarker} openMarkerCoords={openMarkerCoords} setOpenMarkerCoords={setOpenMarkerCoords} />}

        {!embedded && !staticMap && selectedArea &&
            <>
                { selectedArea.satellite == true &&
                    <AreaSatelliteUI areaId={selectedArea.id} setOverlayImage={setOverlayImage} setOpacity={setOverlayImageOpacity} />
                }
                <AreaOnePager initialSelectedArea={selectedArea} initialWaterNeedProp={waterNeedProp} />

            </>
        }
    </div>
    )
}

export default SeabexMap
